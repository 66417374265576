import styled from "styled-components";
import { theme } from "styled-tools";

import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

import Text from "../Text";


export const StepsContainer = styled.div`

  ${mediaQuery[Screen.desktop]} {
    padding-inline: 300px;
  }
`;

export const RowContent = styled.div`
  margin: 128px 0;
`;

export const NameText = styled.span`
  color: ${theme("Primary_01")};
`;

export const CloseButton = styled.span`
  cursor: pointer;
`;

export const InfoCard = styled.div`
  display: flex;
  align-item: flex-start;
  padding: 11px 8px;
  border-radius: 8px;
  border: 1px solid ${theme("Primary_01")};
  margin-top: 12px;

  svg {
    rect {
      fill: ${theme("Primary_01")};
      stroke: ${theme("Primary_01")};
    }
  }
`;

export const AuthorizationCard = styled.div`
  display: flex;
  align-item: flex-start;
  background-color: ${theme("Background_12")};
  padding: 24px 10px;
  border-radius: 20px;

  svg {
    rect {
      fill: ${theme("Primary_01")};
      stroke: ${theme("Primary_01")};
    }
  }
`;

export const AuthorizationRow = styled.div`
  label {
    text-align: left !important;
  }

  gap: 15px;
`;

export const PercentageColumn = styled.div`
  section {
    padding: 0;
  }
`;

export const PayrollCheckbox = styled.input`
  accent-color: ${theme("Content_UX_02")};
  width: 20px;
  height: 20px;
`;

export const CustomText = styled(Text)`
  font-family: Poppins;
  .text-primary {
    color: ${theme("Primary_01")};
  }
`;

export const InfoMessage = styled.div`
  display: flex;
  align-item: flex-start;
  padding: 10px 5px;
  margin-top: 12px;
  color: ${theme("Primary_01")};
`;

export const LinkText = styled.div`
  display: flex;
  padding: 10px 5px;
  margin-top: 24px;
  font-size: 14px;
  font-family: Poppins;
  color: ${theme("Content_UX_02")};
  text-decoration: underline;
`;