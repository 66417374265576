import { ChangeEvent } from "react";
import { TFunction } from "i18next";
import { NavigateFunction } from "react-router-dom";

// Models
import { PlaidAccounts } from "../../../../Models/Funds";
import { JustifyContent } from "../../../../Models/Column";

// Own components
import { Column, Row } from "../../../../Components/Grid";
import PlaidLink from "../../Components/PlaidLink";
import LinkedAccountCardRadio from "./LinkedAccountCardRadio.component";

// Styles
import {
  CursorPointerStyled,
  CustomText,
  LinkedAccountCardExtraStyled,
  LinkedAccountExtraUpdate
} from "../LinkedAccounts.styles";
import Icon from "../../../../Components/Icon";

const LinkedAccountCardList = ({
  title,
  accounts,
  btnText,
  navigate,
  getSelected,
  showDetails,
  updateAccount,
  t,
}: {
  title: string;
  accounts: Array<PlaidAccounts>;
  btnText: string;
  navigate: NavigateFunction;
  getSelected?: (account: PlaidAccounts) => void;
  showDetails: (account: PlaidAccounts, requiredUpdate?: boolean) => void;
  updateAccount: (account: PlaidAccounts) => void;
  t: TFunction;
}) => (
  <Row>
    <Column span={12} justifyContent={JustifyContent.center}>
      <CustomText
        color="black"
        size={4}
        align="left"
        weight={600}
        margin="48px 0 24px 0"
      >
        {title}
      </CustomText>
    </Column>
    <Column span={12} justifyContent={JustifyContent.center}>
      <PlaidLink
        btnText={btnText}
        btnVariant="outline"
        onCompleted={(accountId: string) =>
          navigate("/funds-linked-accounts-details", {
            state: {
              accountId,
              isConfirmed: true,
            },
          })
        }
      />
    </Column>
    <Column span={12} px={1} py={2}>
      {accounts.map((account) => (
        <div key={account.id}>
          <LinkedAccountCardRadio
            title={account.bankName}
            description={account.subtype}
            helperText={`************${account.accountLastFour}`}
            value={account.id}
            getValue={(value: ChangeEvent<HTMLInputElement>) => {
              if (value?.target?.value === account.id && getSelected) {
                getSelected(account);
              }
            }}
            deleteAccount={() => updateAccount(account)}
            extras={
              <>
                {!account.activeToken && (
                  <CursorPointerStyled>
                    <LinkedAccountCardExtraStyled
                      onClick={() => {
                        showDetails(account, true);
                      }}
                    >
                      <LinkedAccountExtraUpdate>
                        <Icon
                          icon={"alertRedTriangle"}
                          color={"error"}
                          size="medium"
                          fillIcon={false}
                        />
                        <CustomText
                          size={0.5}
                          color="error"
                          weight={600}
                          margin="10px 0 10px 10px"
                        >
                          {t("Funds.LinkedAccounts.cardNeedsUpdate")}
                        </CustomText>
                      </LinkedAccountExtraUpdate>
                    </LinkedAccountCardExtraStyled>
                  </CursorPointerStyled>
                )}
                <CursorPointerStyled onClick={() => showDetails(account)}>
                  <CustomText
                    size={0.5}
                    color="black"
                    weight={600}
                    margin="15px 0 15px 0"
                  >
                    {t("Funds.LinkedAccounts.cardViewDetails")}
                  </CustomText>
                </CursorPointerStyled>
              </>
            }
          />
        </div>
      ))}
    </Column>
  </Row>
);

export default LinkedAccountCardList;