import styled from "styled-components";
import { tTableInfoColumn } from "../../Models/TableInfo";
import { handleColor } from "../../Utilities/Icons";

export const TableInfoStyled = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TableInfoRow = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 0;
  width: 100%;
`;

export const TableInfoColumn = styled.div<tTableInfoColumn>`
  flex: 1;
  padding: 15px 0;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  text-align: ${(props) => props.textAlign || "left"};
  font-weight: ${(props) => props.fontWeight || 400};
  color: ${(props) =>
    props.color ? handleColor(props.color) : props.theme.Content_UX_01};
`;

export const Text = styled.p`
  font-weight: 400;
  text-align: right;
  margin: 0;
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 400;
  padding: 15px 0px;
  font-family: Poppins;
  text-align: left;
`;
