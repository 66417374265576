
// Models
import { BankTransferStatus } from "../../../../Models/Funds";

// Own components
import Icon from "../../../../Components/Icon";

import { CustomText } from "../../BankTransfers.styled";
import { BankTransfersHeaderStatus } from "./BankTransfersHeader.styled";

type Props = {
  statusText: string | undefined;
  status: string;
};

export const BankTransfersHeaderIcons = ({
  status,
  statusText
}: Props) => {


  return (
    <BankTransfersHeaderStatus status={status}>
      {setCarStatusIcon(status)}
      <CustomText
        color="black"
        status={status}
        size={0.5}
        align="center"
        weight={400}
        margin="0 0 0 8px"
      >
        {statusText || ''}
      </CustomText>
    </BankTransfersHeaderStatus>
  );
};

const setCarStatusIcon = (status: string) => {
  switch (status) {
    case BankTransferStatus.PENDING:
      return <Icon icon="clock" size="medium" />;
    case BankTransferStatus.PROCESSING:
      return <Icon icon="clock" size="medium" />;
    case BankTransferStatus.COMPLETED:
      return <Icon icon="circleCheck" size="medium" />;
    case BankTransferStatus.FAILED:
      return <Icon icon="errorCircle" size="medium" />;
    default:
      return <Icon icon="circleXWarning" width={50} height={50} />;
  }
}