import styled from "styled-components";
import mediaQuery, { Screen } from "../../../Utilities/MediaQuery";
import { theme } from "styled-tools";
import BgBanner from "../../../Assets/Img/BgBanner.png";

export const PayrollContainer = styled.div`
  border-radius: 12px;
  background: ${theme("Background_11")};
  padding: 10px 18px;
  background-image: url(${BgBanner});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  button {
    font-family: Poppins;
    font-size: 11px;
    color: ${theme("Background_01")};
    background: ${theme("Background_10")};
    border-radius: 50px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }

  ${mediaQuery[Screen.desktop]} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const MessageContainer = styled.div`
  display: flex;

  h3 {
    font-size: 12px;
  }

  img {
    height: 60px;
    width: 60px;
  }

  ${mediaQuery[Screen.desktop]} {
    align-items: center;
  }

  div:nth-child(2) {
    margin-top: 16px;

    ${mediaQuery[Screen.desktop]} {
      margin-top: 0;
    }
  }
`;

export const PaddingLeft = styled.div`
  ${mediaQuery[Screen.desktop]} {
    margin-left: 100px;
  }
`;

export const FormContainer = styled.div`
  ${mediaQuery[Screen.desktop]} {
    margin-left: 100px;
  }
`;

export const InputContainer = styled.div`
  padding-block: 10px;

  span {
    color: ${theme("Primary_14")};
    font-weight: 600;
    font-size: 20px;
  }

  section {
    padding-inline: 0;
    text-align: left;
    font-size: 18px;
    color: ${theme("Content_UX_04")};

    .react-select__control, .react-select__control--is-disabled {
      border-color: ${theme("Background_03")};
    }
  }

  input, .react-select__indicators, .react-select__single-value {
    font-size: 16px;
    color: ${theme("Content_UX_04")};
    font-family: Montserrat;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;

  p:nth-child(2) {
    margin-inline: auto
  }
`;

export const InputCheck = styled.div`
  margin-top: 24px;

  section {
    background: ${theme("Background_12")};
    border-radius: 20px;
    padding: 25px 10px;
  }
`;

export const ButtonContainer = styled.div<{$valid: boolean}>`
  margin-top: 24px;

  button {
    width: 100%;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 600;
    border: 2px solid ${props => props.$valid ? props.theme.Primary_01 : props.theme.Content_UX_02};
  }

  button:nth-child(1) {
    svg rect {
      stroke: none!important;
    }
  }
`;

export const WhatsAppContainer = styled.div<{$show: boolean;}>`
  h3 {
    font-size: 12px;
    margin-top: 12px;
  }

  padding: 50px 20px 20px 20px;
  border-radius: 20px 20px 0 0;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(0, 0);
  background-color: white;
  display: ${props => props.$show ? "block" : "none"};
  width: -webkit-fill-available;
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 2;

  ${mediaQuery[Screen.mobile]} {
    button {
      width: 100%;
      border: 1px solid ${theme("Primary_01")};
    }
  }

  ${mediaQuery[Screen.desktop]} {
    button {
      width: 200px;
    }

    h1, h3 {
      width: 50%;
      margin-inline: auto;
    }
  }
`;

export const PopupOverlay = styled.div<{$show: boolean;}>`
  position: relative;
`;

export const Overlay = styled.div<{$show: boolean;}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: ${props => props.$show ? "block" : "none"};
  transition: opacity 0.3s ease;
  z-index: 1;
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  max-width: 279px;
  margin-inline: auto;
`;

export const IconMessage = styled.div`
  img {
    height: 50px;
  }

  h3 {
    font-size: 10px;
  }
`;

export const Advantages = styled.div`
  ${mediaQuery[Screen.desktop]} {
    display: flex;
    justify-content: space-around;
  }
`;

export const ContainerAdvantages = styled.div`
  display: inline-flex;
  margin-block: 15px;
  width: 100%;

  div:first-child {
    margin-right: 12px;
  }

  ${mediaQuery[Screen.desktop]} {
    width: 230px;
  }
`;

export const WidthContainer = styled.div`
  ${mediaQuery[Screen.desktop]} {
    width: 600px;
  }
`;