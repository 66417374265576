import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { defaultErrorMessage } from "../../Constants/Requests";
import { GlobalPaymentsAPI } from "../../Services/API/GlobalPaymentsApi";
import { LocationsPayload } from "../../Models/LocationsGP";
import { LOCATIONS_GP } from "../../Constants/Locations";
import { Destination } from "../../Models/Destination";

export const getLocationsGPAction = createAsyncThunk<Destination[], LocationsPayload>(
  LOCATIONS_GP,
  async (payload:LocationsPayload, thunkAPI) => {
    try {
      const headers = {
        sponsorBank: payload.sponsorBank,
      };
      const response = await GlobalPaymentsAPI.get(
        `/gpcatalogs/destinations/${payload.type}/${payload.countryCode}`,
        {
          headers,
        }
      );
  
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
