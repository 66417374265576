import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { GET_COPPEL_STATUS } from "../../Constants/CoppelAccess";

/**
 * Method to get Coppel Access status
 */
export const getCoppelAccess = createAsyncThunk(
  GET_COPPEL_STATUS,
  async (personId: string, thunkAPI) => {
    const headers = {
      "personId": personId
    };
    try {
      const response = await BaseConfigApi.get(`/coppelaccess/status`, { headers: headers });
      return response.data.data;
    } catch(error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
