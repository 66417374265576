import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { defaultErrorMessage } from "../../Constants/Requests";
import { GET_PENDING_SSN } from "../../Constants/PendingSSN";
import { AxiosError } from "axios";

export const GetPendingSSNAction = createAsyncThunk(
  GET_PENDING_SSN,
  async (payload:string, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(`/persons/${payload}/pending-ssn`);
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data.message);
      }

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
