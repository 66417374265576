import axios from "axios";
import { baseURLGP, API_KEY_GP } from "../../Constants/ConfigureApi";
import Cookies from "universal-cookie";
import { TOKEN_COOKIE_NAME } from "../../Constants/Auth";

export const GlobalPaymentsAPI = axios.create({
  baseURL: baseURLGP,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": API_KEY_GP,
  },
});

GlobalPaymentsAPI.interceptors.request.use(
  function (config) {
    const cookies = new Cookies();
    const token = cookies.get(TOKEN_COOKIE_NAME);
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
