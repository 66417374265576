import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { defaultErrorMessage } from "../../Constants/Requests";
import { PATCH_LANGUAGE_SETTINGS } from "../../Constants/Language";

export const PatchLanguageSettingAction = createAsyncThunk(
  PATCH_LANGUAGE_SETTINGS,
  async (payload:string, thunkAPI) => {
    try {
      const data = {
        language: payload
      };
      const response = await BaseConfigApi.patch(`/users/language`, data);
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data.message);
      }

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
