import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";

// own states of redux
import { AppDispatch, State } from "../../../Redux/Store";
import { SubmitKYCAction } from "../../../Redux/SubmitKYC/SubmitKYC.actions";
import { getKCYStatusAction } from "../../../Redux/GetKYCStatus/GetKYCStatus.actions";
import { ByUserSliceSelector } from "../../../Redux/ByUser/ByUser.slice";
import { selectorLanguage } from "../../../Redux/Translate";

// Own models
import { JustifyContent } from "../../../Models/Column";

// Own constants
import {
  GET_KYC_STATUS_FULFILLED,
  GET_KYC_STATUS_REJECTED,
  KYC_APPROVED,
  KYC_DECLINED,
  KYC_IN_REVIEW,
  KYC_RESUBMISSION,
  KYC_SUBMITTED,
  SUBMIT_KYC_FULFILLED,
  SUBMIT_KYC_REJECTED,
} from "../../../Constants/KYCStatus";
import { WhatsAppLinkEn, WhatsAppLinkEs } from "../../../Constants/ConfigureApi";

// Own styles
import { MarginContainer } from "./SubmitKYC.styled";

// OWn components
import Layout from "../../../Components/Layout";
import { BottomFixedContainer, Column, Container, FillerContainer, Row } from "../../../Components/Grid";
import Text from "../../../Components/Text";
import Button from "../../../Components/Button";
import Icon from "../../../Components/Icon";
import LoaderIcon from "../../../Components/LoaderIcon/LoaderIcon.component";

const SubmitKYCPage = () => {
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const lang = useSelector(selectorLanguage);
  const WhatsAppLink = lang.language === "en" ? WhatsAppLinkEn : WhatsAppLinkEs;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [isDeclined, setIiDeclined] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const ByUser = useSelector(ByUserSliceSelector);
  const personId = useMemo(() => ByUser.data?.personId || "", [ByUser]);

  const GetKYCStatus = async (personId: string) => {
    const payload = {
      personId: personId,
    };

    const response = await dispatch(getKCYStatusAction(payload));

    if (response?.type === GET_KYC_STATUS_FULFILLED) {
      if (
        response?.payload?.status === KYC_APPROVED ||
        response?.payload?.status === KYC_IN_REVIEW ||
        response?.payload?.status === KYC_SUBMITTED
      ) {
        window.location.href = "/";
      }

      if (response?.payload?.status === KYC_DECLINED) {
        setIiDeclined(true);
      }

      if (response?.payload?.results?.fullAuto?.status === KYC_RESUBMISSION) {
        window.location.href = "/kyc-identity-veriff";
      }
    }

    if (response?.type === GET_KYC_STATUS_REJECTED) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  const SubmitKYC = async () => {
    const response = await dispatch(SubmitKYCAction());

    if (response?.type === SUBMIT_KYC_FULFILLED) {
      GetKYCStatus(personId);
    }

    if (response?.type === SUBMIT_KYC_REJECTED) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    SubmitKYC();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Layout show={isLoading}>
        <LoaderIcon title={t("loaderText.titleOnboarding2")} description={t("loaderText.description2")} />
      </Layout>
      <Layout show={!isLoading && isError}>
        <FillerContainer>
          <Row>
            <Column span={12} justifyContent={JustifyContent.center} mt={6} mb={2}>
              <Icon icon="alertWarning" size="xlarge" />
            </Column>
            <Column span={12} mb={5}>
              <Text size="default" align="center" weight={600} color="error">
                {t("404.subtitle")}
              </Text>
            </Column>

            <Column span={12}>
              <BottomFixedContainer>
                <Button
                  type="submit"
                  variant="primary"
                  text={t("buttons.tryAgain")}
                  loading={isLoading ? 1 : 0}
                  onClick={() => SubmitKYC()}
                  sizeButton="xlarge"
                  sizeText="medium"
                />
              </BottomFixedContainer>
            </Column>
          </Row>
        </FillerContainer>
      </Layout>
      <Layout show={!isLoading && !isError && isDeclined}>
        <Container as={MarginContainer}>
          <Row>
            <Column span={12} justifyContent={JustifyContent.center} my={2}>
              <Icon icon="circleXRed" size="xlarge" />
            </Column>
            <Column span={12} my={1}>
              <Text size={2} align="center" weight={600} color="black" margin="0">
                {t("404.titleOnboardingDeclined")}
              </Text>
            </Column>

            <Column span={12} my={1}>
              <Text size={1} align="center" weight={400} color="grey" margin="0">
                {t("404.subTitleOnboardingDeclined")}
              </Text>
            </Column>

            <Column span={12} my={1}>
              <Button
                type="submit"
                variant="outline"
                text={t("buttons.contactSupport")}
                onClick={() => (window.location.href = WhatsAppLink)}
                sizeButton="xlarge"
                sizeText="medium"
              />
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default SubmitKYCPage;
