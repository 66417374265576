import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { GET_ALL_ACCOUNTS } from "../../Constants/Account";

/**
 * Method to get all accounts
 */
export const getAllAccounts = createAsyncThunk(
  GET_ALL_ACCOUNTS,
  async (payload:string, thunkAPI) => {
    const headers = {
      'personId': payload,
    };
    try {
      const response = await BaseConfigApi.get("/accounts", { headers: headers });
      return response.data.data;
    } catch(error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);