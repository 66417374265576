import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";

const initialState = {
  selectAccount: {
    type: "",
    id: ""
  }
};

export const typeAccountSlice = createSlice({
  name: "SelectTypeAccount",
  initialState,
  reducers: {
    changeTypeAccount: (state, action) => {
      state.selectAccount = action.payload;
    }
  },
});

export const { changeTypeAccount } = typeAccountSlice.actions;
export const selectorTransactionDetail = (state: State) => state.SelectTypeAccount;

export default typeAccountSlice.reducer;
