import { createClient } from "@services/API/GlobalPaymentsApiClient";
import { PaymentOrderWithPlaidRepository } from "../domain/PaymentOrderRepository";
import {
  PlaidAccountsDelete,
  PlaidAccountsDetail,
  PlaidAccountsResponse,
  PlaidAccountsUpdateRequest,
  PlaidCreateToken,
  PlaidCreateTokenStatus,
  PlaidTokenExchangeRequest,
  PlaidTokenExchangeResponse,
} from "../domain/Plaid";

const client = createClient();
const plaidPrefixService = "plaid";

export const PlaidPaymentOrderRepository =
  (): PaymentOrderWithPlaidRepository => {
    return {
      getAccounts,
      getAccountDetails,
      deleteAccount,
      updateAccount,
      createToken,
      exchangeToken,
    };
  };

const createToken = async (languageCode: string, body?: PlaidCreateToken) => {
  const response = await client.post<PlaidCreateTokenStatus>(
    `${plaidPrefixService}/token`,
    body || {},
    {
      headers: { languageCode },
    }
  );
  return response.data;
};

const exchangeToken = async (
  body: PlaidTokenExchangeRequest,
  languageCode: string
) => {
  const response = await client.post<PlaidTokenExchangeResponse>(
    `${plaidPrefixService}/exchange-token`,
    body,
    {
      headers: { languageCode },
    }
  );
  return response.data;
};

const deleteAccount = async (accountId: string, languageCode: string) => {
  const response = await client.delete<PlaidAccountsDelete>(
    `${plaidPrefixService}/delete-token/${accountId}`,
    {
      headers: { languageCode },
    }
  );
  return response.data;
};

const updateAccount = async (
  body: PlaidAccountsUpdateRequest,
  languageCode: string
) => {
  const response = await client.put<PlaidCreateTokenStatus>(
    `${plaidPrefixService}/updatetoken`,
    body,
    {
      headers: { languageCode },
    }
  );
  return response.data;
};

const getAccounts = async (languageCode: string) => {
  const response = await client.get<PlaidAccountsResponse>(
    `${plaidPrefixService}/accounts`,
    {
      headers: { languageCode },
    }
  );
  return response.data;
};

const getAccountDetails = async (accountId: string, languageCode: string) => {
  const response = await client.get<PlaidAccountsDetail>(
    `${plaidPrefixService}/detail-account/${accountId}`,
    {
      headers: { languageCode },
    }
  );
  return response.data;
};
