import { useEffect, useState } from "react";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useLocation } from "react-router-dom";

// own states
import { AppDispatch, State } from "../../Redux/Store";
import { BankTransfersDetailsAction } from "../../Redux/FundsAccount/FundsAccount.actions";

// Own components
import Layout from "../../Components/Layout";
import { Column, Container, Row } from "../../Components/Grid";
import LoaderIcon from "../../Components/LoaderIcon/LoaderIcon.component";
import { BankTransfersDescription, BankTransfersHeader } from "./Components";

// Own Models
import { AlignItems } from "../../Models/Column";
import {
  BankTransfersAccountContainer,
  CustomText,
  CustomTitle,
} from "./BankTransfers.styled";

// Own constants
import CurrencyService from "../../Services/CurrencyService";
import { BankTransferStatus } from "../../Models/Funds";
import { formatDateTransaction } from "../../Utilities/String";

const BankTranfersDetailsPage = () => {
  const [isLoadingLayout, setIsLoadingLayout] = useState(true);
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const bankTransfersDetailsStore = useSelector(
    (state: State) => state.FundsAccount?.bankTransferDetails
  );
  const personId = useSelector((state: State) => state.ByUser.data)?.personId;
  const lang = useSelector((state: State) => state.language);
  const dispatch = useDispatch<AppDispatch>();
  const { state } = useLocation();

  const statusData = setCardTextsStatus(state.transferStatus || "", t);

  useEffect(() => {
    getTransferDetails();
  }, []);

  const getTransferDetails = async () => {
    await dispatch(
      BankTransfersDetailsAction({
        personId: personId || "",
        transferOrderId: state.transferOrderId,
      })
    );

    setIsLoadingLayout(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout show={isLoadingLayout}>
        <LoaderIcon
          title=""
          text=""
          description={t("loaderText.description")}
        />
      </Layout>
      <Layout
        path={state?.backPath || "/manage-card-account"}
        textLink={t("buttons.back")}
        show={!isLoadingLayout}
      >
        <Container as={BankTransfersAccountContainer}>
          <Row>
            <Column span={12} p={2}>
              <CustomTitle
                tagName="h2"
                text={t("Funds.BankTransfer.details.titlePage")}
                color="black"
                size={3}
                align="center"
              />
              <CustomTitle
                tagName="h2"
                text={`$${CurrencyService.getFloatAmount(
                  bankTransfersDetailsStore?.amount.toString() || ""
                )}`}
                color="primary"
                size={4}
                align="center"
              />
              <CustomText
                color="grey"
                size={0.3}
                align="center"
                weight={400}
                margin="2px 0"
              >
                {t("Funds.BankTransfer.details.description")}
              </CustomText>
            </Column>
            <Column span={12} alignItems={AlignItems.center}>
              <BankTransfersHeader
                order={`${t("Funds.BankTransfer.textOrder")} #${state?.transferNumber}`}
                status={state?.transferStatus || ""}
                statusText={statusData.title}
                description={statusData.description}
              />
            </Column>
            <Column span={12} pb={6}>
              <BankTransfersDescription
                status={state?.transferStatus || ""}
                title={t("Funds.BankTransfer.details.title")}
                trackingStatusTitle={t(
                  "Funds.BankTransfer.details.transferStarted.title"
                )}
                trackingStatusDate={formatDateTransaction(
                  bankTransfersDetailsStore?.createdAt || "",
                  lang.language
                )}
                trackingStatusText={t(
                  "Funds.BankTransfer.details.transferStarted.description"
                )}
                trackingCompletedTitle={t(
                  "Funds.BankTransfer.details.transferCompleted.title"
                )}
                trackingCompletedText={t(
                  "Funds.BankTransfer.details.transferCompleted.description"
                )}
                trackingCompletedDate={
                  bankTransfersDetailsStore?.modifiedAt
                    ? formatDateTransaction(
                        bankTransfersDetailsStore?.modifiedAt || "",
                        lang.language
                      )
                    : ""
                }
              />
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

const setCardTextsStatus = (status: string, t: TFunction) => {
  switch (status) {
    case BankTransferStatus.PENDING:
      return {
        title: t("Funds.BankTransfer.statusPending"),
        description: t("Funds.BankTransfer.statusDescriptions.textPending"),
      };
    case BankTransferStatus.PROCESSING:
      return {
        title: t("Funds.BankTransfer.statusProcessing"),
        description: t("Funds.BankTransfer.statusDescriptions.textProcessing"),
      };
    case BankTransferStatus.COMPLETED:
      return {
        title: t("Funds.BankTransfer.statusCompleted"),
        description: t("Funds.BankTransfer.statusDescriptions.textConfirmed"),
      };
    case BankTransferStatus.FAILED:
      return {
        title: t("Funds.BankTransfer.statusFailed"),
        description: t("Funds.BankTransfer.statusDescriptions.textFailed"),
      };
    default:
      return {
        title: "",
        description: "",
      };
  }
};

export default BankTranfersDetailsPage;
