import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";

// Own redux
import { AppDispatch, State } from "../../../Redux/Store";
import { ByUserSliceSelector } from "../../../Redux/ByUser/ByUser.slice";
import { PostProofAddress } from "../../../Redux/ProofAddressSubmit/ProofAddressSubmit.actions";
import { PersonalDataSliceSelector } from "../../../Redux/PersonalData/PersonalData.slice";
import { ProofOfAddressTypeSelector } from "../../../Redux/ProofOfAddressType";

// Own constants
import { POST_PROOF_ADDRESS_FULFILLED, POST_PROOF_ADDRESS_REJECTED } from "../../../Constants/ProofOfAddress";

// Own models
import { EntriesProofOfAddressVeriffTypes, FormValuesFile } from "../../../Models/ProofOfAddress";

// Own components
import Layout from "../../../Components/Layout";
import { Column, Container, Row } from "../../../Components/Grid";
import Icon from "../../../Components/Icon";
import Title from "../../../Components/Title";
import DragDrop from "../../../Components/DragDropFile";
import Text from "../../../Components/Text";
import LoaderIcon from "../../../Components/LoaderIcon/LoaderIcon.component";
import StepsRoutes from "../../../Components/StepsRoutes";

// Own styles
import {
  ButtonCancel,
  ButtonDelete,
  ButtonSubmit,
  ContainerButtonForm,
  TitleVisa,
  VisaInstructionsContainer
} from "./ProofOfAddress.styled";

const ProofOfAddresUploadPhotoPage = () => {
  const theme = useSelector((state: State) => state.theme);
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const PersonalData = useSelector(ByUserSliceSelector);
  const PersonalDataForm = useSelector(PersonalDataSliceSelector);
  const ProofOfAddressType = useSelector(ProofOfAddressTypeSelector);

  const proofAddresTypeSelect = useMemo(() =>
    ProofOfAddressType.documentType || "",
  [ProofOfAddressType]);

  const personId = useMemo(() => 
    PersonalData.data?.personId || PersonalDataForm.data?.personId
  ,[PersonalData]);

  const [activeDelete, setActiveDelete] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm({ mode: "onChange" });

  const onSubmit: SubmitHandler<FormValuesFile> = async(data) => {
    setIsLoading(true);

    const output: EntriesProofOfAddressVeriffTypes = {
      idType: "proof_address",
      proofAddresType: proofAddresTypeSelect,
      file: data.file || ""
    };

    const fd = new FormData();
    for (const key of Object.keys(output)) {
      fd.append(key, output[key as keyof EntriesProofOfAddressVeriffTypes]);
    }

    const request = {
      personId: personId,
      data: fd
    };

    const response = await dispatch(PostProofAddress(request));

    if (response?.type === POST_PROOF_ADDRESS_FULFILLED) {
      window.location.href = "/";
    }

    if (response?.type === POST_PROOF_ADDRESS_REJECTED) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout show={isLoading}>
        <LoaderIcon
          title={t("loaderText.title")}
          description={t("loaderText.description")}
        />
      </Layout>
      <Layout show={!isLoading}>
        <Container as={VisaInstructionsContainer}>
          <Row>
            <Column span={12} mt={1}>
              <StepsRoutes totalSteps={8} stepIndex={6} />
            </Column>
          </Row>
          <Row>
            <Column span={12} mt={4} as={TitleVisa}>
              <ButtonCancel onClick={() => navigate("/proof-of-address")}>
                <Icon
                  icon="cancel"
                  size="large"
                  color="black"
                />
              </ButtonCancel>
              <Title
                tagName="h2"
                color="black"
                size={2}
                text={t("ProofOfAddress.takeOrUploadPhoto.titleUpload")}
                align="left"
              />
            </Column>
            <Column span={12} my={1}>
              <Text size={0.5} align="left" weight={400} color="grey" margin="0">
                {t("ProofOfAddress.takeOrUploadPhoto.message")}
              </Text>
            </Column>
            <Column span={12}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Column span={12} my={2}>
                  <Controller
                    name="file"
                    control={control}
                    defaultValue={null}
                    rules={{
                      required: true,
                      validate: {
                        sizeFile: (value) => {
                          const maxSizeInBytes = 5 * 1024 * 1024;
                          return value.size <= maxSizeInBytes || t("Forms.openAccount.sizeFile");
                        },
                      }
                    }}
                    render={({ field: { onChange } }) => (
                      <DragDrop
                        typeUpdateFile=".pdf,image/png,image/jpg,image/jpeg"
                        nameInput="file"
                        buttonSelectFile={false}
                        onChange={onChange}
                        errors={errors}
                        showBtnDelete={false}
                        deleteImg={activeDelete}
                        showInfo={false}
                      />
                    )}
                  />
                </Column>
                { isError && (
                  <Column span={12} mb={2}>
                    <Text size={0.5} align="center" weight={600} color="error">
                      {t("404.subtitle")}
                    </Text>
                  </Column>
                )}
                {isValid &&
                  <>
                    <Column span={12}>
                      <Text size={1} align="left">
                        {t("ProofOfAddress.takeOrUploadPhoto.documentClearly")}
                      </Text>
                    </Column>
                    <Column span={12} mb={5} as={ContainerButtonForm}>
                      <ButtonDelete onClick={() => setActiveDelete(!activeDelete)}>
                        <Icon
                          icon="cancel"
                          color="solid_light"
                          size="large"
                        />
                      </ButtonDelete>
                      <ButtonSubmit>
                        <Icon
                          icon="check"
                          color="white"
                          size="large"
                        />
                      </ButtonSubmit>
                    </Column>
                  </>
                }
              </form>
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  )
};

export default ProofOfAddresUploadPhotoPage;