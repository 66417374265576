export const GET_USER_INFORMATION_BY_ID = "getUserInformationById";
export const GET_USER_INFORMATION_BY_ID_PENDING = "getUserInformationById/pending";
export const GET_USER_INFORMATION_BY_ID_REJECTED = "getUserInformationById/rejected";
export const GET_USER_INFORMATION_BY_ID_FULFILLED = "getUserInformationById/fulfilled";

export const GET_BY_USER_DATA = "byUser";
export const GET_BY_USER_DATA_PENDING = "byUser/pending";
export const GET_BY_USER_DATA_REJECTED = "byUser/rejected";
export const GET_BY_USER_DATA_FULFILLED = "byUser/fulfilled";

export const UPDATE_USER_ADDRESS = 'AddressUpdate'
export const UPDATE_USER_ADDRESS_PENDING = `${UPDATE_USER_ADDRESS}/pending`;
export const UPDATE_USER_ADDRESS_REJECTED = `${UPDATE_USER_ADDRESS}/rejected`;
export const UPDATE_USER_ADDRESS_FULFILLED = `${UPDATE_USER_ADDRESS}/fulfilled`;

export const UPDATE_USER_SHIPPING_ADDRESS = 'shippingAddressUpdate'
export const UPDATE_USER_SHIPPING_ADDRESS_PENDING = `${UPDATE_USER_SHIPPING_ADDRESS}/pending`;
export const UPDATE_USER_SHIPPING_ADDRESS_REJECTED = `${UPDATE_USER_SHIPPING_ADDRESS}/rejected`;
export const UPDATE_USER_SHIPPING_ADDRESS_FULFILLED = `${UPDATE_USER_SHIPPING_ADDRESS}/fulfilled`;

export const UPDATE_USER_EMAIL = "updateUserEmail"
export const UPDATE_USER_EMAIL_PENDING = `${UPDATE_USER_EMAIL}/pending`;
export const UPDATE_USER_EMAIL_REJECTED = `${UPDATE_USER_EMAIL}/rejected`;
export const UPDATE_USER_EMAIL_FULFILLED = `${UPDATE_USER_EMAIL}/fulfilled`;

export const UPDATE_USER_SSN = "updateUserSsn"
export const UPDATE_USER_SSN_PENDING = `${UPDATE_USER_SSN}/pending`;
export const UPDATE_USER_SSN_REJECTED = `${UPDATE_USER_SSN}/rejected`;
export const UPDATE_USER_SSN_FULFILLED = `${UPDATE_USER_SSN}/fulfilled`;

export const UPDATE_USER_ITIN = "updateUserItin";
export const UPDATE_USER_ITIN_PENDING = `${UPDATE_USER_ITIN}/pending`;
export const UPDATE_USER_ITIN_REJECTED = `${UPDATE_USER_ITIN}/rejected`;
export const UPDATE_USER_ITIN_FULFILLED = `${UPDATE_USER_ITIN}/fulfilled`;

export const GET_ONBOARDING_STEP = "onboarding"
export const GET_ONBOARDING_STEP_PENDING = `${GET_ONBOARDING_STEP}/pending`;
export const GET_ONBOARDING_STEP_REJECTED = `${GET_ONBOARDING_STEP}/rejected`;
export const GET_ONBOARDING_STEP_FULFILLED = `${GET_ONBOARDING_STEP}/fulfilled`;

export const GET_PREFILLED_DATA = "prefilledData"
export const GET_PREFILLED_DATA_PENDING = `${GET_PREFILLED_DATA}/pending`;
export const GET_PREFILLED_DATA_REJECTED = `${GET_PREFILLED_DATA}/rejected`;
export const GET_PREFILLED_DATA_FULFILLED = `${GET_PREFILLED_DATA}/fulfilled`;

export const GET_USER_FEATURE = "getUserFeature";
export const GET_USER_FEATURE_PENDING = `${GET_USER_FEATURE}/pending`;
export const GET_USER_FEATURE_REJECTED = `${GET_USER_FEATURE}/rejected`;
export const GET_USER_FEATURE_FULFILLED = `${GET_USER_FEATURE}/fulfilled`;

export const UPDATE_USER_FEATURE = "updateUserFeature";
export const UPDATE_USER_FEATURE_PENDING = `${UPDATE_USER_FEATURE}/pending`;
export const UPDATE_USER_FEATURE_REJECTED = `${UPDATE_USER_FEATURE}/rejected`;
export const UPDATE_USER_FEATURE_FULFILLED = `${UPDATE_USER_FEATURE}/fulfilled`;

export const GET_CUSTOMER_CONFIG = "customerConfig";
export const GET_CUSTOMER_CONFIG_PENDING = `${GET_CUSTOMER_CONFIG}/pending`;
export const GET_CUSTOMER_CONFIG_REJECTED = `${GET_CUSTOMER_CONFIG}/rejected`;
export const GET_CUSTOMER_CONFIG_FULFILLED = `${GET_CUSTOMER_CONFIG}/fulfilled`;

export const GET_BRANCHES = "branches";
export const GET_BRANCHES_PENDING = `${GET_BRANCHES}/pending`;
export const GET_BRANCHES_REJECTED = `${GET_BRANCHES}/rejected`;
export const GET_BRANCHES_FULFILLED = `${GET_BRANCHES}/fulfilled`;

export const ASSIGNMENT_USER_FEATURE = "assignmentUserFeature";
export const ASSIGNMENT_USER_FEATURE_PENDING = `${ASSIGNMENT_USER_FEATURE}/pending`;
export const ASSIGNMENT_USER_FEATURE_REJECTED = `${ASSIGNMENT_USER_FEATURE}/rejected`;
export const ASSIGNMENT_USER_FEATURE_FULFILLED = `${ASSIGNMENT_USER_FEATURE}/fulfilled`;
