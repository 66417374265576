import styled from "styled-components";
import { theme } from "styled-tools";

export const FloatingButton = styled.button<{ $position?: string }>`
  background: ${theme("Background_08")};
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0px 4px 24px 0px ${theme("Shadow_01")};
  position: fixed;
  border: none;
  cursor: pointer;
  z-index: 1000;

  & > div {
    cursor: pointer;
  }

  ${(props) => {
    switch (props.$position) {
      case "TopLeft":
        return `
          top: 120px;
          left: 20px;
        `;
      case "TopRight":
        return `
          top: 120px;
          right: 20px;
        `;
      case "MiddleLeft":
        return `
          top: 55%;
          left: 20px;
        `;
      case "MiddleRight":
        return `
          top: 55%;
          right: 20px;
        `;
      case "BottomLeft":
        return `
          bottom: 85px;
          left: 20px;
        `;
      default:
        return `
          bottom: 85px;
          right: 20px;
        `;
    }
  }};
`;

export const ContainerModal = styled.div<{
  $show: boolean;
  $position?: string;
}>`
  background: ${theme("Background_01")};
  border: none;
  position: fixed;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0px 4px 24px 0px ${theme("Shadow_01")};
  border-radius: 24px;
  min-width: 280px;
  padding: 10px 25px;
  display: ${(props) => (props.$show ? "block" : "none")};

  ${(props) => {
    switch (props.$position) {
      case "TopLeft":
        return `
          top: 185px;
          left: 20px;
        `;
      case "TopRight":
        return `
          top: 185px;
          right: 20px;
        `;
      case "MiddleLeft":
        return `
          top: calc(55% + 65px);
          left: 20px;
        `;
      case "MiddleRight":
        return `
          top: calc(55% + 65px);
          right: 20px;
        `;
      case "BottomLeft":
        return `
          bottom: 85px;
          left: 20px;
        `;
      default:
        return `
          bottom: 85px;
          right: 20px;
        `;
    }
  }};
`;
