import styled from "styled-components";
import mediaQuery, { Screen } from "../../../Utilities/MediaQuery";
import { theme } from "styled-tools";

export const SelectAccountButton = styled.div`
  button {
    margin-bottom: 0;
  }
`;

export const AccountCard = styled.div`
  background-color: ${theme("Background_01")};
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px 0px rgba(${theme("RGBA_01")}, 0.15);
  height: 110px;
  padding: 12px 24px;
  margin: 16px 0;
  width: 100%;

  ${mediaQuery[Screen.mobile]} {
    height: auto;
    margin: 16px auto;
  }
`;

export const AccountBankDescription = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
`;


export const SelectedAccountInput = styled.div`
  input {
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    border-color: ${theme("Background_03")};
    font-size: 12px;
    font-family: "Poppins";
    font-weight: 700;
    cursor: pointer;
    font-size: 16px;
    padding: 16px;
    margin-top: 8px;
    outline: none;
    width: 100%;
  }
`;

export const SelectedAccountError = styled.span`
  color:  ${theme("Red_08")};
`;