import ARGIcon from "@assets/Flags/ARGIcon.png";
import BOBIcon from "@assets/Flags/BOBIcon.png";
import BRAIcon from "@assets/Flags/BRAIcon.png";
import CLIcon from "@assets/Flags/CLIcon.png";
import COLIcon from "@assets/Flags/COLIcon.png";
import ECUIcon from "@assets/Flags/ECUIcon.png";
import GTMIcon from "@assets/Flags/GTMIcon.png";
import MXIcon from "@assets/Flags/MXIcon.png";
import SOLIcon from "@assets/Flags/SOLIcon.png";
import UYUIcon from "@assets/Flags/UYUIcon.png";
import SLVIcon from "@assets/Flags/SLVIcon.png";
import CANIcon from "@assets/Flags/CANIcon.png";
import NICIcon from "@assets/Flags/NICIcon.png";
import HNDIcon from "@assets/Flags/HNDIcon.png";
import PHPIcon from "@assets/Flags/PHPIcon.png";
import INDIcon from "@assets/Flags/INDIcon.png";
import VNDIcon from "@assets/Flags/VNDIcon.png";
import PANIcon from "@assets/Flags/PANIcon.png";
import USIconSrc from "@assets/Flags/eu.png";

import USARGIcon from "@assets/Flags/IconArg.png";
import USBOBIcon from "@assets/Flags/IconBol.png";
import USBRAIcon from "@assets/Flags/IconBra.png";
import USCLIcon from "@assets/Flags/IconChi.png";
import USCOLIcon from "@assets/Flags/IconCol.png";
import USECUIcon from "@assets/Flags/IconEcu.png";
import USGTMIcon from "@assets/Flags/IconGtm.png";
import USSLVIcon from "@assets/Flags/IconSlv.png";
import USMXIcon from "@assets/Flags/IconMex.png";
import USSOLIcon from "@assets/Flags/IconPer.png";
import USUYUIcon from "@assets/Flags/IconUry.png";
import USHNDIcon from "@assets/Flags/IconHnd.png";
import USNICIcon from "@assets/Flags/IconNic.png";
import USCANIcon from "@assets/Flags/IconCan.png";
import USPHPIcon from "@assets/Flags/IconPhp.png";
import USINDIcon from "@assets/Flags/IconInd.png";
import USVNDIcon from "@assets/Flags/IconVnd.png";
import USPANIcon from "@assets/Flags/IconPan.png";

import { Currency } from "./Currency";

export type ExchangeRate = {
  id: string;
  icon: string;
  iconUs?: string;
  currency?: Currency;
  currencyQuoter?: Currency;
  label?: string;
  labelEs?: string;
  countryCode: string;
};

// TODO: Change this array or remove it when the list of supported currencies change for the product
export const allowedCurrencies = [
  "ARS",
  "BRL",
  "BOB",
  "CLP",
  "COP",
  "GTQ",
  "MXN",
  "PEN",
  "UYU",
  "CAN",
  "NIC",
  "USD",
  "SLV",
  "HNL",
  "ECU",
  "CAD",
  "INR",
  "PHP",
  "VND",
];

export const SenderExchangeRates: ExchangeRate[] = [
  {
    id: "0",
    icon: USIconSrc,
    label: "United States",
    labelEs: "Estados Unidos",
    currency: Currency.USD,
    countryCode: "USA",
  },
];

export const RecipientExchangeRates: ExchangeRate[] = [
  {
    id: "1",
    icon: ARGIcon,
    iconUs: USARGIcon,
    countryCode: "ARG",
  },
  {
    id: "2",
    icon: BOBIcon,
    iconUs: USBOBIcon,
    countryCode: "BOL",
  },
  {
    id: "3",
    icon: BRAIcon,
    iconUs: USBRAIcon,
    countryCode: "BRA",
  },
  {
    id: "4",
    icon: CLIcon,
    iconUs: USCLIcon,
    countryCode: "CHL",
  },
  {
    id: "5",
    icon: COLIcon,
    iconUs: USCOLIcon,
    countryCode: "COL",
  },
  {
    id: "6",
    icon: ECUIcon,
    iconUs: USECUIcon,
    countryCode: "ECU",
  },
  {
    id: "7",
    icon: GTMIcon,
    iconUs: USGTMIcon,
    countryCode: "GTM",
  },
  {
    id: "8",
    icon: MXIcon,
    iconUs: USMXIcon,
    countryCode: "MEX",
  },
  {
    id: "9",
    icon: SOLIcon,
    iconUs: USSOLIcon,
    countryCode: "PER",
  },
  {
    id: "10",
    icon: UYUIcon,
    iconUs: USUYUIcon,
    countryCode: "URY",
  },
  {
    id: "11",
    icon: CANIcon,
    iconUs: USCANIcon,
    countryCode: "CAN",
  },
  {
    id: "12",
    icon: NICIcon,
    iconUs: USNICIcon,
    countryCode: "NIC",
  },
  {
    id: "13",
    icon: SLVIcon,
    iconUs: USSLVIcon,
    countryCode: "SLV",
  },
  {
    id: "14",
    icon: HNDIcon,
    iconUs: USHNDIcon,
    countryCode: "HND",
  },
  {
    id: "15",
    icon: PHPIcon,
    iconUs: USPHPIcon,
    countryCode: "PHL",
  },
  {
    id: "16",
    icon: INDIcon,
    iconUs: USINDIcon,
    label: "India",
    countryCode: "IND",
  },
  {
    id: "17",
    icon: VNDIcon,
    iconUs: USVNDIcon,
    label: "Vietnam",
    countryCode: "VNM",
  },
  {
    id: "18",
    icon: PANIcon,
    iconUs: USPANIcon,
    label: "Panama",
    countryCode: "PAN",
  },
];

export const CountrieWhithoutCP = ["ARG", "BRA", "BOL", "CHL", "COL", "ECU", "PER", "URY", "CAN", "IND"];

export const CountrieWhithoutD2B = ["PAN"];
