import styled from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

export const StepsContainer = styled.div`
  ${mediaQuery[Screen.desktop]} {
    padding-inline: 300px;
  }
`;

export const StepsContainerItems = styled.div`
  display: flex;
`;

export const StepItem = styled.div<{$active?: boolean, $isContinue?: boolean, $color?: string}>`
  flex: 1;
  ${(props) => {
    if (props.$color === "Success_Green" && props.$active) {
      return `
        border: 1px solid ${props.$active ? props.theme.Success_Green : props.theme.Background_07};
        background-color: ${props.$active ? props.theme.Success_Green : props.theme.Background_07};
      `;
    } else {
      return `
        border: 1px solid ${props.$active ? props.theme.Primary_01 : props.theme.Background_07};
        background-color: ${props.$active ? props.theme.Primary_01 : props.theme.Background_07};
      `;
    }
  }}
  text-align: center;
  height: 7px;
  border-radius: 40px;
  margin-right: 0;
  margin-left: -6px;

  &.active {
    ${(props) => {
      if (props.$color === "Success_Green") {
        return `
          background-color: ${props.theme.Success_Green};
          border: 1px solid ${props.theme.Success_Green};
        `;
      } else {
        return `
          background-color: ${props.theme.Primary_01};
          border: 1px solid ${props.theme.Primary_01};
        `;
      }
    }}
    z-index: 1;
  }

  ${mediaQuery[Screen.desktop]} {
    height: 8px;
  }
`;
