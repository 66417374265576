import styled from "styled-components";
import { theme } from "styled-tools";

export const AlerBoxContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: start;
  background-color: ${theme("Background_11")};
  padding: 10px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 14px;
  width: 100%;
`;

export const AlerBoxIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AlertBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  text-align: left;
  padding-left: 5px;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${theme("Background_18")};

  .text-strong {
    font-weight: 600;
  }
`;