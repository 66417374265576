import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// own states
import { AlignItems, JustifyContent } from "@models/Column";
import { useDispatch, useSelector } from "react-redux";
import { selectorLanguage } from "../../Redux/Translate";
// import { AppDispatch } from "../../Redux/Store";

// Own components
import { Column, Container, Row } from "../../Components/Grid";
import Text from "../../Components/Text";
import IconFont from "../../Components/IconFont";
import Button from "../../Components/Payments/Button";
import Modal from "../../Components/Modal";
import IconShape from "../../Components/IconShape";
import { SearchInput } from "@components/Payments/Input/SearchInput.component";
import { Pagination } from "@components/Pagination/Pagination.component";

import { BeneficiaryRecipient } from "@core/Payments/Beneficiary/domain/Beneficiary";
import { BeneficiaryCard } from "@components/Recipients";
import { BeneficiarySelect } from "@core/Payments/Beneficiary/domain/Beneficiary";
import { useBeneficiary } from "@hooks/Payments/useBeneficiary";
import { scrollTopWindow } from "../../Utilities/ScrollTopWindow";
import { useDebounce } from "@hooks/Payments/useDebounce";
import { isEmpty } from "lodash";
import { ThemeProvider } from "styled-components";
import Layout from "@components/Layout";
import { State } from "@redux/Store";
import { useModal } from "@hooks/Payments/useModal";
import { useNavigate } from "react-router-dom";
import { BeneficiarySlice } from "@redux/Payments/Beneficiary";
import { BeneficiaryForm } from "@core/Payments/Beneficiary/domain/BeneficiaryForm";
import { isApiRequestError } from "@core/Common/domain/ApiRequestError";
import { BeneficiaryAccountForm } from "@core/Payments/Beneficiary/domain/BeneficiaryAccountForm";
import { ModalProps } from "@models/ModalInterface";
import { QuotationSlice } from "@redux/Payments/Quotation";

export const Recipients = () => {
  const [t] = useTranslation("global");
  const [tModals] = useTranslation("modals");
  const theme = useSelector((state: State) => state.theme);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [preferred, setPreferred] = useState<BeneficiaryRecipient[]>([]);
  const [recipients, setRecipients] = useState<BeneficiaryRecipient[]>([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<string | undefined>("");
  const [deleteBeneficiary, setDeleteBeneficiary] = useState<string | undefined>("");
  const [searchValue, setSearchValue] = useState("");
  const {
    isLoading,
    errorMessage,
    getBeneficiariesPaginated,
    deleteBeneficiarySelected,
    createRecipient,
    addAccount,
    getMessageError,
  } = useBeneficiary();
  const { language } = useSelector(selectorLanguage);

  const { modal, showModal } = useModal();
  const { modal: errorModal, showModal: showErrorModal } = useModal({ modalType: "error" });
  const handleDeleteBeneficiary = (beneficiary?: BeneficiarySelect) => {
    setOpenModalDelete(true);
    setDeleteBeneficiary(beneficiary?.fullName);
    setSelectedBeneficiary(beneficiary?.beneficiaryId);
  };

  const closeModalDelete = () => {
    setOpenModalDelete(false);
  };

  const handleDelete = async () => {
    if (selectedBeneficiary) {
      await deleteBeneficiarySelected(selectedBeneficiary);
      closeModalDelete();
      fetchBeneficiaries();
    }
  };

  const fetchBeneficiaries = useDebounce(async () => {
    const paginationResponse = await getBeneficiariesPaginated({
      page: currentPage,
      search: searchValue,
    });
    if (paginationResponse) {
      const preferred = paginationResponse.preferredRecipients || [];
      const recipients = paginationResponse.recipients || [];

      setPageCount(Math.ceil(paginationResponse.pagination.total / 20));
      setPreferred(preferred);
      setRecipients(recipients);

      return recipients;
    }
    return [];
  }, 500);

  const createBeneficiary = async (data: BeneficiaryForm, closeModal?: ModalProps["handleClose"]) => {
    const { account, ...beneficiaryData } = data;
    const beneficiaryCreatedResponse = await createRecipient(beneficiaryData);
    if (beneficiaryCreatedResponse && !isApiRequestError(beneficiaryCreatedResponse) && !isEmpty(account)) {
      const accountResponse = await addAccount({
        beneficiaryId: beneficiaryCreatedResponse.recipient.beneficiaryId,
        account: account![0] as BeneficiaryAccountForm["account"],
      });
      if (isApiRequestError(accountResponse)) {
        getMessageError(accountResponse);
        return;
      }
      await fetchBeneficiaries();
      closeModal && closeModal();
    } else if (isApiRequestError(beneficiaryCreatedResponse)) {
      getMessageError(beneficiaryCreatedResponse);
    }
  };

  const addNewRecipient = () => {
    showModal({
      modalType: "recipientModal",
      handleSubmit: (data, close) => {
        createBeneficiary(data as BeneficiaryForm, close);
      },
    });
  };

  const editRecipient = (beneficiary: BeneficiaryRecipient) => {
    dispatch(BeneficiarySlice.actions.setBeneficiaryEdit(beneficiary));
    navigate("/recipients/edit", { state: { previousView: "recipients" } });
  };

  useEffect(() => {
    if (errorMessage) {
      showErrorModal({
        errorMessage: tModals(errorMessage),
      });
    }
  }, [errorMessage]);

  useEffect(() => {
    scrollTopWindow();
    fetchBeneficiaries();
  }, [currentPage, searchValue, language]);

  useEffect(() => {
    dispatch(QuotationSlice.actions.resetState());
    fetchBeneficiaries();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Layout loading={isLoading} showNavigation>
        <Container maxWidth="545px" margin="0 auto 24px auto" padding="24px">
          <Row>
            <Column span={12} mb={3} justifyContent={JustifyContent.flexEnd} alignItems={AlignItems.center} gap={3}>
              <Button
                text={`+ ${t("Beneficiaries.AddNewRecipient")}`}
                variant="outline"
                sizeText="medium"
                padding="11px 24px"
                weight={500}
                onClick={() => {
                  addNewRecipient();
                }}
              />
            </Column>
            <Column span={12} mb={3}>
              <SearchInput searchValue={searchValue} onChange={setSearchValue} />
            </Column>
            {!isEmpty(preferred) && (
              <>
                <Column span={12}>
                  <Text color="black_highlight" size={1} align="left" weight={600} margin="0 15px 4px 0">
                    {t("Beneficiaries.Beneficiary.RecentBeneficiaries")}
                  </Text>
                  <Text color="black_highlight" size={0.5} align="left" weight={400} margin="0 15px 25px 0">
                    {t("Beneficiaries.Beneficiary.SubTitleRecentBeneficiaries")}
                  </Text>
                </Column>

                <Column span={12} justifyContent={JustifyContent.center} direction="column" mb={2} gap="12px">
                  {preferred.map((recipient) => (
                    <BeneficiaryCard
                      {...recipient}
                      key={recipient.beneficiaryId}
                      withEditButtons
                      editBeneficiary
                      withUserIcon={false}
                      handleDeleteBeneficiary={handleDeleteBeneficiary}
                      onClickEdit={() => editRecipient(recipient)}
                    />
                  ))}
                </Column>
              </>
            )}
            <Column span={12}>
              <Text color="black_highlight" size={1} align="left" weight={600} margin="0 15px 16px 2px">
                {t("Beneficiaries.Beneficiary.AllBeneficiaries")}
              </Text>
            </Column>
            {!isEmpty(recipients) ? (
              <Column span={12} justifyContent={JustifyContent.center} direction="column" gap="12px">
                {recipients.map((recipient) => (
                  <BeneficiaryCard
                    {...recipient}
                    key={recipient.beneficiaryId}
                    withEditButtons
                    editBeneficiary
                    withUserIcon={false}
                    handleDeleteBeneficiary={handleDeleteBeneficiary}
                    onClickEdit={() => editRecipient(recipient)}
                  />
                ))}
              </Column>
            ) : (
              <Column
                span={12}
                justifyContent={JustifyContent.center}
                alignItems={AlignItems.center}
                direction="column"
                px={6}
              >
                <IconFont name="user-circle" size="large" />
                <Text size={2} color="black" margin="16px 0">
                  {searchValue ? t("global.NoSearchResults") : t("Dashboard.NotRecipients")}
                </Text>
              </Column>
            )}
          </Row>
          <Row>
            {pageCount > 1 && (
              <Column span={12} my={4}>
                <Pagination current={currentPage} total={pageCount} onPageChange={setCurrentPage} />
              </Column>
            )}
          </Row>
        </Container>
        <Modal show={openModalDelete} handleClose={handleDelete}>
          <IconShape icon="user-x" bgColor="white" iconSize="xxlarge" iconColor="black" />
          <Text size={4} weight={600}>
            {t("Beneficiaries.EditBeneficiary.titleModalDelete")}
          </Text>
          <Text size={3} weight={400}>
            {t("Beneficiaries.EditBeneficiary.subTitleModalDelete")}
          </Text>
          <Text size={1} weight={700} margin={"20px 0 "}>
            {deleteBeneficiary}
          </Text>
          <Button
            text={t("Beneficiaries.EditBeneficiary.buttonDelete")}
            onClick={handleDelete}
            sizeText="medium"
            variant="primary"
          />
          <Button
            text={t("Beneficiaries.EditBeneficiary.buttonCancel")}
            onClick={closeModalDelete}
            sizeText="medium"
            variant="outline"
          />
        </Modal>
        {modal}
        {errorModal}
      </Layout>
    </ThemeProvider>
  );
};
