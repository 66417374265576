import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Own services Api
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { PersonPayrollInfoPayload } from "../../Models/DirectDeposit";

// Own Constants
import {
  GET_DIRECT_DEPOSIT, 
  SET_DIRECT_DEPOSIT, 
  UPDATE_DIRECT_DEPOSIT_FEATURE 
} from "../../Constants/Payroll";
import { defaultErrorMessage } from "../../Constants/Requests";

export const GetDirectDeposit = createAsyncThunk(
  GET_DIRECT_DEPOSIT,
  async (payload: PersonPayrollInfoPayload, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(`/payroll/person/${payload.personId}/customer/${payload.customerId}`);
      return response.data.data;
    } catch(error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const SetDirectDeposit = createAsyncThunk(
  SET_DIRECT_DEPOSIT,
  async (payload: {
    personId: string;
    percentage: number,
    isAccepted: boolean
  }, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post(
        `/request/directdeposit`,
        {
          percentage: payload.percentage,
          isAccepted: payload.isAccepted
        },
        {
          headers: {
            "personId": payload.personId
          }
        }
      );
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);

export const UpdateDirectDepositFeature = createAsyncThunk(
  UPDATE_DIRECT_DEPOSIT_FEATURE,
  async (payload: {
    userId: string;
    featureCode: string;
    isActive: boolean
  }, thunkAPI) => {
    try {
      const response = await BaseConfigApi.patch(
        `/userfeatures/${payload.userId}/${payload.featureCode}`,
        { isActive: payload.isActive },
      );
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
