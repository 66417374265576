import { countriesDummy } from "../Constants/Countries";
import { USRegex, MXRegex, GTRegex } from "../Constants/PhoneRegex";
import { allowedPhoneNumber, allowenNoDigits } from "../Constants/Regexp";
import { IPhoneNumberData } from "../Models/PhoneNumberInterface";

export function phoneNumberFormat(phone: string) {
  // eslint-disable-next-line
  let formatPhone: any | null = null;
  formatPhone = phone.replace(allowenNoDigits, "").match(allowedPhoneNumber);

  if (formatPhone[1].length > 0) {
    phone = "(" + formatPhone[1];
  } else {
    phone.split("(");
  }

  if (formatPhone[2].length > 0) {
    phone += ") " + formatPhone[2];
  } else {
    phone.split(" ");
  }

  if (formatPhone[3].length > 0) {
    phone += "-" + formatPhone[3];
  } else {
    phone.split("-");
  }

  const PhoneNumber = { number: formatPhone, phone: phone };

  return PhoneNumber;
}

export function formatPhoneNumber(phoneNumberString:string) {
  const phoneData = getCountryCodeFromPhoneNumber(phoneNumberString);
  let match: RegExpMatchArray | null = null;
  switch(phoneData?.code){
    case "1":
      match = phoneData?.phoneNumber.match(USRegex);
      break;
    case "52":
      match = phoneData?.phoneNumber.match(MXRegex);
      break;
    case "502":
      match = phoneData?.phoneNumber.match(GTRegex);
      break;
  }

  if (match) {
    return [`+${phoneData?.code}`, ' ', match[2], ' ', match[3], ' ', match[4]].join('');
  }


  return `+${phoneData?.code} ${phoneData?.phoneNumber}`;
}

export const getCountryCodeFromPhoneNumber = (phoneNumber: string) : IPhoneNumberData | undefined => {
  if(phoneNumber){
    const phoneNumberClean = phoneNumber.replace("+", "").trim();
    for (let sizeCode = 3; sizeCode > 0; sizeCode--) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filterCountries = countriesDummy.filter((country: any) => country.dialCode === phoneNumberClean.substring(0,sizeCode));
      if(filterCountries && filterCountries.length > 0){
        const response = {code: filterCountries[0].dialCode, country: filterCountries[0].alpha2Code, phoneNumber: phoneNumberClean.substring(sizeCode, (phoneNumberClean.length))};
        return response;
      }
    }
    return undefined;
  }
  return undefined;
};