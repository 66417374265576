import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";

const initialState = {
  address: {
    line1: null,
    line2: null,
    city: null,
    state: null,
    zipCode: null,
    country: null
  }
};

export const saveAddressSlice = createSlice({
  name: "SaveAddress",
  initialState,
  reducers: {
    saveAddressAction: (state, action) => {
      state.address = action.payload;
    }
  },
});

export const { saveAddressAction } = saveAddressSlice.actions;

export const saveAddressSelector = (state: State) => state.SaveAddress;

export default saveAddressSlice.reducer;
