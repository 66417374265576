import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { VERIFF_IDV_URL } from "../../Constants/Veriff";

export const VeriffIDV = createAsyncThunk(
  VERIFF_IDV_URL,
  async (payload:string | undefined, thunkAPI) => {
    const typeIDV = payload ? `/${payload}` : ""; 
    try {
      const response = await BaseConfigApi.post(`/persons/idv${typeIDV}`);
      return response.data.data;
    } catch(error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
