import { createSlice } from "@reduxjs/toolkit";
import { getLocationsGPAction } from "./LocationsGP.actions";
import { State } from "../Store";
import { LocationsState } from "../../Models/LocationsGP";

const initialState: LocationsState = {
  loading: false,
  error: null,
  data: null
};

export const getLocationsGPSlice = createSlice({
  name: "getLocationsGP",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLocationsGPAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLocationsGPAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getLocationsGPAction.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export const getLocationsGPSelector = (state: State) => state.getLocationsGP;
