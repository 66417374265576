import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { GET_HAS_SENDOLA_ONE } from "../../Constants/HasSendolaOne";

export const getHasSendolaOneAction = createAsyncThunk(
  GET_HAS_SENDOLA_ONE,
  async (payload:string, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(`/persons/${payload}/has-sendola-one`);
      return response.data.data;
    } catch(error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
