import { useEffect, useMemo, useState } from "react";
import { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import isEmpty from "lodash.isempty";

// Own redux
import { AppDispatch, State } from "../../Redux/Store";
import { CardsSelector } from "../../Redux/Cards/Cards.slice";
import { ByUserSliceSelector } from "../../Redux/ByUser/ByUser.slice";
import { setCurrentAccount } from "../../Redux/Accounts/Accounts.slice";
import { findAccountBalance } from "../../Redux/AccountBalance/AccountBalance.actions";
import { getAllAccounts } from "../../Redux/Accounts/Accounts.actions";
import { getAllCards } from "../../Redux/Cards/Cards.actions";
import { handleAccountTransactions } from "../../Redux/AccountTransactions/AccountTransactions.actions";
import { ByUserSliceAction } from "../../Redux/ByUser/ByUser.actions";
import { PrefilledDataAction } from "../../Redux/PrefilledData/PrefilledData.actions";
import { BankTransfersAction } from "../../Redux/FundsAccount/FundsAccount.actions";
import { getUserFeature } from "../../Redux/UserFeatures/UserFeatures.actions";
import { changeTransactionDetail } from "../../Redux/TransactionDetail";

// Own Constants
import {
  GET_BY_USER_DATA_FULFILLED,
  GET_BY_USER_DATA_REJECTED,
  GET_PREFILLED_DATA_FULFILLED,
  GET_PREFILLED_DATA_REJECTED,
  GET_USER_FEATURE_FULFILLED,
  UPDATE_USER_FEATURE_FULFILLED,
} from "../../Constants/user";
import { GET_BALANCE_FULFILLED, GET_BALANCE_REJECTED } from "../../Constants/Balance";
import { GET_ALL_ACCOUNTS_FULFILLED, GET_ALL_ACCOUNTS_REJECTED } from "../../Constants/Account";
import { GET_ALL_CARDS_FULFILLED } from "../../Constants/Cards";
import { ACCOUNT_TRANSACTION_FULFILLED, ACCOUNT_TRANSACTION_REJECTED } from "../../Constants/Transaction";
import { GET_BANK_TRANSFERS_FULFILLED } from "../../Constants/FundsAccount";
import { MODAL_TRANSACTIONS, PLAID_FEATURE } from "../../Constants/Features";
import { ACCOUNT_TAB, TRANSACTION_TAB } from "../../Constants/TabsTitle";

// Own models
import { AlignItems, JustifyContent } from "../../Models/Column";
import { AccountBalance } from "../../Models/BalanceCard";
import { tTransactionAccountArray, tTransactionArray, tTransactionByDay } from "../../Models/TransactionsCard";
import { ObjectCard } from "../../Models/Cards";
import { AccountObject } from "../../Models/Account";
import { BankTransfer, BankTransferStatus } from "../../Models/Funds";
import { SearchValueFormValues } from "../../Models/SearchInput";

// Own utils
import { CustomFormatDateTimeUSI, FormatDateByMonthAndYeayUSI, maskInterbankKey } from "../../Utilities/String";
import { CopyTextToClipboard } from "../../Utilities/CopyTextToClipboard";

// Own services
import CurrencyService from "../../Services/CurrencyService";

// Own components
import Layout from "../../Components/Layout";
import { Column, Container, Row } from "../../Components/Grid";
import Text from "../../Components/Text";
import Tooltip from "../../Components/Tooltip";
import Icon from "../../Components/Icon";
import Title from "../../Components/Title";
import TransactionsCard from "../../Components/TransactionsCard";
import LoaderIcon from "../../Components/LoaderIcon/LoaderIcon.component";
import RoundButton from "../../Components/RoundButton";
import { BankTransfersCard } from "../BankTranfers/Components";
import Tabs from "../../Components/Tabs";
import Input from "../../Components/Input";
import ModalTutorial from "../../Components/ModalTutorial";

// Own styles
import {
  ActionsIcons,
  AvailableBalance,
  CardContainer,
  CardSection,
  ContainerHeaderUSI,
  CurrentBalance,
  CurrentBalanceC,
  InfoBox,
  LabelCard,
  NumberContainer,
  HeaderCurrent,
  ManageAccountContainer,
  EmptySearhSection,
  ContentModal,
  ModalInfo,
  Badge,
  CloseBtn,
  IconTutorial,
} from "./ManageAccount.styled";
import { UpdateUserFeature } from "../../Redux/UpdateUserFeature/UpdateUserFeature.actions";

const Card = () => {
  const theme = useSelector((state: State) => state.theme);
  const [t] = useTranslation("global");
  const lang = useSelector((state: State) => state.language);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sectionTransactions = searchParams.get("transactions");

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [account, setAccount] = useState(false);
  const [showAccountnumber, setShowAccountnumber] = useState(false);
  const [plaidFeature, setPlaidFeature] = useState(false);
  const [accountbalance, setAccountBalance] = useState<AccountBalance>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [transactionsShow, setTransactionsShow] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [transactionsByDate, setTransactionsByDate] = useState<any>([]);
  const [PhysicalCard, setPhysicalCard] = useState<ObjectCard>();
  const [AccountSelected, setAccountSelected] = useState<AccountBalance>();
  const [lastBankTransfer, setLastBankTransfer] = useState([] as Array<BankTransfer>);
  const [activeTab, setActiveTab] = useState<string>(ACCOUNT_TAB);
  const [modalTransactionFeature, setModalTransactionFeature] = useState(false);

  const PersonalData = useSelector(ByUserSliceSelector);

  const personId = useMemo(() => PersonalData.data?.personId, [PersonalData]);

  const userId = useMemo(() => PersonalData.data?.userId, [PersonalData]);

  const typeAccountSelected = useSelector((state: State) => state.SelectTypeAccount);
  const accountIdSelected = typeAccountSelected.selectAccount.id;

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  // List Items for tabs
  const ListItems = [
    {
      id: 1,
      value: ACCOUNT_TAB,
      label: t("TitleTabs.account"),
      isActive: activeTab === ACCOUNT_TAB ? true : false,
    },
    {
      id: 2,
      value: TRANSACTION_TAB,
      label: t("TitleTabs.transactions"),
      isActive: activeTab === TRANSACTION_TAB ? true : false,
      isNew: t("TitleTabs.tabNew"),
    },
  ];

  // Get Cards data
  const cards = useSelector(CardsSelector);

  const compareByCreatedAt = (a: tTransactionAccountArray, b: tTransactionAccountArray): number => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);

    return dateB.getTime() - dateA.getTime();
  };

  // Group By month and year transactions
  const groupByMonthAndYear = (
    array: tTransactionAccountArray[]
  ): { month: string; data: tTransactionAccountArray[] }[] => {
    const groupedByMonth: { [key: string]: tTransactionAccountArray[] } = {};

    array.forEach((item) => {
      const monthYear = item.createdAt.slice(0, 7);
      if (!groupedByMonth[monthYear]) {
        groupedByMonth[monthYear] = [];
      }
      groupedByMonth[monthYear].push(item);
    });

    const result = Object.keys(groupedByMonth).map((key) => ({
      month: key,
      data: groupedByMonth[key],
    }));

    return result;
  };

  const onSubmit: SubmitHandler<SearchValueFormValues> = async (data) => {
    const query = data.searchValue || "";

    const newData = transactionsShow?.filter((element: tTransactionAccountArray) => {
      const result =
        element.title.toLowerCase().includes(query.toLowerCase()) ||
        element.amount.toString().includes(query.toString()) ||
        element.txnType.toLowerCase().includes(query.toLowerCase()) ||
        element.status.toLowerCase().includes(query.toLowerCase());

      return result;
    });

    const groupData = groupByMonthAndYear(newData);

    setTransactionsByDate(groupData);
  };

  const getTransactions = async (personId: string, accountId: string) => {
    const accountTransaction = {
      personId: personId,
      accountId: accountId,
      startTime: "",
      endTime: "",
      limit: 100,
      offset: 0,
    };

    const response = await dispatch(handleAccountTransactions(accountTransaction));

    if (response?.type === ACCOUNT_TRANSACTION_FULFILLED) {
      setIsLoading(false);
      if (response.payload.totalRows !== 0) {
        const combinedTransactions: Array<tTransactionAccountArray> = response.payload?.transactionsByDate.reduce(
          (result: tTransactionAccountArray, item: tTransactionByDay) => {
            return result.concat(item.transactions);
          },
          []
        );
        const transByDate = groupByMonthAndYear(combinedTransactions.slice().sort(compareByCreatedAt));

        setTransactionsByDate(transByDate);
        setTransactionsShow(combinedTransactions.slice().sort(compareByCreatedAt));
      }
    }

    if (response?.type === ACCOUNT_TRANSACTION_REJECTED) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  const getAllCardsDispatch = async (accountId: string, personId: string) => {
    const request = {
      personId: personId,
      accountId: accountId,
    };

    const response = await dispatch(getAllCards(request));

    if (response?.type === GET_ALL_CARDS_FULFILLED) {
      getTransactions(personId, accountId);
    }
  };

  const getBalance = async (personId: string, accountId: string) => {
    const payload = {
      personId: personId,
      accountId: accountId,
    };

    const response = await dispatch(findAccountBalance(payload));

    if (response?.type === GET_BALANCE_FULFILLED) {
      setAccountBalance(response?.payload);

      getAllCardsDispatch(accountId, personId);
    }

    if (response?.type === GET_BALANCE_REJECTED) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  const getAllAccountsDispatch = async (personId: string) => {
    const response = await dispatch(getAllAccounts(personId));

    if (response?.type === GET_ALL_ACCOUNTS_FULFILLED) {
      const account = response.payload?.find((account: AccountObject) => account?.id === accountIdSelected) || {};
      setAccountSelected(account);
      dispatch(
        setCurrentAccount({
          amount: 0,
          data: account,
        })
      );

      getBalance(personId, account.id);
    }

    if (response?.type === GET_ALL_ACCOUNTS_REJECTED) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  const getUserInformation = async () => {
    const response = await dispatch(PrefilledDataAction());

    if (response?.type === GET_PREFILLED_DATA_FULFILLED && personId) {
      setAccount(true);
      getAllAccountsDispatch(personId);
    }

    if (response?.type === GET_PREFILLED_DATA_REJECTED) {
      if (response?.payload?.response.status === 404) {
        setIsError(true);
        setIsLoading(false);
      }
      if (response?.payload?.response.status === 401) {
        return navigate("/");
      }
    }
  };

  const GetByUser = async (userId: string) => {
    const response = await dispatch(ByUserSliceAction(userId));

    if (response?.type === GET_BY_USER_DATA_FULFILLED) {
      getUserInformation();
    }

    if (response?.type === GET_BY_USER_DATA_REJECTED && response.payload?.response?.status === 401) {
      window.location.href = "/";
    }

    if (response?.type === GET_BY_USER_DATA_REJECTED) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  const getBankTransfers = async () => {
    const response = await dispatch(BankTransfersAction({ personId: personId || "" }));

    if (response.type === GET_BANK_TRANSFERS_FULFILLED && response.payload) {
      setLastBankTransfer(response.payload as Array<BankTransfer>);
    }
  };

  const ShowNumber = () => {
    setShowAccountnumber(!showAccountnumber);
  };

  const GetUserFeature = async (userId: string, featureCode: string, setUserFeature: (active: boolean) => void) => {
    const request = {
      userId: userId,
      featureCode: featureCode,
    };
    const response = await dispatch(getUserFeature(request));

    if (response?.type === GET_USER_FEATURE_FULFILLED) {
      setUserFeature(response?.payload?.isActive);
    }
  };

  const updateFeature = async (userId: string, featureCode: string, setUserFeature: (active: boolean) => void) => {
    const payload = {
      userId: userId,
      featureCode: featureCode,
      isActive: false,
    };

    const response = await dispatch(UpdateUserFeature(payload));

    if (response?.type === UPDATE_USER_FEATURE_FULFILLED) {
      if (response.payload?.isUpdated === true) {
        setUserFeature(false);
      }
    }
  };

  const SelectTabActive = (type: string) => {
    if (type === ACCOUNT_TAB) {
      setActiveTab(ACCOUNT_TAB);
    }

    if (type === TRANSACTION_TAB) {
      setActiveTab(TRANSACTION_TAB);
    }
  };

  const showTransactionDetail = (transferOrderId: tTransactionAccountArray) => {
    dispatch(changeTransactionDetail(transferOrderId));

    if (transferOrderId) {
      return navigate("/transactions-detail");
    }
  };

  const showTrasactionsTab = () => {
    window.scrollTo(0, 0);
    setActiveTab(TRANSACTION_TAB);
  };

  const CloseModalTransactions = async () => {
    if (userId) {
      updateFeature(userId, MODAL_TRANSACTIONS, setModalTransactionFeature);
      setModalTransactionFeature(false);
    }
  };

  useEffect(() => {
    if (cards.data.length >= 0) {
      setPhysicalCard(cards?.data.find((card: ObjectCard) => card?.cardType === "physical"));
    }
  }, [cards]);

  useEffect(() => {
    if (userId) {
      GetByUser(userId);
      getBankTransfers();
      GetUserFeature(userId, PLAID_FEATURE, setPlaidFeature);
      GetUserFeature(userId, MODAL_TRANSACTIONS, setModalTransactionFeature);
    }

    if (!isEmpty(sectionTransactions)) {
      setActiveTab(TRANSACTION_TAB);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Layout show={isLoading}>
        <LoaderIcon
          title={t("loaderText.title")}
          text={t("loaderText.subtitle")}
          description={t("loaderText.description")}
        />
      </Layout>
      <Layout show={!isLoading} path="/linked-accounts">
        <Container as={ManageAccountContainer}>
          {/* Tabs Component */}
          <Tabs list={ListItems} selectTabActive={SelectTabActive} />

          {/* Transsaction Tab - content */}
          {activeTab === ACCOUNT_TAB && (
            <>
              <Row>
                <Column span={12}>
                  <Text size={1} color="grey" align="center" margin="16px 0 0 0" weight={400}>
                    {t("Card.infoMessages")}
                  </Text>
                </Column>
                <Column span={12}>
                  <Text size={1} color="black" align="center" margin="4px 0 24px 0" weight={600}>
                    {t("Card.infoMessages2")}
                  </Text>
                </Column>
              </Row>
              <Row>
                <Column span={12}>
                  <Row>
                    {/* Balance Card */}
                    <Column span={12}>
                      <CurrentBalance $accountState={account} as={Row}>
                        <Column span={12} px={2} py={3}>
                          <Row>
                            <Column span={6} xs={12} md={12} sm={12} lg={6} xl={6} as={ContainerHeaderUSI}>
                              <Text size={0.5} color="white" align="left" weight="700" margin="8px 0">
                                {t("Card.balance")}
                              </Text>
                              <Tooltip
                                colorIcon="white"
                                sizeIcon="medium"
                                hasShadow={false}
                                boxInfo={<InfoBoxComponent />}
                              />
                            </Column>
                            <Column
                              span={6}
                              xl={6}
                              xs={12}
                              md={12}
                              sm={12}
                              lg={6}
                              justifyContent={JustifyContent.flexEnd}
                              as={HeaderCurrent}
                            >
                              <Text size={5} color="white" align="left" weight="600" margin="8px 0">
                                {`$ ${CurrencyService.formatNumberString(accountbalance?.availableBalance.toString() || "")}`}
                              </Text>
                            </Column>
                            <Column span={12} justifyContent={JustifyContent.flexEnd} as={AvailableBalance}>
                              <Text size={0.5} color="white" align="left" weight="700" margin="8px 0">
                                {t("Card.CardTitle")}
                              </Text>
                              <Text size={3} color="white" align="left" weight="600" margin="8px 0">
                                {`$ ${CurrencyService.formatNumberString(accountbalance?.currentBalance.toString() || "")}`}
                              </Text>
                            </Column>
                            <Column span={12} justifyContent={JustifyContent.center}>
                              <Text size={1} color="white" weight="600" margin="16px 0 10px 0">
                                {typeAccountSelected && typeAccountSelected.selectAccount.type === "savingAccount"
                                  ? t("balanceCard.savingsAccount")
                                  : t("balanceCard.checkAccount")}
                              </Text>
                            </Column>
                          </Row>
                          <Row as={NumberContainer}>
                            <Column
                              span={6}
                              xl={6}
                              lg={6}
                              xs={12}
                              md={12}
                              sm={12}
                              justifyContent={JustifyContent.flexStart}
                            >
                              <Text size={0.3} color="white" align="left" margin="16px 0 8px 0" weight={600}>
                                {t("Card.CardAccountNumber")}
                              </Text>
                            </Column>
                            <Column
                              span={6}
                              xl={6}
                              lg={6}
                              xs={12}
                              md={12}
                              sm={12}
                              justifyContent={JustifyContent.flexEnd}
                            >
                              <LabelCard>
                                <Text size={1} color="white" margin="0" weight={500}>
                                  {showAccountnumber && AccountSelected
                                    ? AccountSelected.accountNumber
                                    : maskInterbankKey(AccountSelected?.accountNumber as string)}
                                </Text>
                                <ActionsIcons>
                                  <Icon
                                    icon={showAccountnumber ? "eye" : "eyeOff"}
                                    color="white"
                                    fillIcon={false}
                                    size="small"
                                    onClick={ShowNumber}
                                  />
                                  <Icon
                                    icon="copy"
                                    color="white"
                                    fillIcon={false}
                                    size="small"
                                    onClick={() =>
                                      CopyTextToClipboard(AccountSelected ? AccountSelected.accountNumber : "")
                                    }
                                  />
                                </ActionsIcons>
                              </LabelCard>
                            </Column>
                          </Row>
                          <Row>
                            <Column
                              span={6}
                              xl={6}
                              lg={6}
                              xs={12}
                              md={12}
                              sm={12}
                              justifyContent={JustifyContent.flexStart}
                            >
                              <Text size={0.3} color="white" align="left" margin="16px 0 8px 0" weight={600}>
                                {t("Card.CardRoutingNumber")}
                              </Text>
                            </Column>
                            <Column
                              span={6}
                              xl={6}
                              lg={6}
                              xs={12}
                              md={12}
                              sm={12}
                              justifyContent={JustifyContent.flexEnd}
                            >
                              <LabelCard>
                                <Text size={1} color="white" margin="0">
                                  {AccountSelected?.routingNumber}
                                </Text>
                                <Icon
                                  icon="copy"
                                  color="white"
                                  fillIcon={false}
                                  size="small"
                                  onClick={() => CopyTextToClipboard(AccountSelected?.routingNumber)}
                                />
                              </LabelCard>
                            </Column>
                          </Row>
                          {PhysicalCard && PhysicalCard?.last4 && (
                            <Row>
                              <Column span={12} xs={12} my={3}>
                                <CurrentBalanceC>
                                  <CardSection $isActive={true}>
                                    <CardContainer>
                                      <Text size={1} color="white" margin="24px 0" className="card-number" weight="700">
                                        {`**** **** **** ${PhysicalCard.last4}`}
                                      </Text>
                                    </CardContainer>
                                  </CardSection>
                                </CurrentBalanceC>
                              </Column>
                            </Row>
                          )}
                        </Column>
                      </CurrentBalance>
                    </Column>

                    {/* Actions Buttons */}
                    <Column span={12}>
                      <Row>
                        {plaidFeature && (
                          <Column span={3} sm={3} xs={3} md={3} my={5} justifyContent={JustifyContent.center}>
                            <RoundButton
                              icon="plus"
                              colorIcon="white"
                              background="primary"
                              text={t("FundAccount.button")}
                              onClick={() => navigate("/fund-account")}
                              padding="13px"
                              fillIcon={true}
                            />
                          </Column>
                        )}
                        <Column span={3} sm={3} xs={3} md={3} my={5} justifyContent={JustifyContent.center}>
                          <RoundButton
                            icon="send"
                            colorIcon="transparent"
                            fillIcon
                            fillTransparent
                            background="gradient"
                            text={t("Card.buttonExternalServices")}
                            onClick={() => {
                              navigate("/");
                            }}
                            padding="13px"
                          />
                        </Column>
                      </Row>
                    </Column>
                  </Row>
                </Column>
              </Row>

              {plaidFeature && (
                <>
                  <Row>
                    <Column
                      span={12}
                      p={1}
                      pb={2}
                      alignItems={AlignItems.center}
                      justifyContent={JustifyContent.spaceBetween}
                    >
                      <Title
                        size={2}
                        tagName="h2"
                        color="black"
                        align="left"
                        text={t("Funds.BankTransfer.cardTitle")}
                      />
                      <Link to="/bank-transfers">
                        <Text size={0.5} color="solid_light" weight="600">
                          {t("Funds.BankTransfer.cardMore")}
                        </Text>
                      </Link>
                    </Column>
                    <Column span={12}>
                      {lastBankTransfer.length > 0 ? (
                        <BankTransfersCard
                          amount={lastBankTransfer[0].amount}
                          order={`${t("Funds.BankTransfer.textOrder")} #${lastBankTransfer[0].transferNumber}`}
                          status={lastBankTransfer[0].status}
                          action={() => {
                            navigate("/bank-transfers-details", {
                              state: {
                                transferOrderId: lastBankTransfer[0].id,
                                transferStatus: lastBankTransfer[0].status,
                                transferNumber: lastBankTransfer[0].transferNumber,
                              },
                              replace: true,
                            });
                          }}
                        />
                      ) : (
                        <BankTransfersCard
                          title={t("Funds.BankTransfer.emptyCardTitle")}
                          subTitle={t("Funds.BankTransfer.emptyCardSubtitle")}
                          buttonText={t("Funds.BankTransfer.emptyCardButtonText")}
                          description={t("Funds.BankTransfer.emptyCardDesc")}
                          amount={0}
                          order={`${t("Funds.BankTransfer.textOrder")} #123456`}
                          status={BankTransferStatus.DEFAULT}
                          buttonAction={() => navigate("/fund-account")}
                          isEmpty
                        />
                      )}
                    </Column>
                  </Row>
                </>
              )}
              {/* List Transactions */}
              <Row>
                <Column
                  span={12}
                  p={1}
                  pb={2}
                  alignItems={AlignItems.center}
                  justifyContent={JustifyContent.spaceBetween}
                >
                  <Title size={2} tagName="h2" color="black" align="left" text={t("Card.SubTitleRecentTransactions")} />
                  <div onClick={showTrasactionsTab}>
                    <Text size={0.5} color="solid_light" weight="600">
                      {t("Funds.BankTransfer.cardMore")}
                    </Text>
                  </div>
                </Column>
                <Column span={12}>
                  {transactionsShow &&
                    transactionsShow.slice(0, 3).map((transactionAccount: tTransactionAccountArray, index: number) => {
                      return (
                        <TransactionsCard
                          key={index}
                          date={CustomFormatDateTimeUSI(transactionAccount.createdAt, lang.language)}
                          detailText={transactionAccount.status}
                          getDetails={() => {}}
                          operation={transactionAccount.title}
                          typeOperation={transactionAccount.txnType}
                          cost={transactionAccount.amount}
                          txnType={transactionAccount.txnType}
                        />
                      );
                    })}
                  {transactionsShow && transactionsShow.length === 0 && (
                    <>
                      <Column span={12} justifyContent={JustifyContent.center} mt={2}>
                        <Icon icon="cashCancel" size="medium" color="grey" />
                      </Column>
                      <Column span={12} justifyContent={JustifyContent.center} mb={2}>
                        <Text size={0.3} align="center" weight={400} color="grey">
                          {t("Transactions.emptyAllTransaction")}
                        </Text>
                      </Column>
                    </>
                  )}
                </Column>
                {isError && (
                  <Column span={12} mb={2}>
                    <Text size={0.5} align="center" weight={600} color="error">
                      {t("404.subtitle")}
                    </Text>
                  </Column>
                )}
              </Row>
            </>
          )}

          {/* Transsaction Tab - content */}
          {activeTab === TRANSACTION_TAB && (
            <>
              <form onSubmit={handleSubmit(onSubmit)} onKeyUp={handleSubmit(onSubmit)}>
                <Row>
                  <Column span={12} my={2}>
                    <Input
                      typeInput="text"
                      placeholderInput={t("global.search")}
                      label="searchValue"
                      maxLengthInput={30}
                      register={register}
                      onBlur={() => trigger("searchValue")}
                      icon="search"
                      rules={{}}
                      errors={errors}
                    />
                  </Column>
                </Row>
              </form>

              {transactionsByDate &&
                transactionsByDate.map((transaction: tTransactionArray, index: number) => {
                  return (
                    <div key={index}>
                      <Text size={1} align="left" weight={600} color="black">
                        {FormatDateByMonthAndYeayUSI(transaction.month, lang.language)}
                      </Text>

                      {transaction.data.map((transactionAccount: tTransactionAccountArray, index: number) => {
                        return (
                          <TransactionsCard
                            key={index}
                            date={CustomFormatDateTimeUSI(transactionAccount.createdAt, lang.language)}
                            detailText={transactionAccount.status}
                            getDetails={() => showTransactionDetail(transactionAccount)}
                            operation={transactionAccount.title}
                            typeOperation={transactionAccount.txnType}
                            cost={transactionAccount.amount}
                            txnType={transactionAccount.txnType}
                          />
                        );
                      })}
                    </div>
                  );
                })}

              {transactionsByDate && transactionsByDate.length === 0 && (
                <Row as={EmptySearhSection}>
                  <Column span={12} justifyContent={JustifyContent.center}>
                    <Icon icon="search" size="medium" color="black" />
                  </Column>
                  <Column span={12} justifyContent={JustifyContent.center}>
                    <Text size={0.3} align="center" weight={600} color="black">
                      {t("Transactions.emptyTransaction")}
                    </Text>
                  </Column>
                  <Column span={12} justifyContent={JustifyContent.center}>
                    <Text size={0.3} align="center" weight={600} color="black">
                      {t("Transactions.messageTryAgain")}
                    </Text>
                  </Column>
                </Row>
              )}
            </>
          )}
        </Container>
      </Layout>
      <ModalTutorial show={!isLoading && modalTransactionFeature ? true : false}>
        <Container as={ManageAccountContainer}>
          <Row>
            <Column span={12}>
              <ModalInfo>
                <Badge>{t("TitleTabs.tabNew")}</Badge>
                <CloseBtn onClick={CloseModalTransactions}>
                  <Icon icon="cancel" size="medium" color="grey" />
                </CloseBtn>
                <Text size={0.3} color="black" align="left" margin="10px 0" weight={500}>
                  {t("Transactions.newSectionTransaction")}
                </Text>
              </ModalInfo>
            </Column>
            <Column span={12} justifyContent={JustifyContent.flexEnd}>
              <IconTutorial>
                <Icon icon="arrowDownTutorial" size="large" color="white" />
              </IconTutorial>
            </Column>
          </Row>
        </Container>
        <ContentModal>
          <Row>
            <Column span={12}>
              <Container as={ManageAccountContainer}>
                <Row>
                  <Column span={6} justifyContent={JustifyContent.center}>
                    <Text size={1} color="grey02" align="center" margin="12px 0" weight={600}>
                      {t("TitleTabs.account")}
                    </Text>
                  </Column>
                  <Column span={6}>
                    <Text size={1} color="Primary01" align="center" margin="12px 0" weight={600} className="active">
                      {t("TitleTabs.transactions")}
                    </Text>
                  </Column>
                </Row>
              </Container>
            </Column>
          </Row>
        </ContentModal>
      </ModalTutorial>
    </ThemeProvider>
  );
};

const InfoBoxComponent = () => {
  const [t] = useTranslation("global");

  return (
    <InfoBox>
      <Icon icon="circleInfo" color="solid_2" size="medium" />
      <p>
        <span
          dangerouslySetInnerHTML={{
            __html: t("Card.info2"),
          }}
        />
      </p>
      <p>
        <span
          dangerouslySetInnerHTML={{
            __html: t("Card.info1"),
          }}
        />
      </p>
    </InfoBox>
  );
};

export default Card;
