import { InputProps } from "../../Models/inputInterface";
import { Column, Row } from "../Grid";

// Components
import Icon from "../Icon";

// styled
import {
  ContainerText,
  Span,
  InputStyled,
  InputGroup,
  Label,
  IconInput,
} from "./Input.styled";

const Input = ({
  label,
  valueInput,
  typeInput,
  textLabel,
  inputMode,
  placeholderInput,
  disabledInput,
  maxLengthInput,
  register,
  rules,
  onChange,
  errors,
  autoComplete,
  readOnly,
  customError,
  step,
  min,
  max,
  onBlur,
  icon,
}: InputProps) => {
  return (
    <>
      <Row>
        <Column span={12}>
          <InputGroup>
            <InputStyled
              value={valueInput}
              step={step}
              min={min}
              max={max}
              aria-invalid={
                (errors && errors[label]) || !valueInput ? "true" : "false"
              }
              type={typeInput}
              inputMode={inputMode}
              placeholder={placeholderInput}
              autoComplete={autoComplete}
              disabled={disabledInput}
              {...(maxLengthInput ? { maxLength: Number(maxLengthInput) } : {})}
              readOnly={readOnly ? true : false}
              $error={errors[label] || customError}
              required={rules?.required ? true : false}
              {...(register &&
                register(label, {
                  ...rules,
                  onChange,
                }))}
              onBlur={onBlur}
            />
            <Label>{textLabel}</Label>
            {icon && (
              <IconInput>
                <Icon icon={icon} size="medium" color="black" />
              </IconInput>
            )}
          </InputGroup>
        </Column>
      </Row>

      <Row>
        <Column span={12}>
          {customError && !errors[label] && (
            <ContainerText>
              <Icon
                icon={"alertTriangle"}
                color={"error"}
                size="small"
                fillIcon={false}
              />
              <Span $error>{customError}</Span>
            </ContainerText>
          )}
          {errors && errors[label] && (
            <ContainerText>
              <Icon
                icon={"alertTriangle"}
                color={"error"}
                size="small"
                fillIcon={false}
              />
              <Span $error>{errors?.[label].message}</Span>
            </ContainerText>
          )}
        </Column>
      </Row>
    </>
  );
};

export default Input;
