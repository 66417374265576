import styled from "styled-components";
import { ifProp, theme } from "styled-tools";

export const RadioCardStyled = styled.div`
  border-radius: 10px;
  border: 0.5px solid ${theme("Primary_01")};
  box-shadow: 0px 4px 4px 0px rgba(${theme("RGBA_01")}, 0.1);
  padding: 12px 14px;
  height: 104px;
  position: relative;

  span {
    font-family: Poppins;
  }

  .radio-card-title {
    color: ${theme("Content_UX_01")};
    font-weight: 600;
    font-size: 14px;
    line-height: 0.2;
  }

  .radio-card-description {
    color: ${theme("Content_UX_02")};
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  .hidden {
    position: absolute;
    left: 0;
    top: 0;
    rigth: 0;
    bottom: 0;
    width: 100%;
    cursor: pointer;
  }
`;

export const RadioCardSelected = styled(RadioCardStyled)`
  box-shadow: 
    0px 4px 4px 0px rgba(${theme("RGBA_01")}, 0.1), 
    inset 0 0 0 1px ${theme('Primary_01')};
`;

export const RadioCardRow = styled.div`
  height: 100%;
`;

export const RadioCardTitle = styled.div`
  display: flex;

  label {
    margin-top: -3px;
    margin-left: 8px;
  }

  .id-card-icon {
    svg {
      width: 30px;
    }
  }

  .visa-passport-icon {
    svg {
      width: 39px;
    }
  }
`;

export const RadioCardOptions= styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;

  span {
    margin-left: 8px;
  }

  svg {
    rect {
      fill: ${theme("Primary_01")};
      stroke: ${theme("Primary_01")};
    }
  }
`;

export const Radio = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
`;

export const Label = styled.label<{$disabled:boolean}>`
  display: inline-block;
  position: relative;
  padding-left: 6px;
  font-weight: normal;
  cursor: pointer;
  text-align: left;
  width: 100%;

  img {
    position: absolute;
    top: -3px;
    left: -12px;
  }

  span {
    color: ${ifProp("$disabled", theme("Background_04"), theme("Background_02"))};
  }
`;

export const Container = styled.div`
  width: 100%;
  margin: 15px auto;
  position: relative;
  display: block;
`;
