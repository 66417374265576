import styled from "styled-components";
import { theme } from "styled-tools";
// import mediaQuery, { Screen } from "../../../../Utilities/MediaQuery";

export const BankTransfersCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 20px;
  background: ${theme("Background_01")};
  box-shadow: 0px 4px 20px 0px rgba(${theme("RGBA_01")}, 0.15);
  justify-self: center;
  margin-bottom: 24px;

  &:hover {
    cursor: pointer;
  }

  span {
    font-weight: 600;
  }
`;

export const BankTransfersCardPending = styled(BankTransfersCardStyled)`
  box-shadow: 
    0px 4px 20px 0px rgba(${theme("RGBA_01")}, 0.15), 
    inset 0 0 0 2px rgba(${theme('RGB_01')}, 0.4);
`;

export const BankTransfersCardCompleted = styled(BankTransfersCardStyled)`
  box-shadow:
    0px 4px 20px 0px rgba(${theme("RGBA_01")}, 0.15),
    inset 0 0 0 2px rgba(${theme('RGB_02')}, 0.4);
`;

export const BankTransfersCardError = styled(BankTransfersCardStyled)`
  box-shadow:
    0px 4px 20px 0px rgba(${theme("RGBA_01")}, 0.15),
    inset 0 0 0 2px rgba(${theme('RGB_03')}, 0.4);
`;

export const BankTransfersCardDefault = styled(BankTransfersCardStyled)`
  box-shadow:
    0px 4px 20px 0px rgba(${theme("RGBA_01")}, 0.15),
    inset 0 0 0 2px rgba(${theme('RGB_04')}, 0.4);
  
  button {
    font-family: Poppins;
    font-size: 12px;
    color: ${theme("Background_01")};
    background: ${theme("Background_10")};
    border-radius: 50px;
    padding: 2px 10px;
    border: none;
    cursor: pointer;
  }
`;

export const BankTransfersCardItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0;
  background: ${theme("Background_01")};
  justify-self: center;
  border-bottom: 1px solid ${theme("Content_UX_02")};

  &:hover {
    cursor: pointer;
  }

  .bank-transfers-item:first-child > & {
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .bank-transfers-item:last-child > & {
    border-bottom: transparent;
    border-radius: 20px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const BankTransfersCardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div:first-child {
    flex-grow: 1;
  }

  p > span {
    margin-right: 1.3em;
  }
`;

export const BankTransfersCardAmount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BankTransfersCardArrow = styled.div`
  padding-left: 1.3em;
`;
