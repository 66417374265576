import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOnExit
} from "react-plaid-link";
import { useNavigate } from "react-router-dom";

// Own States
import { AppDispatch } from "../../../../Redux/Store";
import { selectorLanguage } from "../../../../Redux/Translate";

// Own components
import Button from "../../../../Components/Button";

// Own Models
import {
  PlaidAccountsUpdateRequest,
  PlaidTokenExchangeRequest,
} from "../../../../Models/Funds";
import { ButtonVariant } from "../../../../Models/buttonInterface";
import { 
  PlaidCreateTokenAction, 
  PlaidExchangeTokenAction, 
  PlaidUpdateAccountAction 
} from "../../../../Redux/FundsAccount/FundsAccount.actions";
import { 
  CREATE_PLAID_TOKEN_FULFILLED, 
  EXCHANGE_PLAID_TOKEN_FULFILLED, 
  EXCHANGE_PLAID_TOKEN_REJECTED, 
  UPDATE_PLAID_ACCOUNT_FULFILLED
} from "../../../../Constants/FundsAccount";

type Props = {
  requiredUpdate?: boolean;
  updateData?: PlaidAccountsUpdateRequest;
  btnText: string;
  btnVariant?: ButtonVariant;
  linkCustomizationName?: "default" | "bannerbank" | "bannerbankspanish";
  btnIcon?: string;
  isOpenAutomatic?: boolean;
  currentAccountId?: string;
  onCompleted: (requestId: string, isUpdated?: boolean) => void;
  onClose?: () => void;
  onLoading?: (active: boolean) => void;
};

const PlaidLink = ({
  requiredUpdate,
  updateData,
  btnText,
  btnVariant = "primary",
  btnIcon,
  isOpenAutomatic,
  linkCustomizationName = "default",
  currentAccountId,
  onCompleted,
  onClose,
  onLoading
}: Props) => {
  const [token, setToken] = useState<string | null>(null);
  const [metadata, setMetada] = useState({} as PlaidTokenExchangeRequest);
  const dispatch = useDispatch<AppDispatch>();
  const lang = useSelector(selectorLanguage);
  const navigate = useNavigate();

  useEffect(() => {
    createLinkToken();
  }, []);

  useEffect(() => {
    if (Object.keys(metadata).length > 0) {
      exchangeToken(metadata);
    }
  }, [metadata]);

  const createLinkToken = async () => {
    let response = null;

    try {
      if (requiredUpdate && updateData) {
        response = await dispatch(PlaidUpdateAccountAction({
          body: updateData,
          languageCode: lang.language,
          customizationName: linkCustomizationName
        }));
      } else {
        response = await dispatch(PlaidCreateTokenAction({
          languageCode: lang.language,
          customizationName: linkCustomizationName
        }));
      }

      if (
        response?.type === CREATE_PLAID_TOKEN_FULFILLED 
        || response?.type === UPDATE_PLAID_ACCOUNT_FULFILLED) {
        setToken(response?.payload?.linkToken);
      }
    } catch (error) {
      navigate("/funds-error", { replace: true });
    }
  };

  const exchangeToken = async (plaidBody: PlaidTokenExchangeRequest) => {
    if (onLoading) {
      onLoading(true);
    }
    const response = await dispatch(
      PlaidExchangeTokenAction({
        body: {
          publicToken: plaidBody.publicToken,
          institutionId: plaidBody.institutionId,
          ...(
            (
              linkCustomizationName === "bannerbank" || 
              linkCustomizationName === "bannerbankspanish" 
            ) && currentAccountId
          ) ? {accountId: currentAccountId} : {}
        },
        languageCode: lang.language
      })
    );

    if (response?.type === EXCHANGE_PLAID_TOKEN_FULFILLED) {
      onCompleted(response.payload?.accountId);
    }

    if (response?.type === EXCHANGE_PLAID_TOKEN_REJECTED) {
      console.log(response.payload.data.errors[0]);
      if (
        response?.payload?.status === 409 &&
        response?.payload?.data?.errors.length &&
        ( response?.payload?.data?.errors[0] === "The account numbers do not match" ||
          response?.payload?.data?.errors[0] === "The account is duplicated" )
      ) {
        navigate("/funds-error", {
          replace: true,
          state: {
            ...response?.payload?.data?.errors['0'] === "The account numbers do not match" ?
            { linkedAccountsNoMatching: true } :
            { isDuplicatedAccount: true } 
          },
        });
      }
    }

    if (onLoading) {
      onLoading(false);
    }
  };

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
      // send public_token to your server
      // https://plaid.com/docs/api/tokens/#token-exchange-flow
      if (!requiredUpdate) {
        setMetada({
          publicToken,
          institutionId: metadata.institution?.institution_id || "",
          accounts: metadata.accounts,
        });
      } else {
        onCompleted("", requiredUpdate);
      }
    },
    []
  );

  const onExit = useCallback<PlaidLinkOnExit>(
    () => {
      if (onClose) {
        return onClose();
      } 
    },
    []
  );

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    onExit,
  };

  const { open, ready } = usePlaidLink(config);

  if (isOpenAutomatic) {
    return open();
  }

  return (
    <>
      <Button
        disabled={!ready}
        type="submit"
        variant={btnVariant}
        sizeButton="large"
        sizeText="medium"
        sizeIcon="medium"
        text={btnText}
        colorIcon="white"
        {...(btnIcon ? { iconButton: "arrowRight" } : {})}
        onClick={() => open()}
      />
      {/* {errorModal} */}
    </>
  );
};

export default PlaidLink;
