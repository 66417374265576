/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useBeneficiaryAdditionalFields } from "@hooks/Payments/useBeneficiaryAdditionalFields";
import { useSubdivision } from "@hooks/Payments/useSubdivision";
import { BeneficiarySelector, BeneficiarySlice } from "@redux/Payments/Beneficiary/Beneficiary.slice";
import { QuotationSelector } from "@redux/Payments/Quotation/Quotation.slice";
import { ThemeProvider } from "styled-components";
import Layout from "@components/Layout";
import { Column, Container, Row } from "@components/Grid";
import Title from "@components/Title";
import { DynamicInput } from "@components/DynamicInput";
import { useLocation } from "react-router";
import { AdditionalField } from "@core/Payments/Common/domain/AdditionalFields";
import { StyledAdditionalFieldsForm } from "./UpdateAdditionalFields.styled";
import Button from "@components/Payments/Button";
import { useBeneficiary } from "@hooks/Payments/useBeneficiary";
import {
  Account,
  BeneficiaryEdit,
  BeneficiaryRecipient,
  isBeneficiaryEdit,
  mapBeneficiaryEditIntoBeneficiaryRecipient,
} from "@core/Payments/Beneficiary/domain/Beneficiary";
import { useModal } from "@hooks/Payments/useModal";
import { AppDispatch, State } from "@redux/Store";
import { usePaymentOrder } from "@hooks/Payments/usePaymentOrders";
import { isEmpty } from "lodash";
import { selectorLanguage } from "@redux/Translate";
import { useNavigate } from "react-router-dom";
import { AlignItems, JustifyContent } from "@models/Column";

export const UpdateAdditionalFields = () => {
  const pathLocation = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const lang = useSelector(selectorLanguage);
  const { missingFields } = (pathLocation.state as {
    missingFields?: AdditionalField[];
  }) || { missingFields: undefined };

  const [t] = useTranslation("global");
  const [tSendMoney] = useTranslation("sendMoney");
  const theme = useSelector((state: State) => state.theme);
  const { currentPaymentDestination, currentDeliveryMethodQuote, paymentMethodSelected, currentPaymentMethod } =
    useSelector(QuotationSelector).data;
  const currentQuoteDestinations = currentDeliveryMethodQuote?.paymentDestinations;
  const { beneficiarySelected } = useSelector(BeneficiarySelector).data;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const transferType = currentDeliveryMethodQuote!.deliveryMethodCode;

  const form = useForm({ mode: "all" });
  const {
    formState: { isValid },
    setValue,
    handleSubmit,
  } = form;

  const { subdivisionList, isLoading: subdivisionsLoading, getSubdivisionOptions } = useSubdivision();
  const {
    isLoading: beneficiaryIsLoading,
    beneficiaryInformationId,
    updateBeneficiaryId,
    getBeneficiaryInformation,
  } = useBeneficiary();
  const {
    velocityModal,
    isLoading: verifyIsLoading,
    verifyErrorModal,
    verifyCurrentSendMoneySelection,
  } = usePaymentOrder();
  const { modal: updateAdditionalFieldsModal, showModal: showAdditionalFieldsModal } = useModal();

  const destinationSelected =
    currentPaymentDestination ||
    currentDeliveryMethodQuote?.paymentDestinations.find(
      (dest) => dest.destination.toLowerCase() === beneficiarySelected?.accountSelected?.bankName?.toLowerCase()
    );

  const { additionalFields: beneficiaryAdditionalFields, isLoading: additionalFieldsLoading } =
    useBeneficiaryAdditionalFields({
      country: beneficiarySelected!.accountSelected?.country || beneficiarySelected!.country!,
      countrySubdivisions: subdivisionList,
      transferType: transferType,
      destinationSelected,
      form,
    });

  const {
    additionalFieldsAppriza,
    isLoading: loadingAdditionalAppriza,
    fetchAdditionalFieldsAppriza,
  } = useBeneficiaryAdditionalFields({
    country: "",
  });

  const isLoading =
    additionalFieldsLoading ||
    subdivisionsLoading ||
    beneficiaryIsLoading ||
    verifyIsLoading ||
    loadingAdditionalAppriza;

  const selectBeneficiary = async (
    beneficiary?: BeneficiaryRecipient,
    account?: Account,
    additionalFields?: Account[]
  ) => {
    const currentAdditionalFields = additionalFields?.find((item) => item.id === account?.id);
    const selected = beneficiary
      ? {
          ...beneficiary,
          accountSelected: {
            ...account,
            additionalFieldInfo: currentAdditionalFields?.additionalFieldInfo,
            rail: currentQuoteDestinations?.find((destination) => destination.destination === account?.bankName)?.rail,
          },
        }
      : undefined;
    dispatch(BeneficiarySlice.actions.setBeneficiarySelected(selected));
  };

  const processSubmit = handleSubmit(async (data) => {
    const formData: Partial<BeneficiaryEdit> = {
      firstName: beneficiaryInformationId?.firstName,
      lastName: beneficiaryInformationId?.lastName,
    };

    if (transferType === "D2B") {
      const accountToUpdate = JSON.parse(JSON.stringify(beneficiarySelected?.accountSelected));
      if (accountToUpdate) {
        accountToUpdate.branch = data.branch || accountToUpdate.branch;
        accountToUpdate.additionalFieldInfo = [
          ...accountToUpdate.additionalFieldInfo,
          ...beneficiaryAdditionalFields
            .filter(
              (field) =>
                missingFields?.find((missingField) => missingField.fieldName === field.fieldName) &&
                field.fieldGroup !== "branch"
            )
            .map((field) => ({
              fieldBelongsTo: field.fieldBelongsTo,
              fieldName: field.fieldName,
              fieldValue: data[field.fieldName],
            })),
        ];
        formData.accounts = [accountToUpdate];
      }
    } else if (transferType === "CPU") {
      formData.additionalField = {
        branch: data.branch || undefined,
        additionalFieldInfo: beneficiaryAdditionalFields
          .filter(
            (field) =>
              missingFields?.find((missingField) => missingField.fieldName === field.fieldName) &&
              field.fieldGroup !== "branch"
          )
          .map((field) => ({
            fieldBelongsTo: field.fieldBelongsTo,
            fieldName: field.fieldName,
            fieldValue: data[field.fieldName],
          })),
      };
    }

    const additionalAccount = formData.accounts?.map((item) => {
      const r = item.additionalFieldInfo?.filter((field) => field.fieldBelongsTo !== "Transaction");

      return {
        ...item,
        additionalFieldInfo: r,
      };
    });

    formData.additionalField = {
      ...formData.additionalField,
      branch: data.branch || null,
      additionalFieldInfo: [
        ...(formData.additionalField?.additionalFieldInfo || []),
        ...additionalFieldsAppriza
          .filter(
            (field) =>
              missingFields?.find((missingField) => missingField.fieldName === field.fieldName) &&
              field.fieldGroup !== "branch"
          )
          .map((field) => ({
            fieldBelongsTo: field.fieldBelongsTo,
            fieldName: field.fieldName,
            fieldValue: data[field.fieldName],
          })),
      ],
    };

    const accountBeneficiary = {
      ...formData,
      accounts: additionalAccount,
    };

    const responseUpdate = await updateBeneficiaryId(accountBeneficiary, beneficiarySelected?.beneficiaryId);

    if (isBeneficiaryEdit(responseUpdate)) {
      const beneficiary = mapBeneficiaryEditIntoBeneficiaryRecipient(responseUpdate as BeneficiaryEdit);

      showAdditionalFieldsModal({
        modalType: "success",
        message: tSendMoney("updateAdditionalFields.success"),
        handleClose: () => {
          selectBeneficiary(
            beneficiary,
            beneficiarySelected?.accountSelected
              ? responseUpdate.accounts?.find((acc) => acc.id === beneficiarySelected.accountSelected?.id)
              : undefined,
            formData.accounts
          );
        },
      });
    } else {
      showAdditionalFieldsModal({
        modalType: "error",
        errorMessage: tSendMoney("updateAdditionalFields.error"),
      });
    }
  });

  useEffect(() => {
    if (lang) {
      if (beneficiarySelected?.country) {
        getSubdivisionOptions(beneficiarySelected?.country, lang.language);
        fetchAdditionalFieldsAppriza(
          transferType === "D2B" ? beneficiarySelected.accountSelected!.country : beneficiarySelected.country
        );
      }
    }
  }, [beneficiarySelected, lang]);

  useEffect(() => {
    if (beneficiarySelected) {
      const onSuccess = () => {
        if (paymentMethodSelected && currentPaymentMethod) {
          navigate("/review-transaction");
        } else {
          navigate("/payments");
        }
      };
      verifyCurrentSendMoneySelection({ onSuccess });
    }
  }, [beneficiarySelected]);

  useEffect(() => {
    if (!isEmpty(subdivisionList)) {
      setValue("destination", destinationSelected?.id);
    }
  }, [subdivisionList]);

  useEffect(() => {
    if (beneficiarySelected?.beneficiaryId) {
      getBeneficiaryInformation(beneficiarySelected.beneficiaryId);
    }
  }, [beneficiarySelected]);

  return (
    <ThemeProvider theme={theme}>
      <Layout textLink={t("buttons.back")} loading={isLoading}>
        <FormProvider {...form}>
          <StyledAdditionalFieldsForm onSubmit={processSubmit}>
            <Container>
              <Row>
                <Column span={12} mb={5}>
                  <Title
                    tagName="h1"
                    text={tSendMoney("updateAdditionalFields.title")}
                    color="black"
                    size={6}
                    align="left"
                  />
                </Column>
              </Row>

              {beneficiaryAdditionalFields &&
                beneficiaryAdditionalFields
                  .filter((field) => missingFields?.find((missingField) => missingField.fieldName === field.fieldName))
                  .map((field) => (
                    <Row key={field.fieldName}>
                      <Column span={12} px={2}>
                        <DynamicInput {...field} />
                      </Column>
                    </Row>
                  ))}

              {additionalFieldsAppriza &&
                additionalFieldsAppriza
                  .filter((field) => missingFields?.find((missingField) => missingField.fieldName === field.fieldName))
                  .map((field) => (
                    <Row key={field.fieldName}>
                      <Column span={12} px={2}>
                        <DynamicInput {...field} />
                      </Column>
                    </Row>
                  ))}
              <Row>
                <Column span={12} alignItems={AlignItems.center} justifyContent={JustifyContent.center}>
                  <Button
                    text={t("Beneficiaries.CreateBeneficiary.Form.Submit")}
                    variant={isValid ? "primary" : "default"}
                    sizeText="large"
                    sizeButton="medium"
                    disabled={!isValid}
                  />
                </Column>
              </Row>
            </Container>
          </StyledAdditionalFieldsForm>
        </FormProvider>
        {updateAdditionalFieldsModal}
        {velocityModal}
        {verifyErrorModal}
      </Layout>
    </ThemeProvider>
  );
};
