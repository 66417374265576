import { FlexContainer } from "../../Flex/Flex.styled";
import Text from "../../Text";
import { GradientCard } from "../../Card/Card.styled";
import Button from "@components/Payments/Button";
import { BeneficiaryRecipient } from "@core/Payments/Beneficiary/domain/Beneficiary";
import { RecipientExchangeRates } from "@core/Payments/Common/domain/ExchangeRates";
import { CSSProperties } from "styled-components";
import theme from "@contexts/ThemeProvider";
import { PropsWithChildren } from "react";

interface RecipientCardProps extends BeneficiaryRecipient, PropsWithChildren {
  id?: string;
  active?: boolean;
  backgroundColor?: CSSProperties["backgroundColor"];
  onClick?: (recipient?: BeneficiaryRecipient) => void;
  onEditClick?: () => void;
}

export const RecipientCard = ({ id, active, children, onClick, ...recipient }: RecipientCardProps) => {
  return (
    <Button id={id} variant="transparent" sizeButton="full" padding="0px" onClick={() => onClick && onClick(recipient)}>
      <GradientCard
        width="100%"
        padding="10px 12px"
        borderWidth={active ? "2px" : "1px"}
        active={active}
        borderColor={theme.Neutral_300}
      >
        <FlexContainer direction="column">
          <FlexContainer justify="space-between" alignItems="center">
            <FlexContainer w="100%" flex="1 1 auto" gap="8px">
              <img
                width={24}
                src={RecipientExchangeRates.find((exchange) => exchange.countryCode === recipient.country)?.icon}
              />
              <FlexContainer direction="column">
                <Text align="left" size={1} lineHeight="21px" weight={500} margin={0} overflow="ellipsis">
                  {recipient.fullName}
                </Text>
              </FlexContainer>
            </FlexContainer>
            {/* <FlexContainer w="content-width" direction="column" alignItems="end">
              <IconFont name="pencil" color="primaryHighlight" size="medium" onClick={onEditClick} />
            </FlexContainer> */}
          </FlexContainer>
          {children}
        </FlexContainer>
      </GradientCard>
    </Button>
  );
};
