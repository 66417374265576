import { createSlice } from "@reduxjs/toolkit";
import { PrefilledDataAction } from "./PrefilledData.actions";
import { State } from "../Store";
import { GET_PREFILLED_DATA } from "../../Constants/user";
import { PrefilledDataState } from "../../Models/PrefilledData";

const initialState: PrefilledDataState = {
  loading: false,
  error: null,
  data: null
}

export const PrefilledDataSlice = createSlice({
  name: GET_PREFILLED_DATA,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PrefilledDataAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PrefilledDataAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(PrefilledDataAction.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export default PrefilledDataSlice.reducer;

export const PrefilledDataSelector = (state: State) => state.prefilledData;
