import { useTranslation } from "react-i18next";

import { transactionConfirmationProps } from "../../Models/tTransactionAccount";

import Icon from "../Icon";
import Title from "../Title";
import Text from "../Text";
import Button from "../Button";

import {
  Container,
  IconContainer,
  InfoContainer,
  Amount,
  Message,
  ContainerButton
} from "./TransactionConfirmation.styled";

const TransactionConfirmation = ({
  title,
  from,
  accountTo,
  to,
  accountFrom,
  amount,
  actionButton
}: transactionConfirmationProps) => {
  const [t] = useTranslation("global");

  return (
    <Container>
      <div>
        <IconContainer>
          <Icon icon="check" size="large" color="white"/>
        </IconContainer>
      </div>
      <Title text={title} tagName="h1" color="black" size={3} />
      <Amount>
        <Title text={amount} tagName="h1" color="primary" size={5} />
      </Amount>
      <InfoContainer>
        <div>
          <Text size={1} weight={600}>
            {t("TransactionConfirmation.from")}
          </Text>
        </div>
        <div>
          <Text size={0.5} margin="0" align="right">
            {from}
          </Text>
          <Text size={0.5} margin="5px 0" align="right">
            {accountFrom}
          </Text>
        </div>
      </InfoContainer>
      <InfoContainer>
        <div>
          <Text size={1} weight={600}>
            {t("TransactionConfirmation.deposit")}
          </Text>
        </div>
        <div>
          <Text size={0.5} margin="0" align="right">
            {to}
          </Text>
          <Text size={0.5} margin="5px 0" align="right">
            {accountTo}
          </Text>
        </div>
      </InfoContainer>
      <Message>
        <Text size={1}>
          <span dangerouslySetInnerHTML={{__html: t("TransactionConfirmation.message")}} />
        </Text>
      </Message>
      <ContainerButton>
        <Button
          type="button"
          variant="primary"
          sizeButton="xlarge"
          sizeText="large"
          text={t("TransactionConfirmation.button")}
          onClick={actionButton}
        />
      </ContainerButton>
    </Container>
  )
}

export default TransactionConfirmation;