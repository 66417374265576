import styled from "styled-components";
import { theme } from "styled-tools";

export const RecentlyUsedTag = styled.div`
  box-sizing: border-box;
  border-radius: 16px;
  padding: 2px 8px;
  text-align: center;
  background-color: ${theme("BackgroundPurple4")};
  min-width: 87px;
  width: fit-content;

  p {
    color: ${theme("Primary_01")};
    font-size: 10px;
  }
`;
