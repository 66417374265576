import { createSlice } from "@reduxjs/toolkit";
import { State } from "../../Store";
import { CountryState } from "@core/Payments/Common/domain/Countries";

const initialState: CountryState = {
  countries: [],
};

export const CountrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    setCountryList(state, { payload }) {
      state.countries = payload;
    },
  },
});

export const countrySelector = (state: State) => state.country;
