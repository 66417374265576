// Components
import Text from "../Text";
import Icon from "../Icon";

// Styles
import { NavItem } from "./NavigationBar.styled";
import { IconTypeKey } from "@models/IconInterface";
import { FlexContainer } from "@components/Flex/Flex.styled";

// Types
type NavigationBarItemProps = {
  text: string;
  isTextGradient?: boolean;
  icon: IconTypeKey;
  path?: string;
  active?: boolean;
  onClick?: () => void;
};

const NavigationBarItem = ({ text, isTextGradient, icon, path, active, onClick }: NavigationBarItemProps) => {
  return (
    <NavItem to={path || window.location.href} active={active} onClick={() => onClick && onClick()}>
      <FlexContainer w="auto" direction="column" alignItems="center">
        <Icon icon={icon} size="medium" color={active ? "primary_01_700" : isTextGradient ? "white" : "grey-blue"} />
        <Text size={0.1} lineHeight="10px" color="grey-blue" gradient={isTextGradient} weight={600} margin={0}>
          {text}
        </Text>
      </FlexContainer>
    </NavItem>
  );
};

export default NavigationBarItem;
