import { useStatus } from "./useStatus";
import { ApiRequestError } from "@core/Common/domain/ApiRequestError";
import { SquareApiPaymentOrderRepository } from "@core/Payments/PaymentOrders/infrastructure/ApiPaymentWithSquareRepository";
import { createPaymentOrderSquare } from "@core/Payments/PaymentOrders/application/createPaymentOrder";

export const useSquarePaymentOrder = () => {
  const { isLoading, setStatus, setError } = useStatus();

  const createSquarePayment = async (
    idTransfer: string,
    paymentMethodFee: number
  ) => {
    setStatus("loading");
    try {
      const response = await createPaymentOrderSquare(
        SquareApiPaymentOrderRepository()
      )(idTransfer, paymentMethodFee);

      setStatus("idle");
      return response;
    } catch (error) {
      setStatus("error");
      if (error && (error as ApiRequestError).errors) {
        setError(error as ApiRequestError);
      }
    }
  };

  return {
    isLoading,
    createSquarePayment,
  };
};
