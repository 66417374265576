import { ReactElement, ReactNode, ReactPortal, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import {
  AuthorizeAccountsModalProps,
  BeneficiaryModalProps,
  CPUInstructionsModalProps,
  ErrorModalProps,
  ModalVariant,
  PartialModalVariantRecord,
  SelectPlaidAccountModalProps,
  UnitellerDisclaimerModalProps,
  VelocityLimitModalProps,
  WalkthroughModalProps,
  ZelleInstructionsModalProps,
  ZelleTransactionModalProps,
  VenmoInstructionsModalProps,
  VenmoTransactionModalProps,
  pendingTransactionsModalProps,
  RecipientModalProps,
} from "@models/ModalInterface";
import Modal from "@components/Modal";
import { ErrorModal } from "@components/Modal/ErrorModal/ErrorModal.component";
import { GeoLocationModal } from "@components/Modal/GeoLocationModal/GeoLocationModal.component";
import { GeoLocationRetryModal } from "@components/Modal/GeoLocationRetryModal/GeoLocationRetryModal.component";
import { VelocityLimitModal } from "@components/Modal/VelocityLimitModal/VelocityLimitModal.component";
import { SuccessModal } from "@components/Modal/SuccessModal/SuccessModal.component";
import { UnitellerDisclaimerModal } from "@components/Modal/DisclaimerModal/UnitellerDisclaimerModal";
import { BottomModal } from "@components/Modal/BottomModal/BottomModal.component";
import { ConfirmationModal } from "@components/Modal/ConfirmationModal/ConfirmationModal.component";
import { SelectBeneficiaryAccountModal } from "@components/Modal/BeneficiaryModal/SelectBeneficiaryAccountModal.component";
import { AddBeneficiaryAccountModal } from "@components/Modal/BeneficiaryModal/AddBeneficiaryAccountModal.component";
import { ZelleInstructionsModal } from "@components/Modal/ZelleInstructionsModal/ZelleInstructionsModal.component";
import { LegalInfoModal } from "@components/Modal/LegalInfoModal/LegalInfoModal.component";
import { CPUInstructionsModal } from "@components/Modal/CPUInstructionsModal/CPUInstructionsModal.component";
import { ZelleConfirmationModal } from "@components/Modal/ZelleInstructionsModal/ZelleConfirmationModal.component";
import { VenmoInstructionsModal } from "@components/Modal/VenmoModal/VenmoInstructionsModal.component";
import { VenmoConfirmationModal } from "@components/Modal/VenmoModal/VenmoConfirmationModal.component";
import { PaymentMethodSelectModal } from "@components/Modal/PaymentMethodSelectModal/PaymentMethodSelectModal.component";
import { SelectPlaidAccountModal } from "@components/Modal/SelectPlaidAccountModal/SelectPlaidAccountModal.component";
import { KYCStarterModal } from "@components/Modal/KYC/KYCStarterModal.component";
import { RecipientModal } from "@components/Modal/BeneficiaryModal/RecipientModal.component";

interface UseModalProps {
  modalType?: ModalVariant;
}

export const useModal = (props?: UseModalProps) => {
  const [openModal, setOpenModal] = useState(true);
  const [modal, setModal] = useState<ReactPortal | ReactElement | null>(null);

  const showModal = ({
    id,
    modalType = props?.modalType || "default",
    content,
    title,
    message,
    errorMessage,
    codesMessages,
    pageName,
    usePortal = true,
    show = true,
    handleConfirm,
    handleClose,
    ...restProps
  }: {
    id?: string;
    modalType?: ModalVariant;
    content?: ReactNode;
    message?: string;
    errorMessage?: string;
    userPhone?: string;
    usePortal?: boolean;
    handleClose?: (confirm?: boolean) => void;
  } & Partial<ErrorModalProps> &
    Partial<VelocityLimitModalProps> &
    Partial<UnitellerDisclaimerModalProps> &
    Partial<WalkthroughModalProps> &
    Partial<BeneficiaryModalProps> &
    Partial<RecipientModalProps> &
    Partial<ZelleInstructionsModalProps> &
    Partial<ZelleTransactionModalProps> &
    Partial<VenmoTransactionModalProps> &
    Partial<VenmoInstructionsModalProps> &
    Partial<pendingTransactionsModalProps> &
    Partial<SelectPlaidAccountModalProps> &
    Partial<AuthorizeAccountsModalProps> &
    Partial<CPUInstructionsModalProps>) => {
    setOpenModal(show);
    // helper variable to always show modal on showModal function call
    let internalOpen = show;

    const variantComponentMap: PartialModalVariantRecord = {
      default: Modal,
      success: SuccessModal,
      error: ErrorModal,
      geolocation: GeoLocationModal,
      geolocationRetry: GeoLocationRetryModal,
      velocityLimit: VelocityLimitModal,
      unitellerDisclaimer: UnitellerDisclaimerModal,
      bottomModal: BottomModal,
      confirmationModal: ConfirmationModal,
      selectBeneficiaryAccountModal: SelectBeneficiaryAccountModal,
      addBeneficiaryAccountModal: AddBeneficiaryAccountModal,
      recipientModal: RecipientModal,
      zelleInstructionsModal: ZelleInstructionsModal,
      zelleConfirmationModal: ZelleConfirmationModal,
      legalInfoModal: LegalInfoModal,
      venmoInstructionsModal: VenmoInstructionsModal,
      venmoConfirmationModal: VenmoConfirmationModal,
      cpuInstructionsModal: CPUInstructionsModal,
      paymentMethodSelectModal: PaymentMethodSelectModal,
      selectPlaidAccountModal: SelectPlaidAccountModal,
      kycStarterModal: KYCStarterModal,
    };

    const VariantComponent = modalType ? variantComponentMap[modalType] : Modal;

    if (VariantComponent) {
      const element = (
        <VariantComponent
          id={id}
          show={internalOpen || openModal}
          title={title}
          message={message}
          {...(modalType === "error" ? { errorMessage } : {})}
          handleClose={(confirm) => {
            setOpenModal(false);
            internalOpen = false;
            handleClose && handleClose(confirm);
          }}
          handleConfirm={handleConfirm}
          codesMessages={codesMessages}
          pageName={pageName}
          {...restProps}
        >
          {content}
        </VariantComponent>
      );
      setModal(usePortal ? createPortal(element, document.body) : element);
    }
  };

  useEffect(() => {
    if (!openModal) {
      setModal(null);
    }
  }, [openModal]);

  return { modal, showModal, setOpenModal };
};
