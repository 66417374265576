import styled from "styled-components";
import { theme } from "styled-tools";

export const ContainerManage = styled.div`
  max-width: 420px;
  min-width: 200px;
`;

export const ManageCard = styled.div`
  display: flex;
  padding: 24px 20px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 12px;
  background: ${theme("Background_01")};
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
`;

export const BtnCollapse = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const PaymentItem = styled.div<{$type?: string}>`
  position: relative;
  padding-left: 36px;
  padding-bottom: 24px;

  :before {
    position: absolute;
    content: "";
    background-color: ${props => props.$type === "success" ? props.theme.Green_06 : props.$type === "disabled" ? props.theme.Grey_10 : props.theme.Primary_01};
    border-radius: 4px;
    left: 12px;
    bottom: 4px;
    height: calc(100% - 16px);
    width: 2px;
    padding-bottom: 15px;
  }

  :last-child {
    :before {
      display: none;
    }
  }
`;
