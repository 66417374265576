import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_BRANCHES } from "../../Constants/user";
import { Branch, BranchesResponse } from "../../Models/Branches";
import { defaultErrorMessage } from "../../Constants/Requests";
import { AxiosError } from "axios";

// Own services Api
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";

export const BranchesSliceAction = createAsyncThunk<Branch[], string, { rejectValue: string }>(
  GET_BRANCHES,
  async (userId: string, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get<BranchesResponse>(`/branch/${userId}`);
      return response.data.data;
    } catch (error) { 
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
