import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";
import { BranchesSliceAction } from "./Branches.actions";
import { BranchesState } from "../../Models/Branches";
import { GET_BRANCHES } from "../../Constants/user";

const initialState: BranchesState = {
  loading: false,
  error: null,
  data: null
}

export const BranchesSlice = createSlice({
  name: GET_BRANCHES,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(BranchesSliceAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(BranchesSliceAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(BranchesSliceAction.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export default BranchesSlice.reducer;

export const BranchesSliceSelector = (state: State) => state.branches;
