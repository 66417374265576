import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

import { AppDispatch, State } from "../../../Redux/Store";

import { UpdateUserFeature } from "../../../Redux/UpdateUserFeature/UpdateUserFeature.actions";

import { UPDATE_USER_FEATURE_FULFILLED, UPDATE_USER_FEATURE_REJECTED } from "../../../Constants/user";
import { UPDATE_SENDOLA } from "../../../Constants/Features";

import Layout from "../../../Components/Layout";
import { ByUserSliceSelector } from "../../../Redux/ByUser/ByUser.slice";
import LoaderIcon from "../../../Components/LoaderIcon/LoaderIcon.component";

const AccountUpdate = () => {
  const theme = useSelector((state: State) => state.theme);
  const { t } = useTranslation("global");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const PersonalData = useSelector(ByUserSliceSelector);
  const userId = useMemo(() =>
    PersonalData.data?.userId || ""
  ,[PersonalData]);

  const [isLoading, setIsLoading] = useState(true);

  const changeFeature = async(userId: string) => {
    const payload = {
      userId: userId,
      featureCode: UPDATE_SENDOLA,
      isActive: false
    };

    const response = await dispatch(UpdateUserFeature(payload));

    if (response?.type === UPDATE_USER_FEATURE_FULFILLED) {
      if (response.payload?.isUpdated === true) {
        setIsLoading(false);
        navigate("/");
      }
    }

    if (response?.type === UPDATE_USER_FEATURE_REJECTED) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (!isEmpty(userId)) {
      changeFeature(userId);
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Layout show={isLoading}>
        <LoaderIcon
          title={t("loaderText.title")}
          text={t("loaderText.subtitle")}
          description={t("loaderText.description")}
        />
      </Layout>
    </ThemeProvider>
  )
}

export default AccountUpdate;