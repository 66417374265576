import styled from "styled-components";
import { CSSObjectWithLabel, StylesConfig, Theme, ThemeConfig } from "react-select";
import { theme as themeStyled } from "styled-tools";
import theme from "../../Contexts/ThemeProvider";
import { SelectOption } from "../../Models/Select";

export const ErrorContainer = styled.div`
  color: ${themeStyled("Red_08")};
  display: flex;
  align-items: end;
  padding: 0 8px;
`;

export const ErrorMessage = styled.div`
  font-size: 16px;
  margin-left: 8px;
`;

export const Label = styled.label`
  display: block;
  width: 100%;
  color: ${themeStyled("Content_UX_02")};
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  font-family: "Montserrat";
  line-height: normal;
  padding-bottom: 8px;
  padding-left: 0px;
  text-align: left;
`;

export const selectStyles: StylesConfig<SelectOption> = {
  control: (): CSSObjectWithLabel => ({
    padding: "8px 10px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "8px",
    height: "fit-content",
    boxSizing: "border-box",
    display: "flex",
    textAlign: "left",
    fontFamily: "Poppins",
    borderColor: theme.Background_03,
    ":focus-within": {
      borderWidth: "2px",
      borderColor: theme.Primary_01,
    },
  }),
  menu: () => ({
    borderStyle: "solid",
    borderRadius: "8px",
    borderColor: theme.Background_03,
    fontFamily: "Poppins",
    borderWidth: "1px",
  }),
};

export const selectStylesGlobalPayments: StylesConfig<SelectOption> = {
  control: (): CSSObjectWithLabel => ({
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "8px",
    height: "fit-content",
    boxSizing: "border-box",
    display: "flex",
    textAlign: "left",
    margin: "0 auto",
    borderColor: theme.Background_03,
    fontFamily: "Poppins",
    ":focus-within": {
      borderWidth: "2px",
      borderColor: theme.Primary_01,
    },
  }),
  menu: () => ({
    flex: "1 1",
    justifyContent: "flex-start",
    borderStyle: "solid",
    borderRadius: "8px",
    borderColor: theme.Background_03,
    fontFamily: "Poppins",
    borderWidth: "1px",
    margin: "0 auto",
    maxWidth: "326px",
    width: "100%",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? theme.Primary_18 : theme.Background_01,
    display: "flex",
    justifyContent: "flex-start",
    margin: "0",
    ":hover": {
      color: theme.Background_01,
      backgroundColor: theme.Primary_18,
      opacity: 0.7,
    },
    ":focus": {
      color: theme.Background_01,
      backgroundColor: theme.Primary_18,
    },
    ":active": {
      color: theme.Background_01,
      backgroundColor: theme.Primary_18,
    },
  }),
  groupHeading: () => ({
    flex: "1 1",
    color: theme.Content_UX_01,
    fontSize: "16px",
    fontFamily: "Poppins",
    margin: "8px 12px",
  }),
};

export const currencySelectStyles: StylesConfig<SelectOption> = {
  container: (base, props) => ({
    ...selectStylesGlobalPayments.container?.call(this, base, props),
    display: !props.selectProps.menuIsOpen ? "flex" : "block",
    maxWidth: props.selectProps.menuIsOpen ? "100%" : "unset",
    minWidth: props.selectProps.menuIsOpen ? "200px" : "unset",
    backgroundColor: theme.Background_01,
    flex: "1 1 auto",
    position: props.selectProps.menuIsOpen ? "absolute" : "relative",
    alignItems: "center",
    boxSizing: "initial",
    fontFamily: "Poppins",
    textAlign: "left",
    marginRight: "auto",
    width: "100%",
    padding: props.selectProps.menuIsOpen ? "7px" : base.padding,
    borderRadius: props.selectProps.menuIsOpen ? "24px 24px 0px 0px" : "24px",
    border: props.selectProps.menuIsOpen ? `1px solid ${theme.Background_03}` : undefined,
  }),
  control: (base, props) => ({
    ...selectStylesGlobalPayments.control?.call(this, base, props),
    ...(!props.selectProps.menuIsOpen ? { border: "none", padding: 0, width: "max-content", margin: 0 } : {}),
    alignItems: "center",
    padding: "0 8px",
    ":focus-within": {
      borderWidth: "2px",
      borderColor: theme.Primary_01,
    },
  }),
  valueContainer: (base, props) => ({
    ...selectStylesGlobalPayments.valueContainer?.call(this, base, props),
    width: "max-content",
    fontSize: "16px",
    fontWeight: 500,
    padding: !props.selectProps.menuIsOpen ? 0 : base.padding,
  }),
  singleValue: (base, props) => ({
    ...base,
    display: props.selectProps.menuIsOpen ? "none" : base.display,
    margin: "0",
  }),
  input: (base, props) => ({
    ...base,
    margin: 0,
    padding: 0,
    zIndex: !props.selectProps.menuIsOpen ? "-1" : base.zIndex,
    width: !props.selectProps.menuIsOpen ? 0 : base.width,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? theme.Primary_18 : theme.Background_01,
    display: "flex",
    justifyContent: "flex-start",
    margin: "0",
    ":hover": {
      color: theme.Background_01,
      backgroundColor: theme.Primary_18,
      opacity: 0.7,
    },
    ":focus": {
      color: theme.Background_01,
      backgroundColor: theme.Primary_18,
    },
    ":active": {
      color: theme.Background_01,
      backgroundColor: theme.Primary_18,
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: "0px",
  }),
  groupHeading: () => ({
    flex: "1 1",
    color: theme.Content_UX_01,
    fontSize: "16px",
    fontFamily: "Poppins",
    margin: "8px 12px",
  }),
};

export const currencySelectRTLStyles: StylesConfig<SelectOption> = {
  ...currencySelectStyles,
  container: (base, props) => ({
    ...currencySelectStyles.container?.call(this, base, props),
    right: props.selectProps.menuIsOpen ? 0 : "unset",
    top: props.selectProps.menuIsOpen ? "-20px" : "unset",
  }),
};

export const selectStylesCountryCatalogs = {
  control: (): CSSObjectWithLabel => ({
    borderWidth: "0",
    borderStyle: "solid",
    padding: "0",
    height: "fit-content",
    boxSizing: "border-box",
    display: "flex",
    textAlign: "left",
    fontWeight: "500",
    margin: "0 auto",
    borderColor: theme.Background_03,
    maxWidth: "326px",
    ":focus-within": {
      borderWidth: "0px",
    },
  }),
  menu: () => ({
    flex: "1 1",
    justifyContent: "flex-start",
    borderStyle: "solid",
    borderRadius: "8px",
    borderColor: theme.Background_03,
    borderWidth: "0",
    margin: "0 auto",
    padding: "0 8px",
    maxWidth: "326px",
    width: "100%",
  }),
  // eslint-disable-next-line
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? theme.Primary_18 : theme.Background_01,
    display: "flex",
    justifyContent: "flex-start",
    margin: "0",
    ":hover": {
      color: theme.Background_01,
      backgroundColor: theme.Primary_18,
      opacity: 0.7,
    },
    ":focus": {
      color: theme.Background_01,
      backgroundColor: theme.Primary_18,
    },
    ":active": {
      color: theme.Background_01,
      backgroundColor: theme.Primary_18,
    },
  }),
};

export const selectTheme: ThemeConfig = (defaultTheme): Theme => ({
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    primary: theme.Background_02,
  },
});
