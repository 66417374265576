import styled from "styled-components";
import { theme } from "styled-tools";

import Text from "../../Components/Text";

export const PaymentItem = styled.div<{$type?: string}>`
  position: relative;
  padding-left: 36px;
  padding-bottom: 24px;
  opacity: 1;
  transition: opacity 2s ease-in-out;

  :before {
    position: absolute;
    content: "";
    background-color: ${props => props.$type === "success" ? props.theme.Green_06 : props.$type === "disabled" ? props.theme.Grey_10 : props.theme.Primary_01};
    border-radius: 4px;
    left: 12px;
    bottom: 4px;
    height: ${props => props.$type === "success" ? "calc(100% - 48px)" : "calc(100% - 16px)"} ;
    width: 2px;
    padding-bottom: 15px;
  }

  :last-child {
    :before {
      display: none;
    }
  }

  &.disabled {
    :before {
      background-color: ${theme("Content_UX_02")};
      opacity: 0.8;
    }

  }

  .disabled {
    ~ p {
      color: ${theme("Content_UX_02")} !important;
      opacity: 0.5;
    }
  }

  .bank-transfer-date {
    font-size: 10px;
  }
`;

export const TransfersItemBullet = styled.div<{$type?: string}>`
  background-color: ${theme("Background_12")};
  box-shadow:
    0 0 0 4px ${theme("Background_12")},
    inset 0 0 0 2px ${props => props.$type === "disabled" ? props.theme.Grey_10 : props.theme.Primary_01};
  height: 24px;
  width: 24px;
  position: absolute;
  border-radius: 50%;
  left: 1px;

  :before {
    content: "";
    border-radius: 50%;
    position: absolute;
    background-color: ${props => props.$type === "disabled" ? props.theme.Grey_10 : props.theme.Primary_01};
    height: 12px;
    width: 12px;
    transform: translate(50%, 50%);
    left: 0;
  }

  &:last-child {
    display: none;
  }

  &.bank-transfer-bullet.disabled {
    box-shadow:
      0 0 0 4px ${theme("Background_12")},
      inset 0 0 0 2px ${theme("Content_UX_02")};
    
    :before {
      background-color: ${theme("Content_UX_02")};
      opacity: 0.8;
    }
  }
`;

export const CustomText = styled(Text)<{
  status?: string;
}>`
  font-family: Poppins;
  ${(props) => {
    switch (props.status) {
      case "completed":
        return `color: ${props.theme.Green_01}; `;
      case "failed":
        return `color: ${props.theme.Red_10}; `;
      default:
        return;
    }
  }};
`;

export const TransfersItemBulletIcon = styled.div<{$type?: string}>`
  position: absolute;
  left: 1px;

  &> div > svg  {
    rect {
      fill: ${theme("Green_06")};
      stroke: ${theme("Green_06")};
    }
  }
`;