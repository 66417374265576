import { createClient } from "@services/API/GlobalPaymentsApiClient";
import { PaymentMethodRepository } from "../domain/PaymentMethodRepository";
import { PaymentMethodsResponse } from "../domain/PaymentMethods";

const client = createClient();
const prefixService = "paymentmethods";

export const createApiPaymentMethodRepository = (): PaymentMethodRepository => {
  return {
    getPaymentMethods,
  };
};

const getPaymentMethods = async () => {
  const response = await client.get<PaymentMethodsResponse>(`${prefixService}`);
  response.data.sendolaPay = response.data.sendolaPay?.map((method) => ({
    ...method,
    paymentSource: "Sendola Card",
  }));
  if (response.data.plaid?.accounts) {
    response.data.plaid.accounts = response.data.plaid?.accounts.map(
      (method) => ({ ...method, paymentSource: "Sendola Plaid" })
    );
  }
  if (response.data.coppel) {
    response.data.coppel = response.data.coppel.map((method) => ({
      ...method,
      paymentSource: "Coppel",
    }));
  }
  if (response.data.square) {
    response.data.square.paymentSource = "Square";
  }
  return response.data;
};
