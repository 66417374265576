import styled from "styled-components";
import { theme } from "styled-tools";

export const RowIntructions = styled.div`
  background-color: ${theme("Background_01")};
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid ${theme("Background_15")};
  padding: 12px 16px;
  margin: 24px 0;

  .text-strong {
    color: ${theme("VenmoColor")};
  }
`;

export const RowSteps = styled.div`
  margin: 16px 0;
`;

export const TextCopy = styled.div`
  display: flex;

  p {
    border-bottom: 1px solid ${theme("Primary_500")};

    &:hover {
      color: ${theme("ZelleColor")};
      border-bottom: none;
    }
  }
`;

export const ArrowBack = styled.div`
  position: absolute;
  top: 12px;
`;