import { createSlice } from "@reduxjs/toolkit";
import { getRecipientsAction } from "./Recipients.actions";
import { State } from "../Store";
import { RecipientsState } from "../../Models/Recipients";

const initialState: RecipientsState = {
  loading: false,
  error: null,
  data: null
};

export const getRecipientsSlice = createSlice({
  name: "getRecipients",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRecipientsAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRecipientsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getRecipientsAction.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export const getRecipientsSelector = (state: State) => state.getRecipients;
