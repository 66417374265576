import { createContext, useContext } from "react";
import { tUseAuth } from "../../Models/tUseAuth";
import { useAuth, userDefaultValue } from "./UseAuth";

const authDefaultValue: tUseAuth = {
  user: userDefaultValue,
};

export const AuthContext = createContext<tUseAuth>(authDefaultValue);

type tAuthProvider = {
  children: React.ReactNode;
};

export default function AuthProvider(props: tAuthProvider) {
  const auth = useAuth();

  return (
    <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
  );
}

export const useAuthContext = () => useContext(AuthContext);
