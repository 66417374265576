import { createSlice } from "@reduxjs/toolkit";
import { getCatalogsAllCountryAction } from "./CatalogsCountry.actions";
import { State } from "../Store";
import { AllCountriesState } from "../../Models/Countries";

const initialState: AllCountriesState = {
  loading: false,
  error: null,
  data: null
};

export const getCatalogsAllCountrySlice = createSlice({
  name: "getCatalogsAllCountry",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCatalogsAllCountryAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCatalogsAllCountryAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getCatalogsAllCountryAction.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export const getCatalogsAllCountrySelector = (state: State) => state.getCatalogsAllCountry;
