import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { SUBMIT_COMPLETE_ONBOARDING } from "../../Constants/KYCStatus";

/**
 * Method to submit complete onboarding
 */
export const SubmitCompleteOnboardingAction = createAsyncThunk(
  SUBMIT_COMPLETE_ONBOARDING,
  async (_, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post("/persons/onboarding-complete");
      return response.data.data;
    } catch(error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);