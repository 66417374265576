// Own styles
import { TitleStyled } from "./Title.styled";
import { Props } from "../../Models/TitleInterface";

const Title = (props: Props) => {
  const { text, tagName, size, color, align } = props;

  return (
    <TitleStyled tagName={tagName} size={size} color={color} align={align}>
      {text}
    </TitleStyled>
  );
};

export default Title;
