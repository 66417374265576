import Modal from "../Modal.component";
import { BottomModalWrapper } from "../BottomModal/BottomModal.styled";
import { ModalProps } from "@models/ModalInterface";
import { FlexContainer } from "@components/Flex/Flex.styled";
import Text from "@components/Text";
import VerifyIdentityImage from "@assets/Img/identity-verify.png";
import { useTranslation } from "react-i18next";
import { SendolaSecondaryColorSpan } from "@components/Text/Text.styled";
import IconShape from "@components/IconShape";
import Button from "@components/Payments/Button";
import IconFont from "@components/IconFont";

export const KYCStarterModal = (props: ModalProps) => {
  const [t] = useTranslation("global");

  return (
    <Modal {...props} hiddenCloseIcon={false} as={BottomModalWrapper}>
      <FlexContainer direction="column" alignItems="center" m="0 0 8px 0">
        <Text align="center" size={2} weight={600} lineHeight="27px">
          {t("notificationModal.verifyIdentity.title")}
        </Text>
        <img src={VerifyIdentityImage} width={89} />
      </FlexContainer>
      <FlexContainer justify="center" m="0 0 16px 0">
        <Text size={0.5} weight={400} lineHeight="24px" color="neutral600" margin={0}>
          {t("notificationModal.verifyIdentity.paragraph1")}
        </Text>
      </FlexContainer>
      <FlexContainer direction="column" alignItems="center" gap="12.3px">
        <FlexContainer justify="center">
          <Text color="primary_01_700" size={0.5} lineHeight="21px" weight={500}>
            <SendolaSecondaryColorSpan
              dangerouslySetInnerHTML={{ __html: t("notificationModal.verifyIdentity.firstLevel") }}
            />
          </Text>
        </FlexContainer>
        <FlexContainer p="0 26px" alignItems="center" gap="10.25px">
          <IconShape
            icon="passport"
            border="rounded"
            width={32.8}
            height={32.8}
            iconSize="medium"
            bgColor="primary700"
            iconColor="white"
          />
          <Text size={0.5} weight={500} lineHeight="21px">
            {t("notificationModal.verifyIdentity.listItems.passport")}
          </Text>
        </FlexContainer>
        <FlexContainer mw="304px" justify="center" alignItems="center" gap="15px">
          <hr />
          <FlexContainer justify="center" w="auto" flex="1 1 auto">
            <Text align="center" color="neutral600" margin={0}>
              {t("global.or")}
            </Text>
          </FlexContainer>
          <hr />
        </FlexContainer>
        <FlexContainer justify="center">
          <Text color="primary_01_700" size={0.5} lineHeight="21px" weight={500}>
            <SendolaSecondaryColorSpan
              dangerouslySetInnerHTML={{ __html: t("notificationModal.verifyIdentity.secondLevel") }}
            />
          </Text>
        </FlexContainer>
        <FlexContainer p="0 26px" alignItems="center" gap="10.25px">
          <IconShape
            icon="user-license"
            border="rounded"
            width={32.8}
            height={32.8}
            iconSize="xmedium"
            bgColor="primary700"
            iconColor="white"
          />
          <Text size={0.5} weight={500} lineHeight="21px">
            {t("notificationModal.verifyIdentity.listItems.license")}
          </Text>
        </FlexContainer>
        <FlexContainer p="0 26px" alignItems="center" gap="10.25px">
          <IconShape
            icon="user-id-card"
            border="rounded"
            width={32.8}
            height={32.8}
            iconSize="xmedium"
            bgColor="primary700"
            iconColor="white"
          />
          <Text size={0.5} weight={500} lineHeight="21px">
            {t("notificationModal.verifyIdentity.listItems.idCard")}
          </Text>
        </FlexContainer>
        <FlexContainer justify="center" m="20px 0">
          <Button
            variant="primary"
            padding="16px 65px"
            sizeButton="fit"
            withShadow
            onClick={() => {
              props.handleConfirm && props.handleConfirm();
              props.handleClose && props.handleClose();
            }}
          >
            <FlexContainer alignItems="center" gap="15px">
              <Text size={1} weight={600} lineHeight="16px" color="white" margin={0}>
                {t("notificationModal.verifyIdentity.btnText")}
              </Text>
              <IconFont name="arrow-right" size="medium" />
            </FlexContainer>
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
