import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

// Own reducers
import { themeSlice } from "../Theme";
import { languageSlice } from "../Translate";
import { AuthSlice } from "../Auth/Auth.slice";
import { transactionDetailSlice } from "../TransactionDetail";
import { accounTransactionSlice } from "../AccountTransactions/AccountTransactions.slice";
import { PersonalDataSlice } from "../PersonalData/PersonalData.slice";
import { ByUserSlice } from "../ByUser/ByUser.slice";
import { TokenValidationSlice } from "../TokenValidation/TokenValidation.slice";
import { GetPayrollPdfSlice } from "../PayrollPdf/PayrollPdf.slice";
import { AccountsSlice } from "../Accounts/Accounts.slice";
import { AccountBalanceSlice } from "../AccountBalance/AccountBalance.slice";
import { CardsSlice } from "../Cards/Cards.slice";
import { OnboardingSlice } from "../Onboarding/Onboarding.slice";
import { PrefilledDataSlice } from "../PrefilledData/PrefilledData.slice";
import { typeAccountSlice } from "../SelectTypeAccount";
import { VeriffIDVSlice } from "../Veriff/Veriff.slice";
import { UploadDocumentsSlice } from "../Documents/Documents.slice";
import { HasSendolaOneSlice } from "../HasSendolaOne/HasSendolaOne.slice";
import { SubmitKYCSlice } from "../SubmitKYC/SubmitKYC.slice";
import { UpdateUserFeatureSlice } from "../UpdateUserFeature/UpdateUserFeature.slice";
import { UserFeatureSlice } from "../UserFeatures/UserFeatures.slice";
import { CustomerConfigSlice } from "../CustomerConfig/CustomerConfig.slice";
import { BranchesSlice } from "../Branches/Branches.slice";
import { visaDocumentSlice } from "../Visa/Visa.slice";
import { getKCYStatuseSlice } from "../GetKYCStatus/GetKYCStatus.slice";
import { saveAddressSlice } from "../USAddress";
import { GetPersonsSlice } from "../GetPersons/GetPersons.slice";
import { GetPendingSSNSlice } from "../PendingSSN/PendingSSN.slice";
import { IdvSSNSlice } from "../IdvSSN/IdvSSN.slice";
import { PayrollDataSlice } from "../Payroll/PayrollData.slice";
import { PersonPayrollDataSlice } from "../PersonPayrollInfo/PersonPayrollInfo.slice";
import { PdfShippingUpdateSlice } from "../PdfShippingUpdate/PdfShippingUpdate.slice";
import { GetDirectDepositSlice } from "../CheckDirectDeposit/CheckDirectDeposit.slice";
import { UploadCashInSlice } from "../CashIn/CashIn.slice";
import { QuotationSlice } from "../Payments/Quotation/Quotation.slice";
import { FundsAccountDataSlice } from "../FundsAccount/FundsAccount.slice";
import { NationalitySlice } from "../Nationality";
import { VisaPhotoVeriffSlice } from "../VisaPhoto/VisaPhoto.slice";
import { ModalTutorialsSlice } from "../ModalTutorials";
import { CoppelAccessSlice } from "../CoppelAccess/CoppelAccess.slice";
import { UpdateCoppelAccessSlice } from "../UpdateCoppelAccess/UpdateCoppelAccess.slice";
import { GetProofAddressDocumentSlice } from "../ProofAddressDocument/ProofAddressDocument.slice";
import { ProofOfAddressTypeSlice } from "../ProofOfAddressType";
import { GetProofAddressRequiredSlice } from "../ProofAddressRequired/ProofAddressRequired.slice";
import { PostProofAddressSlice } from "../ProofAddressSubmit/ProofAddressSubmit.slice";
import { AddressOptionSlice } from "../AddressOption";
import { getRecipientsSlice } from "../Recipients/Recipients.slice";
import { AssignmentUserFeatureSlice } from "../AssigmentFeature/AssigmentFeature.slice";
import { getLocationsGPSlice } from "../LocationsGP/LocationsGP.slice";
import { getCatalogsAllCountrySlice } from "../CatalogsCountry/CatalogsCountry.slice";
import { veriffIntegrationSlice } from "../GetIntegrationStatus/GetIntegrationStatus.slice";
import { getCellphoneSlice } from "../Cellphone/Cellphone.slice";
import { getCellphoneDetailsSlice } from "../CellphoneDetails/CellphoneDetails.slice";
import { SubmitCompleteOnboardingSlice } from "../SubmitCompleteOnboarding/SubmitCompleteOnboarding.slice";
import { GetIdvIsRequiredSlice } from "../IDVRequired/IDVRequired.slice";
import { PathPaymentsSlice } from "../PathPayments";
import { GetSelfieStatusSlice } from "../GetSelfie/GetSelfie.slice";
import { GetAmountTransactionSlice } from "../GetAmountTransaction/GetAmountTransaction.slice";
import { accountPaymentOrdersSlice } from "../PaymentOrders/PaymentOrders.slice";
import { InvitationCodeSlice } from "../InvitationCode/InvitationCode.slice";
import { GetLanguageSettingSlice } from "../GetLanguageSettings/LanguageSettings.slice";
import { PatchLanguageSettingSlice } from "../PatchLanguageSettings/PatchLanguageSettings.slice";
import { ZelleModalSlice } from "../ZelleModal";
import { VenmoModalSlice } from "../VenmoModal";
import { BeneficiarySlice } from "../Payments/Beneficiary";
import { CountrySlice } from "@redux/Payments/Country/Country.slice";
import { PersonsKYCSlice } from "@redux/Payments/PersonsKYC";
import { UserIdSlice } from "@redux/Payments/User/User.slice";
import { paymentOrderSlice } from "@redux/Payments/PaymentOrder";
import { PaymentOrderVerifySlice } from "@redux/Payments/PaymentOrder/PaymentOrderVerify.slice";
import { SendolaPayOrderSlice } from "@redux/Payments/PaymentOrder/SendolaPayOrder.slice";
import { PaymentLinkedAccountSlice } from "@redux/Payments/PaymentLinkedAccount/PaymentLinkedAccount.slice";
import { PaymentAchSlice } from "@redux/Payments/PaymentACH";
import { GeolocationSlice } from "@redux/Payments/Geolocation/Geolocation.slice";
import { TransferOrdersSlice } from "@redux/Payments/TransferOrders";
import { globalConfigSlice } from "@redux/Payments/GlobalConfig/GlobalConfig.slice";
import { CouponsSlice } from "@redux/Payments/Coupons/Coupons.slice";

const rootReducer = combineReducers({
  [AuthSlice.name]: AuthSlice.reducer,
  [AddressOptionSlice.name]: AddressOptionSlice.reducer,
  [AssignmentUserFeatureSlice.name]: AssignmentUserFeatureSlice.reducer,
  [PersonalDataSlice.name]: PersonalDataSlice.reducer,
  [AccountBalanceSlice.name]: AccountBalanceSlice.reducer,
  [ByUserSlice.name]: ByUserSlice.reducer,
  [HasSendolaOneSlice.name]: HasSendolaOneSlice.reducer,
  [PrefilledDataSlice.name]: PrefilledDataSlice.reducer,
  [OnboardingSlice.name]: OnboardingSlice.reducer,
  [languageSlice.name]: languageSlice.reducer,
  [getLocationsGPSlice.name]: getLocationsGPSlice.reducer,
  [SubmitKYCSlice.name]: SubmitKYCSlice.reducer,
  [SubmitCompleteOnboardingSlice.name]: SubmitCompleteOnboardingSlice.reducer,
  [themeSlice.name]: themeSlice.reducer,
  [transactionDetailSlice.name]: transactionDetailSlice.reducer,
  [accounTransactionSlice.name]: accounTransactionSlice.reducer,
  [TokenValidationSlice.name]: TokenValidationSlice.reducer,
  [GetIdvIsRequiredSlice.name]: GetIdvIsRequiredSlice.reducer,
  [PathPaymentsSlice.name]: PathPaymentsSlice.reducer,
  [GetPayrollPdfSlice.name]: GetPayrollPdfSlice.reducer,
  [getCatalogsAllCountrySlice.name]: getCatalogsAllCountrySlice.reducer,
  [getKCYStatuseSlice.name]: getKCYStatuseSlice.reducer,
  [veriffIntegrationSlice.name]: veriffIntegrationSlice.reducer,
  [GetPendingSSNSlice.name]: GetPendingSSNSlice.reducer,
  [GetProofAddressRequiredSlice.name]: GetProofAddressRequiredSlice.reducer,
  [GetProofAddressDocumentSlice.name]: GetProofAddressDocumentSlice.reducer,
  [GetSelfieStatusSlice.name]: GetSelfieStatusSlice.reducer,
  [ProofOfAddressTypeSlice.name]: ProofOfAddressTypeSlice.reducer,
  [PostProofAddressSlice.name]: PostProofAddressSlice.reducer,
  [GetLanguageSettingSlice.name]: GetLanguageSettingSlice.reducer,
  [PatchLanguageSettingSlice.name]: PatchLanguageSettingSlice.reducer,
  [GetPersonsSlice.name]: GetPersonsSlice.reducer,
  [GetAmountTransactionSlice.name]: GetAmountTransactionSlice.reducer,
  [AccountsSlice.name]: AccountsSlice.reducer,
  [CardsSlice.name]: CardsSlice.reducer,
  [saveAddressSlice.name]: saveAddressSlice.reducer,
  [typeAccountSlice.name]: typeAccountSlice.reducer,
  [UploadDocumentsSlice.name]: UploadDocumentsSlice.reducer,
  [UpdateUserFeatureSlice.name]: UpdateUserFeatureSlice.reducer,
  [UserFeatureSlice.name]: UserFeatureSlice.reducer,
  [IdvSSNSlice.name]: IdvSSNSlice.reducer,
  [VeriffIDVSlice.name]: VeriffIDVSlice.reducer,
  [visaDocumentSlice.name]: visaDocumentSlice.reducer,
  [CustomerConfigSlice.name]: CustomerConfigSlice.reducer,
  [BranchesSlice.name]: BranchesSlice.reducer,
  [PayrollDataSlice.name]: PayrollDataSlice.reducer,
  [PersonPayrollDataSlice.name]: PersonPayrollDataSlice.reducer,
  [PdfShippingUpdateSlice.name]: PdfShippingUpdateSlice.reducer,
  [GetDirectDepositSlice.name]: GetDirectDepositSlice.reducer,
  [UploadCashInSlice.name]: UploadCashInSlice.reducer,
  [QuotationSlice.name]: QuotationSlice.reducer,
  [FundsAccountDataSlice.name]: FundsAccountDataSlice.reducer,
  [NationalitySlice.name]: NationalitySlice.reducer,
  [VisaPhotoVeriffSlice.name]: VisaPhotoVeriffSlice.reducer,
  [ModalTutorialsSlice.name]: ModalTutorialsSlice.reducer,
  [CoppelAccessSlice.name]: CoppelAccessSlice.reducer,
  [UpdateCoppelAccessSlice.name]: UpdateCoppelAccessSlice.reducer,
  [getRecipientsSlice.name]: getRecipientsSlice.reducer,
  [getCellphoneSlice.name]: getCellphoneSlice.reducer,
  [getCellphoneDetailsSlice.name]: getCellphoneDetailsSlice.reducer,
  [accountPaymentOrdersSlice.name]: accountPaymentOrdersSlice.reducer,
  [InvitationCodeSlice.name]: InvitationCodeSlice.reducer,
  [ZelleModalSlice.name]: ZelleModalSlice.reducer,
  [VenmoModalSlice.name]: VenmoModalSlice.reducer,
  [BeneficiarySlice.name]: BeneficiarySlice.reducer,
  [CountrySlice.name]: CountrySlice.reducer,
  [PersonsKYCSlice.name]: PersonsKYCSlice.reducer,
  [UserIdSlice.name]: UserIdSlice.reducer,
  [CouponsSlice.name]: CouponsSlice.reducer,
  [paymentOrderSlice.name]: paymentOrderSlice.reducer,
  [PaymentOrderVerifySlice.name]: PaymentOrderVerifySlice.reducer,
  [SendolaPayOrderSlice.name]: SendolaPayOrderSlice.reducer,
  [PaymentLinkedAccountSlice.name]: PaymentLinkedAccountSlice.reducer,
  [PaymentAchSlice.name]: PaymentAchSlice.reducer,
  [GeolocationSlice.name]: GeolocationSlice.reducer,
  [TransferOrdersSlice.name]: TransferOrdersSlice.reducer,
  [globalConfigSlice.name]: globalConfigSlice.reducer,
});

const persistConfig = {
  key: "Sendola-WebApp",
  storage,
  whitelist: [
    AuthSlice.name,
    AccountsSlice.name,
    AddressOptionSlice.name,
    OnboardingSlice.name,
    ByUserSlice.name,
    languageSlice.name,
    getKCYStatuseSlice.name,
    saveAddressSlice.name,
    transactionDetailSlice.name,
    typeAccountSlice.name,
    NationalitySlice.name,
    ModalTutorialsSlice.name,
    PathPaymentsSlice.name,
    ProofOfAddressTypeSlice.name,
    getCellphoneSlice.name,
    QuotationSlice.name,
    BeneficiarySlice.name,
    CountrySlice.name,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type State = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
