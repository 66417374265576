import { createSlice } from "@reduxjs/toolkit";
import { VeriffIDV } from "./Veriff.actions";
import { State } from "../Store";
import { VeriffState } from "../../Models/Veriff";

const initialState: VeriffState = {
  loading: false,
  error: null,
  data: null
};

export const VeriffIDVSlice = createSlice({
  name: "VeriffIDV",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(VeriffIDV.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(VeriffIDV.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      .addCase(VeriffIDV.rejected, (state, action:any) => {
        state.loading = false;
        state.data = null;
        state.error = action?.payload?.response;
      });
  },
});

export const veriffIDVSelector = (state: State) => state.VeriffIDV;
