import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

// Own Components
import Icon from "../Icon";
import Text from "../Text";

// Styles
import { 
  LinkedCardOptionsBtn, 
  LinkedCardDeleteContainer, 
  LinkedCardDeleteBtn, 
} from "./LinkedAccountCard.styled";

type Props = {
  onShowDeleteModal?: () => void;
}

const LinkedAccountDeleteButton = ({ onShowDeleteModal }: Props) => {
  const [t] = useTranslation("global");
  const[showSubmenu, setShowSubmenu] = useState(false);
  const menuRef = useRef<HTMLButtonElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef && !menuRef.current?.contains(event.target as HTMLElement))
      setShowSubmenu(false); 
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
   
    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  });

  return (
    <>
      <LinkedCardDeleteContainer>
        <LinkedCardOptionsBtn
          ref={menuRef}
          onClick={() => setShowSubmenu(!showSubmenu)}
        >
          <Icon icon="options" color="white" size="medium" />
        </LinkedCardOptionsBtn>

        {showSubmenu && (
          <LinkedCardDeleteBtn>
            <button onClick={() => {
              if (onShowDeleteModal) {
                onShowDeleteModal()
              }
              setShowSubmenu(false);
            }}>
              <Text
                tagName="span"
                size={1}
                weight={500}
                align="center"
                color="black"
                margin="0"
              >
                {t("LinkedAccounts.deleteAccountTitle")}
              </Text>
              <Icon icon="trash" color="error" size="medium" />
            </button>
          </LinkedCardDeleteBtn>
        )}
      </LinkedCardDeleteContainer>
    </>
  );
}

export default LinkedAccountDeleteButton