import styled from "styled-components";
import { theme } from "styled-tools";

export const BankTransfersHeaderStatus = styled.div<{
  status: string
}>`
  display: flex;
  align-items: center;

  ${(props) => {
    switch (props.status) {
      case "completed":
        return `> div > svg {
          rect {
            fill: ${props.theme.Green_01}; 
            stroke: ${props.theme.Green_01}; 
          };

          path {
            stroke: ${props.theme.Green_01}; 
          }
        }`;
      case "pending":
        return `> div > svg {
          rect {
            fill: ${props.theme.Primary_08}; 
            stroke: ${props.theme.Primary_08}; 
          };

          path {
            stroke: ${props.theme.Primary_08}; 
          }
        }`;
      case "processing":
        return `> div > svg {
          rect {
            fill: ${props.theme.Primary_08}; 
            stroke: ${props.theme.Primary_08}; 
          };

          path {
            stroke: ${props.theme.Primary_08}; 
          }
        }`;
      default:
        return;
    }
  }};
`;

export const BankTransfersItemBulletIcon = styled.div`
  position: absolute;
  left: 1px;

 &.disabled > div > svg  {
    rect {
      fill: ${theme("Content_UX_02")};
      stroke: ${theme("Content_UX_02")};
      opacity: 0.3;
    }
  }
`;

export const BankTransferHeaderContent = styled.div`
  display: flex;
  justify-content: center;
`;
