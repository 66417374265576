import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

// Own styles
import { FlexContainer } from "@components/Flex/Flex.styled";
import { CouponBarContainer, CouponInputStyled, ButtonStyled, ContainerText, Span } from "./CouponInput.styled";
import Icon from "@components/Icon";

export interface CouponInputProps {
  handleInputValue: (inputValue: string) => void;
  hasError: string | undefined;
  warningLabel?: string;
  errorLabel: string;
  msgError?: string;
  handleSubmit: () => void;
}

export const CouponInput = (props: CouponInputProps) => {
  const [tSendMoney] = useTranslation("sendMoney");
  const [couponCode, setCouponCode] = useState<string>("");
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const { handleInputValue, handleSubmit, hasError, msgError } = props;
  const errorsCode = ["4001", "4002", "4003", "5002", "4004"];

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.toUpperCase().trim();
    handleInputValue(inputValue);
    setCouponCode(inputValue);

    if (inputValue.length >= 6 && inputValue.length < 18) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  };

  const handleSubmitButton = () => {
    handleSubmit();
  };

  return (
    <>
      <CouponBarContainer>
        <FlexContainer w="85%">
          <CouponInputStyled $isError={hasError || ""}>
            <input
              placeholder={tSendMoney("Coupons.inputText")}
              value={couponCode}
              minLength={6}
              maxLength={18}
              onChange={(e) => handleInputChange(e)}
            />
          </CouponInputStyled>
        </FlexContainer>
        <FlexContainer w="15%">
          <ButtonStyled disabled={!canSubmit} onClick={handleSubmitButton}>
            {tSendMoney("Coupons.applyText")}
          </ButtonStyled>
        </FlexContainer>
      </CouponBarContainer>
      {errorsCode.includes(hasError || "") && hasError === "4003" && (
        <ContainerText>
          <Icon icon="alertTriangle" color="warning" size="small" />
          <Span>
            {hasError === "4003" && tSendMoney("Coupons.errorsCoupons.4003")} {msgError}
          </Span>
        </ContainerText>
      )}
      {hasError && errorsCode.includes(hasError || "") && hasError !== "4003" && (
        <ContainerText>
          <Icon icon="alertTriangle" color="error" size="small" />
          <Span $error>
            {hasError === "4001" && tSendMoney("Coupons.errorsCoupons.4001")}
            {hasError === "4002" && tSendMoney("Coupons.errorsCoupons.4002")}
            {hasError === "5001" && tSendMoney("Coupons.errorsCoupons.5001")}
            {hasError === "5002" && tSendMoney("Coupons.errorsCoupons.5002")}
            {hasError === "4004" && tSendMoney("Coupons.errorsCoupons.4004")}
          </Span>
        </ContainerText>
      )}
    </>
  );
};
