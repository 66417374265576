import { allowedNumbers } from "@constants/Regexp";
import { SelectOption } from "@models/Select";
import { Destination } from "@core/Payments/Common/domain/Destination";
import { AdditionalFieldData, AdditionalFieldInfo } from "./BeneficiaryForm";

export type BeneficiariesPaginated = {
  preferredRecipients?: BeneficiaryRecipient[] | null;
  recipients?: BeneficiaryRecipient[] | null;
  pagination: {
    page: number;
    perPage: number;
    total: number;
  };
};

export type BeneficiaryRecipient = {
  beneficiaryId: string;
  fullName: string;
  email?: string;
  phoneNumber?: string;
  alias: string;
  country: string;
  account: Account[];
  accountSelected?: Account;
  additionalField?: AdditionalFieldData;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isBeneficiaryRecipient(obj: any): obj is BeneficiaryRecipient {
  return (
    "beneficiaryId" in obj &&
    typeof obj.beneficiaryId === "string" &&
    "fullName" in obj &&
    typeof obj.fullName === "string" &&
    "account" in obj &&
    typeof obj.account === "object"
  );
}

export function mapBeneficiaryEditIntoBeneficiaryRecipient(
  edit: BeneficiaryEdit
): BeneficiaryRecipient {
  return {
    beneficiaryId: edit.beneficiaryId,
    fullName: edit.fullName,
    alias: edit.alias,
    country: edit.country,
    email: edit.email,
    phoneNumber: edit.phoneNumber,
    account: edit.accounts ?? [],
    accountSelected: edit.accountSelected || edit.accounts?.[0],
    additionalField: edit.additionalField,
  };
}

export type Account = {
  id: string;
  index?: number;
  accountName?: string;
  accountNumber: string;
  accountTypeId: string;
  bankName: string;
  rail?: string;
  country: string;
  destination?: string;
  destinationId: string;
  destinationList?: Array<SelectOption & Destination>;
  subdivisionList?: SelectOption[];
} & AdditionalFieldData;

export interface BeneficiarySelect {
  beneficiaryId: string;
  fullName: string;
  email?: string;
  phoneNumber?: string;
  alias: string;
  country: string;
  account: Account[];
  accountSelected?: Account;
  additionalField?: AdditionalFieldData;
}

export interface BeneficiaryEdit {
  beneficiaryId: string;
  fullName: string;
  country: string;
  firstName: string;
  middleName: string;
  lastName: string;
  alias: string;
  dateOfBirth: string;
  phoneNumber: string;
  email: string;
  address: string;
  addressExtra: string;
  city: string;
  subnational: string;
  postalCode: string;
  accounts?: Account[];
  additionalField?: AdditionalFieldData;
  accountsGrouped?: Map<string, Account[]>;
  accountSelected?: Account;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isBeneficiaryEdit(obj: any): obj is BeneficiaryEdit {
  return (
    "beneficiaryId" in obj &&
    typeof obj.beneficiaryId === "string" &&
    "firstName" in obj &&
    typeof obj.firstName === "string" &&
    "lastName" in obj &&
    typeof obj.lastName === "string" &&
    "accounts" in obj &&
    typeof obj.accounts === "object"
  );
}

export function mapBeneficiaryRecipientIntoBeneficiaryEdit(
  recipient: BeneficiaryRecipient
): BeneficiaryEdit {
  return {
    beneficiaryId: recipient.beneficiaryId,
    fullName: recipient.fullName,
    alias: recipient.alias,
    country: recipient.country,
    email: recipient.email ?? "",
    phoneNumber: recipient.phoneNumber ?? "",
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: "",
    address: "",
    addressExtra: "",
    city: "",
    subnational: "",
    postalCode: "",
    accounts: recipient.account ?? [],
    accountSelected: recipient.accountSelected,
    additionalField: recipient.additionalField,
    accountsGrouped: groupAccounts(recipient.account ?? []),
  };
}

export interface BeneficiaryUpdate {
  body: Partial<BeneficiaryEdit>;
  idBeneficiary: string | undefined;
}

export interface BeneficiaryInfoUpdate {
  firstName: string;
  middleName: string;
  lastName: string;
  alias: string;
  dateOfBirth: string;
  phoneNumber: string;
  email: string;
  address: string;
  addressExtra: string;
  city: string;
  subnational: string;
  country: string;
  postalCode: string;
  accountBanks: AccountsUpdate[];
  additionalField?: AdditionalFieldData;
  [x: string]: unknown;
}

export interface AccountsUpdate {
  accountId: string;
  accountNumber: string;
  branch?: string;
  additionalFieldInfo?: AdditionalFieldInfo[];
  ifscCode?: string;
}

export const formatDOBString = (dateString: string) => {
  const [day, month, year] = dateString.split("/");
  const formattedDay = day.padStart(2, "0");
  const formattedMonth = month.padStart(2, "0");
  return `${formattedDay}/${formattedMonth}/${year}`;
};

export const isValidAccountWithMessage = (account: string, country: string) => {
  if (country === "MEX") {
    return account.length === 18 && allowedNumbers.test(account);
  }

  return true;
};

export const groupAccounts = (accounts: Account[]) => {
  return accounts.reduce(
    (groups, currentAccount, index) => {
      const destinationAccounts =
        groups?.get(currentAccount.destinationId) || [];
      destinationAccounts.push({ ...currentAccount, index });
      groups?.set(currentAccount.destinationId, destinationAccounts);

      return groups;
    },
    new Map([]) as Map<string, Account[]>
  );
};
