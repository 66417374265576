import { useState } from "react";

import { getLinkedAccounts } from "@core/LinkedAccounts/application/getLinkedAccounts";
import { LinkedAccount } from "@core/LinkedAccounts/domain/LinkedAccount";
import { createApiLinkedAccountRepository } from "@core/LinkedAccounts/infrastructure/ApiLinkedAccountRepository";
import { ApiRequestError } from "@core/Common/domain/ApiRequestError";

import { useStatus } from "./useStatus";

export const useLinkedAccounts = (personId: string) => {
  const { status, isLoading, hasError, error, setStatus, setError } = useStatus();
  const [accounts, setAccounts] = useState<LinkedAccount[]>([]);

  const getAccounts = async () => {
    setStatus("loading");

    try {
      const response = await getLinkedAccounts(createApiLinkedAccountRepository())(personId);
      setAccounts(response.data);
      setStatus("idle");
    } catch (error) {
      setStatus("error");

      if (
        (error as ApiRequestError).errors ||
        (error as ApiRequestError).error
      ) {
        setError(error as ApiRequestError);
      }
    }
  }

  return {
    status,
    isLoading,
    hasError,
    error,
    accounts,
    getAccounts
  }
}