import styled from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { theme } from "styled-tools";

export const CardDetails = styled.div`
  background-color: ${theme("Background_01")};
  box-sizing: border-box;
  border-bottom: 1px solid ${theme("Grey_03")};
  height: 90px;
  padding: 16px 0;
  margin: 16px 0;
  width: 100%;
`;

export const IconSection = styled.div`
  background: ${theme("Background_02")};
  border-radius: 20px;
  height: 24px;
  padding: 16px;
  width: 24px;

  div {
    height: 24px;
    width: 24px;

    svg {
      height: 24px;
      width: 24px;
    }
  }
`;

export const AmountContainerMobile = styled.div`
  display: none;
  box-sizing: border-box;

  ${mediaQuery[Screen.mobile]} {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
`;

export const ColunmIcon = styled.div`
  align-items: center;
  display: flex;
  justify-content: start;
`;

export const CostSectionMobile = styled.div`
  position: relative;

  p {
    text-align: right;
  }

  div {
    position: absolute;
    top: -20px;
    right: 0;
  }
`;

export const LocalMoney = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 25px;

  span {
    color: ${theme("Content_UX_02")};
    font-size: 16px;
  }
`;

export const GridAlignCenter = styled.div`
  display: grid;
  align-items: center;
`;

export const AmountContainerDesktop = styled.div`
  display: grid;
  align-items: center;

  ${mediaQuery[Screen.sm]} {
    display: none;
  }

  ${mediaQuery[Screen.mobile]} {
    display: none;
  }
`;
