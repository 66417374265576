import { createClient } from "@services/API/GlobalPaymentsApiClient";

import { Quote } from "../domain/Quote";
import { QuoteRepository } from "../domain/QuoteRepository";

const cliente = createClient();

export const createApiQuoteRepository = (): QuoteRepository => {
  return { getQuoteWithFees, getQuoteWithFeesAndTiers };
};

const getQuoteWithFees: QuoteRepository["getQuoteWithFees"] = async (
  countryCode,
  currency,
  amount,
  payerQuote,
  channel
) => {
  let payerCode = payerQuote;
  if (channel) {
    payerCode = `${channel}-${payerQuote}`;
  }
  const response = await cliente.get<Quote>(
    `Quote/${countryCode}/${currency}/${amount}/${payerCode}`,
    {}
  );
  return response.data;
};

const getQuoteWithFeesAndTiers = async (
  countryCode: string,
  currency: string,
  amount: number,
  payerQuote?: string
) => {
  const response = await cliente.get<Quote>(
    `Quote/tiers/${countryCode}/${currency}/${amount}/${payerQuote}`
  );
  return response.data;
};
