import styled from "styled-components";
import { theme } from "styled-tools";

export const DetailSection = styled.div`
  background-color: ${theme("Background_12")};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin: 24px 0;
  max-width: 600px;
  width: 100%;
`;

export const ContactContainer = styled.div`
  max-width: 660px;
  width: 100%;
`;

export const ContactSection = styled.div`
  margin-top: 120px;
  margin-bottom: 24px;
  max-width: 660px;
  width: 100%;
`;
