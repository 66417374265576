import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

// Own states
import { ThemeProvider } from "styled-components";
import { AppDispatch, State } from "../../../Redux/Store";
import { UpdateEmailSliceAction } from "../../../Redux/Email/Email.actions";
import { ByUserSliceSelector } from "../../../Redux/ByUser/ByUser.slice";
import { PersonalDataSliceSelector } from "../../../Redux/PersonalData/PersonalData.slice";
import { PrefilledDataSelector } from "../../../Redux/PrefilledData/PrefilledData.slice";
import { setSteps } from "../../../Redux/Onboarding/Onboarding.slice";

// Own constants
import { UPDATE_USER_EMAIL_FULFILLED, UPDATE_USER_EMAIL_REJECTED } from "../../../Constants/user";

// Own models
import { EmailForm } from "../../../Models/Email";
import { Step } from "../../../Models/Onboarding";

// Own components
import { FillerContainer, Row, Column } from "../../../Components/Grid";
import Title from "../../../Components/Title";
import Layout from "../../../Components/Layout";
import Text from "../../../Components/Text";
import Input from "../../../Components/Input";
import Button from "../../../Components/Button";
import StepsRoutes from "../../../Components/StepsRoutes";
import { StepsContainer } from "../../KYC/LegalName/LegalName.styled";
import { allowedEmail } from "../../../Constants/Regexp";

export default function Email() {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const theme = useSelector((state: State) => state.theme);
  const PersonalData = useSelector(PersonalDataSliceSelector);
  const ByUser = useSelector(ByUserSliceSelector);
  const PrefilledData = useSelector(PrefilledDataSelector);
  const dispatch = useDispatch<AppDispatch>();

  const personId = useMemo(() =>
    PersonalData.data?.personId || ByUser.data?.personId,
  [PersonalData, ByUser]);
  const defaultEmail = useMemo(() =>
    PrefilledData.data?.email,
  [PrefilledData])

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<EmailForm>({
    mode: "onChange",
    defaultValues: {
      email: defaultEmail || ''
    }
  })

  const onSubmit = async (data: EmailForm) => {
    if (!personId) {
      setIsError(true);
      return
    }
    setIsLoading(true);

    const response = await dispatch(UpdateEmailSliceAction({
      personId,
      email: data.email
    }))

    if (response?.type === UPDATE_USER_EMAIL_FULFILLED) {
      setIsLoading(false);
      dispatch(setSteps({
        lastStep: Step.personEmail,
        nextStep: Step.selectIdvFlow
      }))
      navigate("/kyc-ssn");
    }
    
    if (response?.type === UPDATE_USER_EMAIL_REJECTED) {
      setIsLoading(false);
      setIsError(true);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <FillerContainer as={StepsContainer}>
          <Row>
            <Column span={12}>
              <Row>
                <Column span={12}>
                  <StepsRoutes totalSteps={8} stepIndex={6} />
                </Column>
                <Column span={12} my={2}>
                  <Title
                    tagName="h1"
                    color="black"
                    align="left"
                    size={4}
                    text={t("personalData.email.title")}
                  />
                  <Text
                    tagName="p"
                    color="grey"
                    align="left"
                    size={1}
                    margin="5px 0"
                  >
                    {t("personalData.email.subtitle")}
                  </Text>
                </Column>

                <Column span={12} my={2}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Input
                      label="email"
                      typeInput="text"
                      textLabel={t("personalData.email.placeholders.email")}
                      maxLengthInput={50}
                      register={register}
                      errors={errors}
                      rules={{
                        pattern: {
                          value: allowedEmail,
                          message: t("Forms.inputEmail"),
                        },
                      }}
                    />

                    <Row>
                      {isError && (
                        <Column span={12} my={2}>
                          <Text
                            size={0.5}
                            align="center"
                            weight={600}
                            color="error"
                          >
                            {t("404.subtitle")}
                          </Text>
                        </Column>
                      )}

                      <Column span={12} my={6}>
                        <Button
                          variant="primary"
                          text={t("personalData.email.button")}
                          loading={isLoading ? 1 : 0}
                          sizeText="medium"
                          onClick={handleSubmit(onSubmit)}
                        />
                      </Column>
                    </Row>
                  </form>
                </Column>
              </Row>
            </Column>
          </Row>
        </FillerContainer>
      </Layout>
    </ThemeProvider>
  );
}
