export const LEGAL_FILES = [
  {
    path: "/ConsumerCardholderAgreement",
    urlEs:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola+Card/espan%CC%83ol/ES_CardHolderAgreement.pdf",
    urlEn:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola+Card/ingle%CC%81s/CardHolderAgreement.pdf",
  },
  {
    path: "/SendolaPrivacyPolicy",
    urlEs:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola+Card/espan%CC%83ol/ES_PrivacyPolicy.pdf",
    urlEn:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola+Card/ingle%CC%81s/PrivacyPolicy.pdf",
  },
  {
    path: "/SolidPrivacyPolicy",
    urlEs:
      "https://help.solidfi.com/hc/en-us/articles/4405726967195-Solid-Privacy-Po",
    urlEn:
      "https://help.solidfi.com/hc/en-us/articles/4405726967195-Solid-Privacy-Po",
  },
  {
    path: "/LCBPrivacyPolicy",
    urlEs: "https://www.solidfi.com/lcb-privacy-policy",
    urlEn: "https://www.solidfi.com/lcb-privacy-policy",
  },
  {
    path: "/SendolaTermsAndConditions",
    urlEs:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola.io/Sendola+Terms+of+Service++(8092025).pdf",
    urlEn:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola.io/Sendola+Terms+of+Service++(8092025).pdf",
  },
  {
    path: "/SolidTermsAndConditions",
    urlEs: "https://help.solidfi.com/hc/en-us/articles/4405720601755",
    urlEn: "https://help.solidfi.com/hc/en-us/articles/4405720601755",
  },
  {
    path: "/ElectronicCommunicationsDisclosures",
    urlEs:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola.io/ElectronicCommunicationsDisclosuresandAgreement.pdf",
    urlEn:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola.io/ElectronicCommunicationsDisclosuresandAgreement.pdf",
  },
  {
    path: "/FeeShedule",
    urlEs:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola+Card/espan%CC%83ol/ES_FeeSchedule.pdf",
    urlEn:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola+Card/ingle%CC%81s/FeeSchedule.pdf",
  },
  {
    path: "/TransactionLimits",
    urlEs:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola+Card/ingle%CC%81s/ES_TxLimits.pdf",
    urlEn:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola+Card/ingle%CC%81s/TxLimits.pdf",
  },
  {
    path: "/ESignatureAgreement",
    urlEs:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola+Card/espan%CC%83ol/ES_EsignatureAgreement.pdf",
    urlEn:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola+Card/ingle%CC%81s/EsignatureAgreement.pdf",
  },
  {
    path: "/USAPatriotAct",
    urlEs:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola+Card/espan%CC%83ol/ES_USAPatriotAct.pdf",
    urlEn:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures%20y%20Legales%20/Sendola%20Card/ingle%CC%81s/USAPatriotAct.pdf",
  },
];

export const UNITELLER_LEGAL_FILES = [
  {
    name: "user_agreement",
    urlEs:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Global/espan%CC%83ol/ES_UniTellerUserAgreement.pdf",
    urlEn:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Global/ingle%CC%81s/UniTellerUserAgreement.pdf",
  },
  {
    name: "privacy_policy",
    urlEs:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Global/espan%CC%83ol/ES_UniTellerPrivacyPolicy.pdf",
    urlEn:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Global/ingle%CC%81s/UniTellerPrivacyPolicy.pdf",
  },
];
