import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "@redux/Store";

import {
  PaymentOrder,
  PaymentOrdersSortByDate,
  PaymentTransactionHistory,
  PaymentTransactions,
} from "@core/Payments/PaymentOrders/domain/PaymentOrder";
import {
  getPaymentOrder,
  getPaymentOrderTracking,
  getPaymentOrders,
  getPaymentOrdersTransaction,
  getVelocityAmountTxn,
  getVelocityTsxDestination,
  getVelocityTsxSendolaPay,
  getVelocityTxn,
} from "@core/Payments/PaymentOrders/application/getTransactions";
import { createApiPaymentOrderRepository } from "@core/Payments/PaymentOrders/infrastructure/ApiPaymentOrderRepository";
import { ApiRequestError } from "@core/Common/domain/ApiRequestError";
import { useStatus } from "./useStatus";
import { PaymentOrderPayload } from "@core/Payments/PaymentOrders/domain/PaymentOrderCreated";
import { createPaymentOrder } from "@core/Payments/PaymentOrders/application/createPaymentOrder";
import { paymentOrderSlice } from "@redux/Payments/PaymentOrder";
import { ValidateVerifyResponse, VerifyPaymentOrder } from "@core/Payments/PaymentOrders/domain/VerifyPaymentOrder";
import {
  saveSenderEmail,
  termsConditionsUniteller,
  verifyPaymentOrder,
} from "@core/Payments/PaymentOrders/application/verifyPaymentOrder";
import { TransferOrdersSlice } from "@redux/Payments/TransferOrders/TransferOrders.slice";
import { PaymentOrderVerifySlice } from "@redux/Payments/PaymentOrder/PaymentOrderVerify.slice";
import { BeneficiarySelector, BeneficiarySlice } from "@redux/Payments/Beneficiary/Beneficiary.slice";
import { QuotationSelector } from "@redux/Payments/Quotation/Quotation.slice";
import { GeoLocationContext } from "@contexts/GeoLocation";
import isEmpty from "lodash.isempty";
import { useTranslation } from "react-i18next";
import { Codes } from "@core/Payments/PaymentOrders/domain/VelocityLimit";
import { useVelocityLimits } from "./useVelocityLimits";
import { useModal } from "./useModal";
import { useNavigate } from "react-router-dom";
import { useBeneficiaryAdditionalFields } from "./useBeneficiaryAdditionalFields";
import { AdditionalField } from "@core/Payments/Common/domain/AdditionalFields";
import { QuotePaymentMethodDestination } from "@core/Payments/Quote/domain/QuoteDeliveryMethod";
import { PersonsKYCSelector } from "@redux/Payments/PersonsKYC";
import { SendolaCardPaymentMethod } from "@core/Payments/Common/domain/PaymentMethods";
import { sortVelocityLimits } from "@core/Payments/PaymentOrders/domain/PaymentOrderRepository";
import { rails } from "@constants/Rails";

export const usePaymentOrder = () => {
  const [tModals] = useTranslation("modals");
  const [t] = useTranslation("global");
  const dispatch = useDispatch<AppDispatch>();
  const { status, isLoading, hasError, error, setStatus, setError } = useStatus();
  const [transactions, setTransactions] = useState<PaymentOrder[]>([]);
  const location = useContext(GeoLocationContext);

  const currentBeneficiarySelected = useSelector(BeneficiarySelector).data.beneficiarySelected;
  const {
    currentQuotation,
    countryDestination,
    currentPaymentDestination,
    currentDeliveryMethodQuote,
    paymentMethodSelected,
    couponToApply,
    currentChannel,
    currentQuoteDiscounted,
    currentPaymentMethod,
  } = useSelector(QuotationSelector).data;
  const { data: personData } = useSelector(PersonsKYCSelector);
  const person = personData?.personVeriff;
  const { velocityModal, showVelocityModal } = useVelocityLimits();
  const { modal: verifyErrorModal, showModal: showVerifyErrorModal } = useModal();

  const { fetchAdditionalFields, fetchAdditionalFieldsAppriza } = useBeneficiaryAdditionalFields({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    country: countryDestination!,
  });
  const navigate = useNavigate();

  const getTransactions = async () => {
    setStatus("loading");
    try {
      let response = await getPaymentOrders(createApiPaymentOrderRepository())();

      if (response && !isEmpty(response)) {
        response = response.map((transaction) => ({
          ...transaction,
          paymentReason: t("transferOrder.paymentTitle"),
        }));
      }

      setTransactions(PaymentOrdersSortByDate(response));
      setStatus("idle");
    } catch (error) {
      setStatus("error");
      if (error && (error as ApiRequestError).errors) {
        setError(error as ApiRequestError);
      }
    }
  };

  const getTransactionDetail = async (id: string) => {
    setStatus("loading");
    try {
      const response = await getPaymentOrder(createApiPaymentOrderRepository())(id);
      setStatus("idle");
      dispatch(TransferOrdersSlice.actions.setTransactionDetail(response));
    } catch (error) {
      setStatus("error");
      if (error && (error as ApiRequestError).errors) {
        setError(error as ApiRequestError);
      }
    }
  };

  const getTransactionHistory = async (payload: PaymentTransactions) => {
    setStatus("loading");
    try {
      const response: PaymentTransactionHistory = await getPaymentOrdersTransaction(createApiPaymentOrderRepository())(
        payload
      );

      if (response && response.results) {
        response.results.map((item) => {
          const date = new Date(item.processedAt);
          const monthYear = `${("0" + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
          item.month = monthYear;
        });
      }

      setStatus("idle");
      return response;
    } catch (error) {
      setStatus("error");
      return error as ApiRequestError;
    }
  };

  const getTransactionTracking = async (id: string) => {
    setStatus("loading");
    try {
      const response = await getPaymentOrderTracking(createApiPaymentOrderRepository())(id);
      dispatch(TransferOrdersSlice.actions.setTransactionTracking(response));
    } catch (error) {
      setStatus("error");
      if (error && (error as ApiRequestError).errors) {
        setError(error as ApiRequestError);
      }
    }
  };

  const createPayment = async (payload: PaymentOrderPayload) => {
    setStatus("loading");
    try {
      const response = await createPaymentOrder(createApiPaymentOrderRepository())(payload);

      dispatch(paymentOrderSlice.actions.setPaymentOrderCreated(response));

      setStatus("idle");

      return response;
    } catch (error) {
      setStatus("error");
      if (error) {
        setError(error as ApiRequestError);
      }
    }
  };

  const verifyPayment = async (payload: VerifyPaymentOrder) => {
    setStatus("loading");
    try {
      const response = await verifyPaymentOrder(createApiPaymentOrderRepository())(payload);

      dispatch(PaymentOrderVerifySlice.actions.setCurrentVerifyResponse(response));

      setStatus("idle");

      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setStatus("error");
      return error;
    }
  };

  const compareAdditionalFields = (current: VerifyPaymentOrder["additionalField"], needed: AdditionalField[]) => {
    const missing = needed.filter((neededField) => {
      return (
        (neededField.fieldGroup !== "branch" &&
          !current?.additionalFields?.find((currentField) => currentField.fieldName === neededField.fieldName)
            ?.fieldValue) ||
        (neededField.fieldGroup === "branch" && !current?.branch)
      );
    });

    return missing;
  };

  const buildAdditionalFieldsPayload = (paymentDestination?: QuotePaymentMethodDestination) => {
    const arrayAccountAdditionalField =
      currentBeneficiarySelected?.accountSelected?.additionalFieldInfo?.map((field) => ({
        fieldBelongsTo: field.fieldBelongsTo,
        fieldName: field.fieldName,
        fieldValue: field.fieldValue,
      })) || [];

    const arrayAdditionalField =
      currentBeneficiarySelected?.additionalField?.additionalFieldInfo?.map((field) => ({
        fieldBelongsTo: field.fieldBelongsTo,
        fieldName: field.fieldName,
        fieldValue: field.fieldValue,
      })) || [];

    const AdditionalField = arrayAccountAdditionalField?.concat(arrayAdditionalField);

    const branch =
      currentDeliveryMethodQuote?.deliveryMethodCode === "CPU"
        ? currentBeneficiarySelected?.additionalField?.branch
        : currentBeneficiarySelected?.accountSelected?.branch;

    const additionalFieldsObj: VerifyPaymentOrder["additionalField"] = {
      branch: paymentDestination?.isPayerBranchRequired ? branch : undefined,
      additionalFields: AdditionalField,
    };
    return additionalFieldsObj;
  };

  const verifyCurrentSendMoneySelection = async (opts?: {
    onSuccess?: () => void;
    onVerifyErrorClose?: () => void;
  }) => {
    let paymentDestination = currentPaymentDestination;
    if (!currentPaymentDestination && currentBeneficiarySelected?.accountSelected) {
      paymentDestination = currentDeliveryMethodQuote?.paymentDestinations.find(
        (dest) => dest.destination.toLowerCase() === currentBeneficiarySelected.accountSelected?.bankName?.toLowerCase()
      );
    }

    const additionalFieldsObj = buildAdditionalFieldsPayload(paymentDestination);

    if (
      currentBeneficiarySelected &&
      (currentDeliveryMethodQuote?.deliveryMethodCode === "CPU" || currentBeneficiarySelected.accountSelected) &&
      currentBeneficiarySelected.beneficiaryId &&
      currentQuotation &&
      countryDestination &&
      paymentDestination &&
      currentDeliveryMethodQuote
    ) {
      setStatus("loading");
      const additionalFields = await fetchAdditionalFields({
        destinationSelected: paymentDestination,
        transferType: currentDeliveryMethodQuote?.deliveryMethodCode,
        country: countryDestination,
      });

      let additionalFieldsApz: AdditionalField[] = [];
      if (paymentDestination.rail === rails.APZ) {
        additionalFieldsApz = await fetchAdditionalFieldsAppriza(countryDestination);
      }

      const CurrenAdditionalData = additionalFieldsObj.additionalFields?.map((field) => {
        const result =
          additionalFields &&
          additionalFields.find((currentField) => {
            return currentField.fieldName === field.fieldName;
          });
        return {
          ...field,
          fieldBelongsTo: result?.fieldBelongsTo || field.fieldBelongsTo,
        };
      });

      additionalFieldsObj.additionalFields = CurrenAdditionalData;

      const missingFields = compareAdditionalFields(additionalFieldsObj, [...additionalFields, ...additionalFieldsApz]);
      if (!isEmpty(missingFields)) {
        setStatus("idle");
        navigate("/update-additional-fields", {
          state: { missingFields },
        });
      } else {
        const couponDiscountIsApplied = couponToApply || currentQuoteDiscounted;
        const response = await verifyPayment({
          receiver: currentBeneficiarySelected.beneficiaryId,
          instruction: {
            origination: {
              amount: currentQuotation.amount,
              currency: currentQuotation.originCurrency,
              isUsi: (paymentMethodSelected as SendolaCardPaymentMethod)?.sponsorBank === "USI Federal Credit Union",
              discount:
                !couponDiscountIsApplied && currentPaymentMethod?.discount ? currentPaymentMethod.discount : undefined,
              countrySubdivision: person?.countrySubdivision,
            },
            destination: {
              amount: currentDeliveryMethodQuote?.amountToReceive,
              currency: currentQuotation.destinationCurrency,
              destinationId: paymentDestination.id,
              accountType: currentBeneficiarySelected.accountSelected?.accountTypeId,
              accountNumber:
                currentDeliveryMethodQuote.deliveryMethodCode === "D2B"
                  ? currentBeneficiarySelected.accountSelected?.accountNumber
                  : undefined,
            },
            quote: {
              type: currentDeliveryMethodQuote.deliveryMethodCode,
              fee: currentDeliveryMethodQuote.fee,
              fx: currentDeliveryMethodQuote.fx,
              quoteUpdatedAt: new Date().toISOString(),
              couponCode: couponToApply?.code,
              quoteType: currentChannel,
            },
          },
          paymentReason: "Pago internacional",
          additionalField: additionalFieldsObj || undefined,
        });

        validateVerifyResponse({
          verifyResponse: response,
          onSuccess: opts?.onSuccess,
          onVerifyErrorClose: opts?.onVerifyErrorClose,
        });

        setStatus("idle");
        return response;
      }
    }
  };

  const validateVerifyResponse = ({
    verifyResponse,
    onSuccess = () => {
      dispatch(BeneficiarySlice.actions.setBeneficiaryToSave(undefined));
      navigate("/review-transaction");
    },
    onVelocityErrorClose = () => {
      navigate("/");
    },
    onVerifyErrorClose = () => {
      navigate("/payment-method-selection");
    },
  }: ValidateVerifyResponse) => {
    if (!verifyResponse.id) {
      if (
        (verifyResponse.errors as Codes[])?.some((error: Codes) => {
          return error?.code?.startsWith("VT");
        }) ||
        verifyResponse.error?.code.startsWith("VT")
      ) {
        const errors = verifyResponse.errors as Codes[];
        const firstVerifyError =
          errors?.find((error: Codes) => {
            return error?.code?.startsWith("VT");
          }) || verifyResponse.error;
        const errorTitle = tModals([`verifyPaymentOrder.${firstVerifyError?.code}.title`, "verifyPaymentOrder.title"]);
        const errorMessage = tModals([
          `verifyPaymentOrder.${firstVerifyError?.code}.message`,
          "verifyPaymentOrder.errorMessage",
        ]);

        showVerifyErrorModal({
          modalType: "error",
          title: errorTitle,
          errorMessage,
          cancelText: "ok",
          handleClose: onVerifyErrorClose,
        });
      } else if (
        (verifyResponse.errors as Codes[])?.some((error: Codes) => {
          return error?.code?.startsWith("VL") || error?.code === "VF005";
        }) ||
        verifyResponse.error?.code === "VF005"
      ) {
        showVelocityModal({
          codesMessages: (verifyResponse.errors || [verifyResponse.error]) as Codes[],
          modalTypeVelocity: "velocityLimit",
          pageName: "verify",
          onClose: onVelocityErrorClose,
        });
      } else if (
        (verifyResponse.errors as Codes[])?.some((error: Codes) => {
          return error?.code === "VF002";
        })
      ) {
        location?.showLocationNotAllowedModal();
      } else {
        let errorMessage = tModals("verifyPaymentOrder.errorMessage");

        if (
          verifyResponse.errors?.includes("VerifyOrder: code:BA01 message:Invalid Beneficiary Account Number") ||
          verifyResponse.errors?.includes("ErrorBeneficiary bank-code is not valid, is not on the catalog") ||
          verifyResponse.errors?.includes("Error in save D2B beneficiary. Invalid account number.")
        ) {
          errorMessage = tModals("verifyPaymentOrder.errorAccount");
        } else if (verifyResponse.errors?.includes("Sender document doesn't exists")) {
          errorMessage = tModals("verifyPaymentOrder.senderDocumentDoesntExist");
        } else if (verifyResponse.errors?.includes("The sender's document is expired")) {
          errorMessage = tModals("verifyPaymentOrder.senderDocumentExpired");
        } else if (
          verifyResponse.errors?.find(
            (error) => (error as string)?.includes("ErrorOrigin") || (error as string)?.includes("GetCatalogCountries")
          )
        ) {
          errorMessage = tModals("verifyPaymentOrder.errorOrigin");
        }

        showVerifyErrorModal({
          modalType: "error",
          errorMessage,
          handleClose: onVerifyErrorClose,
        });
      }
    } else {
      onSuccess();
    }
  };

  const getStatusVelocityTxn = async () => {
    setStatus("loading");
    try {
      const response = await getVelocityTxn(createApiPaymentOrderRepository())();

      setStatus("idle");
      return response;
    } catch (error) {
      setStatus("error");
      return error;
    }
  };

  const getStatusVelocityAmountTxn = async (amount: number): Promise<undefined | ApiRequestError<Codes>> => {
    setStatus("loading");
    try {
      const response = await getVelocityAmountTxn(createApiPaymentOrderRepository())(amount);

      setStatus("idle");

      return response;
    } catch (error) {
      setStatus("error");
      return error as ApiRequestError<Codes>;
    }
  };

  const getStatusVelocityTxnDestination = async (
    amount: number,
    destinationId: string | null | undefined
  ): Promise<undefined | ApiRequestError<Codes>> => {
    setStatus("loading");
    try {
      const response = await getVelocityTsxDestination(createApiPaymentOrderRepository())(amount, destinationId);
      setStatus("idle");
      return response;
    } catch (error) {
      setStatus("error");
      const errorObj = error as ApiRequestError<Codes>;

      return {
        ...errorObj,
        errors: errorObj.errors ? sortVelocityLimits(errorObj.errors) : [],
      };
    }
  };

  const getStatusVelocityTxnSendolaPay = async () => {
    setStatus("loading");
    try {
      const response = await getVelocityTsxSendolaPay(createApiPaymentOrderRepository())();

      setStatus("idle");
      return response;
    } catch (error) {
      setStatus("error");
      return error;
    }
  };

  const verificationUniteller = async () => {
    setStatus("loading");
    try {
      const response = await termsConditionsUniteller(createApiPaymentOrderRepository())();

      setStatus("idle");
      return response;
    } catch (error) {
      setStatus("error");
      setError(error as ApiRequestError);
      return null;
    }
  };

  const saveSenderEmailUniteller = async (email: string) => {
    setStatus("loading");
    try {
      const response = await saveSenderEmail(createApiPaymentOrderRepository())(
        email,
        personData?.personVeriff?.personId
      );

      setStatus("idle");
      return response;
    } catch (error) {
      setStatus("error");
      setError(error as ApiRequestError);
      return null;
    }
  };

  return {
    isLoading,
    status,
    hasError,
    error,
    transactions,
    verifyErrorModal,
    velocityModal,
    getTransactionDetail,
    getTransactions,
    createPayment,
    verifyPayment,
    verifyCurrentSendMoneySelection,
    getStatusVelocityTxn,
    getStatusVelocityAmountTxn,
    getStatusVelocityTxnSendolaPay,
    getStatusVelocityTxnDestination,
    verificationUniteller,
    saveSenderEmailUniteller,
    getTransactionHistory,
    getTransactionTracking,
  };
};
