
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Own States
import { AppDispatch } from "../../Redux/Store";
import { selectorLanguage } from "../../Redux/Translate";
import { PlaidDeleteAccountAction } from "../../Redux/FundsAccount/FundsAccount.actions";

// Own Components
import Text from "../Text";
import { Flex } from "../Flex";

// Own constants
import { DELETE_PLAID_ACCOUNT_FULFILLED } from "../../Constants/FundsAccount";

// Styles
import { 
  LinkedAccountModalContainer,
  LinkedAccountModalContent,
  LinkedAccountModalCancelButton,
  LinkedAccountModalConfirmButton
} from "./LinkedAccountCard.styled";

type Props = {
  accountId: string;
  isShowModal: boolean;
  onShowModal: () => void; 
  onDeleteCompleted?: () => void; 
}

const LinkedAccountDeleteModal = ({ 
  accountId, isShowModal, onShowModal, onDeleteCompleted }: Props
) => {
  const [t] = useTranslation("global");
  const lang = useSelector(selectorLanguage);
  const dispatch = useDispatch<AppDispatch>();

  const unlinkAccount = async () => {
    // setIsLoading(true);

    const response = await dispatch(PlaidDeleteAccountAction({
      accountId: accountId,
      languageCode: lang.language
    }));

    if(response?.type === DELETE_PLAID_ACCOUNT_FULFILLED) {
      onShowModal();

      if (onDeleteCompleted) onDeleteCompleted();
    }
  };

  return (
    <>
      { isShowModal && (
        <LinkedAccountModalContainer>
          <LinkedAccountModalContent>
            <Text size={1.5} weight={600} align="center" color="black" margin="0">
              {t("LinkedAccounts.deleteAccountTitle")}
            </Text>
            <Text
              size={1}
              weight={400}
              align="left"
              color="black"
              margin="16px 0"
            >
              {t("LinkedAccounts.deleteAccountDescription")}
            </Text>
            <Flex width="80%" justifyContent="space-between" gap="10px">
              <LinkedAccountModalCancelButton onClick={() => onShowModal()}>
                {t("LinkedAccounts.deleteAccountOptions.cancel")}
              </LinkedAccountModalCancelButton>
              <LinkedAccountModalConfirmButton
                onClick={() => unlinkAccount()}
              >
                {t("LinkedAccounts.deleteAccountOptions.delete")}
              </LinkedAccountModalConfirmButton>
            </Flex>
          </LinkedAccountModalContent>
        </LinkedAccountModalContainer>
      )}
    </>
  );
}

export default LinkedAccountDeleteModal;