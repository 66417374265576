import styled from "styled-components";
import { theme, ifProp } from "styled-tools";
import CurrencyInput from "react-currency-input-field";
import isEmpty from "lodash.isempty";

export const InputCurrencyMoney = styled(CurrencyInput)<{
  $error?: boolean;
  $align?: string;
  $smallPadding?: boolean;
  $noBorder?: boolean;
}>`
  box-sizing: border-box;
  font-size: 18px;
  font-family: "Poppins";
  border-width: ${ifProp("$noBorder", "0px", "1px")};
  border-style: solid;
  border-radius: 8px;
  border-color: ${(props) => (!isEmpty(props.$error) ? theme("Red_08") : theme("Background_03"))};
  background: ${theme("Background_01")};
  margin-top: ${ifProp("$smallPadding", "0px", "8px")};
  font-weight: 600;
  padding: ${ifProp("$smallPadding", "0 0 8px 0", "16px 24px")};
  text-align: ${(props) => (!isEmpty(props.$align) ? props.$align : "right")};
  width: 100%;
  outline: none;
  color: ${(props) =>
    !isEmpty(props.$error) ? theme("Red_08") : ifProp("$noBorder", theme("Background_02"), theme("Primary_01"))};
  &:hover {
    opacity: 0.9;
  }

  &:focus {
    border: ${ifProp("$noBorder", "0px", "2px")}
      ${(props) => (!isEmpty(props.$error) ? theme("Red_08") : theme("Primary_01"))} solid;
  }
`;

export const ErrorContainer = styled.div`
  color: ${theme("Red_08")};
  display: flex;
  align-items: end;
  padding: 0 8px;
`;

export const ErrorMessage = styled.div<{ $size?: string }>`
  font-size: ${(props) => (!isEmpty(props.$size) ? props.$size + "px" : "16px")};
  margin-left: 8px;
`;

export const Label = styled.label`
  display: block;
  color: ${theme("Content_UX_02")};
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  font-family: "Poppins";
  line-height: normal;
  padding-bottom: 8px;
  padding-left: 5px;
  text-align: left;
`;

export const LabelContainer = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  width: 100%;
`;

export const ContainerInput = styled.div`
  padding: 0;
`;
