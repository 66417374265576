import { useTranslation } from "react-i18next";
import { ModalProps } from "../../../Models/ModalInterface";
import Button from "../../Button";
import { FlexContainer } from "../../Flex/Flex.styled";
import Text from "../../Text";
import Modal from "../Modal.component";
import IconFont from "../../IconFont";

export const ConfirmationModal = (props: ModalProps) => {
  const [tModals] = useTranslation("modals");

  return (
    <Modal {...props}>
      {props.icon || <IconFont name="info" color="black" size="xlarge" />}
      <Text size={3} weight={700}>
        {props.title && props.title}
      </Text>
      {props.message && <Text size={2}>{props.message}</Text>}
      {props.children}
      <FlexContainer
        direction="column"
        alignItems="center"
        justify="center"
        gap="1rem"
      >
        <Button
          variant={"primary"}
          sizeButton="large"
          sizeText="large"
          text={tModals("confirm")}
          colorIcon="white"
          sizeIcon="large"
          onClick={() => {
            props.handleConfirm && props.handleConfirm();
            props.handleClose && props.handleClose();
          }}
        />
        <Button
          variant={"outline"}
          sizeButton="large"
          sizeText="large"
          text={tModals("cancel")}
          colorIcon="white"
          sizeIcon="large"
          onClick={() => props.handleClose && props.handleClose()}
          color={"Primary_01"}
        />
      </FlexContainer>
    </Modal>
  );
};
