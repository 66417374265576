import { createSlice } from "@reduxjs/toolkit";
import { getAllCards } from "./Cards.actions";
import { State } from "../Store";

export const CardsSlice = createSlice({
  name: "Cards",
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllCards.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      .addCase(getAllCards.rejected, (state: any, action: any) => {
        state.loading = false;
        state.data = {};
        state.error = action.payload.response;
      });
  },
});

export default CardsSlice.reducer;

export const CardsSelector = (state: State) => state.Cards;
