import { createSlice } from "@reduxjs/toolkit";
import { getAllAccounts } from "./Accounts.actions";
import { State } from "../Store";

export const AccountsSlice = createSlice({
  name: "Accounts",
  initialState: {
    loading: false,
    error: null,
    data: [],
    accountSelected: {
      amount: "",
      data: {}
    }
  },
  reducers: {
    resetAccount(state) {
      return {
        ...state,
        accountSelected: {
          amount: "",
          data: {}
        }
      };
    },
    setCurrentAccount(state, action) {
      state.accountSelected = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAccounts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllAccounts.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      .addCase(getAllAccounts.rejected, (state: any, action: any) => {
        state.loading = false;
        state.data = {};
        state.error = action.payload.response;
      });
  },
});

export const { setCurrentAccount, resetAccount } = AccountsSlice.actions;

export default AccountsSlice.reducer;

export const AccountsSelector = (state: State) => state.Accounts;
