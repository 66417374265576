
export const GET_PAYROLL = "Payroll";
export const GET_PAYROLL_PENDING = "Payroll/pending";
export const GET_PAYROLL_REJECTED = "Payroll/rejected";
export const GET_PAYROLL_FULFILLED = "Payroll/fulfilled";

export const GET_PERSON_PAYROLL_INFO = 'GetPersonPayrollInfo'
export const GET_PERSON_PAYROLL_INFO_PENDING = `${GET_PERSON_PAYROLL_INFO}/pending`;
export const GET_PERSON_PAYROLL_INFO_REJECTED = `${GET_PERSON_PAYROLL_INFO}/rejected`;
export const GET_PERSON_PAYROLL_INFO_FULFILLED = `${GET_PERSON_PAYROLL_INFO}/fulfilled`;

export const GET_PAYROLL_PDF = 'GetPayrollPdf'
export const GET_PAYROLL_PDF_PENDING = `${GET_PAYROLL_PDF}/pending`;
export const GET_PAYROLL_PDF_REJECTED = `${GET_PAYROLL_PDF}/rejected`;
export const GET_PAYROLL_PDF_FULFILLED = `${GET_PAYROLL_PDF}/fulfilled`;

export const PDF_SHIPPING_UPDATE = 'PdfShippingUpdate'
export const PDF_SHIPPING_UPDATE_PENDING = `${PDF_SHIPPING_UPDATE}/pending`;
export const PDF_SHIPPING_UPDATE_REJECTED = `${PDF_SHIPPING_UPDATE}/rejected`;
export const PDF_SHIPPING_UPDATE_FULFILLED = `${PDF_SHIPPING_UPDATE}/fulfilled`;

export const GET_DIRECT_DEPOSIT = 'GetDirectDeposit'
export const GET_DIRECT_DEPOSIT_PENDING = `${GET_DIRECT_DEPOSIT}/pending`;
export const GET_DIRECT_DEPOSIT_REJECTED = `${GET_DIRECT_DEPOSIT}/rejected`;
export const GET_DIRECT_DEPOSIT_FULFILLED = `${GET_DIRECT_DEPOSIT}/fulfilled`;

export const SET_DIRECT_DEPOSIT = 'SetDirectDeposit'
export const SET_DIRECT_DEPOSIT_PENDING = `${SET_DIRECT_DEPOSIT}/pending`;
export const SET_DIRECT_DEPOSIT_REJECTED = `${SET_DIRECT_DEPOSIT}/rejected`;
export const SET_DIRECT_DEPOSIT_FULFILLED = `${SET_DIRECT_DEPOSIT}/fulfilled`;

export const UPDATE_DIRECT_DEPOSIT_FEATURE = 'UpdateDirectDeposit'
export const UPDATE_DIRECT_DEPOSIT_FEATURE_PENDING = `${UPDATE_DIRECT_DEPOSIT_FEATURE}/pending`;
export const UPDATE_DIRECT_DEPOSIT_FEATURE_REJECTED = `${UPDATE_DIRECT_DEPOSIT_FEATURE}/rejected`;
export const UPDATE_DIRECT_DEPOSIT_FEATURE_FULFILLED = `${UPDATE_DIRECT_DEPOSIT_FEATURE}/fulfilled`;

export const GET_PAYROLL_BYUSER = "PayrollByUser";
export const GET_PAYROLL_BYUSER_PENDING = "PayrollByUser/pending";
export const GET_PAYROLL_BYUSER_REJECTED = "PayrollByUser/rejected";
export const GET_PAYROLL_BYUSER_FULFILLED = "PayrollByUser/fulfilled";

export const GET_PINWHEEL_LINK = "PinWheelLink";
export const GET_PINWHEEL_LINK_PENDING = "PinWheelLink/pending";
export const GET_PINWHEEL_LINK_REJECTED = "PinWheelLink/rejected";
export const GET_PINWHEEL_LINK_FULFILLED = "PinWheelLink/fulfilled";

export const GET_PINWHEEL_ACCOUNT = "PinWheelAccount";
export const GET_PINWHEEL_ACCOUNT_PENDING = "PinWheelAccount/pending";
export const GET_PINWHEEL_ACCOUNT_REJECTED = "PinWheelAccount/rejected";
export const GET_PINWHEEL_ACCOUNT_FULLFILLED = "PinWheelAccount/fulfilled";

export const GET_REQUEST_DIRECT_DEPOSIT = 'GetRequestDirectDeposit'
export const GET_REQUEST_DIRECT_DEPOSIT_PENDING = `${GET_REQUEST_DIRECT_DEPOSIT}/pending`;
export const GET_REQUEST_DIRECT_DEPOSIT_REJECTED = `${GET_REQUEST_DIRECT_DEPOSIT}/rejected`;
export const GET_REQUEST_DIRECT_DEPOSIT_FULFILLED = `${GET_REQUEST_DIRECT_DEPOSIT}/fulfilled`;

export const POST_REQUEST_DIRECT_DEPOSIT = 'PostRequestDirectDeposit'
export const POST_REQUEST_DIRECT_DEPOSIT_PENDING = `${POST_REQUEST_DIRECT_DEPOSIT}/pending`;
export const POST_REQUEST_DIRECT_DEPOSIT_REJECTED = `${POST_REQUEST_DIRECT_DEPOSIT}/rejected`;
export const POST_REQUEST_DIRECT_DEPOSIT_FULFILLED = `${POST_REQUEST_DIRECT_DEPOSIT}/fulfilled`;

export const PDF_DIRECT_DEPOSIT = 'PdfDirectDeposit'
export const PDF_DIRECT_DEPOSIT_PENDING = `${PDF_DIRECT_DEPOSIT}/pending`;
export const PDF_DIRECT_DEPOSIT_REJECTED = `${PDF_DIRECT_DEPOSIT}/rejected`;
export const PDF_DIRECT_DEPOSIT_FULFILLED = `${PDF_DIRECT_DEPOSIT}/fulfilled`;
