import { createBrowserRouter, Navigate } from "react-router-dom";

// Own Pages
import ErrorPage from "../Pages/404";
import HomePage from "../Pages/HomePage";

// Own Components
import UserProvider from "../Components/AuthHandler/AuthHandler.component";
import ScrollToTop from "../Components/ScrollToTop";
import ManageAccount from "../Pages/ManageAccount";
import Transactions from "../Pages/ManageAccount/Transactions";
import TransactionDetail from "../Pages/ManageAccount/TransactionDetailOld";
import LegalName from "../Pages/KYC/LegalName";
import ShippingAddress from "../Pages/KYC/ShippingAddress";
import UsAddress from "../Pages/KYC/UsAddress";
import SocialSecurityNumber from "../Pages/KYC/SocialSecurityNumber";
import { PayrollUpdate, PayrollInstructions } from "../Pages/ManageAccount/PayrollUpdate";
import VeriffPage from "../Pages/KYC/Veriff";
import PassportPage from "../Pages/KYC/Passport";
import SubmitKYCPage from "../Pages/KYC/SubmitKYC";
import Contacts from "../Pages/ConfigUser/Contacts";
import Disclosures from "../Pages/ConfigUser/Disclosures";
import Settings from "../Pages/ConfigUser/Settings";
import AccountDetails from "../Pages/ConfigUser/AccountDetails";
import AccountUpdate from "../Pages/ConfigUser/AccountUpdate";
import { VisaPhoto, UploadVisaPhoto, VisaVerificationVeriff } from "../Pages/KYC/Visa2.0";
import {
  UploadResubmissionVisaPhoto,
  VisaResubmissionPhoto,
  VisaResubmissionVeriff,
} from "../Pages/CompleteProfile/Visa";
import SSNPage from "../Pages/CompleteProfile/SSN";
import { PayrollDirectDeposit, PayrollDirectDepositError } from "../Pages/ManageAccount/Payroll";
import Email from "../Pages/KYC/Email";
import { FundAccount, FundAccountConfirmed, FundAccountError } from "../Pages/Funds/FundAccount";
import FundsSelectAccountPage from "../Pages/Funds/SelectAccount";
import FundsCaptureCheckInstructions from "../Pages/Funds/CaptureCheckInstructions";
import { FundsLinkedAccounts, FundsLinkedAccountsDetails } from "../Pages/Funds/LinkedAccounts";
import { BankTranfersPage } from "../Pages/BankTranfers";
import BankTranfersDetailsPage from "../Pages/BankTranfers/BankTransfersDetails.page";
import {
  ProofOfAddresPage,
  ProofOfAddresTakePhotoPage,
  ProofOfAddresUploadPhotoPage,
} from "../Pages/CompleteProfile/ProofOfAddress";
import SelectAccountType from "../Pages/SelectAccountType";
import ManagePayments from "../Pages/ManagePayments";
import IdentityVerifyPage from "../Pages/CompleteProfile/IdentityVerify";
import SubmitCompleteOnboardingPage from "../Pages/KYC/SubmitCompleteOnboarding";
import ProofOfLifePage from "../Pages/CompleteProfile/ProofOfLife";
import LinkedAccounts from "../Pages/LinkedAccounts";
import TransactionDetailPage from "../Pages/ManageAccount/TransactionDetail/TransactionDetailPage.component";
import BeneficiarySelectionPage from "../Pages/Payments/BeneficiarySelection/BeneficiarySelection.page";
import PaymentMethodSelectionPage from "../Pages/Payments/PaymentMethodSelection/PaymentMethodSelection.page";
import ReviewTransactionPage from "../Pages/Payments/ReviewTransaction";
import TransactionCompletedPage from "../Pages/Payments/TansactionCompleted";
import Recipients from "../Pages/Recipients";
import { Accounts, AddAccounts } from "../Pages/Recipients/Accounts";
import { EditBeneficiary } from "../Pages/Recipients/Edit/EditBeneficiary.page";
import { UpdateAdditionalFields } from "../Pages/Payments/UpdateAdditionalFields";

export const BASE_PATH = "/";

// eslint-disable-next-line
const ParentPage = ({ page }: any) => (
  <>
    <ScrollToTop />
    <UserProvider>{page}</UserProvider>
  </>
);

export const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <ParentPage page={<HomePage />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/beneficiary-selection",
      element: <ParentPage page={<BeneficiarySelectionPage />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/payment-method-selection",
      element: <ParentPage page={<PaymentMethodSelectionPage />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/review-transaction",
      element: <ParentPage page={<ReviewTransactionPage />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/transaction-completed",
      element: <TransactionCompletedPage />,
    },
    {
      path: "/dashboard-error",
      element: <ParentPage page={<ErrorPage />} />,
    },
    {
      path: "/welcome",
      element: <Navigate to="/" replace />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/kyc-personal-data",
      element: <ParentPage page={<LegalName />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/kyc-personal-address",
      element: <ParentPage page={<UsAddress />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/kyc-identity-veriff",
      element: <ParentPage page={<IdentityVerifyPage />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/complete-onboarding",
      element: <ParentPage page={<SubmitCompleteOnboardingPage />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/submit-kyc",
      element: <ParentPage page={<SubmitKYCPage />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/kyc-email",
      element: <ParentPage page={<Email />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/kyc-shipping-address",
      element: <ParentPage page={<ShippingAddress />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/kyc-ssn",
      element: <ParentPage page={<SocialSecurityNumber />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/manage-card-account",
      element: <ParentPage page={<ManageAccount />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/transactions",
      element: <ParentPage page={<Transactions />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/transactions/detail",
      element: <ParentPage page={<TransactionDetailPage />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/transactions-detail",
      element: <ParentPage page={<TransactionDetail />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/payroll-authorization",
      element: <ParentPage page={<PayrollUpdate />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/payroll-instructions",
      element: <ParentPage page={<PayrollInstructions />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/payroll-setup-deposit",
      element: <ParentPage page={<PayrollDirectDeposit />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/payroll-setup-deposit-error",
      element: <ParentPage page={<PayrollDirectDepositError />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/kyc-veriff",
      element: <ParentPage page={<VeriffPage />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/kyc-veriff-visa",
      element: <ParentPage page={<VisaVerificationVeriff />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/kyc-veriff-visa-photo",
      element: <ParentPage page={<VisaPhoto />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/kyc-veriff-upload-visa",
      element: <ParentPage page={<UploadVisaPhoto />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/kyc-veriff-passport",
      element: <ParentPage page={<PassportPage />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/kyc-submit-kyc",
      element: <ParentPage page={<SubmitKYCPage />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/contact-us",
      element: <ParentPage page={<Contacts />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/disclosures",
      element: <ParentPage page={<Disclosures />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/settings",
      element: <ParentPage page={<Settings />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/account-details",
      element: <ParentPage page={<AccountDetails />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/accepted-account-update",
      element: <ParentPage page={<AccountUpdate />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/account-cancellation",
      element: <ParentPage page={<AccountUpdate />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/visa",
      element: <ParentPage page={<VisaVerificationVeriff />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/visa-photo",
      element: <ParentPage page={<VisaPhoto />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/upload-visa",
      element: <ParentPage page={<UploadVisaPhoto />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/complete-proof-of-life",
      element: <ParentPage page={<ProofOfLifePage />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/complete-profile-ssn",
      element: <ParentPage page={<SSNPage />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/complete-profile-visa",
      element: <ParentPage page={<VisaResubmissionVeriff />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/complete-profile-visa-photo",
      element: <ParentPage page={<VisaResubmissionPhoto />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/complete-profile-upload-visa",
      element: <ParentPage page={<UploadResubmissionVisaPhoto />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/fund-account",
      element: <ParentPage page={<FundAccount />} />,
    },
    {
      path: "/funds-select-account",
      element: <ParentPage page={<FundsSelectAccountPage />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/funds-linked-accounts",
      element: <ParentPage page={<FundsLinkedAccounts />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/funds-linked-accounts-details",
      element: <ParentPage page={<FundsLinkedAccountsDetails />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/funds-capture-check-instructions",
      element: <ParentPage page={<FundsCaptureCheckInstructions />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/funds-confirmation",
      element: <ParentPage page={<FundAccountConfirmed />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/funds-error",
      element: <ParentPage page={<FundAccountError />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/bank-transfers",
      element: <ParentPage page={<BankTranfersPage />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/bank-transfers-details",
      element: <ParentPage page={<BankTranfersDetailsPage />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/proof-of-address",
      element: <ParentPage page={<ProofOfAddresPage />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/proof-of-address-take-photo",
      element: <ParentPage page={<ProofOfAddresTakePhotoPage />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/proof-of-address-upload-photo",
      element: <ParentPage page={<ProofOfAddresUploadPhotoPage />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/select-account-type",
      element: <ParentPage page={<SelectAccountType />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/manage-payments",
      element: <ParentPage page={<ManagePayments />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/linked-accounts",
      element: <ParentPage page={<LinkedAccounts />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/recipients",
      element: <ParentPage page={<Recipients />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/recipients/edit",
      element: <ParentPage page={<EditBeneficiary />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/recipients/accounts",
      element: <ParentPage page={<Accounts />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/recipients/add-accounts",
      element: <ParentPage page={<AddAccounts />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/update-additional-fields",
      element: <ParentPage page={<UpdateAdditionalFields />} />,
      errorElement: <ErrorPage />,
    },
  ],
  { basename: BASE_PATH }
);
