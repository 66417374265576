import { TOKEN_COOKIE_NAME } from "@constants/Token";
import { LANG_KEY } from "@constants/Language";
import Cookies from "universal-cookie";
import { AxiosProvider } from "./http/Axios.provider";
import { ApiRequestError } from "@core/Common/domain/ApiRequestError";
import { CreateAxiosDefaults } from "axios";
import store from "@redux/Store";
import { baseURLGP, API_KEY_GP } from "../../Constants/ConfigureApi";

const apiKey = API_KEY_GP;
const prefixUrl = baseURLGP;
const HttpClient = AxiosProvider;

function getToken() {
  const cookies = new Cookies();
  return (
    sessionStorage.getItem(TOKEN_COOKIE_NAME) || cookies.get(TOKEN_COOKIE_NAME)
  );
}

export function createClient(config?: CreateAxiosDefaults) {
  const language = sessionStorage.getItem(LANG_KEY) || "en";

  const client = new HttpClient({
    config: config || {
      baseURL: prefixUrl,
      headers: {
        languageCode: language,
        "x-api-key": apiKey,
      },
    },
    hooks: {
      request: (config) => {
        const token = getToken();

        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
      },
      response: {
        onRejected: (error) => {
          const apiErrorData = error.response?.data as ApiRequestError;
          const errorObj = {
            ...apiErrorData,
            status: error.response?.status,
            statusText: error.response?.statusText,
          };

          if (
            (error.response?.data as { code: string; message: string })
              ?.code === "AUTH001"
          ) {
            store.dispatch({ type: "PersonsKYC/blockUser" });
          } else if (error.response?.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            window.location.reload();
          } else {
            store.dispatch({ type: "PersonsKYC/unBlockUser" });
          }

          // TODO: Customize error handling allowing more types of errors and actions

          throw errorObj;
        },
      },
    },
  });

  return client;
}
