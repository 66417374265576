import { createSlice } from "@reduxjs/toolkit";
import { UploadCashIn } from "./CashIn.actions";
import { State } from "../Store";
import { CashInState } from "../../Models/Cashin";

const initialState: CashInState = {
  loading: false,
  error: null,
  data: null
}

export const UploadCashInSlice = createSlice({
  name: "UploadCashIn",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UploadCashIn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UploadCashIn.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(UploadCashIn.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export default UploadCashInSlice.reducer;

export const UploadCashInSelector = (state: State) => state.UploadCashIn;