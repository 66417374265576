import { createSlice } from "@reduxjs/toolkit";
import { State } from "@redux/Store";

export const UserIdSlice = createSlice({
  name: "userId",
  initialState: "",
  reducers: {
    setUserId(_, { payload }) {
      return payload;
    },
    clearUserId() {
      return "";
    },
  },
});

export const userIdSelector = (state: State) => state.userId;
