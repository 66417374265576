import { createSlice } from "@reduxjs/toolkit";
import { UploadDocuments } from "./Documents.actions";
import { State } from "../Store";
import { DocumentsState } from "../../Models/Documents";

const initialState: DocumentsState = {
  loading: false,
  error: null,
  data: null
};

export const UploadDocumentsSlice = createSlice({
  name: "uploadDocuments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UploadDocuments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UploadDocuments.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      .addCase(UploadDocuments.rejected, (state, action:any) => {
        state.loading = false;
        state.data = null;
        state.error = action?.payload?.response;
      });
  },
});

export const uploadDocumentsSelector = (state: State) => state.uploadDocuments;
