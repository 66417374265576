import styled, { CSSProperties } from "styled-components";
import { prop, theme } from "styled-tools";

import { FlexContainer } from "@components/Flex/Flex.styled";

import RightArrowSvg from "@assets/Icons/white-arrow-narrow-right.svg?react";

export const Description = styled.p`
  text-align: left;
  color: ${theme("Content_UX_02")};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
  margin-top: 24px;
`;

export const MTCNTitle = styled.h3`
  color: ${theme("Content_UX_01")};
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const MTCNNumber = styled.h3<{
  letterSpacing?: CSSProperties["letterSpacing"];
}>`
  color: ${theme("Content_UX_01")};
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  letter-spacing: ${prop("letterSpacing")};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 48px 0;
  outline: none;
  border: none;
`;

export const TransactionShieldCheckIcon = styled.img`
  width: 50px;
  height: 50px;
`;

export const TransactionNoticeStyled = styled.div`
  span {
    font-weight: 600;
  }
`;

export const TransactionTextPrimary = styled.span`
  color: ${theme("Primary_01")};
`;

export const SectionTitle = styled.h1`
  max-width: 671px;
  color: ${theme("Context_UX_01")};
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  overflow-wrap: break-word;
  margin-top: 0;
  margin-bottom: 40px;
`;

export const Button = styled.a`
  display: flex;
  width: 236px;
  height: 56px;
  padding: 0 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border: none;
  border-radius: 30px;
  background: ${theme("Primary_01")};
  color: ${theme("Content_UX_03")};
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
`;

export const RightArrowIcon = styled(RightArrowSvg)`
  width: 40px;
  height: 40px;
`;

export const disclaimerUniteller = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 24px 0;

  a {
    font-weight: 800;
    color: ${theme("Error")};
    text-decoration: underline;
    text-decoration-color: ${theme("Error")};
  }
`;

export const WaitingForFundsDisclaimer = styled(FlexContainer)`
  padding: 8px 30px;
  background-color: ${theme("Primary_01_25")};
  color: ${theme("Primary_01_600")};
  border-radius: 8px;
  justify-content: center;
  gap: 8px;
`;

export const SummaryContainer = styled.div`
  width: 100%;
  padding: 16px;
  background-color: ${theme("Background_12")};
  border-radius: 8px;
`;

export const ActionsIcons = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
  gap: 4px;
  max-width: 60px;
  align-items: center;
  margin: 0 8px;

  p {
    text-decoration: underline;
  }
`;

export const ContainerMTCN = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 8px;
  background: ${theme("BackgroundPurple6")};
  margin: 12px 0 0 0;
  align-items: center;
  width: 100%;
  max-width: 380px;
  gap: 8px;
`;

export const CopyCOntainer = styled.div`
  width: 100%;
  height: 29px;
  border-radius: 4px;
  margin-right: 16px;
  display: flex;
  z-index: 1;
  position: absolute;
  justify-content: right;
  max-width: 300px;
`;

export const TextCopy = styled.div`
  display: flex;
  width: 93px;
  color: ${theme("Primary_01_50")};
  padding: 2px 11px 2px 10px;
  height: 28px;
  border-radius: 4px;
  border: 0.5px solid ${theme("Background_03")};
  opacity: 0.75;
  background: ${theme("Background_11")};
  box-shadow: 0px 4px 4px 0px rgba(${theme("RGB_Background_01")}, 0.25);
  align-items: center;
  justify-content: center;
  gap: 4px;
  transition: opacity 0.3s linear;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }
`;

export const LabelCard = styled.div`
  border: 1px solid ${theme("Background_01")};
  background: ${theme("Background_01")};
  color: ${theme("Content_UX_01")};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 8px;
  width: auto;
  height: 29px;
  border-radius: 4px;
  margin-right: 16px;
  min-width: 235px;
  justify-content: space-between;

  p:first-child {
    letter-spacing: 1px;
    padding-left: 8px;
  }

  svg {
    cursor: pointer;
  }
`;
