import { createClient } from "@services/API/GlobalPaymentsApiClient";

import {
  BeneficiariesPaginated,
  BeneficiaryEdit,
  BeneficiaryUpdate,
  groupAccounts,
} from "../domain/Beneficiary";
import { BeneficiaryAccountCreated } from "../domain/BeneficiaryAccountCreated";
import { BeneficiaryAccountForm } from "../domain/BeneficiaryAccountForm";
import { BeneficiaryCreated } from "../domain/BeneficiaryCreated";
import { BeneficiaryForm } from "../domain/BeneficiaryForm";
import { BeneficiaryRepository } from "../domain/BeneficiaryRepository";

const client = createClient();
const beneficiaryPrefixService = "Beneficiary";

export const createApiBeneficiaryRepository = (): BeneficiaryRepository => {
  return {
    getBeneficiaries,
    getBeneficiariesRecent,
    createBeneficiary,
    addBeneficiaryAccount,
    getBeneficiaryId,
    updateBeneficiary,
    deleteAccount,
    deleteBeneficiary,
  };
};

const getBeneficiaries: BeneficiaryRepository["getBeneficiaries"] = async ({
  page,
  perPage,
  country,
  destination,
  search,
  language,
}) => {
  const response = await client.get<BeneficiariesPaginated>(
    `${beneficiaryPrefixService}/${page}/${perPage}`,
    {
      headers: {
        destinationCountry: country,
        destinationId: destination,
        languageCode: language,
      },
      params: {
        search,
      },
    }
  );
  return response.data;
};

const getBeneficiariesRecent: BeneficiaryRepository["getBeneficiaries"] =
  async ({ page, perPage, country, destination, search, language }) => {
    const response = await client.get<BeneficiariesPaginated>(
      `${beneficiaryPrefixService}/frequently/${page}/${perPage}`,
      {
        headers: {
          destinationCountry: country,
          destinationId: destination,
          languageCode: language,
        },
        params: {
          search,
        },
      }
    );
    return response.data;
  };

const createBeneficiary = async (
  beneficiaryData: BeneficiaryForm,
  languageCode: string
) => {
  const response = await client.post<BeneficiaryCreated>(
    `${beneficiaryPrefixService}`,
    {
      ...beneficiaryData,
      account: beneficiaryData.account?.[0],
    },
    {
      headers: {
        languageCode,
      },
    }
  );

  return response.data;
};

const addBeneficiaryAccount = async (accountData: BeneficiaryAccountForm) => {
  const response = await client.patch<BeneficiaryAccountCreated>(
    `${beneficiaryPrefixService}/addaccount/${accountData.beneficiaryId}`,
    accountData
  );

  return response.data;
};

const getBeneficiaryId = async (idBeneficiary: string) => {
  const response = await client.get<BeneficiaryEdit>(
    `Beneficiary/${idBeneficiary}`
  );
  const beneficiary = response.data;
  if (beneficiary.accounts) {
    beneficiary.accountsGrouped = groupAccounts(beneficiary.accounts);
  }

  return beneficiary;
};

const updateBeneficiary = async (data: BeneficiaryUpdate) => {
  const response = await client.patch<BeneficiaryEdit>(
    `/Beneficiary/${data.idBeneficiary}`,
    data.body
  );
  return response.data;
};

const deleteBeneficiary = async (beneficiaryId: string) => {
  const response = await client.delete<BeneficiaryEdit>(
    `/Beneficiary/${beneficiaryId}`
  );
  return response.data;
};

const deleteAccount = async (beneficiaryId: string, accountId: string) => {
  const response = await client.delete<BeneficiaryEdit>(
    `/Beneficiary/${beneficiaryId}/${accountId}`
  );

  return response.data;
};
