import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { defaultErrorMessage } from "../../Constants/Requests";
import { GET_LANGUAGE_SETTINGS } from "../../Constants/Language";

export const GetLanguageSettingAction = createAsyncThunk(
  GET_LANGUAGE_SETTINGS,
  async (_, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(`/users/language`);
      if (!response.data.data.language) {
        response.data.data.language = "en";
      }
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data.message);
      }

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
