import { useState, useEffect, useMemo, useRef } from "react";
import { SearchSelect } from "../SearchSelect/SearchSelect.component";
import { format, getDaysInMonth, getYear } from "date-fns";
import { FlexContainer } from "../Flex/Flex.styled";
import Text from "@components/Text";
import { enUS, es } from "date-fns/locale";
import { useSelector } from "react-redux";
import { selectorLanguage } from "../../Redux/Translate";
import { useTranslation } from "react-i18next";
import { currencySelectStyles } from "./CurrencySelectTheme";
import { FieldErrors, FieldValues } from "react-hook-form";
import Icon from "../Icon";
import { get } from "lodash";
import { allowedNumbers, allowedOnlyCharacters } from "../../Constants/Regexp";
import { CustomSelectInstance } from "@models/Select";

const locales = { en: enUS, es: es };

interface DateInputProps {
  name: string;
  value: string;
  defaultValue?: string;
  label?: string;
  errors?: FieldErrors<FieldValues>;
  maxYearAllowed?: number;
  onChange?: (value: string) => void;
}

export const DateInput = ({ name, value, defaultValue, label, errors, maxYearAllowed, onChange }: DateInputProps) => {
  const [t] = useTranslation("global");
  const { language } = useSelector(selectorLanguage);
  const yearRef = useRef<CustomSelectInstance>(null);
  const monthRef = useRef<CustomSelectInstance>(null);
  const dayRef = useRef<CustomSelectInstance>(null);

  const [month, setMonth] = useState(defaultValue ? defaultValue?.split("-")?.[1] || "" : "");
  const [day, setDay] = useState(defaultValue ? defaultValue?.split("-")?.[2] || "" : "");
  const [year, setYear] = useState(defaultValue ? defaultValue?.split("-")?.[0] || "" : "");
  const maxYear = maxYearAllowed || getYear(new Date()) - 18;
  const locale = locales[language as keyof typeof locales] || locales.en;

  const daysInMonth = (month: string, year: string) => {
    const monthIndex = monthsOptions.findIndex((m) => m.value === month);
    return getDaysInMonth(new Date(Number(year), monthIndex));
  };

  const monthsOptions = useMemo(
    () =>
      Array.from({ length: 12 }, (_, i) => ({
        label: format(new Date(0, i), "MMM", { locale }),
        value: String(i + 1).padStart(2, "0"),
      })),
    [locale]
  );

  const daysInMonthOptions = useMemo(
    () =>
      Array.from({ length: daysInMonth(month, year) }, (_, i) => ({
        label: String(i + 1).padStart(2, "0"),
        value: String(i + 1).padStart(2, "0"),
      })),
    [month, year]
  );

  const yearsOptions = useMemo(
    () =>
      Array.from({ length: maxYear - 1924 }, (_, i) => ({
        label: String(maxYear - i),
        value: String(maxYear - i),
      })),
    []
  );

  const handleMonthChange = (value: string) => {
    setMonth(value);

    const days = daysInMonth(value, year);
    if (days < Number(day)) {
      setDay("");
      dayRef.current?.clearValue();
    }
  };

  const handleDayChange = (value: string) => {
    setDay(value);
  };

  const handleYearChange = (value: string) => {
    setYear(value);
  };

  useEffect(() => {
    if (value) {
      const [y, m, d] = value.split("-");
      setMonth(m);
      setDay(d);
      setYear(y);
    }
  }, [value]);

  useEffect(() => {
    if (month && day && year && onChange) {
      onChange(`${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`);
    }
  }, [month, day, year, onChange]);

  return (
    <FlexContainer direction="column" gap="17px">
      <Text size={0.5} lineHeight="18px" as="label" weight={500}>
        {label}
      </Text>
      <FlexContainer gap="10px">
        <SearchSelect
          selectRef={monthRef}
          placeholderInput={t("global.month")}
          label={`${name}Month`}
          options={monthsOptions}
          value={monthsOptions.find((s) => s.value === month)}
          searchRegexp={allowedOnlyCharacters}
          onChange={(sel) => {
            handleMonthChange(sel?.value);
          }}
          customStyles={currencySelectStyles}
        />
        <SearchSelect
          selectRef={dayRef}
          placeholderInput={t("global.day")}
          label={`${name}Day`}
          options={daysInMonthOptions}
          value={daysInMonthOptions.find((s) => s.value === day)}
          searchRegexp={allowedNumbers}
          onChange={(sel) => {
            handleDayChange(sel?.value);
          }}
          customStyles={currencySelectStyles}
        />
        <SearchSelect
          selectRef={yearRef}
          placeholderInput={t("global.year")}
          label={`${name}Year`}
          options={yearsOptions}
          value={yearsOptions.find((s) => s.value === year)}
          searchRegexp={allowedNumbers}
          onChange={(sel) => {
            handleYearChange(sel?.value);
          }}
          customStyles={currencySelectStyles}
        />
      </FlexContainer>
      {errors && get(errors, name) && (
        <FlexContainer p="0px 3px" m="-17px 0 0 0" alignItems="center" gap="5px">
          <Icon icon={"alertTriangle"} color={"error"} size="small" fillIcon={false} />
          <Text size={0.5} color="error" weight={500} margin={0}>
            {get(errors, name)?.message?.toString()}
          </Text>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};
