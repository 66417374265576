import { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { AppDispatch, State } from "../../../Redux/Store";
import { setCompletedStatus } from "../../../Redux/Payroll/PayrollData.slice";
import { PayrollDataSelector, PayrollDataSlice } from '../../../Redux/Payroll/PayrollData.slice';
import { selectorLanguage } from "../../../Redux/Translate";

import Text from "../../../Components/Text";
import Layout from '../../../Components/Layout';
import { Container, Column, Row } from '../../../Components/Grid';
import { PayrollCard, PinWheel } from '../Components';

import ProvidersSrc from "../../../Assets/Img/payroll_providers.png";
import { PinwheelAccountAction, PinwheelLinkTokenAction } from '../../../Redux/Payroll/PayrollData.actions';
import Loader from '../../../Components/Loader';
import { SuccessEventPayload } from '@pinwheel/react-modal';
import { PinwheelAccountData } from '../../../Models/PayrollData';

export const PayrollDirectDeposit = () => {
  const {t} = useTranslation("global");
  const navigate = useNavigate();
  const theme = useSelector((state: State) => state.theme);
  const dispatch = useDispatch<AppDispatch>();
  const pinWheelData = useSelector(PayrollDataSelector).pinwheelData;
  const lang = useSelector(selectorLanguage);

  const [isLoading, setIsLoading] = useState(false);
  
  const getPinWheelLink = async () => {
    setIsLoading(true);
    await dispatch(PinwheelLinkTokenAction(lang.language));
    setIsLoading(false);
  }

  return (
    <ThemeProvider theme={theme}> 
      <Layout textLink={t("buttons.back")} path="/">
        {isLoading && (<Loader full />)}
        <Container>
          <Row>
            <Column span={12} pt={4} pb={6}>
              <Row>
                <Column span={12} lg={7} xl={7} pb={1}>
                  <Text size={3} color="black" weight="600" margin="0 0" align="left">
                    {t("Payroll.menu.title")}
                  </Text>
                </Column>
                <Column span={12} lg={7} xl={7} pb={4}>
                  <PayrollCard 
                    headerTitle={""}
                    title={t("Payroll.menu.providerCard.title")}
                    description={t("Payroll.menu.providerCard.description")}
                    image={<img src={ProvidersSrc} alt="Playroll By Provider" />}
                    btnText={t("Payroll.menu.btnText")}
                    btnHandleClick={getPinWheelLink}
                  />
                  { pinWheelData && (
                    <PinWheel 
                      token={pinWheelData?.token || ''}
                      isActive={Object.keys(pinWheelData).length > 0}
                      handleExit={() => dispatch(PayrollDataSlice.actions.resetState())}
                      handleSuccess={async (event: SuccessEventPayload) => {
                        const accountData: PinwheelAccountData = {
                          "accountId": event.accountId,
                          "platformId": event.platformId,
                          "job": event.job,
                          "action": event.params?.action,
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          "type": event.params?.allocation?.type || '',
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          "typeValue": event.params?.allocation?.value || ''
                        };

                        await dispatch(PinwheelAccountAction(accountData));
                        await dispatch(setCompletedStatus(true));
                        navigate("/");
                      }}
                    />
                  )}
                </Column>
                {/* <Column span={12} lg={7} xl={7}> Share DD is commented
                  <PayrollCard
                    headerTitle={t("Payroll.menu.employerCard.header")}
                    title={t("Payroll.menu.employerCard.title")}
                    description={t("Payroll.menu.employerCard.description")}
                    image={<img src={EmployerIconSrc} alt="Playroll By Employer" />}
                    btnText={t("Payroll.menu.btnText")}
                    btnHandleClick={() => navigate("/payroll-instructions")}
                  />
                </Column> */}
              </Row>
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  )
}