import { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { AppDispatch, State } from "../../../Redux/Store";
import { GetIntegrationStatus } from "../../../Redux/GetIntegrationStatus/GetIntegrationStatus.actions";
import { veriffIntegrationSelector } from "../../../Redux/GetIntegrationStatus/GetIntegrationStatus.slice";
import { GET_VERIFF_STATUS_FULFILLED } from "../../../Constants/Veriff";
import { setSteps } from "../../../Redux/Onboarding/Onboarding.slice";

import { JustifyContent } from "../../../Models/Column";
import { Step } from "../../../Models/Onboarding";

import { Column, Container, Row } from "../../../Components/Grid";
import Title from "../../../Components/Title";
import Button from "../../../Components/Button";
import Layout from "../../../Components/Layout";
import Icon from "../../../Components/Icon";
import StepsRoutes from "../../../Components/StepsRoutes";
import LoaderIcon from "../../../Components/LoaderIcon/LoaderIcon.component";

import IdImg from "../../../Assets/Img/id.png";
import Visa from "../../../Assets/Img/visa.png";
import Visa1 from "../../../Assets/Img/visa1.png";
import Visa2 from "../../../Assets/Img/visa2.png";
import Visa3 from "../../../Assets/Img/visa3.png";
import Visa4 from "../../../Assets/Img/visa4.png";

import {
  ContainerText,
  MessageContainer,
  ContainerVisa,
  VisaInstructionsContainer,
  ButtonCancel
} from "./VisaVerification.styled";

const VisaVerificationVeriff = () => {
  const theme = useSelector((state: State) => state.theme);
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const veriffIntegrationStatus = useSelector(veriffIntegrationSelector);

  const [visaInstructions, showVisaInstructions] = useState(false);

  const validateVisaStatus = async () => { 
    const response = await dispatch(GetIntegrationStatus("visa"))

    if (response?.type === GET_VERIFF_STATUS_FULFILLED && ["approved", "submitted"].includes(response?.payload?.status)) {
      dispatch(setSteps({
        lastStep: Step.visa,
        nextStep: null
      }))
      navigate("/kyc-submit-kyc");
    }
  }

  useEffect(() => {
    validateVisaStatus()
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Layout show={veriffIntegrationStatus.loading}>
        <LoaderIcon
          title={t("loaderText.title")}
          text={t("loaderText.subtitle")}
        />
      </Layout>
      <Layout show={!veriffIntegrationStatus.loading}>
        {!visaInstructions &&
          <Container>
            <Row>
              <Column span={12} mb={5}>
                <StepsRoutes totalSteps={8} stepIndex={6} />
              </Column>
            </Row>
            <Row>
              <Column span={12} justifyContent={JustifyContent.center} my={5}>
                <Title
                  tagName="h2"
                  color="black"
                  size={4}
                  text={t("VisaVerification.title")}
                />
              </Column>
              <Column span={12} justifyContent={JustifyContent.center} mb={5}>
                <img src={IdImg} alt="id"/>
              </Column>
              <Column span={12} justifyContent={JustifyContent.center} mt={5}>
                <Button
                  text={t("buttons.next")}
                  type="button"
                  sizeButton="large"
                  sizeText="medium"
                  variant="primary"
                  onClick={() => showVisaInstructions(true)}
                  viewIcon={false}
                />
              </Column>
            </Row>
          </Container>
        }
        {visaInstructions &&
          <Container as={VisaInstructionsContainer}>
            <Row>
              <Column span={12} mt={3}>
                <ButtonCancel onClick={() => showVisaInstructions(false)}>
                  <Icon
                    icon="cancel"
                    size="large"
                    color="black"
                  />
                </ButtonCancel>
              </Column>
              <Column span={12} justifyContent={JustifyContent.center}>
                <Title
                  tagName="h3"
                  color="black"
                  size={4}
                  text={t("VisaVerification.title2")}
                />
              </Column>
              <Column span={12} mt={1}>
                <Title
                  tagName="h3"
                  color="black"
                  size={0.5}
                  text={t("VisaVerification.example")}
                  align="left"
                />
              </Column>
              <Column span={12} my={1}>
                <img src={Visa} alt="visa"/>
              </Column>
              <Column span={12} my={1} justifyContent={JustifyContent.center}>
                <ContainerText>
                  <Icon
                    icon="circleCheck"
                    size="xlarge"
                    color="white"
                    fillIcon={true}
                  />
                  <Title
                    tagName="h4"
                    color="black"
                    size={0.5}
                    text={t("VisaVerification.imgMessage")}
                    align="left"
                  />
                </ContainerText>
              </Column>
              <Column span={12} mt={1}>
                <Title
                  tagName="h3"
                  color="black"
                  size={0.5}
                  text={t("VisaVerification.badExample")}
                  align="left"
                />
              </Column>
              <>
                <Column span={6} mt={1} justifyContent={JustifyContent.center} as={ContainerVisa}>
                  <img src={Visa1} alt="visa"/>
                  <MessageContainer>
                    <Icon
                      icon="circleX"
                      size="large"
                      color="white"
                    />
                    <Title
                      tagName="h4"
                      color="black"
                      size={0.5}
                      text={t("VisaVerification.img1")}
                      align="left"
                    />
                  </MessageContainer>
                </Column>
                <Column span={6} mt={1} justifyContent={JustifyContent.center} as={ContainerVisa}>
                  <img src={Visa2} alt="visa"/>
                  <MessageContainer>
                    <Icon
                      icon="circleX"
                      size="large"
                      color="white"
                    />
                    <Title
                      tagName="h4"
                      color="black"
                      size={0.5}
                      text={t("VisaVerification.img2")}
                      align="left"
                    />
                  </MessageContainer>
                </Column>
                <Column span={6} mt={1} justifyContent={JustifyContent.center} as={ContainerVisa}>
                  <img src={Visa3} alt="visa"/>
                  <MessageContainer>
                    <Icon
                      icon="circleX"
                      size="large"
                      color="white"
                    />
                    <Title
                      tagName="h4"
                      color="black"
                      size={0.5}
                      text={t("VisaVerification.img3")}
                      align="left"
                    />
                  </MessageContainer>
                </Column>
                <Column span={6} mt={1} justifyContent={JustifyContent.center} as={ContainerVisa}>
                  <img src={Visa4} alt="visa" className="visa4"/>
                  <MessageContainer>
                    <Icon
                      icon="circleX"
                      size="large"
                      color="white"
                    />
                    <Title
                      tagName="h4"
                      color="black"
                      size={0.5}
                      text={t("VisaVerification.img4")}
                      align="left"
                    />
                  </MessageContainer>
                </Column>
              </>
              <Column span={12}>
                <Button
                  variant="primary"
                  text={t("VisaVerification.buttonTake")}
                  sizeText="medium"
                  sizeButton="xlarge"
                  onClick={() => navigate("/kyc-veriff-visa-photo")}
                />
                <Button
                  variant="outline"
                  text={t("VisaVerification.buttonUpload")}
                  sizeText="medium"
                  sizeButton="xlarge"
                  onClick={() => navigate("/kyc-veriff-upload-visa")}
                />
              </Column>
            </Row>
          </Container>
        }
      </Layout>
    </ThemeProvider>
  )
};

export default VisaVerificationVeriff;