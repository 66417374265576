import {
  PaymentOrder,
  PaymentOrderDetailResponse,
  PaymentOrderSenderEmail,
  PaymentOrderVerifications,
  PaymentTransactionHistory,
  PaymentTransactions,
} from "./PaymentOrder";
import { PaymentOrderCreated, PaymentOrderPayload, PaymentOrderTracking } from "./PaymentOrderCreated";
import {
  PlaidAccountsDelete,
  PlaidAccountsDetail,
  PlaidAccountsResponse,
  PlaidAccountsUpdateRequest,
  PlaidCreateToken,
  PlaidCreateTokenStatus,
  PlaidTokenExchangeRequest,
  PlaidTokenExchangeResponse,
} from "./Plaid";
import {
  PrivacyPolicyStatus,
  SenderPrivacyPolicyStatus,
  SendolaCredentials,
  SendolaPayTransaction,
  SendolaPayTransactionDetail,
  ShowSendolaPay,
} from "./SendolaPay";
import { SquarePaymentOrder } from "./Square";
import { PayPalOrder, PayPalOrderCapture } from "./PayPal";
import { VerifyPaymentOrder, VerifyPaymentOrderAPIResponse } from "./VerifyPaymentOrder";
import { AuthorizeCreateOrder, AuthorizeResponse } from "./Authorize";
import { Codes } from "./VelocityLimit";

export interface PaymentOrderRepository {
  getPaymentOrders: () => Promise<PaymentOrder[]>;
  createPaymentOrder: (payload: PaymentOrderPayload) => Promise<PaymentOrderCreated>;
  getPaymentOrder: (id: string) => Promise<PaymentOrderDetailResponse>;
  verifyPaymentOrder: (payload: VerifyPaymentOrder) => Promise<VerifyPaymentOrderAPIResponse>;
  getVelocityTxn: () => Promise<any>;
  getVelocityAmountTxn: (amount: number) => Promise<undefined>;
  getVelocityTsxSendolaPay: () => Promise<any>;
  getVelocityTsxDestination: (amount: number, destinationId: string | null | undefined) => Promise<any>;
  termsConditionsUniteller: () => Promise<PaymentOrderVerifications>;
  saveSenderEmail: (email: string, personId?: string) => Promise<PaymentOrderSenderEmail>;
  getPaymentOrdersTransaction: (payload: PaymentTransactions) => Promise<PaymentTransactionHistory>;
  getPaymentOrderTracking: (id: string) => Promise<PaymentOrderTracking[]>;
}

export interface PaymentOrderWithSendolaPayRepository {
  getShowSendolaPay: () => Promise<ShowSendolaPay>;
  getPrivacypolicyStatus: () => Promise<PrivacyPolicyStatus>;
  senderPrivacypolicyStatus: () => Promise<SenderPrivacyPolicyStatus>;
  senderCredentialsSendolaOne: (personId: string, body: SendolaCredentials) => Promise<SenderPrivacyPolicyStatus>;
  sendolaPayTransactionDetail: (personId: string, body: SendolaPayTransaction) => Promise<SendolaPayTransactionDetail>;
}

export interface PaymentOrderWithPlaidRepository {
  getAccounts: (languageCode: string) => Promise<PlaidAccountsResponse>;
  getAccountDetails: (accountId: string, languageCode: string) => Promise<PlaidAccountsDetail>;
  deleteAccount: (accountId: string, languageCode: string) => Promise<PlaidAccountsDelete>;
  updateAccount: (body: PlaidAccountsUpdateRequest, languageCode: string) => Promise<PlaidCreateTokenStatus>;
  createToken: (languageCode: string, body?: PlaidCreateToken) => Promise<PlaidCreateTokenStatus>;
  exchangeToken: (body: PlaidTokenExchangeRequest, languageCode: string) => Promise<PlaidTokenExchangeResponse>;
}

export interface PaymentOrderWithSquareRepository {
  createPaymentLink: (id: string, paymentMethodFee: number) => Promise<SquarePaymentOrder>;
}

export interface PaymentOrderWithPayPalRepository {
  createOrder: (amount: number, transactionId: string) => Promise<PayPalOrder>;
  captureOrder: (paypalOrderId: string) => Promise<PayPalOrderCapture>;
}

export interface PaymentOrderWithAuthorizeRepository {
  createOrder: (order: AuthorizeCreateOrder) => Promise<any>;
  getCardsByPersonId: (personId: string) => Promise<AuthorizeResponse>;
}

export const sortVelocityLimits = (codes: Codes[]) => {
  return codes.sort((a, b) => {
    const isMonthlyA = a.msg.toLowerCase().includes("monthly");
    const isMonthlyB = b.msg.toLowerCase().includes("monthly");

    if (isMonthlyA !== isMonthlyB) {
      return Number(isMonthlyB) - Number(isMonthlyA);
    }

    const codeA = parseInt(a.code.slice(2));
    const codeB = parseInt(b.code.slice(2));

    return codeA - codeB;
  });
};
