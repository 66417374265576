import styled from "styled-components"
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

export const FlagDestinationContainer = styled.div`
  margin: 8px 0;
  position: relative;
`;

export const OriginFlag = styled.div`
  border-radius: 50%;
  height: 31px;
  width: 31px;
`;

export const DestinationFlag = styled.div`
  border-radius: 50%;
  height: 24px;
  position: absolute;
  right: 10%;
  top: 50%;
  width: 24px;

  ${mediaQuery[Screen.mobile]} {
    right: -5px;
  }
`;