import { createSlice } from "@reduxjs/toolkit";
import { GetPendingSSNAction } from "./PendingSSN.actions";
import { State } from "../Store";
import { GetPendingSSNState } from "../../Models/PendingSSN";

const initialState: GetPendingSSNState = {
  loading: false,
  error: null,
  data: null
}

export const GetPendingSSNSlice = createSlice({
  name: "getPendingSSN",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetPendingSSNAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetPendingSSNAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(GetPendingSSNAction.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export default GetPendingSSNSlice.reducer;

export const GetPendingSSNSelector = (state: State) => state.getPendingSSN;
