import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Own redux
import { selectorLanguage } from "../../Redux/Translate";

// Own constants
import { WhatsAppLinkEn, WhatsAppLinkEs } from "../../Constants/ConfigureApi";
import { FaqsLinkEN, FaqsLinkES } from "../../Constants/LinksExternals";

// Own components
import Icon from "../Icon";
import Link from "../Link";

// Own styles
import { ContainerLink } from "./HelpLinks.styled";

const HelpLinks = () => {
  const [t] = useTranslation("global");
  const lang = useSelector(selectorLanguage);
  const FaqsLink = lang.language === "en" ? FaqsLinkEN : FaqsLinkES;
  const WhatsAppLink = lang.language === "en" ? WhatsAppLinkEn : WhatsAppLinkEs;

  return (
    <div>
      <ContainerLink>
        <Icon icon="whatsApp" size="medium" />
        <Link href={WhatsAppLink} size={0.3}>
          {t("HelpLinks.whatsApp")}
        </Link>
      </ContainerLink>
      <ContainerLink>
        <Icon color="black" icon="mail" size="medium" />
        <Link href="mailto:support@sendola.io" size={0.3} variant="none">
          {t("HelpLinks.mail")}
        </Link>
      </ContainerLink>
      <ContainerLink>
        <Icon color="black" icon="phone" size="medium" />
        <Link href={"tel:2816388018"} size={0.3} variant="none">
          {t("HelpLinks.phone")}
        </Link>
      </ContainerLink>
      <ContainerLink>
        <Icon color="black" icon="faqs" size="medium" />
        <Link href={FaqsLink} size={0.3} variant="none" target="_blank">
          {t("HelpLinks.faqs")}
        </Link>
      </ContainerLink>
    </div>
  );
};

export default HelpLinks;
