import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import {
  useNavigate,
  useLocation,
  Navigate
} from "react-router-dom";

// own states
import { AppDispatch, State } from "../../../Redux/Store";
import { selectorLanguage } from "../../../Redux/Translate";
import { 
  CreateBankTransferAction,
  PlaidAccountDetailsAction,
  PlaidDeleteAccountAction,
} from "../../../Redux/FundsAccount/FundsAccount.actions";

// Own components
import Button from "../../../Components/Button";
import Layout from "../../../Components/Layout";
import { Column, Container, Row } from "../../../Components/Grid";
import Loader from "../../../Components/Loader";
import Icon from "../../../Components/Icon";
import PlaidLink from "../Components/PlaidLink";
import {
  UnlinkAccountModal,
  UnlinkAccountSuccessModal
} from "../Components/UnlinkAccountModal";

// Own Models
import { AlignItems, JustifyContent } from "../../../Models/Column";
import { PlaidAccountsDetail } from "../../../Models/Funds";
import { CurrentAccount } from "../../../Models/Account";

import { 
  CustomText,
  CustomTitle,
  LinkedAccountContainer,
  LinkedAccountExtraUpdate,
  LinkedAccountsDetails,
  LinkedAccountsDetailsDivider
} from "./LinkedAccounts.styles";
import { 
  CREATE_BANK_TRANSFER_FULFILLED, 
  CREATE_BANK_TRANSFER_REJECTED, 
  DELETE_PLAID_ACCOUNT_FULFILLED, 
  GET_PLAID_ACCOUNT_DETAILS_FULFILLED 
} from "../../../Constants/FundsAccount";
import { setCurrentPlaidAccount } from "../../../Redux/FundsAccount/FundsAccount.slice";

type CurrentAccountProps = {
  amount: string;
  data: CurrentAccount;
}

const FundsLinkedAccountsDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [t] = useTranslation("global");
  const lang = useSelector(selectorLanguage);
  const { state } = useLocation();

  const theme = useSelector((state: State) => state.theme);
  const accountDetail = useSelector((state: State) => state.FundsAccount?.plaidAccountDetails);
  const currentAccountStore = useSelector((state: State) => state.Accounts).accountSelected as CurrentAccountProps;
  const personId = useSelector((state: State) => state.ByUser.data)?.personId || '';
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  
  const unlinkAccount = async () => {
    setIsLoading(true);
    setShowUnlinkModal(false);

    const response = await dispatch(PlaidDeleteAccountAction({
      accountId: state?.accountId,
      languageCode: lang.language
    }));

    if(response?.type === DELETE_PLAID_ACCOUNT_FULFILLED) {
      setShowModalSuccess(true);
    }

    setIsLoading(false);
  };

  const unlinkSuccessModal = () => {
    setShowModalSuccess(false);
    navigate("/funds-linked-accounts", { replace: true });
  }

  const getAccountDetails = async () => {
    setIsLoading(true);

    if (state?.firstAccount || state?.isConfirmed) {
      dispatch(setCurrentPlaidAccount(null));

      const responseDetails = await dispatch(PlaidAccountDetailsAction({
        accountId: state?.accountId,
        languageCode: lang.language
      })); 

      if(responseDetails.type === GET_PLAID_ACCOUNT_DETAILS_FULFILLED) {
        const plaidAccountDetail = responseDetails.payload as PlaidAccountsDetail;
        dispatch(setCurrentPlaidAccount(plaidAccountDetail));
      }
    }
    setIsLoading(false);
  }

  const setPayment = async () => {
    setIsLoading(true);

    if (accountDetail) {
      if (accountDetail?.balance.available < Number(currentAccountStore.amount)) {
        return navigate(
          "/funds-error", 
          { state: { isInsufficientFunds: true }, replace: true }
        );
      }

      dispatch(setCurrentPlaidAccount(accountDetail));

      const body = {
        contactId: null,
        destinationAccountNumber: accountDetail.account,
        destinationRoutingNumber: accountDetail.routing,
        sendolaPlaidAccountId: accountDetail.accountId,
        accountId: currentAccountStore.data.id, 
        amount: Number(currentAccountStore.amount),
        transferOrderType: "ach",
        status: "pending",
        txnType: "credit",
      };

      const response = await dispatch(CreateBankTransferAction({
        personId,
        body
      }));

      if(response.type === CREATE_BANK_TRANSFER_FULFILLED) {
        navigate("/funds-confirmation", { replace: true });
      }

      if(response.type === CREATE_BANK_TRANSFER_REJECTED) {
        navigate("/funds-error", { replace: true });
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getAccountDetails();
  }, []);

  if (!state?.accountId) {
    return <Navigate to="/funds-linked-accounts" replace />;
  }

  return (
    <ThemeProvider theme={theme}>
      {isLoading && <Loader full />}
      <Layout
        path={!state.firstAccount ? "/funds-linked-accounts" : null}
        textLink={t("buttons.back")}
      >
        <Container as={LinkedAccountContainer}>
          <Row>
            <Column
              span={12}
              pb={8}
              px={1}
              alignItems={AlignItems.center}
              justifyContent={JustifyContent.center}
            >
              <Row>
                {(state.firstAccount || state.isConfirmed) && (
                    <>
                      <Column span={12} mt={5}>
                        <CustomTitle
                          text={t(
                            "Funds.LinkedAccounts.details.linkedAccountTitle"
                          )}
                          size={3}
                          tagName="h2"
                          color="black"
                          align="left"
                        />
                        <CustomText
                          size={1}
                          color="grey"
                          weight={400}
                          margin="5px 0 24px"
                          align="left"
                        >
                          {t(
                            "Funds.LinkedAccounts.details.linkedAccountDescription"
                          )}
                        </CustomText>
                      </Column>
                      <Column
                        span={12}
                        justifyContent={JustifyContent.center}
                        mt={2}
                        mb={4}
                      >
                        <Icon icon="linkedAccount" size="xlarge" />
                      </Column>
                    </>
                  )}
                <Column
                  span={12}
                  mt={state.firstAccount || state.isConfirmed ? 0 : 4}
                  p={4}
                  as={LinkedAccountsDetails}
                >
                  <CustomTitle
                    text={accountDetail?.bankName || ""}
                    size={2}
                    tagName="h2"
                    color="black"
                    align="left"
                  />
                  <CustomText
                    size={1}
                    color="grey"
                    weight={500}
                    margin="5px 0 24px"
                    align="left"
                  >
                    {t("Funds.LinkedAccounts.details.title")}
                  </CustomText>
                  <CustomText
                    size={0.5}
                    color="black"
                    weight={600}
                    margin="5px 0"
                    align="left"
                  >
                    {t("Funds.LinkedAccounts.details.accountType")}
                  </CustomText>
                  <LinkedAccountsDetailsDivider />
                  <CustomText
                    size={0.5}
                    color="grey"
                    weight={500}
                    margin="5px 0 20px"
                    align="left"
                  >
                    {accountDetail?.subtype || ""}
                  </CustomText>
                  <CustomText
                    size={0.5}
                    color="black"
                    weight={600}
                    margin="5px 0"
                    align="left"
                  >
                    {t("Funds.LinkedAccounts.details.accountNumber")}
                  </CustomText>
                  <LinkedAccountsDetailsDivider />
                  <CustomText
                    size={0.5}
                    color="grey"
                    weight={500}
                    margin="5px 0 20px"
                    align="left"
                  >
                    {`**********${accountDetail?.accountLastFour || ""}`}
                  </CustomText>
                  <CustomText
                    size={0.5}
                    color="black"
                    weight={600}
                    margin="5px 0"
                    align="left"
                  >
                    {t("Funds.LinkedAccounts.details.routingNumber")}
                  </CustomText>
                  <LinkedAccountsDetailsDivider />
                  <CustomText
                    size={0.5}
                    color="grey"
                    weight={500}
                    margin="5px 0 20px"
                    align="left"
                  >
                    {`*****${accountDetail?.routingLastFour || ""}`}
                  </CustomText>
                  <CustomText
                    size={0.5}
                    color="black"
                    weight={600}
                    margin="5px 0"
                    align="left"
                  >
                    {t("Funds.LinkedAccounts.details.status")}
                  </CustomText>
                  <LinkedAccountsDetailsDivider />
                  { state?.requiredUpdate ? (
                    <Row>
                      <Column span={12} justifyContent={JustifyContent.flexStart}>
                        <LinkedAccountExtraUpdate>
                          <Icon
                            icon={"alertRedTriangle"}
                            color={"error"}
                            size="medium"
                            fillIcon={false}
                          />
                          <CustomText
                            size={0.5}
                            color="error"
                            weight={600}
                            margin="10px 0 10px 10px"
                          >
                            {t("Funds.LinkedAccounts.cardNeedsUpdate")}
                          </CustomText>
                        </LinkedAccountExtraUpdate>
                      </Column>
                    </Row>
                  ) : (
                    <CustomText
                      size={0.5}
                      color="grey"
                      weight={500}
                      margin="5px 0 20px"
                      align="left"
                    >
                      {accountDetail?.status}
                    </CustomText>
                  )}
                </Column>
                <Column
                  span={12}
                  justifyContent={JustifyContent.center}
                  mt={4}
                  mb={6}
                >
                  {state.firstAccount || state.isConfirmed ? (
                    <Row>
                      <Column span={12} justifyContent={JustifyContent.center}>
                        <Button
                          onClick={() => setPayment()}
                          variant={"primary"}
                          sizeButton="xlarge"
                          sizeText="large"
                          sizeIcon="large"
                          text={t(
                            "Funds.LinkedAccounts.details.linkedAccountBtnConfirm"
                          )}
                        />
                      </Column>
                      <Column span={12} justifyContent={JustifyContent.center}>
                        <Button
                          onClick={() =>
                            navigate("/funds-linked-accounts", {
                              replace: true,
                            })
                          }
                          variant={"outline"}
                          sizeButton="xlarge"
                          sizeText="large"
                          sizeIcon="large"
                          text={t(
                            "Funds.LinkedAccounts.details.linkedAccountBtnCancel"
                          )}
                        />
                      </Column>
                    </Row>
                  ) : (
                    <>
                      {state.requiredUpdate ? (
                        <>
                          <PlaidLink
                            requiredUpdate={state.requiredUpdate}
                            updateData={{
                              redirectUri: "https://prueba.example.com",
                              accountId: state?.accountId,
                            }}
                            btnText={t("Funds.LinkedAccounts.details.btnRelink")}
                            onCompleted={() =>
                              navigate("/funds-linked-accounts", {
                                replace: true,
                              })
                            }
                          />
                        </>
                      ) : (
                        <Button
                          onClick={() => setShowUnlinkModal(true)}
                          variant={"outline"}
                          sizeButton="large"
                          sizeText="large"
                          sizeIcon="large"
                          text={t("Funds.LinkedAccounts.details.btnUnlink")}
                        />
                      )}
                    </>
                  )}
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
        <UnlinkAccountModal
          show={showUnlinkModal}
          title={t("Funds.LinkedAccounts.modalUnlink.title")}
          message={t("Funds.LinkedAccounts.modalUnlink.subtitle")}
          handleConfirm={() => unlinkAccount()}
          handleCancel={() => setShowUnlinkModal(false)}
        >
          <CustomText
            size={1}
            color="black"
            weight={600}
            margin="5px 0 20px"
            align="center"
          >
            {accountDetail?.bankName}{" "}
            {`*********${accountDetail?.accountLastFour}`}
          </CustomText>
        </UnlinkAccountModal>
        <UnlinkAccountSuccessModal
          title=""
          message=""
          show={showModalSuccess}
          handleConfirm={() => unlinkSuccessModal()}
        >
          <CustomText
            size={3}
            color="black"
            weight={600}
            margin="-24px 0 20px"
            align="center"
          >
            {t("Funds.LinkedAccounts.modalUnlink.successTitle")}
          </CustomText>
        </UnlinkAccountSuccessModal>
      </Layout>
    </ThemeProvider>
  );
};

export default FundsLinkedAccountsDetails;
