import { useTranslation } from "react-i18next";

import Modal from "../Modal.component";
import Text from "../../Text";
import Button from "../../Button";
import { CodeKeys, VelocityLimitModalProps } from "../../../Models/ModalInterface";
import IconFont from "../../IconFont";
import { MarginIcon } from "../Modal.styled";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectorLanguage } from "../../../Redux/Translate";
import { formatNumber } from "@utils/NumberUtils";

export const VelocityLimitModal = ({ codesMessages, ...props }: VelocityLimitModalProps) => {
  const [tModals] = useTranslation("modals");
  const [errorsTimeout, setErrorsTimeout] = useState<{ [key: string]: string }>({});
  const lang = useSelector(selectorLanguage);

  const messages: CodeKeys = useMemo(
    () => ({
      VL001: tModals("velocityLimits.messagetxnDaily"),
      VL002: tModals("velocityLimits.messageTxnMonthly"),
      VL003: tModals("velocityLimits.messageAmountDaily"),
      VL004: tModals("velocityLimits.messageAmountMonthly"),
      VL005: tModals("velocityLimits.messageAmount", {
        amount: formatNumber(Number(codesMessages?.find((item) => item.code === "VL005")?.value || "0")),
      }),
      VL007: tModals("velocityLimits.messageMinAmountDestination"),
      VL008: tModals("velocityLimits.messageAmount", {
        amount: formatNumber(Number(codesMessages?.find((item) => item.code === "VL008")?.value || "0")),
      }),
      VL009: tModals("velocityLimits.messageAmountDestinationDaily", {
        limit: formatNumber(Number(codesMessages?.find((item) => item.code === "VL009")?.value || "0")),
      }),
      VL010: tModals("velocityLimits.messageAmountDestinationMonthly"),
      VL013: tModals("velocityLimits.messageCountrySubdivisionDaily", {
        limit: formatNumber(Number(codesMessages?.find((item) => item.code === "VL013")?.value || "0")),
      }),
      VL014: tModals("velocityLimits.messageCountrySubdivisionMonthly", {
        limit: formatNumber(Number(codesMessages?.find((item) => item.code === "VL014")?.value || "0")),
      }),
      VL015: tModals("velocityLimits.messageCountrySubdivisionDaily", {
        limit: formatNumber(Number(codesMessages?.find((item) => item.code === "VL015")?.value || "0")),
      }),
      VL016: tModals("velocityLimits.messageCountrySubdivisionMonthly", {
        limit: formatNumber(Number(codesMessages?.find((item) => item.code === "VL016")?.value || "0")),
      }),
      VL017: tModals("velocityLimits.messageBasicKYCLimitDaily", {
        limit: formatNumber(Number(codesMessages?.find((item) => item.code === "VL017")?.value || "0")),
      }),
      VL018: tModals("velocityLimits.messageBasicKYCLimitMonthly", {
        limit: formatNumber(Number(codesMessages?.find((item) => item.code === "VL018")?.value || "0")),
      }),
      VL019: tModals("velocityLimits.messageBasicKYCDeclined"),
    }),
    [errorsTimeout, codesMessages, lang]
  );

  useEffect(() => {
    if (codesMessages) {
      for (const item of codesMessages) {
        if (item.code === "VF005") {
          setErrorsTimeout((current) => ({
            ...current,
            [item.code]: item.msg,
          }));
        }
      }
    }
  }, [codesMessages]);

  return (
    <Modal {...props}>
      <MarginIcon>
        <IconFont name="alert" size="xlarge" color="error" />
      </MarginIcon>
      {codesMessages &&
        codesMessages.map((item, index) => (
          <Text key={index} size={1} margin="0 0 20px 0">
            {messages[item.code]}
          </Text>
        ))}
      <Button
        variant={"primary"}
        sizeButton="large"
        sizeText="large"
        text={tModals("velocityLimits.next")}
        colorIcon="white"
        sizeIcon="large"
        onClick={() => props.handleClose && props.handleClose()}
      />
    </Modal>
  );
};
