import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { defaultErrorMessage } from "../../Constants/Requests";
import { GlobalPaymentsAPI } from "../../Services/API/GlobalPaymentsApi";
import { CATALOGS_ALL_COUNTRY_GP } from "../../Constants/Countries";
import { AllCountriesData } from "../../Models/Countries";

export const getCatalogsAllCountryAction = createAsyncThunk<
  AllCountriesData[],
  { languageCode: string; sponsorBank?: string }
>(CATALOGS_ALL_COUNTRY_GP, async ({ languageCode, sponsorBank }, thunkAPI) => {
  try {
    const headers = {
      languageCode,
      sponsorBank,
    };
    const response = await GlobalPaymentsAPI.get<AllCountriesData[]>(`/gpcatalogs/country`, {
      headers,
    });

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError)
      return thunkAPI.rejectWithValue(error.response?.data?.message);

    return thunkAPI.rejectWithValue(defaultErrorMessage);
  }
});
