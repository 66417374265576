import styled from "styled-components";
import { theme } from "styled-tools";

export const CoppelAccess = styled.div<{$isYellow: boolean}>`
  background-color: ${(props) => props.$isYellow ? theme("Secondary_Coppel") : theme("Background_01")};
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow:0px 4px 24px 0px rgba(${theme("RGBA_01")}, 0.15);
  padding: 16px 24px;
  height: 230px;
  margin: 24px 8px;
  text-align: left;
  p {
    font-family: Poppins;
  }
`;

export const LinkContainer = styled.a`
  display: flex;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid ${theme("Content_UX_05")};
  background: ${theme("Gradient_Coppel")};
	cursor: pointer;
`;

export const Details = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  p {
    text-decoration: underline;
  }
`;

export const NumberText = styled.p`
  font-size: 10px;
  color: ${theme("Primary_16")};
  margin-bottom: 3px;
  margin-top: 0;
`;

export const ButtonIcon = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const Copy = styled.div<{$show?: boolean}>`
  display: ${(props) => props.$show ? "flex" : "none"};
  padding: 2px 11px 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 0.5px solid ${theme("Background_03")};
  opacity: 0.75;
  background: var(--text-base-600, ${theme("Base_600")});
  box-shadow: 0px 4px 4px 0px rgba(${theme("RGBA_04")});
  margin-bottom: 5px;
  max-height: 23px;
  margin-top: -2px;
`;

export const ContainerCopy= styled.div`
  height: 18px;
`;

export const ButtonContainer = styled.div`
  button {
    margin-inline: 0;
    margin-block: 0;
  }
`;

export const TitleCard= styled.div`
  p {
    font-size: 10px;
  }
`;