import { Flex } from "../Flex"
import { CardBanner, ContainerInfo, ContainerText, FeeContainer, IconContainer } from "./BannerAccount.styled"
import Text from "../Text"
import Icon from "../Icon"
import { BannerAccountProps } from "../../Models/BannerAccount"

const BannerAccount = ({
  typeAccount,
  feeText,
  title,
  message,
  onClickAccount,
  imgBox
}: BannerAccountProps) => {

  return (
    <CardBanner $type={typeAccount} onClick={onClickAccount}>
      <FeeContainer $type={typeAccount}><span>{feeText}</span></FeeContainer>
      <Flex justifyContent="flex-start" alignItems="center" as={ContainerInfo}>
        <div>
          {imgBox}
        </div>
        <ContainerText $type={typeAccount}>
          <Text
            size={0.5}
            margin="0 0 2px 0"
            weight={500}
            align="left"
            color="black_highlight"
          >
            {title}
          </Text>
          <p className="message">
            {message}
          </p>
        </ContainerText>
        <IconContainer>
          <Icon icon="chevronRight" size="medium" color={typeAccount === "bannerBank" ? "grey_08" : "black_highlight"} />
        </IconContainer>
      </Flex>
    </CardBanner>
  )
}

export default BannerAccount