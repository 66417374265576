import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormInput } from "@components/Payments/Input";
import { allowedCompoundNames, allowedNonSpecialCharacters } from "@constants/Regexp";
import { Controller, useFormContext } from "react-hook-form";
import { DateInput } from "@components/DateInput/DateInput.component";

export const ProfileForm = () => {
  const { t } = useTranslation("global");
  const { control, formState, getValues } = useFormContext();

  const { errors } = formState;

  const formValues = useMemo(() => {
    return getValues();
  }, [formState]);

  return (
    <>
      <FormInput
        placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.FirstName")}
        label="firstName"
        rules={{
          required: t("Forms.required"),
          pattern: {
            value: allowedNonSpecialCharacters,
            message: t("Forms.invalidCharacters"),
          },
          maxLength: {
            value: 50,
            message: t("Forms.maxLength", { max: 50 }),
          },
          validate: (value) => (value.trim() === "" ? t("Forms.required") : true),
        }}
        mb="15px"
        isError={Boolean(errors?.["name"])}
      />
      <FormInput
        placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.MiddleName")}
        label="middleName"
        rules={{
          pattern: {
            value: allowedCompoundNames,
            message: t("Forms.invalidCharacters"),
          },
          maxLength: {
            value: 50,
            message: t("Forms.maxLength", { max: 50 }),
          },
        }}
        mb="15px"
        isError={Boolean(errors?.["name"])}
      />
      <FormInput
        placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.LastName")}
        label="lastName"
        rules={{
          required: t("Forms.required"),
          pattern: {
            value: allowedCompoundNames,
            message: t("Forms.inputAlphabets"),
          },
          maxLength: {
            value: 100,
            message: t("Forms.maxLength", { max: 100 }),
          },
          validate: (value) => (value.trim() === "" ? t("Forms.required") : true),
        }}
        mb="15px"
        isError={Boolean(errors?.["lastName"])}
      />

      <Controller
        control={control}
        name="dateOfBirth"
        rules={{ required: t("Forms.required") }}
        render={({ field: { value, onChange } }) => (
          <DateInput
            label={t("Beneficiaries.CreateBeneficiary.Form.DateOfBirth")}
            name="dateOfBirth"
            defaultValue={formValues?.dateOfBirth}
            value={value}
            errors={errors}
            onChange={onChange}
          />
        )}
      />
    </>
  );
};
