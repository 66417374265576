// Own Components
import Text from "../../Components/Text";
import Title from "../../Components/Title";
import Icon from "../../Components/Icon";
import Button from "../../Components/Button";

// Model
import { CardFoundProps } from "../../Models/CardFound";

import { CardFoundContainer, IconCard } from "./CardFound.styled";
import { Container, Row, Column } from "../Grid";
import { AlignItems, JustifyContent } from "../../Models/Column";

const CardFound = (props: CardFoundProps) => {
  const { titleCard, textCard, type, msgCard, icon, btnText, onClick } = props;

  const messageFooter = () => {
    return (
      <>
        <Icon
          icon="circleInfo"
          color="solid_2"
          fillIcon={false}
          size="medium"
          />
        <Text size={3} color="black" margin="0 4px" weight="600">
          {msgCard}
        </Text>
      </>
    );
  };

  return (
    <Container as={CardFoundContainer} onClick={onClick}>
      <Row>
        <Column span={9}>
          <Title size={5} tagName="h2" color="black" text={titleCard} align="left"/>
          <Text size={2} color="grey" align="left">{textCard}</Text>
        </Column>
        <Column span={3}>
          <IconCard className={type}>
            <Icon icon={icon} color="white" fillIcon={false} size="xlarge" />
          </IconCard>
        </Column>
      </Row>
      <Row>
        <Column span={12} justifyContent={JustifyContent.flexStart} alignItems={AlignItems.center} >
          {btnText &&
            <Button
              variant="primary"
              sizeButton="medium"
              sizeText="medium"
              text={btnText}
              onClick={onClick}
            />
          }
        </Column>
      </Row>
      <Row>
        <Column span={12} justifyContent={JustifyContent.flexStart} alignItems={AlignItems.center} hiddenMobile>
          { msgCard && messageFooter() }
        </Column>
        <Column span={12} justifyContent={JustifyContent.center} alignItems={AlignItems.center} hiddenDesktop>
          { msgCard && messageFooter() }
        </Column>
      </Row>
    </Container>
  );
};

export default CardFound;
