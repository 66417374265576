import Icon from "../Icon";

import { FloatingButton } from "./FloatingZelleButton.styled";
import { updateZelleModal } from "../../Redux/ZelleModal";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Redux/Store";

const FloatingZelleButton = () => {
  const dispatch = useDispatch<AppDispatch>();

  const openModal = async () => {
    await dispatch(updateZelleModal(true));
  };

  return (
    <div>
      <FloatingButton onClick={openModal} id="floatingButton">
        <Icon icon="currencyDollarExchange" color="white" fillIcon={false} size="medium" />
      </FloatingButton>
    </div>
  );
};

export default FloatingZelleButton;
