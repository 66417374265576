import { useEffect, useMemo, useState } from "react";
import { BottomModalWrapper } from "@components/Modal/BottomModal/BottomModal.styled";
import { RecipientModalProps } from "@models/ModalInterface";
import { FlexContainer } from "@components/Flex/Flex.styled";
import Text from "@components/Text";
import Modal from "../Modal.component";
import { FieldValues, FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Icon from "@components/Icon";
import IconFont from "@components/IconFont";
import { ProfileForm } from "@components/Recipients/ProfileForm/ProfileForm.component";
import Button from "@components/Button";
import { ContactForm } from "@components/Recipients/ContactForm/ContactForm.component";
import { AddressForm } from "@components/Recipients/AddressForm/AddressForm.component";
import { useSelector } from "react-redux";
import { QuotationSelector } from "@redux/Payments/Quotation/Quotation.slice";
import { useBeneficiaryAdditionalFields } from "@hooks/Payments/useBeneficiaryAdditionalFields";
import isEmpty from "lodash.isempty";
import { DynamicInput } from "@components/DynamicInput";
import { mapFieldValuesIntoBeneficiaryForm } from "@core/Payments/Beneficiary/domain/BeneficiaryForm";
import { BeneficiaryAccount } from "@components/Recipients";
import { useBankAccount } from "@hooks/Payments/useBankAccount";
import { selectorLanguage } from "@redux/Translate";
import { AccountTypes } from "@core/Payments/Common/domain/Destination";
import { allowedCountries } from "@core/Payments/Common/domain/Countries";
import { getCountryCallingCode, parsePhoneNumber } from "react-phone-number-input";
import { iso31661Alpha2ToAlpha3 } from "iso-3166";
import { useBeneficiary } from "@hooks/Payments/useBeneficiary";
import { ByUserSliceSelector } from "@redux/ByUser/ByUser.slice";
import Loader from "@components/Loader";

export const RecipientModal = ({
  handleClose,
  handleSubmit,
  initialStep = 1,
  recipient,
  isCPU,
  ...props
}: RecipientModalProps) => {
  const [t] = useTranslation("global");
  const { language } = useSelector(selectorLanguage);
  const {
    data: { countryDestination: quoteCountrySelected, currentPaymentDestination },
  } = useSelector(QuotationSelector);
  const user = useSelector(ByUserSliceSelector).data;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const form = useForm<any>({ mode: "all", defaultValues: { country: quoteCountrySelected } });
  const { formState, handleSubmit: formHandleSubmit, getValues, setValue, watch } = form;
  const { isValid } = formState;
  const formValues = useMemo(() => {
    return getValues();
  }, [formState]);
  const [step, setStep] = useState<number>(initialStep);
  const [countrySelected, setCountrySelected] = useState(formValues?.country || quoteCountrySelected);
  const [AccountTypeList, setAccountTypeList] = useState<AccountTypes[]>([]);
  const [phoneCountryCodeSelect, setPhoneCountryCodeSelect] = useState<string | undefined>(countrySelected);
  const [formIsLoading, setFormIsLoading] = useState(false);

  const {
    bankList,
    subdivisionList,
    additionalFields,
    isLoading: bankAccountIsLoading,
    setAdditionalFields,
    setBankList,
    setStatus: setBankAccountStatus,
    getSubdivisionOptions,
  } = useBankAccount();
  const {
    additionalFields: beneficiaryAdditionalFields,
    additionalFieldsAppriza,
    isLoading: additionalFieldsAprizaIsLoading,
    fetchAdditionalFieldsAppriza,
  } = useBeneficiaryAdditionalFields({
    country: countrySelected || "",
    destinationSelected: currentPaymentDestination,
    transferType: "CPU",
    includeBranchFields: false,
    form,
  });
  const {
    beneficiaryInformationId,
    isLoading: beneficiaryIsLoading,
    getBeneficiaryInformation,
  } = useBeneficiary({ form: form as unknown as UseFormReturn<FieldValues> });

  const isLoading = formIsLoading || bankAccountIsLoading || additionalFieldsAprizaIsLoading || beneficiaryIsLoading;

  const hasAdditionalFields = useMemo(() => {
    return !isEmpty(additionalFieldsAppriza) || !isEmpty(beneficiaryAdditionalFields);
  }, [additionalFieldsAppriza, beneficiaryAdditionalFields]);

  const destinationIdSelected = watch("destination", null);

  const nextStep = () => {
    if (beneficiaryAdditionalFields) {
      if (step >= 4) return;
    } else {
      if (step >= 3) return;
    }
    setStep(step + 1);
  };
  const prevStep = () => {
    if (step <= 1) return;
    setStep(step - 1);
  };

  const handleSubmitForm = formHandleSubmit(async (data) => {
    switch (step) {
      case 1:
        nextStep();
        break;
      case 2:
        if (!isCPU) {
          nextStep();
        } else if (handleSubmit) {
          await handleSubmit(
            mapFieldValuesIntoBeneficiaryForm(user!.personId, data, additionalFields, additionalFieldsAppriza),
            handleClose
          );
        }
        break;
      case 3:
        if (hasAdditionalFields) nextStep();
        else if (handleSubmit) {
          await handleSubmit(
            mapFieldValuesIntoBeneficiaryForm(user!.personId, data, additionalFields, additionalFieldsAppriza),
            handleClose
          );
        }
        break;
      case 4:
        if (handleSubmit) {
          await handleSubmit(
            mapFieldValuesIntoBeneficiaryForm(user!.personId, data, additionalFields, additionalFieldsAppriza),
            handleClose
          );
        }
        break;
      default:
        setStep(0);
        break;
    }
  });

  const initializeFormData = () => {
    if (beneficiaryInformationId) {
      const countryPhone = parsePhoneNumber(beneficiaryInformationId?.phoneNumber || "");
      const phoneNumber = countryPhone?.nationalNumber;
      const phoneCountry = countryPhone?.country || "US";
      if (phoneCountry) {
        setValue("phoneNumber", phoneNumber);
        const strPhoneCode = `+${getCountryCallingCode(phoneCountry)}`;
        setPhoneCountryCodeSelect(iso31661Alpha2ToAlpha3[phoneCountry]);
        setValue("phoneCountryCode", strPhoneCode);
      }

      setValue("dateOfBirth", beneficiaryInformationId?.dateOfBirth || "", {
        shouldValidate: true,
      });
      setValue("country", beneficiaryInformationId.country, {
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue("subnational", beneficiaryInformationId.subnational);
      setValue("city", beneficiaryInformationId.city);
      setValue("firstName", beneficiaryInformationId.firstName, { shouldTouch: true });
      setValue("middleName", beneficiaryInformationId.middleName, { shouldTouch: true });
      setValue("lastName", beneficiaryInformationId.lastName, { shouldTouch: true });
      setValue("alias", beneficiaryInformationId.alias, { shouldTouch: true });
      setValue("email", beneficiaryInformationId.email, { shouldTouch: true });
      setValue("address1", beneficiaryInformationId.address, { shouldTouch: true });
      setValue("address2", beneficiaryInformationId.addressExtra, { shouldTouch: true });
      setValue("postalCode", beneficiaryInformationId.postalCode, { shouldTouch: true });
    }
  };

  useEffect(() => {
    if (beneficiaryInformationId) {
      initializeFormData();
    }
  }, [beneficiaryInformationId]);

  useEffect(() => {
    const phoneNumber = beneficiaryInformationId?.phoneNumber;
    if (phoneNumber) {
      const countryPhone = parsePhoneNumber(phoneNumber);
      const phoneCountry = countryPhone?.country || "US";
      if (countryPhone && phoneCountry) {
        setValue("phoneNumber", countryPhone.nationalNumber);
        const strPhoneCode = `+${getCountryCallingCode(phoneCountry)}`;
        setValue("phoneCountryCode", strPhoneCode);
      }
    }
  }, [phoneCountryCodeSelect]);

  useEffect(() => {
    if (recipient) {
      getBeneficiaryInformation(recipient?.beneficiaryId || "");
    }
  }, [recipient]);

  useEffect(() => {
    if (countrySelected) fetchAdditionalFieldsAppriza(countrySelected);
  }, [countrySelected]);

  useEffect(() => {
    getSubdivisionOptions(formValues?.country || quoteCountrySelected, language);
  }, [formValues?.country, quoteCountrySelected, allowedCountries]);

  useEffect(() => {
    if (destinationIdSelected && !isEmpty(bankList)) {
      const destinationSelected = bankList.find((dest) => dest.id === destinationIdSelected);
      const accountTypeList: AccountTypes[] = [];
      if (destinationSelected) {
        destinationSelected?.destinationExpressions?.map((item) => {
          accountTypeList.push({
            label: language === "es" ? item.labelSP : item.labelEN,
            value: item.accountType,
            key: item.accountType,
          });
        });
      }
      setAccountTypeList(accountTypeList);
    }
  }, [bankList, language, destinationIdSelected]);

  return (
    <Modal handleClose={handleClose} {...props} as={BottomModalWrapper}>
      {isLoading && <Loader full />}
      <FlexContainer justify="space-between" m="0">
        {step === 1 ? (
          <IconFont name="cancel" color="black" size="medium" onClick={() => handleClose && handleClose()} />
        ) : (
          <Icon
            icon="arrowLeft"
            size="large"
            onClick={() => {
              prevStep();
            }}
          />
        )}
        <Text size={0.5} color="gray" margin={0}>
          {step} / {!hasAdditionalFields ? 3 : 4}
        </Text>
      </FlexContainer>

      <FlexContainer justify="center" m="0">
        <Text size={2} weight={500} margin="8px 0 20px 0">
          {step === 1 && t("Beneficiaries.AddRecipientInformation")}
          {step === 2 && t("Beneficiaries.AddRecipientAddress")}
          {((step === 3 && !hasAdditionalFields) || (step === 4 && hasAdditionalFields)) &&
            t("Beneficiaries.AddRecipientBankInformation")}
          {step === 3 && hasAdditionalFields && t("Beneficiaries.AddRecipientBankInformation")}
        </Text>
      </FlexContainer>
      {((step === 3 && !hasAdditionalFields) || (step === 4 && hasAdditionalFields)) && (
        <FlexContainer justify="center" m="0">
          <Text size={1} weight={500} color="neutral600" margin="-16px 0 20px 0">
            <b>{`${formValues.firstName} ${formValues.lastName}`}</b> &bull; {formValues.country}
          </Text>
        </FlexContainer>
      )}

      <FlexContainer direction="column" gap="8px">
        <form onSubmit={handleSubmitForm} style={{ width: "100%", maxHeight: "700px", padding: "0 8px" }}>
          <FormProvider {...form}>
            {step === 1 && (
              <FlexContainer justify="center" direction="column" m="0 0 31px 0">
                <ProfileForm />
                <ContactForm countrySelected={phoneCountryCodeSelect} />
              </FlexContainer>
            )}
            {step === 2 && (
              <FlexContainer justify="center" direction="column">
                <AddressForm
                  countrySelected={countrySelected}
                  lockCountry={!!quoteCountrySelected}
                  onSelectCountry={setCountrySelected}
                  onLoading={setFormIsLoading}
                />
              </FlexContainer>
            )}
            {step === 3 && (
              <FlexContainer justify="center" direction="column" m="0 0 40px 0">
                {hasAdditionalFields ? (
                  <>
                    {!isEmpty(beneficiaryAdditionalFields) &&
                      beneficiaryAdditionalFields.map((field) => <DynamicInput key={field.fieldName} {...field} />)}
                    {!isEmpty(additionalFieldsAppriza) &&
                      additionalFieldsAppriza.map((field) => <DynamicInput key={field.fieldName} {...field} />)}
                  </>
                ) : (
                  <>
                    <BeneficiaryAccount
                      country={formValues?.country}
                      countrySubdivisions={subdivisionList}
                      AccountTypeList={AccountTypeList}
                      onBankList={(bankList) => bankList && setBankList(bankList)}
                      onAdditionalFields={(additionalFields) => setAdditionalFields(additionalFields)}
                      showDestinationSelector
                      onLoading={(loading) => {
                        setBankAccountStatus(loading ? "loading" : "idle");
                      }}
                    />
                  </>
                )}
              </FlexContainer>
            )}
            {step === 4 && (
              <FlexContainer justify="center" direction="column">
                <BeneficiaryAccount
                  country={formValues?.country}
                  countrySubdivisions={subdivisionList}
                  AccountTypeList={AccountTypeList}
                  onBankList={(bankList) => bankList && setBankList(bankList)}
                  onAdditionalFields={(additionalFields) => setAdditionalFields(additionalFields)}
                  showDestinationSelector
                  onLoading={(loading) => {
                    setBankAccountStatus(loading ? "loading" : "idle");
                  }}
                />
              </FlexContainer>
            )}
            <FlexContainer justify="center">
              <Button
                variant={!isValid ? "default" : "primary"}
                sizeButton="full"
                text={t("global.next")}
                sizeText="medium"
                iconButton="arrowRight"
                colorIcon="white"
                sizeIcon="large"
                padding="8px"
                type="submit"
                disabled={!isValid}
              />
            </FlexContainer>
          </FormProvider>
        </form>
      </FlexContainer>
    </Modal>
  );
};
