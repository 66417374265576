import { createSlice } from "@reduxjs/toolkit";
import { TokenValidationAction } from "./TokenValidation.actions";
import { TokenValidationState } from "../../Models/TokenValidation";
import { State } from "../Store";

const initialState: TokenValidationState = {
  loading: false,
  error: null,
  data: null
}

export const TokenValidationSlice = createSlice({
  name: "TokenValidation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(TokenValidationAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(TokenValidationAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(TokenValidationAction.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export default TokenValidationSlice.reducer;

export const TokenValidationSelector = (state: State) => state.TokenValidation;
