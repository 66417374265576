import { createSlice } from "@reduxjs/toolkit";
import { GetPayrollPdf } from "./PayrollPdf.actions";
import { State } from "../Store";
import { PayrollPDFState } from "../../Models/DirectDeposit";

const initialState: PayrollPDFState = {
  loading: false,
  error: null,
  data: null
}

export const GetPayrollPdfSlice = createSlice({
  name: "GetPayrollPdf",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetPayrollPdf.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetPayrollPdf.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(GetPayrollPdf.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export default GetPayrollPdfSlice.reducer;

export const GetPayrollPdfSelector = (state: State) => state.GetPayrollPdf;
