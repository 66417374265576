import styled from "styled-components";
import { theme } from "styled-tools";

export const AvatarStyled = styled.img<{ $src: string }>`
  background: ${(props) => (props.$src ? "none" : props.theme.Content_UX_02)};
  border: ${(props) => (props.$src ? "none" : `3px solid ${props.theme.Content_State_UX_Disabled}`)};
  border-radius: 50%;
  height: 25px;
  margin: 0 16px;
  width: 25px;
`;

export const LetterAvatarStyled = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${theme("Platinum")};
  color: ${theme("Black")};
  display: flex;
  align-items: center;
  justify-content: center;
`;
