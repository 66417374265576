// Customer ID of Costa Farms
export const CostaFarmsId = import.meta.env.VITE_COSTA_FARMS || "";

// Customer ID of Omnibus
export const OmnibusId = import.meta.env.VITE_OMNIBUS || "";

// Customer ID of new Sendola
export const SendolaId = import.meta.env.VITE_SENDOLA || "";

// Custumer ID Star Businessce
export const StarBusinessce = import.meta.env.VITE_STAR_BUSINESSCE || "";

// Custumer ID Pilot Testing
export const PilotTesting = import.meta.env.VITE_PILOT_TESTNG || "";

// Custumer ID Martori Farms
export const MartoriId = import.meta.env.VITE_MARTORI || "";

// Custumer ID Washington Fruit - Ranch Office
export const WashingtonFruit = import.meta.env.VITE_WASHINGTON_FRUIT || ""

// Custumer ID Washington Fruit - Warehouses
export const WashingtonFruitWh = import.meta.env.VITE_WASHINGTON_FRUIT_WH || ""

// Custumer ID BLD - Speciality Contractor
export const BLDSpecialityContractor = import.meta.env.VITE_BLD_SPECIALITY_CONTRACTOR || "";

export const CustomerIdsList = [
  CostaFarmsId,
  OmnibusId,
  SendolaId,
  StarBusinessce,
  PilotTesting,
  MartoriId,
  WashingtonFruit,
  WashingtonFruitWh
];

export const EmployerList = [
  {
    id: CostaFarmsId,
    name: "Costa Farms"
  },
  {
    id: OmnibusId,
    name: "Omnibus"
  },
  {
    id: SendolaId,
    name: "Sendola"
  },
  {
    id: StarBusinessce,
    name: "Star Businessce"
  },
  {
    id: PilotTesting,
    name: "Pilot Testing"
  },
  {
    id: MartoriId,
    name: "Martori Farms"
  }
];