import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { PersonalDataPayload, PersonalDataResponse } from "../../Models/PersonalData";
import { UPDATE_PERSONAL_DATA } from "../../Constants/PersonalData";
import { defaultErrorMessage } from "../../Constants/Requests";
import { AxiosError } from "axios";

/**
 * Method to update Personal info
 */
export const PersonalDataAction = createAsyncThunk<PersonalDataResponse, PersonalDataPayload, { rejectValue: string }>(
  UPDATE_PERSONAL_DATA,
  async (payload: PersonalDataPayload, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post("/persons/personal-info", payload);
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);