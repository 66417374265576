import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { defaultErrorMessage } from "../../Constants/Requests";
import { GET_PROOF_ADDRESS_DOCUMENT } from "../../Constants/Documents";

export const GetProofAddressType = createAsyncThunk(
  GET_PROOF_ADDRESS_DOCUMENT,
  async (_, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(`/persons/documents/proof-address/`);
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
