import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { defaultErrorMessage } from "../../Constants/Requests";
import { IDV_IS_REQUIRED } from "../../Constants/IDV";

export const GetIdvIsRequiredAction = createAsyncThunk(
  IDV_IS_REQUIRED,
  async (payload:string, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(`/persons/${payload}/idv/is-required`);
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data.message);
      }

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
