import { createSlice } from "@reduxjs/toolkit";
import { getCoppelAccess } from "./CoppelAccess.actions";
import { State } from "../Store";

export const CoppelAccessSlice = createSlice({
  name: "CoppelAccess",
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCoppelAccess.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCoppelAccess.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      .addCase(getCoppelAccess.rejected, (state: any, action: any) => {
        state.loading = false;
        state.data = {};
        state.error = action.payload.response;
      });
  },
});

export default CoppelAccessSlice.reducer;

export const CoppelAccessSelector = (state: State) => state.CoppelAccess;
