
export interface ShippingAddressState {
  loading: boolean
  error: string | null
}

export enum ShippingAddressOptions {
  registeredAddress = "registeredAddress",
  newAddress = "newAddress"
}

export enum UsAddressOptions {
  CompanyAddress = "companyAddress",
  personalAddress = "personalAddress"
}

export enum VerificationMethodOptions {
  SocialSecurityNumber = "SSN",
  VisaAndPassport = "VisaPassport"
}

export interface ShippingAddress {
  line1: string
  line2?: string
  city: string
  state: string
  country: string
  zipCode: string
  addressType?: string
}

export type ShippingAddressForm = Exclude<ShippingAddress, 'country'>

export interface ShippingAddressPayload {
  personId: string
  preferredShippingAddress: ShippingAddress
}

