import styled from "styled-components";
import { ifProp, theme } from "styled-tools";

export const SwitchButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme("Background_07")};
  border-radius: 24px;
  width: 100%;
  margin: auto;
`;

export const SwitchElement = styled.div<{ $active: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  background: ${ifProp("$active", theme("Gradient_08"), "none")};
  border-radius: 24px;
  color: ${ifProp("$active", theme("Background_01"), theme("Content_UX_02"))};
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  height: 26px;
  width: 100%;
  transition:
    0.3s ease all,
    left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
`;
