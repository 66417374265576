import styled from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

export const Container = styled.div`
  margin-block: 60px;
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0;
`

export const ContainerButton = styled.div`
  margin: 64px 0;

  button {
    width: 100%;

    ${mediaQuery[Screen.desktop]} {
      width: 80%;
    }
  }
`;