// Own Components
import Text from "../Text";

// Own styles
import { LoaderContainer, LoaderStyled, Percent } from "./Loader.styled";

import { LoaderProps } from "../../Models/Loader";

const Loader = (props: LoaderProps) => {
  const { percentText, message, full } = props;
  return (
    <LoaderContainer full={full}>
      <LoaderStyled />
      {percentText && (
        <Percent $msg={message ? true : false}>{percentText}</Percent>
      )}
      {message && <Text size={0.3}>{message}</Text>}
    </LoaderContainer>
  );
};

export default Loader;
