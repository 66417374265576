// Own components
import Icon from "@components/Icon";
import Skeleton from "@components/Skeleton";

// Model
import { PhoneNumberInputProps } from "@models/PhoneNumberInput";

// Own styles
import {
  FlagPhoneNumberContainer,
  ErrorContainer,
  ErrorMessage,
  FlagInputContainer,
  FlagDropdown,
  FlagImgDropdown,
} from "./FlagPhoneNumberInput.styled";
import { getCountryCallingCode } from "react-phone-number-input/input";

import "react-phone-number-input/style.css";
import { useEffect, useRef, useState } from "react";
import { CountryCode } from "libphonenumber-js/core";
import Input from "@components/Payments/Input";
import { useTranslation } from "react-i18next";
import { RecipientExchangeRates, SenderExchangeRates } from "@core/Payments/Common/domain/ExchangeRates";
import { iso31661Alpha2ToAlpha3 } from "iso-3166";
import { Placeholder } from "../Input/Input.styled";
import { useFormContext } from "react-hook-form";
const FlagPhoneNumberInput = ({
  label = "PhoneNumber",
  labelText,
  rules,
  errors,
  countryDefault = "US",
  countries = [],
  isLoading = false,
  mb,
  onCountryChange,
  register,
  onPhoneNumberChange,
  setCountryCode,
}: PhoneNumberInputProps) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<CountryCode | null>(null);
  const [t] = useTranslation("global");
  const [countryCodeSelected, setCountryCodeSelected] = useState<string>("");
  const [isValid, setIsValid] = useState(false);

  const { setValue } = useFormContext();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const selectOption = (option: CountryCode) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
    const strPhoneCode = `+${getCountryCallingCode(option)}`;
    setCountryCodeSelected(strPhoneCode);
    setCountryCode(strPhoneCode);
    setValue("phoneCountryCode", strPhoneCode);
    if (onCountryChange) onCountryChange(strPhoneCode, iso31661Alpha2ToAlpha3[option]);
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const number = `${countryCodeSelected}${event.target.value?.replace(/\s+/g, "")}`;
    if (onPhoneNumberChange) onPhoneNumberChange(number, isValid, event);
  };

  const checkClickOutside = (event: MouseEvent) => {
    if (isDropdownOpen && !dropdownRef.current?.contains(event.target as HTMLDivElement)) {
      setIsDropdownOpen(false);
    }

    return null;
  };

  useEffect(() => {
    if (errors?.[label]) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [errors]);

  useEffect(() => {
    if (dropdownRef.current) {
      document.addEventListener("click", checkClickOutside);

      return () => document.removeEventListener("click", checkClickOutside);
    }
  }, [isDropdownOpen, dropdownRef.current]);

  useEffect(() => {
    selectOption(countryDefault);
    const strPhoneCode = `+${getCountryCallingCode(countryDefault)}`;
    setValue("phoneCountryCode", strPhoneCode);
  }, [countryDefault]);

  return (
    <FlagPhoneNumberContainer $error={Boolean(errors?.[label])} mb={mb}>
      {isLoading ? (
        <Skeleton height={35} />
      ) : (
        <FlagInputContainer>
          <FlagDropdown>
            <Placeholder size={1} active={true}>
              {t("Beneficiaries.CreateBeneficiary.Form.CountryCode")}
            </Placeholder>
            <div ref={dropdownRef} className="dropdown-toggle" onClick={toggleDropdown}>
              <FlagImgDropdown>
                <img
                  src={
                    [...SenderExchangeRates, ...RecipientExchangeRates].find(
                      (rateData) =>
                        rateData.countryCode ===
                        iso31661Alpha2ToAlpha3[selectedOption ? selectedOption : countryDefault]
                    )?.icon
                  }
                  alt={selectedOption ? selectedOption : countryDefault}
                />
              </FlagImgDropdown>
              <span className="dropdown-option-label">
                {`+${getCountryCallingCode(selectedOption ? selectedOption : countryDefault)}`}
              </span>
            </div>
            {isDropdownOpen && (
              <ul className={`dropdown-menu ${isDropdownOpen ? "open" : ""}`}>
                {countries.map((country: CountryCode, index: number) => (
                  <li key={index} onClick={() => selectOption(country)}>
                    <FlagImgDropdown>
                      <img
                        src={
                          [...SenderExchangeRates, ...RecipientExchangeRates].find(
                            (rateData) => rateData.countryCode === iso31661Alpha2ToAlpha3[country]
                          )?.icon
                        }
                        alt={country}
                      />
                    </FlagImgDropdown>
                    <span className="dropdown-option-label">+{getCountryCallingCode(country)}</span>
                  </li>
                ))}
              </ul>
            )}
          </FlagDropdown>

          <Input
            typeInput="number"
            inputMode="tel"
            label={label}
            register={register}
            placeholderInput={labelText}
            rules={rules}
            mb="0px"
            isError={Boolean(errors?.[label])}
            onInputChange={(event) => handleChangeInput(event)}
          />
        </FlagInputContainer>
      )}
      {errors?.[label] && (
        <ErrorContainer>
          <Icon icon="alertTriangle" color="error" size="small" fillIcon={false} />
          <ErrorMessage>{errors?.[label]?.message?.toString()}</ErrorMessage>
        </ErrorContainer>
      )}
    </FlagPhoneNumberContainer>
  );
};

export default FlagPhoneNumberInput;
