export const KYC_APPROVED = "approved";
export const KYC_IN_REVIEW = "inReview";
export const KYC_DECLINED = "declined";
export const KYC_SUBMITTED = "submitted";
export const KYC_STARTED = "started";
export const KYC_NOT_STARTED = "notStarted";
export const KYC_RESUBMISSION = "resubmission";
export const KYC_RESUBMISSION_REQUESTED = "resubmission_requested";

export const SUBMIT_KYC = "submitKYC";
export const SUBMIT_KYC_PENDING = `${SUBMIT_KYC}/pending`;
export const SUBMIT_KYC_REJECTED = `${SUBMIT_KYC}/rejected`;
export const SUBMIT_KYC_FULFILLED = `${SUBMIT_KYC}/fulfilled`;

export const GET_KYC_STATUS = "getKYCStatus";
export const GET_KYC_STATUS_PENDING = `${GET_KYC_STATUS}/pending`;
export const GET_KYC_STATUS_REJECTED = `${GET_KYC_STATUS}/rejected`;
export const GET_KYC_STATUS_FULFILLED = `${GET_KYC_STATUS}/fulfilled`;

export const SUBMIT_COMPLETE_ONBOARDING = "submitCompleteOnboarding";
export const SUBMIT_COMPLETE_ONBOARDING_PENDING = `${SUBMIT_COMPLETE_ONBOARDING}/pending`;
export const SUBMIT_COMPLETE_ONBOARDING_REJECTED = `${SUBMIT_COMPLETE_ONBOARDING}/rejected`;
export const SUBMIT_COMPLETE_ONBOARDING_FULFILLED = `${SUBMIT_COMPLETE_ONBOARDING}/fulfilled`;
