import styled from "styled-components";
import mediaQuery, { Screen } from "@utils/MediaQuery";

export const StyledAdditionalFieldsForm = styled.form`
  box-sizing: border-box;
  width: 100%;
  padding: 2rem 2rem;

  ${mediaQuery[Screen.desktop]} {
    max-width: 560px;
  }
`;
