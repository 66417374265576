import { FlexContainer } from "@components/Flex/Flex.styled";
import IconFont from "@components/IconFont";
import Layout from "@components/Layout";
import Button from "@components/Payments/Button";
import { ChannelSwitch } from "@components/Payments/ChannelSwitch/ChannelSwitch.component";
import { CouponBar } from "@components/Payments/CouponBar/CouponBar.component";
import { CouponInput } from "@components/Payments/CouponInput/CouponInput.component";
import { QuoteTotals } from "@components/Payments/QuoteSummary/QuoteTotals.component";
import Text from "@components/Text";
import { bestFXLessFeePayers } from "@constants/Regexp";
import theme from "@contexts/ThemeProvider";
import {
  SendolaCardPaymentMethod,
  PaymentMethods,
  PaymentMethodType,
  PlaidAccount,
  SquarePaymentMethod,
  PaymentMethod,
  isSendolaCardPaymentMethod,
  isPlaidAccount,
  isSquareAccount,
  isZellePaymentMethod,
} from "@core/Payments/Common/domain/PaymentMethods";
import {
  calculateTotalBeforeDiscount,
  calculateTotalToShow,
  QuoteChannel,
} from "@core/Payments/Quote/domain/QuoteSelected";
import { useCoupons } from "@hooks/Payments/useCoupons";
import { useFeature } from "@hooks/Payments/useFeature";
import { useModal } from "@hooks/Payments/useModal";
import { usePerson } from "@hooks/Payments/usePerson";
import { useQuote } from "@hooks/Payments/useQuote";
import { CouponsSelector } from "@redux/Payments/Coupons/Coupons.slice";
import { QuotationSelector, QuotationSlice } from "@redux/Payments/Quotation/Quotation.slice";
import { selectorLanguage } from "@redux/Translate";
import { formatNumber } from "@utils/NumberUtils";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import ZelleLogo from "@assets/Img/zelle-rounded.png";
import SquareLogo from "@assets/Img/square-rounded.png";
import PayPalLogo from "@assets/Img/paypal-rounded.png";
import VenmoLogo from "@assets/Img/venmo-rounded.png";
import SendolaPayLogo from "@assets/Img/sendola-pay-short.png";
import AuthorizeLogo from "@assets/Img/authorize-rounded.png";
import { darkenColor } from "@utils/Colors";
import { ObjectFitImage } from "@components/Image/Image.styled";
import { PaymentMethodTypeTag } from "@components/Payments/PaymentMethodSelect/PaymentRadio/PaymentRadio.styled";
import { usePaymentOrder } from "@hooks/Payments/usePaymentOrders";
import Icon from "@components/Icon";

const PaymentMethodSelectionPage = () => {
  const [t] = useTranslation("global");
  const [tModals] = useTranslation("modals");
  const [tSendMoney] = useTranslation("sendMoney");
  const dispatch = useDispatch();
  const {
    currentQuotation,
    currentDeliveryMethodQuote,
    currentPaymentDestination,
    currentPaymentMethod,
    countryDestination,
    couponToApply,
    currentQuoteDiscounted,
    currentChannel,
    quotesPerChannel,
    paymentMethodSelected,
  } = useSelector(QuotationSelector).data;
  const couponsSelector = useSelector(CouponsSelector);
  const { language } = useSelector(selectorLanguage);

  const submitButton = useRef<HTMLButtonElement | null>(null);
  const [showCouponInputManual, setShowCouponInputManual] = useState<boolean>(true);
  const [couponManualValue, setCouponManualValue] = useState<string>("");
  const [balanceNotValid, setBalanceNotValid] = useState(false);

  const { isLoading: couponsIsLoading, getUserCoupons, verifyQuoteCoupon } = useCoupons();
  const {
    isLoading: quoteIsLoading,
    velocityLimitsToShow,
    messagesVelocity,
    handleQuotationChange,
    fetchPaymentMethods,
    mapPaymentMethodIntoQuotePaymentMethod,
  } = useQuote();
  const { isLoading: personIsLoading, person, getCurrentPerson } = usePerson();
  const { manualCouponFeature, bestFxLessFeeFeature } = useFeature();
  const {
    isLoading: verifyIsLoading,
    velocityModal,
    verifyErrorModal,
    verifyCurrentSendMoneySelection,
  } = usePaymentOrder();
  const { modal, showModal } = useModal();

  const loading = couponsIsLoading || quoteIsLoading || personIsLoading || verifyIsLoading;

  const showChannelSwitch = useMemo(
    () =>
      bestFxLessFeeFeature &&
      currentDeliveryMethodQuote &&
      Number(currentQuotation?.amount || "0") >= 400 &&
      currentPaymentDestination?.payerQuote &&
      bestFXLessFeePayers.test(currentPaymentDestination.payerQuote),
    [
      currentDeliveryMethodQuote,
      currentQuotation,
      currentPaymentDestination?.payerQuote,
      currentPaymentMethod,
      bestFxLessFeeFeature,
    ]
  );

  const paymentMethodData = useMemo(() => {
    if (paymentMethodSelected?.paymentSource) {
      const isSendolaPay = isSendolaCardPaymentMethod(paymentMethodSelected);
      const isPlaid = isPlaidAccount(paymentMethodSelected);
      const isZelle = isZellePaymentMethod(paymentMethodSelected);
      const isSquare = isSquareAccount(paymentMethodSelected);
      return {
        name: getPaymentMethodName(paymentMethodSelected),
        type: (isSendolaPay || isPlaid
          ? "economy"
          : isZelle
            ? "economy-express-minutes"
            : isSquare
              ? "express"
              : "economy-minutes") as PaymentMethodType,
        logo: getLogoFromPaymentMethod(paymentMethodSelected.paymentSource),
        backgroundLogo: isPlaid
          ? (paymentMethodSelected as PlaidAccount)?.backgroundColor
          : isSendolaPay
            ? theme.White
            : undefined,
        gradientBackground: isPlaid ? darkenColor((paymentMethodSelected as PlaidAccount)?.backgroundColor) : undefined,
        logoWidth: isSendolaPay ? "40px" : "32px",
        logoHeight: isSendolaPay ? "40px" : "32px",
        logoPadding: "0px",
      };
    }
  }, [paymentMethodSelected]);

  const couponDiscountIsApplied = couponToApply && currentQuoteDiscounted;
  const totalCost = useMemo(
    () =>
      calculateTotalToShow({
        currentTotalCost:
          couponDiscountIsApplied && currentPaymentMethod?.discount
            ? Number(currentPaymentMethod.totalCost) + Number(currentPaymentMethod.discount)
            : currentPaymentMethod?.totalCost,
        currentFee: currentDeliveryMethodQuote?.fee,
        currentQuoteDiscounted,
        discount: currentQuoteDiscounted?.discount,
        quoteDiscount: !couponDiscountIsApplied ? currentPaymentMethod?.discount : null,
      }),
    [currentPaymentMethod?.totalCost, currentDeliveryMethodQuote?.fee, currentQuoteDiscounted]
  );
  const { totalBeforePaymentMethodDiscount, totalDiscounts } = useMemo(
    () => calculateTotalBeforeDiscount(totalCost, currentPaymentMethod?.totalCost, currentPaymentMethod?.discount),
    [totalCost, currentQuoteDiscounted, currentPaymentMethod?.discount, currentPaymentMethod?.totalCost]
  );

  function getLogoFromPaymentMethod(paymentOrigin: PaymentMethods): string | undefined {
    switch (paymentOrigin) {
      case "Zelle":
        return ZelleLogo;
      case "Square":
        return SquareLogo;
      case "PayPal":
        return PayPalLogo;
      case "Venmo":
        return VenmoLogo;
      case "Sendola Plaid":
        return (paymentMethodSelected as PlaidAccount).logoUrl;
      case "Sendola Card":
        return SendolaPayLogo;
      case "authorize.net":
        return AuthorizeLogo;
      default:
        return undefined;
    }
  }

  function getPaymentMethodName(paymentMethod: SendolaCardPaymentMethod | PlaidAccount | SquarePaymentMethod) {
    switch (paymentMethod.paymentSource) {
      case "Sendola Plaid":
        return (paymentMethod as PlaidAccount).bankName;
      case "Sendola Card":
        return "ACH";
      case "authorize.net":
        return t("Authorize.summary");
      default:
        return paymentMethod.paymentSource;
    }
  }

  const changeChannel = async (channel: QuoteChannel) => {
    await handleQuotationChange({
      amount: currentQuotation?.amount,
      deliveryMethod: currentDeliveryMethodQuote?.deliveryMethodCode,
      currency: currentQuotation?.destinationCurrency,
      country: countryDestination,
      sendTo: currentPaymentDestination?.id,
      paymentMethodSource: paymentMethodSelected?.paymentSource,
      channel,
    });
  };

  const verifyManualCoupon = async (couponManualValue: string) => {
    const couponVerification = await verifyQuoteCoupon({
      amount: currentQuotation?.amount || 0,
      corridor:
        currentDeliveryMethodQuote?.paymentDestinations?.find((dest) => dest.id === currentPaymentDestination?.id)
          ?.destination || "",
      couponCode: couponManualValue,
      deliveryMethod: currentDeliveryMethodQuote?.deliveryMethodCode || "CPU",
      paymentMethod: currentPaymentMethod?.paymentOrigin || "Square",
      paymentMethodFee: currentPaymentMethod?.paymentMethodFee || 0,
      fee: currentDeliveryMethodQuote?.fee || 0,
      countryCode: countryDestination || "",
      countrySubdivision: person?.countrySubdivision || "",
    });

    if (couponVerification?.quoteDiscounted) {
      const CouponToApplyBody = {
        code: couponManualValue,
        description: couponManualValue,
        qty: 1,
      };
      dispatch(QuotationSlice.actions.setCurrentQuoteDiscounted(couponVerification.quoteDiscounted));
      dispatch(QuotationSlice.actions.setCouponToApply(CouponToApplyBody));
    }
  };

  const openPaymentMethodSelection = () => {
    showModal({
      modalType: "paymentMethodSelectModal",
      handleConfirm: (paymentMethod) => {
        dispatch(QuotationSlice.actions.setPaymentMethodSelected(paymentMethod));
        mapPaymentMethodIntoQuotePaymentMethod(
          currentDeliveryMethodQuote?.paymentMethods,
          paymentMethod as PaymentMethod
        );
        handleQuotationChange({
          amount: currentQuotation?.amount,
          deliveryMethod: currentDeliveryMethodQuote?.deliveryMethodCode,
          currency: currentQuotation?.destinationCurrency,
          country: countryDestination,
          sendTo: currentPaymentDestination?.id,
          paymentMethodSource: (paymentMethod as PaymentMethod)?.paymentSource,
          channel: currentChannel,
        });
      },
    });
  };

  const submitPaymentMethodSelection = async () => {
    await verifyCurrentSendMoneySelection();
  };

  const clearBalanceError = () => {
    const idx = velocityLimitsToShow.findIndex((error) => error.code === "VB001");
    if (idx !== -1) {
      velocityLimitsToShow?.splice(idx, 1);
    }
  };

  useEffect(() => {
    try {
      const paymentMethod = paymentMethodSelected;
      const paymentMethodIsPlaidAccount = isPlaidAccount(paymentMethod);
      const paymentMethodIsSendolaPay = isSendolaCardPaymentMethod(paymentMethod);

      if (!paymentMethod || !currentPaymentMethod || (!paymentMethodIsPlaidAccount && !paymentMethodIsSendolaPay)) {
        setBalanceNotValid(false);
        clearBalanceError();
        return;
      }

      const plaidBalanceIsLessThanTotal = paymentMethodIsPlaidAccount && paymentMethod.available < totalCost;
      const sendolaBalanceIsLessThanTotal = paymentMethodIsSendolaPay && paymentMethod.availableBalance < totalCost;
      const availableBalance = paymentMethodIsPlaidAccount ? paymentMethod.available : paymentMethod.availableBalance;

      if (!plaidBalanceIsLessThanTotal && !sendolaBalanceIsLessThanTotal) {
        setBalanceNotValid(false);
        clearBalanceError();
        return;
      }

      if (!velocityLimitsToShow.find((error) => error.code === "VB001")) {
        velocityLimitsToShow?.push({
          code: "VB001",
          value: availableBalance.toString(),
          msg: tModals("velocityLimits.messageNegativeBalance"),
        });
        setBalanceNotValid(true);
      }
    } catch (error) {
      setBalanceNotValid(false);
      clearBalanceError();
    }
  }, [paymentMethodSelected, currentPaymentMethod]);

  useEffect(() => {
    getCurrentPerson().then((person) => {
      if (person) {
        getUserCoupons();
      }
    });
    fetchPaymentMethods();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Layout path="/beneficiary-selection" showHelpBtn={false} showNavigation={false} loading={loading}>
        <FlexContainer direction="column" p="0 16px" mw="556px" gap="24px">
          <FlexContainer justify="center">
            <Text align="center" size={2} weight={600} lineHeight="30px" margin={0}>
              {tSendMoney("PaymentMethodSelection.title")}
            </Text>
          </FlexContainer>
          <FlexContainer direction="column" alignItems="start" gap="8px">
            <Text align="left" color="neutral700" size={1.5} weight={500} margin={0}>
              {tSendMoney("PaymentMethodSelection.payingWith")}
            </Text>
            <Button
              type="button"
              variant="outlineMuted"
              sizeButton="full"
              padding="12px"
              borderRadius="8px"
              onClick={() => openPaymentMethodSelection()}
            >
              {!currentPaymentMethod ? (
                <FlexContainer justify="center" alignItems="center" gap="8px">
                  <Text size={1} color="primary" weight={500} margin={0}>
                    {tSendMoney("PaymentMethodSelection.select")}
                  </Text>
                  <IconFont name="arrow-right" color="primary" weight={500} />
                </FlexContainer>
              ) : (
                <FlexContainer justify="space-between" alignItems="center" gap="10px">
                  <FlexContainer w="auto">
                    <ObjectFitImage
                      fit="contain"
                      width={paymentMethodData?.logoWidth}
                      height={paymentMethodData?.logoHeight}
                      src={paymentMethodData?.logo}
                      background={paymentMethodData?.backgroundLogo}
                      gradientBackground={paymentMethodData?.gradientBackground}
                      objectPosition="center"
                      padding={paymentMethodData?.logoPadding}
                      rounded
                    />
                  </FlexContainer>
                  <FlexContainer direction="column" alignItems="start" flex="1 1 auto">
                    <Text align="left" size={0.5} weight={600} lineHeight="15px">
                      {paymentMethodData?.name}
                    </Text>
                    <PaymentMethodTypeTag type={paymentMethodData?.type}>
                      {tSendMoney(`paymentMethods.types.${paymentMethodData?.type}`)}
                    </PaymentMethodTypeTag>
                  </FlexContainer>
                  <FlexContainer w="auto" alignItems="center" gap="4px">
                    <Text align="left" color="primary_01_600" lineHeight="16px" weight={500}>
                      {t("global.change")}
                    </Text>
                    <IconFont name="chevron-right" size="small" color="primary_01_600" weight={500} />
                  </FlexContainer>
                </FlexContainer>
              )}
            </Button>
          </FlexContainer>
          {Number(currentQuotation?.amount || 0) >= 400 &&
            showChannelSwitch &&
            currentQuotation?.destinationCurrency && (
              <FlexContainer direction="column" alignItems="start" gap="8px">
                <FlexContainer direction="column">
                  <Text align="left" color="neutral700" size={1.5} weight={500} margin={0}>
                    {tSendMoney("PaymentMethodSelection.chooseDeal")}
                  </Text>
                  <Text align="left" color="neutral500" size={0.3} weight={500} margin={0}>
                    <span
                      dangerouslySetInnerHTML={{ __html: tSendMoney("PaymentMethodSelection.chooseDealDescription") }}
                    ></span>
                  </Text>
                </FlexContainer>
                <ChannelSwitch
                  value={currentChannel}
                  lessFeesData={{
                    fx: quotesPerChannel?.[QuoteChannel.lessFee]?.currentDeliveryMethodQuote?.fx || 0,
                    fee: quotesPerChannel?.[QuoteChannel.lessFee]?.currentDeliveryMethodQuote?.fee || 0,
                    currency: currentQuotation?.destinationCurrency,
                  }}
                  bestFXData={{
                    fx: quotesPerChannel?.[QuoteChannel.bestFx]?.currentDeliveryMethodQuote?.fx || 0,
                    fee: quotesPerChannel?.[QuoteChannel.bestFx]?.currentDeliveryMethodQuote?.fee || 0,
                    currency: currentQuotation?.destinationCurrency,
                  }}
                  onChange={changeChannel}
                />
              </FlexContainer>
            )}
          {couponToApply && currentQuoteDiscounted && (
            <CouponBar
              couponCode={couponToApply.code || "REF-FRIENDS"}
              showCouponInputManual={setShowCouponInputManual}
              showRemoveCoupon={manualCouponFeature}
            />
          )}
          {manualCouponFeature && showCouponInputManual && (
            <CouponInput
              hasError={couponsSelector.error?.[0]}
              msgError={
                language === "es"
                  ? couponsSelector.error?.[1]
                  : language === "en"
                    ? couponsSelector.error?.[2]
                    : undefined
              }
              errorLabel={tSendMoney("Coupons.errorCoupon")}
              warningLabel={tSendMoney("Coupons.warningCoupon")}
              handleInputValue={setCouponManualValue}
              handleSubmit={() => {
                verifyManualCoupon(couponManualValue);
              }}
            />
          )}
          <FlexContainer justify="center" gap="8px">
            <Text size={1.5} weight={600} margin={0}>
              {tSendMoney("RecipientWillGet")}:
            </Text>
            <Text color="gradientPrimaryBlue" size={1.5} weight={600} margin={0} gradient>
              ${formatNumber(currentDeliveryMethodQuote?.amountToReceive || 0)} {currentQuotation?.destinationCurrency}
            </Text>
          </FlexContainer>
          <QuoteTotals
            transferFee={currentQuoteDiscounted?.fee ?? (currentDeliveryMethodQuote?.fee || 0)}
            previousTransferFee={
              couponDiscountIsApplied && currentQuoteDiscounted?.fee !== currentDeliveryMethodQuote?.fee
                ? currentDeliveryMethodQuote?.fee
                : undefined
            }
            paymentMethodFee={currentQuoteDiscounted?.paymentMethodFee ?? (currentPaymentMethod?.paymentMethodFee || 0)}
            previousPaymentMethodFee={
              couponDiscountIsApplied &&
              currentQuoteDiscounted?.paymentMethodFee !== currentPaymentMethod?.paymentMethodFee
                ? currentPaymentMethod?.paymentMethodFee
                : undefined
            }
            paymentMethodDiscount={!couponDiscountIsApplied ? currentPaymentMethod?.discount || 0 : 0}
            total={totalCost}
            previousTotal={
              couponDiscountIsApplied &&
              currentQuoteDiscounted?.discount &&
              totalCost !== currentPaymentMethod?.totalCost
                ? currentPaymentMethod?.totalCost
                : !couponDiscountIsApplied &&
                    currentPaymentMethod?.discount &&
                    totalBeforePaymentMethodDiscount !== totalCost
                  ? totalBeforePaymentMethodDiscount
                  : 0
            }
            totalDiscounted={
              couponDiscountIsApplied &&
              currentQuoteDiscounted?.discount &&
              totalCost !== currentPaymentMethod?.totalCost
                ? currentQuoteDiscounted?.discount
                : !couponDiscountIsApplied &&
                    currentPaymentMethod?.discount &&
                    totalBeforePaymentMethodDiscount !== totalCost
                  ? totalDiscounts
                  : 0
            }
            isFeeDropdownOpen
          />
          {balanceNotValid && (
            <FlexContainer gap="5px" flex="1 1 auto" alignItems="center">
              <Icon icon="alertTriangle" color="error" size="medium" />
              <Text size={0.5} color="error" align="left" weight={500} margin={0}>
                {messagesVelocity["VB001"]}
              </Text>
            </FlexContainer>
          )}
          <FlexContainer justify="center">
            <Button
              buttonRef={submitButton}
              variant="primary"
              padding="20px 90px"
              sizeButton="fit"
              withShadow
              disabled={!paymentMethodSelected || balanceNotValid}
              onClick={submitPaymentMethodSelection}
            >
              <Text size={1} weight={600} lineHeight="16px" color="white" margin={0}>
                {tSendMoney("Next")}
              </Text>
            </Button>
          </FlexContainer>
        </FlexContainer>
        {modal}
        {velocityModal}
        {verifyErrorModal}
      </Layout>
    </ThemeProvider>
  );
};

export default PaymentMethodSelectionPage;
