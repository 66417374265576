import styled from "styled-components";

export const CardBanner = styled.div<{$type?: string}>`
  border-radius: 10px;
  border: 0.5px solid ${props => props.$type === "bannerBank" ? props.theme.BackgroundBlue3 : props.$type === "bannerCoppel" ? props.theme.Coppel : props.theme.Grey_09};
  background: ${props => props.$type === "bannerBank" ? props.theme.Linear_coppel : props.$type === "bannerCoppel" ? props.theme.Coppel : props.theme.Background_01};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
  max-width: 345px;
  height: 95px;
  flex-shrink: 0;
  padding: 8px 10px;
  margin-block: 8px;
  margin-inline: auto;
`;

export const FeeContainer = styled.div<{$type?: string}>`
  width: 77px;
  flex-shrink: 0;
  border-radius: 16px;
  border: none;
  background: ${props => (props.$type === "bannerBank" || props.$type === "bannerCoppel") ? props.theme.Content_UX_03 : props.theme.Neutral_100};
  padding-bottom: 3px;
  margin-left: auto;

  span {
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    color: ${props => props.$type === "bannerBank" ? props.theme.BackgroundBlue : props.$type === "bannerCoppel" ? props.theme.BackgroundBlue1 : props.$type === "bannerUSI" ? props.theme.BackgroundBlue2 : props.theme.Primary_500};
  }
`;

export const ContainerInfo = styled.div`
  margin-top: -5px;
`;

export const ContainerText = styled.div<{$type?: string}>`
  padding-left: 10px;
  p:first-child {
    color: ${props => props.$type === "bannerBank" ? props.theme.Content_UX_03 : props.$type === "bannerCoppel" ? props.theme.BackgroundBlue1 : props.theme.Content_UX_01};
  }

  .message {
    width: 178px;
    height: 32px;
    flex-shrink: 0;
    color: ${props => props.$type === "bannerBank" ? props.theme.Background_01 : props.$type === "bannerCoppel" ? props.theme.BackgroundBlue1 : props.theme.Content_UX_02};
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12.5px;
    letter-spacing: -0.1px;
    text-align: left;
    margin-block: 0;
  }
`;

export const IconContainer = styled.div`
  margin-left: auto;
  margin-top: 10px;
`;