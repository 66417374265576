import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { GET_ALL_CARDS } from "../../Constants/Cards";
import { PersonAccountRequest } from "../../Models/PersonAccountRequest";

/**
 * Method to get all cards
 */
export const getAllCards = createAsyncThunk(
  GET_ALL_CARDS,
  async (payload:PersonAccountRequest, thunkAPI) => {
    const headers = {
      "personId": payload.personId
    };
    try {
      const response = await BaseConfigApi.get(`/cards?accountId=${payload.accountId}`, { headers: headers });
      return response.data.data;
    } catch(error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
