import { createSlice } from "@reduxjs/toolkit";
import { getHasSendolaOneAction } from "./HasSendolaOne.actions";
import { HasSendolaOneState } from "../../Models/HasSendolaOne";
import { State } from "../Store";

const initialState: HasSendolaOneState = {
  loading: false,
  error: null,
  data: null
};

export const HasSendolaOneSlice = createSlice({
  name: "hasSendolaOne",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHasSendolaOneAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHasSendolaOneAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      .addCase(getHasSendolaOneAction.rejected, (state, action:any) => {
        state.loading = false;
        state.data = null;
        state.error = action?.payload?.response;
      });
  },
});

export const hasSendolaOneSelector = (state: State) => state.hasSendolaOne;
