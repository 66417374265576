
export function downloadFile(name: string, objectUrl?: string): void {
    if (objectUrl) {
      const a: HTMLAnchorElement = document.createElement(
        "a"
      ) as HTMLAnchorElement;
  
      a.href = objectUrl;
      a.download = name;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
  
      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);
    }
  }