import { useEffect, useState } from "react";

import { Column, Row } from "../Grid";

import { StepsRoutesProps } from "../../Models/stepsInterface";

import {
  StepsContainerItems,
  StepItem,
} from "./StepsRoutes.styled";

const StepsRoutes = (props: StepsRoutesProps) => {
  const { totalSteps, stepIndex, color } = props;

  const [completedSteps, setCompletedSteps] = useState<number[]>([]);

  useEffect(() => {
    const stepsToAdd = Array.from({ length: stepIndex }, (_, i) => i);
    setCompletedSteps(stepsToAdd);
  }, [stepIndex])

  return (
    <Row>
      <Column span={12} as={StepsContainerItems} mt={2}>
        {Array.from({length: totalSteps}).map((_, index) => (
          <StepItem
            key={index}
            className={`${index === stepIndex ? 'active' : ''}`}
            $active={completedSteps.includes(index) || index === stepIndex}
            $color={color}
          />
        ))}
      </Column>
    </Row>
  )
};

export default StepsRoutes;