import styled, { css, CSSProperties } from "styled-components";
import { ifProp, prop, theme } from "styled-tools";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { FlexItem } from "../Flex/Flex.styled";

export const CardStyled = styled.div<{
  $type?: string;
}>`
  padding: 24px;
  border-radius: 20px;
  background: ${theme("Background_01")};
  box-shadow: 2px 4px 10px 0px rgba(${theme("RGBA_04")});
`;

export const GradientCard = styled.div<{
  borderWidth?: string;
  borderColor?: CSSProperties["backgroundColor"];
  borderRadius?: CSSProperties["borderRadius"];
  padding?: CSSProperties["padding"];
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  maxWidth?: CSSProperties["maxWidth"];
  active?: boolean;
  backgroundColor?: CSSProperties["backgroundColor"];
  gradientBackgroundColor?: CSSProperties["backgroundColor"];
  color?: CSSProperties["color"];
}>`
  position: relative;
  box-sizing: border-box;
  background: ${ifProp(
    "active",
    theme("Gradient_16"),
    ifProp(
      "gradientBackgroundColor",
      css`linear-gradient(
        180deg,
        ${prop("gradientBackgroundColor")} 0%,
        ${prop("backgroundColor")} 100%
      )`,
      ifProp("borderColor", prop("borderColor"), theme("Gradient_Rewards"))
    )
  )};
  padding: ${prop("borderWidth", "2px")};
  border-radius: ${prop("borderRadius", "8px")};
  width: ${prop("width")};
  max-width: ${prop("maxWidth", "100%")};
  height: ${prop("height", "100%")};
  ${ifProp(
    "active",
    css`
      box-shadow: 0px 4px 8px 0px ${theme("Black")}25;
    `
  )};

  > div {
    max-width: 100%;
    box-sizing: border-box;
    border-radius: ${css`calc(${prop("borderRadius", "8px")} - ${prop("borderWidth", "2px")})`};
    background: ${prop("backgroundColor", "white")};
    color: ${prop("color")};
    overflow: hidden;
    padding: ${prop("padding", "3px 5px")};
    height: calc(100% - 0.5px);

    ${ifProp(
      "gradientBackgroundColor",
      css`
        background: linear-gradient(180deg, ${prop("gradientBackgroundColor")} 0%, ${prop("backgroundColor")} 100%);
      `
    )}

    * {
      color: ${prop("color")};
    }
  }
`;

export const CardButton = styled(FlexItem)<{
  minHeight?: CSSProperties["minHeight"];
  maxHeight?: CSSProperties["maxHeight"];
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: ${prop("direction", "column")};
  width: 100%;
  min-height: ${prop("minHeight", "90px")};
  max-height: ${prop("maxHeight")};
  border-radius: 12px;
  justify-self: center;
  justify-content: center;
  border: 1px solid #c6c6c6;
  background: ${theme("Background_01")};
  width: 100%;

  ${mediaQuery[Screen.desktop]} {
    max-width: 382px;
  }

  ${mediaQuery[Screen.lg]} {
    max-width: 350px;
  }
`;

export const ClickableCardButton = styled(CardButton)`
  -webkit-tap-highlight-color: transparent;
  transition: all 100ms ease-in-out;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-0.1rem) scale(1.01);
  }

  &:focus {
    outline: none;
    background: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    background: rgba(${theme("RGBA_02")}, 0.3);
    border-radius: 50%;
    transform: scale(0);
    transition:
      transform 0.5s,
      opacity 1s;
    opacity: 0;
  }

  &:active::after {
    transform: scale(60);
    opacity: 1;
    transition:
      transform 0.5s,
      opacity 0.8s;
  }
`;

export const SquareCard = styled(GradientCard)`
  > div {
    background-color: ${theme("White")};

    * {
      color: ${theme("Black")};
    }
  }

  div:nth-child(1) > img {
    width: 2.0625rem;
    height: 2.0625rem;
  }

  div:nth-child(1) div img {
    max-width: 41px;
    height: auto;
  }
`;
