import styled, { css, CSSProperties } from "styled-components";
import { ifProp, prop } from "styled-tools";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

export const CarouselContainer = styled.div<{
  gap?: CSSProperties["gap"];
  overflowSize?: string;
  top?: CSSProperties["top"];
  size?: string;
  amount?: number;
}>`
  width: 100%;
  overflow-x: ${ifProp({amount: 1}, "none", "scroll")};
  padding-bottom: 16px;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: ${ifProp({amount: 1}, "none", "thin")};
  margin-left: ${ifProp({amount: 1}, "0", "-16px")};

  // overflow show slides
  ${ifProp(
    "overflowSize",
    css`
      position: absolute;
      top: ${prop("top", "33px")};
      left: 0;
      width: calc(100% + ${prop("overflowSize")});
    `
  )}

  ${mediaQuery[Screen.mobile]} {
    margin-left: ${ifProp({amount: 1}, "0", "-16px")};
   
    ${ifProp(
      {amount: 1},
      css`
        width: 100%;
      `,
      css`
        width: calc(100% + ${ifProp({amount: 0}, "0", "32px")});
      `
    )}
    scrollbar-width: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  > div {
    padding-left: ${ifProp({amount: 1}, "0", "10px")};
    scroll-snap-align: start;
    display: inline-flex;
    gap: ${prop("gap", "4px")};
    min-width: 100%;

    > div {
      width: ${prop("size", "auto")};
    }

    * {
      scroll-snap-align: start;
    }
  }
`;