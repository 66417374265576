// Base url BFF
export const baseURL = import.meta.env.VITE_BASE_URL;

// x-api-key
export const API_KEY = import.meta.env.VITE_API_KEY;

// Contacts
export const contactsAPIUrl = import.meta.env.VITE_CONTACTS_URL || "";

// Contact-us
export const contactusAPIUrl = import.meta.env.VITE_CONTACTUS_URL || "";
export const contactUsApiKey = import.meta.env.VITE_CONTACTUS_API_KEY || "";

// External Services
export const urlExternalServices = import.meta.env.VITE_URL_EXTERNAL_SERVICES;

// Base URL IAM
export const baseURLiam = import.meta.env.VITE_BASE_URL_IAM;

// URL Auth Site
export const baseURLAuthSite = import.meta.env.VITE_FRONT_AUTHENTICATION_URL;

// x-api-key iam
export const API_KEY_IAM = import.meta.env.VITE_API_KEY_IAM;

// URL for Mi Celular Sendola
export const MyCelular_URL = import.meta.env.VITE_URL_MY_CELULAR || "";

// URL for WhatsApp contact
export const WhatsAppLinkEs = import.meta.env.VITE_WHATSAPP_LINK_ES || "";
export const WhatsAppLinkEn = import.meta.env.VITE_WHATSAPP_LINK_EN || "";

// Base URl for Global Payments BFF
export const baseURLGP =
  import.meta.env.VITE_GLOBAL_PAYMENTS_BFF_BASE_URL || "";

// x-api-key for Global Payments BFF
export const API_KEY_GP =
  import.meta.env.VITE_GLOBAL_PAYMENTS_BFF_API_KEY || "";

  // URL for WhatsApp contact Cellphone
export const CellphoneWhatsAppLinkEs = import.meta.env.VITE_CELLPHONE_WHATSAPP_LINK_ES || "";
export const CellphoneWhatsAppLinkEn = import.meta.env.VITE_CELLPHONE_WHATSAPP_LINK_EN || "";
