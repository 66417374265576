import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_ONBOARDING_STEP } from "../../Constants/user";
import { Onboarding } from "../../Models/Onboarding";
import { defaultErrorMessage } from "../../Constants/Requests";
import { AxiosError } from "axios";

// Own services Api
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";

export const OnboardingSliceAction = createAsyncThunk<Onboarding, string, { rejectValue: string }>(
  GET_ONBOARDING_STEP,
  async (userId: string, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post(
        `/persons/by-user/${userId}/onboarding-step`,
        { userId }
      );
      return response.data.data;
    } catch (error) { 
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
