import { createAsyncThunk } from "@reduxjs/toolkit";
import { EmailPayload } from "../../Models/Email";
import { UPDATE_USER_EMAIL } from "../../Constants/user";
import { defaultErrorMessage } from "../../Constants/Requests";
import { AxiosError } from "axios";

// Own services Api
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";

export const UpdateEmailSliceAction = createAsyncThunk(
  UPDATE_USER_EMAIL,
  async (payload: EmailPayload, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post(
        `/persons/${payload.personId}/email`,
        {
          personId: payload.personId,
          email: payload.email
        }
      );
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
