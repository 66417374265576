import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SearchSelect } from "@components/SearchSelect/SearchSelect.component";
import { useCountry } from "@hooks/Payments/useCountry";
import { useSubdivision } from "@hooks/Payments/useSubdivision";
import { selectorLanguage } from "@redux/Translate";
import { CustomSelectInstance, SelectOption } from "@models/Select";
import { useCity } from "@hooks/Payments/useCity";
import { FormInput } from "@components/Payments/Input";
import { CountryCodeList } from "@core/Payments/Common/domain/PhoneCountryCode";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { allowedNumbers, allowedNonSpecialCharacters, allowedSoftSpecialCharacters } from "@constants/Regexp";
import { QuotationSelector } from "@redux/Payments/Quotation/Quotation.slice";

interface AddressFormProps {
  countrySelected?: string | null;
  subdivisionSelected?: string | null;
  citySelected?: string | null;
  lockCountry?: boolean;
  onLoadSubdivisions?: (subdivisions: SelectOption[]) => void;
  onSelectCountry?: (countryCode: string) => void;
  onLoading?: (value: boolean) => void;
}

export const AddressForm = ({
  countrySelected,
  subdivisionSelected,
  citySelected,
  lockCountry,
  onLoadSubdivisions,
  onSelectCountry,
  onLoading,
}: AddressFormProps) => {
  const { t } = useTranslation("global");
  const { language } = useSelector(selectorLanguage);
  const { formState, control, setValue, getValues } = useFormContext();
  const { isLoading: countryIsLoading, countriesWithoutUSAOptions, getDestinationCountryList } = useCountry();
  const { isLoading: subdivisionIsLoading, subdivisionList, getSubdivisionOptions } = useSubdivision();
  const { isLoading: cityIsLoading, getSubdivisionCitiesOptions } = useCity();
  const subdivRef = useRef<CustomSelectInstance>(null);
  const cityRef = useRef<CustomSelectInstance>(null);

  const { errors } = formState;

  const {
    data: { countryDestination },
  } = useSelector(QuotationSelector);

  const zipCodeLenght = useMemo(() => {
    const allowedLength = CountryCodeList.find((c) => c.countryCode === countrySelected)?.zipCodeLength || 5;

    return allowedLength;
  }, [countrySelected]);

  const formValues = useMemo(() => {
    return getValues();
  }, [formState]);

  const onChangeCountry = async (value: string, clear = true) => {
    if (onSelectCountry) {
      onSelectCountry(value);
    }

    if (clear) {
      setValue("subnational", "");
      subdivRef.current?.clearValue();
    }

    const subdivisions = await getSubdivisionOptions(value, language);
    if (subdivisions && onLoadSubdivisions) {
      onLoadSubdivisions(subdivisions);
    }
  };

  const onChangeSubdivision = async (value: string, clear = true) => {
    if (clear) {
      setValue("city", "");
      cityRef.current?.clearValue();
    }
    if (value) {
      await getSubdivisionCitiesOptions(value);
    }
  };

  const initializeForm = async () => {
    await getDestinationCountryList(language);
    if (countryDestination && !formValues?.country) {
      const countryOption = countriesWithoutUSAOptions.find((c) => c.value === countryDestination);
      if (countryOption) {
        onChangeCountry(countryOption.value, false);
      }
    }

    if (countrySelected && language) {
      setValue("country", countrySelected);
      await onChangeCountry(countrySelected, false);

      if (subdivisionSelected) {
        setValue("subnational", subdivisionSelected);
        await onChangeSubdivision(subdivisionSelected, false);
      }

      if (citySelected) {
        setValue("city", citySelected);
      }
    }
  };

  useEffect(() => {
    initializeForm();
  }, [countrySelected, subdivisionSelected, citySelected, language]);

  useEffect(() => {
    if (onLoading) {
      onLoading(countryIsLoading || subdivisionIsLoading);
    }
  }, [countryIsLoading, subdivisionIsLoading, cityIsLoading]);

  return (
    <>
      <Controller
        control={control}
        name="country"
        rules={{ required: t("Forms.required") }}
        render={({ field: { onBlur, onChange, value } }) => (
          <>
            <SearchSelect
              errors={errors}
              placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.Country")}
              options={countriesWithoutUSAOptions}
              name="country"
              value={countriesWithoutUSAOptions.find((c) => c.value === value)}
              m="0 0 15px 0"
              showAvatar
              showAvatarOnValue
              isDisabled={lockCountry}
              onBlur={onBlur}
              onChange={(sel) => {
                if (sel?.value) {
                  onChange(sel.value);
                  onChangeCountry(sel.value);
                }
              }}
            />
          </>
        )}
      />

      <Controller
        control={control}
        name="subnational"
        rules={{ required: t("Forms.required") }}
        render={({ field: { onBlur, onChange, value } }) => (
          <>
            <SearchSelect
              selectRef={subdivRef}
              errors={errors}
              placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.State")}
              options={subdivisionList}
              name="subnational"
              placeholder=""
              value={subdivisionList.find((s) => s.value === value)}
              m="0 0 15px 0"
              onBlur={onBlur}
              onChange={(sel) => {
                if (sel?.value) {
                  onChange(sel?.value);
                  onChangeSubdivision(sel?.value);
                }
              }}
            />
          </>
        )}
      />

      <FormInput
        placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.City")}
        label="city"
        rules={{
          required: t("Forms.required"),
          pattern: {
            value: allowedNonSpecialCharacters,
            message: t("Forms.invalidCharacters"),
          },
          maxLength: {
            value: 100,
            message: t("Forms.maxLength", { max: 100 }),
          },
          validate: (value) => (value.trim() === "" ? t("Forms.required") : true),
        }}
        mb="15px"
        isError={Boolean(errors?.["city"])}
      />

      <FormInput
        placeholderInput={
          countrySelected && ["MEX", "GTM"].includes(countrySelected)
            ? t("Beneficiaries.CreateBeneficiary.Form.AddressStreet")
            : t("Beneficiaries.CreateBeneficiary.Form.Address1")
        }
        label="address1"
        rules={{
          required: t("Forms.required"),
          validate: (value) => (value.trim() === "" ? t("Forms.required") : true),
          pattern: {
            value: allowedSoftSpecialCharacters,
            message: t("Forms.invalidCharacters"),
          },
          maxLength: {
            value: 100,
            message: t("Forms.maxLength", { max: 100 }),
          },
        }}
        mb="15px"
        isError={Boolean(errors?.["address1"])}
      />

      <FormInput
        placeholderInput={
          countrySelected && ["MEX", "GTM"].includes(countrySelected)
            ? t("Beneficiaries.CreateBeneficiary.Form.AddressApartment")
            : t("Beneficiaries.CreateBeneficiary.Form.Address2")
        }
        label="address2"
        rules={{
          pattern: {
            value: allowedSoftSpecialCharacters,
            message: t("Forms.invalidCharacters"),
          },
          maxLength: {
            value: 50,
            message: t("Forms.maxLength", { max: 50 }),
          },
        }}
        mb="15px"
      />

      <FormInput
        placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.PostalCode")}
        typeInput="number"
        inputMode="numeric"
        maxLength={zipCodeLenght}
        label="postalCode"
        rules={{
          required: t("Forms.required"),
          minLength: {
            value: zipCodeLenght,
            message: t("Beneficiaries.CreateBeneficiary.Form.PostalCodeMinLength", {
              minLength: zipCodeLenght,
            }),
          },
          maxLength: {
            value: zipCodeLenght,
            message: t("Beneficiaries.CreateBeneficiary.Form.PostalCodeMinLength", {
              minLength: zipCodeLenght,
            }),
          },
          validate: (value) => (value.trim() === "" ? t("Forms.required") : true),
          pattern: {
            value: allowedNumbers,
            message: t("Forms.inputNumeric"),
          },
        }}
        isError={Boolean(errors?.["postalCode"])}
        mb="40px"
      />
    </>
  );
};
