
import styled from "styled-components";
import { theme } from "styled-tools";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

// Components
import Text from "../../Components/Text";
import Title from "../../Components/Title";

export const BankTransfersAccountContainer = styled.div`
  margin-bottom: 40px;

  ${mediaQuery[Screen.desktop]} {
    padding-inline: 300px;
  }
`;

export const BankTransfersDetailsCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: ${theme("Background_12")};
`;

export const CustomTitle = styled(Title)`
  font-family: Poppins;
`;

export const CustomText = styled(Text)<{
  status?: string;
}>`
  font-family: Poppins;
  ${(props) => {
    switch (props.status) {
      case "completed":
        return `color: ${props.theme.Green_01}; `;
      case "failed":
        return `color: ${props.theme.Red_10}; `;
      default:
        return;
    }
  }};
`;

export const CursorPointerStyled = styled.div`
  cursor: pointer;
`;