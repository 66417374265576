import { createAsyncThunk } from "@reduxjs/toolkit";
import { ShippingAddressPayload } from "../../Models/ShippingAddress";
import { UPDATE_USER_SHIPPING_ADDRESS } from "../../Constants/user";
import { defaultErrorMessage } from "../../Constants/Requests";
import { AxiosError } from "axios";

// Own services Api
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";

export const UpdateShippingAddressSliceAction = createAsyncThunk(
  UPDATE_USER_SHIPPING_ADDRESS,
  async (payload: ShippingAddressPayload, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post(
        `/persons/${payload.personId}/shipping-address`,
        { 
          preferredShippingAddress: payload.preferredShippingAddress
        }
      );
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
