import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

// Own Components
import Layout from "../../../Components/Layout";
import { Column, Container, Row } from "../../../Components/Grid";
import Icon from "../../../Components/Icon";
import PlaidLink from "../Components/PlaidLink";
import Loader from "../../../Components/Loader";

// Own States
import { State } from "../../../Redux/Store";
import { selectorLanguage } from "../../../Redux/Translate";

// Own Models
import { JustifyContent } from "../../../Models/Column";

// Own Styles
import {
  Example,
  IconContainer,
  InstructionsCard,
  InstructionsContainer,
  InstructionsList,
  InstructionsText,
  TitleContainer,
} from "./CaptureCheckInstructions.styles";

// Assets
import PlaidInstructions from "../../../Assets/Img/plaid-instructions.svg";
import PlaidStep3 from "../../../Assets/Img/step3.png";
import PlaidStep3_es from "../../../Assets/Img/step3_es.png";

// Constants
const FundsCaptureCheckInstructions = () => {
  const { t } = useTranslation("global");
  const lang = useSelector(selectorLanguage);
  const navigate = useNavigate();
  const theme = useSelector((state: State) => state.theme);
  const { state } = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const [showFunction, setShowFunction] = useState(false);

  if (!state?.firstAccount) {
    return <Navigate to="/funds-linked-accounts" replace />;
  }

  return (
    <ThemeProvider theme={theme}>
      {isLoading && <Loader full />}
      <Layout
        path={state.homePage ? "/" : state.backUrl ? state.backUrl : null } 
        textLink={t("buttons.back")}
      >
        <Container as={InstructionsContainer}>
          <Row>
            <Column span={12}>
              <Row>
                <Column
                  span={12}
                  justifyContent={JustifyContent.center}
                  mt={3}
                  mb={4}
                >
                  <img
                    src={PlaidInstructions}
                    alt={t("Funds.CheckInstructions.titleInstructions")}
                  />
                </Column>
                <Column span={12}>
                  <InstructionsText
                    fontSize={20}
                    lineHeight="30px"
                    weight={400}
                    size={3}
                    margin="0 20px 12px 20px"
                    color="Primary_16"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("Funds.CheckInstructions.info"),
                      }}
                    />
                  </InstructionsText>
                  <InstructionsList>
                    <li>
                      <InstructionsText
                        size={1}
                        color="grey"
                        weight="400"
                        lineHeight="24px"
                        margin="12px 0 0 0"
                        align="left"
                      >
                        {t("Funds.CheckInstructions.step1")}
                      </InstructionsText>
                    </li>
                    <li>
                      <InstructionsText
                        size={1}
                        color="grey"
                        weight="400"
                        lineHeight="24px"
                        margin="0"
                        align="left"
                      >
                        {t("Funds.CheckInstructions.step2")}
                      </InstructionsText>
                    </li>
                    <li>
                      <InstructionsText
                        size={1}
                        color="grey"
                        weight="400"
                        lineHeight="24px"
                        margin="0"
                        align="left"
                      >
                        {t("Funds.CheckInstructions.step3")}
                      </InstructionsText>
                    </li>
                    <li>
                      <InstructionsText
                        size={1}
                        color="grey"
                        weight="400"
                        lineHeight="24px"
                        margin="0 0 16px 0"
                        align="left"
                      >
                        {t("Funds.CheckInstructions.step4")}
                      </InstructionsText>
                    </li>
                  </InstructionsList>
                  <Example>{t("Funds.CheckInstructions.example")}</Example>
                  <img
                    className="check-instructions-img"
                    src={lang.language === "en" ? PlaidStep3 : PlaidStep3_es}
                    alt={t("Funds.CheckInstructions.step3")}
                  />
                </Column>
                <Column span={12}>
                  <Row>
                    <Column span={12} as={InstructionsCard}>
                      <TitleContainer
                        onClick={() => setShowFunction(!showFunction)}
                      >
                        <Icon
                          icon={"circleInfo"}
                          size="medium"
                          color="primary_500"
                        />
                        <InstructionsText
                          size={0.5}
                          color="black"
                          weight="600"
                          margin="0"
                          align="left"
                        >
                          {t("Funds.CheckInstructions.titleInstructions")}
                        </InstructionsText>
                        <IconContainer>
                          <Icon
                            icon={showFunction ? "chevronUp" : "chevronDown"}
                            size="small"
                            color="primary_500"
                          />
                        </IconContainer>
                      </TitleContainer>
                      {showFunction && (
                        <Row>
                          <Column span={12}>
                            <InstructionsText
                              size={0.3}
                              lineHeight="18px"
                              color="neutral_2"
                              weight="400"
                              margin="12px 0"
                              align="left"
                            >
                              {t("Funds.CheckInstructions.instructions1")}
                            </InstructionsText>
                            <InstructionsText
                              size={0.3}
                              lineHeight="18px"
                              color="neutral_2"
                              weight="400"
                              margin="12px 0"
                              align="left"
                            >
                              {t("Funds.CheckInstructions.instructions2")}
                            </InstructionsText>
                            <InstructionsText
                              size={0.3}
                              lineHeight="18px"
                              color="neutral_2"
                              weight="400"
                              margin="12px 0 0 0"
                              align="left"
                            >
                              {t("Funds.CheckInstructions.instructions3")}
                            </InstructionsText>
                          </Column>
                        </Row>
                      )}
                    </Column>
                  </Row>
                </Column>
                <Column span={12} mb={6}>
                  <PlaidLink
                    currentAccountId={state?.accountId}
                    linkCustomizationName={state?.linkCustomName}
                    btnText={t("Funds.CheckInstructions.btnText")}
                    btnIcon="arrowLeft"
                    onLoading={setIsLoading}
                    onCompleted={(accountId: string) => {
                      if (state.homePage) {
                        return navigate(state.backUrl ? state.backUrl : "/", { replace: true });
                      }

                      return navigate("/funds-linked-accounts-details", {
                        state: {
                          accountId,
                          firstAccount: true,
                        },
                      });
                    }}
                  />
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default FundsCaptureCheckInstructions;
