import { MouseEvent, ReactNode, RefObject } from "react";
import {
  PDFViewerModalContent,
  PDFViewerHeader,
  PDFViewerModalStyled,
  PDFViewerModalOverlay,
  PDFViewerFooter,
  PdfHeaderClose,
  PdfHeaderDownload,
  PdfHeaderRight,
} from "./PDFViewerModal.styled";
import Icon from "../../../Icon";
import Button from "../../../Button";
import { useTranslation } from "react-i18next";
import { downloadFile } from "../../../../Utilities/Files";
type Props = {
  isOpen: boolean;
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  modalRef?: RefObject<HTMLDivElement>;
  onClose: (event: MouseEvent<HTMLElement>) => void;
  canDownload?: boolean;
  url?: string;
};

const PDFViewerModal = ({
  isOpen,
  onClose,
  children,
  header,
  footer,
  modalRef,
  canDownload,
  url
}: Props) => {
  const { t } = useTranslation("global");
  return (
    <>
      <PDFViewerModalStyled
        isOpen={isOpen}
        ref={modalRef}
        tabIndex={-1}
        role="dialog"
        onClick={(event) => event.preventDefault()}
      >
        <PDFViewerHeader>
          {header || null}
          <PdfHeaderRight onClick={onClose}>
            {canDownload && (
              <PdfHeaderDownload>
                <Button
                  variant="primary"
                  text={t("buttons.download")}
                  sizeText="small"
                  colorIcon="white"
                  sizeIcon="small"
                  iconButtonLeft="download"
                  sizeButton="small"
                  onClick={() => downloadFile("", url)}
                />
              </PdfHeaderDownload>
            )}
            <PdfHeaderClose>
              <Icon icon="circleX" size="large" color="white" />
            </PdfHeaderClose>
          </PdfHeaderRight>
        </PDFViewerHeader>
        <PDFViewerModalContent>{children}</PDFViewerModalContent>
        <PDFViewerFooter>{footer || null}</PDFViewerFooter>
      </PDFViewerModalStyled>
      <PDFViewerModalOverlay isOpenModal={isOpen} />
    </>
  );
};

export default PDFViewerModal;
