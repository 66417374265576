import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { Document, pdfjs } from "react-pdf";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import Button from "../Button";
import Link from "../Link";
import Loader from "../Loader";

import {
  PDFViewerConfirmStyled,
  PDFViewerNavigatorStyled,
  PDFViewerScrollAnimation,
  PDFViewerStyled,
  PDFViewerLoaderStyled,
} from "./PDFViewer.styled";

import PDFViewerModal from "./components/PDFViewerModal";
import PDFViewerPage from "./components/PDFViewerPage";
import PDFViewerZoom from "./components/PDFViewerZoom";
import IconFont from "../IconFont";
import { isMobile } from "react-device-detect";
import { SizesKey } from "@utils/Sizes";
import { FontColor } from "@utils/Icons";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

type Props = {
  url: string;
  text: string;
  size?: SizesKey;
  variant?: FontColor;
  confirmButtonText?: string;
  canDownload?: boolean;
  onClose?: () => void;
  handleConfirmButton?: () => void;
};

const PDFViewer = ({ text, size, url, variant, onClose }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [pageWidth, setPageWidth] = useState(0);
  const [visiblePages, setVisiblePages] = useState(1);
  const [documentScale, setDocumentScale] = useState<number>(0.6);
  const documentRef = useRef<HTMLDivElement>(null);
  const pdfFile = useMemo(() => ({ url }), [url]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const onPageLoadSuccess = () => {
    setPageWidth(window.innerWidth);
  };

  const onDocumentResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;

    if (entry) {
      setPageWidth(entry.contentRect.width);
    }
  }, []);

  const onZoomIn = () => {
    if (documentScale > 0.8) return;
    setDocumentScale((prevState) => prevState + 0.1);
  };

  const onZoomOut = () => {
    if (documentScale === 0.6) return;
    setDocumentScale((prevState) => prevState - 0.1);
  };

  useEffect(() => {
    if (!documentRef?.current || !("ResizeObserver" in window)) {
      return;
    }

    const observer = new ResizeObserver(onDocumentResize);

    observer.observe(documentRef?.current);

    return () => {
      observer.disconnect();
    };
  }, [documentRef, onDocumentResize]);

  const setPageVisibility = useCallback((pageNumber: number) => {
    setVisiblePages(pageNumber);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "17px";
    } else {
      setNumPages(0);
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    }
  }, [isOpen]);

  return (
    <>
      <Link
        onClick={() => setIsOpen(true)}
        href="#"
        variant={variant || "white"}
        size={size || 1}
        underline
      >
        {text}
      </Link>
      {isOpen && (
        <PDFViewerModal
          isOpen={true}
          header={
            <>
              {numPages > 0 && (
                <PDFViewerPageNavigator
                  totalPages={numPages}
                  currentPage={visiblePages}
                />
              )}
            </>
          }
          onClose={(event) => {
            event.preventDefault();
            setIsOpen(false);
            if (onClose) onClose();
          }}
        >
          <PDFViewerStyled ref={documentRef}>
            {!(numPages == visiblePages) && (
              <>
                {isMobile ? (
                  <PDFViewerScrollAnimation>
                    <IconFont name="arrows" color="white" size="medium" />
                  </PDFViewerScrollAnimation>
                ) : (
                  <PDFViewerScrollAnimation>
                    <IconFont name="chevron-down" color="white" size="medium" />
                  </PDFViewerScrollAnimation>
                )}
              </>
            )}
            <PDFViewerZoom zoomIn={onZoomIn} zoomOut={onZoomOut}>
              <Document
                file={pdfFile}
                onLoadSuccess={onDocumentLoadSuccess}
                className="pdf-container"
                loading={
                  <PDFViewerLoaderStyled>
                    <Loader full={true} />
                  </PDFViewerLoaderStyled>
                }
              >
                {Array.from(new Array(numPages), (_el, index) => (
                  <PDFViewerPage
                    totalPages={numPages}
                    isOpenedModal={isOpen}
                    key={`page_${index + 1}`}
                    className="pdf-page"
                    width={Math.max(pageWidth * documentScale, 350)}
                    pageNumber={index + 1}
                    onLoadSuccess={onPageLoadSuccess}
                    setPageVisibility={setPageVisibility}
                  />
                ))}
              </Document>
            </PDFViewerZoom>
          </PDFViewerStyled>
        </PDFViewerModal>
      )}
    </>
  );
};

const PDFViewerPageNavigator = ({
  currentPage,
  totalPages,
}: {
  totalPages: number;
  currentPage: number;
}) => {
  return (
    <PDFViewerNavigatorStyled>
      <span>
        {currentPage} / {totalPages}
      </span>
    </PDFViewerNavigatorStyled>
  );
};

export const PDFViewerConfirm = ({
  handleConfirmButton,
  activeConfirmButton,
  confirmButtonText,
}: {
  activeConfirmButton: boolean;
  confirmButtonText: string;
  handleConfirmButton?: () => void;
}) => {
  return (
    <PDFViewerConfirmStyled>
      {handleConfirmButton && (
        <Button
          variant={activeConfirmButton ? "primary" : "default"}
          disabled={!activeConfirmButton}
          sizeButton="xlarge"
          sizeText="small"
          text={confirmButtonText ? confirmButtonText : "Ok"}
          onClick={handleConfirmButton}
        />
      )}
    </PDFViewerConfirmStyled>
  );
};

export default PDFViewer;
