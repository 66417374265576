import styled from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { theme } from "styled-tools";

export const HeaderStyled = styled.div`
  background-color: ${(props) => props.theme.Neutral_900};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  position: relative;
  margin: 0 auto;
  width: 100%;
`;

export const Logo = styled.div`
  img {
    height: 45px;
    width: 137px;
  }
`;

export const ProfileSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  width: 140px;
`;

export const LanguageSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  width: 100%;
  padding-left: 16px;
  p {
    display: inline-block;
    font-weight: 600;
  }

  ${mediaQuery[Screen.md]} {
    box-sizing: border-box;
    justify-content: right;
    width: 100%;
  }

  ${mediaQuery[Screen.mobile]} {
    box-sizing: border-box;
    justify-content: right;
    width: 100%;
  }
`;

export const ButtonsSection = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  min-width: 80px;
  background: rgba(${(props) => props.theme.RGBA_07});
`;

export const LanguageContainer = styled.div`
  display: flex;

  h4 {
    margin-left: 8px;
  }
`;

export const IconSection = styled.div`
  margin-left: 10px;

  ${mediaQuery[Screen.mobile]} {
    margin-left: 5px;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 80px;
  margin-right: 16px;
  div:first-child {
    cursor: pointer;
  }
`;

export const MenuIconContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

export const MenuContent = styled.div`
  box-shadow: 4px 4px 15px 0px rgba(${(props) => props.theme.RGB_06});
  display: none;
  position: absolute;
  text-align: center;
  width: 264px;
  z-index: 1002;
  top: 65px;
  right: 0;
  border-radius: 0px 0px 0px 24px;

  &.show {
    display: block;
  }
`;

export const MenuButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 16px;
  border-radius: 0px 0px 24px 0px;

  a {
    width: 160px;
  }
`;

export const MenuNav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  background: rgba(${(props) => props.theme.RGBA_07});
  border-radius: 0px 0px 0px 24px;
  box-shadow: 4px 4px 15px 0px rgba(${(props) => props.theme.RGB_06});

  p {
    margin: 8px 0;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  padding-block: 16px;
  padding-inline: 16px;
  border-bottom: 1px solid ${theme("Dark_04")};

  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background: ${theme("Grey")};
    &:last-child {
      border-radius: 0px 0px 0px 24px;
    }
  }
`;

export const Options = styled.div<{ $show: boolean }>`
  background: rgba(${(props) => props.theme.RGBA_07});
  display: ${(props) => (props.$show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 150px;
  border-radius: 0px 0px 24px 0px;
  box-shadow: 4px 4px 15px 0px rgba(${(props) => props.theme.RGB_06});
  position: absolute;
  top: 45px;
  z-index: 1002;
`;

export const Element = styled.button`
  cursor: pointer;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  padding: 16px 0px;
  color: ${theme("Neutral_900")};
  width: -webkit-fill-available;
  z-index: 1002;
  border: none;
  background: none;
  padding-inline: 16px;
  &:hover {
    background: ${theme("Grey")};

    &:last-child {
      border-radius: 0px 0px 24px 0px;
    }
  }
`;
export const BorderGray = styled.div`
  width: 80%;
  height: 1px;
  background-color: ${theme("Dark_04")};
`;

export const ButtonExternalService = styled.div`
  margin: 0 16px;
`;

export const BlockedView = styled.div`
  height: 290vh;
  position: absolute;
  top: 65px;
  left: 0;
  width: 100%;
  z-index: 99;

  ${mediaQuery[Screen.tablet]} {
    height: 150vh;
  }

  ${mediaQuery[Screen.mobile]} {
    height: 270vh;
  }
`;
