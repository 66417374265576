import { createSlice } from "@reduxjs/toolkit";
import { GetProofAddressRequired } from "./ProofAddressRequired.actions";
import { GetProofAddressRequiredState } from "../../Models/ProofOfAddress";
import { State } from "../Store";

const initialState: GetProofAddressRequiredState = {
  loading: false,
  error: null,
  data: null
};

export const GetProofAddressRequiredSlice = createSlice({
  name: "getProofAddressRequired",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetProofAddressRequired.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetProofAddressRequired.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(GetProofAddressRequired.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export const GetProofAddressRequiredSelector = (state: State) => state.getProofAddressRequired;
