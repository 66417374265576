import styled from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.Background_02};
  background-color: rgba(${(props) => props.theme.RGBA_01}, 0.75);
`;

export const PopupCard = styled.div`
  margin: 70px auto;
  padding: 24px 10px;
  background: ${(props) => props.theme.Background_01};
  border: 1px solid ${(props) => props.theme.Content_State_UX_Hover};
  width: 350px;
  border-radius: 45px;
  box-shadow: 0 0 50px rgba(${(props) => props.theme.RGBA_01}, 0.3);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;

  ${mediaQuery[Screen.sm]} {
    width: 75%;
    max-width: 374px;
  }

  ${mediaQuery[Screen.mobile]} {
    width: 100%;
    max-width: 374px;
  }
`;

export const IconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;

  & div, svg {
    width: 45px;
    height: 45px;
  }
`;

export const TextContainer = styled.div`
  width: 100%;

  h1 {
    font-family: Poppins;
    padding: 0 50px;
    text-align: center;
  }

  h5 {
    padding: 0 50px;
  }
`;

export const ButtonContainer = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: center;

  button {
    span {
      font-size: 20px;
    }
  }
`;
