
import { PlaidAccount } from "react-plaid-link";

export interface FundsSelectAccountForm {
  amount: number
}

export interface PaymentPlaid {
  accountId: string;
  accountNumber: string;
  routingNumber: string;
  fullName?: string;
  bankName: string;
}

export interface FundsState {
  loading: boolean;
  error: unknown | null;
  plaidAccounts: PlaidAccountsResponse | null;
  plaidAccountDetails: PlaidAccountsDetail | null;
  plaidToken: PlaidCreateTokenStatus | null;
  plaidExchangeToken: PlaidTokenExchangeResponse | null;
  plaidAccountSelected: PlaidAccounts | null;
  bankTransfers: Array<BankTransfer> | null;
  bankTransferDetails: BankTransferDetails | null;
  bankTransferCreated: BankTransferRequest | null;
}

export interface PlaidStatus {
  succeeded?: boolean;
  message?: string;
  error?: Array<string>;
}

export interface PlaidCreateTokenRequest {
  clientName: string;
  countryCodes: Array<string>;
  language: string;
  user: {
    clientUserId: string;
  };
  products: Array<string>;
}

export interface PlaidCreateTokenStatus extends PlaidStatus {
  expiration: string;
  linkToken: string;
  requestId: string;
}

export interface PlaidTokenExchangeRequest {
  institutionId: string;
  publicToken: string;
  accounts?: Array<PlaidAccount>;
}

export interface PlaidTokenExchangeResponse extends PlaidStatus {
  requestId: string;
  accountId: string;
}

export interface PlaidAccounts {
  id: string;
  plaidAccountId: string;
  bankName: string;
  accountName: string;
  accountLastFour: string;
  routingNumber?: string;
  subtype: string;
  available: null | number;
  activeToken: boolean;
  logoUrl?: string;
  backgroundColor?: string;
}

export interface PlaidAccountsRequest {
  userId: string;
}

export interface PlaidAccountsResponse extends PlaidStatus {
  hasAccounts: boolean;
  accounts: Array<PlaidAccounts>;
  accountsLedger: Array<PlaidAccounts>;
}

export interface PlaidAccountsDetail extends PlaidStatus {
  accountId: string;
  accessTokenID: string;
  bankName: string;
  plaidAccountId: string;
  plaidAccountName: string;
  accountLastFour: string;
  account: string;
  routing: string;
  routingLastFour: string;
  wireRouting: string;
  status: string;
  subtype: string;
  balance: {
    available: number;
    current: number;
    isoCurrencyCode: string;
    limit: number;
    unofficialCurrencyCode: string;
  };
}

export interface PlaidAccountsDelete extends PlaidStatus {
  deleted: boolean;
}

export interface PlaidAccountsUpdateRequest extends PlaidStatus {
  redirectUri: string;
  accountId: string;
}

export enum BankTransferStatus {
  PENDING = "pending",
  COMPLETED = "completed",
  PROCESSING = "processing",
  FAILED = "failed",
  DEFAULT = "default"
}

export interface BankTransferRequest {
  sourceAccountNumber?: string;
  sourceRoutingNumber?: string;
  contactId: string | null;
  destinationAccountNumber: string;
  destinationRoutingNumber: string;
  amount: number;
  transferOrderType: string;
  status: string;
  txnType: string;
}

export interface BankTransfer {
  id: string;
  personId: string;
  transferNumber: string;
  sourceAccountNumber: string;
  sourceRoutingNumber: string;
  contactId: string | null;
  destinationAccountNumber: string;
  destinationRoutingNumber: string;
  amount: number;
  transferOrderType: {
    description: string;
    createdAt: string;
    modifiedAt: string;
    id: string;
    isDeleted: boolean;
  };
  status: string;
  txnType: string;
}


export interface BankTransferDetails {
  id: string;
  title: string;
  amount: number;
  txnType: string;
  transferType: string;
  description: string;
  balance: string;
  status: string;
  createdAt: string;
  modifiedAt: string;
}