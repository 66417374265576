import styled from "styled-components";

import { ButtonGradientProps } from "../../Models/buttonInterface";

export const ContainerButton = styled.div`
  width: 65px;
  margin-inline: 4px;
`

export const RoundBtn = styled.button<{$type?: string; $bgGradient?: ButtonGradientProps, $fillIcon?: boolean, $padding?: string, $sizeButton?: string }>`
  margin-bottom: 8px;
  padding: ${(props) => (props.$padding ? props.$padding : "10px")};
  border-radius: 50%;
  cursor: pointer;
  margin-inline: auto;
  display: block;
  height: ${(props) => (props.$sizeButton === "medium" ? "48px" : props.$sizeButton === "small" ? "40px" : "60px")};
  width: ${(props) => (props.$sizeButton === "medium" ? "48px" : props.$sizeButton === "small" ? "40px" : "60px")};
  ${(props) => {
    switch (props.$type) {
      case "primary":
        return `border: 1px solid ${props.theme.Primary_01}; background: ${props.theme.Primary_01}; color: ${props.theme.Background_01}; `;

      case "primary_gradient":
        return `border: none; background: ${props.theme.Gradient_08}; color: ${props.theme.Gradient_08}; `;
      
      case "light_gradient":
        return `border: none; background: ${props.theme.Gradient_10}; color: ${props.theme.Background_01}; `;

      case "outline":
        return `border: 2px solid ${props.theme.Primary_01} !important; background: transparent; color: ${props.theme.Primary_01};`;
      
      case "outline_light":
        return `border: 2px solid ${props.theme.Background_01} !important; background: transparent; color: ${props.theme.Background_01};`;

      case "secondary":
        return `box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset; background: ${props.theme.Background_02}; color: ${props.theme.Background_01}; `;

      case "danger":
        return `border: 1px solid ${props.theme.Red_08}; background: ${props.theme.Background_01}; color: ${props.theme.Red_08}; `;
      case "gradient":
        return `border: 0 solid ${props.theme.Transparent}; background: linear-gradient(
          to right, 
          ${props.theme.Tanhide_Color},
          ${props.theme.Bluebonnet_Color});`;
      case "purple":
        return `border: 1px solid ${props.theme.Primary_13}; background: ${props.theme.Primary_13}; color: ${props.theme.Background_01}; `;

      default:
        return `border: 1px solid ${props.theme.Content_UX_02}; background: ${props.theme.Content_UX_02}; color: ${props.theme.Background_01}; `;
    }
  }};

  path {
    fill: ${(props) => ((props.$fillIcon && props.$type === "outline") ? props.theme.Primary_01 : props.$fillIcon ? props.theme.Background_01 : "none")};
  }
`