import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { defaultErrorMessage } from "../../Constants/Requests";
import { UPDATE_STATUS_COPPEL } from "../../Constants/CoppelAccess";

/**
 * Method to update Coppel Access
 */
export const updateCoppelAccess = createAsyncThunk(
  UPDATE_STATUS_COPPEL,
  async (personId: string, thunkAPI) => {
    const headers = {
      "personId": personId
    };
    try {
      const response = await BaseConfigApi.post(`/coppelaccess/status`, {}, {headers});
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);