// External Services Links

// Sendola One
export const OneExternalServices = import.meta.env.VITE_LINK_SENDOLA_ONE;

// Sendola Payments
export const PaymentsExternalServices = import.meta.env.VITE_LINK_PAYMENTS;

// Sendola Repatriation
export const RepatriationExternalServices = import.meta.env.VITE_LINK_REPATRIATION;
export const RepatriationExternalServicesEn = import.meta.env.VITE_LINK_REPATRIATION_EN;

// Sendola Mi celular
export const MyCelularExternalServices = import.meta.env.VITE_LINK_MY_CELULAR;
export const MyCelularExternalServicesEn = import.meta.env.VITE_LINK_MY_CELULAR_EN;

// Sendola Coppel Access celular
export const CoppelAccessCelularExternalServices = import.meta.env.VITE_LINK_COPPEL_ACCESS_CELULAR;
export const CoppelAccessCelularExternalServicesEn = import.meta.env.VITE_LINK_COPPEL_ACCESS_CELULAR_EN;

// Sendola Cash In
export const CashInLink = import.meta.env.VITE_LINK_CASH_IN;
export const CashInLinkEn = import.meta.env.VITE_LINK_CASH_IN_EN;

// Sendola External Cash In
export const ExternalCashInLinkEs = import.meta.env.VITE_LINK_EXTERNAL_CASH_IN_ES;
export const ExternalCashInLinkEn = import.meta.env.VITE_LINK_EXTERNAL_CASH_IN_EN;

// Sendola Zelle banner
export const ZelleBannerES = import.meta.env.VITE_LINK_ZELLE_BANNER_ES;
export const ZelleBannerEN = import.meta.env.VITE_LINK_ZELLE_BANNER_EN;
export const ZelleAndroidStoreUrl = import.meta.env.VITE_LINK_ZELLE_ANDROID_STORE;
export const ZelleIosStoreUrl = import.meta.env.VITE_LINK_ZELLE_IOS_STORE;


// Sendola Zelle banner
export const VisaBannerES = import.meta.env.VITE_LINK_VISA_BANNER_ES;
export const VisaBannerEN = import.meta.env.VITE_LINK_VISA_BANNER_EN;

// Sendola Soccer banner
export const SoccerBannerES = import.meta.env.VITE_LINK_SOCCER_BANNER_ES;
export const SoccerBannerEN = import.meta.env.VITE_LINK_SOCCER_BANNER_EN;

// Sendola ATM banner
export const ATMBannerES = import.meta.env.VITE_LINK_ATM_BANNER_ES;
export const ATMBannerEN = import.meta.env.VITE_LINK_ATM_BANNER_EN;

// Sendola Support Visa Request
export const VisaSupportES = import.meta.env.VITE_LINK_VISA_SUPPORT_ES;
export const VisaSupportEN = import.meta.env.VITE_LINK_VISA_SUPPORT_EN;

// Sendola Faqs page
export const FaqsLinkES = import.meta.env.VITE_LINK_FAQS_ES;
export const FaqsLinkEN = import.meta.env.VITE_LINK_FAQS_EN;

// Coppel Access
export const CoppelAccessLinkES = import.meta.env.VITE_LINK_COPPEL_ACCESS_ES;
export const CoppelAccessLinkEN = import.meta.env.VITE_LINK_COPPEL_ACCESS_EN;
export const OpenCoppelAccess = import.meta.env.VITE_LINK_OPEN_COPPEL_ACCESS;

// Sendola Payroll Advance

export const PayrollAdvanceLinkEN = import.meta.env.VITE_LINK_PAYROLL_ADVANCE_EN
export const PayrollAdvanceLinkES = import.meta.env.VITE_LINK_PAYROLL_ADVANCE_ES