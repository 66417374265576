import { ButtonProps } from "@models/buttonInterface";
import Icon from "@components/Icon";
import { ButtonDefault, TextContainer } from "./Button.styled";

const Button = (props: ButtonProps) => {
  const {
    text,
    variant,
    sizeButton,
    sizeIcon,
    iconButton,
    iconButtonLeft,
    iconButtonSrc,
    fillIcon,
    colorIcon,
    children,
  } = props;

  const buttonProps = {
    $type: variant,
    $sizeButton: sizeButton,
    className: "Button",
    ...props,
  };

  return (
    <ButtonDefault ref={props.buttonRef} {...buttonProps}>
      {children}
      {(iconButton || iconButtonLeft || iconButtonSrc || text) && (
        <TextContainer $iconButton={iconButton} $iconButtonLeft={iconButtonLeft}>
          {iconButtonLeft && <Icon icon={iconButtonLeft} color={colorIcon} fillIcon={fillIcon} size={sizeIcon} />}
          <span>{text}</span>
          {iconButton && !iconButtonSrc && (
            <Icon icon={iconButton} color={colorIcon} fillIcon={fillIcon} size={sizeIcon} />
          )}
          {iconButtonSrc}
        </TextContainer>
      )}
    </ButtonDefault>
  );
};

export default Button;
