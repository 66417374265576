import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";
import { SubmitCompleteOnboardingAction } from "./SubmitCompleteOnboarding.actions";
import { SubmitCompleteOnboardingState } from "../../Models/SubmitKYC";
import { SUBMIT_COMPLETE_ONBOARDING } from "../../Constants/KYCStatus";

const initialState: SubmitCompleteOnboardingState = {
  loading: false,
  error: null,
  data: null
};

export const SubmitCompleteOnboardingSlice = createSlice({
  name: SUBMIT_COMPLETE_ONBOARDING,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SubmitCompleteOnboardingAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SubmitCompleteOnboardingAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      .addCase(SubmitCompleteOnboardingAction.rejected, (state, action: any) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload.response;
      });
  },
});

export const submitCompleteOnboardingSelector = (state: State) => state.submitCompleteOnboarding;
