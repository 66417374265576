import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { defaultErrorMessage } from "../../Constants/Requests";
import { AxiosError } from "axios";
import { GET_CELLPHONE } from "../../Constants/Cellphone";

/**
 * Method to get credit cellphone
 */
export const getCellphoneAction = createAsyncThunk(
  GET_CELLPHONE,
  async (payload:string, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(`/credit/person/${payload}`);
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
