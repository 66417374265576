import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { GET_VERIFF_STATUS } from "../../Constants/Veriff";


export const GetIntegrationStatus = createAsyncThunk(
  GET_VERIFF_STATUS,
  async (payload: 'passport' | 'visa', thunkAPI) => { 
    try {
      const response = await BaseConfigApi.get(`/persons/${payload}`);
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(error);
    }
  }
);
