import { useEffect, useRef } from "react";

export const useDebounce = <R = void,>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (...args: any[]) => Promise<R> | void,
  delay: number
) => {
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const debouncedCallback = <RR = R,>(
    ...args: Parameters<typeof callback>
  ): Promise<RR> => {
    return new Promise((resolve, reject) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(async () => {
        try {
          const response = await callback(...args);

          resolve(response as RR);
        } catch (error) {
          reject(error);
        }
      }, delay) as unknown as number;
    });
  };

  return debouncedCallback;
};
