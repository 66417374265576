import {
  ErrorContainer,
  ErrorMessage,
  Label,
  InputCurrencyMoney,
  LabelContainer,
  ContainerInput,
} from "./CurrencyInput.styled";

import { Container, Row, Column } from "../Grid";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

// eslint-disable-next-line
const CurrencyInput = (props: any) => {
  const {
    id,
    name,
    label,
    placeholder,
    value,
    textLabel,
    prefix,
    defaultValue,
    decimalsLimit,
    fixedDecimalLength,
    step = 1,
    onValueChange,
    errors,
    infoTooltipText,
    allowDecimals = true,
    className = undefined,
    customError,
    align,
    smallPadding,
    noBorder,
    errorSize,
    maxLength,
  } = props;

  return (
    <Container as={ContainerInput}>
      <Row>
        <Column span={12}>
          {textLabel && (
            <LabelContainer>
              <Label>{textLabel}</Label>
              {infoTooltipText && <Tooltip colorIcon="neutral_700" sizeIcon="medium" textInfo={infoTooltipText} />}
            </LabelContainer>
          )}
          <InputCurrencyMoney
            id={id}
            name={name}
            placeholder={placeholder}
            prefix={prefix}
            defaultValue={defaultValue}
            decimalsLimit={decimalsLimit}
            fixedDecimalLength={fixedDecimalLength}
            step={step}
            value={value}
            allowNegativeValue={false}
            allowDecimals={allowDecimals}
            intlConfig={{ locale: navigator.language === "es" ? "es-ES" : "en-US", currency: "" }}
            onValueChange={(v: string | undefined) => {
              Number(value) !== Number(v) && onValueChange(v);
            }}
            maxLength={maxLength ? maxLength : 6}
            className={className}
            $error={errors?.[label] || customError}
            $align={align}
            $smallPadding={smallPadding}
            $noBorder={noBorder}
          />
          {errors && errors[name] && (
            <ErrorContainer>
              <Icon icon="alertTriangle" color="error" size="small" fillIcon={false} />
              <ErrorMessage $size={errorSize}>{errors[name].message}</ErrorMessage>
            </ErrorContainer>
          )}
        </Column>
      </Row>
    </Container>
  );
};

export default CurrencyInput;
