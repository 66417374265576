import { Container, Label, Radio } from "./RadioCustom.styled";

export interface RadioProps {
  text?: string;
  name: string;
  value: string;
  forwardRef?: React.Ref<HTMLInputElement>;
  checked?: boolean;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioCustom = (props: RadioProps) => {
  const { text, name, onChange, value, forwardRef, ...rest } = props;
  return (
    <Container>
      <Radio ref={forwardRef} type="radio" name={name} onChange={onChange} value={value} {...rest} />
      <Label htmlFor="radio">
        <span>{text}</span>
      </Label>
    </Container>
  );
};
export default RadioCustom;
