import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_BY_USER_DATA } from "../../Constants/user";
import { defaultErrorMessage } from "../../Constants/Requests";
import { AxiosError } from "axios";

// Own services Api
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";

export const ByUserSliceAction = createAsyncThunk(
  GET_BY_USER_DATA,
  async (payload:string, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(`/persons/byuserid/${payload}`);
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data.message);
      }

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
