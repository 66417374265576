import styled, { css } from "styled-components";
import isEmpty from "lodash.isempty";
import { ifProp, theme } from "styled-tools";

export const ContainerText = styled.div`
  font-family: "Montserrat";
  margin-top: 5px;
  width: 100%;
  color: ${theme("Content_UX_02")};
  display: flex;
  flex-direction: row;
  align-content: center;

  &.hidden {
    display: none;
  }
`;

export const ContainerLeft = styled.div<{ $typeInput?: string }>`
  width: ${(props) => (props.$typeInput === "password" ? "70%" : "100%")};
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding-left: 2px;
`;

export const Span = styled.span<{ $error?: boolean }>`
  margin-left: 4px;
  display: flex;
  flex-direction: row;
  position: relative;
  font-size: 16px;
  color: ${(props) => (props.$error ? theme("Red_08") : theme("Green_05"))};
  width: 100%;
  height: 15px;
  text-align: left;
`;

export const InputGroup = styled.div`
  box-sizing: border-box;
  font-family: "Montserrat";
  position: relative;
  width: 100%;
`;

export const InputStyled = styled.input<{ $error?: boolean }>`
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  border-color: ${(props) => (!isEmpty(props.$error) ? theme("Red_08") : theme("Background_03"))};
  font-size: 12px;
  font-family: "Montserrat";
  font-weight: 70;
  cursor: pointer;
  font-size: 16px;
  padding: 16px;
  margin-top: 8px;
  outline: none;
  width: 100%;

  &:focus,
  &:focus-visible {
    outline: none;
    border: 2px ${(props) => (!isEmpty(props.$error) ? theme("Red_08") : theme("Primary_01"))} solid;

    ~ Label {
      background: ${theme("Background_01")};
      top: 0px;
      left: 8px;
      font-size: 14px;
      color: ${(props) => (!isEmpty(props.$error) ? theme("Red_08") : theme("Primary_01"))};
      width: auto;
    }
  }

  &:disabled {
    ~ Label {
      background: ${theme("Background_01")};
      top: 0px;
      left: 8px;
      font-size: 14px;
      width: auto;
    }
  }

  &:valid[required],
  &:user-valid,
  &:autofill,
  &:-webkit-autofill {
    ~ Label {
      background-color: ${theme("Background _01")};
      top: 0px;
      left: 8px;
      font-size: 14px;
      color: ${theme("Background_04")};
      width: auto;
    }
  }

  &:focus-within {
    ~ Label {
      color: ${(props) => (!isEmpty(props.$error) ? theme("Red_08") : theme("Primary_01"))};
    }
  }
`;

export const Label = styled.label`
  background: ${theme("Background_01")};
  box-sizing: border-box;
  font-family: "Montserrat";
  color: ${theme("Background_04")};
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  padding: 0 8px;
  pointer-events: none;
  left: 8px;
  top: 24px;
  width: 95%;
  text-align: left;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
`;

export const IconInput = styled.div`
  position: absolute;
  right: 24px;
  top: 35%;
`;

export const Placeholder = styled.div<{
  active?: boolean;
  error?: boolean;
}>`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 16px;
  font-weight: 400;
  color: ${ifProp("error", theme("Red_08"), theme("Background_03"))};
  ${ifProp(
    "active",
    css`
      color: ${ifProp("error", theme("Red_08"), theme("Background_03"))};
      top: 0;
      left: 8px;
      font-size: 12px;
      line-height: 18px;
      background-color: ${theme("Background_01")};
      padding-left: 4px;
      padding-right: 4px;
      border-radius: 5px;
      z-index: 1;s
    `
  )}
  transition: top ease-in-out 300ms, font-size ease-in-out 300ms, background-color ease-in-out 400ms;

  &:hover:not(&:focus, &:focus-visible, &:active) {
    color: ${ifProp("error", theme("Error"), theme("Background_03"))};
  }
`;
