import { useTranslation } from "react-i18next";
import { GradientCard } from "../../Card/Card.styled";
import { FlexContainer } from "../../Flex/Flex.styled";
import Text from "../../Text";
import { useEffect } from "react";
import { QuoteChannel } from "@core/Payments/Quote/domain/QuoteSelected";
import { formatNumber } from "../../../Utilities/NumberUtils";
import theme from "@contexts/ThemeProvider";
import Button from "@components/Payments/Button";

interface ModalSwitchProps {
  value?: QuoteChannel;
  lessFeesData: { fx: number; fee: number; currency: string };
  bestFXData: { fx: number; fee: number; currency: string };
  onChange?: (value: QuoteChannel) => void;
}

export const ChannelSwitch = ({ value, lessFeesData, bestFXData, onChange }: ModalSwitchProps) => {
  const [t] = useTranslation("sendMoney");

  useEffect(() => {
    onChange && onChange(value || QuoteChannel.bestFx);
  }, []);

  return (
    <FlexContainer alignItems="center" justify="center" gap="8px">
      <Button
        type="button"
        variant="transparent"
        sizeButton="full"
        padding="0"
        onClick={() => onChange && onChange(QuoteChannel.lessFee)}
      >
        <GradientCard
          active={value === QuoteChannel.lessFee}
          width="100%"
          padding="8px"
          backgroundColor={theme.Gradient_Soft_Gray}
          borderColor={theme.Neutral_300}
          borderRadius="8.2px"
        >
          <FlexContainer justify="center">
            <FlexContainer w="fit-content" direction="column">
              <Text align="left" size={0.5} weight={600} lineHeight="17.5px">
                {t("Models.LessFees.title")}
              </Text>
              <Text align="left" size={0.3} weight={600} lineHeight="17.5px" color="primaryBlue400">
                $1 USD = {formatNumber(lessFeesData.fx, { minimumFractionDigits: 4, maximumFractionDigits: 4 })}{" "}
                {lessFeesData.currency}
              </Text>
              <FlexContainer w="auto" alignItems="center" gap="6px">
                <Text align="left" size={0.2} weight={500} lineHeight="12.5px" transform="lowercase">
                  &bull; ${lessFeesData.fee} {t("TransferFees")}
                </Text>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </GradientCard>
      </Button>
      <Button
        type="button"
        variant="transparent"
        sizeButton="full"
        padding="0"
        onClick={() => onChange && onChange(QuoteChannel.bestFx)}
      >
        <GradientCard
          active={value === QuoteChannel.bestFx}
          width="100%"
          padding="8px"
          backgroundColor={theme.Gradient_Soft_Gray}
          borderColor={theme.Neutral_300}
          borderRadius="8.2px"
        >
          <FlexContainer justify="center">
            <FlexContainer w="fit-content" direction="column">
              <Text align="left" size={0.5} weight={600} lineHeight="17.5px">
                {t("Models.BestFX.title")}
              </Text>
              <Text align="left" size={0.3} weight={600} lineHeight="17.5px" color="magenta">
                $1 USD = {formatNumber(bestFXData.fx, { minimumFractionDigits: 4, maximumFractionDigits: 4 })}{" "}
                {lessFeesData.currency}
              </Text>
              <FlexContainer w="auto" alignItems="center" gap="6px">
                <Text align="left" size={0.2} weight={500} lineHeight="12.5px" transform="lowercase">
                  &bull; ${bestFXData.fee} {t("TransferFees")}
                </Text>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </GradientCard>
      </Button>
    </FlexContainer>
  );
};
