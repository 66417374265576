export const GET_CELLPHONE = 'GetCellphone'
export const GET_CELLPHONE_PENDING = `${GET_CELLPHONE}/pending`;
export const GET_CELLPHONE_REJECTED = `${GET_CELLPHONE}/rejected`;
export const GET_CELLPHONE_FULFILLED = `${GET_CELLPHONE}/fulfilled`;

export const GET_CELLPHONE_DETAIL = 'GetCellphoneDetail'
export const GET_CELLPHONE_DETAIL_PENDING = `${GET_CELLPHONE_DETAIL}/pending`;
export const GET_CELLPHONE_DETAIL_REJECTED = `${GET_CELLPHONE_DETAIL}/rejected`;
export const GET_CELLPHONE_DETAIL_FULFILLED = `${GET_CELLPHONE_DETAIL}/fulfilled`;

export const CELLPHONE_PAID = "Paid";
export const CELLPHONE_NEXT = "Next payment";
export const CELLPHONE_PENDING = "Pending";