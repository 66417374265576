import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Own models
import { AlignItems, JustifyContent } from "../../Models/Column";

// Own utils
import { CopyTextToClipboard } from "../../Utilities/CopyTextToClipboard";
import { formatNumber } from "../../Utilities/NumberUtils";

// Own redux
import { accountPaymentOrdersSelector } from "../../Redux/PaymentOrders/PaymentOrders.slice";
import { updateZelleModal } from "../../Redux/ZelleModal";
import { AppDispatch } from "../../Redux/Store";

// Own components
import { Column, Row } from "../Grid";
import Link from "../Link";
import Text from "../Text";
import ZelleTransactionCard from "../ZelleTransactionCard";
import Icon from "../Icon";

// Own styles
import {
  ArrowBack,
  RowIntructions,
  RowSteps,
  TextCopy,
} from "./ZelleTransaction.styled";

// Own assets
import ZelleLogo from "../../Assets/Img/ZelleLogo.png";
import ZelleStep1 from "../../Assets/Img/zelle-step-1.png";
import ZelleStep2 from "../../Assets/Img/Zelle-step-2.png";
import ZelleStep3 from "../../Assets/Img/Zelle-step-3.png";
import CurrencyDollarExchange from "../../Assets/Img/currency-dollar-exchange.png";
import { DifferenceTime } from "../../Utilities/DifferenceTime";
import { selectorLanguage } from "../../Redux/Translate";
import { WhatsAppLinkEn, WhatsAppLinkEs } from "../../Constants/ConfigureApi";
import { IconTypeKey } from "@models/IconInterface";

export interface CopyToClipboardComponentProps {
  copyText: string;
}

const CopyToClipboardComponent = (props: CopyToClipboardComponentProps) => {
  const [t] = useTranslation("global");
  const { copyText } = props;
  const [iconCopy, setIconCopy] = useState<IconTypeKey>("copy");

  const CopyToClipboard = () => {
    CopyTextToClipboard(copyText);
    setIconCopy("check");
    setTimeout(() => {
      setIconCopy("copy");
    }, 1000);
  };

  return (
    <TextCopy onClick={CopyToClipboard}>
      <Icon icon={iconCopy} color="Primary500" size="small" />
      <Text
        size={0.3}
        color="Primary500"
        align="center"
        weight="600"
        margin="0 4px"
      >
        {t("zelleTransactionsModal.instruction.copy")}
      </Text>
    </TextCopy>
  );
};

const ZelleTransaction = () => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch<AppDispatch>();
  const FloatingBtn = useRef<HTMLDivElement>(null);
  const [showInstructions, setShowInstructions] = useState<boolean>(false);
  const [amountSelected, setAmountSelected] = useState<number>(0);
  const [referenceSelected, setReferenceSelected] = useState<string>("");
  const lang = useSelector(selectorLanguage);
  const WhatsAppLink = lang.language === "en" ? WhatsAppLinkEn : WhatsAppLinkEs;
  const paymentOrders = useSelector(accountPaymentOrdersSelector);
  const transactionsZelle = paymentOrders?.data?.results?.filter(
    (transaction) =>
      transaction.fundType === "Zelle" &&
      transaction.status === "Funds" &&
      DifferenceTime(transaction?.processedAt)
  );

  const selectedData = (amount: number, reference: string) => {
    setAmountSelected(amount);
    setReferenceSelected(reference);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        FloatingBtn.current &&
        !FloatingBtn.current?.contains(event.target as Node)
      ) {
        dispatch(updateZelleModal(false));
        document.body.classList.remove("modal-zelle-transactions-pending");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [FloatingBtn]);

  return (
    <div ref={FloatingBtn}>
      {!showInstructions && (
        <div>
          <Row>
            <Column span={2}>
              <img
                src={CurrencyDollarExchange}
                alt="icon-currency-zelle"
                width={62}
                height={62}
              />
            </Column>
            <Column span={10} mb={1}>
              <Text
                size={2}
                color="Primary01"
                align="left"
                weight="600"
                margin="0 12px"
              >
                {t("zelleTransactionsModal.title")}
              </Text>
              <Text
                size={0.3}
                color="grey"
                align="left"
                weight="500"
                margin="0 12px"
              >
                {t("zelleTransactionsModal.subTitle")}
              </Text>
            </Column>
          </Row>
          {/* Transfers List */}
          <Row>
            {transactionsZelle?.map((transaction) => {
              return (
                <ZelleTransactionCard
                  key={transaction.mtcn}
                  dateAt={transaction.processedAt}
                  userName={transaction.contactName}
                  amount={transaction.amountFound}
                  countrySender={transaction.countrySender}
                  destinationCountry={transaction.destinationCountry}
                  mtcn={transaction.mtcn}
                  selectedData={selectedData}
                  setShowInstructions={setShowInstructions}
                />
              );
            })}
          </Row>
          <Row>
            <Column span={12} justifyContent={JustifyContent.center} mt={2}>
              <Text
                size={1}
                color="black"
                align="left"
                weight="400"
                margin="0 12px"
              >
                {t("zelleTransactionsModal.needHelp")}
                <Link href={WhatsAppLink} weight={600}>
                  {t("zelleTransactionsModal.contact")}
                </Link>
              </Text>
            </Column>
          </Row>
        </div>
      )}

      {showInstructions && (
        <div>
          <Row onClick={() => setShowInstructions(false)}>
            <ArrowBack>
              <Icon icon="arrowLeft" color="black" size="medium" />
            </ArrowBack>
          </Row>
          <Row>
            <Column span={12} justifyContent={JustifyContent.center}>
              <Text
                size="default"
                color="ZelleColor"
                align="center"
                weight="600"
                margin="0"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("zelleTransactionsModal.instruction.title"),
                  }}
                />
              </Text>
              &nbsp;
              <img
                src={ZelleLogo}
                width="57px"
                height="32px"
                style={{ display: "inline", margin: "0 4px" }}
              />
            </Column>
            <Column span={12} justifyContent={JustifyContent.center}>
              <Text
                size={1}
                color="black"
                align="center"
                weight="500"
                margin="0"
              >
                {t("zelleTransactionsModal.instruction.subTitle")}
              </Text>
            </Column>
          </Row>
          <Row as={RowIntructions}>
            <Column span={12}>
              <Row as={RowSteps}>
                <Column span={2}>
                  <img src={ZelleStep1} width="32px" height="32px" />
                </Column>
                <Column span={8} alignItems={AlignItems.center}>
                  <Text
                    size={0.3}
                    color="black"
                    align="left"
                    weight="600"
                    margin="0"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("zelleTransactionsModal.instruction.step1"),
                      }}
                    />
                  </Text>
                </Column>
                <Column span={2} alignItems={AlignItems.center}>
                  <CopyToClipboardComponent copyText="pay@sendola.io" />
                </Column>
              </Row>

              <Row as={RowSteps}>
                <Column span={2}>
                  <img src={ZelleStep2} width="32px" height="32px" />
                </Column>
                <Column span={8} alignItems={AlignItems.center}>
                  <Text
                    size={0.3}
                    color="black"
                    align="left"
                    weight="600"
                    margin="0"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("zelleTransactionsModal.instruction.step2", {
                          totalAmount: formatNumber(amountSelected || 0),
                        }),
                      }}
                    />
                  </Text>
                </Column>
                <Column span={2} alignItems={AlignItems.center}>
                  <CopyToClipboardComponent copyText={`${amountSelected}`} />
                </Column>
              </Row>

              <Row as={RowSteps}>
                <Column span={2}>
                  <img src={ZelleStep3} width="32px" height="32px" />
                </Column>
                <Column span={8} alignItems={AlignItems.center}>
                  <Text
                    size={0.3}
                    color="black"
                    align="left"
                    weight="600"
                    margin="0"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("zelleTransactionsModal.instruction.step3", {
                          reference: referenceSelected,
                        }),
                      }}
                    />
                  </Text>
                </Column>
                <Column span={2} alignItems={AlignItems.center}>
                  <CopyToClipboardComponent copyText={referenceSelected} />
                </Column>
              </Row>
            </Column>
          </Row>
          <Row>
            <Column span={12} justifyContent={JustifyContent.center} mt={2}>
              <Text
                size={1}
                color="black"
                align="left"
                weight="400"
                margin="0 12px"
              >
                {t("zelleTransactionsModal.needHelp")}
                <Link href={WhatsAppLink} weight={600}>
                  {t("zelleTransactionsModal.contact")}
                </Link>
              </Text>
            </Column>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ZelleTransaction;
