
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useNavigate } from "react-router-dom";

// own states
import { AppDispatch, State } from "../../Redux/Store";
import { BankTransfersAction } from "../../Redux/FundsAccount/FundsAccount.actions";

// Own components
import Layout from "../../Components/Layout";
import { Column, Container, Row } from "../../Components/Grid";
import LoaderIcon from "../../Components/LoaderIcon/LoaderIcon.component";
import { BankTransfersCard } from "./Components";

// Own Models
import { BankTransfersAccountContainer, BankTransfersDetailsCard, CustomText, CustomTitle } from "./BankTransfers.styled";
import { BankTransfer } from "../../Models/Funds";

// Own constants
import EmptyBankTransferSrc from "../../Assets/Img/cash-up.svg";

const BankTranfersPage = () => {
  const [isLoadingLayout, setIsLoadingLayout] = useState(true);
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const bankTransfersStore = useSelector((state: State) => state.FundsAccount?.bankTransfers);
  const personId = useSelector((state: State) => state.ByUser.data)?.personId;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    getTransfers();
  }, []);

  const getTransfers = async () => {
    await dispatch(BankTransfersAction({personId: personId || ''}));

    setIsLoadingLayout(false);
  }

  const viewBankTransferDetails = (transaction: BankTransfer, fromItem?: boolean) => {
    navigate("/bank-transfers-details", {
      state: { 
        transferOrderId: transaction.id,
        transferStatus: transaction.status,
        transferNumber: transaction.transferNumber,
        ...fromItem ? {backPath: "/bank-transfers"} : {}
      },
      replace: true
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout show={isLoadingLayout}>
        <LoaderIcon
          title=""
          text=""
          description={t("loaderText.description")}
        />
      </Layout>
      <Layout
        path="/manage-card-account"
        textLink={t("buttons.back")}
        show={!isLoadingLayout}
      >
        <Container as={BankTransfersAccountContainer}>
          <Row>
            <Column span={12} pb={4} mt={4}>
              <CustomTitle
                tagName="h2"
                text={t("Funds.BankTransfer.transfers.title")}
                color="black"
                size={4}
                align="left"
              />
            </Column>
            {!bankTransfersStore?.length ? (
              <Column span={12} px={2} py={6}>
                <img src={EmptyBankTransferSrc} alt="Bank Transfer empty" />
                <CustomText
                  color="grey"
                  size={0.5}
                  align="center"
                  weight={600}
                  margin="12px 0"
                >
                  {t("Funds.BankTransfer.transfers.empty")}
                </CustomText>
                <CustomText
                  color="grey"
                  size={0.5}
                  align="center"
                  weight={600}
                  margin="0"
                >
                  {t("Funds.BankTransfer.transfers.emptySecond")}
                </CustomText>
              </Column>
            ) : (
              <Column span={12} p={2} as={BankTransfersDetailsCard}>
                {
                  bankTransfersStore.map(transfer => (
                    <BankTransfersCard
                      key={transfer.id}
                      amount={transfer.amount}
                      order={`${t("Funds.BankTransfer.textOrder")} #${transfer.transferNumber}`}
                      status={transfer.status}
                      action={() => viewBankTransferDetails(transfer, true)}
                      isListItem
                    />
                  ))
                }
              </Column>
            )}
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default BankTranfersPage;
