import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { defaultErrorMessage } from "../../Constants/Requests";
import { AxiosError } from "axios";
import { GET_CELLPHONE_DETAIL } from "../../Constants/Cellphone";

/**
 * Method to get credit detail cellphone
 */
export const getCellphoneDetailsAction = createAsyncThunk(
  GET_CELLPHONE_DETAIL,
  async (payload:string, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(`/credit/${payload}`);
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
