import { defaultErrorMessage } from "../../Constants/Requests";
import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { pdfUpdatePayload } from "../../Models/DirectDeposit";
import { PDF_SHIPPING_UPDATE } from "../../Constants/Payroll";

export const PdfShippingUpdate = createAsyncThunk(
  PDF_SHIPPING_UPDATE,
  async (payload: pdfUpdatePayload, thunkAPI) => {
    try {
      const response = await BaseConfigApi.patch(`/payroll/person/${payload.personId}/customer/${payload.customerId}`, {"isSent": payload.isSent});
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
