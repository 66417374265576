import { RadioProps } from "../../../../Models/checkboxInterface";
import { Container, Label, Radio } from "./AccountRadio.styles";

const AccountRadio = (props: RadioProps) => {
  const { text, name, onChangeEvent, id, forwardRef, ...rest } = props;
  return (
    <Container>
      <Radio
        ref={forwardRef}
        type="radio"
        name={name}
        onChange={onChangeEvent}
        value={id}
        {...rest}
      />
      <Label htmlFor="radio">
        <span>{text}</span>
      </Label>
    </Container>
  );
};
export default AccountRadio;