import styled from "styled-components";
import mediaQuery, { Screen } from "../../../Utilities/MediaQuery";

export const StepsContainer = styled.div`
  ${mediaQuery[Screen.desktop]} {
    padding-inline: 300px;
  }
`;

export const AccountSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  margin-top: 20px;

  p {
    text-align: left;
    margin: 6px;
  }

  ${mediaQuery[Screen.mobile]} {
    h1 {
      font-size: 23px;
    }
  }
`;

export const ModalContent = styled.div`
  padding: 16px;

  p {
    width: 100%;
  }

  button {
    margin-block: 16px;
    margin-inline: 0;
  }
`;
