import { HeadModal, ModalWrap, OverLay } from "./Modal.styled";
import { ModalProps } from "../../Models/ModalInterface";
import IconFont from "../IconFont";
import Button from "@components/Payments/Button";
import { useEffect } from "react";

const Modal = (props: ModalProps) => {
  const { id, show, children, handleClose, hiddenCloseIcon = true, as } = props;

  useEffect(() => {
    const scrollY = window.scrollY;

    if (show) {
      document.documentElement.style.overflow = "hidden";
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = "100%";
    } else {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
    }

    return () => {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
    };
  }, [show]);

  return (
    <OverLay
      id={id}
      hidden={!show}
      zIndex={props.zIndex}
      onClick={(evt) => {
        evt.stopPropagation();
        if (!hiddenCloseIcon) {
          handleClose && handleClose();
        }
      }}
    >
      <ModalWrap onClick={(event: React.MouseEvent) => event.stopPropagation()} as={as || ModalWrap}>
        {!hiddenCloseIcon && (
          <HeadModal>
            <Button variant="transparent" sizeButton="fit" padding="0px" onClick={() => handleClose && handleClose()}>
              <IconFont name="cancel" color="black" size="medium" />
            </Button>
          </HeadModal>
        )}
        {children}
      </ModalWrap>
    </OverLay>
  );
};

export default Modal;
