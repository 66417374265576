import { useState, useMemo, ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// Own utils
import { maskSsn } from "../../../Utilities/String";

// Own styles
import { StepsContainer } from "../../KYC/LegalName/LegalName.styled";

// Own constants
import { allowedSsn } from "../../../Constants/Regexp";
import { UPDATE_USER_SSN_FULFILLED, UPDATE_USER_SSN_REJECTED } from "../../../Constants/user";
import { IDV_SSN_FULFILLED, IDV_SSN_REJECTED } from "../../../Constants/IDV";
import { DOCUMENT_CONFLICT } from "../../../Constants/SsnError";

// Own models
import { SsnForm } from "../../../Models/SocialSecurityNumber";

// Own states
import { AppDispatch, State } from "../../../Redux/Store";
import { ByUserSliceSelector } from "../../../Redux/ByUser/ByUser.slice";
import { UpdateSsnSliceAction } from "../../../Redux/SocialSecurityNumber/SocialSecurityNumber.actions";
import { PersonalDataSliceSelector } from "../../../Redux/PersonalData/PersonalData.slice";
import { IdvSSNAction } from "../../../Redux/IdvSSN/IdvSSN.actions";

// Own components
import { Row, Column, FillerContainer, BottomFixedContainer } from "../../../Components/Grid";
import Title from "../../../Components/Title";
import Layout from "../../../Components/Layout";
import Input from "../../../Components/Input";
import Text from "../../../Components/Text";
import Button from "../../../Components/Button";
import LoaderIcon from "../../../Components/LoaderIcon/LoaderIcon.component";

const SSNPage = () => {
  const [t] = useTranslation("global")
  const navigate = useNavigate();
  const theme = useSelector((state: State) => state.theme);
  const ByUser = useSelector(ByUserSliceSelector);
  const PersonalData = useSelector(PersonalDataSliceSelector);
  const dispatch = useDispatch<AppDispatch>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: {
      errors,
      isValid,
    }
  } = useForm<SsnForm>({
    mode: 'onChange'
  });

  const personId = useMemo(() =>
    PersonalData.data?.personId || ByUser.data?.personId,
  [PersonalData, ByUser]);

  const UpdateIdvSSN = async () => {
    const response = await dispatch(IdvSSNAction());

    if (response?.type === IDV_SSN_FULFILLED) {
      setIsLoading(false);
      navigate("/");
    }

    if (response?.type === IDV_SSN_REJECTED) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  const onSubmit = async (data: SsnForm) => {
    setIsError(false);
    setIsLoading(true);

    const request = {
      personId: personId || "",
      ssn: !data?.ssn ? null : data.ssn.replace(/-/g, "")
    }

    const response = await dispatch(UpdateSsnSliceAction(request));

    if (response?.type === UPDATE_USER_SSN_FULFILLED) {
      await UpdateIdvSSN();
    }

    if (response?.type === UPDATE_USER_SSN_REJECTED) {
      if (response.payload === DOCUMENT_CONFLICT) {
        setErrorMsg(t("409.errorSSN"));
      } else {
        setErrorMsg(t("404.subtitle"));
      }

      setIsLoading(false);
    }
  }

  const handleBackClick = () => navigate("/")

  const handleSSNChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputSSN = event.target.value.replace(/\D/g, '');
    const cursorPosition = event.target.selectionStart;

    setErrorMsg("");

    const [formattedSSN, newCursorPosition] = maskSsn(inputSSN, cursorPosition);

    setValue("ssn", formattedSSN);

    if (newCursorPosition && event?.target?.setSelectionRange)
      event.target.setSelectionRange(newCursorPosition, newCursorPosition);
  };

  useEffect(() => {
    document.body.classList.remove('modal');
  },[]);

  return (
    <ThemeProvider theme={theme}>
      <Layout show={isLoading}>
        <LoaderIcon
          title={t("loaderText.title")}
          text={t("loaderText.subtitle")}
          description={t("loaderText.description")}
        />
      </Layout>
      <Layout show={!isLoading}>
        <FillerContainer as={StepsContainer}>
          <Row>
            <Column span={12}>
              <Row>
                <Column span={12} mt={2} mb={1}>
                  <Title
                    tagName="h1"
                    color="black"
                    align="left"
                    size={3}
                    text={t("personalData.ssn.completeProfile.title")}
                  />
                  <Text
                    tagName="p"
                    color="grey"
                    align="left"
                    size={0.5}
                    margin="24px 0"
                  >
                    {t("personalData.ssn.completeProfile.paragraph")}
                  </Text>
                </Column>

                <Column span={12} my={1}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Input
                      label="ssn"
                      typeInput="text"
                      textLabel={t("personalData.ssn.placeholders.ssn")}
                      maxLengthInput={11}
                      register={register}
                      onChange={handleSSNChange}
                      errors={errors}
                      rules={{
                        required: t("Forms.required"),
                        pattern: {
                          value: allowedSsn,
                          message: t("Forms.inputSsn"),
                        }
                      }}
                      customError = {errorMsg}
                    />

                    {isError && (
                      <Column span={12} mb={2}>
                        <Text size={0.5} align="center" weight={600} color="error">
                          {t("404.subtitle")}
                        </Text>
                      </Column>
                    )}

                    <BottomFixedContainer>
                      <Button
                        variant={"secondary"}
                        text={t("buttons.cancel")}
                        sizeText="medium"
                        sizeButton="medium"
                        onClick={handleBackClick}
                      />

                      <Button
                        variant={!isValid || isLoading ? undefined : "primary"}
                        text={t("buttons.save")}
                        sizeText="medium"
                        sizeButton="medium"
                        loading={isLoading ? 1 : 0}
                        disabled={!isValid}
                      />
                    </BottomFixedContainer>
                  </form>
                </Column>
              </Row>
            </Column>
          </Row>
        </FillerContainer>
      </Layout>
    </ThemeProvider>
  )
}

export default SSNPage;