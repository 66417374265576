import { MonthsEn, MonthsEs } from "../Constants/Date";

export type MonthsByLang = { en: string[]; es: string[] };

export const randomString = (length = 10) =>
  Array(length)
    .fill(0)
    .map(() => Math.random().toString(36).charAt(2))
    .join("");

export function maskUsernameInEmail(email: string): string {
  const parts = email.split("@");
  const username = parts[0];
  const domain = parts[1];
  const hiddenUsername = "*".repeat(username.length);
  const hiddenEmail = hiddenUsername + "@" + domain;
  return hiddenEmail;
}

export function maskPhoneNumber(phoneNumber: string): string {
  const countryCode = phoneNumber.substring(0, 3);
  const areaCode = phoneNumber.substring(3, 5);
  const hiddenDigits = "*".repeat(phoneNumber.length - 5);
  const maskedPhoneNumber = `${countryCode}${areaCode}${hiddenDigits}`;
  return maskedPhoneNumber;
}

export function maskInterbankKey(number: string): string {
  if (number) {
    const lastDigits = number.slice(-4);
    const asterisks = "*".repeat(lastDigits.length);

    return asterisks + lastDigits;
  }
  return "";
}

export function maskSsn(ssn: string, cursorPosition: number | null): [string, number | null] {
  let formattedSSN = "";

  for (let i = 0; i < ssn.length; i++) {
    if (i === 3 || i === 5) {
      formattedSSN += "-";
      if (cursorPosition) cursorPosition++;
    }
    formattedSSN += ssn[i];
  }

  let hyphensAdded: number = 0;
  if (formattedSSN.length > 4) {
    hyphensAdded = 1;
  }
  if (formattedSSN.length > 7) {
    hyphensAdded = 2;
  }

  if (cursorPosition && formattedSSN.length > cursorPosition) {
    return [formattedSSN, cursorPosition - hyphensAdded];
  }

  return [formattedSSN, null];
}

export function parseDate(date: string): string {
  const parts = date.split("/");

  if (parts.length !== 3) {
    throw new Error("Incorrect date format. It should be dd-mm-yyyy");
  }

  const month = parts[0];
  const day = parts[1];
  const year = parts[2];

  if (isNaN(parseInt(day)) || isNaN(parseInt(month)) || isNaN(parseInt(year))) {
    throw new Error("Incorrect date format. Components should be numbers");
  }

  const convertedDate = `${year}-${month}-${day}`;
  return convertedDate;
}

export function formatDate(inputDate: string, lang: string): string {
  const months: MonthsByLang = {
    en: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    es: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
  };

  const date = new Date(inputDate);

  const day = date.getDate();
  const month = months[lang as keyof MonthsByLang][date.getMonth()];
  const year = date.getFullYear().toString().slice(-2);
  const hour = date.getHours();
  const minute = date.getMinutes();

  return ` ${month}/${day}/${year} ${hour}:${minute.toString().padStart(2, "0")}`;
}

export function formatCamelCaseString(camelCaseString: string) {
  const words = camelCaseString.split(/(?=[A-Z])/);
  const formattedWord = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");

  return formattedWord;
}

export function formatDateTransaction(inputDate: string, lang: string): string {
  const months: MonthsByLang = {
    en: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "Octuber",
      "November",
      "December",
    ],
    es: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
  };

  const date = new Date(inputDate);

  const day = date.getDate();
  const month = months[lang as keyof MonthsByLang][date.getMonth()];
  const year = date.getFullYear().toString();

  return `${month} ${day}, ${year}`;
}

export function CustomFormatDateTime(date: string) {
  if (date.length > 0) {
    const customDate = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return `
    ${customDate.toLocaleString("en-US", { month: "long" })}
    ${customDate.getDate()},
    ${customDate.getFullYear()}
    ${customDate.toLocaleString("en-US", options).toLowerCase()}
    `;
  }
  return "";
}

export function CustomFormatDateTimeUSI(date: string, lang: string) {
  if (date.length > 0) {
    const customDate = new Date(date);
    const langSet = lang === "es" ? "es-MX" : "en-US";
    const month = customDate.toLocaleString(langSet, { month: "long" });

    return `
    ${month[0].toUpperCase() + month.substring(1)}
    ${customDate.getDate()},
    ${customDate.getFullYear()}
    `;
  }
  return "";
}

export function FormatDateByMonthAndYeayUSI(date: string, lang: string) {
  const completDate = `${date}-15`;
  const langSet = lang === "es" ? "es-MX" : "en-US";

  if (completDate.length > 0) {
    const customDate = new Date(completDate);
    const month = customDate.toLocaleString(langSet, { month: "long" });

    return `
    ${month[0].toUpperCase() + month.substring(1)}
    ${customDate.getFullYear()}
    `;
  }
  return "";
}

export function parseDateFromAPI(date: string): string {
  const parts = date.split("-");

  if (parts.length !== 3) {
    throw new Error("Incorrect date format. It should be dd-mm-yyyy");
  }

  const year = parts[0];
  const month = parts[1];
  const day = parts[2];

  if (isNaN(parseInt(day)) || isNaN(parseInt(month)) || isNaN(parseInt(year))) {
    throw new Error("Incorrect date format. Components should be numbers");
  }

  const convertedDate = `${month}/${day}/${year}`;
  return convertedDate;
}

export function formatDatePayroll(date: Date, lang: string) {
  const day = date.getDate();
  const month = lang === "es" ? MonthsEs[date.getMonth()].label : MonthsEn[date.getMonth()].label;
  const year = date.getFullYear();

  if (lang === "es") {
    return `${day} de ${month} de ${year}`;
  } else {
    const suffix = day === 1 ? "st" : day === 2 ? "nd" : day === 3 ? "rd" : "th";
    return `${month} ${day}${suffix}, ${year}`;
  }
}

export function chageToNumber(number: string | number) {
  let numberOutput = number;

  if (typeof number === "string") {
    numberOutput = parseInt(number);
  }

  return numberOutput;
}

export function MaskSimpleSSN(ssn: string | number) {
  const ssnString = ssn.toString();
  const MaskSsn = [];

  for (let i = 0; i < ssnString.length; i++) {
    if (i === 3 || i === 5) {
      MaskSsn.push("-");
    }

    MaskSsn.push(ssnString[i]);
  }

  return MaskSsn.join("").toString();
}

export function removeAccents(str: string) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function getSlicedString(str: string, numChars: number): string {
  return `${str.substring(0, numChars)}${str.length > numChars ? "..." : ""}`;
}

export function CustomFormatDate(inputDate: string, lang: string): string {
  const months: {
    [x: string]: string[];
  } = {
    en: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    es: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
  };

  const formattedDate = new Date(parseInt(inputDate.split("-")[1]), parseInt(inputDate.split("-")[0]) - 1, 1);

  const date = new Date(formattedDate);
  const month = months[lang][date.getMonth()];
  const year = date.getFullYear().toString();

  return `${month} ${year}`;
}
