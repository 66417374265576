import { useTranslation } from "react-i18next";

// Own Components
import Modal from "../../../../Components/Modal";
import Text from "../../../../Components/Text";
import Button from "../../../../Components/Button";

// Own Modals
import { ModalProps } from "../../../../Models/ModalInterface";
import Icon from "../../../../Components/Icon";

type Props = {
  title: string;
  message: string;
  handleConfirm?: () => void;
  handleClose?: () => void;
}

export const UnlinkAccountSuccessModal = (props: ModalProps & Props) => {
  const [t] = useTranslation("global");

  return (
    <Modal {...props}>
      <Icon icon="checkCirclePurple" size="xlarge" />
      <Text size={3} weight={700}>
        {props.title || ""}
      </Text>
      {props.children}
      <Button
        variant={"primary"}
        sizeButton="large"
        sizeText="large"
        text={t("Funds.LinkedAccounts.modalUnlink.btnClose")}
        colorIcon="white"
        sizeIcon="large"
        onClick={props.handleConfirm || props.handleClose}
      />
    </Modal>
  );
};
