import { useNavigate } from "react-router-dom";

// Own models
import { AlignItems, JustifyContent } from "../../Models/Column";
import { PendingRequestBannerProps } from "../../Models/PedingRequestBanner";

// Own components
import Text from "../Text";
import { Column, Container, Row } from "../Grid";

// Own styled
import {
  BannerContainer,
  RowBottomFixed,
  containerStyled,
} from "./PendingRequestBanner.styled";

// Own assets
import Warning from "../../Assets/Img/circle-x-warning.svg";

const PendingRequestBanner = ({
  title,
  message,
  secondMessage,
  showButton,
  textButton,
  linkButton,
  actionButton,
  heightAuto,
  imageIcon,
}: PendingRequestBannerProps) => {
  const navigate = useNavigate();
  const link = linkButton ? linkButton : "/";

  return (
    <BannerContainer $heightAuto={heightAuto ? true : false}>
      <Container as={containerStyled}>
        <Row>
          <Column
            span={2}
            justifyContent={JustifyContent.flexStart}
            alignItems={AlignItems.flexStart}
          >
            <img
              src={imageIcon ? imageIcon : Warning}
              alt="Warning"
              width="100%"
            />
          </Column>
          <Column
            span={10}
            alignItems={AlignItems.center}
            justifyContent={JustifyContent.flexStart}
          >
            <Row>
              <Column span={12}>
                <Text
                  size={0.5}
                  color="black_highlight"
                  align="left"
                  weight={700}
                  margin={0}
                >
                  {title}
                </Text>
              </Column>
              <Column span={12}>
                <Text
                  size={0.3}
                  color="black_highlight"
                  align="left"
                  weight={400}
                >
                  <span dangerouslySetInnerHTML={{ __html: message }} />
                </Text>
                {secondMessage && (
                  <Text
                    size={0.3}
                    color="black_highlight"
                    align="left"
                    weight={400}
                  >
                    <span dangerouslySetInnerHTML={{ __html: secondMessage }} />
                  </Text>
                )}
              </Column>
            </Row>
            {showButton && (linkButton || actionButton) && (
              <Row as={RowBottomFixed}>
                <Column span={12} justifyContent={JustifyContent.flexEnd}>
                  <button
                    onClick={() =>
                      actionButton ? actionButton() : navigate(link)
                    }
                  >
                    {textButton}
                  </button>
                </Column>
              </Row>
            )}
          </Column>
        </Row>
      </Container>
    </BannerContainer>
  );
};

export default PendingRequestBanner;
