import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { GET_PREFILLED_DATA } from "../../Constants/user";
import { defaultErrorMessage } from "../../Constants/Requests";
import { AxiosError } from "axios";

export const PrefilledDataAction = createAsyncThunk(
  GET_PREFILLED_DATA,
  async (_, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(
        `/persons/by-user/pre-filled-info`
      );
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
