import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { MESSAGES, createVeriffFrame } from "@veriff/incontext-sdk";
import Carousel from "../../Components/Carousel";
import Cookies from "universal-cookie";

// Own states of redux
import { AppDispatch, State } from "../../Redux/Store";
import { PrefilledDataSelector } from "../../Redux/PrefilledData/PrefilledData.slice";
import { ByUserSliceSelector } from "../../Redux/ByUser/ByUser.slice";
import { getKycStatusSelector } from "../../Redux/GetKYCStatus/GetKYCStatus.slice";
import { getAllAccounts } from "../../Redux/Accounts/Accounts.actions";
import { GetDirectDeposit } from "../../Redux/CheckDirectDeposit/CheckDirectDeposit.actions";
import { selectorLanguage } from "../../Redux/Translate";
import { getUserFeature } from "../../Redux/UserFeatures/UserFeatures.actions";
import { UploadCashIn } from "../../Redux/CashIn/CashIn.actions";
import { selectCreditCellphoneId } from "../../Redux/Cellphone/Cellphone.slice";
import { getCellphoneAction } from "../../Redux/Cellphone/Cellphone.actions";
import { accountPaymentOrdersSelector } from "../../Redux/PaymentOrders/PaymentOrders.slice";
import { GetInvitationCodeAction } from "../../Redux/InvitationCode/InvitationCode.actions";
import { ZelleModalSelector } from "../../Redux/ZelleModal";
import { VenmoModalSelector } from "../../Redux/VenmoModal";

// Own Models
import { AccountObject } from "../../Models/Account";
import { Initial_Results, KYC_Data_Results } from "../../Models/KYC";
import { AlignItems } from "../../Models/Column";
import { CellphoneData } from "../../Models/Cellphone";

// Own utils
import { filterObject } from "../../Utilities/FilterObjects";
import { formatDate } from "../../Utilities/FormatDate";
import { DifferenceTime } from "../../Utilities/DifferenceTime";

// Own hooks
import { useTimer } from "../../Hooks/useTimer";

// Own constants
import {
  CashInLink,
  CashInLinkEn,
  PayrollAdvanceLinkEN,
  PayrollAdvanceLinkES,
  RepatriationExternalServices,
  RepatriationExternalServicesEn,
  SoccerBannerEN,
  SoccerBannerES,
} from "../../Constants/LinksExternals";
import {
  KYC_APPROVED,
  KYC_DECLINED,
  KYC_IN_REVIEW,
  KYC_RESUBMISSION,
  KYC_RESUBMISSION_REQUESTED,
  KYC_SUBMITTED,
} from "../../Constants/KYCStatus";
import { GET_ALL_ACCOUNTS_FULFILLED } from "../../Constants/Account";
import { GET_DIRECT_DEPOSIT_FULFILLED } from "../../Constants/Payroll";
import { GET_USER_FEATURE_FULFILLED } from "../../Constants/user";
import {
  BUY_PHONE_BANNER,
  CASH_IN_BANNER,
  REPATRIATION_BANNER,
  DIRECT_DEPOSIT_REQUEST_FEATURE,
  PROMOTION_SOCCER_BANNER,
  PAYROLL_ADVANCE_BANNER,
  REWARDS_FEATURE,
  REWARDS_POPUP_FEATURE,
} from "../../Constants/Features";
import { BLDSpecialityContractor } from "../../Constants/Customers";
import { WhatsAppLinkEn, WhatsAppLinkEs } from "../../Constants/ConfigureApi";
import { GET_CELLPHONE_FULFILLED } from "../../Constants/Cellphone";
import { EN } from "../../Constants/Langs";
import { REFERRAL_COOKIE_NAME } from "../../Constants/Referral";
import { INVITE_CODE_FULFILLED } from "../../Constants/InviteCode";
import { DOMAIN } from "../../Constants/Auth";

// Own components
import { Container, Row, Column } from "../../Components/Grid";
import Layout from "../../Components/Layout";
import Text from "../../Components/Text";
import WarningMessage from "../../Components/WarningMessage";
import LoaderIcon from "../../Components/LoaderIcon/LoaderIcon.component";
import ZelleBanner from "../../Components/ZelleBanner";
import PayrollSetting from "../../Components/PayrollSetting";
import BannerServicesCard from "../../Components/BannerServicesCard";
import NotificationModal from "../../Components/NotificationModal";
import Icon from "../../Components/Icon";
import GlobalPaymentsQuoter from "../../Components/GlobalPaymentsQuoter";
import PendingRequestBanner from "../../Components/PendingRequestBanner";
import ReferralModal from "../../Components/ReferralModal";
import ReferralBanner from "../../Components/ReferralBanner";

// Own styles
import { HomeContainer, RowMargin, TextNotification, TextNotificationPaid } from "./HomePage.styles";

// Own assets
import Contigo from "../../Assets/Img/contigo.png";
import Repatriation from "../../Assets/Img/repatriation.png";
import Micel from "../../Assets/Img/micel.png";
import MyPhone from "../../Assets/Img/myphone.png";
import Adelanto from "../../Assets/Img/adelanto.png";
import AdvancePay from "../../Assets/Img/advancePay.png";
import Beneficios from "../../Assets/Img/beneficios.png";
import Benefits from "../../Assets/Img/benefits.png";
import PuertoRicoAlertImage from "../../Assets/Img/puertoRicoAlert.png";
import VerifyIdentityImage from "../../Assets/Img/identity-verify.png";
import ProofOfLifeImage from "../../Assets/Img/proof-of-life.png";
import { AlertBox } from "../../Components/AlertBox/AlertBox.component";
import { GetIdvIsRequiredAction } from "@redux/IDVRequired/IDVRequired.actions";
import { IDV_IS_REQUIRED_FULFILLED } from "@constants/IDV";
import { useModal } from "@hooks/Payments/useModal";
import { LessFeesBestFXBanner } from "@components/LessFeesBestFXBanner/LessFeesBestFXBanner.component";
import { CarouselPaginated } from "@components/Carousel/CarouselPaginated.component";
import { PersonsKYCSelector } from "@redux/Payments/PersonsKYC";

const HomePage = () => {
  const cookies = new Cookies();
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const proofOfLifeParam = searchParams.get("proofOfLife");
  const benefitsRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAccountsLoading, setAccountsIsLoading] = useState<boolean>(true);
  const [accounts, setAccount] = useState<AccountObject[]>();
  const [openPueroRicoAlert, setOpenPueroRicoAlert] = useState<boolean>(false);
  const [, setShowDirectDeposit] = useState<boolean>(false); // It is left like this for the moment since the Share direct deposit functionality is being hidden showDirectDeposit
  const [showReferralPopup, setShowReferralPopup] = useState<boolean>(false);
  {
    /* Temporary disabled */
  }
  // const [showReferralWelcomeModal, setShowReferralWelcomeModal] =
  //   useState<boolean>(false);
  {
    /* Temporary disabled */
  }
  // const [hasPinwheelAccount, setHasPinwheelAccount] = useState<boolean>(false);
  const [resubmissionStatus, setResubmissionStatus] = useState<boolean>(false);
  const [urlResubmissionstatus, setUrlResubmissionstatus] = useState<string>("");

  /** This cases are features **/
  const [CashInFeature, setCashInFeature] = useState<boolean>(false);
  const [BuyPhoneFeature, setBuyPhoneFeature] = useState<boolean>(false);
  const [RepatriationFeature, setRepatriationFeature] = useState<boolean>(false);
  const [DirectDepositFeature, setDirectDepositFeature] = useState<boolean>(true);
  const [isOpenDirectDepositModal, setIsOpenDirectDepositModal] = useState<boolean>(false);
  const [RewardsFeature, setRewardsFeature] = useState<boolean>(false);
  const [RewardsPopupFeature, setRewardsPopupFeature] = useState<boolean>(false);
  {
    /* Temporary disabled */
  }
  // const [PayrollFeature, setPayrollFeature] = useState<boolean>(false);
  const [PromotionSoccerFeature, setPromotionSoccerFeature] = useState<boolean>(false);
  const [PayrollAdvanceFeature, setPayrollAdvanceFeature] = useState<boolean>(false);
  const [showCellphone, setShowCellphone] = useState(true);
  const [dataCellphone, setDataCellphone] = useState<CellphoneData | null>();
  const [pathIdvIsRequired, setPathIdvIsRequired] = useState<string>("");
  const [proofOfLife, setProofOfLife] = useState<boolean>(proofOfLifeParam ? true : false);
  const [invitationCode, setInvitationCode] = useState<string>("");

  const ByUser = useSelector(ByUserSliceSelector);
  const personPayments = useSelector(PersonsKYCSelector);
  const PrefilledData = useSelector(PrefilledDataSelector);
  const kycStatusSelector = useSelector(getKycStatusSelector);
  const paymentOrders = useSelector(accountPaymentOrdersSelector);
  const showZelleModalSelector = useSelector(ZelleModalSelector);
  const showVenmoModalSelector = useSelector(VenmoModalSelector);
  const transactionsZelle =
    paymentOrders?.data?.results?.filter(
      (transaction) =>
        transaction.fundType === "Zelle" && transaction.status === "Funds" && DifferenceTime(transaction?.processedAt)
    ) || [];
  const transactionsVenmo =
    paymentOrders?.data?.results?.filter(
      (transaction) =>
        transaction.fundType === "Venmo" && transaction.status === "Funds" && DifferenceTime(transaction?.processedAt)
    ) || [];
  const { minutes, seconds } = useTimer({
    target: new Date(transactionsZelle[0]?.processedAt),
  });

  const { minutes: venmoMinutes, seconds: venmoSeconds } = useTimer({
    target: new Date(transactionsVenmo[0]?.processedAt),
  });

  const notificationsItemsTotal: number = 1;

  const lang = useSelector(selectorLanguage);
  const urlRepatriation = lang.language === EN ? RepatriationExternalServicesEn : RepatriationExternalServices;
  const urlCashIn = lang.language === EN ? CashInLinkEn : CashInLink;
  const urlSoccerBanner = lang.language === EN ? SoccerBannerEN : SoccerBannerES;
  const urlWhatsAppSupport = lang.language === EN ? WhatsAppLinkEn : WhatsAppLinkEs;
  const urlPayrollAdvance = lang.language === EN ? PayrollAdvanceLinkEN : PayrollAdvanceLinkES;
  const locLang = lang.language === "en" ? "en-US" : "es-ES";

  const kycStatus = useMemo(() => kycStatusSelector.data?.status || "", [kycStatusSelector]);

  const kycLevel = useMemo(() => kycStatusSelector.data?.level || 0, [kycStatusSelector]);

  const pepStatus = useMemo(() => kycStatusSelector.data?.results?.pep?.status || "", [kycStatusSelector]);

  const kycResults = useMemo(() => kycStatusSelector.data?.results || Initial_Results, [kycStatusSelector]);

  const personId = useMemo(() => ByUser.data?.personId, [ByUser]);

  const userId = useMemo(() => ByUser.data?.userId || "", [ByUser]);

  const nationality = useMemo(() => PrefilledData.data?.nationality || "MEX", [PrefilledData]);

  const subdivision = useMemo(() => PrefilledData.data?.address?.countrySubdivision || "", [PrefilledData]);

  const customerId = useMemo(() => ByUser.data?.customerId, [ByUser]);

  const isFirstTransaction = useMemo(() => {
    return personPayments?.data?.personVeriff?.applyFeePromotion;
  }, [personPayments]);

  const showZelleModal = useMemo(() => showZelleModalSelector?.show, [showZelleModalSelector]);

  const showVenmoModal = useMemo(() => showVenmoModalSelector?.show, [showVenmoModalSelector]);

  const { modal: kycStarterModal, showModal: showKycStarterModal } = useModal({ modalType: "kycStarterModal" });

  const handleKYCStartVeriff = () => {
    showKycStarterModal({
      handleConfirm: () => {
        navigate(pathIdvIsRequired);
      },
    });
  };

  const handleOpenIframeVeriff = () => {
    createVeriffFrame({
      url: urlResubmissionstatus,
      onEvent: function (msg: string) {
        switch (msg) {
          case MESSAGES.FINISHED:
            window.location.href = "/submit-kyc";
            break;
          case MESSAGES.CANCELED:
            window.location.href = "/";
            break;
        }
      },
    });
  };

  const handleResubmissionStatus = (results: KYC_Data_Results) => {
    const data: KYC_Data_Results = filterObject(results, "status", KYC_RESUBMISSION || KYC_RESUBMISSION_REQUESTED);

    if (data.fullAuto && data.fullAuto?.hasAttemps) {
      setResubmissionStatus(true);
      setUrlResubmissionstatus(data.fullAuto?.url || "");
    }

    if (data.fullAutoSelfie && data.fullAutoSelfie?.hasAttemps) {
      setProofOfLife(true);
    }
  };

  const getAllAccountsDispatch = async (personId: string) => {
    const response = await dispatch(getAllAccounts(personId));

    if (response?.type === GET_ALL_ACCOUNTS_FULFILLED) {
      const sortedAccounts = [...response.payload].sort((a, b) => {
        if (a.sponsorBank === "Community Federal Savings Bank" && b.sponsorBank !== "Community Federal Savings Bank") {
          return 1;
        } else if (
          a.sponsorBank !== "Community Federal Savings Bank" &&
          b.sponsorBank === "Community Federal Savings Bank"
        ) {
          return -1;
        } else {
          return 0;
        }
      });

      setAccount(sortedAccounts);
    }

    setAccountsIsLoading(false);
    setIsLoading(false);
  };

  const getManualDirectDeposit = async () => {
    const payload = {
      personId: personId,
      customerId: customerId,
    };

    const response = await dispatch(GetDirectDeposit(payload));

    if (response?.type === GET_DIRECT_DEPOSIT_FULFILLED) {
      setShowDirectDeposit(response.payload.isSent);
    }
  };

  {
    /* Temporary disabled */
  }
  // const getAutomaticDirectDeposit = async () => {
  //   const response = await dispatch(getPayrollByUserAction());

  //   if (response?.type === GET_PAYROLL_BYUSER_FULFILLED) {
  //     setHasPinwheelAccount(response.payload?.hasPhinwheelAcount);
  //   }
  // };

  const GetUserFeature = async (userId: string, featureCode: string, setUserFeature: (active: boolean) => void) => {
    const request = {
      userId: userId,
      featureCode: featureCode,
    };
    const response = await dispatch(getUserFeature(request));

    if (response?.type === GET_USER_FEATURE_FULFILLED) {
      setUserFeature(response?.payload?.isActive);
    }
  };

  const sendToCashIn = async () => {
    const payload = {
      userId: userId,
      personId: personId,
      code: CASH_IN_BANNER,
    };

    await dispatch(UploadCashIn(payload));
  };

  const getCreditCellphone = async (userId: string) => {
    const response = await dispatch(getCellphoneAction(userId));

    if (response?.type === GET_CELLPHONE_FULFILLED) {
      if (response.payload === null) {
        setShowCellphone(false);
      }

      if (response.payload?.id) {
        setDataCellphone(response.payload);
        dispatch(selectCreditCellphoneId(response.payload?.id));
        setShowCellphone(true);
      }
    } else {
      setShowCellphone(false);
    }
  };

  const showNotificationAlert = () => {
    setOpenPueroRicoAlert(true);
  };

  const GetIdvISRequired = async (personId: string) => {
    const response = await dispatch(GetIdvIsRequiredAction(personId));

    if (response?.type === IDV_IS_REQUIRED_FULFILLED) {
      if (response?.payload?.hasIdvVeriff === false) {
        setPathIdvIsRequired("/kyc-identity-veriff");
        return;
      }

      if (response?.payload?.hasAddress === false) {
        setPathIdvIsRequired("/kyc-personal-address");
        return;
      }
    }
  };

  const setHideBanner = () => {
    const date = new Date();
    const nextDate = new Date(date.setDate(date.getDate() + 1));

    cookies.set(REFERRAL_COOKIE_NAME, nextDate.getTime(), {
      expires: nextDate,
      domain: DOMAIN,
    });
  };
  const getMustShowBanner = (): boolean => {
    const expires = cookies.get(REFERRAL_COOKIE_NAME);

    if (!expires) return true;

    try {
      if (expires) {
        const date = new Date();
        if (new Date(expires).getTime() > date.getTime()) return false;
      }
    } catch (error) {
      cookies.remove(REFERRAL_COOKIE_NAME);
      return true;
    }

    return true;
  };
  const getInvitationCode = async () => {
    const response = await dispatch(GetInvitationCodeAction(userId));

    if (response.type === INVITE_CODE_FULFILLED && response.payload) {
      setInvitationCode(response.payload);
    }
  };

  useEffect(() => {
    if (personId && userId) {
      // Temporary disabled
      // GetUserFeature(userId, PAYROLL_BANNER, setPayrollFeature);
      GetUserFeature(userId, CASH_IN_BANNER, setCashInFeature);
      GetUserFeature(userId, BUY_PHONE_BANNER, setBuyPhoneFeature);
      GetUserFeature(userId, REPATRIATION_BANNER, setRepatriationFeature);
      GetUserFeature(userId, DIRECT_DEPOSIT_REQUEST_FEATURE, (active: boolean) => {
        setDirectDepositFeature(active);
        setIsOpenDirectDepositModal(active);
      });
      GetUserFeature(userId, PROMOTION_SOCCER_BANNER, setPromotionSoccerFeature);
      GetUserFeature(userId, PAYROLL_ADVANCE_BANNER, setPayrollAdvanceFeature);
      GetUserFeature(userId, REWARDS_FEATURE, setRewardsFeature);
      GetUserFeature(userId, REWARDS_POPUP_FEATURE, setRewardsPopupFeature);
      // getAutomaticDirectDeposit();
      getAllAccountsDispatch(personId);
      handleResubmissionStatus(kycResults);
      getManualDirectDeposit();
      getCreditCellphone(userId);
      GetIdvISRequired(personId);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (RewardsPopupFeature && !isFirstTransaction) {
      const mustShowBanner = getMustShowBanner();
      if (mustShowBanner) setShowReferralPopup(true);
    }
    if (RewardsFeature && userId) {
      getInvitationCode();
    }
  }, [RewardsFeature, RewardsPopupFeature, isFirstTransaction]);

  return (
    <ThemeProvider theme={theme}>
      {/* Layout of loading */}
      <Layout show={isLoading} showNavigation>
        <LoaderIcon
          title={t("loaderText.title")}
          text={t("loaderText.subtitle")}
          description={t("loaderText.description")}
        />
      </Layout>
      <PayrollSetting
        show={!isLoading && DirectDepositFeature && isOpenDirectDepositModal}
        onClose={() => setDirectDepositFeature(false)}
        onCloseModal={() => setIsOpenDirectDepositModal(false)}
      />
      {/* Layout - home page */}
      <Layout
        show={(!isLoading && kycStatus !== KYC_DECLINED) || (pepStatus === KYC_APPROVED && !isOpenDirectDepositModal)}
        ref={benefitsRef}
        rewardsFeature={RewardsFeature}
        showNavigation
      >
        {RewardsPopupFeature && RewardsFeature && !isFirstTransaction && !showZelleModal && !showVenmoModal && (
          <ReferralModal
            show={showReferralPopup}
            invitationCode={invitationCode}
            handleClose={() => {
              setHideBanner();
              setShowReferralPopup(false);
            }}
          />
        )}

        {/* Temporary disabled */}
        {/* <ReferralWelcomeModal
          show={showReferralWelcomeModal}
          setVerifyIdentity={setVerifyIdentity}
          handleClose={() => {
            setShowReferralWelcomeModal(false);
          }}
        /> */}

        {transactionsZelle.length && (Number(minutes) > 0 || Number(seconds) > 0) ? (
          <AlertBox>
            <Text size={0.3} color="AlertBoxColor" align="left" weight="500" margin="0">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("zelleTransactionsModal.alertBox.msg1", {
                    time: `${minutes}:${seconds}`,
                  }),
                }}
              />
            </Text>
          </AlertBox>
        ) : (
          ""
        )}

        {/* VENMO */}
        {transactionsVenmo.length && (Number(venmoMinutes) > 0 || Number(venmoSeconds) > 0) ? (
          <AlertBox>
            <Text size={0.3} color="AlertBoxColor" align="left" weight="500" margin="0">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("venmoTransactionsModal.alertBox.msg1", {
                    time: `${venmoMinutes}:${venmoSeconds}`,
                  }),
                }}
              />
            </Text>
          </AlertBox>
        ) : (
          ""
        )}

        <Container as={HomeContainer}>
          {(kycStatus === KYC_IN_REVIEW || kycStatus === KYC_SUBMITTED) && (
            <Row>
              <Column span={12} mt={2}>
                <PendingRequestBanner
                  title={t("banners.bannerInReview.title")}
                  message={t("banners.bannerInReview.message")}
                  heightAuto
                />
              </Column>
            </Row>
          )}
          <Row>
            {/* Notification Banners Carousel */}
            {PromotionSoccerFeature && (
              <Column span={12} my={1}>
                <Carousel
                  itemSize={notificationsItemsTotal === 1 ? "100%" : "336px"}
                  totalItems={notificationsItemsTotal}
                  gap={"8px"}
                >
                  {/* Temporary disabled */}
                  {/* {kycStatus === KYC_APPROVED &&
                    accounts &&
                    accounts.length > 0 &&
                    hasPersonalChecking(accounts) &&
                    personalCheckingStatus === "active" &&
                    !hasPinwheelAccount &&
                    PayrollFeature && (
                      <PayrollStatusBanner
                        hasPinwheelAccount={hasPinwheelAccount}
                      />
                  )} */}

                  {/* Promotions Banners Carousel */}
                  <ZelleBanner
                    title={t("banners.Sweepstakes.title")}
                    message={t("banners.Sweepstakes.message")}
                    textButton={t("banners.Sweepstakes.btn")}
                    urlLink={urlSoccerBanner}
                  />
                </Carousel>
              </Column>
            )}
          </Row>
          {customerId !== BLDSpecialityContractor && !isAccountsLoading && (
            <GlobalPaymentsQuoter
              title={t("HomePage.GlobalPaymentsQuoter.title")}
              amount={0}
              countryDestination={nationality}
              countrySubdivision={subdivision}
              kycLevel={kycLevel}
              kycStatus={kycStatus}
              pepStatus={pepStatus}
              sponsorBank={accounts?.[0]?.sponsorBank}
              handleShowNotification={() => showNotificationAlert()}
              setVerifyIdentity={handleKYCStartVeriff}
              rewardsFeature={RewardsFeature}
              onKYCLevel2Request={() => setProofOfLife(true)}
            />
          )}
          {/* 
          <FlexContainer mw="556px">
            <Carousel itemSize={"100%"} totalItems={2}>
            </Carousel>
          </FlexContainer> */}

          <CarouselPaginated items={RewardsFeature && !isFirstTransaction ? 2 : 1}>
            <LessFeesBestFXBanner />
            {RewardsFeature && !isFirstTransaction && <ReferralBanner invitationCode={invitationCode} />}
          </CarouselPaginated>

          {/* {
            <Row>
              <Column span={12} my={1}>
                <Row>
                  <Column span={8}>
                    <Text
                      color="black"
                      align="left"
                      weight="500"
                      margin="0"
                      size={1}
                    >
                      {t("HomePage.accountTitle")}
                    </Text>
                  </Column>
                  <Column span={4} justifyContent={JustifyContent.flexEnd}>
                    {PlaidGlobalPaymentsFeature && (
                      <HomeLinkAccount
                        to="/funds-capture-check-instructions"
                        state={{ firstAccount: true, homePage: true }}
                        replace
                        variant="none"
                        size={0.5}
                      >
                        <Icon icon="plus" size="small" color="Primary01" />
                        <span>{t("LinkedAccounts.linkAccounts")}</span>
                      </HomeLinkAccount>
                    )}
                  </Column>
                </Row>
                <Row>
                  <Column span={12}>
                    {accountsItems?.length === 0 &&
                      isAccountsLoading &&
                      isLinkedAccountsLoading && <BalanceCardEmpty />}
                    {accountsItems?.length > 0 && (
                      <>
                        <Carousel
                          itemSize={
                            accountsItems?.length === 1 ? "100%" : "310px"
                          }
                          totalItems={accountsItems?.length}
                        >
                          {accountsItems}
                        </Carousel>
                      </>
                    )}
                  </Column>
                </Row>
              </Column>
            </Row>
          } */}

          {((BuyPhoneFeature && showCellphone) || PayrollAdvanceFeature || CashInFeature) && (
            <Row ref={benefitsRef}>
              <Column span={12}>
                <img src={lang.language === EN ? Benefits : Beneficios} width={145} height={45} />
              </Column>
            </Row>
          )}
          {/* Banners of Services */}
          <Row as={RowMargin}>
            {/* {BuyPhoneFeature && !showCellphone && (
              <Column span={6}>
                <BannerServicesCard
                  bgColor="Gradient_02"
                  icon="deviceMobile"
                  title={t("banners.bannerPhone.title")}
                  message={t("banners.bannerPhone.message")}
                  link={urlMyCelular}
                  target={true}
                  imageTitle={lang.language === EN ? MyPhone : Micel}
                  widthImageTitle={lang.language === EN ? 105 : 90}
                  heightImageTitle={32}
                />
              </Column>
            )} */}
            {BuyPhoneFeature && showCellphone && dataCellphone?.status !== "Paid" && (
              <Column span={6}>
                <BannerServicesCard
                  bgColor="Gradient_02"
                  icon="deviceMobile"
                  title={t("banners.bannerPhone.title")}
                  message={t("banners.bannerPhone.message")}
                  onClickBtn={() => navigate("/manage-payments")}
                  link=""
                  imageTitle={lang.language === EN ? MyPhone : Micel}
                  widthImageTitle={lang.language === EN ? 105 : 90}
                  heightImageTitle={30}
                  imgSubtitle={dataCellphone?.image}
                  notification={
                    <>
                      <TextNotification $margin="14px 0 0 6px">{t("banners.bannerPhone.pay")}</TextNotification>
                      <TextNotification $weight={600} $margin="0 0 0 6px;">
                        {dataCellphone?.nextPayment ? formatDate(dataCellphone?.nextPayment, locLang) : ""}
                      </TextNotification>
                    </>
                  }
                  buttonText={t("banners.bannerPhone.buttonManage")}
                  check
                />
              </Column>
            )}
            {BuyPhoneFeature && showCellphone && dataCellphone?.status === "Paid" && (
              <Column span={6}>
                <BannerServicesCard
                  bgColor="Gradient_02"
                  icon="deviceMobile"
                  title={t("banners.bannerPhone.title")}
                  message={t("banners.bannerPhone.message")}
                  onClickBtn={() => navigate("/manage-payments")}
                  link=""
                  imageTitle={lang.language === EN ? MyPhone : Micel}
                  widthImageTitle={lang.language === EN ? 105 : 90}
                  heightImageTitle={30}
                  imgSubtitle={dataCellphone?.image}
                  buttonIcon="star"
                  notification={
                    <>
                      <TextNotificationPaid $margin="14px 0 0 6px">
                        {t("banners.bannerPhone.fullPayment")}
                      </TextNotificationPaid>
                    </>
                  }
                  buttonText={t("banners.bannerPhone.buttonFullPayment")}
                  check
                  sizeTextBtn="10px"
                />
              </Column>
            )}
            {PayrollAdvanceFeature && (
              <Column span={6}>
                <BannerServicesCard
                  bgColor="Gradient_11"
                  icon="deviceMobile"
                  title={t("banners.bannerPayrollAdvance.title")}
                  message={t("banners.bannerPayrollAdvance.message")}
                  link={urlPayrollAdvance}
                  target={true}
                  imageTitle={lang.language === EN ? AdvancePay : Adelanto}
                  widthImageTitle={lang.language === EN ? 125 : 105}
                  heightImageTitle={32}
                />
              </Column>
            )}
            {CashInFeature && (
              <Column span={6}>
                <BannerServicesCard
                  bgColor="Gradient_07"
                  icon="cash"
                  title={t("banners.bannerCashIn.title")}
                  message={t("banners.bannerCashIn.message")}
                  link={urlCashIn}
                  target={true}
                  onClickBtn={sendToCashIn}
                />
              </Column>
            )}
            {RepatriationFeature && (
              <Column span={6}>
                <BannerServicesCard
                  bgColor="Gradient_09"
                  imageTitle={lang.language === EN ? Repatriation : Contigo}
                  title={t("banners.bannerRepatriation.title")}
                  message={t("banners.bannerRepatriation.message")}
                  link={urlRepatriation}
                  target={true}
                  widthImageTitle={lang.language === EN ? 125 : 95}
                  heightImageTitle={32}
                />
              </Column>
            )}
          </Row>
        </Container>

        {/* Open Puerto Rico */}
        <NotificationModal
          show={openPueroRicoAlert}
          modalName="modal-open-puerto-rico"
          image={PuertoRicoAlertImage}
          hasSupport
          hideSubmit
          urlSupport={urlWhatsAppSupport}
          title={t("HomePage.GlobalPaymentsQuoter.notificationModal.title")}
          paragraph1={t("HomePage.GlobalPaymentsQuoter.notificationModal.subtitle")}
          btnText={t("buttons.submit")}
          handleActionButton={() => ""}
          handleClose={() => setOpenPueroRicoAlert(false)}
          textAlignTitle="center"
          textAlignparagraph1="center"
        />
        {/* KYC LVL 1 */}
        {kycStarterModal}
        {/* KYC LVL 2 - proof of life */}
        <NotificationModal
          show={proofOfLife}
          modalName="modal-proof-of-life"
          image={ProofOfLifeImage}
          textAlignTitle="center"
          textAlignparagraph1="center"
          title={t("notificationModal.proofOfLife.title")}
          paragraph1={t("notificationModal.proofOfLife.paragraph1")}
          btnText={t("notificationModal.proofOfLife.btnText")}
          handleActionButton={() => navigate("/complete-proof-of-life")}
          handleClose={() => setProofOfLife(false)}
        >
          <Row>
            <Column span={1}>
              <Text size={3} color="Primary01" align="left" weight="400" margin="10px 0 0 0">
                1
              </Text>
            </Column>
            <Column span={11}>
              <Row>
                <Column span={12} my={1}>
                  <Text color="black" align="left" weight="500" margin="0" size={1}>
                    {t("notificationModal.proofOfLife.subTitle1")}
                  </Text>
                </Column>
              </Row>
              <Row>
                <Column span={2} my={1}>
                  <Icon icon="faceId" color="primary" size="medium" />
                </Column>
                <Column span={10} alignItems={AlignItems.center}>
                  <Text color="black" align="left" weight="400" margin="0" size={0.5}>
                    {t("notificationModal.proofOfLife.listItems.faceId")}
                  </Text>
                </Column>
              </Row>
            </Column>
          </Row>
          <Row>
            <Column span={1}>
              <Text size={3} color="Primary01" align="left" weight="400" margin="10px 0 0 0">
                2
              </Text>
            </Column>
            <Column span={11}>
              <Row>
                <Column span={12} my={1}>
                  <Text color="black" align="left" weight="500" margin="0" size={1}>
                    {t("notificationModal.proofOfLife.subTitle2")}
                  </Text>
                </Column>
              </Row>
              <Row>
                <Column span={2} my={1}>
                  <Icon icon="licenseCardAmerican" color="primary" size="medium" />
                </Column>
                <Column span={10} alignItems={AlignItems.center}>
                  <Text color="black" align="left" weight="400" margin="0" size={0.5}>
                    {t("notificationModal.proofOfLife.listItems.lincese")}
                  </Text>
                </Column>
              </Row>
              <Row>
                <Column span={2} my={1}>
                  <Icon icon="iDCardAmerican" color="primary" size="medium" />
                </Column>
                <Column span={10} alignItems={AlignItems.center}>
                  <Text color="black" align="left" weight="400" margin="0" size={0.5}>
                    {t("notificationModal.proofOfLife.listItems.idCard")}
                  </Text>
                </Column>
              </Row>
            </Column>
          </Row>
        </NotificationModal>
        {/* Resumibssion KYC */}
        <NotificationModal
          show={resubmissionStatus}
          modalName="modal-resubmission-fullAuto"
          image={VerifyIdentityImage}
          hasSupport
          textAlignTitle="center"
          title={t("notificationModal.Resubmission.title")}
          paragraph1={t("notificationModal.Resubmission.paragraph1")}
          btnText={t("notificationModal.Resubmission.btnText")}
          handleActionButton={() => handleOpenIframeVeriff()}
          handleClose={() => setResubmissionStatus(false)}
        />
      </Layout>
      {/* Layout of KYC declined status */}
      <Layout
        show={
          !isLoading &&
          (kycStatus === KYC_DECLINED || pepStatus === KYC_DECLINED || pepStatus === KYC_DECLINED) &&
          !resubmissionStatus
        }
      >
        <Container>
          <Row>
            <Column span={12} my={2}>
              <WarningMessage
                title={t("ApplicationDenied.title2")}
                message={t("ApplicationDenied.message2")}
                icon="circleXRed"
              />
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default HomePage;
