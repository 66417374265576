export function getContrastColor(hex = "#FFFFFF") {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? "#000000" : "#FFFFFF";
}

export function darkenColor(hex = "#FFFFFF", factor = 0.2) {
  if (hex.charAt(0) === "#") {
    hex = hex.slice(1);
  }

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  const darken = (color: number) => Math.max(0, Math.min(255, Math.floor(color * (1 - factor))));

  const newR = darken(r);
  const newG = darken(g);
  const newB = darken(b);

  const toHex = (color: number) => {
    const hex = color.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  return `#${toHex(newR)}${toHex(newG)}${toHex(newB)}`;
}
