import styled, { CSSProperties } from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { prop, theme } from "styled-tools";

export const OverLay = styled.div<{ zIndex?: CSSProperties["zIndex"] }>`
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: ${(props) => (props.hidden ? "none" : "flex")};
  align-items: center;
  overflow: hidden;
  z-index: ${prop("zIndex", 1001)};
  backdrop-filter: blur(2px);
  pointer-events: auto;
  scroll-padding-bottom: 10px;
`;

export const ModalWrap = styled.div<{
  dragClose?: boolean;
  transformY?: string;
  isDragging?: boolean;
  zIndex?: CSSProperties["zIndex"];
}>`
  box-sizing: border-box;
  margin: auto;
  padding: 30px;
  background: ${theme("Background_01")};
  border: 1px solid ${theme("Content_State_UX_Hover")};
  width: 350px;
  border-radius: 45px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: ${prop("zIndex", 1000)};
  pointer-events: auto;

  ${mediaQuery[Screen.sm]} {
    width: 75%;
    max-width: 374px;
    padding: 45px 10px;
  }

  ${mediaQuery[Screen.mobile]} {
    width: 85%;
    max-width: 374px;
    padding: 25px;
  }

  h1 {
    ${mediaQuery[Screen.mobile]} {
      width: 100%;
    }
  }
`;

export const HeadModal = styled.div`
  width: 100%;
  text-align: right;
  justify-content: end;
  display: flex;
  z-index: 2;
`;

export const MarginIcon = styled.div`
  margin: 0 0 20px 0;
`;
