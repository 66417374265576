import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { AppDispatch } from "../../../Redux/Store";

import { ByUserSliceSelector } from "../../../Redux/ByUser/ByUser.slice";
import { setCompletedStatus } from "../../../Redux/Payroll/PayrollData.slice";

import { PdfShippingUpdate } from "../../../Redux/PdfShippingUpdate/PdfShippingUpdate.actions";

import { propsWsp } from "../../../Models/DirectDeposit";

import { PDF_SHIPPING_UPDATE_FULFILLED, PDF_SHIPPING_UPDATE_REJECTED } from "../../../Constants/Payroll";

import Button from "../../../Components/Button";
import { Column } from "../../../Components/Grid";
import Title from "../../../Components/Title";

import { WhatsAppContainer } from "./PayrollUpdate.styled";

const WhatsAppConfirmation = (props: propsWsp) => {
  const {show, isValid, onHide} = props;

  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [isLoading, setIsLoading] = useState(false);

  const ByUser = useSelector(ByUserSliceSelector);

  const personId = useMemo(() =>
    ByUser.data?.personId
  ,[ByUser])

  const customerId = useMemo(() =>
    ByUser.data?.customerId,
  [ByUser]);

  const whatsappConfirmation = async() => {
    setIsLoading(true);

    const payload = {
      personId: personId,
      customerId: customerId,
      isSent: true
    }

    const response = await dispatch(PdfShippingUpdate(payload));
    if (response?.type === PDF_SHIPPING_UPDATE_FULFILLED) {
      setIsLoading(false);
      dispatch(setCompletedStatus(true));
      navigate("/")
    }

    if (response?.type === PDF_SHIPPING_UPDATE_REJECTED) {
      setIsLoading(false);
    }
  }


  return (
    <WhatsAppContainer $show={show}>
      <Title
        size={1}
        tagName="h1"
        color="black_highlight"
        align="left"
        text={t("Payroll.whatsapp.title")}
      />
      <Title
        size={1}
        tagName="h3"
        color="grey"
        align="left"
        text={t("Payroll.whatsapp.message")}
      />
      <Column span={12}>
        <Button
          type="submit"
          variant="primary"
          text={t("Payroll.whatsapp.send")}
          sizeText="medium"
          sizeButton="xlarge"
          disabled={!isValid}
          onClick={whatsappConfirmation}
          loading={isLoading ? 1 : 0}
        />
        <Button
          type="submit"
          variant="outline"
          text={t("Payroll.whatsapp.tryAgain")}
          sizeText="medium"
          sizeButton="xlarge"
          disabled={!isValid}
          onClick={onHide}
        />
      </Column>
    </WhatsAppContainer>
  )
}

export default WhatsAppConfirmation;