import styled from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { theme } from "styled-tools";
import { imgTypes } from "../../Utilities/Icons";

export const ManageAccountContainer = styled.div`
  max-width: 556px;
  min-width: auto;
  margin: auto;

  ${mediaQuery[Screen.tablet]} {
    padding-inline: 0;
  }

  ${mediaQuery[Screen.xlDesktop]} {
    padding-inline: 0;
  }
`;

export const CurrentBalance = styled.div<{ $accountState: boolean;}>`
  background-size: contain;
  background-repeat: no-repeat;
  height: 800px;
  width: 100%;

  background: ${(props) => props.$accountState ? theme("Primary_02") : theme("Primary_05")};
  border-radius: 24px;
  height: auto;
`;

export const ContainerHeaderUSI = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mediaQuery[Screen.desktop]} {
    justify-content: start;
  }
`;

export const InfoBox = styled.div`
  p {
    font-family: Poppins;
    font-size: 12px;
    text-align: justify;
    color: ${theme("Background_02")};
    margin: 5px 0;
  }
`;

export const AvailableBalance = styled.div`
  display: block;
  padding: 8px 0;
  border-top: 1px solid ${theme("Background_01")};
  border-bottom: 1px solid ${theme("Background_01")};

  ${mediaQuery[Screen.desktop]} {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border: none;
  }
`;

export const LabelCard = styled.div`
  border: 1px solid ${theme("Background_01")};
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 8px 16px;
  width: 100%;

  svg {
    cursor: pointer;
  }
`;

export const ActionsIcons = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-around;
  flex-direction: row;
  width: 50px;
`;

export const NumberContainer = styled.div`
  ${mediaQuery[Screen.desktop]} {
    margin-bottom: 30px;
  }
`;

export const CurrentBalanceC = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  height: 190px;
  align-self: center;

  ${mediaQuery[Screen.mobile]} {
    height: auto;
  }
`;

export const CardSection = styled.div<{ $isActive: boolean }>`
  width: 100%;
  max-width: 300px;
  background: ${(props) => (props.$isActive ? `url(${imgTypes["cardSendola"]})` : `url(${imgTypes["cardDisableSendola"]})`)};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  ${mediaQuery[Screen.mobile]} {
    max-width: 370px;
    height: 240px;

    p {
      font-size: 18px;
      margin: 8px 20px;
    }
  }

  ${mediaQuery[Screen.xs]} {
    height: 170px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 68px;
  padding-left: 15px;
  align-items: flex-start;

  ${mediaQuery[Screen.mobile]} {
    padding-left: 0;
    padding-bottom: 110px;

    p {
      margin-inline: 40px;
    }
  }

  ${mediaQuery[Screen.xs]} {
    padding-bottom: 40px;
  }

  p.limited {
    font-size: 10px;

    ${mediaQuery[Screen.mobile]} {
      margin-top: 0;
    }
  }

  p.legend {
    font-size: 12px;

    ${mediaQuery[Screen.mobile]} {
      margin-bottom: 5px;
    }
  }

  p.card-number {
    padding-top: 40px;
    font-size: 16px;

    ${mediaQuery[Screen.mobile]} {
      transform: translate(0, -40%);
    }
  }
`;

export const HeaderCurrent = styled.div`
  ${mediaQuery[Screen.mobile]} {
    justify-content: flex-start;
  }
`;

export const EmptySearhSection = styled.div`
  margin-bottom: 400px;
`;

export const ModalInfo = styled.div`
  background: ${theme("Background_01")};
  border-radius: 8px;
  box-sizing: border-box;
  height: 90px;
  margin-top: 110px;
  position: relative;
  padding: 10px 16px;
  width: 100%;
`;

export const CloseBtn = styled.div`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const ContentModal = styled.div`
  background: ${theme("Background_01")};
  height: 50px;
  position: relative;
  width: 100%;

  p {
    padding-bottom: 4px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
  }

  p.active {
    border-bottom: 2px solid ${theme("Primary_01")};
  }
`;

export const Badge = styled.div`
  background-color: ${theme("Primary_15")};
  border-radius: 16px;
  color: ${theme("Primary_01")};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  padding: 2px 10px;
  text-align: center;
  width: 72px;
`;

export const IconTutorial = styled.div`
  position: relative;
  margin: 4px 0;
  margin-right: 30%;
`;