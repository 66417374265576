import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { DirectDepositRequest } from "../../Models/DirectDeposit";
import { GET_PAYROLL_PDF } from "../../Constants/Payroll";

/**
 * Method to get payroll pdf
 */
export const GetPayrollPdf = createAsyncThunk(
  GET_PAYROLL_PDF,
  async (payload: DirectDepositRequest, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post(`/payroll/person/${payload.personId}/customer/${payload.customerId}`);
      return response.data.data;
    } catch(error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
