import { Props } from "../../Models/IconInterface";
import { iconTypes } from "../../Utilities/Icons";
import { IconStyled } from "./Icon.styled";

const Icon = ({ gradient, icon, size, color, fillIcon, fillTransparent, ...props }: Props) => {
  const Icons = iconTypes[icon];
  return (
    <IconStyled
      size={size ? size : "default"}
      color={color ? color : "default"}
      gradient={gradient}
      $fillTransparent={fillTransparent}
      $fill={fillIcon ? true : false}
      {...props}
    >
      {Icons && <Icons />}
    </IconStyled>
  );
};

export default Icon;
