import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";

import Icon from "../Icon";
import NavigationBarItem from "./NavigationBarItem.component";

import { State } from "../../Redux/Store";

import { BarContainer, GradientCenterButton, NavigationContainer } from "./NavigationBar.styled";
import { Link, useLocation } from "react-router-dom";

interface NavigationBarProps {
  hasCoupons?: boolean;
  onClickCoupons?: () => void;
}

const NavigationBar = ({ hasCoupons = false, onClickCoupons }: NavigationBarProps) => {
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <BarContainer>
        <NavigationContainer>
          <NavigationBarItem
            text={t("NavigationBar.Recipients")}
            icon="users2"
            path="/recipients"
            active={location.pathname === "/recipients"}
          />

          <NavigationBarItem
            text={t("NavigationBar.Transactions")}
            icon="receipt"
            path="/transactions"
            active={location.pathname === "/transactions"}
          />

          <GradientCenterButton as={Link} to="/" gap="2px">
            <Icon icon="currencyDollarExchange" color="white" size="large" />
          </GradientCenterButton>

          <NavigationBarItem
            text={t("NavigationBar.LinkedAccounts")}
            icon="bank2"
            path="/linked-accounts"
            active={location.pathname === "/linked-accounts"}
          />

          {hasCoupons ? (
            <NavigationBarItem
              onClick={() => onClickCoupons && onClickCoupons()}
              text={t("NavigationBar.Discounts")}
              isTextGradient
              icon="flameDiscountGradient"
              path="/"
            />
          ) : (
            <NavigationBarItem
              onClick={() => onClickCoupons && onClickCoupons()}
              text={t("NavigationBar.Discounts")}
              icon="flameDiscount2"
            />
          )}
        </NavigationContainer>
      </BarContainer>
    </ThemeProvider>
  );
};

export default NavigationBar;
