import styled, { css } from "styled-components";
import { ButtonSizesKey, handleButtonLineHeight, handleSizeButton, handleText } from "@utils/SizesButtons";
import mediaQuery, { Screen } from "@utils/MediaQuery";
import { ifProp, prop, switchProp, theme } from "styled-tools";
import { ButtonProps } from "@models/buttonInterface";

const GeneralStyles = `
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

export const ButtonDefault = styled.button<
  {
    $type?: string;
    $sizeButton?: ButtonSizesKey;
  } & Partial<ButtonProps>
>`
  ${GeneralStyles}
  border-radius: ${prop("borderRadius", "50px")};
  background: ${switchProp("variant", {
    default: theme("Primary_01"),
    primary: theme("Primary_01"),
    secondary: theme("Background_01"),
    danger: theme("Error"),
    gradient: `linear-gradient(
      180deg,
      ${theme("Primary_03")} 0%,
      ${theme("Primary_01")} 100%
    )`,
    outline: theme("Transparent"),
    outlineMuted: theme("Transparent"),
    transparent: theme("Transparent"),
    zelle: theme("ZelleColor"),
    venmo: theme("VenmoColor"),
    zelleGradient: `linear-gradient(8.83deg, #680BAD 0.97%, #6E66FF 112.47%)`,
    bannerBank: `linear-gradient(8.83deg, #264093 0.97%, #0766E1 56.53%)`,
  })};
  color: ${switchProp("variant", {
    default: theme("Content_UX_03"),
    primary: theme("Content_UX_03"),
    secondary: theme("Primary_01"),
    danger: theme("Content_UX_03"),
    gradient: theme("Content_UX_03"),
    outline: theme("Primary_01"),
    outlineMuted: theme("Black"),
    transparent: theme("Black"),
    zelle: theme("White"),
    venmo: theme("VenmoColor"),
    zelleGradient: theme("White"),
    bannerBank: theme("White"),
  })};
  ${(props) =>
    !["gradient", "transparent"].includes(props.variant || "")
      ? css`
          border: 1px solid
            ${switchProp("variant", {
              default: theme("Primary_01"),
              primary: theme("Primary_01"),
              outline: theme("Primary_01"),
              outlineMuted: theme("Neutral_300"),
              danger: theme("Error"),
              secondary: theme("Primary_01"),
              gradient: theme("Primary_01"),
              transparent: theme("Transparent"),
              zelle: theme("Transparent"),
              venmo: theme("VenmoColor"),
              zelleGradient: theme("Transparent"),
              bannerBank: theme("Transparent"),
            })};
        `
      : css`
          border: 0px;
        `};
  font-family: "Poppins";
  width: ${(props) => (props.$sizeButton ? `${handleSizeButton(props.$sizeButton)}` : `auto`)};
  font-size: ${(props) => (props.sizeText ? `${handleText(props.sizeText)}px` : "20px")};
  font-weight: ${prop("weight", "700")};
  line-height: ${(props) =>
    props.lineHeight || (props.sizeText ? `${handleButtonLineHeight(props.sizeText)}px` : "20px")};
  padding: ${(props) =>
    props.padding || (!props.$sizeButton || props.$sizeButton === "small" ? "8px 32px" : "16px 32px")};
  ${ifProp(
    "withShadow",
    css`
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    `
  )}

  &:disabled {
    background: ${ifProp(
      {
        variant: "outline",
      },
      theme("Background_01"),
      theme("Content_UX_02")
    )};
    color: ${ifProp(
      {
        variant: "outline",
      },
      theme("Content_UX_02"),
      theme("Background_01")
    )};
    border: 1px solid ${theme("Content_UX_02")};
  }

  ${mediaQuery[Screen.xs]} {
    text-align: center;
  }
`;

export const TextContainer = styled.div<{
  $iconButton?: string;
  $iconButtonLeft?: string;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  span {
    width: ${(props) => (!props.$iconButton && !props.$iconButtonLeft ? "100%" : "")};
    text-align: ${(props) => (!props.$iconButton ? "center" : "right")};
  }
`;
