import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { ThemeProvider } from "styled-components";
import { isEmpty } from "lodash";

// own states
import { AppDispatch, State } from "../../../Redux/Store";
import { UpdateAddressSliceAction } from "../../../Redux/Address/Address.actions";
import { ByUserSliceSelector } from "../../../Redux/ByUser/ByUser.slice";
import { PrefilledDataSelector } from "../../../Redux/PrefilledData/PrefilledData.slice";
import { saveAddressAction } from "../../../Redux/USAddress";

// Own Models
import { JustifyContent } from "../../../Models/Column";
import { ValueProp } from "../../../Models/Select";
import { AddressForm } from "../../../Models/Address";
import { PersonalDataSliceSelector } from "../../../Redux/PersonalData/PersonalData.slice";

// Own styles
import { StepsContainer } from "./UsAddress.styles";

// Own constants
import { states } from "../../../Constants/StatesUS";
import { UPDATE_USER_ADDRESS_FULFILLED, UPDATE_USER_ADDRESS_REJECTED } from "../../../Constants/user";
import { noLeadingSpaces, allowenNumbers, minLengthPostalCode } from "../../../Constants/Regexp";

// OWn components
import { Row, Column, Container } from "../../../Components/Grid";
import Layout from "../../../Components/Layout";
import Input from "../../../Components/Input";
import ReactSelect from "../../../Components/ReactSelect";
import Text from "../../../Components/Text";
import Button from "../../../Components/Button";
import LoaderIcon from "../../../Components/LoaderIcon/LoaderIcon.component";

const UsAddress = () => {
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const PersonalData = useSelector(PersonalDataSliceSelector);
  const ByUser = useSelector(ByUserSliceSelector);
  const PrefilledData = useSelector(PrefilledDataSelector);
  const dispatch = useDispatch<AppDispatch>();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    trigger,
    formState: { errors, isDirty, isValid },
    setValue
  } = useForm<AddressForm>({
    mode: "onChange"
  });

  const personId = useMemo(() =>
    PersonalData.data?.personId || ByUser.data?.personId,
  [PersonalData, ByUser]);

  const onSubmit = async (data: AddressForm) => {
    if (!personId) {
      setIsError(true);
      return;
    }

    setIsError(false);
    setIsLoading(true);

    const address = {
      ...data,
      country: "USA"
    }

    await dispatch(saveAddressAction(address));

    const response = await dispatch(UpdateAddressSliceAction({
      personId,
      address
    }))

    if (response?.type === UPDATE_USER_ADDRESS_FULFILLED) {
      window.location.href = "/submit-kyc";
    }
    
    if (response?.type === UPDATE_USER_ADDRESS_REJECTED) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  const getPreload = () => {
    setValue("line1", PrefilledData.data?.address?.line1 || '', {
      shouldValidate: true,
      shouldDirty: true
    });
    setValue("line2", PrefilledData.data?.address?.line2 || '', {
      shouldValidate: true
    });
    setValue("city", PrefilledData.data?.address?.locality || '', {
      shouldValidate: true
    });
    setValue("state", PrefilledData.data?.address?.countrySubdivision || '', {
      shouldValidate: true
    });
    setValue("zipCode", PrefilledData.data?.address?.zipCode || '', {
      shouldValidate: true
    });
  }

  useEffect(() => {
    if(PrefilledData.data?.address && !isEmpty(PrefilledData.data?.address) ) {
      getPreload();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Layout show={isLoading}>
        <LoaderIcon
          title={t("loaderText.title")}
          text={t("loaderText.subtitle")}
        />
      </Layout>
      <Layout show={!isLoading}>
        <Container as={StepsContainer}>
          <Row>
            <Column span={12}>
              <Row>
                <Column span={12}>
                  <Row>
                    <Column span={12} justifyContent={JustifyContent.flexStart} mt={2} mb={1}>
                      <Text size={3} align="left" weight={600} color="black" margin={0}>
                        {t("personalData.usAddress.title")}
                      </Text>
                    </Column>
                  </Row>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Column span={12} my={1}>
                        <Input
                          typeInput="text"
                          textLabel={t("personalData.usAddress.form.addressInput")}
                          label="line1"
                          maxLengthInput={30}
                          register={register}
                          onBlur={() => trigger("line1")}
                          rules={{
                            required: t("Forms.required"),
                            pattern: {
                              value: noLeadingSpaces,
                              message: t("Forms.validateSpaces"),
                            }
                          }}
                          errors={errors}
                        />
                      </Column>
                      <Column span={12} my={1}>
                        <Input
                          typeInput="text"
                          textLabel={t("personalData.usAddress.form.deptoInput")}
                          label="line2"
                          maxLengthInput={30}
                          register={register}
                          rules={{
                            pattern: {
                              value: noLeadingSpaces,
                              message: t("Forms.validateSpaces"),
                            }
                          }}
                          errors={errors}
                        />
                      </Column>
                      <Column span={12} my={1}>
                        <Input
                          typeInput="text"
                          textLabel={t("personalData.usAddress.form.cityInput")}
                          label="city"
                          maxLengthInput={30}
                          register={register}
                          onBlur={() => trigger("city")}
                          rules={{
                            required: t("Forms.required"),
                            pattern: {
                              value: noLeadingSpaces,
                              message: t("Forms.validateSpaces"),
                            }
                          }}
                          errors={errors}
                        />
                      </Column>
                      <Column span={12} my={1}>
                        <Controller
                          control={control}
                          name="state"
                          rules={{
                            required: t("Forms.required"),
                          }}
                          render={({ field: { onChange, value, ref } }) => (
                            <ReactSelect
                              inputRef={ref}
                              nameSelect="state"
                              placeholder={t("personalData.usAddress.form.stateInput")}
                              textLabel=""
                              options={states}
                              value={states.find(c => c.value === value)}
                              onChange={(val:ValueProp) => onChange(val?.value)}
                              selectedDefaultValue="US"
                              errors={errors}
                            />
                          )}
                        />
                      </Column>
                      <Column span={12} my={1}>
                        <Input
                          typeInput="text"
                          inputMode="numeric"
                          textLabel={t("personalData.usAddress.form.postalCodeInput")}
                          label="zipCode"
                          maxLengthInput={5}
                          register={register}
                          onBlur={() => trigger("zipCode")}
                          rules={{
                            required: t("Forms.required"),
                            pattern: {
                              value: allowenNumbers,
                              message: t("Forms.inputNumber"),
                            },
                            validate: {
                              noLeadingSpaces: value => noLeadingSpaces.test(value) || t("Forms.validateSpaces"),
                              minNumber: value => minLengthPostalCode.test(value) || t("Forms.postalCodeMinLength")
                            }
                          }}
                          errors={errors}
                        />
                      </Column>
                    </Row>           
                    
                    {isError && (
                      <Column span={12} mb={2}>
                        <Text size={0.5} align="center" weight={600} color="error">
                          {t("404.subtitle")}
                        </Text>
                      </Column>
                    )}
                    
                    <Row>
                      <Column span={12} my={4}>
                        <Button
                          type="submit"
                          variant={
                            !isDirty || !isValid || isLoading ? "default" : "primary"
                          }
                          loading={isLoading ? 1 : 0}
                          text={t("personalData.usAddress.form.btnNext")}
                          sizeText="medium"
                          sizeButton="large"
                          disabled={!isValid}
                        />
                      </Column>
                    </Row>
                  </form>
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default UsAddress;
