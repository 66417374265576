import {
  PaymentOrder,
  PaymentOrderDetailResponse,
  PaymentTransactionHistory,
  PaymentTransactions,
} from "../domain/PaymentOrder";
import { PaymentOrderTracking } from "../domain/PaymentOrderCreated";
import { PaymentOrderRepository } from "../domain/PaymentOrderRepository";

export const getPaymentOrders =
  (paymentOrderRepository: PaymentOrderRepository) =>
  async (): Promise<PaymentOrder[]> =>
    paymentOrderRepository.getPaymentOrders();

export const getPaymentOrder =
  (paymentOrderRepository: PaymentOrderRepository) =>
  async (id: string): Promise<PaymentOrderDetailResponse> =>
    paymentOrderRepository.getPaymentOrder(id);

export const getVelocityTxn =
  (paymentOrderRepository: PaymentOrderRepository) => async (): Promise<any> =>
    paymentOrderRepository.getVelocityTxn();

export const getVelocityAmountTxn =
  (paymentOrderRepository: PaymentOrderRepository) =>
  async (amount: number): Promise<undefined> =>
    paymentOrderRepository.getVelocityAmountTxn(amount);

export const getVelocityTsxSendolaPay =
  (paymentOrderRepository: PaymentOrderRepository) => async (): Promise<any> =>
    paymentOrderRepository.getVelocityTsxSendolaPay();

export const getVelocityTsxDestination =
  (paymentOrderRepository: PaymentOrderRepository) =>
  async (
    amount: number,
    destinationId: string | null | undefined
  ): Promise<any> =>
    paymentOrderRepository.getVelocityTsxDestination(amount, destinationId);

export const getPaymentOrdersTransaction =
  (paymentOrderRepository: PaymentOrderRepository) =>
  async (payload: PaymentTransactions): Promise<PaymentTransactionHistory> =>
    paymentOrderRepository.getPaymentOrdersTransaction(payload);

export const getPaymentOrderTracking =
  (paymentOrderRepository: PaymentOrderRepository) =>
  async (id: string): Promise<PaymentOrderTracking[]> =>
    paymentOrderRepository.getPaymentOrderTracking(id);
