import styled from "styled-components";
import mediaQuery, { Screen } from "../../../Utilities/MediaQuery";
import { theme } from "styled-tools";

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  text-align: left;
  margin: 0px 100px;

  ${mediaQuery[Screen.mobile]} {
    width: 100%;
    margin: 0px;
  }

  h1 {
    text-align: left;
    display: block;
    ${mediaQuery[Screen.mobile]} {
      text-align: center;
    }
  }
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  margin: 0px 100px;

  input {
    width: 100%;
    box-sizing: border-box;
  }

  ${mediaQuery[Screen.mobile]} {
    margin: 0;
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const InputStyled = styled.div`
  margin-bottom: 24px;
`;

export const TextCustom = styled.span`
  line-height: 19px;
  text-decoration-line: underline;
  color: ${theme("Content_UX_02")};
  font-size: 16px;
  margin: 20px 0;
`;

export const FormContactUs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 100px;

  ${mediaQuery[Screen.mobile]} {
    margin: 25px 0;
  }
`;
