
import styled from "styled-components";
import { ifProp, prop, theme } from "styled-tools";

export const ContainerCard = styled.div<{
  $bgColor?: string;
}>`
  
  position: relative; 
  background: 
    linear-gradient(
      45deg,
      transparent,
      rgba(${theme('RGBA_01')}, 0.38)
    );
  background-color: ${ifProp("$bgColor", prop("$bgColor"), theme("Background_01"))};
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow:0px 4px 24px 0px rgba(${theme("RGBA_01")}, 0.15);
  padding: 16px 0;
  height: 230px;
  margin: 24px 8px;
  text-align: left;

  span,
  .linked-account-type {
    font-size: 10px;
  }

  button {
    max-width: 180px;
    
    span {
      font-weight: 500;
      font-size: 12px;
    }

    path {
      fill: ${theme("Background_01")};
    }
  }

  .linked-account-options {
    background-color: transparent;
    border: none;
    z-index: 22;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const UnlinkedCard = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
  border-radius: 10px;
  background: 
    linear-gradient(
      transparent,
      rgba(${theme('RGBA_01')}, 1) 60%
    );
`;

export const UnlinkedCardContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;

  .linked-account-unapproved {
    p {
      font-size: 10px;
    }
  }
`;

export const UnapprovedLinkedCard = styled(UnlinkedCard)`
  background: 
    linear-gradient(
      transparent,
      rgba(${theme('RGBA_01')}, 1) 90%
    );
`;

export const UnapprovedLinkedCardContent = styled(UnlinkedCardContent)`
  top: 75%;
  transform: translate(-50%, 0);
  width: 85%;

  p {
    font-size: 10px;
  }
`;

export const UnlinkedCardInfoLocked = styled.div<{
  $width?: string;
  $margin?: string;
}>`
  width: ${ifProp("$width", prop("$width"), "100%")};
  margin: ${ifProp("$margin", prop("$margin"), "0")};
  height: 3px;
  background-color: ${theme('Background_01')};
  border-radius: 4px;
`;

export const LinkedCardOptionsBtn = styled.button`
  background: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }
`;

export const LinkedCardDeleteContainer = styled.div`
  position: relative;
  z-index: 22;
`;

export const LinkedCardDeleteBtn = styled.div`
  position: absolute;
  bottom: -36px;
  right: 30px;
  background-color: ${theme('Background_01')};
  width: 224px;
  height: 56px;
  border-radius: 10px;
  padding: 0 16px;

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  
  svg {
    path {
      stroke: ${theme('Red_11')} !important;
    }
  }
`;

export const LinkedAccountModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(${theme('RGBA_01')}, 0.6);
  z-index: 30;
`;

export const LinkedAccountModalContent = styled.div`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: ${theme('Background_01')};
  border-radius: 10px;
  border: 1px solid ${theme('Red_11')};
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  z-index: 32;
  width: 300px;
  padding: 32px 24px;
`;

export const LinkedAccountModalCancelButton = styled(LinkedCardOptionsBtn)`
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  color: ${theme('Primary_09')};
  cursor: pointer;
`;

export const LinkedAccountModalConfirmButton = styled(LinkedCardOptionsBtn)`
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  color: ${theme('Red_11')};
  cursor: pointer;
`;

export const Balance = styled.p`
  color: ${theme('Background_01')};
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  margin: -8px 0 0 0;
`;

export const NumberText = styled.p`
  font-size: 10px;
  color: ${theme("Background_01")};
  margin-bottom: 2px;
  margin-top: 0;
  font-weight: 500;
`;

export const ButtonIcon = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  svg {
    width: 12px;
    height: 12px;

    path {
      fill: transparent!important;
    }
  }
`;

export const ButtonContainer = styled.div`
  button {
    width: 143px;
    height: 30px;
    padding: 0;

    span {
      margin-top: -2px;
    }
  }
`;

export const AccountsInfoContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 6px;
`;