import { useTranslation } from "react-i18next";

// Models
import { BankTransferStatus } from "../../../../Models/Funds";

// Own components
import { Column, Row } from "../../../../Components/Grid";
import Icon from "../../../../Components/Icon";

import {
  BankTransfersDetailsCard,
  CustomText,
} from "../../BankTransfers.styled";
import {
  BankTransfersItem,
  BackTransfersItemBullet,
  BankTransfersItemBulletIcon,
} from "./BankTransfersDescription.styled";

type Props = {
  title: string;
  trackingStatusTitle: string;
  trackingCompletedTitle: string;
  trackingStatusText: string;
  trackingCompletedText: string;
  trackingCompletedDate: string;
  trackingStatusDate: string;
  status: string;
};

export const BankTransfersDescription = ({
  title,
  trackingStatusTitle,
  trackingCompletedTitle,
  trackingStatusText,
  trackingCompletedText,
  trackingStatusDate,
  trackingCompletedDate,
  status,
}: Props) => {
  const [t] = useTranslation("global");

  return (
    <Row>
      <Column span={12} as={BankTransfersDetailsCard} p={2}>
        <Row>
          <Column span={12}>
            <CustomText
              color="black"
              size={1}
              align="left"
              weight={600}
              margin="5px 0 14px 0"
            >
              {title}
            </CustomText>
          </Column>
          <Column span={12}>
            {status === BankTransferStatus.FAILED ? (
              <BankTransfersItem>
                <BankTransfersItemBulletIcon>
                  <Icon icon="errorWarningCircle" size="medium" />
                </BankTransfersItemBulletIcon>
                <CustomText
                  color="error"
                  status={BankTransferStatus.FAILED}
                  size={0.5}
                  align="left"
                  weight={500}
                  margin="2px 0"
                >
                  {t("Funds.BankTransfer.details.errorTitle")}
                </CustomText>
                <CustomText
                  color="error"
                  status={BankTransferStatus.FAILED}
                  size={0.3}
                  align="left"
                  weight={400}
                  margin="2px 0"
                >
                  {t("Funds.BankTransfer.details.errorDescription")}
                </CustomText>
              </BankTransfersItem>
            ) : (
              <>
                <BankTransfersItem>
                  <BackTransfersItemBullet />
                  <CustomText
                    color="neutral_700"
                    size={0.5}
                    align="left"
                    weight={500}
                    margin="2px 0"
                  >
                    {trackingStatusTitle}
                  </CustomText>
                  <CustomText
                    color="grey"
                    size={0.3}
                    align="left"
                    weight={400}
                    margin="2px 0"
                  >
                    {trackingStatusText}
                  </CustomText>
                  <CustomText
                    className="bank-transfer-date"
                    color="grey"
                    size={0.3}
                    align="left"
                    weight={400}
                    margin="2px 0"
                  >
                    {trackingStatusDate}
                  </CustomText>
                </BankTransfersItem>

                <BankTransfersItem
                  {...(status === BankTransferStatus.PROCESSING
                    ? {}
                    : { className: "disabled" })}
                >
                  <BackTransfersItemBullet
                    {...(status === BankTransferStatus.PROCESSING
                      ? { className: "bank-transfer-bullet" }
                      : { className: "bank-transfer-bullet disabled" })}
                  />
                  <CustomText
                    color="neutral_700"
                    size={0.5}
                    align="left"
                    weight={500}
                    margin="2px 0"
                  >
                    {t("Funds.BankTransfer.details.transferProcessing.title")}
                  </CustomText>
                  <CustomText
                    color="grey"
                    size={0.3}
                    align="left"
                    weight={400}
                    margin="2px 0"
                  >
                    {t(
                      "Funds.BankTransfer.details.transferProcessing.description"
                    )}
                  </CustomText>
                  {status === BankTransferStatus.PROCESSING && (
                    <CustomText
                      className="bank-transfer-date"
                      color="grey"
                      size={0.3}
                      align="left"
                      weight={400}
                      margin="2px 0"
                    >
                      {trackingStatusDate}
                    </CustomText>
                  )}
                </BankTransfersItem>

                <BankTransfersItem
                  {...(status !== BankTransferStatus.COMPLETED
                    ? { className: "disabled" }
                    : {})}
                >
                  <BankTransfersItemBulletIcon
                    {...(status !== BankTransferStatus.COMPLETED
                      ? { className: "disabled" }
                      : {})}
                  >
                    <Icon icon="checkCircleGreen" size="medium" />
                  </BankTransfersItemBulletIcon>
                  <CustomText
                    color="neutral_700"
                    size={0.5}
                    align="left"
                    weight={500}
                    margin="2px 0"
                  >
                    {trackingCompletedTitle}
                  </CustomText>
                  <CustomText
                    color="grey"
                    size={0.3}
                    align="left"
                    weight={400}
                    margin="2px 0"
                  >
                    {trackingCompletedText}
                  </CustomText>
                  {trackingCompletedDate && (
                    <CustomText
                      className="bank-transfer-date"
                      color="grey"
                      size={0.3}
                      align="left"
                      weight={400}
                      margin="2px 0"
                    >
                      {trackingCompletedDate}
                    </CustomText>
                  )}
                </BankTransfersItem>
              </>
            )}
          </Column>
        </Row>
      </Column>
    </Row>
  );
};
