import { useSelector } from "react-redux";
import { QuotationSelector } from "@redux/Payments/Quotation/Quotation.slice";
import { BeneficiarySelector } from "@redux/Payments/Beneficiary/Beneficiary.slice";
import { FlexContainer } from "../../Flex/Flex.styled";
import Text from "../../Text";
import { useTranslation } from "react-i18next";
import { ExchangeRateContainer } from "./QuoteSummary.styled";
import IconFont from "../../IconFont";
import { formatNumber } from "@utils/NumberUtils";
import { QuoteSummaryContainer } from "./QuoteSummary.styled";
import { Flags } from "@components/Payments/Flags/Flags.component";
import { SenderExchangeRates } from "@core/Payments/Common/domain/ExchangeRates";
import { useEffect, useMemo } from "react";
import ZelleLogo from "@assets/Img/zelle-rounded.png";
import SquareLogo from "@assets/Img/square-rounded.png";
import PayPalLogo from "@assets/Img/paypal-rounded.png";
import VenmoLogo from "@assets/Img/venmo-rounded.png";
import SendolaPayLogo from "@assets/Img/sendola-pay-short.png";
import AuthorizeLogo from "@assets/Img/authorize-rounded.png";
import {
  SendolaCardPaymentMethod,
  PaymentMethods,
  PaymentMethodType,
  PlaidAccount,
  SquarePaymentMethod,
} from "@core/Payments/Common/domain/PaymentMethods";
import { maskInterbankKey } from "@utils/String";
import { ObjectFitImage } from "@components/Image/Image.styled";
import theme from "../../../Contexts/ThemeProvider";
import { darkenColor } from "@utils/Colors";
import { PaymentMethodTypeTag } from "../PaymentMethodSelect/PaymentRadio/PaymentRadio.styled";
import { CouponBar } from "@components/Payments/CouponBar/CouponBar.component";
import { PaymentOrderCreated } from "@core/Payments/PaymentOrders/domain/PaymentOrderCreated";
import { AlerBoxContainer } from "../../AlertBox/AlertBox.styled";
import { ZelleInstructions } from "./ZelleInstructions.component";
import { useModal } from "@hooks/Payments/useModal";
import { calculateTotalBeforeDiscount, calculateTotalToShow } from "@core/Payments/Quote/domain/QuoteSelected";
import { VenmoInstructions } from "./VenmoInstructions.component";
import { QuoteTotals } from "./QuoteTotals.component";
import { useCountry } from "@hooks/Payments/useCountry";
import { selectorLanguage } from "@redux/Translate";

interface QuoteSummaryProps {
  paymentOrderCreated?: PaymentOrderCreated;
}

export const QuoteSummary = ({ paymentOrderCreated }: QuoteSummaryProps) => {
  const [t] = useTranslation("global");
  const [tSendMoney] = useTranslation("sendMoney");
  const { modal, showModal } = useModal();

  const { language } = useSelector(selectorLanguage);
  const {
    countryDestination,
    currentQuotation,
    currentDeliveryMethodQuote,
    currentPaymentMethod,
    currentPaymentDestination,
    paymentMethodSelected,
    couponToApply,
    currentQuoteDiscounted,
  } = useSelector(QuotationSelector).data;
  const { beneficiarySelected } = useSelector(BeneficiarySelector).data;

  const { countries, getDestinationCountryList } = useCountry();

  const isD2B = currentDeliveryMethodQuote?.deliveryMethodCode === "D2B";
  const isCPU = currentDeliveryMethodQuote?.deliveryMethodCode === "CPU";
  const couponDiscountIsApplied = couponToApply && currentQuoteDiscounted;
  const totalCost = useMemo(
    () =>
      calculateTotalToShow({
        currentTotalCost:
          couponDiscountIsApplied && currentPaymentMethod?.discount
            ? Number(currentPaymentMethod.totalCost) + Number(currentPaymentMethod.discount)
            : currentPaymentMethod?.totalCost,
        currentFee: currentDeliveryMethodQuote?.fee,
        currentQuoteDiscounted,
        discount: currentQuoteDiscounted?.discount,
        quoteDiscount: !couponDiscountIsApplied ? currentPaymentMethod?.discount : null,
      }),
    [currentPaymentMethod?.totalCost, currentDeliveryMethodQuote?.fee, currentQuoteDiscounted]
  );
  const { totalBeforePaymentMethodDiscount, totalDiscounts } = useMemo(
    () => calculateTotalBeforeDiscount(totalCost, currentPaymentMethod?.totalCost, currentPaymentMethod?.discount),
    [totalCost, currentQuoteDiscounted, currentPaymentMethod?.discount, currentPaymentMethod?.totalCost]
  );

  const paymentMethodData = useMemo(() => {
    if (paymentMethodSelected?.paymentSource) {
      const isSendolaPay = paymentMethodSelected?.paymentSource === "Sendola Card";
      const isPlaid = paymentMethodSelected?.paymentSource === "Sendola Plaid";
      const isZelle = paymentMethodSelected?.paymentSource === "Zelle";
      const isSquare = paymentMethodSelected?.paymentSource === "Square";
      return {
        name: getPaymentMethodName(paymentMethodSelected),
        type: (isSendolaPay || isPlaid
          ? "economy"
          : isZelle
            ? "economy-express-minutes"
            : isSquare
              ? "express"
              : "economy-minutes") as PaymentMethodType,
        logo: getLogoFromPaymentMethod(paymentMethodSelected.paymentSource),
        backgroundLogo: isPlaid
          ? (paymentMethodSelected as PlaidAccount)?.backgroundColor
          : isSendolaPay
            ? theme.White
            : undefined,
        gradientBackground: isPlaid ? darkenColor((paymentMethodSelected as PlaidAccount)?.backgroundColor) : undefined,
        logoWidth: isSendolaPay ? "40px" : "32px",
        logoHeight: isSendolaPay ? "40px" : "32px",
        logoPadding: isZelle ? "0px" : "5px",
      };
    }
  }, [paymentMethodSelected]);

  function getLogoFromPaymentMethod(paymentOrigin: PaymentMethods): string | undefined {
    switch (paymentOrigin) {
      case "Zelle":
        return ZelleLogo;
      case "Square":
        return SquareLogo;
      case "PayPal":
        return PayPalLogo;
      case "Venmo":
        return VenmoLogo;
      case "Sendola Plaid":
        return (paymentMethodSelected as PlaidAccount).logoUrl;
      case "Sendola Card":
        return SendolaPayLogo;
      case "authorize.net":
        return AuthorizeLogo;
      default:
        return undefined;
    }
  }

  function getPaymentMethodName(paymentMethod: SendolaCardPaymentMethod | PlaidAccount | SquarePaymentMethod) {
    switch (paymentMethod.paymentSource) {
      case "Sendola Plaid":
        return (paymentMethod as PlaidAccount).bankName;
      case "Sendola Card":
        return "ACH";
      case "authorize.net":
        return t("Authorize.summary");
      default:
        return paymentMethod.paymentSource;
    }
  }

  const openZelleInstructions = () => {
    showModal({
      modalType: "zelleInstructionsModal",
    });
  };

  const openVenmoInstructions = () => {
    showModal({
      modalType: "venmoInstructionsModal",
    });
  };

  useEffect(() => {
    getDestinationCountryList(language);
  }, [language]);

  return (
    <QuoteSummaryContainer direction="column" gap="16px">
      <FlexContainer direction="column" gap="4px">
        <Text size={1} lineHeight="24px" align="left" weight={600} margin={0}>
          {tSendMoney("AmountToSend")}:
        </Text>
        <FlexContainer gap="12px" alignItems="start">
          <Flags>
            <img src={SenderExchangeRates[0].icon} />
            <img src={countries.find((c) => c.countryCode === countryDestination)?.icon} />
          </Flags>
          <FlexContainer direction="column">
            <Text size={1} weight={500} lineHeight="24px">
              {formatNumber(currentQuotation?.amount || 0, { minimumFractionDigits: 2 })}&nbsp;
              {currentQuotation?.originCurrency}
            </Text>
            <Text size={1} weight={600} lineHeight="24px" color="gradientPrimaryBlue" gradient>
              {formatNumber(currentDeliveryMethodQuote?.amountToReceive || 0, { minimumFractionDigits: 2 })}&nbsp;
              {currentQuotation?.destinationCurrency}
            </Text>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <hr />
      <FlexContainer direction="column" gap="8px" p="0 0 12px 0">
        <Text size={1} lineHeight="24px" align="left" weight={600} margin={0}>
          {tSendMoney("RecipientTitle")}:
        </Text>
        <FlexContainer gap="12px" alignItems="center">
          {isD2B && <IconFont name="building-bank" color="primaryStrong" weight={500} size="large" />}
          {isCPU && <IconFont name="hand-coin" color="primaryStrong" weight={500} size="large" />}
          <FlexContainer direction="column">
            <Text size={0.5} weight={500} lineHeight="24px">
              {beneficiarySelected?.fullName}
            </Text>
            <Text size={0.5} weight={500} lineHeight="14px" color="grey">
              {isD2B &&
                `${beneficiarySelected?.accountSelected?.bankName} ${maskInterbankKey(
                  beneficiarySelected?.accountSelected?.accountNumber || ""
                )}`}
              {isCPU && `${currentPaymentDestination?.destination}`}
            </Text>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <hr />
      <FlexContainer
        order={paymentOrderCreated && paymentMethodData?.name === "Zelle" ? -1 : undefined}
        direction="column"
        gap="26px"
        p="5px 0 12px 0"
      >
        <FlexContainer direction="column" gap="8px">
          <FlexContainer justify="space-between" alignItems="center">
            <Text size={1} lineHeight="24px" align="left" weight={600} margin={0}>
              {tSendMoney("PaymentMethod")}
            </Text>
            <PaymentMethodTypeTag type={paymentMethodData?.type}>
              {tSendMoney(`paymentMethods.types.${paymentMethodData?.type}`)}
            </PaymentMethodTypeTag>
          </FlexContainer>
          <FlexContainer gap="12px" alignItems="flex-start">
            <ObjectFitImage
              fit="contain"
              width={paymentMethodData?.logoWidth}
              height={paymentMethodData?.logoHeight}
              src={paymentMethodData?.logo}
              background={paymentMethodData?.backgroundLogo}
              gradientBackground={paymentMethodData?.gradientBackground}
              objectPosition="center"
              padding={paymentMethodData?.logoPadding}
              rounded
            />
            <FlexContainer direction="column" alignSelf="center">
              <Text size={0.5} weight={600} lineHeight="15px">
                {paymentMethodData?.name}
              </Text>
              {paymentOrderCreated && paymentMethodData?.name === "Zelle" ? (
                <ZelleInstructions
                  amount={paymentOrderCreated?.payment?.amount || totalCost}
                  reference={paymentOrderCreated?.mtcn || ""}
                />
              ) : (
                paymentMethodData?.name === "Zelle" && (
                  <FlexContainer alignItems="center" gap="11px" onClick={openZelleInstructions}>
                    <Text
                      weight={600}
                      size="default"
                      lineHeight="15px"
                      align="left"
                      color="ZelleColor"
                      textDecoration="underline"
                    >
                      {tSendMoney("Zelle.howToCta")}
                    </Text>
                    {modal}
                  </FlexContainer>
                )
              )}
              {paymentOrderCreated && paymentMethodData?.name === "Venmo" ? (
                <VenmoInstructions
                  amount={paymentOrderCreated?.payment?.amount || totalCost}
                  reference={paymentOrderCreated?.mtcn || ""}
                />
              ) : (
                paymentMethodData?.name === "Venmo" && (
                  <FlexContainer alignItems="center" gap="11px" onClick={openVenmoInstructions}>
                    <Text
                      weight={600}
                      size="default"
                      lineHeight="15px"
                      align="left"
                      color="VenmoColor"
                      textDecoration="underline"
                    >
                      {tSendMoney("Venmo.howToCta")}
                    </Text>
                    {modal}
                  </FlexContainer>
                )
              )}
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <hr />
      </FlexContainer>
      <FlexContainer alignItems="baseline" justify="space-between" p="4px 8px 4px 8px" as={ExchangeRateContainer}>
        <Text size={0.5} lineHeight="21px" align="left" weight={600} margin={0}>
          {tSendMoney("ExchangeRate")}
        </Text>
        <Text size={0.5} lineHeight="21px" align="right" weight={600} margin={0}>
          $1.00 USD = $
          {formatNumber(currentDeliveryMethodQuote?.fx || 0, { minimumFractionDigits: 4, maximumFractionDigits: 4 })}{" "}
          {currentQuotation?.destinationCurrency}
        </Text>
      </FlexContainer>
      {(paymentMethodSelected?.paymentSource === "Zelle" || paymentMethodSelected?.paymentSource === "Venmo") && (
        <FlexContainer m="-8px 0 0 0">
          <AlerBoxContainer>
            <FlexContainer justify="center" alignItems="center" gap="8px">
              <IconFont name="alert" color="warningStrong" weight={500} />
              <Text align="left" size="default" lineHeight="14px" weight={500} color="warningStrong">
                {paymentMethodSelected?.paymentSource === "Zelle"
                  ? tSendMoney("Zelle.expirationDisclaimer")
                  : tSendMoney("Venmo.expirationDisclaimer")}
              </Text>
            </FlexContainer>
          </AlerBoxContainer>
        </FlexContainer>
      )}
      <QuoteTotals
        transferFee={currentQuoteDiscounted?.fee ?? (currentDeliveryMethodQuote?.fee || 0)}
        previousTransferFee={
          couponDiscountIsApplied && currentQuoteDiscounted?.fee !== currentDeliveryMethodQuote?.fee
            ? currentDeliveryMethodQuote?.fee
            : undefined
        }
        paymentMethodFee={currentQuoteDiscounted?.paymentMethodFee ?? (currentPaymentMethod?.paymentMethodFee || 0)}
        previousPaymentMethodFee={
          couponDiscountIsApplied && currentQuoteDiscounted?.paymentMethodFee !== currentPaymentMethod?.paymentMethodFee
            ? currentPaymentMethod?.paymentMethodFee
            : undefined
        }
        paymentMethodDiscount={!couponDiscountIsApplied ? currentPaymentMethod?.discount || 0 : 0}
        total={totalCost}
        previousTotal={
          couponDiscountIsApplied && currentQuoteDiscounted?.discount && totalCost !== currentPaymentMethod?.totalCost
            ? currentPaymentMethod?.totalCost
            : !couponDiscountIsApplied &&
                currentPaymentMethod?.discount &&
                totalBeforePaymentMethodDiscount !== totalCost
              ? totalBeforePaymentMethodDiscount
              : 0
        }
        totalDiscounted={
          couponDiscountIsApplied && currentQuoteDiscounted?.discount && totalCost !== currentPaymentMethod?.totalCost
            ? currentQuoteDiscounted?.discount
            : !couponDiscountIsApplied &&
                currentPaymentMethod?.discount &&
                totalBeforePaymentMethodDiscount !== totalCost
              ? totalDiscounts
              : 0
        }
      />
      {couponToApply && <CouponBar couponCode={couponToApply.code || "REF-FRIENDS"} />}
    </QuoteSummaryContainer>
  );
};
