import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { defaultErrorMessage } from "../../Constants/Requests";
import { GET_PERSONS } from "../../Constants/Persons";
import { AxiosError } from "axios";

export const GetPersonsAction = createAsyncThunk(
  GET_PERSONS,
  async (payload:string, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(`/persons/${payload}`);
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data.message);
      }

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
