import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";

const initialState = {
  nationality: null
};

export const NationalitySlice = createSlice({
  name: "nationality",
  initialState,
  reducers: {
    updateNationality: (state, action) => {
      state.nationality = action.payload;
    },
  },
});

export const { updateNationality } = NationalitySlice.actions;
export const NationalitySelector = (state: State) => state.nationality;

export default NationalitySlice.reducer;
