import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import isEmpty from "lodash.isempty";

// services store
import { selectorLanguage } from "@redux/Translate";
import { AppDispatch, State } from "@redux/Store";
import { ByUserSliceSelector } from "@redux/ByUser/ByUser.slice";
import { getKycStatusSelector } from "@redux/GetKYCStatus/GetKYCStatus.slice";
import { PrefilledDataSelector } from "@redux/PrefilledData/PrefilledData.slice";
import { getUserFeature } from "@redux/UserFeatures/UserFeatures.actions";
import { PlaidAccountAction } from "@redux/FundsAccount/FundsAccount.actions";
import { changeTypeAccount } from "@redux/SelectTypeAccount";
import { getCoppelAccess } from "@redux/CoppelAccess/CoppelAccess.actions";
import { updateCoppelAccess } from "@redux/UpdateCoppelAccess/UpdateCoppelAccess.actions";

// components
import Title from "@components/Title";
import { Column, Container, Row } from "@components/Grid";
import Layout from "@components/Layout";
import Icon from "@components/Icon";
import CoppelCardAccount from "@components/CoppelCardAccount";
import NotificationModal from "@components/NotificationModal";
import LoaderIcon from "@components/LoaderIcon";
import { BalanceCard, BannerBankBalanceCard } from "@components/BalanceCard";
import LinkingCard from "@components/LinkingCard";
import LinkedAccountCard from "@components/LinkedAccountCard";
import PayrollSetting from "@components/PayrollSetting";
import LinkedAccountDeleteModal from "@components/LinkedAccountCard/LinkedAccountDeleteModal.component";
import CoppelCard from "@components/CoppelCard";
import PlaidLink from "../Funds/Components/PlaidLink";
import Text from "@components/Text";

// Models
import { JustifyContent } from "@models/Column";
import { PlaidAccounts, PlaidAccountsResponse } from "@models/Funds";

// Styles
import { LinkAccount, LinkedAccountLayout, LinkedAccountsContainer } from "./LinkedAccounts.styled";

// Assets
import PuertoRicoAlertImage from "@assets/Img/puertoRicoAlert.png";

// Constants
import { EN } from "@constants/Langs";
import {
  COPPEL_ACCESS_ACCOUNT_FEATURE,
  DIRECT_DEPOSIT_REQUEST_FEATURE,
  PLAID_GLOBAL_PAYMENTS_FEATURE,
} from "@constants/Features";
import { GET_USER_FEATURE_FULFILLED } from "@constants/user";
import { GET_COPPEL_STATUS_FULFILLED } from "@constants/CoppelAccess";
import {
  CoppelAccessCelularExternalServices,
  CoppelAccessCelularExternalServicesEn,
  CoppelAccessLinkEN,
  CoppelAccessLinkES,
  OpenCoppelAccess,
} from "@constants/LinksExternals";
import { KYC_APPROVED } from "@constants/KYCStatus";
import { WhatsAppLinkEn, WhatsAppLinkEs } from "@constants/ConfigureApi";
import { GET_PLAID_ACCOUNT_FULFILLED } from "@constants/FundsAccount";

import { useLinkedAccounts } from "@hooks/useLinkedAccounts";
import { FlexContainer } from "@components/Flex/Flex.styled";

const LinkedAccounts = () => {
  const [t] = useTranslation("global");
  const lang = useSelector(selectorLanguage);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const kycStatusSelector = useSelector(getKycStatusSelector);
  const PrefilledData = useSelector(PrefilledDataSelector);
  const [PlaidGlobalPaymentsFeature, setPlaidGlobalPaymentsFeature] = useState<boolean>(false);
  const [CoppelAccessAccountFeature, setCoppelAccessAccountFeature] = useState<boolean>(false);
  const [isOpenDirectDepositModal, setIsOpenDirectDepositModal] = useState<boolean>(false);
  const [ledgerAccountsID, setLedgerAccountsID] = useState<string[]>([]);
  const [isShowLinkedAccountDeleteModal, setShowLinkedAccountDeleteModal] = useState<boolean>(false);
  const [DirectDepositFeature, setDirectDepositFeature] = useState<boolean>(true);
  const [openPueroRicoAlert, setOpenPueroRicoAlert] = useState<boolean>(false);
  const [isLinkedAccountsLoading, setLinkedAccountsIsLoading] = useState<boolean>(true);
  const [linkedAccounts, setLinkedAccounts] = useState<PlaidAccountsResponse>();
  const [CoppelAccessState, setCoppelAccessState] = useState("");
  const [currentLinkedAccount, setCurrentLinkedAccount] = useState<string>();
  const [isOpenPlaid, setIsOpenPlaid] = useState<boolean>(false);
  const [isUpdatedBannerBankAccount, setUpdateBannerBankAccount] = useState<boolean>(false);

  const theme = useSelector((state: State) => state.theme);
  const ByUser = useSelector(ByUserSliceSelector);

  const userId = useMemo(() => ByUser.data?.userId || "", [ByUser]);
  const personId = useMemo(() => ByUser.data?.personId || "", [ByUser]);
  const userPhoneNumber = useMemo(() => PrefilledData.data?.phone, [PrefilledData]);
  const urlCoppelAccessCelular =
    lang.language === EN ? CoppelAccessCelularExternalServicesEn : CoppelAccessCelularExternalServices;
  const kycStatus = useMemo(() => kycStatusSelector.data?.status || "", [kycStatusSelector]);
  const subdivision = useMemo(() => PrefilledData.data?.address?.countrySubdivision || "", [PrefilledData]);

  const urlSeeAccount = lang.language === EN ? CoppelAccessLinkEN : CoppelAccessLinkES;
  const urlWhatsAppSupport = lang.language === EN ? WhatsAppLinkEn : WhatsAppLinkEs;

  const { getAccounts, accounts, isLoading } = useLinkedAccounts(personId);

  useEffect(() => {
    if (userId) {
      GetUserFeature(userId, PLAID_GLOBAL_PAYMENTS_FEATURE, setPlaidGlobalPaymentsFeature);
      GetUserFeature(userId, DIRECT_DEPOSIT_REQUEST_FEATURE, (active: boolean) => {
        setDirectDepositFeature(active);
        setIsOpenDirectDepositModal(active);
      });
      GetUserFeature(userId, COPPEL_ACCESS_ACCOUNT_FEATURE, setCoppelAccessAccountFeature);

      getAccounts();
      getStatusCoppelAccess(personId);
      getLinkedAccounts();
    }
  }, []);

  useEffect(() => {
    if (linkedAccounts && linkedAccounts.accountsLedger.length > 0) {
      const ledgerAccounts: string[] = [];

      linkedAccounts.accountsLedger.map((ledger) => {
        if (!ledger.activeToken) {
          ledgerAccounts.push(ledger.id);
        }
      });
      setLedgerAccountsID(ledgerAccounts);
    }
  }, [linkedAccounts]);

  const GetUserFeature = async (userId: string, featureCode: string, setUserFeature: (active: boolean) => void) => {
    const request = {
      userId: userId,
      featureCode: featureCode,
    };
    const response = await dispatch(getUserFeature(request));

    if (response?.type === GET_USER_FEATURE_FULFILLED) {
      setUserFeature(response?.payload?.isActive);
    }
  };

  const getStatusCoppelAccess = async (personId: string) => {
    const response = await dispatch(getCoppelAccess(personId));

    if (response?.type === GET_COPPEL_STATUS_FULFILLED) {
      setCoppelAccessState(response.payload.status);
    }
  };

  const selectTypeAccount = (type: string, idAccount: string) => {
    const payload = {
      type: type,
      id: idAccount,
    };
    dispatch(changeTypeAccount(payload));
    navigate("/manage-card-account");
  };

  const showNotificationAlert = () => {
    setOpenPueroRicoAlert(true);
  };

  const getLinkedAccounts = async () => {
    setLinkedAccountsIsLoading(true);

    const response = await dispatch(PlaidAccountAction(lang.language));

    if (response?.type === GET_PLAID_ACCOUNT_FULFILLED) {
      setLinkedAccounts(response.payload);
    }

    setLinkedAccountsIsLoading(false);
  };

  const getUnLinkedLedgerAccount = (
    currentLedgerAccountId: string,
    plaidAccounts: Array<PlaidAccounts>
  ): boolean | undefined => {
    let unlikedLedgerAccount = undefined;

    if (plaidAccounts?.length > 0 && !isEmpty(currentLedgerAccountId)) {
      unlikedLedgerAccount = plaidAccounts.some(
        (account) => account.id === currentLedgerAccountId && !account.activeToken
      );
      if (unlikedLedgerAccount) {
        setCurrentLinkedAccount(currentLedgerAccountId);
      }

      return unlikedLedgerAccount;
    }
    return undefined;
  };

  const onOpenCoppelAccount = async () => {
    if (personId) {
      window.open(OpenCoppelAccess, "_blank", "noopener,noreferrer");
      return await dispatch(updateCoppelAccess(personId));
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {/* Layout of loading */}
      <Layout show={isLoading || isLinkedAccountsLoading} showNavigation>
        <LoaderIcon
          title={t("loaderText.title")}
          text={t("loaderText.subtitle")}
          description={t("loaderText.description")}
        />
      </Layout>
      <PayrollSetting
        show={!isLoading && !isLinkedAccountsLoading && DirectDepositFeature && isOpenDirectDepositModal}
        onClose={() => setDirectDepositFeature(false)}
        onCloseModal={() => setIsOpenDirectDepositModal(false)}
      />
      <LinkedAccountLayout>
        <Layout show={!isLoading} path="/" as={LinkedAccountLayout} showNavigation>
          <Container as={LinkedAccountsContainer}>
            <Row>
              <Column span={12} mt={1} justifyContent={JustifyContent.center}>
                <Title tagName="h1" text={t("HomePage.accountTitle")} size={2} color="black" align="left" />
              </Column>
              <Column span={12} mt={3} justifyContent={JustifyContent.center}>
                {PlaidGlobalPaymentsFeature && (
                  <LinkAccount
                    to="/funds-capture-check-instructions"
                    state={{ firstAccount: true, backUrl: "/linked-accounts", homePage: true }}
                    replace
                  >
                    <Icon icon="plus" size="small" color="Primary01" />
                    <span>{t("LinkedAccounts.linkAccounts")}</span>
                  </LinkAccount>
                )}
              </Column>
              <Column span={12} mt={3}>
                { accounts?.length === 0 && (linkedAccounts && !linkedAccounts?.hasAccounts) && (
                  <Row>
                    <Column span={12} m={6}>
                      <FlexContainer direction="column" alignItems="center">
                        <Icon icon="bankAccount" color="black" size="xlarge" fillIcon={false} />
                        <Text size={1} align="center" color="black" weight={400} margin="20px 0 4px 0">
                          {t("LinkedAccounts.notLinkedAccounts")}
                        </Text>
                      </FlexContainer>
                    </Column>
                  </Row>
                )}

                {accounts.length > 0 && (
                  <>
                    {accounts.map((account) => (
                      <>
                        {CoppelAccessAccountFeature &&
                          CoppelAccessState === "accountOpened" &&
                          account.sponsorBank === "Community Federal Savings Bank" && (
                            <CoppelCardAccount
                              key={`coppel-${account.id}`}
                              status="active"
                              message={t("CoppelAccess.activeMessage")}
                              amount={account.availableBalance}
                              showBalance={account?.availableBalance ? true : false}
                              accountNumber={account.accountNumber}
                              routingNumber={account.routingNumber}
                              logoUrlBank={account.logoUrlBank}
                              onClickBtn={() => window.open(urlSeeAccount, "_blank", "noopener,noreferrer")}
                            />
                          )}

                        {kycStatus === KYC_APPROVED && account.sponsorBank !== "Banner Bank" && (
                          <BalanceCard
                            key={`balance-${account.id}`}
                            title={
                              account.type === "personalChecking"
                                ? t("balanceCard.checkAccount")
                                : t("balanceCard.savingsAccount")
                            }
                            accountNumber={account.accountNumber}
                            amount={account.availableBalance}
                            subtitle={t("balanceCard.availableBalance")}
                            showAlert={false}
                            showButton={false}
                            buttonText={t("balanceCard.btnAddFunds")}
                            buttonDisabled={false}
                            active={account.status === "active" ? true : false}
                            selectTypeAccount={() => selectTypeAccount(account.type, account.id)}
                            sponsorBank={account.sponsorBank}
                            logoUrlBank={account.logoUrlBank}
                            type={account.type}
                            countrySubdivision={subdivision}
                            handleShowNotification={showNotificationAlert}
                          />
                        )}

                        {kycStatus === KYC_APPROVED &&
                          account.sponsorBank === "Banner Bank" &&
                          PlaidGlobalPaymentsFeature && (
                            <BannerBankBalanceCard
                              key={`banner-bank-${account.id}`}
                              isLinkedAccount={account?.isLinkedPlaid}
                              isRelinkedAccount={ledgerAccountsID.includes(account?.sendolaPlaidAccountId || "")}
                              isUpdatedAccount={isUpdatedBannerBankAccount}
                              title={
                                account.type === "personalChecking"
                                  ? t("balanceCard.checkAccount")
                                  : t("balanceCard.savingsAccount")
                              }
                              accountNumber={account.accountNumber}
                              amount={account.availableBalance}
                              subtitle={t("balanceCard.availableBalance")}
                              showAlert={false}
                              showButton={false}
                              buttonText={t("balanceCard.btnAddFunds")}
                              buttonDisabled={false}
                              active={false}
                              selectTypeAccount={() => selectTypeAccount(account.type, account.id)}
                              sponsorBank={account.sponsorBank}
                              logoUrlBank={account.logoUrlBank}
                              type={account.type}
                              hasDirectDeposit={DirectDepositFeature}
                              onDirectDeposit={() => {
                                setIsOpenDirectDepositModal(true);
                              }}
                              onOpenPlaid={() => {
                                const unlinkedLedgerAccount = getUnLinkedLedgerAccount(
                                  account.sendolaPlaidAccountId || "",
                                  linkedAccounts?.accountsLedger || []
                                );
                                if (unlinkedLedgerAccount) {
                                  setCurrentLinkedAccount(account?.sendolaPlaidAccountId || "");
                                  return setIsOpenPlaid(true);
                                }
                              }}
                              onLinkAccount={() =>
                                navigate("/funds-capture-check-instructions", {
                                  state: {
                                    firstAccount: true,
                                    homePage: true,
                                    linkCustomName: lang.language === "en" ? "bannerbank" : "bannerbankspanish",
                                    accountId: account.id,
                                  },
                                })
                              }
                              countrySubdivision={subdivision}
                              handleShowNotification={showNotificationAlert}
                            />
                          )}
                      </>
                    ))}
                  </>
                )}

                <>
                  { linkedAccounts &&
                    !linkedAccounts?.hasAccounts &&
                    PlaidGlobalPaymentsFeature && <LinkingCard key={`empty-0`} />}

                  {linkedAccounts && linkedAccounts?.hasAccounts && (
                    <>
                      {linkedAccounts?.accounts.map((account) => (
                        <LinkedAccountCard
                          key={`linked-account-${account.id}`}
                          id={account.id}
                          amount={account?.available}
                          isUpdatedAccount={isUpdatedBannerBankAccount}
                          bankName={account.bankName}
                          accountNumber={account.accountLastFour}
                          routingNumber={account.routingNumber}
                          bgColor={account.backgroundColor}
                          logoUrl={account.logoUrl}
                          isActive={account.activeToken}
                          type={account.subtype}
                          onShowDeleteModal={() => setShowLinkedAccountDeleteModal(!isShowLinkedAccountDeleteModal)}
                          onOpenPlaid={() => setIsOpenPlaid(true)}
                          selectAccount={setCurrentLinkedAccount}
                        />
                      ))}
                    </>
                  )}
                </>

                {CoppelAccessAccountFeature && (
                  <>
                    {(CoppelAccessState === null || CoppelAccessState === "onboardingStarted") && (
                      <CoppelCard
                        isUsaPhoneNumber={userPhoneNumber && userPhoneNumber.startsWith("+1") ? true : false}
                        key={"status-0"}
                        status="open"
                        message={
                          userPhoneNumber && userPhoneNumber.startsWith("+1")
                            ? t("CoppelAccess.openMessageUS")
                            : t("CoppelAccess.openMessage")
                        }
                        {...(userPhoneNumber && userPhoneNumber.startsWith("+1")
                          ? {}
                          : { link: urlCoppelAccessCelular })}
                        onClickBtn={() => onOpenCoppelAccount()}
                      />
                    )}
                  </>
                )}
              </Column>
            </Row>
          </Container>

          {/* Linked Account - Open Plaid to Re-link */}
          {isOpenPlaid && (
            <PlaidLink
              requiredUpdate={true}
              updateData={{
                redirectUri: "https://prueba.example.com",
                accountId: currentLinkedAccount || "",
              }}
              isOpenAutomatic={isOpenPlaid}
              btnVariant="secondary"
              btnText={t("LinkedAccounts.link")}
              onClose={() => {
                setIsOpenPlaid(false);
                setCurrentLinkedAccount("");
              }}
              onCompleted={(_, isUpdated) => {
                setIsOpenPlaid(false);
                getLinkedAccounts();
                setCurrentLinkedAccount("");

                if (isUpdated) {
                  setUpdateBannerBankAccount(true);
                }
              }}
            />
          )}

          {/* Linked Account Delete Modal */}
          <LinkedAccountDeleteModal
            accountId={currentLinkedAccount || ""}
            isShowModal={isShowLinkedAccountDeleteModal}
            onShowModal={() => setShowLinkedAccountDeleteModal(!isShowLinkedAccountDeleteModal)}
            onDeleteCompleted={() => getLinkedAccounts()}
          />

          {/* Open Puerto Rico */}
          <NotificationModal
            show={openPueroRicoAlert}
            modalName="modal-open-puerto-rico"
            image={PuertoRicoAlertImage}
            hasSupport
            hideSubmit
            urlSupport={urlWhatsAppSupport}
            title={t("HomePage.GlobalPaymentsQuoter.notificationModal.title")}
            paragraph1={t("HomePage.GlobalPaymentsQuoter.notificationModal.subtitle")}
            btnText={t("buttons.submit")}
            handleActionButton={() => ""}
            handleClose={() => setOpenPueroRicoAlert(false)}
            textAlignTitle="center"
            textAlignparagraph1="center"
          />
        </Layout>
      </LinkedAccountLayout>
    </ThemeProvider>
  );
};

export default LinkedAccounts;
