import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { defaultErrorMessage } from "../../Constants/Requests";
import { GET_PROOF_ADDRESS_REQUIRED } from "../../Constants/ProofOfAddress";

export const GetProofAddressRequired = createAsyncThunk(
  GET_PROOF_ADDRESS_REQUIRED,
  async (payload:string, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(`/persons/${payload}/documents/proof-address/is-required`);
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
