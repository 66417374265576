/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useTranslation } from "react-i18next";
import Text from "../../Text";
import { FlexContainer } from "../../Flex/Flex.styled";
import { useSelector } from "react-redux";
import { QuotationSelector } from "@redux/Payments/Quotation/Quotation.slice";
import {
  isSendolaCardPaymentMethod,
  isPlaidAccount,
  PayPalPaymentMethod,
  SquarePaymentMethod,
  PaymentMethod,
  ZellePaymentMethod,
} from "@core/Payments/Common/domain/PaymentMethods";
import { useFeature } from "@hooks/Payments/useFeature";
import { useEffect, useMemo, useRef } from "react";
import { AuthorizePaymentRadio, PaymentRadio, SendolaPaymentRadio } from "./PaymentRadio/PaymentRadio.component";
import ZelleLogo from "@assets/Img/zelle-rounded.png";
import SquareLogo from "@assets/Img/square-rounded.png";
import PayPalLogo from "@assets/Img/paypal-rounded.png";
import VenmoLogo from "@assets/Img/venmo-rounded.png";
import AuthorizeLogo from "@assets/Img/authorize-rounded.png";
import isEmpty from "lodash.isempty";
import Link from "../../Link";
import { useModal } from "@hooks/Payments/useModal";

interface PaymentMethodSelectProps {
  onSelect?: (paymentMethod: PaymentMethod) => void;
}

export const PaymentMethodSelect = ({ onSelect }: PaymentMethodSelectProps) => {
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const radioRef = useRef<Array<HTMLInputElement>>([]);
  const [tSendMoney] = useTranslation("sendMoney");
  const { paymentMethods, paymentMethodSelected, currentDeliveryMethodQuote } = useSelector(QuotationSelector).data;
  const {
    isLoading,
    squarePaymentMethodFeature,
    paypalPaymentMethodFeature,
    venmoPaymentMethodFeature,
    authorizePaymentMethodFeature,
  } = useFeature();
  const { modal, showModal } = useModal();

  const quotePaymentMethods = useMemo(
    () => new Map(currentDeliveryMethodQuote?.paymentMethods?.map((pm) => [pm.paymentOrigin, pm]) || []),
    [currentDeliveryMethodQuote]
  );

  const selectPaymentMethod = (paymentMethod: PaymentMethod) => {
    if (isSendolaCardPaymentMethod(paymentMethod)) {
      openPlaidAccountsSelection(onSelect);
    } else if (onSelect) {
      onSelect(paymentMethod);
    }
  };

  const openPlaidAccountsSelection = (callback?: (data: PaymentMethod) => void) => {
    const value =
      isPlaidAccount(paymentMethodSelected) || isSendolaCardPaymentMethod(paymentMethodSelected)
        ? paymentMethodSelected
        : undefined;
    const plaidAccounts = paymentMethods?.plaid?.accounts?.filter((acc) => acc.isVisible) || [];
    const sendolaAccounts = paymentMethods?.sendolaPay?.filter((acc) => acc.isVisible) || [];
    showModal({
      modalType: "selectPlaidAccountModal",
      accounts: [...sendolaAccounts, ...plaidAccounts],
      value,
      handleConfirm(data) {
        if (callback) {
          callback(data as PaymentMethod);
        }
      },
    });
  };

  const openZelleInstructions = () => {
    showModal({
      modalType: "zelleInstructionsModal",
    });
  };

  const openVenmoInstructions = () => {
    showModal({
      modalType: "venmoInstructionsModal",
    });
  };

  useEffect(() => {
    sliderRef.current?.scrollTo(0, 0);
    if (!isLoading && !isEmpty(paymentMethods) && !isEmpty(radioRef.current)) {
      const radioSelected = paymentMethodSelected
        ? radioRef.current.find((radio) => paymentMethodSelected.paymentSource === radio.value) || radioRef.current[0]
        : radioRef.current[0];
      radioSelected?.click();
    } else {
      radioRef.current[0]?.click();
      selectPaymentMethod({
        name: "Zelle",
        description: "Zelle",
        paymentSource: "Zelle",
      } as ZellePaymentMethod);
    }
  }, [isLoading, paymentMethods, radioRef.current]);

  return (
    <FlexContainer direction="column" style={{ position: "relative" }}>
      <FlexContainer direction="column" gap="1rem">
        <PaymentRadio
          type="economy-express-minutes"
          forwardRef={(el) => {
            if (el) {
              radioRef.current.push(el);
            }
          }}
          value="Zelle"
          logo={ZelleLogo}
          title={tSendMoney("paymentMethods.zelle.title")}
          discount={quotePaymentMethods.get("Zelle")?.discount}
          fee={quotePaymentMethods.get("Zelle")?.paymentMethodFee}
          mostPopular
          onChange={() => {
            selectPaymentMethod({
              name: "Zelle",
              description: "Zelle",
              paymentSource: "Zelle",
            } as ZellePaymentMethod);
          }}
        >
          <Link href="#" onClick={openZelleInstructions}>
            <Text
              weight={600}
              size="default"
              lineHeight="15px"
              align="left"
              color="ZelleColor"
              textDecoration="underline"
              margin={0}
            >
              {tSendMoney("Zelle.howToCta")}
            </Text>
          </Link>
          {quotePaymentMethods.get("Zelle")?.discount && (
            <Text size={0.1} weight={400} align="left">
              {tSendMoney("DiscountNotCombine")}
            </Text>
          )}
        </PaymentRadio>
        {(!isEmpty(paymentMethods?.plaid?.accounts) || !isEmpty(paymentMethods?.sendolaPay)) && (
          <SendolaPaymentRadio
            type="economy"
            value="Sendola Card"
            forwardRef={(el) => {
              if (el) {
                radioRef.current.push(el);
              }
            }}
            title={tSendMoney("paymentMethods.sendolaPay.title")}
            fee={quotePaymentMethods.get("Sendola Card")?.paymentMethodFee}
            onChange={() => {
              selectPaymentMethod({
                paymentSource: "Sendola Card",
              } as SquarePaymentMethod);
            }}
          />
        )}
        {authorizePaymentMethodFeature && (
          <AuthorizePaymentRadio
            type="economy-express"
            forwardRef={(el) => {
              if (el) {
                radioRef.current.push(el);
              }
            }}
            value="authorize.net"
            logo={AuthorizeLogo}
            title={tSendMoney("paymentMethods.authorize.title")}
            fee={quotePaymentMethods.get("authorize.net")?.paymentMethodFee}
            description={tSendMoney("paymentMethods.authorize.description")}
            onChange={() => {
              selectPaymentMethod({
                name: "authorize",
                description: "authorize.net",
                paymentSource: "authorize.net",
              } as SquarePaymentMethod);
            }}
          />
        )}
        {squarePaymentMethodFeature && (
          <PaymentRadio
            type="express"
            forwardRef={(el) => {
              if (el) {
                radioRef.current.push(el);
              }
            }}
            value="Square"
            logo={SquareLogo}
            title={tSendMoney("paymentMethods.square.title")}
            fee={quotePaymentMethods.get("Square")?.paymentMethodFee}
            description={tSendMoney("paymentMethods.square.description")}
            showBankSupported
            onChange={() => {
              selectPaymentMethod({
                name: "Square",
                description: "Square Link",
                paymentSource: "Square",
              } as SquarePaymentMethod);
            }}
          />
        )}
        {paypalPaymentMethodFeature && (
          <PaymentRadio
            type="express"
            forwardRef={(el) => {
              if (el) {
                radioRef.current[2] = el;
              }
            }}
            value="PayPal"
            logo={PayPalLogo}
            title={tSendMoney("paymentMethods.paypal.title")}
            fee={quotePaymentMethods.get("PayPal")?.paymentMethodFee}
            description={tSendMoney("paymentMethods.paypal.description")}
            showBankSupported
            onChange={() => {
              selectPaymentMethod({
                name: "PayPal",
                description: "PayPal button",
                paymentSource: "PayPal",
              } as PayPalPaymentMethod);
            }}
          />
        )}
        {venmoPaymentMethodFeature && (
          <PaymentRadio
            type="economy-express"
            forwardRef={(el) => {
              if (el) {
                radioRef.current[2] = el;
              }
            }}
            value="Venmo"
            logo={VenmoLogo}
            title={tSendMoney("paymentMethods.venmo.title")}
            fee={quotePaymentMethods.get("Venmo")?.paymentMethodFee}
            onChange={() => {
              selectPaymentMethod({
                name: "Venmo",
                description: "Venmo button",
                paymentSource: "Venmo",
              } as PayPalPaymentMethod);
            }}
          >
            <Link href="#" onClick={openVenmoInstructions}>
              <Text
                weight={600}
                size="default"
                lineHeight="15px"
                align="left"
                color="VenmoColor"
                textDecoration="underline"
              >
                {tSendMoney("Venmo.howToCta")}
              </Text>
            </Link>
          </PaymentRadio>
        )}
        {modal}
      </FlexContainer>
    </FlexContainer>
  );
};
