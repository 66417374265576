import { defaultErrorMessage } from "../../Constants/Requests";
import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { DocumentsPayload } from "../../Models/Documents";
import { VISA_DOCUMENT } from "../../Constants/Visa";

export const VisaPhotoVeriffAction = createAsyncThunk(
  VISA_DOCUMENT,
  async (payload:DocumentsPayload, thunkAPI) => {
    try {
      const headers = {
        'content-type': 'multipart/form-data',
      };
      const response = await BaseConfigApi.post(`/persons/${payload.personId}/visa-veriff`, payload.data, { headers: headers });
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
