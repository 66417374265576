import { ReactNode, useState } from "react";

// Components
import Text from "@components/Text";
import Icon from "@components/Icon";
import { FlexContainer, FlexItem } from "@components/Flex/Flex.styled";

import { Dropdown } from "./QuoteSummary.styled";
import Button from "../Button";

type Props = {
  anchorText: string;
  anchorAmountText: string;
  children: ReactNode;
  isOpen?: boolean;
};

const QuoterSummaryDropdown = ({ children, anchorText, anchorAmountText, isOpen = false }: Props) => {
  const [isShow, setShow] = useState(isOpen);
  return (
    <FlexContainer direction="column" justify="space-between">
      <Button variant="transparent" sizeButton="full" padding="0" onClick={() => setShow(!isShow)}>
        <FlexContainer alignItems="baseline" justify="space-between">
          <Text size={0.5} lineHeight="18px" weight={500} color="grey" margin={0}>
            {anchorText}
          </Text>
          <FlexItem alignItems="center" gap="3px">
            <Text size={0.5} lineHeight="18px" weight={500} margin={0}>
              {anchorAmountText}
            </Text>
            <Icon
              icon={isShow ? "chevronUp" : "chevronDown"}
              fillIcon={false}
              size="medium"
              onClick={() => setShow(!isShow)}
            />
          </FlexItem>
        </FlexContainer>
      </Button>
      <Dropdown $show={isShow}>{children}</Dropdown>
    </FlexContainer>
  );
};

export default QuoterSummaryDropdown;
