import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";

const initialState = {
  show: true
};

export const VenmoModalSlice = createSlice({
  name: "venmoModal",
  initialState,
  reducers: {
    updateVenmoModal: (state, action) => {
      state.show = action.payload;
    },
  },
});

export const { updateVenmoModal } = VenmoModalSlice.actions;
export const VenmoModalSelector = (state: State) => state.venmoModal;

export default VenmoModalSlice.reducer;