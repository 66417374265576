import { Column, FillerContainer, Row } from "../Grid";
import Text from "../Text";
import {
  Spinner,
  IconLoader,
  LoaderContent
} from "./LoaderIcon.styled";

export type tOpenAccountLoaderProps = {
  title?: string;
  text?: string;
  description?: string;
};

import Diamond from "../../Assets/Img/DiamondsFour.png";

const LoaderIcon = (props: tOpenAccountLoaderProps) =>{
  const { title, text, description } = props;

  return (
    <FillerContainer>
      <Row>
        {title && (
          <>
            <Column span={12} mt={2}>
              <Text tagName="p" color="black" align="center" weight="600" size={2}>
                {title}
              </Text>
            </Column>
            <Column span={12} mb={5}>
              <Text tagName="p" color="black" align="center" weight="400" size={1}>
                {text}
              </Text>
            </Column>
          </>
        )}
        <Column span={12} as={LoaderContent} my={5}>
          <IconLoader>
            <img src={Diamond} alt="" />
          </IconLoader>
          <Spinner />
        </Column>
        <Column span={12} my={5}>
          <Text tagName="p" color="black" align="center" weight="500" size={0.5}>
            {description}
          </Text>
        </Column>
      </Row>
    </FillerContainer>
  );
}

export default LoaderIcon;
