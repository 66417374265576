import { useMemo, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isAndroid, isIOS } from "react-device-detect";

import { selectorLanguage } from "../../Redux/Translate";

import { BalanceCardProps } from "../../Models/BalanceCard";
import { JustifyContent } from "../../Models/Column";

import { FormatNumberToThousands } from "../../Utilities/FormatNumberToThousands";

import { ZelleBannerEN, ZelleBannerES } from "../../Constants/LinksExternals";
import { CHECKING } from "../../Constants/Account";

import Text from "../Text";
import { Flex } from "../Flex";
import Button from "../Button";
import RoundButton from "../RoundButton";
import { Column, Row } from "../Grid";

import {
  AlertBackground,
  AlertContainer,
  BalanceContainer,
  BalanceLink,
  Subtitle,
  ContainerButton,
  ContainerBalance,
  Details,
  TextInfo,
} from "./BalanceCard.styled";

import USI from "../../Assets/Img/usi.png";

const BalanceCard = ({
  title,
  amount,
  subtitle,
  href,
  showAlert = false,
  alertText = "",
  showButton,
  buttonText,
  buttonDisabled = false,
  onClick,
  alertClick,
  selectTypeAccount,
  active,
  accountNumber,
  logoUrlBank,
  sponsorBank,
  type,
  countrySubdivision,
  handleShowNotification,
}: BalanceCardProps) => {
  const [roundBalance, centsBalance]: string[] = useMemo(() => {
    if (amount) {
      const stringAmount = amount.toFixed(2);
      return stringAmount.split(".");
    } else {
      return ["0", "00"];
    }
  }, [amount]);

  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const lang = useSelector(selectorLanguage);
  const urlZelleBanner = lang.language === "en" ? ZelleBannerEN : ZelleBannerES;

  const handleClick = () => {
    if (href && active) location.href = href;
  };

  const handleAlertClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (alertClick) alertClick();
  };

  const handleButtonZelle = () => {
    const androidDeepLink = "intent://#Intent;scheme=zelle;package=com.zellepay.zelle;end";
    const iosDeepLink = "zelle://";

    if (isAndroid) {
      window.location.href = androidDeepLink;
    } else if (isIOS) {
      window.location.href = iosDeepLink;
    } else {
      window.open(urlZelleBanner, "_blank");
    }
  };

  const addFunds = () => {
    if (selectTypeAccount) {
      selectTypeAccount();
    }
    navigate("/fund-account");
  };

  const onSendMoney = () => {
    if (countrySubdivision === "PR") {
      return handleShowNotification && handleShowNotification();
    }

    navigate("/");
  };

  return (
    <BalanceContainer onClick={handleClick} $active={active ? true : false}>
      <Flex justifyContent="space-between">
        <div>
          <img alt={sponsorBank} src={logoUrlBank ? logoUrlBank : USI} height={20} style={{ maxWidth: "100px" }} />
        </div>
        {type === CHECKING && (
          <Details onClick={active ? selectTypeAccount : () => {}}>
            <Text size={0.3} weight={600} align="left" margin={0} color="grey">
              {t("Card.btnDetails")}
            </Text>
          </Details>
        )}
      </Flex>
      <Flex justifyContent="center" as={ContainerBalance}>
        <div>
          <BalanceLink
            alignItems="center"
            justifyContent="center"
            width="100%"
            onClick={active ? selectTypeAccount : () => {}}
          >
            <Flex>
              <Text weight={600} size={5} margin={0} color="primary_500">
                ${FormatNumberToThousands(roundBalance)}
              </Text>
              <Text weight={600} size={roundBalance.length > 5 ? 0 : 1} margin={"4px 0 0 0"} color="primary_500">
                .{centsBalance}
              </Text>
            </Flex>
          </BalanceLink>
          <Subtitle>{subtitle}</Subtitle>
          <Flex justifyContent="center" as={TextInfo}>
            <Text size={0.3} align="left" margin={0} color="grey">
              {title}
            </Text>
            <Text size={0.3} align="left" margin={"0 10px"} color="grey">
              {accountNumber ? `***${accountNumber.slice(-4)}` : ""}
            </Text>
          </Flex>
        </div>
      </Flex>

      {type === CHECKING ? (
        <Flex justifyContent="center" as={ContainerButton}>
          <RoundButton
            icon="send"
            colorIcon="white"
            fillIcon={false}
            background="primary_gradient"
            text={t("buttons.sendMoney")}
            padding="8px"
            sizeButton="small"
            sizeIcon="medium"
            colorLabel="grey"
            onClick={onSendMoney}
          />
          <RoundButton
            icon="rightLeft"
            colorIcon="solid_light"
            background="outline"
            text={t("Card.zelle")}
            onClick={handleButtonZelle}
            sizeButton="small"
            sizeIcon="medium"
            colorLabel="grey"
            padding="6px"
          />
          <RoundButton
            icon="plus"
            colorIcon="solid_light"
            background="outline"
            text={t("Card.btnAddFunds")}
            padding="6px"
            sizeButton="small"
            sizeIcon="medium"
            colorLabel="grey"
            fillIcon={true}
            onClick={addFunds}
          />
        </Flex>
      ) : (
        <Row>
          <Column span={12} justifyContent={JustifyContent.center} mt={4}>
            <Button
              text={t("Card.btnCardDetails")}
              variant="primary"
              sizeButton="medium"
              sizeText="small"
              onClick={active ? selectTypeAccount : () => {}}
            />
          </Column>
        </Row>
      )}

      <Flex justifyContent="center" width="100%">
        {showButton && buttonText && (
          <Button
            variant="outline"
            text={buttonText}
            disabled={buttonDisabled}
            sizeText="small"
            sizeButton="medium"
            onClick={onClick}
          />
        )}
      </Flex>

      {showAlert && (
        <>
          <AlertBackground />
          <AlertContainer onClick={handleAlertClick}>
            <Text size={1} weight={600} color="white">
              {alertText}
            </Text>
          </AlertContainer>
        </>
      )}
    </BalanceContainer>
  );
};

export default BalanceCard;
