import styled from "styled-components";
import { ifProp } from "styled-tools";
import { PropsStyled } from "../../Models/TitleInterface";
import { handleSizeType } from "../../Utilities/Sizes";

const Header = ({ tagName, ...otherProps }: PropsStyled) => {
  const Tag = tagName ? tagName : "h1";

  return <Tag {...otherProps} />;
};

const SelectColor = (color: string) => {
  switch (color) {
    case "white":
      return (props: PropsStyled) => props.theme.Background_01;
    case "black":
      return (props: PropsStyled) => props.theme.Background_02;
    case "solid":
      return (props: PropsStyled) => props.theme.Primary_04;
    case "grey":
      return (props: { theme: { Content_UX_02: string } }) =>
        props.theme.Content_UX_02;
    case "Red10":
      return (props: { theme: { Red_10: string } }) =>
        props.theme.Red_10;
    case "primary":
      return (props: { theme: { Primary_01: string } }) =>
        props.theme.Primary_01;
    default:
      return (props: PropsStyled) => props.theme.Background_02;
  }
};

export const TitleStyled = styled(Header)`
  // It can improve with styled tools, for the moment it's fine.
  color: ${(props) =>
    props.color ? SelectColor(props.color) : props.theme.Background_02};
  font-family: "Poppins";
  font-size: ${(props) => (props.size ? handleSizeType(props.size) : "72px")};
  text-align: ${ifProp("align", "align", "center")};
  margin: 0;
`;
