import { FontColor } from "@utils/Icons";

export enum RowBold {
  LEFT = "left",
  RIGHT = "right",
  ROW = "row",
  NONE = "none",
}

export enum Color {
  WHITE = "white",
  SUCCESS = "success",
  SOLID = "solid",
}

export type tTableInfoData = {
  id?: string;
  type?: string;
  label?: string;
  value?: string | null;
  bold?: RowBold;
  color?: Color;
  value2?: string;
  label2?: string;
};

export type tTableInfo = {
  data: Array<tTableInfoData>;
};

export type tTableInfoColumn = {
  fontWeight?: number;
  textAlign?: string;
  color?: FontColor;
  padding?: string;
  grow?: number;
};
