import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useNavigate } from "react-router-dom";

// Own States
import { AppDispatch, State } from "../../../Redux/Store";
import { selectorLanguage } from "../../../Redux/Translate";
import { getKycStatusSelector } from "../../../Redux/GetKYCStatus/GetKYCStatus.slice";
import { getPayrollByUserAction } from "../../../Redux/Payroll/PayrollData.actions";

// Own Components
import Layout from "../../../Components/Layout";
import Title from "../../../Components/Title";
import { Column, Container, Row } from "../../../Components/Grid";
import CardFound from "../../../Components/CardFound";
import LoaderIcon from "../../../Components/LoaderIcon/LoaderIcon.component";

// Own models
import { JustifyContent } from "../../../Models/Column";
import { CurrentAccount } from "../../../Models/Account";

import { KYC_APPROVED } from "../../../Constants/KYCStatus";
import { GET_PAYROLL_BYUSER_FULFILLED } from "../../../Constants/Payroll";

import { FundsAccountContainer } from "./FundAccount.styled";


type CurrentAccountProps = {
  amount: string;
  data: CurrentAccount;
}

const FundAccount = () => {
  const {t} = useTranslation("global");
  const navigate = useNavigate();
  const [isLoadingLayout, setIsLoadingLayout] = useState(true);
  const [hasPinwheelAccount, setHasPinwheelAccount] = useState<boolean>(false);
  const theme = useSelector((state: State) => state.theme);
  const lang = useSelector(selectorLanguage);
  const dispatch = useDispatch<AppDispatch>();
  const kycStatusSelector = useSelector(getKycStatusSelector);
  const currentAccountsStore = useSelector((state: State) => state.Accounts).accountSelected as CurrentAccountProps; 
  
  const kycStatus = useMemo(() =>
    kycStatusSelector.data?.status || ""
  ,[kycStatusSelector]);

  useEffect(() => {
    getAutomaticDirectDeposit();
  }, [lang]);
  
  const getAutomaticDirectDeposit = async() => {
    const response = await dispatch(getPayrollByUserAction());

    if (response?.type === GET_PAYROLL_BYUSER_FULFILLED) {
      setHasPinwheelAccount(response.payload?.hasPhinwheelAcount);
    }
    setIsLoadingLayout(false);
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout show={isLoadingLayout}>
        <LoaderIcon
          title=""
          text=""
          description={t("loaderText.description")} />
      </Layout>
      <Layout show={!isLoadingLayout} textLink={t("buttons.back")} path="/manage-card-account">
        <Container as={FundsAccountContainer}>
          <Row>
            <Column span={12} mt={3}>
              <Title
                text={t("FundAccount.title")}
                size={6}
                tagName="h1"
                color="black"
                align="left"
              />
            </Column>
            { (kycStatus === KYC_APPROVED)
              && currentAccountsStore
              && currentAccountsStore.data.type === "personalChecking"
              && currentAccountsStore.data.status === "active"
              && !hasPinwheelAccount
              && (
                <Column span={12} justifyContent={JustifyContent.center}>
                  <CardFound
                    titleCard={t("FundAccount.directDeposit.title")}
                    textCard={t("FundAccount.directDeposit.message")}
                    type="active"
                    icon="bank"
                    onClick={() => navigate("/payroll-setup-deposit")}
                  />
                </Column>
              )
            }
            <Column span={12} mb={6} justifyContent={JustifyContent.center}>
              <CardFound
                titleCard={t("FundAccount.bankTransfer.title")}
                textCard={t("FundAccount.bankTransfer.message")}
                type="active"
                icon="link"
                onClick={() => navigate("/funds-select-account")}
              />
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  )
}

export default FundAccount;