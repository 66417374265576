import { BannerCard } from "./LessFeesBestFXBanner.styled";
import sendMoneyYourWay from "@assets/Img/send-money-your-way.svg";
import sparksLeft from "@assets/Img/left-sparks.png";
import sparksRight from "@assets/Img/right-sparks.png";
import { useTranslation } from "react-i18next";
import Text from "@components/Text";
import { FlexContainer } from "@components/Flex/Flex.styled";

export const LessFeesBestFXBanner = () => {
  const [t] = useTranslation("global");
  const [tSendMoney] = useTranslation("sendMoney");
  return (
    <BannerCard>
      <img src={sendMoneyYourWay} width={244.98} />
      <Text color="white" size={0.3} weight={500} align="center" margin="9px 0">
        <span dangerouslySetInnerHTML={{ __html: tSendMoney("SendMoneyYourWay") }}></span>
      </Text>
      <FlexContainer justify="center" alignItems="center" gap="9.2px">
        <FlexContainer w="auto">
          <img src={sparksLeft} />
          <Text weight={700} size={1} color="white">
            {tSendMoney("GetTheBestFX")}
          </Text>
        </FlexContainer>
        <Text color="white" weight={500} align="center">
          {t("global.or")}
        </Text>
        <FlexContainer w="auto">
          <Text weight={700} size={1} color="white">
            {tSendMoney("PayLessFees")}
          </Text>
          <img src={sparksRight} />
        </FlexContainer>
      </FlexContainer>
    </BannerCard>
  );
};
