import styled, { css } from "styled-components";
import { ifProp, theme, withProp } from "styled-tools";
export const PopoverContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
`;
export const PopoverContent = styled.div<{
  sizew?: number;
  align?: string;
  color: string;
}>`
  position: absolute;
  min-width: fit-content;
  height: fit-content;
  ${ifProp(
    {align: "left"},
    css `
      left: 0;
    `,
    css `
      right: 0; 
    `
  )}
  top: calc(100% + 5px);
  z-index: 50;
  padding: 8px 10px;
  background-color: ${ifProp({ color: "default" }, theme("Background_02"), theme("Background_01"))};
  box-shadow: 0px 4px 20px 0px rgba(${theme("RGBA_01")}, 0.15);
  border-radius: 6px;
  width: ${ifProp("sizew", withProp("sizew", sizew => `${sizew}px`), "auto")};
  :after {
    position: absolute;
    top: -4px;
    ${ifProp(
      {align: "left"},
      css `
        left: 8px;
      `,
      css `
        right: 8px; 
      `
    )}
    content: "";
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid ${ifProp({ color: "default" }, theme("Background_02"), theme("Background_01"))};
  }
  p {
    color: ${ifProp({ color: "default" }, theme("Background_01"), theme("Background_02"))};
    line-height: 18px;
  }
`;
export const PopoverWrapper = styled.div`
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;