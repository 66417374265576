import { useSelector } from "react-redux";
import { useRouteError, isRouteErrorResponse } from "react-router-dom";
import { useTranslation } from "react-i18next";

// own states
import { ThemeProvider } from "styled-components";
import { State } from "../../Redux/Store";

// Own assets
import Smile from "../../Assets/Img/Smile.png";
import { JustifyContent } from "../../Models/Column";

// OWn components
import { Container, Row, Column } from "../../Components/Grid";
import Title from "../../Components/Title";
import Text from "../../Components/Text";
import Layout from "../../Components/Layout";
import Button from "../../Components/Button";

export default function ErrorPage() {
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const environmentDev = import.meta.env.DEV;
  const error = useRouteError();
  let errorMessage: string;

  if (isRouteErrorResponse(error)) {
    errorMessage = error.data?.message || error.statusText;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === "string") {
    errorMessage = error;
  } else {
    errorMessage = "Unknown error";
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Container id="error-page">
          <Row>
            <Column span={12} justifyContent={JustifyContent.center} mt={5}>
              <Title
                tagName="h1"
                color="black"
                size={6}
                text={t("404.title")}
              />
            </Column>
            <Column span={12} justifyContent={JustifyContent.center} mt={5}>
              <img src={Smile} alt="smile-oops" />
            </Column>
            <Column span={12} justifyContent={JustifyContent.center} mt={5}>
              <Text size={3}>
                {t("404.subtitle")}
              </Text>
            </Column>

            <Column span={12} justifyContent={JustifyContent.center} mt={5}>
              <Button
                variant="primary"
                text={t("buttons.tryAgain")}
                sizeText="large"
                sizeButton="large"
                onClick={() => window.location.href = "/"}
              />
            </Column>

            {
              environmentDev && (
                <Column span={12} justifyContent={JustifyContent.center} mt={5}>
                  <Text size={3}>
                    {errorMessage}
                  </Text>
                </Column>
              )
            }
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
}
