import { createSlice } from "@reduxjs/toolkit";
import { PostProofAddress } from "./ProofAddressSubmit.actions";
import { PostProofAddressState } from "../../Models/ProofOfAddress";
import { State } from "../Store";

const initialState: PostProofAddressState = {
  loading: false,
  error: null,
  data: null
};

export const PostProofAddressSlice = createSlice({
  name: "postProofAddress",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PostProofAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PostProofAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(PostProofAddress.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export const PostProofAddressSelector = (state: State) => state.postProofAddress;
