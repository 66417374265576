import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { defaultErrorMessage } from "../../Constants/Requests";
import { AxiosError } from "axios";
import { GET_KYC_STATUS } from "../../Constants/KYCStatus";
import { KYC_Status_Interface } from "../../Models/KYC";

/**
 * Method to get status of KYC
 */
export const getKCYStatusAction = createAsyncThunk(
  GET_KYC_STATUS,
  async (payload:KYC_Status_Interface, thunkAPI) => {
    try {
      const path = payload.path ? payload.path : ""; 
      const response = await BaseConfigApi.get(`/persons/${payload.personId}/kyc/${path}`);
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
