import styled from "styled-components";
import { theme } from "styled-tools";

export const BackgroundAddress = styled.div`
  background-color: ${theme("Background_12")};
  border-radius: 12px;
  height: 122px;
  padding: 12px 24px;
  display: flex;
  flex-direction: row;
`;

export const AddressSection = styled.div`
  display: flex;
  width: 65%;
`;

export const LinkChange = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  height: 30px;
  padding: 12px 0;
  width: 35%;

  p {
    text-decoration: underline;
  }
`;

export const TextWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  height: 30px;
  padding: 12px 0;
`;
