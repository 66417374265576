import { RadioProps } from "../../Models/checkboxInterface";
import { Column, Row } from "../Grid";
import Icon from "../Icon";
import { 
  Label, Radio, RadioCardOptions, RadioCardRow, RadioCardSelected, RadioCardStyled, 
  RadioCardTitle
} from "./RadioCard.styled";

const RadioCustom = (props: RadioProps) => {
  const { 
    id, 
    text, 
    imageElement, 
    image, 
    name, 
    legends, 
    onChangeEvent, 
    selected, 
    disabled, 
    forwardRef 
  } = props;

  return (
    <Row key={`card-${id}`}>
      <Column
        span={12}
        mb={3}
        as={selected ? RadioCardSelected : RadioCardStyled}
      >
        <Row as={RadioCardRow}>
          <Column span={12}>
            <Radio
              type="radio"
              ref={forwardRef}
              id={id}
              name={name}
              onChange={onChangeEvent}
              value={id}
              checked={selected}
              disabled={disabled}
            />
            <label htmlFor={id} className="hidden" />
            <RadioCardTitle>
              {imageElement || null}
              {image && <img src={image} alt="image-radio" />}
              <Label htmlFor={id} $disabled={disabled ? disabled : false}>
                <span className="radio-card-title">{text}</span>
              </Label>
            </RadioCardTitle>

            {legends &&
              legends.map((legend, index) => (
                <RadioCardOptions key={`card-option-${index}`}>
                  <Icon icon="checkCircleGreen" size="small" />
                  <span className="radio-card-description">{legend}</span>
                </RadioCardOptions>
              ))}
          </Column>
        </Row>
      </Column>
    </Row>
  );
};
export default RadioCustom;
