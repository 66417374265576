import { useTranslation } from "react-i18next";
import { ErrorModalProps } from "../../../Models/ModalInterface";
import IconShape from "../../IconShape";
import Text from "../../Text";
import Modal from "../Modal.component";
import Button from "../../Button";

export const SuccessModal = (props: ErrorModalProps) => {
  const [t] = useTranslation("global");

  return (
    <Modal {...props}>
      <IconShape
        icon="circle-check"
        iconSize="xlarge"
        border="rounded"
        bgColor="primary"
        height={75}
        width={75}
      />
      <Text size={3} weight={700}>
        {props.message && props.message}
      </Text>
      <Button
        text={t("buttons.next")}
        onClick={() => props.handleClose && props.handleClose()}
        sizeText="medium"
        variant="primary"
      />
    </Modal>
  );
};
