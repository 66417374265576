
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { State } from "../../../Redux/Store";

import Layout from "../../../Components/Layout";
import { Column, Container, Row } from "../../../Components/Grid";
import WarningMessage from "../../../Components/WarningMessage";

const FundAccountError = () => {
  const theme = useSelector((state: State) => state.theme);
  const [t] = useTranslation("global");
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Container>
          <Row>
            <Column span={12}>
              { state?.isInsufficientFunds ? (
                <WarningMessage
                  title={t("Funds.LinkedAccounts.linkingError")}
                  message={t("Funds.LinkedAccounts.linkingErrorMsg1")}
                  message2={t("Funds.LinkedAccounts.linkingErrorMsg2")}
                  icon="circleXRed"
                  handleClick={() => navigate("/funds-select-account")}
                />
              ) : state?.linkedAccountsNoMatching ? (
                <WarningMessage
                  title={t("LinkingError.titleAccountsNoMatching")}
                  message={t("LinkingError.messageAccountsNoMatching")}
                  icon="circleXRed"
                  isShowContactButton={false}
                  handleClick={() => navigate("/")}
                />
              ) : state?.isDuplicatedAccount ? (
                <WarningMessage
                  title={t("LinkingError.titleDuplicatedAccount")}
                  message={t("LinkingError.messageDuplicatedAccount")}
                  icon="circleXRed"
                  isShowContactButton={false}
                  handleClick={() => navigate("/")}
                />
              ) : (
                <WarningMessage
                  title={t("LinkingError.title")}
                  message={t("LinkingError.message")}
                  icon="circleXRed"
                  handleClick={() => navigate("/")}
                />
              )}
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  )
};

export default FundAccountError;