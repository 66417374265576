import { FlexContainer } from "@components/Flex/Flex.styled";
import IconFont from "@components/IconFont";
import Layout from "@components/Layout";
import { RecipientCard } from "@components/Payments/Beneficiary/RecipientCard.component";
import Button from "@components/Payments/Button";
import { QuoteTotals } from "@components/Payments/QuoteSummary/QuoteTotals.component";
import Text from "@components/Text";
import { isApiRequestError } from "@core/Common/domain/ApiRequestError";
import { Account, BeneficiaryRecipient } from "@core/Payments/Beneficiary/domain/Beneficiary";
import { BeneficiaryAccountForm } from "@core/Payments/Beneficiary/domain/BeneficiaryAccountForm";
import { BeneficiaryForm } from "@core/Payments/Beneficiary/domain/BeneficiaryForm";
import { useBeneficiary } from "@hooks/Payments/useBeneficiary";
import { useModal } from "@hooks/Payments/useModal";
import { usePaymentOrder } from "@hooks/Payments/usePaymentOrders";
import { useQuote } from "@hooks/Payments/useQuote";
import { ModalProps } from "@models/ModalInterface";
import { BeneficiarySelector, BeneficiarySlice } from "@redux/Payments/Beneficiary/Beneficiary.slice";
import { QuotationSelector } from "@redux/Payments/Quotation/Quotation.slice";
import { State } from "@redux/Store";
import { formatNumber } from "@utils/NumberUtils";
import { maskInterbankKey } from "@utils/String";
import isEmpty from "lodash.isempty";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";

const BeneficiarySelectionPage = () => {
  const [t] = useTranslation("global");
  const [tSendMoney] = useTranslation("sendMoney");
  const [tModals] = useTranslation("modals");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector((state: State) => state.theme);
  const {
    currentQuotation,
    currentDeliveryMethodQuote,
    currentPaymentDestination,
    currentPaymentMethod,
    countryDestination,
  } = useSelector(QuotationSelector).data;
  const {
    data: { beneficiarySelected },
  } = useSelector(BeneficiarySelector);

  const submitButton = useRef<HTMLButtonElement | null>(null);
  const [currentPage] = useState(1);
  const [, setPageCount] = useState(0);
  const [recipients, setRecipients] = useState<BeneficiaryRecipient[]>([]);

  const {
    isLoading: beneficiariesIsLoading,
    errorMessage,
    getBeneficiariesPaginated,
    getMessageError,
    createRecipient,
    addAccount,
  } = useBeneficiary();
  const { modal: errorModal, showModal: showErrorModal } = useModal({ modalType: "error" });
  const { modal, showModal } = useModal();
  const {
    modal: unitellerDisclaimerModal,
    showModal: showUnitellerDisclaimerModal,
    setOpenModal: setUnitellerDisclaimerModalOpen,
  } = useModal();
  const { quoterValue, handleQuotationChange } = useQuote();
  const { isLoading: paymentOrderIsLoading, verificationUniteller, saveSenderEmailUniteller } = usePaymentOrder();

  const currentQuoteDestinations = currentDeliveryMethodQuote?.paymentDestinations;
  const isCashPickup = currentDeliveryMethodQuote?.deliveryMethodCode === "CPU";
  const isDirectToBank = currentDeliveryMethodQuote?.deliveryMethodCode === "D2B";

  const isLoading = paymentOrderIsLoading || beneficiariesIsLoading;

  const fetchBeneficiaries = async () => {
    const paginationResponse = await getBeneficiariesPaginated({
      country: countryDestination,
      page: currentPage,
      recent: true,
      perPage: 1000,
    });
    if (paginationResponse) {
      const recipients = paginationResponse.recipients || [];

      setPageCount(Math.ceil(paginationResponse.pagination.total / 20));
      setRecipients(recipients);
      if (!beneficiarySelected && isCashPickup) {
        selectBeneficiary(recipients[0]);
      } else if (!beneficiarySelected) {
        const recipientOfDestinationQuoted = recipients.find((recipient) =>
          recipient.account.some((acc) => acc.destinationId === currentPaymentDestination?.id)
        );

        recipientOfDestinationQuoted
          ? selectBeneficiary(
              {
                ...recipientOfDestinationQuoted,
              },
              recipientOfDestinationQuoted.account.find((acc) => acc.destinationId === currentPaymentDestination?.id)
            )
          : !isEmpty(recipients) && selectBeneficiary({ ...recipients[0] }, recipients[0].account[0]);
      }

      return recipients;
    }
    return [];
  };

  const selectBeneficiary = async (beneficiary?: BeneficiaryRecipient, account?: Account) => {
    if (
      (isCashPickup && beneficiary?.beneficiaryId === beneficiarySelected?.beneficiaryId) ||
      (isDirectToBank && account && account.id === beneficiarySelected?.accountSelected?.id)
    ) {
      return;
    }
    const selected = beneficiary
      ? {
          ...beneficiary,
          accountSelected: account && {
            ...account,
            destinationId:
              currentQuoteDestinations?.find(
                (destination) =>
                  destination.id === account?.destinationId || destination.destination === account.bankName
              )?.id || account?.destinationId,
            rail: currentQuoteDestinations?.find(
              (destination) => destination.id === account?.destinationId || destination.destination === account.bankName
            )?.rail,
          },
        }
      : undefined;
    if (selected) {
      dispatch(BeneficiarySlice.actions.setBeneficiarySelected(selected));
    }
    if (selected?.accountSelected) {
      await handleQuotationChange({
        ...quoterValue.current,
        amount: currentQuotation?.amount,
        deliveryMethod: currentDeliveryMethodQuote?.deliveryMethodCode,
        currency: currentQuotation?.destinationCurrency,
        country: countryDestination,
        sendTo: selected.accountSelected.destinationId,
      });
    }
    submitButton.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onClickBeneficiary = (recipient?: BeneficiaryRecipient) => {
    if (recipient && isEmpty(recipient.account) && currentDeliveryMethodQuote?.deliveryMethodCode === "D2B") {
      showModal({
        modalType: "addBeneficiaryAccountModal",
        recipient,
        country: countryDestination,
        handleConfirm: async (account) => {
          await fetchBeneficiaries();
          const recipientSelected = recipients.find((r) => r.beneficiaryId === recipient.beneficiaryId);
          const accountSelected = recipientSelected?.account?.find((acc) => acc.id === (account as Account).id);
          selectBeneficiary(recipient, accountSelected as Account);
        },
      });
    } else if (recipient && recipient.account.length > 1 && currentDeliveryMethodQuote?.deliveryMethodCode === "D2B") {
      showModal({
        modalType: "selectBeneficiaryAccountModal",
        recipient,
        handleConfirm: async (account) => {
          await fetchBeneficiaries();
          const recipientSelected = recipients.find((r) => r.beneficiaryId === recipient.beneficiaryId);
          const accountSelected = recipientSelected?.account?.find((acc) => acc.id === (account as Account).id);
          selectBeneficiary(recipient, accountSelected as Account);
        },
      });
    } else {
      selectBeneficiary(
        recipient,
        currentDeliveryMethodQuote?.deliveryMethodCode !== "CPU" ? recipient?.account[0] : undefined
      );
    }
  };

  const createBeneficiary = async (data: BeneficiaryForm, closeModal?: ModalProps["handleClose"]) => {
    const { account, ...beneficiaryData } = data;
    const beneficiaryCreatedResponse = await createRecipient(beneficiaryData);

    if (beneficiaryCreatedResponse && isCashPickup) {
      await fetchBeneficiaries();
      closeModal && closeModal();
    } else if (
      beneficiaryCreatedResponse &&
      !isApiRequestError(beneficiaryCreatedResponse) &&
      !isCashPickup &&
      !isEmpty(account)
    ) {
      const accountResponse = await addAccount({
        beneficiaryId: beneficiaryCreatedResponse.recipient.beneficiaryId,
        account: account![0] as BeneficiaryAccountForm["account"],
      });
      if (isApiRequestError(accountResponse)) {
        getMessageError(accountResponse);
        return;
      }

      const recipients = await fetchBeneficiaries();
      const recipientToSelect = recipients.find(
        (recipient) => recipient.beneficiaryId === beneficiaryCreatedResponse.recipient.beneficiaryId
      );

      selectBeneficiary(recipientToSelect, recipientToSelect?.account[0]);
      closeModal && closeModal();
    } else if (isApiRequestError(beneficiaryCreatedResponse)) {
      getMessageError(beneficiaryCreatedResponse);
    }
  };

  const onClickAddBeneficiary = async () => {
    showModal({
      modalType: "recipientModal",
      isCPU: isCashPickup,
      handleSubmit: (data, close) => {
        createBeneficiary(data as BeneficiaryForm, close);
      },
    });
  };

  const runUnitellerVerifications = async () => {
    if (beneficiarySelected) {
      const rail = currentPaymentDestination?.rail;

      if (rail === "UNT") {
        const responseVerification = await verificationUniteller();
        if (responseVerification?.showTermsAndConditions || isEmpty(responseVerification?.senderEmail)) {
          showUnitellerDisclaimerModal({
            modalType: "unitellerDisclaimer",
            showEmailInput: isEmpty(responseVerification?.senderEmail),
            emailDefaultValue: responseVerification?.senderEmail || undefined,
            handleConfirm: async (data) => {
              const { email } = data as {
                checkLegalTerms: boolean;
                email: string;
              };
              if (email) {
                await saveSenderEmailUniteller(email);
              } else if (responseVerification?.senderEmail) {
                await saveSenderEmailUniteller(responseVerification.senderEmail);
              }
              setUnitellerDisclaimerModalOpen(false);

              navigate("/payment-method-selection");
            },
          });
        } else {
          navigate("/payment-method-selection");
        }
      } else {
        navigate("/payment-method-selection");
      }
    }
  };

  const submitBeneficiary = async () => {
    await runUnitellerVerifications();
  };

  useEffect(() => {
    if (errorMessage) {
      showErrorModal({
        errorMessage: tModals(errorMessage),
      });
    }
  }, [errorMessage]);

  useEffect(() => {
    fetchBeneficiaries();
  }, [countryDestination, isCashPickup]);

  useEffect(() => {
    dispatch(BeneficiarySlice.actions.setBeneficiarySelected(undefined));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Layout path="/" showHelpBtn={false} showNavigation={false} loading={isLoading}>
        <FlexContainer direction="column" p="0 16px" mw="556px" gap="24px">
          <FlexContainer justify="center">
            <Text align="center" size={2} weight={600} lineHeight="30px" margin={0}>
              {tSendMoney("RecipientSelection.title")}
            </Text>
          </FlexContainer>
          <FlexContainer direction="column" alignItems="center" gap="18px">
            <Button
              variant="outlineMuted"
              sizeButton="full"
              padding="12px"
              borderRadius="8px"
              onClick={onClickAddBeneficiary}
            >
              <Text size={1} color="primary" weight={500} margin={0}>
                + {tSendMoney("Beneficiaries.addButton")}
              </Text>
            </Button>
            {((isDirectToBank && !recipients?.some((recipient) => isEmpty(recipient.account))) ||
              (isCashPickup && !isEmpty(recipients))) && (
              <FlexContainer justify="center" alignItems="center" gap="15px">
                <hr />
                <FlexContainer justify="center" flex="1 1 auto">
                  <Text align="center" margin={0}>
                    {tSendMoney("RecipientSelection.selectOne")}
                  </Text>
                </FlexContainer>
                <hr />
              </FlexContainer>
            )}
            {!beneficiariesIsLoading && (
              <FlexContainer direction="column" gap="24px">
                {isDirectToBank
                  ? recipients.map((recipient) =>
                      recipient.account?.map((account) => (
                        <RecipientCard
                          id={`recipient-${recipient.beneficiaryId}-${account.id}`}
                          key={`recipient-${recipient.beneficiaryId}-${account.id}`}
                          {...recipient}
                          account={[account]}
                          active={
                            beneficiarySelected?.beneficiaryId === recipient.beneficiaryId &&
                            beneficiarySelected?.accountSelected?.id === account.id
                          }
                          onClick={(recipient) => onClickBeneficiary(recipient)}
                          onEditClick={() => {
                            dispatch(BeneficiarySlice.actions.setBeneficiaryEdit(recipient));
                            // navigate("/beneficiaries/edit");
                          }}
                        >
                          <FlexContainer gap="4px" m="8px 0 0 0">
                            <IconFont name="building-bank" color="primary700" weight={500} />
                            <FlexContainer flex="1 1 auto" w="auto">
                              <Text align="left" color="primary_01_700" size={0.5} weight={500} margin={0}>
                                {t("HomePage.GlobalPaymentsQuoter.label1")}:
                              </Text>
                            </FlexContainer>
                            <Text align="left" color="neutral600" size={0.5} weight={500} margin={0}>
                              {account.bankName} {maskInterbankKey(account.accountNumber)}
                            </Text>
                          </FlexContainer>
                        </RecipientCard>
                      ))
                    )
                  : recipients.map((recipient) => (
                      <RecipientCard
                        id={`recipient-${recipient.beneficiaryId}`}
                        key={recipient.beneficiaryId}
                        {...recipient}
                        active={beneficiarySelected?.beneficiaryId === recipient.beneficiaryId}
                        onClick={(recipient) => onClickBeneficiary(recipient)}
                        onEditClick={() => {
                          dispatch(BeneficiarySlice.actions.setBeneficiaryEdit(recipient));
                          // navigate("/beneficiaries/edit");
                        }}
                      >
                        <FlexContainer gap="4px" m="8px 0 0 0">
                          <IconFont name="cash" color="primary700" weight={500} />
                          <Text color="primary_01_700" weight={500} margin={0}>
                            {t("HomePage.GlobalPaymentsQuoter.label2")}
                          </Text>
                        </FlexContainer>
                      </RecipientCard>
                    ))}
              </FlexContainer>
            )}
          </FlexContainer>
          <FlexContainer justify="center" gap="8px">
            <Text size={1.5} weight={600} margin={0}>
              {tSendMoney("RecipientWillGet")}:
            </Text>
            <Text color="gradientPrimaryBlue" size={1.5} weight={600} margin={0} gradient>
              ${formatNumber(currentDeliveryMethodQuote?.amountToReceive || 0)} {currentQuotation?.destinationCurrency}
            </Text>
          </FlexContainer>
          <QuoteTotals
            transferFee={currentDeliveryMethodQuote?.fee || 0}
            paymentMethodFee={currentPaymentMethod?.paymentMethodFee || 0}
            total={currentDeliveryMethodQuote?.paymentMethods?.[0]?.totalCost || 0}
          />
          <FlexContainer justify="center">
            <Button
              buttonRef={submitButton}
              variant="primary"
              padding="20px 90px"
              sizeButton="fit"
              disabled={!beneficiarySelected}
              withShadow
              onClick={submitBeneficiary}
            >
              <Text size={1} weight={600} lineHeight="16px" color="white" margin={0}>
                {tSendMoney("Next")}
              </Text>
            </Button>
          </FlexContainer>
        </FlexContainer>
        {modal}
        {errorModal}
        {unitellerDisclaimerModal}
      </Layout>
    </ThemeProvider>
  );
};

export default BeneficiarySelectionPage;
