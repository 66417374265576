import styled from "styled-components";
import { theme } from "styled-tools";
import { Link } from "react-router-dom";

import mediaQuery, { Screen } from "@utils/MediaQuery";

export const LinkedAccountLayout = styled.div`
  .main-content {
    justify-content: flex-start;
  }
`;

export const LinkedAccountsContainer = styled.div`
  ${mediaQuery[Screen.desktop]} {
    padding-inline: 300px;
  }
`;


export const LinkAccount = styled(Link)`
  border: 1px solid ${theme('Grey_10')};
  border-radius: 8px;
  color: ${theme('Primary_01')};
  font-weight: 600;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 12px;
    height: 12px;

    path {
      fill: ${theme('Primary_01')};
      stroke: ${theme('Primary_01')};
    }
  }
  
  span {
    margin-left: 2px;
  }
`;