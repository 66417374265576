import styled from "styled-components";
import mediaQuery, { Screen } from "../../../../Utilities/MediaQuery";

export const PDFViewerZoomStyled = styled.div`
  padding-bottom: 6rem;

  ${mediaQuery[Screen.mobile]} {
    padding-bottom: 14rem;
  }
`;

export const PDFViewerZooomButtons = styled.div`
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 99;
  button {
    margin-right: 5px;
    min-width: auto;
    padding: 5px !important;
    width: 30px !important;
  }

  ${mediaQuery[Screen.mobile]} {
    right: 5px;
    bottom: 10px;
    display: grid;
    button {
      margin: 5px;
      padding: 12px !important;
      width: 45px !important;
    }
  }
`;
