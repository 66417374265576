// Own components
import { Column, Row } from "../../../../Components/Grid";

import { CustomText } from "../../BankTransfers.styled";
import { BankTransfersHeaderIcons } from "./BankTransferHeaderIcons.component";
import { BankTransferHeaderContent } from "./BankTransfersHeader.styled";

type Props = {
  description: string;
  order: string;
  status: string;
  statusText: string;
};

export const BankTransfersHeader = ({
  description,
  order,
  status,
  statusText,
}: Props) => {
  return (
    <Row>
      <Column span={12} p={2}>
        <Row>
          <Column span={12}>
            <CustomText
              color="black"
              size={0.5}
              align="center"
              weight={600}
              margin="5px 0 14px 0"
            >
              {order}
            </CustomText>
            <CustomText
              color="grey"
              size={0.3}
              align="center"
              weight={400}
              margin="5px 0 14px 0"
            >
              {description}
            </CustomText>
          </Column>
          <Column span={12} as={BankTransferHeaderContent}>
            <BankTransfersHeaderIcons status={status} statusText={statusText} />
          </Column>
        </Row>
      </Column>
    </Row>
  );
};
