import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_CUSTOMER_CONFIG } from "../../Constants/user";
import { defaultErrorMessage } from "../../Constants/Requests";
import { AxiosError } from "axios";

// Own services Api
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";

export const CustomerConfigSliceAction = createAsyncThunk(
  GET_CUSTOMER_CONFIG,
  async (_, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(`/onboarding-configuration`);
      return response.data.data;
    } catch (error) { 
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
