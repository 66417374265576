import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
import isEmpty from "lodash.isempty";

// Own constants
import { privateRoutes } from "../../Constants/PrivateRoutes";
import { TOKEN_COOKIE_NAME } from "../../Constants/Auth";

// Own utils
import { fromAuthApp } from "../../Utilities/RedirectFromOrigin";

// Own models
import { tAuthUser } from "../../Models/tAuthUser";
import { tUseAuth } from "../../Models/tUseAuth";

// Own redux
import { AuthSelector } from "../../Redux/Auth/Auth.slice";

export const userDefaultValue: tAuthUser = {
  token: "",
  refreshToken: "",
  userId: ""
};

export function useAuth(): tUseAuth {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<tAuthUser>(userDefaultValue);
  const [timer, setTimer] = useState(0);
  const cookies = new Cookies();
  const authCookie = cookies.get(TOKEN_COOKIE_NAME);
  const authUser = useSelector(AuthSelector);
  const logoutUrl = fromAuthApp('logout');

  useEffect(() => {
    const authenticated = authUser.data;

    // If the cookie does not exist, the session will be closed.
    if (isEmpty(authCookie)) {
      localStorage.clear();
      window.location.href = logoutUrl;
    }

    // If the user is be logged
    if (authenticated && privateRoutes.includes(location.pathname) && !isEmpty(authenticated.token)) {
      setUser(authenticated);
    }

    // If the user is not be logged and location pathname is private route
    if (authenticated && privateRoutes.includes(location.pathname) && isEmpty(authenticated.token)) {
      navigate("/");
    }
  }, [pathname, timer]);

  const auth: tUseAuth = {
    user,
  };

  // Update the internal timer mentioned above, every second
  useEffect(() => {
    const n = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);

    return () => clearInterval(n);
  }, []);

  return auth;
}
