export const onboardingRoutes = {
  personInformation: "/kyc-personal-data",
  personAddress: "/kyc-personal-address",
  personShippingAddress: "/kyc-shipping-address",
  personEmail: "/kyc-email",
  selectIdvFlow: "/kyc-ssn",
  ssn: "/kyc-ssn",
  idv: "/kyc-veriff",
  passport: "/kyc-veriff-passport",
  visa: "/kyc-veriff-visa",
  submitPep: "/complete-onboarding",
  submitKyc: "/submit-kyc"
}
