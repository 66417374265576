import styled from "styled-components";

export const EditInfoContainer = styled.div`
  margin-block: 16px;
`;

export const ContainerTitle = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid black;

  svg {
    cursor: pointer;
  }
`;