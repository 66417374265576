import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApiIam } from "../../Services/API/BaseConfigApi";
import { TOKEN_VALIDATION } from "../../Constants/Token";
import { TokenValidation } from "../../Models/TokenValidation";
import { defaultErrorMessage } from "../../Constants/Requests";
import { AxiosError } from "axios";

export const TokenValidationAction = createAsyncThunk<TokenValidation, string, { rejectValue: string }>(
  TOKEN_VALIDATION,
  async (payload:string, thunkAPI) => {
    try {
      const header = {
        "Authorization": 'Bearer ' + payload
      };
      const response = await BaseConfigApiIam.get(`/api/tokenvalidation`, { headers: header });
      return response.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
