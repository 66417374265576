import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// Own redux
import { AppDispatch, State } from "../../../Redux/Store";
import { GetProofAddressType } from "../../../Redux/ProofAddressDocument/ProofAddressDocument.actions";
import { updateProofOfAddressType } from "../../../Redux/ProofOfAddressType";

// Own constants
import { GET_PROOF_ADDRESS_DOCUMENT_FULFILLED, GET_PROOF_ADDRESS_DOCUMENT_REJECTED } from "../../../Constants/Documents";

// Own models
import { AlignItems } from "../../../Models/Column";
import { POADocumentFormValues } from "../../../Models/ProofOfAddress";
import { GetProofAddressDocumentData } from "../../../Models/Documents";

// Own styles
import { ButtonContainer, IconList, StepsContainer } from "./ProofOfAddress.styled";

// Own components
import { Column, Container, Row } from "../../../Components/Grid";
import Layout from "../../../Components/Layout";
import StepsRoutes from "../../../Components/StepsRoutes";
import Text from "../../../Components/Text";
import Button from "../../../Components/Button";
import ReactSelect from "../../../Components/ReactSelect";
import Icon from "../../../Components/Icon";
import LoaderIcon from "../../../Components/LoaderIcon/LoaderIcon.component";

const ProofOfAddresPage = () => {
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [documents, setDocuments] = useState<GetProofAddressDocumentData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });

  const GetDocumentsAddressList = async () => {
    setIsLoading(true);
    const response = await dispatch(GetProofAddressType());

    if (response?.type === GET_PROOF_ADDRESS_DOCUMENT_FULFILLED) {
      setDocuments(response?.payload);
      setIsLoading(false);
      setIsError(false);
    }

    if (response?.type === GET_PROOF_ADDRESS_DOCUMENT_REJECTED) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  const onSubmit: SubmitHandler<POADocumentFormValues> = async () => {
    setIsLoading(true);
    setIsError(false);
  };

  useEffect(() => {
    document.body.classList.remove('modal');
    GetDocumentsAddressList();
  },[]);

  return(
    <ThemeProvider theme={theme}>
      <Layout show={isLoading}>
        <LoaderIcon
          title={t("loaderText.title")}
          text={t("loaderText.subtitle")}
        />
      </Layout>
      <Layout show={!isLoading}>
        <Container as={StepsContainer}>
          <Row>
            <Column span={12} mt={1}>
              <StepsRoutes totalSteps={8} stepIndex={6} />
            </Column>
          </Row>
          <Row>
            <Column span={12} mt={4} mb={1}>
              <Text size={3} align="left" weight={600} color="black" margin="0">
                {t("ProofOfAddress.instructions.pageTitle")}
              </Text>
            </Column>
            <Column span={12} my={1}>
              <Text size={0.5} align="left" weight={400} color="grey" margin="0">
                {t("ProofOfAddress.instructions.pageDescription")}
              </Text>
            </Column>
          </Row>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Column span={12} mt={3} mb={1}>
                <Controller
                  control={control}
                  name="idSelectDocument"
                  rules={{
                    required: t("Forms.required"),
                  }}
                  render={({ field: { onChange, ref } }) => (
                    <ReactSelect
                      inputRef={ref}
                      nameSelect="idSelectDocument"
                      options={documents.map(document => ({
                        value: document.id,
                        label: document.description
                      }))}
                      onChange={(val: { value: string, label: string }) => {
                        const branch = documents.find(document => document.id === val.value)
                        if (branch)
                          dispatch(updateProofOfAddressType(branch.id));
                          onChange(val?.value);
                        return branch
                      }}
                      errors={errors}
                    />
                  )}
                />
              </Column>

              {isError && (
                <Column span={12} mt={1}>
                  <Text size={0.5} align="center" weight={600} color="error">
                    {t("404.subtitle")}
                  </Text>
                </Column>
              )}
            </Row>
          </form>

          <Row>
            <Column span={1} mt={2} alignItems={AlignItems.flexStart}>
              <IconList>
                <Icon icon="checkCircleGreen2" fillIcon={false} size="medium" />
              </IconList>
            </Column>
            <Column span={11} mt={2} alignItems={AlignItems.flexStart}>
              <Text size={1} align="left" margin="4px 0 0 4px" weight={400} color="black">
                {t("ProofOfAddress.instructions.firstPoint")}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column span={1} alignItems={AlignItems.flexStart}>
              <IconList>
                <Icon icon="checkCircleGreen2" fillIcon={false} size="medium" />
              </IconList>
            </Column>
            <Column span={11} alignItems={AlignItems.flexStart}>
              <Text size={1} align="left" margin="4px 0 0 4px" weight={400} color="black">
                {t("ProofOfAddress.instructions.secondPoint")}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column span={1} alignItems={AlignItems.flexStart}>
              <IconList>
                <Icon icon="checkCircleGreen2" fillIcon={false} size="medium" />
              </IconList>
            </Column>
            <Column span={11} alignItems={AlignItems.flexStart}>
              <Text size={1} align="left" margin="4px 0 0 4px" weight={400} color="black">
                {t("ProofOfAddress.instructions.thirdPoint")}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column span={1} alignItems={AlignItems.flexStart}>
              <IconList>
                <Icon icon="checkCircleGreen2" fillIcon={false} size="medium" />
              </IconList>
            </Column>
            <Column span={11} alignItems={AlignItems.flexStart}>
              <Text size={1} align="left" margin="4px 0 0 4px" weight={400} color="black">
                {t("ProofOfAddress.instructions.fourthPoint")}
              </Text>
            </Column>
          </Row>

          <Row as={ButtonContainer}>
            <Column span={12}>
              <Button
                variant={!isDirty || !isValid || isLoading ? "default" : "primary"}
                disabled={!isValid}
                text={t("VisaVerification.buttonTake")}
                sizeText="medium"
                sizeButton="xlarge"
                onClick={() => navigate("/proof-of-address-take-photo")}
              />
              <Button
                variant={!isDirty || !isValid || isLoading ? "default" : "outline"}
                disabled={!isValid}
                text={t("VisaVerification.buttonUpload")}
                sizeText="medium"
                sizeButton="xlarge"
                onClick={() => navigate("/proof-of-address-upload-photo")}
              />
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  )
};

export default ProofOfAddresPage;