import { FlexContainer } from "@components/Flex/Flex.styled";
import styled from "styled-components";
import { ifProp, prop, theme } from "styled-tools";

export const ButtonSection = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const CountryIcon = styled.img<{ size?: string }>`
  height: ${ifProp("size", prop("size"), "35px")};
  width: ${ifProp("size", prop("size"), "35px")};
  margin-right: 3px;
  border-radius: 50%;
`;

export const CountryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 5px;
  border-bottom: 2px ${theme("Primary_18")} solid;
`;

export const ContainerTitleAccount = styled.div`
  width: 100%;
  border-left: 8px solid ${theme("Primary_01")};
`;
