import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiRequestError } from "@core/Common/domain/ApiRequestError";
import { createClient } from "@services/API/GlobalPaymentsApiClient";
import { Person } from "@core/Payments/Person/domain/Person";

const client = createClient();

export const getStatusPersonKYC = createAsyncThunk<
  Person,
  void,
  { rejectValue: ApiRequestError }
>("getStatusPersonKYC", async (_, { rejectWithValue }) => {
  try {
    const response = await client.get<Person>("persons/ByUserId");
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return rejectWithValue(e);
  }
});
