import { useDispatch, useSelector } from "react-redux";
import { getCoupons } from "@core/Payments/Rewards/application/getCoupons";
import { createApiCouponRepository } from "@core/Payments/Rewards/infrastructure/ApiCouponRepository";
import { useStatus } from "./useStatus";
import { CouponsSelector, CouponsSlice } from "@redux/Payments/Coupons/Coupons.slice";
import { AppDispatch } from "@redux/Store";
import { QuotationSlice } from "@redux/Payments/Quotation";
import { PersonsKYCSelector } from "@redux/Payments/PersonsKYC";
import { verifyCoupon } from "@core/Payments/Rewards/application/verifyCoupon";
import { checkIfCouponApplied, VerifyCouponPayloadShort } from "@core/Payments/Rewards/domain/Coupon";
import { ApiRequestError } from "@core/Common/domain/ApiRequestError";
import { ByUserSliceSelector } from "@redux/ByUser/ByUser.slice";

export const useCoupons = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, startRequest, endRequest } = useStatus();
  const { couponsGrouped } = useSelector(CouponsSelector).data;
  const user = useSelector(ByUserSliceSelector).data;
  const personData = useSelector(PersonsKYCSelector).data;
  const person = personData?.personVeriff;

  const getUserCoupons = async (apply = true) => {
    startRequest();
    try {
      const couponsRes = await getCoupons(createApiCouponRepository())();

      dispatch(CouponsSlice.actions.setCoupons(couponsRes));

      if (apply) {
        dispatch(QuotationSlice.actions.setCurrentQuoteDiscounted(undefined));
        if (couponsRes.couponsGrouped?.length > 0) {
          dispatch(QuotationSlice.actions.setCouponToApply(couponsRes.couponsGrouped[0]));
        } else {
          dispatch(QuotationSlice.actions.setCouponToApply(undefined));
        }
      }

      endRequest();
      return couponsRes;
    } catch (error) {
      dispatch(CouponsSlice.actions.setCoupons([]));
      dispatch(QuotationSlice.actions.setCouponToApply(undefined));
      endRequest({ error: error as ApiRequestError });
    }
  };

  const verifyQuoteCoupon = async (payload: VerifyCouponPayloadShort) => {
    startRequest();
    try {
      const quoteDiscounted = await verifyCoupon(createApiCouponRepository())({
        ...payload,
        isFirstTransaction: person?.applyFeePromotion || false,
        userId: user?.userId || "",
      });

      const couponApplied = checkIfCouponApplied(payload, quoteDiscounted.data);

      dispatch(CouponsSlice.actions.setErrorsCoupons(null));

      endRequest();
      return { couponApplied, quoteDiscounted: quoteDiscounted.data };
    } catch (error) {
      dispatch(CouponsSlice.actions.setErrorsCoupons(error));
      endRequest({ error: error as ApiRequestError });
    }
  };

  return {
    isLoading,
    couponsGrouped,
    getUserCoupons,
    verifyQuoteCoupon,
  };
};
