import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";

const initialState = {
  transactionDetail: {
    id: "",
    amount: "",
    transferType: "",
    description: "",
    balance: "",
    status: "",
    transferId: "",
    transferOrderId: "",
    createdAt: "",
    txnType: "",
    storeName: null,
    subType: "",
    title: "",
  },
  typeDispute: "",
};

export const transactionDetailSlice = createSlice({
  name: "transactionDetail",
  initialState,
  reducers: {
    changeTransactionDetail: (state, action) => {
      state.transactionDetail = action.payload;
    },
    changeTypeDispute: (state, action) => {
      state.typeDispute = action.payload;
    }
  },
});

export const { changeTransactionDetail, changeTypeDispute } = transactionDetailSlice.actions;
export const selectorTransactionDetail = (state: State) => state.transactionDetail;

export default transactionDetailSlice.reducer;
