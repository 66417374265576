import { createAsyncThunk } from "@reduxjs/toolkit";

// Own services Api
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { AddressPayload, UpdateAddressResponse } from "../../Models/Address";
import { UPDATE_USER_ADDRESS } from "../../Constants/user";
import { AxiosError } from "axios";
import { defaultErrorMessage } from "../../Constants/Requests";

export const UpdateAddressSliceAction = createAsyncThunk<UpdateAddressResponse, AddressPayload, { rejectValue: string }>(
  UPDATE_USER_ADDRESS,
  async (payload: AddressPayload, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post(
        `/persons/${payload.personId}/address`,
        {
          "address": payload.address
        }
      );
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError) {
        return error.response?.data.message
      }

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
