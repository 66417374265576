import { Flex } from "../Flex";
import Icon from "../Icon";

import {
  CustomText,
  PaymentItem,
  TransfersItemBullet,
  TransfersItemBulletIcon,
} from "./PaymentItem.styled";

export interface PaymentItemProps {
  title: string | null | undefined;
  subtitle: string;
  text: string;
  pay: string;
  type: "primary" | "success" | "disabled";
}

const PaymentItemComponent = ({
  title,
  subtitle,
  text,
  pay,
  type,
}: PaymentItemProps) => {
  return (
    <PaymentItem $type={type}>
      {type === "success" ? (
        <TransfersItemBulletIcon>
          <Icon icon="checkCircleGreen" size="medium" />
        </TransfersItemBulletIcon>
      ) : (
        <TransfersItemBullet $type={type} />
      )}
      <Flex justifyContent="space-between">
        <div>
          <CustomText
            color="Dark_03"
            size={0.5}
            align="left"
            weight={400}
            margin="2px 0"
          >
            {title}
          </CustomText>
          <CustomText
            color="grey"
            size={0.3}
            align="left"
            weight={400}
            margin="2px 0"
          >
            {subtitle}
          </CustomText>
          <CustomText
            className="bank-transfer-date"
            color="grey"
            size={0.3}
            align="left"
            weight={400}
            margin="2px 0"
          >
            {text}
          </CustomText>
        </div>
        <CustomText
          color="Dark_03"
          size={0.5}
          align="left"
          weight={600}
          margin="2px 0"
        >
          {pay}
        </CustomText>
      </Flex>
    </PaymentItem>
  );
};

export default PaymentItemComponent;
