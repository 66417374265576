import styled from "styled-components";
import { theme } from "styled-tools";

export const ContainerCard = styled.div`
  background-color: ${theme("Background_01")};
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow:0px 4px 24px 0px rgba(${theme("RGBA_01")}, 0.15);
  padding: 16px 0;
  height: 230px;
  margin: 24px 8px;
  text-align: left;

  button {
    max-width: 180px;
    margin-top: 0;

    path {
      fill: ${theme("Background_01")};
    }
  }
`;

export const TitleCard = styled.div`
  p {
    max-width: 200px;
  }
`