import { createAsyncThunk } from "@reduxjs/toolkit";
import { ItinPayload, SsnPayload } from "../../Models/SocialSecurityNumber";
import { UPDATE_USER_SSN } from "../../Constants/user";
import { defaultErrorMessage } from "../../Constants/Requests";
import { AxiosError } from "axios";

// Own services Api
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";


export const UpdateSsnSliceAction = createAsyncThunk(
  UPDATE_USER_SSN,
  async (payload: SsnPayload, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post(
        `/persons/${payload.personId}/ssn`,
        {
          "personId": payload.personId,
          "socialSecurityNumber": payload.ssn
        }
      );
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);

export const UpdateItinSliceAction = createAsyncThunk(
  UPDATE_USER_SSN,
  async (payload: ItinPayload, thunkAPI) => {
    try {
      const response = await BaseConfigApi.patch(
        `/persons/${payload.personId}/itin`,
        {
          "itin": payload.itin
        }
      );
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);