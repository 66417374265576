import { DragEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// components
import {
  Container,
  Row,
  Column
} from "../../Components/Grid";
import Icon from "../../Components/Icon";
import Button from "../../Components/Button";
import Text from "../../Components/Text";
import Title from "../../Components/Title";
import Modal from "../../Components/Modal";

// Interface
import { Props } from "../../Models/DragDrop";

import {
  ContentFile,
  DragDropStyled,
  ButtonContainer,
  ButtonContainerModal,
  ContainerModal,
  BoldText,
  DisclosureFile,
  ContainerText,
  Span,
  ReactWebCamContainer
} from "./DragDropFile.styled";

const DragDropFile = (props: Props) => {
  const {
    typeUpdateFile,
    onChange,
    nameInput,
    buttonSelectFile,
    errors,
    showBtnDelete,
    deleteImg,
    showInfo
  } = props;
  const [t] = useTranslation("global");
  const [, setIsOver] = useState<boolean>(false);
  const [, setFiles] = useState<File[]>([]);
  const [, setTypeFile] = useState<string>("");
  const [preview, setPreview] = useState<string>("");
  const [nameFile, setNameFile] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);

  /**
   * Method to upload files using input
   */
  // eslint-disable-next-line 
  const uploadDrop = (event: any) => {
    const [file] = event.target.files;
    setTypeFile(file.type);
    setNameFile(file.name);
    uploadFile(file);

    onChange(file);
  };

  /**
   * Get file uploaded by input
   */
  const uploadFile = (file: Blob) => {
    // Use FileReader to read file content
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = () => {
      // this is the base64 data
      const fileRes = btoa(String(reader.result));
      setPreview(`data:${file.type};base64,${fileRes}`);
    };

    reader.onerror = () => {
      console.error("There is a problem while uploading...");
    };
  };

  /**
   * Drag Method
   */
  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(true);
  };

  /**
   * Drag Method
   */
  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(false);
  };

  /**
   * Drag file upload method
   * @param event DragEvent
   */
  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(false);

    // Fetch the files
    const droppedFiles = Array.from(event.dataTransfer.files);
    setFiles(droppedFiles);
    setNameFile(droppedFiles[0].name);

    // Use FileReader to read file content
    droppedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        setTypeFile(file.type);
        setPreview(`${reader.result}`);
      };

      reader.onerror = () => {
        console.error("There was an issue reading the file.");
      };

      reader.readAsDataURL(file);
      onChange(file);

      return reader;
    });
  };

  /**
   * Method to delete the uploaded file
   */
  const handleDelete = () => {
    setPreview("");
    onChange("");
    setNameFile("");
    setShowModal(false);
  };

  const onOpenModal = () => {
    setShowModal(true);
  }

  const onCloseModal = () => {
    setShowModal(false);
  }

  const openFileInput = () => {
    const fileInput = document.getElementsByName("dragDropInput")[0];

    if (fileInput) {
      fileInput.click();
    }
  };

  useEffect(() => {
    handleDelete();
  }, [deleteImg]);

  return (
    <>
      {preview === "" ? (
        <>
          <DragDropStyled>
            <Icon fillIcon={false} icon="upload" size="xlarge" color="solid_2"/>
            <Text size={4} weight={600} margin="48px 0 0 0">
              {t("VisaVerification.inputUpload")}
              <BoldText>
                {t("VisaVerification.inputBrowse")}
              </BoldText>
            </Text>
            <input
              type="file"
              accept={typeUpdateFile}
              onChange={(e) => uploadDrop(e)}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              name={nameInput}
            />
          </DragDropStyled>
          { buttonSelectFile && (
            <Button
              sizeButton="large"
              onClick={openFileInput}
              sizeText="large"
              text={t("VisaVerification.button")}
              variant="primary"
              viewIcon={false}
            />
          )}
          {showInfo &&
            <DisclosureFile>
              <Icon fillIcon={false} icon="circleInfo" size="medium" color="black" />
              <Text size={1} weight={500} margin="0" align="left">
                {t("VisaVerification.infoUpload")}
              </Text>
            </DisclosureFile>
          }
        </>
      ) : (
        <ContentFile>
          { preview && (
            <Column span={12}>
              <ReactWebCamContainer>
                <img src={preview} width="100%" />
              </ReactWebCamContainer>
            </Column>
          )}
          <Text size={1} weight={500} margin="0" align="left">
            - { nameFile }
          </Text>
          {showBtnDelete &&
            <ButtonContainer>
              <Button
                type="button"
                sizeButton="large"
                sizeText="medium"
                text={t("buttons.removeFile")}
                variant="primary"
                onClick={() => onOpenModal()}
                viewIcon={false}
                iconButtonLeft="trash"
                sizeIcon="medium"
                colorIcon="white"
              />
            </ButtonContainer>
          }
        </ContentFile>
      )}
      {errors[nameInput] && (
        <ContainerText>
          {errors[nameInput].message &&
            <Icon
              icon={"alertTriangle"}
              color={"error"}
              size="small"
              fillIcon={false}
            />
          }
          <Span>{errors[nameInput].message}</Span>
        </ContainerText>
      )}
      <Modal show={showModal}>
        <Container>
          <Row>
            <Column span={12} as={ContainerModal}>
              <Title
                tagName="h1"
                text={t("AddressVerification.modalDeletion.title")}
                size={5}
                color="black"
              />
              <Text size={3} margin="32px 0">
                {t("AddressVerification.modalDeletion.message")}
              </Text>
              <ButtonContainerModal>
                <Button
                  type="button"
                  sizeButton="large"
                  sizeText="medium"
                  text={t("AddressVerification.modalDeletion.confirm")}
                  variant="primary"
                  onClick={() => handleDelete()}
                  viewIcon={false}
                />
                <Button
                  type="button"
                  sizeButton="large"
                  sizeText="medium"
                  text={t("AddressVerification.modalDeletion.cancel")}
                  variant="secondary"
                  onClick={() => onCloseModal()}
                  viewIcon={false}
                />
              </ButtonContainerModal>
            </Column>
          </Row>
        </Container>
      </Modal>
    </>
  );
};

export default DragDropFile;
