import { createSlice } from "@reduxjs/toolkit";
import { GetLanguageSettingAction } from "./LanguageSettings.actions";
import { State } from "../Store";
import { GetLanguageState } from "../../Models/Language";

const initialState: GetLanguageState = {
  loading: false,
  error: null,
  data: null
}

export const GetLanguageSettingSlice = createSlice({
  name: "getLanguageSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetLanguageSettingAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetLanguageSettingAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(GetLanguageSettingAction.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export default GetLanguageSettingSlice.reducer;

export const GetLanguageSettingSliceSelector = (state: State) => state.getLanguageSettings;
