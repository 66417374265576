import styled from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { theme } from "styled-tools";
import BgBanner from "../../Assets/Img/BgBanner.png";

export const BannerContainer = styled.div<{$heightAuto:boolean}>`
  border-radius: 12px;
  background: ${theme("Background_11")};
  padding: 12px 8px;
  background-image: url(${BgBanner});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: ${(props) => props.$heightAuto ? "auto" : "122px" };
  margin: 0;
  position: relative;
  z-index: 9;

  button {
    border: none;
    border-radius: 50px;
    background: ${theme("Background_02")};
    display: flex;
    width: 143px;
    height: 36px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: ${theme("Background_01")};
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;

    a {
      color: ${theme("Background_01")};
      font-weight: 600;
    }
  }

  ${mediaQuery[Screen.desktop]} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const containerStyled = styled.div`
  padding: 0;
`;

export const RowBottomFixed = styled.div`
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 12px 24px;
`;