import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, State } from "@redux/Store";
import { ThemeProvider } from "styled-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import { Column, Container, Row } from "@components/Grid";
import Icon from "@components/Icon";
import Text from "@components/Text";

import {
  AccountsCard,
  ActionButtons,
  AddBeneficiaryBtn,
  BeneficiaryDisplayName,
  BeneficiaryName,
  CardNumber,
} from "./BeneficiaryCard.styled";

import { AlignItems, JustifyContent } from "@models/Column";
import { BeneficiarySlice } from "@redux/Payments/Beneficiary";
import { BeneficiarySelect } from "@core/Payments/Beneficiary/domain/Beneficiary";
import Modal from "@components/Modal";
import Button from "@components/Button";

import { FlexContainer, FlexItem } from "@components/Flex/Flex.styled";
import { BeneficiaryCardProps } from "@core/Payments/Beneficiary/domain/BeneficiaryCardProps";

import { maskInterbankKey } from "@utils/String";
import IconShape from "@components/IconShape";
import { RecipientExchangeRates } from "@core/Payments/Common/domain/ExchangeRates";
import Link from "@components/Link";
import { CardButton, ClickableCardButton } from "@components/Card/Card.styled";

const BeneficiaryCard = ({
  withAddButton,
  withAccountSelect,
  withEditButtons,
  withUserIcon = true,
  onClick,
  onClickEdit,
  onAddButtonClick,
  ...beneficiary
}: BeneficiaryCardProps) => {
  const theme = useSelector((state: State) => state.theme);
  const [t] = useTranslation("global");
  const dispatch = useDispatch<AppDispatch>();
  const [cashPickUp, setCashPickUp] = useState(false);

  const [openModalConfirmationDelete, setOpenModalConfirmationDelete] = useState(false);

  const handleEditBeneficiary = async (beneficiary: BeneficiarySelect) => {
    dispatch(BeneficiarySlice.actions.setBeneficiaryEdit(beneficiary));
    onClickEdit && onClickEdit(beneficiary);
  };

  useEffect(() => {
    const banckAccount = beneficiary.account.find((item) => item.accountNumber);
    if (isEmpty(banckAccount)) {
      setCashPickUp(true);
    }
  }, [beneficiary]);

  const handleConfirmationDelete = () => {
    setOpenModalConfirmationDelete(!openModalConfirmationDelete);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container padding="0">
        <Row>
          <Column
            span={12}
            as={onClick ? ClickableCardButton : CardButton}
            mb={2}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              !withAccountSelect && onClick && onClick(beneficiary);
            }}
            alignItems={AlignItems.center}
          >
            <FlexContainer w="100%" p="1rem" gap="5%" alignItems="center">
              {withUserIcon ? (
                <Column justifyContent={JustifyContent.flexStart}>
                  <IconShape icon="user" iconSize="medium" gradient />
                </Column>
              ) : (
                <FlexItem h="100%" alignItems="center">
                  <img
                    width={44}
                    src={RecipientExchangeRates.find((exchange) => exchange.countryCode === beneficiary.country)?.icon}
                  />
                </FlexItem>
              )}
              <FlexItem h="100%" w="64%" direction="column" justify="center" flexWrap>
                <BeneficiaryDisplayName>
                  <Text size={1.5} weight={500} margin="0" align="left">
                    {beneficiary.alias || beneficiary.fullName}
                  </Text>
                </BeneficiaryDisplayName>
                {beneficiary.alias && (
                  <BeneficiaryDisplayName>
                    <Text size={0.5} weight={400} margin="0" align="left">
                      {beneficiary.fullName}
                    </Text>
                  </BeneficiaryDisplayName>
                )}
                {withAccountSelect && (
                  <BeneficiaryName>
                    <img
                      src={
                        RecipientExchangeRates.find((exchange) => exchange.countryCode === beneficiary.country)?.icon
                      }
                    />
                    <Text size={1} weight={400} color="grey" margin="8px 0" align="left">
                      {beneficiary.country}
                    </Text>
                  </BeneficiaryName>
                )}
              </FlexItem>
              {withEditButtons && (
                <FlexItem flex="1 1 auto" alignSelf="center" justify="end" direction="column" as={ActionButtons}>
                  <Link href="#" variant="primary" onClick={() => handleEditBeneficiary(beneficiary)}>
                    {t("global.edit")}
                  </Link>
                </FlexItem>
              )}
            </FlexContainer>
            {!cashPickUp && withAccountSelect && (
              <FlexContainer direction="column" p="1rem 0" gap="0.7rem">
                {beneficiary.account.map(
                  (account, index) =>
                    account.accountNumber && (
                      <FlexContainer key={index} justify="end">
                        <FlexContainer
                          w="auto"
                          gap="0.3rem"
                          as={AccountsCard}
                          select={withAccountSelect}
                          alignItems="center"
                          onClick={() => withAccountSelect && onClick && onClick(beneficiary, account)}
                        >
                          <FlexItem w="15px" alignItems="center">
                            <img
                              width="15px"
                              src={
                                RecipientExchangeRates.find((exchange) => exchange.countryCode === account.country)
                                  ?.icon
                              }
                            />
                          </FlexItem>
                          <FlexItem
                            w="max-content"
                            as={CardNumber}
                            my={1}
                            justifyContent={JustifyContent.flexEnd}
                            flex="1 1 auto"
                          >
                            {account.bankName}
                          </FlexItem>
                          <FlexItem w="auto" justifyContent={JustifyContent.flexEnd} my={1} as={CardNumber}>
                            {maskInterbankKey(account.accountNumber)}
                          </FlexItem>
                          {withAccountSelect && (
                            <FlexItem w="auto">
                              <Icon icon="chevronRight" color="grey" size="medium" />
                            </FlexItem>
                          )}
                        </FlexContainer>
                      </FlexContainer>
                    )
                )}
              </FlexContainer>
            )}
            {cashPickUp && withAccountSelect && (
              <FlexContainer direction="column" p="1rem 0" gap="0.7rem">
                <Row>
                  <Column span={10} as={CardNumber} my={1} justifyContent={JustifyContent.flexEnd}>
                    <span>{t("Quote.CashPickUp")}</span>
                  </Column>
                  <Column span={2} justifyContent={JustifyContent.center} my={1} as={CardNumber}>
                    <Icon icon="simpleCash" color="black" size="medium" />
                  </Column>
                </Row>
              </FlexContainer>
            )}

            {/* to do: Commented for future version */}
            {withAddButton && (
              <FlexContainer m="auto 0 0 0" justify="end">
                <FlexItem
                  w="min-content"
                  flex="1 1 auto"
                  as={AddBeneficiaryBtn}
                  type="button"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    onAddButtonClick && onAddButtonClick();
                  }}
                >
                  <Icon icon="cirlePlus" color="black" size="medium" />
                  <Text size={0} color="black" margin="0" align="left" weight="600">
                    {t("Beneficiaries.ButtonNewAccount")}
                  </Text>
                </FlexItem>
              </FlexContainer>
            )}
          </Column>
        </Row>
      </Container>
      <Modal show={openModalConfirmationDelete} handleClose={handleConfirmationDelete}>
        <IconShape icon="circle-check" iconSize="xlarge" border="rounded" bgColor="primary" height={75} width={75} />
        <Text size={3} weight={700}>
          {t("The recipient has been successfully deleted")}
        </Text>
        <Button text={t("buttons.next")} onClick={handleConfirmationDelete} sizeText="medium" variant="primary" />
      </Modal>
    </ThemeProvider>
  );
};

export default BeneficiaryCard;
