import PinwheelModal, { SuccessEventPayload } from "@pinwheel/react-modal";

type Props = {
  token: string;
  isActive: boolean;
  handleSuccess?: (event: SuccessEventPayload) => void;
  handleExit?: () => void;
};

const onSuccess = (
  event: SuccessEventPayload,
  handleSuccess?: (event: SuccessEventPayload) => void,
) => {
  if (handleSuccess) {
    handleSuccess(event);
  }
};

const onEvent = (_event: string, handleExit?: () => void) => {
  if (handleExit && _event === 'exit') {
    handleExit();
  }
};

export const PinWheel = ({ token, isActive, handleSuccess, handleExit}: Props) => {
  return (
    <PinwheelModal
      onEvent={(event) => onEvent(event, handleExit)}
      onSuccess={(event) => onSuccess(event, handleSuccess)}
      linkToken={token}
      open={isActive}
    />
  );
};
