import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash.isempty";
import { AppDispatch } from "@redux/Store";
import { countrySelector, CountrySlice } from "@redux/Payments/Country/Country.slice";
import { SelectOption } from "@models/Select";
import { ApiRequestError } from "@core/Common/domain/ApiRequestError";
import { createApiCountryRepository } from "@core/Payments/Common/infraestructure/ApiCountryRepository";
import { getAllCountries, getCountry } from "@core/Payments/Common/application/getCountry";
import { CountryWithCurrency } from "@core/Payments/Common/domain/Countries";
import { useStatus } from "./useStatus";

export const useCountry = () => {
  const { status, isLoading, setStatus, setError } = useStatus();
  const dispatch = useDispatch<AppDispatch>();
  // const { language } = useSelector(selectorLanguage);
  // const { paymentMethodSelected } = useSelector(QuotationSelector).data;
  // const languagePrev = useRef<string>(language);
  // const paymentSourcePrev = useRef<PaymentMethods | undefined>();
  const allDestinationCountries = useSelector(countrySelector).countries;
  const [countries, setCountries] = useState<CountryWithCurrency[]>(allDestinationCountries);
  const [allCountries, setAllCountries] = useState<CountryWithCurrency[]>([]);
  const allCountriesSelectOption = useMemo(
    () =>
      allCountries.map((c) => ({
        label: c.countryName,
        value: c.countryCode,
        shortCode: c.alpha2Code,
        image: c.icon,
        shortLabel: c.defaultCurrency,
        defaultCurrency: c.defaultCurrency,
      })) as (SelectOption & { shortCode: string; shortLabel: string })[],
    [allCountries]
  );

  const countriesWithoutUSAOptions: (SelectOption & { shortCode: string; shortLabel: string })[] =
    allDestinationCountries
      .filter((country) => country.countryCode !== "USA")
      .map((c) => ({
        label: c.countryName,
        value: c.countryCode,
        shortCode: c.alpha2Code, // For the phone number input
        image: c.icon,
        shortLabel: c.defaultCurrency,
        defaultCurrency: c.defaultCurrency,
      }));

  const getDestinationCountryList = async (language: string, sponsorBank?: string) => {
    setStatus("loading");
    try {
      const countriesList = await getCountry(createApiCountryRepository())(language, sponsorBank);
      setCountries(countriesList);
      dispatch(CountrySlice.actions.setCountryList(countriesList));
      setStatus("idle");
      return countriesList;
    } catch (error) {
      setStatus("error");
      setError(error as ApiRequestError);
    }
  };

  const getAllCountriesList = async (language: string) => {
    setStatus("loading");
    try {
      const countries = await getAllCountries(createApiCountryRepository())(language);

      if (!isEmpty(countries)) {
        setAllCountries(countries);
      } else {
        setAllCountries([]);
      }
      setStatus("idle");
      return countries;
    } catch (error) {
      setStatus("error");
      setError(error as ApiRequestError);
    }
  };

  return {
    status,
    isLoading,
    countries,
    countriesWithoutUSAOptions,
    allCountries,
    allCountriesSelectOption,
    getDestinationCountryList,
    getAllCountriesList,
  };
};
