import { StylesConfig } from "react-select";
import { SelectOption } from "../../Models/Select";
import { selectStyles } from "../SearchSelect/SearchSelectTheme";

export const currencySelectStyles: StylesConfig<SelectOption> = {
  container: (base, props) => ({
    ...selectStyles.container?.call(this, base, props),
    marginLeft: props.selectProps.menuIsOpen ? "0px" : "auto",
    padding: props.selectProps.menuIsOpen ? "0" : base.padding,
  }),
};
