import styled from "styled-components";
import { theme } from "styled-tools";

export const PaginationSection = styled.div`
  .page-item a.page-link:hover {
    background-color: linear-gradient(90deg, ${theme("Neutral_900")} 61%, ${theme("Primary_01_700")} 100%);
    color: ${theme("Background_01")};
  }

  .page-item.active .page-link {
    font-weight: 700;
    color: ${theme("Background_01")};
    background-color: ${theme("Primary_01")};
    border: ${theme("Primary_01")};
  }

  .page-item.disabled .page-link {
    color: ${theme("Background_04")};
    pointer-events: none;
    cursor: auto;
  }

  .page-item .page-link {
    color: ${`linear-gradient(90deg, ${theme("Neutral_900")} 61%, ${theme("Primary_01_700")} 100%)`};
  }
`;
