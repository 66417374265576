import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { AppDispatch, State } from "../../../Redux/Store";
import { ByUserSliceSelector } from "../../../Redux/ByUser/ByUser.slice";

import { GetPayrollPdf } from "../../../Redux/PayrollPdf/PayrollPdf.actions";
import { getPersonPayrollInfo } from "../../../Redux/PersonPayrollInfo/PersonPayrollInfo.actions";

import { formatDatePayroll, maskInterbankKey } from "../../../Utilities/String";

import { states } from "../../../Constants/StatesUS";
import { allowedNames, allowedNumbers, allowedWord } from "../../../Constants/Regexp";
import { EmployerList } from "../../../Constants/Customers";
import {
  GET_PAYROLL_PDF_FULFILLED,
  GET_PAYROLL_PDF_REJECTED,
  GET_PERSON_PAYROLL_INFO_FULFILLED,
  GET_PERSON_PAYROLL_INFO_REJECTED
} from "../../../Constants/Payroll";

import Layout from "../../../Components/Layout";
import Text from "../../../Components/Text";
import { Column, Container, Row } from "../../../Components/Grid";
import Input from "../../../Components/Input";
import CheckboxInput from "../../../Components/Checkbox";
import Button from "../../../Components/Button";
import WhatsAppConfirmation from "./WhatsAppConfirmation.component";
import PDFViewer from "../../../Components/PDFViewer";
import LoaderIcon from "../../../Components/LoaderIcon/LoaderIcon.component";

import {
  PaddingLeft,
  FormContainer,
  InputContainer,
  DateContainer,
  InputCheck,
  ButtonContainer,
  PopupOverlay,
  Overlay
} from "./PayrollUpdate.styled";

export interface States {
  label: string | undefined;
  value: string | undefined;
  visible: boolean;
}

const PayrollUpdate = () => {
  const theme = useSelector((state: State) => state.theme);
  const { t } = useTranslation("global");
  const dispatch = useDispatch<AppDispatch>();
  const lang = useSelector((state: State) => state.language);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] =useState(false);
  const [showWsp, setShowWsp] = useState(false);
  const [typeBtn, setTypeBtn] = useState("whatsApp");
  const [showPDF, setShowPdf] = useState(false);
  const [urlPdf, setUrlPdf] = useState("");
  const [stateToShow, setStateToShow] = useState<States[]>(states)

  const ByUser = useSelector(ByUserSliceSelector);

  const personId = useMemo(() =>
    ByUser.data?.personId
  ,[ByUser])

  const customerId = useMemo(() =>
    ByUser.data?.customerId,
  [ByUser]);

  // Get Employer
  const employer = EmployerList.find(e => e.id === customerId);
  const employerName = employer?.name;

  const currentDate = new Date();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async () => {
    setLoadingBtn(true);

    const payload = {
      personId: personId,
      customerId: customerId
    }

    const response = await dispatch(GetPayrollPdf(payload));

    if (response?.type === GET_PAYROLL_PDF_FULFILLED) {
      setLoadingBtn(false);

      const phone = response.payload.phoneNumber;
      const message = response.payload.message;
      const url = response.payload.urFile;

      if (typeBtn === "whatsApp") {
        setShowWsp(true);
        window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${message}`, '_blank');
      } else {
        setUrlPdf(url);
        setShowPdf(true);
      }
    }

    if (response?.type === GET_PAYROLL_PDF_REJECTED) {
      setLoadingBtn(false);
    }
  };

  const removePrefixUSA = (data: string) => {
    if (data.startsWith("USA-")) {
      return data.slice(4);
    } else {
      return data;
    }
  }

  const getPersonInfo = async () => {
    setIsLoading(true);

    const payload = {
      personId: personId,
      customerId: customerId
    }

    const response = await dispatch(getPersonPayrollInfo(payload));

    if (response?.type === GET_PERSON_PAYROLL_INFO_FULFILLED) {
      setStateToShow(states.filter(e => e.value === removePrefixUSA(response.payload.customer.address?.state)))

      setValue("legalName", response.payload.person.name, { shouldValidate: true });
      setValue("employer", response.payload.customer.name, { shouldValidate: true });
      setValue("line1", response.payload.customer.address?.line1, { shouldValidate: true });
      setValue("line2", response.payload.customer.address?.line2, { shouldValidate: true });
      setValue("city", response.payload.customer.address?.city, { shouldValidate: true });
      setValue("state", response.payload.customer.address?.state, { shouldValidate: true });
      setValue("zipCode", response.payload.customer.address?.postalCode, { shouldValidate: true });
      setValue("account", maskInterbankKey (response.payload.person.account.accountNumber), { shouldValidate: true });
      setValue("routing", response.payload.person.account.routingNumber, { shouldValidate: true });
      setValue("state", stateToShow[0].label, { shouldValidate: true });

      setIsLoading(false);
    }

    if (response?.type === GET_PERSON_PAYROLL_INFO_REJECTED) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if(personId && customerId) {
      getPersonInfo();
    }
  }, [personId, customerId]);

  return (
    <ThemeProvider theme={theme}>
      <Layout show={isLoading}>
        <LoaderIcon
          title={t("loaderText.title")}
          text={t("loaderText.subtitle")}
          description={t("loaderText.description")}
        />
      </Layout>
      <Layout textLink={t("buttons.back")} path="/payroll-instructions">
        <PopupOverlay $show={showWsp}>
          <Overlay $show={showWsp}></Overlay>
          <Container>
            <Row>
              <Column span={12} lg={7} xl={7} as={PaddingLeft} my={3}>
                <Text size={3} color="black" weight="600" margin="0 0" align="left">
                  {t("Payroll.title")}
                </Text>
                <Text size={1} color="grey" align="left">
                  {t("Payroll.message")}
                </Text>
              </Column>
              <Column span={12} as={FormContainer} lg={7} xl={7}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <InputContainer>
                    <Text size={3} color="black" weight="600" margin="20px 0" align="left">
                      {t("Payroll.name")}
                    </Text>
                    <Text size={1} color="grey" margin="10px 0" align="left">
                      {t("Payroll.nameSubtitle")}
                    </Text>
                    <Input
                      typeInput="text"
                      label="legalName"
                      register={register}
                      rules={{
                        required: t("Forms.required"),
                        pattern: {
                          value: allowedNames,
                          message: t("Forms.inputAlphabets"),
                        },
                      }}
                      readOnly
                      maxLengthInput={70}
                      errors={errors}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Text size={3} color="black" weight="600" margin="10px 0" align="left">
                      {t("Payroll.employer")}
                    </Text>
                    <Input
                      typeInput="text"
                      label="employer"
                      register={register}
                      rules={{
                        required: t("Forms.required"),
                      }}
                      readOnly
                      maxLengthInput={70}
                      errors={errors}
                    />
                  </InputContainer>
                  <Text size={3} color="black" weight="600" margin="10px 0" align="left">{t("Payroll.address")}</Text>
                  <InputContainer>
                    <Input
                      typeInput="text"
                      label="line1"
                      register={register}
                      rules={{
                        pattern: {
                          value: allowedWord,
                          message: t("Forms.inputAlphabets"),
                        },
                      }}
                      readOnly
                      maxLengthInput={70}
                      errors={errors}
                    />
                    <Input
                      typeInput="text"
                      label="line2"
                      register={register}
                      rules={{
                        pattern: {
                          value: allowedWord,
                          message: t("Forms.inputAlphabets"),
                        },
                      }}
                      readOnly
                      maxLengthInput={70}
                      errors={errors}
                    />
                    <Input
                      typeInput="text"
                      label="city"
                      register={register}
                      rules={{
                        pattern: {
                          value: allowedWord,
                          message: t("Forms.inputAlphabets"),
                        },
                      }}
                      readOnly
                      maxLengthInput={70}
                      errors={errors}
                    />

                    <Input
                      typeInput="text"
                      label="state"
                      register={register}
                      rules={{
                        pattern: {
                          value: allowedWord,
                          message: t("Forms.inputAlphabets"),
                        },
                      }}
                      readOnly
                      maxLengthInput={70}
                      errors={errors}
                    />
                    <Input
                      typeInput="number"
                      label="zipCode"
                      register={register}
                      rules={{
                        pattern: {
                          value: allowedNumbers,
                          message: t("Forms.manyCharacters"),
                        },
                        maxLength: {
                          value: 5,
                          message: t("Forms.manyCharacters"),
                        },
                      }}
                      readOnly
                      maxLengthInput={70}
                      errors={errors}
                    />
                  </InputContainer>
                  <Text size={1} color="grey" margin="14px 0" align="left">
                    {t("Payroll.message2")}
                  </Text>
                  <InputContainer>
                    <Text size={2} color="black" weight="600" margin="20px 0" align="left">
                      {t("Payroll.account")}
                    </Text>
                    <Input
                      typeInput="text"
                      label="account"
                      register={register}
                      rules={{
                        required: t("Forms.required"),
                      }}
                      readOnly
                      maxLengthInput={70}
                      errors={errors}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Text size={2} color="black" weight="600" margin="20px 0" align="left">
                      {t("Payroll.routing")}
                    </Text>
                    <Input
                      typeInput="text"
                      label="routing"
                      register={register}
                      rules={{
                        required: t("Forms.required"),
                      }}
                      readOnly
                      maxLengthInput={70}
                      errors={errors}
                    />
                  </InputContainer>
                  <DateContainer>
                    <Text size={2} color="black" margin="14px 0" weight="600" align="left">
                      {t("Payroll.date")}
                    </Text>
                    <Text size={1} color="black_highlight" margin="14px 0" align="left">
                      {formatDatePayroll(currentDate,  lang.language)}
                    </Text>
                  </DateContainer>
                  <InputCheck>
                    <CheckboxInput
                      id="check-legal-terms-gp-2"
                      variant="checkbox"
                      label="checkLegalUSITerms"
                      register={register}
                      errors={errors}
                      rules={{
                        required: t("Forms.required"),
                      }}
                    >
                      <Text size={1} margin="0" color="black_highlight" align="start">
                        {t("Payroll.authorization")}{employerName}{t("Payroll.authorization2")}
                      </Text>
                    </CheckboxInput>
                  </InputCheck>
                  <Column span={12} >
                    <ButtonContainer $valid={isValid}>
                      <Button
                        type="submit"
                        variant={!isValid || isLoading ? "default" : "primary"}
                        text={t("Payroll.buttonSend")}
                        sizeText="medium"
                        sizeButton="xlarge"
                        iconButton="whatsApp"
                        sizeIcon="large"
                        disabled={!isValid}
                        onClick={() => setTypeBtn("whatsApp")}
                        loading={loadingBtn ? 1 : 0}
                      />
                      <Button
                        type="submit"
                        variant={!isValid || isLoading ? "default" : "outline"}
                        text={t("Payroll.buttonForm")}
                        sizeText="medium"
                        sizeButton="xlarge"
                        disabled={!isValid}
                        onClick={() => setTypeBtn("download")}
                      />
                    </ButtonContainer>
                  </Column>
                </form>
              </Column>
            </Row>
          </Container>
          <WhatsAppConfirmation
            show={showWsp}
            isValid={isValid}
            onHide={() => setShowWsp(false)}
          />
          <PDFViewer
            directUrl={urlPdf}
            canDownload
            open={showPDF}
            onClose={() => setShowPdf(false)}
          >
          </PDFViewer>
        </PopupOverlay>
      </Layout>
    </ThemeProvider>
  )
}

export default PayrollUpdate;