export const privateRoutes = [
  "/",
  "/kyc-personal-data",
  "/kyc-personal-address",
  "/kyc-shipping-address",
  "/kyc-email",
  "/kyc-ssn",
  "/card",
  "/transactions",
  "/transactions-detail",
  "/payroll"

];
