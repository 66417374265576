import axios from "axios";
import { baseURL, API_KEY, baseURLiam, API_KEY_IAM } from "../../Constants/ConfigureApi";
import Cookies from "universal-cookie";
import { TOKEN_COOKIE_NAME } from "../../Constants/Auth";

export const BaseConfigApi = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": API_KEY
  }
});

BaseConfigApi.interceptors.request.use(
  function(config) {
    const cookies = new Cookies();
    const token = cookies.get(TOKEN_COOKIE_NAME);
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export const BaseConfigApiIam = axios.create({
  baseURL: baseURLiam,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": API_KEY_IAM
  }
});

BaseConfigApiIam.interceptors.request.use(
  function(config) {
    const cookies = new Cookies();
    const token = cookies.get(TOKEN_COOKIE_NAME);
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);