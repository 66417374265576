import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { defaultErrorMessage } from "../../Constants/Requests";
import { IDV_SSN } from "../../Constants/IDV";

/**
 * Method to update SSN fo Veriff
 */
export const IdvSSNAction = createAsyncThunk(
  IDV_SSN,
  async (_, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post(`/persons/idv/ssn`);
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);