import { PropsWithChildren } from "react";
import Icon from "../Icon";
import { AlerBoxContainer, AlerBoxIconContainer, AlertBoxContent } from "./AlertBox.styled";
import { Column, Container, Row } from "../Grid";

export const AlertBox = ({ children }: PropsWithChildren) => {
  return (
    <AlerBoxContainer>
      <Container>
        <Row>
          <Column span={12}>
            <AlerBoxIconContainer>
              <Icon icon="timeClockLoading" color="error" size="medium" />
              <AlertBoxContent>{children}</AlertBoxContent>
            </AlerBoxIconContainer>
          </Column>
        </Row>
      </Container>
    </AlerBoxContainer>
  );
};
