import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";

// Own services store
import { State } from "../../../Redux/Store";

// Own utils
import { formatPhoneNumber } from "../../../Utilities/phoneNumberFormat";

// Own components
import Title from "../../../Components/Title";
import { Column, Container, Row } from "../../../Components/Grid";
import Layout from "../../../Components/Layout";
import EditInformation from "../../../Components/EditInformation";
import ContactSupportModal from "./ContactSupportModal.component";

// Owwn Styles
import { AccountSection, StepsContainer } from "./AccountDetails.styled";
import ConstructionPopUp from "../../../Components/ConstructionPopUp";
import { PrefilledDataSelector } from "../../../Redux/PrefilledData/PrefilledData.slice";

const AccountDetails = () => {
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const [showModalPerson, setModalPerson] = useState(false);
  const [isShowPopup, setIsShowPopup] = useState(true);
  const handlePopUp = () => setIsShowPopup(!isShowPopup);

  // Get User data
  const userDataSelector = useSelector(PrefilledDataSelector);
  const userData = userDataSelector?.data;

  const phone = formatPhoneNumber(userData?.phone || "");

  return (
    <ThemeProvider theme={theme}>
      <Layout textLink={t("buttons.back")} path="/settings">
        <Container as={StepsContainer}>
          <Row>
            <Column span={12}>
              <Row>
                <Column span={12}>
                  <Row>
                    <Column span={12}>
                      <AccountSection>
                        <Title
                          tagName="h1"
                          text={t("AccountDetails.personalInfo.title")}
                          size={18}
                          color="black"
                          align="left"
                        />
                        <EditInformation
                          title={t("AccountDetails.personalInfo.name")}
                          text={
                            userData
                              ? `${userData?.firstName} ${userData?.middleName} ${userData?.lastName}`
                              : ""
                          }
                        />
                        <EditInformation
                          title={t("AccountDetails.personalInfo.phone")}
                          text={phone || ""}
                        />
                        <EditInformation
                          title={t("AccountDetails.personalInfo.email")}
                          onClickIcon={() => setModalPerson(true)}
                          text={userData ? userData?.email : ""}
                        />
                        <EditInformation
                          title={t("AccountDetails.personalInfo.address")}
                          text={
                            userData
                              ? `${userData?.address?.country}, ${userData?.address?.line1}, ${userData?.address?.locality}, ${userData?.address?.countrySubdivision}, ${userData?.address?.zipCode}`
                              : ""
                          }
                        />
                      </AccountSection>
                    </Column>
                  </Row>

                  <Row>
                    <Column span={12} mb={6}>
                      <AccountSection>
                        <Title
                          tagName="h1"
                          text={t("AccountDetails.security.title")}
                          size={18}
                          color="black"
                          align="left"
                        />
                        <EditInformation
                          title={t("AccountDetails.security.password")}
                          onClickIcon={handlePopUp}
                          isPassword
                          text="••••••"
                        />
                      </AccountSection>
                    </Column>
                  </Row>
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
        <ContactSupportModal
          showModal={showModalPerson}
          titleText={t("AccountDetails.edit.personalTitle")}
          infoText={t("AccountDetails.edit.personalText")}
          closeModal={() => setModalPerson(false)}
        />
        <ConstructionPopUp handlePopUp={handlePopUp} isPopout={isShowPopup} />
      </Layout>
    </ThemeProvider>
  );
};

export default AccountDetails;
