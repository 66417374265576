import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

import { State } from "../../Redux/Store";

import Layout from "../../Components/Layout";
import { Column, Container, Row } from "../../Components/Grid";
import StepsRoutes from "../../Components/StepsRoutes";
import Title from "../../Components/Title";
import Text from "../../Components/Text";
import { Flex } from "../../Components/Flex";
import BannerAccount from "../../Components/BannerAccount";

import BannerBank from "../../Assets/Img/banner-bank.png";
import Coppel from "../../Assets/Img/coppel-card.png";
import USI from "../../Assets/Img/usi-white.png";
import Bank from "../../Assets/Icons/bank.svg"

import { ImgContainer, Line } from "./SelectAccountType.styled";
import { StepsContainer } from "../KYC/LegalName/LegalName.styled";

const SelectAccountType = () => {
  const {t} = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);

  return (
    <ThemeProvider theme={theme}>
      <Layout
        path={"/"}
        textLink={t("buttons.back")}
      >
        <Container as={StepsContainer}>
          <Row>
            <Column span={12}>
              <Row>
                <Column span={12}>
                  <Row>
                    <Column span={12}>
                      <StepsRoutes totalSteps={6} stepIndex={4} />
                    </Column>
                  </Row>
                  <Row>
                    <Column span={12} mt={1}>
                      <Title
                        tagName="h1"
                        color="black"
                        size={2}
                        text={t("LinkedAccounts.accountType.title")}
                        align="left"
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column span={12}>
                      <BannerAccount
                        imgBox={
                          <img src={BannerBank} alt={"bannerBank"} />
                        }
                        typeAccount={"bannerBank"}
                        feeText={t("LinkedAccounts.accountType.noFee")}
                        title={t("LinkedAccounts.accountType.bannerBank.title")}
                        message={t("LinkedAccounts.accountType.bannerBank.message")}
                      />
                    </Column>
                    <Column span={12}>
                      <BannerAccount
                        imgBox={
                          <img src={Coppel} alt={"bannerCoppel"} />
                        }
                        typeAccount={"bannerCoppel"}
                        feeText={t("LinkedAccounts.accountType.fee")}
                        title={t("LinkedAccounts.accountType.bannerCoppel.title")}
                        message={t("LinkedAccounts.accountType.bannerCoppel.message")}
                      />
                    </Column>
                    <Column span={12}>
                      <BannerAccount
                        imgBox={
                          <ImgContainer $background={"bannerUSI"}><img src={USI} alt={"bannerUSI"} /></ImgContainer>
                        }
                        typeAccount={"bannerUSI"}
                        feeText={t("LinkedAccounts.accountType.fee")}
                        title={t("LinkedAccounts.accountType.bannerUSI.title")}
                        message={t("LinkedAccounts.accountType.bannerUSI.message")}
                      />
                    </Column>
                    <Column span={12}>
                      <Flex>
                        <Line />
                        <Text size={0.5} weight={600} color="grey" margin={"14px 16px"}>{t("LinkedAccounts.accountType.or")}</Text>
                        <Line />
                      </Flex>
                    </Column>
                    <Column span={12}>
                      <BannerAccount
                        imgBox={
                          <ImgContainer $background={"bannerBankAccount"}><img src={Bank} alt={"bannerUSI"} /></ImgContainer>
                        }
                        typeAccount={"bannerBankAccount"}
                        feeText={t("LinkedAccounts.accountType.noFee")}
                        title={t("LinkedAccounts.accountType.bannerYourBank.title")}
                        message={t("LinkedAccounts.accountType.bannerYourBank.message")}
                      />
                    </Column>
                  </Row>
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  )
}

export default SelectAccountType