import styled from "styled-components";
import { theme } from "styled-tools";

export const StepContainer = styled.div<{ $enabledStatus?: boolean }>`
  display: flex;
  padding: 16px 0;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  position: relative;
  :last-child:before {
    content: "";
    position: absolute;
    border-radius: 2px;
    background: ${theme("Primary_01")};
    height: 50px;
    width: 2px;
    top: ${(props) => (props.$enabledStatus ? "42%" : "46%")};
    transform: translateY(-50%);
    left: 20px;
  }
  :last-child:after {
    content: "";
    position: absolute;
    border-radius: 2px;
    background: ${theme("Primary_01")};
    height: 50px;
    width: 2px;
    top: ${(props) => (props.$enabledStatus ? "42%" : "46%")};
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 20px;
  }
`;

export const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  margin-left: 8px;

  :last-child {
    margin-top: 30px;
  }
`;

export const StepStyle = styled.div<{ $step: string }>`
  width: 24px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => (props.$step === "completed" ? theme("Primary_12") : theme("Background_01"))};
  border: 3px solid ${(props) => (props.$step === "completed" ? theme("Primary_12") : theme("Primary_01"))};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StepCheckStyle = styled.div`
  border-radius: 50%;
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
`;

export const StepCount = styled.span`
  font-size: 15px;
  color: ${theme("Primary_01")};
  background: ${theme("Primary_01")};
  border-radius: 50%;
  width: 10px;
  height: 10px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export const StepsLabelContainer = styled.div`
  top: 50px;
  width: 100%;
  left: 70px;
  text-align: left;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
`;

export const StepLabel = styled.span<{
  $enabledStatus?: boolean;
  $active?: boolean;
}>`
  color: ${(props) =>
    props.$active ? (props.$enabledStatus ? theme("Primary_13") : theme("Background_03")) : theme("Primary_13")};
  font-size: 14px;
  font-weight: 400;
`;

export const StepText = styled.span<{
  $enabledStatus?: boolean;
  $active?: boolean;
}>`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  font-family: Poppins;
  color: ${(props) =>
    props.$active ? (props.$enabledStatus ? theme("Primary_13") : theme("Background_03")) : theme("Primary_13")};
`;

export const StepSubText = styled.span<{
  $enabledStatus?: boolean;
  $active?: boolean;
}>`
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  color: ${(props) =>
    props.$active ? (props.$enabledStatus ? theme("Primary_13") : theme("Background_03")) : theme("Primary_13")};
`;

export const PaymentSendolaPayIcon = styled.img<{ $active?: boolean }>`
  width: 26px;
  height: 26px;
  margin-left: ${(props) => (props.$active ? "0" : "2px")};
  margin-top: ${(props) => (props.$active ? "0" : "5px")};
`;
