import { CreateAxiosDefaults } from "axios";
import Cookies from "universal-cookie";

// Own constants
import { TOKEN_COOKIE_NAME } from "@constants/Token";
import { LANG_KEY } from "@constants/Language";
import { baseURL, API_KEY } from "../../Constants/ConfigureApi";

// Own services
import { AxiosProvider } from "./http/Axios.provider";
import { ApiRequestError } from "@core/Common/domain/ApiRequestError";

const apiKey = API_KEY;
const prefixUrl = baseURL;
const HttpClient = AxiosProvider;

function getToken() {
  const cookies = new Cookies();
  return sessionStorage.getItem(TOKEN_COOKIE_NAME) || cookies.get(TOKEN_COOKIE_NAME);
}

export function createConfigApiApp(config?: CreateAxiosDefaults) {
  const language = sessionStorage.getItem(LANG_KEY) || "en";

  const client = new HttpClient({
    config: config || {
      baseURL: prefixUrl,
      headers: {
        languageCode: language,
        "x-api-key": apiKey,
      },
    },
    hooks: {
      request: (config) => {
        const token = getToken();

        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
      },
      response: {
        onRejected: (error) => {
          const apiErrorData = error.response?.data as ApiRequestError;
          const errorObj = {
            ...apiErrorData,
            status: error.response?.status,
            statusText: error.response?.statusText,
          };

          // TODO: Customize error handling allowing more types of errors and actions

          throw errorObj;
        },
      },
    },
  });

  return client;
}
