import { RadioProps } from "../../Models/checkboxInterface";
import { Column, Row } from "../Grid";
import { Label, Radio } from "./RadioCustom.styled";

const RadioCustom = (props: RadioProps) => {
  const { id, text, name, legend, onChangeEvent, image, selected, disabled, forwardRef } = props;
  return (
    <>
      <Row>
        <Column span={1}>
          <Radio
            type="radio"
            ref={forwardRef}
            id={id}
            name={name}
            onChange={onChangeEvent}
            value={id}
            checked={selected}
            disabled={disabled}/>
        </Column>
        <Column span={11}>
          <Label htmlFor={id} $disabled={disabled ? disabled : false}>
            { image && (<img src={image} alt="image-radio"/>)}
            <span>{text}</span>
          </Label>
          {legend && <Label htmlFor={id} $disabled={disabled ? disabled : false}>
            <span>{legend}</span>
          </Label>}
        </Column>
      </Row>
    </>
  );
};
export default RadioCustom;
