import { createSlice } from "@reduxjs/toolkit";
import { PersonalDataAction } from "./PersonalData.actions";
import { UPDATE_PERSONAL_DATA } from "../../Constants/PersonalData";
import { PersonalDataState } from "../../Models/PersonalData";
import { State } from "../Store";

const initialState: PersonalDataState = {
  loading: false,
  error: null,
  data: null
}

export const PersonalDataSlice = createSlice({
  name: UPDATE_PERSONAL_DATA,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PersonalDataAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PersonalDataAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload
      })
      .addCase(PersonalDataAction.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export default PersonalDataSlice.reducer;

export const PersonalDataSliceSelector = (state: State) => state.UpdatePersonalData;