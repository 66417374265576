import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "../Button";
import Icon from "../Icon";
import Text from "../Text";

import { WarningMessageProps } from "../../Models/WarningMessage";

import { Container, IconContainer, ContainerButton } from "./WarningMessage.styled";

const WarningMessage = ({
  title,
  message,
  message2,
  icon,
  handleClick,
  isShowContactButton = true,
  urlExternal,
  textColor
}: WarningMessageProps & { isShowContactButton?: boolean }) => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();

  return (
    <Container>
      <IconContainer>
        <Icon icon={icon} size="xlarge"/>
      </IconContainer>
      <Text size={2} weight={600}>
        {title}
      </Text>
      <Text size={1} color={textColor}>
        <span dangerouslySetInnerHTML={{__html: message}} />
      </Text>
      { message2 && (
        <Text size={1}>
          <span dangerouslySetInnerHTML={{__html: message2}} />
        </Text>
      )}
      <ContainerButton>
        { handleClick && (
          <Button
            type="button"
            variant="primary"
            sizeButton="xlarge"
            sizeText="large"
            text={t("buttons.tryAgain")}
            onClick={handleClick}
          />
        )}
        { isShowContactButton && (
          <Button
            type="button"
            variant="outline"
            sizeButton="xlarge"
            sizeText="large"
            text={t("buttons.contactSupport")}
            onClick={() => urlExternal?.length ? window.location.href = urlExternal : navigate("/contact-us")}
          />
        )}
      </ContainerButton>
    </Container>
  )
}

export default WarningMessage;