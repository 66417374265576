import { createSlice } from "@reduxjs/toolkit";
import { ByUserSliceAction } from "./ByUser.actions";
import { State } from "../Store";
import { ByUserState } from "../../Models/usersInterface";

const initialState: ByUserState = {
  loading: false,
  error: null,
  data: null
}

export const ByUserSlice = createSlice({
  name: "ByUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ByUserSliceAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ByUserSliceAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(ByUserSliceAction.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export default ByUserSlice.reducer;

export const ByUserSliceSelector = (state: State) => state.ByUser;
