import styled from "styled-components";
import { theme } from "styled-tools";

export const TabsTitle = styled.div`
  position: relative;
  p {
    padding-bottom: 4px;
    display: inline-block;
    cursor: pointer;
  }

  p.active {
    border-bottom: 2px solid ${theme("Primary_01")};
  }
`;

export const Badge = styled.div`
  background-color: ${theme("Primary_15")};
  border-radius: 16px;
  color: ${theme("Primary_01")};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  padding: 2px 10px;
  position: absolute;
  top: -12px;
  right: -12px;
  width: 72px;
`;