import { useEffect } from "react";
import { useSelector } from "react-redux";
import { State } from "../../../Redux/Store";
import { useTranslation, withTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";

import { FormContactUs, Header } from "./Contacts.styled";
import Title from "../../../Components/Title";
import Layout from "../../../Components/Layout";
import { Column, Container, Row } from "../../../Components/Grid";

import { selectorLanguage } from "../../../Redux/Translate";

const Contacts = () => {
  const theme = useSelector((state: State) => state.theme);
  const { t } = useTranslation("global");
  const lang = useSelector(selectorLanguage);
  const formIdEs = import.meta.env.VITE_HUBSPOT_FORMID_ES;
  const formIdEn = import.meta.env.VITE_HUBSPOT_FORMID_EN;

  useEffect(() => {
    if (lang) {
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/embed/v2.js";
      script.setAttribute("id", "hs-script-loader");
      document.body.appendChild(script);

      const _hsp = (window._hsp = window._hsp || []);
      _hsp.push(["showBanner"]);

      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "44207752",
            formId: lang.language === "es" ? formIdEs : formIdEn,
            target: "#hubspotForm",
          });
        }
      });
      return () => {
        const element = document.getElementById("hs-script-loader");
        if (element) {
          document.body.removeChild(element);
        }
      };
    }
  }, [lang]);

  return (
    <ThemeProvider theme={theme}>
      <Layout textLink={t("buttons.back")} path="/settings">
        <Container>
          <Row>
            <Column span={12}>
              <Header>
                <Title
                  tagName="h1"
                  text={t("ContactUs.Title")}
                  size={6}
                  color="black"
                />
              </Header>
            </Column>
          </Row>
          <Row>
            <Column span={7} xs={12} sm={12} md={7} lg={7}>
              <FormContactUs id="hubspotForm"></FormContactUs>
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default withTranslation("ns")(Contacts);
