import { createSlice } from "@reduxjs/toolkit";
import { GetProofAddressType } from "./ProofAddressDocument.actions";
import { GetProofAddressDocumentState } from "../../Models/Documents";
import { State } from "../Store";

const initialState: GetProofAddressDocumentState = {
  loading: false,
  error: null,
  data: null
};

export const GetProofAddressDocumentSlice = createSlice({
  name: "getProofAddressDocument",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetProofAddressType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetProofAddressType.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(GetProofAddressType.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export const GetProofAddressDocumentSelector = (state: State) => state.getProofAddressDocument;
