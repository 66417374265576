import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useNavigate } from "react-router-dom";

// own states
import { AppDispatch, State } from "../../../Redux/Store";
import { selectorLanguage } from "../../../Redux/Translate";
import { 
  PlaidAccountAction,
  PlaidAccountDetailsAction,
} from "../../../Redux/FundsAccount/FundsAccount.actions";

// Own components
import Button from "../../../Components/Button";
import Layout from "../../../Components/Layout";
import { Column, Container, Row } from "../../../Components/Grid";
import LoaderIcon from "../../../Components/LoaderIcon/LoaderIcon.component";
import Loader from "../../../Components/Loader";
import { LinkedAccountCardList } from "./Components";

// Own Models
import { PlaidAccounts } from "../../../Models/Funds";
import { AlignItems, JustifyContent } from "../../../Models/Column";

// Own constants
import { 
  GET_PLAID_ACCOUNT_DETAILS_FULFILLED,
  GET_PLAID_ACCOUNT_FULFILLED 
} from "../../../Constants/FundsAccount";
import { LinkedAccountContainer } from "./LinkedAccounts.styles";
import { setCurrentPlaidAccount } from "../../../Redux/FundsAccount/FundsAccount.slice";
import { CurrentAccount } from "../../../Models/Account";

const FundsLinkedAccounts = () => {
  const [isLoadingLayout, setIsLoadingLayout] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation("global");
  const lang = useSelector(selectorLanguage);
  const [selectedAccount, setSelectedAccount] = useState({} as PlaidAccounts);
  const theme = useSelector((state: State) => state.theme);
  const accountsStore = useSelector((state: State) => state.FundsAccount?.plaidAccounts);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    getAccounts();
  }, [lang]);

  const getAccounts = async () => {
    dispatch(setCurrentPlaidAccount(null));

    const response = await dispatch(PlaidAccountAction(lang.language));

    if (
      response?.type === GET_PLAID_ACCOUNT_FULFILLED
      && !response.payload?.hasAccounts
    ) {
      return navigate(
        '/funds-capture-check-instructions',{
        state: {
          firstAccount: true,
        },
      });
    }
    setIsLoadingLayout(false);
  }

  const viewAccountDetails = async (
    account: PlaidAccounts | CurrentAccount,
    requiredUpdate?: boolean,
    isConfirmed?: boolean
  ) => {
    setIsLoading(true);
    setSelectedAccount({} as PlaidAccounts);

    const response = await dispatch(PlaidAccountDetailsAction({
      languageCode: lang.language,
      accountId: account.id
    }));

    if( response?.type === GET_PLAID_ACCOUNT_DETAILS_FULFILLED) {
      dispatch(setCurrentPlaidAccount(response.payload));

      navigate('/funds-linked-accounts-details', {
        state: { 
          accountId: account.id, 
          ...requiredUpdate ? { requiredUpdate } : {},
          ...isConfirmed ? { isConfirmed } : {}
        },
      });
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout show={isLoadingLayout}>
        <LoaderIcon
          title=""
          text=""
          description={t("loaderText.description")} />
      </Layout>
      { isLoading && <Loader  full />}
      <Layout
        path="/"
        textLink={t("buttons.back")}
        show={!isLoadingLayout}
      >
        <Container as={LinkedAccountContainer}>
          <Row>
            <Column
              span={12}
              pb={8}
              px={1}
              alignItems={AlignItems.center}
              justifyContent={JustifyContent.center}
            >
              <>
                {
                  accountsStore
                  && (Object.keys(accountsStore.accounts).length > 0 
                  && accountsStore?.hasAccounts) && (
                    <LinkedAccountCardList
                      title={t("Funds.LinkedAccounts.title")}
                      btnText={t("Funds.LinkedAccounts.btnLinkAccount")}
                      accounts={accountsStore?.accounts || []}
                      navigate={navigate}
                      getSelected={(account) => {
                        dispatch(setCurrentPlaidAccount(account));
                        setSelectedAccount(account)
                      }}
                      showDetails={(account, requiredUpdate) => {
                        viewAccountDetails(account, requiredUpdate)
                      }}
                      updateAccount={(account) => viewAccountDetails(account)}
                      t={t}
                    />
                  )}
              </>
            </Column>
            {Object.keys(selectedAccount).length > 0 && (
              <Column
                span={12}
                pb={6}
                alignItems={AlignItems.center}
                justifyContent={JustifyContent.center}
              >
                <Button
                  onClick={() => viewAccountDetails(selectedAccount, false, true)}
                  variant={"primary"}
                  sizeButton="large"
                  sizeText="large"
                  sizeIcon="large"
                  iconButton="arrowRight"
                  text={t("Funds.LinkedAccounts.btnNext")}
                  colorIcon="white"
                  disabled={Object.keys(selectedAccount).length === 0}
                />
              </Column>
            )}
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default FundsLinkedAccounts;
