import { createSlice } from "@reduxjs/toolkit";

import { State } from "../Store";
import { VeriffIntegrationState } from "../../Models/Veriff";
import { GetIntegrationStatus } from "./GetIntegrationStatus.actions";
import { GET_VERIFF_STATUS } from "../../Constants/Veriff";

const initialState: VeriffIntegrationState = {
  loading: false,
  error: null,
  data: null
};

export const veriffIntegrationSlice = createSlice({
  name: GET_VERIFF_STATUS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetIntegrationStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetIntegrationStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(GetIntegrationStatus.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action?.payload as string
      });
  },
});

export const veriffIntegrationSelector = (state: State) => state.getVeriffStatus;
