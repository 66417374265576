import styled from "styled-components";
import { theme } from "styled-tools";
import mediaQuery, { Screen } from "../../../Utilities/MediaQuery";

import Text from "../../../Components/Text";
import Title from "../../../Components/Title";

export const LinkedAccountContainer = styled.div`
  ${mediaQuery[Screen.desktop]} {
    padding-inline: 300px;
  }
`;

export const LinkedAccountCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 20px;
  background: ${theme("Background_01")};
  box-shadow: 0px 4px 20px 0px rgba(${theme("RGBA_01")}, 0.15);
  justify-self: center;
  margin-bottom: 24px;

  &:hover {
    cursor: pointer;
  }

  span {
    font-weight: 600;
  }
`;

export const LinkedAccountRadioCardStyled = styled(LinkedAccountCardStyled)`
  &:hover {
    cursor: initial;
  }
`;

export const LinkedAccountEmptyImg = styled.div`
  background-color: ${theme("Image_Empty_bg")};
  height: 50px;
  width: 50px;
  position: relative;
  border-radius: 6px;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    color: ${theme("Image_Empty_Color")};
    transform: translate(-50%, -50%);
  }
`;

export const LinkedAccountContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div:first-child {
    flex-grow: 1;
  }

  p > span {
    margin-right: 1.3em;
  }
`;

export const LinkedAccountCardAmount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LinkedAccountContentArrow = styled.div`
  padding-right: 1.3em;
`;

export const LinkExternalCardDescription = styled.span`
  span {
    font-weight: 400;

    &:first-child {
      margin-right: 1.5em;
    }
  }
`;

export const LinkedAccountCardExtraStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;

  span {
    margin-right: 10px;
  }
`;

export const LinkedAccountExtraUpdate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: ${theme("Red_09")};
  }

  path {
    stroke: ${theme("Red_09")} !important;
  }
`;

export const LinkedAccountsDetails = styled.div`
  background-color: ${theme("Background_12")};
  border-radius: 8px;
`;

export const LinkedAccountsDetailsDivider = styled.div`
  border-top: 1px solid ${theme("Background_02")};
`;

export const CursorPointerStyled = styled.div`
  cursor: pointer;
`;

export const CustomTitle = styled(Title)`
  font-family: Poppins;
`;

export const CustomText = styled(Text)`
  font-family: Poppins;
`;