import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";
import { AssignmentUserFeature } from "./AssigmentFeature.actions";
import { UpdateUserFeaturesState } from "../../Models/UserFeatures";

const initialState: UpdateUserFeaturesState = {
  loading: false,
  error: null,
  data: null
};

export const AssignmentUserFeatureSlice = createSlice({
  name: "AssignmentUserFeature",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AssignmentUserFeature.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AssignmentUserFeature.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(AssignmentUserFeature.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export default AssignmentUserFeatureSlice.reducer;

export const AssignmentUserFeatureSelector = (state: State) => state.AssignmentUserFeature;
