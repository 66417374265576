import styled from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { theme } from "styled-tools";

export const TooltipIcon = styled.div`
  cursor: pointer;
`;

export const TooltipBoxText = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  visibility: hidden;
  color: transparent;
  width: 150px;
  padding: 15px 15px;
  border-radius: 10px;
  text-align: left;
  font-size: 12px;

  ${mediaQuery[Screen.mobile]} {
    right: 1px;
  }

  b {
    width: 90%;
    text-align: left;
    color: ${theme("Primary_01")};
    font-weight: 700;
  }

  span {
    width: 90%;
    text-align: left;
  }
`;

export const TooltipBox = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  visibility: hidden;
  color: transparent;
  width: 200px;
  padding: 15px 15px;
  border-radius: 20px;
  text-align: left;

  ${mediaQuery[Screen.mobile]} {
    right: 1px;
  }
`;

export const TooltipContainer = styled.div<{$hasShadow?: boolean}>`
  margin-left: 10px;
  position: relative;

  & ${TooltipIcon}:hover + ${TooltipBoxText} {
    visibility: visible;
    color: ${theme("Content_UX_02")};
    width: 250px;
    background: ${theme("Content_UX_03")};
    box-shadow: ${(props) => !props.$hasShadow ? "none" : `0px 4px 4px 0px ${props.theme.Content_UX_02}`};
    padding: 20px 15px;
  }

  & ${TooltipIcon}:hover + ${TooltipBox} {
    visibility: visible;
    color: ${theme("Content_UX_02")};
    width: 280px;
    background: ${theme("Content_UX_03")};
    box-shadow: ${(props) => !props.$hasShadow ? "none" : `0px 4px 4px 0px ${props.theme.Content_UX_02}`};
    padding: 20px 15px;
  }
`;