import styled from "styled-components";
import { theme } from "styled-tools";

export const BankTransfersItem = styled.div`
  position: relative;
  padding-left: 36px;
  padding-bottom: 24px;

  :before {
    position: absolute;
    content: "";
    background-color: ${theme("Primary_01")};
    border-radius: 4px;
    left: 12px;
    bottom: 4px;
    height: calc(100% - 16px);
    width: 2px;
    padding-bottom: 15px;
  }

  :last-child {
    :before {
      display: none;
    }
  }

  &.disabled {
    :before {
      background-color: ${theme("Content_UX_02")};
      opacity: 0.8;
    }

  }

  .disabled {
    ~ p {
      color: ${theme("Content_UX_02")} !important;
      opacity: 0.5;
    }
  }

  .bank-transfer-date {
    font-size: 10px;
  }
`;

export const BackTransfersItemBullet = styled.div`
  background-color: ${theme("Background_12")};
  box-shadow:
    0 0 0 4px ${theme("Background_12")},
    inset 0 0 0 2px ${theme("Primary_01")};
  height: 24px;
  width: 24px;
  position: absolute;
  border-radius: 50%;
  left: 1px;

  :before {
    content: "";
    border-radius: 50%;
    position: absolute;
    background-color: ${theme("Primary_01")};
    height: 12px;
    width: 12px;
    transform: translate(50%, 50%);
    left: 0;
  }

  &:last-child {
    display: none;
  }

  &.bank-transfer-bullet.disabled {
    box-shadow:
      0 0 0 4px ${theme("Background_12")},
      inset 0 0 0 2px ${theme("Content_UX_02")};
    
    :before {
      background-color: ${theme("Content_UX_02")};
      opacity: 0.8;
    }
  }
`;

export const BankTransfersItemBulletIcon = styled.div`
  position: absolute;
  left: 1px;

 &.disabled > div > svg  {
    rect {
      fill: ${theme("Content_UX_02")};
      stroke: ${theme("Content_UX_02")};
      opacity: 0.3;
    }
  }
`;

export const BankTransfersCardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div:first-child {
    flex-grow: 1;
  }

  p > span {
    margin-right: 1.3em;
  }
`;
