import { createSlice } from "@reduxjs/toolkit";
import { CustomerConfigSliceAction } from "./CustomerConfig.actions";
import { State } from "../Store";
import { CustomerConfigState } from "../../Models/CustomerConfig";
import { GET_CUSTOMER_CONFIG } from "../../Constants/user";

const initialState: CustomerConfigState = {
  loading: false,
  error: null,
  data: null
}

export const CustomerConfigSlice = createSlice({
  name: GET_CUSTOMER_CONFIG,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CustomerConfigSliceAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(CustomerConfigSliceAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(CustomerConfigSliceAction.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export default CustomerConfigSlice.reducer;

export const CustomerConfigSliceSelector = (state: State) => state.customerConfig;
