// Own icons Components
import AlertTriangleIcon from "../Components/Icons/AlertTriangle.tsx";
import AlertRedTriangleIcon from "../Components/Icons/AlertRedTriangle.tsx";
import AlertWarningIcon from "../Components/Icons/AlertWarning.tsx";
import ArrowLeftIcon from "../Components/Icons/ArrowLeft.tsx";
import ArrowRightIcon from "../Components/Icons/ArrowRight.tsx";
import ArrowTopIcon from "../Components/Icons/ArrowTop.tsx";
import ArrowDownIcon from "../Components/Icons/ArrowDown.tsx";
import BankIcon from "../Components/Icons/Bank.tsx";
import Bank2Icon from "../Components/Icons/Bank2Icon.tsx";
import BankAccountIcon from "../Components/Icons/BankAccount.tsx";
import BagIcon from "../Components/Icons/Bag.tsx";
import BellIcon from "../Components/Icons/Bell.tsx";
import BuildingStoreIcon from "../Components/Icons/BuildingStore.tsx";
import CancelIcon from "../Components/Icons/Cancel.tsx";
import CashIcon from "../Components/Icons/Cash.tsx";
import ChatLeftIcon from "../Components/Icons/ChatLeft.tsx";
import CheckIcon from "../Components/Icons/Check.tsx";
import CheckCircleGreenIcon from "../Components/Icons/CheckCircleGreen.tsx";
import CheckPurpleIcon from "../Components/Icons/CheckPurple.tsx";
import CheckCirclePurpleIcon from "../Components/Icons/CheckCirclePurple.tsx";
import ChevronDownIcon from "../Components/Icons/ChevronDown.tsx";
import ChevronRightIcon from "../Components/Icons/ChevronRight.tsx";
import ChevronUpIcon from "../Components/Icons/ChevronUp.tsx";
import CircleCheckIcon from "../Components/Icons/CircleCheck.tsx";
import CircleArrowLeftGreyIcon from "../Components/Icons/CircleArrowLeftGrey.tsx";
import CirlePlusIcon from "../Components/Icons/CirlePlus.tsx";
import CircleInfoIcon from "../Components/Icons/CircleInfo.tsx";
import CircleInfoBlackIcon from "../Components/Icons/CircleInfoBlack.tsx";
import CircleSelectedIcon from "../Components/Icons/CircleSelected.tsx";
import CircleDotIcon from "../Components/Icons/CircleDot.tsx";
import CircleXIcon from "../Components/Icons/CircleX.tsx";
import CirleXRedIcon from "../Components/Icons/CircleXRed.tsx";
import CircleXWarningIcon from "../Components/Icons/CircleXWarning.tsx";
import CircleWarningIcon from "../Components/Icons/CircleWarning.tsx";
import CircleUserIcon from "../Components/Icons/CircleUser.tsx";
import ClockIcon from "../Components/Icons/Clock.tsx";
import CogIcon from "../Components/Icons/CogIcon.tsx";
import CoinGradientIcon from "../Components/Icons/CoinGradient.tsx";
import CoinDocument from "../Components/Icons/CoinDocument.tsx";
import CoinIcon from "../Components/Icons/Coin.tsx";
import CreditCardIcon from "../Components/Icons/CreditCard.tsx";
import DocumentIcon from "../Components/Icons/Document.tsx";
import CopyIcon from "../Components/Icons/Copy.tsx";
import DownloadIcon from "../Components/Icons/Download.tsx";
import EditIcon from "../Components/Icons/Edit.tsx";
import EyeOffIcon from "../Components/Icons/EyeOff.tsx";
import EyeIcon from "../Components/Icons/Eye.tsx";
import ErrorCircleIcon from "../Components/Icons/ErrorCircle.tsx";
import ErrorWarningCircleIcon from "../Components/Icons/ErrorWarningCircle.tsx";
import GroupIcon from "../Components/Icons/Group.tsx";
import FaqsIcon from "../Components/Icons/Faqs.tsx";
import HeadsetIcon from "../Components/Icons/Headset.tsx";
import LinkIcon from "../Components/Icons/Link.tsx";
import LinkedAccountIcon from "../Components/Icons/LinkedAccount.tsx";
import LockIcon from "../Components/Icons/Lock.tsx";
import LogoutIcon from "../Components/Icons/Logout.tsx";
import MailIcon from "../Components/Icons/Mail.tsx";
import MenuIcon from "../Components/Icons/Menu.tsx";
import MessageIcon from "../Components/Icons/Message.tsx";
import PiggyBankIcon from "../Components/Icons/PiggyBank.tsx";
import PhoneIcon from "../Components/Icons/Phone.tsx";
import PriceCoinIcon from "../Components/Icons/PriceCoin.tsx";
import PlusIcon from "../Components/Icons/Plus.tsx";
import ReceiptIcon from "../Components/Icons/Receipt.tsx";
import RefreshIcon from "../Components/Icons/Refresh.tsx";
import ShareIcon from "../Components/Icons/Share.tsx";
import SettingsIcon from "../Components/Icons/Settings.tsx";
import SendIcon from "../Components/Icons/Send.tsx";
import ShieldCheckIcon from "../Components/Icons/ShieldCheck.tsx";
import SimpCash from "../Components/Icons/SimpCash.tsx";
import SwitchIcon from "../Components/Icons/SwitchHorizontal.tsx";
import TrashIcon from "../Components/Icons/Trash.tsx";
import TrashGrayIcon from "../Components/Icons/TrashGray.tsx";
import UnderReviewIcon from "../Components/Icons/UnderReview.tsx";
import UploadIcon from "../Components/Icons/Upload.tsx";
import UploadLineIcon from "../Components/Icons/UploadLine.tsx";
import USAFlagIcon from "../Components/Icons/USAFlag.tsx";
import UserIcon from "../Components/Icons/User.tsx";
import UsersIcon from "../Components/Icons/Users.tsx";
import Users2Icon from "../Components/Icons/Users2.tsx";
import WalletIcon from "../Components/Icons/Wallet.tsx";
import WhatsAppIcon from "../Components/Icons/WhatsApp.tsx";
import WarningCircleIcon from "../Components/Icons/WarningCircle.tsx";
import AlertCheckCircleIcon from "../Components/Icons/AlertCheckCircle.tsx";
import AlertInfoErrorCircleIcon from "../Components/Icons/AlertInfoErrorCircle.tsx";
import DoorIcon from "../Components/Icons/Door.tsx";
import MXIcon from "../Components/Icons/MX.tsx";
import USIcon from "../Components/Icons/US.tsx";
import IdCardIcon from "../Components/Icons/IdCard.tsx";
import VisaPassportIcon from "../Components/Icons/VisaPassport.tsx";
import MoreIcon from "../Components/Icons/More.tsx";
import RightLeftIcon from "../Components/Icons/RightLeft.tsx";
import NewArrowRightIcon from "../Components/Icons/NewArrowRight.tsx";
import LocationIcon from "../Components/Icons/Location.tsx";
import SearchIcon from "../Components/Icons/Search.tsx";
import CashCancelIcon from "../Components/Icons/CashCancel.tsx";
import ArrowDownTutorialIcon from "../Components/Icons/ArrowDownTutorialIcon.tsx";
import DeviceMobileIcon from "../Components/Icons/DeviceMobile.tsx";
import SpeakerPhoneIcon from "../Components/Icons/SpeakerPhone.tsx";
import CheckCircleGreen2Icon from "../Components/Icons/CheckCircleGreen2.tsx";
import NewCopyIcon from "../Components/Icons/NewCopy.tsx";
import WorldIcon from "../Components/Icons/World.tsx";
import OptionsIcon from "../Components/Icons/Options.tsx";
import PayrollIcon from "../Components/Icons/Payroll.tsx";
import StarIcon from "../Components/Icons/Star.tsx";
import DocumentsIcon from "../Components/Icons/Documents.tsx";
import HeartIcon from "../Components/Icons/Heart.tsx";
import FlameDiscountIcon from "../Components/Icons/FlameDiscount.tsx";
import FlameDiscount2Icon from "../Components/Icons/FlameDiscount2.tsx";
import FlameDiscountGradientIcon from "../Components/Icons/FlameDiscountGradient.tsx";
import DiscountPercentBadge from "../Components/Icons/DiscountPercentBadge.tsx";
import DiscountPercentFire from "../Components/Icons/DiscountPercentFire.tsx";

// Own images collection
import CardSendola from "@/Assets/Img/SendolaCard.png";
import CardDisableSendola from "@/Assets/Img/SendolaCardDisable.png";
import LoaderImage from "@/Assets/Img/bgLoader.jpg";
import TransactionLoader from "@/Assets/Img/bgTransferLoader.png";

import SquareMailIcon from "../Components/Icons/SquareMail.tsx";
import UnLinkedAccountIcon from "../Components/Icons/UnLinkedAccount.tsx";
import IDCardAmericanIcon from "../Components/Icons/IDCardAmerican.tsx";
import LicenseCardAmericanIcon from "../Components/Icons/LicenseCardAmerican.tsx";
import PassportIcon from "../Components/Icons/Passport.tsx";
import FaceIdIcon from "../Components/Icons/AlertCheckCircle copy.tsx";
import { theme } from "../Contexts/ThemeProvider/Theme.tsx";
import CurrencyDollarExchangeIcon from "../Components/Icons/CurrencyDollar.tsx";
import TimeClockStopIcon from "../Components/Icons/TimeClockStop.tsx";
import TimeClockLoadingIcon from "../Components/Icons/TimeClockLoading.tsx";

export const iconTypes = {
  alertCheckCircle: AlertCheckCircleIcon,
  alertInfoErrorCircle: AlertInfoErrorCircleIcon,
  alertTriangle: AlertTriangleIcon,
  alertRedTriangle: AlertRedTriangleIcon,
  alertWarning: AlertWarningIcon,
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  arrowTop: ArrowTopIcon,
  arrowDown: ArrowDownIcon,
  arrowDownTutorial: ArrowDownTutorialIcon,
  bank: BankIcon,
  bank2: Bank2Icon,
  bankAccount: BankAccountIcon,
  bell: BellIcon,
  bag: BagIcon,
  buildingStore: BuildingStoreIcon,
  cancel: CancelIcon,
  cash: CashIcon,
  cashCancel: CashCancelIcon,
  chatLeft: ChatLeftIcon,
  check: CheckIcon,
  checkCircleGreen: CheckCircleGreenIcon,
  checkCircleGreen2: CheckCircleGreen2Icon,
  checkPurple: CheckPurpleIcon,
  checkCirclePurple: CheckCirclePurpleIcon,
  chevronDown: ChevronDownIcon,
  chevronRight: ChevronRightIcon,
  chevronUp: ChevronUpIcon,
  circleArrowLeftGrey: CircleArrowLeftGreyIcon,
  circleCheck: CircleCheckIcon,
  cirlePlus: CirlePlusIcon,
  circleInfo: CircleInfoIcon,
  circleInfoBlack: CircleInfoBlackIcon,
  circleSelected: CircleSelectedIcon,
  circleDot: CircleDotIcon,
  circleX: CircleXIcon,
  circleXRed: CirleXRedIcon,
  circleXWarning: CircleXWarningIcon,
  circleWarning: CircleWarningIcon,
  circleUser: CircleUserIcon,
  clock: ClockIcon,
  cog: CogIcon,
  coinGradient: CoinGradientIcon,
  coin: CoinIcon,
  coinDocument: CoinDocument,
  creditCard: CreditCardIcon,
  currencyDollarExchange: CurrencyDollarExchangeIcon,
  document: DocumentIcon,
  documents: DocumentsIcon,
  deviceMobile: DeviceMobileIcon,
  copy: CopyIcon,
  download: DownloadIcon,
  door: DoorIcon,
  edit: EditIcon,
  eyeOff: EyeOffIcon,
  eye: EyeIcon,
  errorCircle: ErrorCircleIcon,
  errorWarningCircle: ErrorWarningCircleIcon,
  group: GroupIcon,
  faqs: FaqsIcon,
  faceId: FaceIdIcon,
  headset: HeadsetIcon,
  heart: HeartIcon,
  idCard: IdCardIcon,
  iDCardAmerican: IDCardAmericanIcon,
  licenseCardAmerican: LicenseCardAmericanIcon,
  passport: PassportIcon,
  link: LinkIcon,
  linkedAccount: LinkedAccountIcon,
  unLinkedAccount: UnLinkedAccountIcon,
  lock: LockIcon,
  logout: LogoutIcon,
  location: LocationIcon,
  mail: MailIcon,
  menu: MenuIcon,
  message: MessageIcon,
  more: MoreIcon,
  mxFlag: MXIcon,
  newArrowRight: NewArrowRightIcon,
  newCopy: NewCopyIcon,
  users: UsersIcon,
  users2: Users2Icon,
  piggyBank: PiggyBankIcon,
  phone: PhoneIcon,
  priceCoin: PriceCoinIcon,
  plus: PlusIcon,
  receipt: ReceiptIcon,
  refresh: RefreshIcon,
  rightLeft: RightLeftIcon,
  search: SearchIcon,
  share: ShareIcon,
  settings: SettingsIcon,
  send: SendIcon,
  shieldCheck: ShieldCheckIcon,
  simpleCash: SimpCash,
  speakerPhone: SpeakerPhoneIcon,
  switch: SwitchIcon,
  trash: TrashIcon,
  trashGray: TrashGrayIcon,
  timeClockLoading: TimeClockLoadingIcon,
  timeClockStop: TimeClockStopIcon,
  underReview: UnderReviewIcon,
  upload: UploadIcon,
  uploadLine: UploadLineIcon,
  usaFlagCircle: USIcon,
  usaFlag: USAFlagIcon,
  user: UserIcon,
  visaPassport: VisaPassportIcon,
  wallet: WalletIcon,
  whatsApp: WhatsAppIcon,
  warningCircle: WarningCircleIcon,
  squareMail: SquareMailIcon,
  star: StarIcon,
  world: WorldIcon,
  options: OptionsIcon,
  payroll: PayrollIcon,
  flameDiscount: FlameDiscountIcon,
  flameDiscount2: FlameDiscount2Icon,
  flameDiscountGradient: FlameDiscountGradientIcon,
  discountPercentBadge: DiscountPercentBadge,
  discountPercentFire: DiscountPercentFire,
};

export const imgTypes = {
  cardSendola: CardSendola,
  cardDisableSendola: CardDisableSendola,
  loaderImage: LoaderImage,
  loaderTransactionImage: TransactionLoader
};

export const handleSize = (size: string) => {
  switch (size) {
    case "xsmall":
      return "14px";
    case "small":
      return "16px";
    case "small-2":
      return "20px";
    case "medium":
      return "24px";
    case "large":
      return "32px";
    case "large2":
      return "40px";
    case "xlarge":
      return "60px";
    case "xxlarge":
      return "100px";
    default:
      return "18px";
  }
};

const colorMap = {
  primary: theme["Primary_01"],
  Primary01: theme["Primary_01"],
  solid_light: theme["Primary_01"],
  solid: theme["Primary_01"],
  solid_2: theme["Primary_01"],
  primary_01_700: theme["Primary_01_700"],
  primary_01_600: theme["Primary_01_600"],
  primary_01_500: theme["Primary_01_500"],
  white: theme["Background_01"],
  black: theme["Background_02"],
  dark: theme["Grey_01"],
  gray: theme["Content_UX_02"],
  grey: theme["Content_UX_02"],
  grey02: theme["Grey_02"],
  "grey-blue": theme["Primary_09"],
  black_highlight: theme["Content_UX_01"],
  disabled: theme["Primary_11"],
  success: theme["Primary_06"],
  warning: theme["Primary_08"],
  warningStrong: theme["Background_17"],
  Tanhide_Color: theme["Tanhide_Color"],
  error: theme["Error"],
  transparent: theme["Transparent"],
  Red10: theme["Red_10"],
  Red11: theme["Red_11"],
  primary_500: theme["Primary_500"],
  Primary_16: theme["Primary_16"],
  grey_05: theme["Grey_05"],
  linear: theme["Gradient_04"],
  dark_01: theme["Dark_01"],
  primary_17: theme["Primary_17"],
  yellow: theme["Secondary_Coppel"],
  bannerBank: theme["Background_Banner_Bank"],
  Dark_02: theme["Dark_02"],
  Dark_03: theme["Dark_03"],
  Green_06: theme["Green_06"],
  grey_08: theme["Grey_08"],
  Foreground_Purple_01: theme["Foreground_Purple_01"],
  pink_100: theme["Primary_03_100"],
  Primary500: theme["Primary_500"],
  ZelleColor: theme["ZelleColor"],
  VenmoColor: theme["VenmoColor"],
  AlertBoxColor: theme["Background_18"],
  magenta: theme["Primary_03"],
  zelle: theme["ZelleColor"],
  venmo: theme["VenmoColor"],
  neutral: theme["Content_State_UX_Disabled"],
  neutral_2: theme["Neutral_600"],
  neutral_700: theme["Neutral_700"],
  neutral500: theme["Neutral_500"],
  neutral600: theme["Neutral_600"],
  neutral700: theme["Neutral_700"],
  primaryBlue400: theme["Primary_Blue_400"],
  gradientPrimaryBlue: theme["Gradient_Primary_Blue"],
  none: theme["Background_02"],
  default: undefined,
};

export type FontColor = keyof typeof colorMap;

export const handleColor = (color: FontColor) => {
  return color ? colorMap[color] || colorMap["default"] : colorMap["default"];
};

export const iconFontTypes = [
  "alert",
  "arrow-left",
  "arrow-right",
  "arrows",
  "ach-bank",
  "average-smile",
  "bell",
  "building-bank",
  "calendar-event",
  "camera",
  "cash",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "circle-check",
  "circle-exclamation",
  "circle-minus",
  "circle-plus",
  "circle-x",
  "coin",
  "copy",
  "clock",
  "credit-card",
  "diagonal-arrow",
  "download",
  "edit",
  "email",
  "equal",
  "eye-off",
  "eye",
  "external-link",
  "faqs",
  "fulled-star",
  "home",
  "iconsax-linear-bill",
  "iconsax-linear",
  "info",
  "link",
  "location",
  "lock",
  "logout",
  "menu",
  "message",
  "phone-assistance",
  "phone",
  "picture",
  "pin",
  "plus",
  "resize",
  "sad-smile",
  "search",
  "send",
  "sendola-pay",
  "settings",
  "share",
  "shield-check",
  "star",
  "step-one",
  "step-three",
  "step-two",
  "transaction",
  "trash",
  "user-card-id",
  "user-check",
  "user-exclamation",
  "user-id",
  "user-plus",
  "user-x",
  "user",
  "wallet",
  "arrows-flat",
  "user-rounded",
  "user-circle",
  "settings-bars",
  "cancel",
  "hand-coin",
  "safe",
  "pencil",
  "currency-dollar",
  "device-mobile",
  "gift",
  "play",
  "sending-plane",
  "file-text",
  "sand-clock",
  "location-outline",
  "discount-percent-flame",
  "user-license",
  "user-id-card",
  "passport",
] as const;
