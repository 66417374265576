import styled from "styled-components";

export const Flag = styled.div<{$src: string}>`
  background: ${(props) => `url(${props.$src})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  position: relative;
  border-radius: 50%;
  height: 32px;
  width: 32px;
`;