import { ZelleInstructionsModalProps } from "../../../Models/ModalInterface";
import Modal from "../Modal.component";
import { BottomModalWrapper } from "../BottomModal/BottomModal.styled";
import { FlexContainer } from "../../Flex/Flex.styled";
import Text from "../../Text";
import { useTranslation } from "react-i18next";
import ZelleLogo from "../../../Assets/Img/zelle-wide-logo.png";
import { StepNumber } from "./ZelleInstructionsModal.styled";
import { waLinkEn, waLinkEs } from "@constants/Zelle";
import { useSelector } from "react-redux";
import { selectorLanguage } from "../../../Redux/Translate";
import Link from "../../Link";
import { ZelleColorSpan } from "../../Text/Text.styled";

export const ZelleInstructionsModal = (props: ZelleInstructionsModalProps) => {
  const [t] = useTranslation("global");
  const [tSendMoney] = useTranslation("sendMoney");
  const { language } = useSelector(selectorLanguage);
  const zelleSupportWA = language === "en" ? waLinkEn : waLinkEs;

  return (
    <Modal {...props} hiddenCloseIcon={false} as={BottomModalWrapper}>
      <FlexContainer justify="center" alignItems="center" gap="4px" m="0 0 20px 0">
        <Text display="inline" align="left" size={1} weight={600}>
          {tSendMoney("Zelle.instructions.title1")}
          &nbsp;
          <img src={ZelleLogo} width="57px" height="32px" style={{ display: "inline", margin: "0 4px -9px 4px" }} />
          {tSendMoney("Zelle.instructions.title2")}
        </Text>
      </FlexContainer>
      <FlexContainer direction="column" gap="12px" p="0">
        <FlexContainer alignItems="center" gap="10px">
          <StepNumber>1</StepNumber>
          <FlexContainer direction="column">
            <Text align="left" size={0.5} color="black" weight={400} lineHeight="16px">
              <ZelleColorSpan
                dangerouslySetInnerHTML={{
                  __html: tSendMoney("Zelle.instructions.step1.description"),
                }}
              />
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer alignItems="center" gap="10px">
          <StepNumber>2</StepNumber>
          <FlexContainer direction="column">
            <Text align="left" size={0.5} color="black" weight={400} lineHeight="16px">
              <ZelleColorSpan
                dangerouslySetInnerHTML={{
                  __html: tSendMoney("Zelle.instructions.step2.description"),
                }}
              />
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer alignItems="center" gap="10px">
          <StepNumber>3</StepNumber>
          <FlexContainer direction="column">
            <Text align="left" size={0.5} color="black" weight={400} lineHeight="16px">
              <ZelleColorSpan
                dangerouslySetInnerHTML={{
                  __html: tSendMoney("Zelle.instructions.step3.description"),
                }}
              />
            </Text>
          </FlexContainer>
        </FlexContainer>

        {/* Temporarily disabled */}
        {/* <FlexContainer justify="center">
          <Link href="https://youtu.be/Ra71FNAjaHk" size={0.5}>
            <FlexContainer alignItems="center" gap="8px">
              <IconFont name="play" color="zelle" />
              <Text size={0.5} weight={600} color="zelle" textDecoration="underline">
                {t("Zelle.instructions.videoTutorial")}
              </Text>
            </FlexContainer>
          </Link>
        </FlexContainer> */}
        <FlexContainer justify="center" alignItems="baseline" gap="4px">
          <Text color="black" size={0.5} weight={400}>
            {tSendMoney("Zelle.instructions.helpAdvice")}
          </Text>
          <Link
            href="#"
            size={0.5}
            underline
            onClick={() => {
              window.location.href = zelleSupportWA;
            }}
          >
            <Text color="black" size={0.5} weight={600}>
              {t("buttons.contactSupport")}
            </Text>
          </Link>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
