import styled from "styled-components";
import { theme } from "styled-tools";

export const DragDropStyled = styled.div`
  height: 236px;
  padding: 30px;
  margin: 0 auto;
  margin-bottom: 24px;
  position: relative;
  text-align: center;
  font-weight: bold;
  color: ${theme("Content_UX_03")};
  border: 1px solid ${theme("Primary_01")};
  border-radius: 25px;
  background: ${theme("Background_01")};

  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.3s;
  width: 100%;
  max-width: -webkit-fill-available;

  input[type="file"] {
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    top: 0;
    left: 0;
  }

  svg {
    color: ${theme("Primary_01")};
    fill: ${theme("Primary_01")};
  }

  div {
    width: 100%;
  }
`;

export const ButtonContainer= styled.div`
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  margin-top: 48px;
  margin-bottom: 24px;
`;

export const ContainerModal = styled.div`
  padding: 30px 20px;
`;

export const ButtonContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  margin-inline: auto;

  button {
    margin-inline: auto;
  }

  button:first-child {
    margin-block: 32px;
  }
`;

export const ContentFile = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    margin-block: 48px;
  }
`;

export const BoldText = styled.span`
  color: ${theme("Primary_01")};
  cursor: pointer;
  margin-left: 6px;
`;

export const DisclosureFile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

export const ContainerText = styled.div`
  font-family: "Montserrat";
  margin-top: 5px;
  width: 100%;
  color: ${theme("Red_08")};
  display: flex;
  flex-direction: row;
  align-content: center;
`;

export const Span = styled.span<{ $error?: boolean }>`
  margin-left: 4px;
  display: flex;
  flex-direction: row;
  position: relative;
  font-size: 16px;
  color: ${theme("Red_08")};
  width: 100%;
  height: 15px;
  text-align: left;
`;

export const ReactWebCamContainer = styled.div`
  border: 3px solid ${theme("Primary_01")};
  box-sizing: border-box;
  border-radius: 20px;
  padding: 0;
  margin: 24px 0;
  height: auto;

  video,
  img {
    margin-bottom: -3.5px;
    border-radius: 17px;
  }
`;