import styled from "styled-components";
import { ifProp, theme } from "styled-tools";

export const Radio = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid ${theme("Background_04")};
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  ::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 6px;
    height: 6px;
  }
  :checked {
    border: 1px solid ${theme("Primary_10")};

    ::after {
      background-color: ${theme("Primary_600")};
      border: 2px solid ${theme("Primary_600")};
    }
    :hover {
      background-color: ${theme("Background_600")};

      ::after {
        background-color: ${theme("Primary_600")};
      }
    }
  }
  :disabled {
    ::after {
      background-color: ${theme("Content_State_UX_Disabled")};
      border: 1px solid ${theme("Content_State_UX_Disabled")};
      box-shadow: 0px 0px 0px 4px ${theme("Content_State_UX_Disabled")};
    }
    :hover {
      background-color: ${theme("Background_01")};
      ::after {
        background-color: ${theme("Content_State_UX_Disabled")};
      }
    }
  }
`;

export const Label = styled.label<{$disabled:boolean}>`
  display: inline-block;
  position: relative;
  padding-left: 6px;
  font-weight: normal;
  cursor: pointer;
  text-align: left;
  width: 100%;
  font-size: 18px;
  font-family: "Poppins";

  img {
    position: absolute;
    top: -3px;
    left: -12px;
  }

  span {
    color: ${ifProp("$disabled", theme("Background_04"), theme("Content_UX_04"))};
  }
`;

export const Container = styled.div`
  width: 100%;
  margin: 15px auto;
  position: relative;
  display: block;
`;
