import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";

const initialState = {
  path: null
};

export const PathPaymentsSlice = createSlice({
  name: "pathPayments",
  initialState,
  reducers: {
    updatePathPayments: (state, action) => {
      state.path = action.payload;
    },
  },
});

export const { updatePathPayments } = PathPaymentsSlice.actions;
export const PathPaymentsSelector = (state: State) => state.pathPayments;

export default PathPaymentsSlice.reducer;
