import { createSlice } from "@reduxjs/toolkit";
import { State } from "@redux/Store";
import { PaymentOrderVerifyState } from "@core/Payments/PaymentOrders/domain/VerifyPaymentOrder";

const initialState: PaymentOrderVerifyState = {
  loading: false,
  error: null,
  data: {
    currentVerifyResponse: undefined,
  },
};

export const PaymentOrderVerifySlice = createSlice({
  name: "paymentOrderVerify",
  initialState,
  reducers: {
    resetState(state) {
      state = initialState;
      return state;
    },
    setCurrentVerifyResponse(state, action) {
      state.data.currentVerifyResponse = action.payload;
      return state;
    },
  },
});

export const PaymentOrderVerifySelector = (state: State) =>
  state.paymentOrderVerify;
