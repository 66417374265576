// Own components
import Icon from "../Icon";
import DropDownItem from "./DropDownItem.component";
// Own styles
import { List, Item } from "./LinksList.styled";

// Own models
import { dataProps, EnumDataItem } from "../../Models/LinksList";
import PDFViewer from "../PDFViewer";

const DocumentLinksList = (props: dataProps) => {
  return (
    <>
      <List>
        {props.data.map((elem: EnumDataItem, index) => {
          if (elem.url) {
            return (
              <PDFViewer url={elem.url} key={index}>
                <div className="pointer" key={index}>
                  {elem.icon && (
                    <Icon icon={elem.icon} color="black" size="medium" />
                  )}
                  <Item>
                    {elem.name}
                    <Icon icon="chevronRight" color="black" size="medium" />
                  </Item>
                </div>
              </PDFViewer>
            );
          } else {
            return (
              <DropDownItem
                key={index}
                id={index}
                icon={elem.icon}
                name={elem.name}
                items={elem.items}
              />
            );
          }
        })}
      </List>
    </>
  );
};

export default DocumentLinksList;
