
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import {
  GET_PAYROLL_BYUSER,
  GET_PINWHEEL_ACCOUNT,
  GET_PINWHEEL_LINK,
  GET_REQUEST_DIRECT_DEPOSIT,
  PDF_DIRECT_DEPOSIT,
  POST_REQUEST_DIRECT_DEPOSIT
} from "../../Constants/Payroll";
import { defaultErrorMessage } from "../../Constants/Requests";
import { AxiosError } from "axios";
import { DirectDepositPayload, PdfDirectDepositPayload, PinwheelAccountData } from "../../Models/PayrollData";


export const getPayrollByUserAction = createAsyncThunk(
  GET_PAYROLL_BYUSER,
  async (_, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(`/accounts/by-user`);
      return response.data.data;
    } catch (error) { 
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
)

export const PinwheelLinkTokenAction = createAsyncThunk(
  GET_PINWHEEL_LINK,
  async (language: string, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post(
        `/link-token`,
        {},
        {
          headers: {
            "language": language
          }
        }
      );
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);

export const PinwheeUpdatelLinkTokenAction = createAsyncThunk(
  GET_PINWHEEL_LINK,
  async (language: string, thunkAPI) => {
    try {
      const response = await BaseConfigApi.put(
        `/link-token`,
        {},
        {
          headers: {
            "language": language
          }
        }
      );
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);

export const PinwheelAccountAction = createAsyncThunk(
  GET_PINWHEEL_ACCOUNT,
  async (payload: PinwheelAccountData, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post(`/account`, payload);
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);

export const getRequestDirectDepositAction = createAsyncThunk(
  GET_REQUEST_DIRECT_DEPOSIT,
  async (payload: string, thunkAPI) => {
    try {
      const header = {
        "personId": payload,
      };
      const response = await BaseConfigApi.get(`/payroll/request/directdeposit`, {headers: header});
      return response.data.data;
    } catch (error) { 
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);

export const postRequestDirectDepositAction = createAsyncThunk(
  POST_REQUEST_DIRECT_DEPOSIT,
  async (payload: DirectDepositPayload, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post(
        `/payroll/request/directdeposit`,
        payload,
        {
          headers: {
            "personId": payload.personId
          }
        }
      );
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);

export const pdfDirectDepositAction = createAsyncThunk(
  PDF_DIRECT_DEPOSIT,
  async (payload: PdfDirectDepositPayload, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post(`/payroll/request/directdeposit/pdf`, payload,
        {
          headers: {
            "personId": payload.personId
          }
        }
      );
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);

