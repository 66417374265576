import styled, { css } from "styled-components";
import { FlexContainer } from "../../Flex/Flex.styled";
import { theme } from "styled-tools";
import { ButtonStyled } from "../CouponInput/CouponInput.styled";

export const QuoteSummaryContainer = styled(FlexContainer)`
  hr {
    width: 100%;
    height: 0.5px;
    background-color: ${theme("Black")};
    border: none;
  }

  hr:last-of-type {
    margin: -11px 0 -6px 0;
  }
`;

export const ExchangeRateContainer = styled.div`
  background: linear-gradient(8.83deg, ${theme("Primary_01_800")} 0.97%, ${theme("Primary_01")} 112.47%);
  border-radius: 4px;
  * {
    color: ${theme("White")};
  }
`;

export const StepLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  padding: 4px;
  background-color: ${theme("Grey_Light")};
`;

export const Dropdown = styled.div<{ $show: boolean }>`
  height: auto;
  padding: 8px 27px 0 16px;
  opacity: 1;
  width: 100%;
  transition:
    height 0.15s ease-in-out,
    opacity 0.3s ease-in-out,
    padding 0.3s ease-in-out;
  interpolate-size: allow-keywords;

  ${(props) =>
    !props.$show &&
    css`
      opacity: 0;
      height: 0;
      padding: 0;
    `}
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${theme("Black")};
  width: 100%;
`

export const InstructionsCopyButton = styled(ButtonStyled)`
  background: transparent;
  padding: 0 !important;
  width: auto;
  min-width: auto;
`;


