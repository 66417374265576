import styled from "styled-components";
import { theme } from "styled-tools";

export const ImgContainer = styled.div<{$background?: string}>`
  width: 49px;
  height: 49px;
  flex-shrink: 0;
  border-radius: 8px;
  background: ${props => props.$background === "bannerUSI" ? props.theme.BackgroundBlue2 : props.theme.Gradient_08};
  display: grid;
  align-content: center;
  justify-content: center;
`;

export const Line = styled.div`
  border: 0.5px solid ${theme("Grey_10")};
  height: 0;
  width: 100%;
  margin-block: auto;
`;