import { useState } from "react";
import { useDispatch } from "react-redux";

import { AppDispatch } from "@redux/Store";
import { PersonsKYCSlice } from "@redux/Payments/PersonsKYC";

import { CreateIDDocumentPayload, Person, SelfieStatusResponse } from "@core/Payments/Person/domain/Person";
import { getPerson, getSelfieStatus } from "@core/Payments/Person/application/getPerson";
import { createApiPersonRepository } from "@core/Payments/Person/infrastructure/ApiPersonRepository";
import { ApiRequestError } from "@core/Common/domain/ApiRequestError";
import { globalConfigSlice } from "@redux/Payments/GlobalConfig/GlobalConfig.slice";
import { useStatus } from "./useStatus";
import { updateIDDocument } from "@core/Payments/Person/application/updateIDDocument";

export const usePerson = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, startRequest, endRequest } = useStatus();
  const [person, setPerson] = useState({} as Person);
  const [selfieStatus, setSelfieStatus] = useState<SelfieStatusResponse | undefined>();
  const [errors, setErros] = useState({} as ApiRequestError);

  const getCurrentPerson = async () => {
    startRequest();
    try {
      const response = await getPerson(createApiPersonRepository())();
      dispatch(PersonsKYCSlice.actions.setPersonVeriff(response));

      if (response?.codeResponse) {
        const config = response?.codeResponse;
        dispatch(
          globalConfigSlice.actions.setConfig({
            customerId: config?.customerId,
            customerName: config?.customerName,
            invitationCode: config?.invitationCode,
            gpTier: config?.gpTier,
            oneTier: config?.oneTier,
          })
        );
      }

      setPerson(response);
      return response;
    } catch (error) {
      setErros(error as ApiRequestError);
    } finally {
      endRequest();
    }
  };

  const updateIdentificationDocument = async (payload: CreateIDDocumentPayload) => {
    startRequest();
    try {
      const response = await updateIDDocument(createApiPersonRepository())(payload);

      endRequest();
      return response;
    } catch (error) {
      endRequest({ error: error as ApiRequestError });
    }
  };

  const getPersonSelfieStatus = async () => {
    startRequest();
    try {
      const response = await getSelfieStatus(createApiPersonRepository())();

      endRequest();
      setSelfieStatus(response);
      return response;
    } catch (error) {
      endRequest({ error: error as ApiRequestError });
    }
  };

  return {
    person,
    selfieStatus,
    errors,
    isLoading,
    getCurrentPerson,
    updateIdentificationDocument,
    getPersonSelfieStatus,
  };
};
