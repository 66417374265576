import { createSlice } from "@reduxjs/toolkit";
import { State } from "../../Store";
import { BeneficiaryState } from "@core/Payments/Beneficiary/domain/BeneficiaryState";

const initialState: BeneficiaryState = {
  loading: false,
  error: null,
  data: {},
};

export const BeneficiarySlice = createSlice({
  name: "beneficiary",
  initialState,
  reducers: {
    resetState(state) {
      state = initialState;
      return state;
    },
    setBeneficiaryEdit(state, action) {
      state.data.beneficiaryEdit = action.payload;
      state.error = null;
    },
    setBeneficiaryToSave(state, action) {
      state.data.beneficiaryToSave = action.payload;
    },
    setBeneficiarySelected(state, action) {
      state.data.beneficiarySelected = action.payload;
      state.error = null;
    },
    setCountrySelected(state, action) {
      state.data.countrySelected = action.payload;
    },
    setBankSelected(state, action) {
      state.data.bankSelected = action.payload;
    },
    setNewBeneficiary(state, action) {
      state.data.newBeneficiary = action.payload;
    },
  },
});

export default BeneficiarySlice.reducer;
export const BeneficiarySelector = (state: State) => state.beneficiary;
