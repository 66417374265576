export interface OnboardingState {
  loading: boolean
  error: string | null
  data: Onboarding | null
}

export enum Step {
  personInformation = 'personInformation',
  personAddress = 'personAddress',
  personShippingAddress = 'personShippingAddress',
  personEmail = 'personEmail',
  submitPep = 'submitPep',
  selectIdvFlow = 'selectIdvFlow',
  ssn = 'ssn',
  idv = 'idv',
  passport = 'passport',
  visa = 'visa',
  submitKyc = 'submitKyc'
}

export interface Onboarding {
  lastStep: Step | null
  nextStep: Step | null
  requiredOnboarding: boolean
}

export interface SetStepsPayload {
  lastStep: Step | null
  nextStep: Step | null
  requiredOnboarding?: boolean
}
