// Own componets
import { AlignItems, JustifyContent } from "../../Models/Column";
import { Column, Row } from "../Grid";

// Own styles
import { Flag } from "./CountriesWithFlag.styled";

interface CountriesWithFlagProps {
  countryCode?: string;
  label?: string;
}

const CountriesWithFlag = (props:CountriesWithFlagProps) => {
  const { countryCode, label } = props;
  return(
    <Row>
      <Column span={2}>
        <Flag $src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}/>
      </Column>
      <Column span={10} alignItems={AlignItems.center} justifyContent={JustifyContent.center}>
        { label }
      </Column>
    </Row>
  );
}

export default CountriesWithFlag;