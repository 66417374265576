import { Link } from "react-router-dom";

// Own models
import { BannerServicesProps } from "../../Models/BannerCard";

// Own styles
import {
  BannerServicesContainer,
  Banner,
  TitleCard,
  TextCard,
  ContainerTitle,
  Btn,
  Check,
  CellPhoneImage,
} from "./BannerServicesCard.styled";

// Own components
import { Column, Row } from "../Grid";
import Icon from "../Icon";
import { JustifyContent } from "../../Models/Column";

const BannerServicesCard = (props: BannerServicesProps) => {
  const {
    bgColor,
    title,
    message,
    link,
    target,
    icon,
    imageTitle,
    onClickBtn,
    widthImageTitle,
    heightImageTitle,
    imgSubtitle,
    notification,
    buttonText,
    buttonIcon,
    check,
    sizeTextBtn,
  } = props;

  return (
    <Banner onClick={onClickBtn}>
      {check && (
        <Check>
          <Icon icon="check" color="white" fillIcon={false} size="small" />
        </Check>
      )}
      <Link to={link} target={target ? "_blank" : ""}>
        <BannerServicesContainer $bgColor={bgColor}>
          <Row>
            <Column
              span={7}
              justifyContent={JustifyContent.flexStart}
              as={ContainerTitle}
            >
              {imageTitle ? (
                <img
                  src={imageTitle}
                  width={widthImageTitle ? widthImageTitle : 80}
                  height={heightImageTitle ? heightImageTitle : 40}
                />
              ) : (
                icon && (
                  <Icon
                    icon={icon}
                    color="white"
                    fillIcon={false}
                    size="large2"
                  />
                )
              )}
              {notification && <div>{notification}</div>}
            </Column>
            {imgSubtitle && (
              <Column span={5} as={CellPhoneImage}>
                <img src={imgSubtitle} height={50} width={50} />
              </Column>
            )}
            {!notification && (
              <>
                <Column span={12}>
                  <TitleCard>{title}</TitleCard>
                </Column>
                <Column span={12}>
                  <TextCard>{message}</TextCard>
                </Column>
              </>
            )}
            {buttonText && (
              <Column span={12}>
                <Btn $sizeText={sizeTextBtn}>
                  {buttonIcon && (
                    <Icon icon={buttonIcon} color="white" size="small" />
                  )}
                  <span>{buttonText}</span>
                </Btn>
              </Column>
            )}
          </Row>
        </BannerServicesContainer>
      </Link>
    </Banner>
  );
};

export default BannerServicesCard;
