import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { QuotationSlice } from "@redux/Payments/Quotation";
import { AppDispatch } from "@redux/Store";
import { CouponsSlice } from "@redux/Payments/Coupons/Coupons.slice";
import IconFont from "@components/IconFont";
import Text from "@components/Text";
import { FlexContainer } from "@components/Flex/Flex.styled";
import { CloseButton, CouponBarContainer } from "./CouponBar.styled";

interface CouponBarProps {
  couponCode: string;
  showCouponInputManual?: (show: boolean) => void;
  showRemoveCoupon?: boolean;
}

export const CouponBar = ({ couponCode, showCouponInputManual, showRemoveCoupon }: CouponBarProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [tSendMoney] = useTranslation("sendMoney");

  const HandleCancelButton = async () => {
    if (showCouponInputManual) {
      showCouponInputManual(true);
      dispatch(QuotationSlice.actions.setCouponToApply(undefined));
      dispatch(QuotationSlice.actions.setCurrentQuoteDiscounted(undefined));
      dispatch(CouponsSlice.actions.setErrorsCoupons(null));
    }
  };

  useEffect(() => {
    if (showCouponInputManual) showCouponInputManual(false);

    return () => {
      if (showCouponInputManual) {
        showCouponInputManual(true);
        dispatch(CouponsSlice.actions.setErrorsCoupons(null));
      }
    };
  }, []);

  return (
    <CouponBarContainer>
      <IconFont name="discount-percent-flame" color="primaryLight" />
      <FlexContainer w="auto">
        <Text color="white" align="left" weight={600}>
          {`${tSendMoney("Coupons.CouponApplied")} ${couponCode}`}
        </Text>
      </FlexContainer>
      {showRemoveCoupon && (
        <CloseButton onClick={HandleCancelButton}>
          <IconFont name="cancel" color="white" size="medium" />
        </CloseButton>
      )}
    </CouponBarContainer>
  );
};
