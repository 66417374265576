import { createSlice } from "@reduxjs/toolkit";
import { State } from "@redux/Store";
import { TransferOrderState } from "@core/Payments/PaymentOrders/domain/PaymentOrderState";

const initialState: TransferOrderState = {
  loading: false,
  error: null,
  data: {
    transactionDetails: undefined,
    transactionCard: undefined,
    transactionTracking: undefined,
  },
};

export const TransferOrdersSlice = createSlice({
  name: "TransferOrders",
  initialState,
  reducers: {
    setTransactionDetail(state, action) {
      state.data.transactionDetails = action.payload;
    },
    setTransactionCard(state, action) {
      state.data.transactionCard = action.payload;
    },
    setTransactionTracking(state, action) {
      state.data.transactionTracking = action.payload;
    },
  },
});

export default TransferOrdersSlice.reducer;

export const TransferOrdersSelector = (state: State) => state.TransferOrders;
