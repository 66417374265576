import { useState } from "react";
import Select from "react-select";
import { tOption as SelectOption } from "../../Models/Select";

import { removeAccents } from "../../Utilities/String";

import { ErrorContainer, ErrorMessage, selectStyles, Label, selectStylesGlobalPayments } from "./ReactSelect.styled";

import { Row, Column } from "../Grid";
import Icon from "../Icon";

// eslint-disable-next-line
const ReactSelect = (props:any) => {
  const {
    ref,
    options,
    nameSelect,
    placeholder,
    textLabel,
    onBlur,
    onChange,
    value,
    errors,
    selectedDefaultValue,
    isDisabled,
    customFilter = false,
    setStyles
  } = props;

  const [inputValue, setInputValue] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInputChange = (newValue: any) => {
    setInputValue(newValue);
  };

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const defaultValue = options.find((option:any) => option.value === selectedDefaultValue);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customFilterOption = (option: any, inputValue: any) => {
    const normalizedInputValue = removeAccents(inputValue.toLowerCase());

    if (!inputValue) {
      return true;
    }

    if (inputValue.length >= 1) {
      if (customFilter) {
        const normalizedFilter = removeAccents(option.data.filter.toLowerCase());
        const normalizedValue = removeAccents(option.value.toLowerCase());

        return normalizedFilter.includes(normalizedInputValue) || normalizedValue.includes(normalizedInputValue);
      } else {
        const normalizedLabel = removeAccents(option.label.toLowerCase());
        const normalizedValue = removeAccents(option.value.toLowerCase());

        return normalizedLabel.includes(normalizedInputValue) || normalizedValue.includes(normalizedInputValue);
      }
    }

    return false;
  };

  const seletedStyles = (typeSelect:string) => {
    switch(typeSelect) {
      case "GlobalPayment":
        return selectStylesGlobalPayments;
      case "APP":
        return selectStyles;
      default:
        return selectStyles
    }
  };

  return(
    <Row>
      <Column span={12}>
        <Label>{textLabel}</Label>
        <Select
          ref={ref}
          options={options}
          name={nameSelect}
          styles={seletedStyles(setStyles)}
          placeholder={placeholder}
          onBlur={onBlur}
          components={{
            IndicatorSeparator: () => null,
          }}
          onChange={(newValue) => onChange(newValue as SelectOption)}
          defaultValue={defaultValue}
          isDisabled={isDisabled}
          value={value}
          onInputChange={handleInputChange}
          filterOption={(options) => customFilterOption(options, inputValue)}
        />
        {errors[nameSelect] && (
          <ErrorContainer>
            <Icon
              icon="alertTriangle"
              color="error"
              size="small"
              fillIcon={false}
            />
            <ErrorMessage>{errors[nameSelect].message}</ErrorMessage>
          </ErrorContainer>
        )}
      </Column>
    </Row>
  );
};

export default ReactSelect;