import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash.isempty";

// Own Models
import { AlignItems, JustifyContent } from "../../Models/Column";

// Own Components
import { Column, Container, Row } from "../Grid";
import Button from "../Button";
import Icon from "../Icon";
import Text from "../Text";
import LinkedAccountDeleteButton from "./LinkedAccountDeleteButton.component";

// Styles
import {
  AccountsInfoContainer,
  Balance,
  ButtonIcon,
  ContainerCard,
  NumberText,
  UnlinkedCard,
  UnlinkedCardContent,
  UnlinkedCardInfoLocked,
} from "./LinkedAccountCard.styled";
import { Flex } from "../Flex";

// Utilities
import { FormatNumberToThousands } from "../../Utilities/FormatNumberToThousands";
import { CopyTextToClipboard } from "../../Utilities/CopyTextToClipboard";

type Props = {
  id: string;
  bankName?: string;
  logoUrl: string | undefined;
  bgColor?: string;
  type: string;
  accountNumber: string;
  routingNumber: string | undefined;
  isActive: boolean;
  isUpdatedAccount?: boolean | undefined;
  amount?: number | null;
  onShowDeleteModal?: () => void | undefined;
  onOpenPlaid?: () => void | undefined;
  selectAccount?: (id: string) => void | undefined;
};

const LinkedAccountCard = ({
  id,
  bankName,
  logoUrl,
  accountNumber,
  routingNumber,
  type,
  bgColor,
  isActive,
  isUpdatedAccount = false,
  amount,
  onShowDeleteModal,
  selectAccount,
  onOpenPlaid,
}: Props) => {
  const [t] = useTranslation("global");
  // const navigate = useNavigate();
  const [roundBalance, centsBalance]: string[] = useMemo(() => {
    if (amount) {
      const stringAmount = amount.toFixed(2);
      return stringAmount.split(".");
    } else {
      return ["0", "00"];
    }
  }, [amount]);
  const [copyRoute, setCopyRoute] = useState(false);
  const [copyAccountNumber, setCopyAccountNumber] = useState(false);

  const copyText = (type: "router" | "account", text: string | undefined) => {
    CopyTextToClipboard(text);

    if (type === "router") {
      setCopyRoute(true);
    } else {
      setCopyAccountNumber(true);
    }

    setTimeout(() => {
      setCopyRoute(false);
      setCopyAccountNumber(false);
    }, 1000);
  };

  return (
    <ContainerCard $bgColor={bgColor}>
      {!isActive && (
        <UnlinkedCard>
          <UnlinkedCardContent>
            {!isUpdatedAccount ? (
              <>
                <Button
                  text={t("LinkedAccounts.link")}
                  variant="secondary"
                  sizeText={"small"}
                  sizeButton="small"
                  colorIcon="white"
                  onClick={() => {
                    if (onOpenPlaid) {
                      onOpenPlaid();
                    }

                    if (selectAccount) {
                      selectAccount(id);
                    }
                  }}
                />
                <Text
                  size={0.3}
                  weight={500}
                  align="center"
                  color="white"
                  margin="0 0 5px 0"
                >
                  {t("LinkedAccounts.unlinkedAccount")}
                </Text>
              </>
            ) : (
              <Text
                size={0.3}
                weight={500}
                align="center"
                color="white"
                margin="0 0 5px 0"
              >
                {t("LinkedAccounts.updatedAccount")}
              </Text>
            )}
          </UnlinkedCardContent>
        </UnlinkedCard>
      )}

      <Container>
        <Row>
          <Column span={12}>
            <Row>
              <Column span={6}>
                {!isEmpty(logoUrl) ? (
                  <img alt={bankName} src={logoUrl} height={20} />
                ) : (
                  <Text
                    tagName="p"
                    size={1}
                    weight={600}
                    align="left"
                    color="white"
                    margin="0"
                  >
                    {bankName}
                  </Text>
                )}
              </Column>
              <Column span={6} justifyContent={JustifyContent.flexEnd}>
                <LinkedAccountDeleteButton
                  onShowDeleteModal={() => {
                    if (onShowDeleteModal) {
                      onShowDeleteModal();
                    }

                    if (selectAccount) {
                      selectAccount(id);
                    }
                  }}
                />
              </Column>
            </Row>
          </Column>
          <Column span={12}>
            {!isActive ? (
              <UnlinkedCardInfoLocked $width="90px" />
            ) : (
              <Text
                className="linked-account-type"
                tagName="p"
                size={0.3}
                weight={400}
                align="left"
                color="white"
                margin="-8px 0 0 0"
              >
                {type === "savings"
                  ? t("LinkedAccounts.type.savingsAccount")
                  : t("LinkedAccounts.type.checkingAccount")}
              </Text>
            )}
          </Column>
          <Column span={12} mt={1}>
            <Row>
              <Column span={12} justifyContent={JustifyContent.flexStart}>
                {!isActive ? (
                  <Row>
                    <Column span={12} mt={2} mb={2}>
                      <UnlinkedCardInfoLocked $width="100px" />
                    </Column>
                  </Row>
                ) : (
                  <Flex>
                    <Text size={3} weight={600} color="white" margin="0">
                      ${FormatNumberToThousands(roundBalance)}
                    </Text>
                    <Text size={0.3} weight={600} color="white">
                      .{centsBalance}
                    </Text>
                  </Flex>
                )}
              </Column>
              <Column span={12} justifyContent={JustifyContent.flexStart}>
                <Balance>{t("LinkedAccounts.balance")}</Balance>
              </Column>
            </Row>
          </Column>
          <Column span={12} as={AccountsInfoContainer}>
            <Row>
              <Column span={6} alignItems={AlignItems.flexEnd}>
                <Row>
                  <Column span={12} mt={0}>
                    <NumberText>
                      {t("LinkedAccounts.accountNumberLabel")}
                    </NumberText>
                  </Column>
                  <Column span={12}>
                    <Flex>
                      {!isActive ? (
                        <UnlinkedCardInfoLocked
                          $width="100px"
                          $margin="8px 0 0 0"
                        />
                      ) : (
                        <>
                          <Text
                            size={0.3}
                            weight={700}
                            color="white"
                            margin={0}
                          >
                            ******* {accountNumber}
                          </Text>
                          <ButtonIcon
                            onClick={() =>
                              copyText(
                                "account",
                                accountNumber ? accountNumber : ""
                              )
                            }
                          >
                            <Icon
                              icon={copyAccountNumber ? "check" : "newCopy"}
                              color="white"
                              fillTransparent
                              size="small"
                            />
                          </ButtonIcon>
                        </>
                      )}
                    </Flex>
                  </Column>
                </Row>
              </Column>
              <Column span={6} alignItems={AlignItems.flexEnd}>
                <Row>
                  <Column span={12}>
                    <NumberText>
                      {t("LinkedAccounts.routingNumberLabel")}
                    </NumberText>
                  </Column>
                  <Column span={12}>
                    <Flex>
                      {!isActive ? (
                        <UnlinkedCardInfoLocked
                          $width="100px"
                          $margin="8px 0 0 0"
                        />
                      ) : (
                        <>
                          <Text
                            size={0.3}
                            weight={700}
                            color="white"
                            margin={0}
                          >
                            {routingNumber}
                          </Text>
                          <ButtonIcon
                            onClick={() =>
                              copyText(
                                "router",
                                routingNumber ? routingNumber : ""
                              )
                            }
                          >
                            <Icon
                              icon={copyRoute ? "check" : "newCopy"}
                              color="white"
                              fillTransparent
                              size="small"
                            />
                          </ButtonIcon>
                        </>
                      )}
                    </Flex>
                  </Column>
                </Row>
              </Column>
            </Row>
          </Column>
          <Column span={12} justifyContent={JustifyContent.flexEnd} pt={2}>
            <Icon icon="linkedAccount" color="white" size="medium" />
          </Column>
        </Row>
      </Container>
    </ContainerCard>
  );
};

export default LinkedAccountCard;
