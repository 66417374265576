import { Search } from "./SearchInput.styled";
import { useTranslation } from "react-i18next";

interface SearchInputProps {
  searchValue?: string;
  onChange?: (value: string) => void;
}

export const SearchInput = ({ searchValue, onChange }: SearchInputProps) => {
  const { t } = useTranslation("global");
  return (
    <Search>
      <input
        type="search"
        name="search-form"
        className="search-input"
        placeholder={t("global.search")}
        value={searchValue}
        onChange={(e) => {
          onChange && onChange(e.target.value);
        }}
      />
    </Search>
  );
};
