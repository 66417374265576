export interface PayrollDataState {
  loading: boolean
  error: string | null
  payrollData: PayrollAccountByUser | null,
  pinwheelData: PinwheelLinkData | null,
  pdfDirectDepositData: PdfDirectDepositData | null,
  requestDirectDepositData: RequestDirectDepositData | null,
  complete: boolean
}

export interface PinwheelLinkData {
  mode: string
  id: string
  token: string
  smartBranchUrl: string | null
  expires: string;
}

export interface PayrollAccountByUser {
  hasPhinwheelAcount: boolean;
  accounts: Array<{
    userId: string;
    accountId: string;
    platformId: string;
    platformName: string;
    job: string;
    action: string;
    type: string;
    typeValue: number;
  }>;
}

export interface PinwheelAccountData {
  accountId: string;
  platformId: string;
  job: string;
  action: "full_switch" | "partial_switch" | null | undefined;
  type: string;
  typeValue: number;
}

export interface PinwheelPayload {
  accountId: string;
  platformId: string;
  job: string;
  params: {
    action: string;
    allocation?: {
      type: string;
      value: number;
    }
  }
}

export interface DirectDepositPayload{
  employeeNumber?: string,
  paymentType: string,
  percentage?: string,
  amount?: string,
  isAccepted: boolean,
  personId: string,
  email: string | undefined,
  socialSecurityNumber?: string | null,
}

export interface PdfDirectDepositPayload{
  email: string,
  personId: string
}

export interface PdfDirectDepositData {
  urlFile: string
}

export interface RequestDirectDepositData {
  urlFile: string,
  employeeNumber: string,
  paymentType: string,
  percentage?: number,
  amount?: number,
  employeerName: string,
  isAccepted: boolean,
  acceptedAt: string
}

export interface PayrollForm {
  employeeNumber?: string;
  idType: string;
  paymentType: string;
  percentage?: string;
  email: string | undefined;
  ssn?: string;
  amount?: string
}

export enum PayrollAmountOptions {
  allPercentage = "allPercentage",
  amount = "amount"
}

export enum PaymentTypeOptions {
  check = "check",
  depositToAccount = "depositAccount"
}

export enum IdTypeOptions {
  ssn = "ssn",
  employeeNumber = "employeenumber"
}