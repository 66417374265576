import { createSlice } from "@reduxjs/toolkit";
import { PaymentACHState } from "@core/Payments/PaymentOrders/domain/Payments";
import { State } from "@redux/Store";

const initialState: PaymentACHState = {
  loading: false,
  error: null,
  data: {
    achInformation: undefined,
  },
};

export const PaymentAchSlice = createSlice({
  name: "paymentAch",
  initialState,
  reducers: {
    resetState(state) {
      state = initialState;
      return state;
    },
    setAchInformation(state, action) {
      state.data.achInformation = action.payload;
    },
  },
});

export default PaymentAchSlice.reducer;

export const PaymentAchSelector = (state: State) => state.paymentAch;
