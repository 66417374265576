import { PropsCheck } from "../../Models/checkboxInterface";
import { Checkbox, Label, ErrorContainer, ErrorMessage } from "./Checkbox.styled";

import Icon from "../Icon";
import { Column } from "../Grid";
import { FlexContainer } from "@components/Flex/Flex.styled";
import Text from "@components/Text";

const CheckboxInput = (props: PropsCheck) => {
  const { id, variant, check, children, register, label, errors, rules } = props;
  return (
    <FlexContainer alignItems="center">
      <FlexContainer w="auto" alignItems="center">
        <Column span={1}>
          <Checkbox id={id} checked={check} type={variant} {...register(label, rules)} />
        </Column>
        <Column span={11}>
          <Label htmlFor={id}>
            <Text align="left" size={0.5}>
              {children}
            </Text>
          </Label>
        </Column>
      </FlexContainer>
      <FlexContainer w="auto" alignItems="center">
        <Column span={12}>
          {errors[label] && (
            <ErrorContainer>
              <Icon icon="alertTriangle" color="error" size="small" fillIcon={false} />
              <ErrorMessage>{errors[label].message}</ErrorMessage>
            </ErrorContainer>
          )}
        </Column>
      </FlexContainer>
    </FlexContainer>
  );
};

export default CheckboxInput;
