import { createSlice } from "@reduxjs/toolkit";
import { getCellphoneDetailsAction } from "./CellphoneDetails.actions";
import { State } from "../Store";
import { CellphoneDetailsState } from "../../Models/Cellphone";

const initialState: CellphoneDetailsState = {
  loading: false,
  error: null,
  data: null,
};

export const getCellphoneDetailsSlice = createSlice({
  name: "getCellphoneDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCellphoneDetailsAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCellphoneDetailsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getCellphoneDetailsAction.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export const getCellphoneDetailsSelector = (state: State) => state.getCellphoneDetails;
