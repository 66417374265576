import { useRef, useState } from "react";
import { InputProps } from "./inputInterface";

// Components
import Icon from "@components/Icon";

// styled
import {
  ContainerRight,
  ContainerText,
  Span,
  InputStyled,
  ContainerInput,
  HintTooltip,
  Placeholder,
} from "./Input.styled";
import IconFont from "@components/IconFont";

import { get } from "lodash";
import Text from "@components/Text";
import { useFormContext } from "react-hook-form";
import { FlexContainer } from "@components/Flex/Flex.styled";

const Input = ({
  disabledInput,
  errors,
  feedbackText,
  full,
  infoText,
  isError,
  label,
  labelSize = 0,
  maxLength,
  minLength,
  placeholderInput,
  rules,
  size,
  textLabel,
  typeInput,
  validate = true,
  valueInput,
  defaultValueInput,
  opacity,
  inputMode,
  mb,
  onInputChange,
  register,
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement | null>();
  const [showHintTooltip, setShowHintTooltip] = useState(false);
  const [focusInput, setFocusInput] = useState(false);

  const { getValues } = useFormContext();
  const { ref, onChange, onBlur, ...restInputProps } = register(label, rules);

  const hasError = isError || (errors && Boolean(get(errors, label)));

  return (
    <FlexContainer direction="column" m={`0 0 ${mb || "10px"} 0`} style={{ position: "relative" }}>
      {showHintTooltip && infoText && (
        <HintTooltip
          onMouseEnter={() => setShowHintTooltip(true)}
          onMouseLeave={() => setShowHintTooltip(false)}
          dangerouslySetInnerHTML={{
            __html: infoText,
          }}
        ></HintTooltip>
      )}
      {textLabel && (
        <Text size={labelSize} weight={700} align="left" margin="0 0 10px 2px" color="grey" tagName={"label"}>
          {textLabel}
        </Text>
      )}
      <FlexContainer alignItems="center">
        <ContainerInput $size={size} error={hasError}>
          {placeholderInput && (
            <Placeholder
              size={1}
              active={focusInput || Boolean(valueInput) || Boolean(defaultValueInput) || Boolean(getValues(label))}
              error={hasError}
              onFocus={() => {
                inputRef.current?.focus();
              }}
              onClick={() => {
                inputRef.current?.focus();
              }}
            >
              {placeholderInput}
            </Placeholder>
          )}
          <InputStyled
            ref={(e) => {
              ref(e);
              inputRef.current = e;
            }}
            value={valueInput}
            defaultValue={defaultValueInput}
            aria-invalid={hasError ? "true" : "false"}
            type={typeInput || "text"}
            disabled={disabledInput}
            $error={hasError}
            $validate={validate}
            $size={size}
            maxLength={maxLength}
            minLength={minLength}
            {...restInputProps}
            $full={full}
            opacity={opacity}
            inputMode={inputMode}
            autoComplete="new-value"
            onChange={(e) => {
              onChange(e);
              onInputChange && onInputChange(e);
            }}
            onKeyDown={(evt) => {
              if (typeInput === "number") {
                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault();
              }
            }}
            onBlur={(event) => {
              onBlur(event);
              setFocusInput(false);
            }}
            onFocus={() => setFocusInput(true)}
          />
        </ContainerInput>
        {infoText && (
          <ContainerRight onMouseEnter={() => setShowHintTooltip(true)} onMouseLeave={() => setShowHintTooltip(false)}>
            <IconFont name="info" size="medium" color="black" />
          </ContainerRight>
        )}
      </FlexContainer>
      {feedbackText && (
        <ContainerText>
          <Icon icon={"bank"} color={"success"} size="small" fillIcon={false} />
          <Span>{feedbackText}</Span>
        </ContainerText>
      )}
      {errors && get(errors, label) && (
        <ContainerText>
          <Icon icon={"alertTriangle"} color={"error"} size="small" fillIcon={false} />
          <Span $error>{get(errors, label)?.message?.toString()}</Span>
        </ContainerText>
      )}
    </FlexContainer>
  );
};

export default Input;
