import { createSlice } from "@reduxjs/toolkit";
import { State } from "@redux/Store";
import { PaymentOrderState } from "@core/Payments/PaymentOrders/domain/SendolaPay";

const initialState: PaymentOrderState = {
  loading: false,
  error: null,
  data: {
    statusSendolaPay: undefined,
    privacypolicyStatus: undefined,
    privacypolicyStatusSendola: undefined,
    credentialsSendola: undefined,
    credentialsSendolaResponse: undefined,
    transactionDetail: undefined,
  },
};

export const SendolaPayOrderSlice = createSlice({
  name: "sendolaPayOrder",
  initialState,
  reducers: {
    resetState(state) {
      state = initialState;
      return state;
    },
    setStatusSendolaPay(state, action) {
      state.data.statusSendolaPay = action.payload;
      return state;
    },
    setPrivacypolicyStatus(state, action) {
      state.data.privacypolicyStatus = action.payload;
      return state;
    },
    setPrivacypolicyStatusSendola(state, action) {
      state.data.privacypolicyStatusSendola = action.payload;
      return state;
    },
    setCredentialsSendola(state, action) {
      state.data.credentialsSendola = action.payload;
      return state;
    },
    setTransactionDetail(state, action) {
      state.data.transactionDetail = action.payload;
      return state;
    },
    setCredentialsSendolaResponse(state, action) {
      state.data.credentialsSendolaResponse = action.payload;
      return state;
    },
  },
});

export const SendolaPayOrderSelector = (state: State) => state.sendolaPayOrder;
