import styled from "styled-components";
import mediaQuery, {
  mediaQueryMax,
  Screen,
} from "../../../Utilities/MediaQuery";
import { theme } from "styled-tools";
import Text from "@components/Text";

export const ContainerTitleDay = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 0;
  font-size: 16px;
  justify-content: space-between;
  border-bottom: 1px solid ${theme("Neutral_900")};
  padding-top: 20px;

  p {
    margin-bottom: 4px;
  }
`;

export const ContainerItem = styled.button`
  background: none;
  border: none;
  width: 100%;
  padding: 22px 0 4px 0;
  cursor: pointer;
  border-bottom: 1px solid ${theme("Neutral_900")};

  p {
    margin-block: 0;
  }
`;

export const TitleItem = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const CurrentBalance = styled.div`
  display: inline-flex;
  margin-block: 4px;
  align-items: center;
`;

export const BtnViewMore = styled.button`
  display: inline-block;
  margin-top: 40px;
  background: none;
  border: none;
  cursor: pointer;

  p {
    border-bottom: 1px solid ${theme("Background_02")};
  }

  div:first-child {
    margin-inline: auto;
  }

  ${mediaQuery[Screen.mobile]} {
    display: inline-flex;
    align-items: center;
  }
`;

export const TransactionsContainer = styled.div`
  box-sizing: border-box;
  padding: 0 16px;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

export const ItemsContainerPaginator = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  ${mediaQuery[Screen.mobile]} {
    display: none;
  }
`;

export const ItemsContainer = styled(ItemsContainerPaginator)`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-bottom: 25px;
  ${mediaQuery[Screen.desktop]} {
    display: none;
  }
`;

export const ContentColumn = styled.div`
  margin: 64px 136px;

  @media screen and (${mediaQueryMax[Screen.md]}) {
    margin: 0 30px;
  }
`;

export const RecentTransactions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 30px 0;
  align-items: center;
`;

export const PaginationSection = styled.div`
  ${mediaQuery[Screen.mobile]} {
    display: none;
  }

  .page-item a.page-link:hover {
    background-color: ${theme("Gradient_01")};
    color: ${theme("Background_01")};
  }

  .page-item.active .page-link {
    font-weight: 700;
    color: ${theme("Background_01")};
    background-color: ${theme("Primary_01")};
    border: ${theme("Primary_01")};
  }

  .page-item.disabled .page-link {
    color: ${theme("Background_04")};
    pointer-events: none;
    cursor: auto;
  }

  .page-item .page-link {
    color: ${theme("Gradient_01")};
  }
`;

export const TransactionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const TransferMonthTitle = styled(Text)`
  width: 100%;
  max-width: 540px;
  text-align: left;
  margin: 20px 0 0 0;

  ${mediaQuery[Screen.mobile]} {
    max-width: none;
  }
`;
