function truncateNumber(num: number, decimals: number) {
  const factor = Math.pow(10, decimals);
  return Math.trunc(num * factor) / factor;
}

export const formatNumber = (
  value: number,
  options?: { maximumFractionDigits?: number; minimumFractionDigits?: number }
) => {
  const numberToFormat = truncateNumber(value, options?.maximumFractionDigits || 2);
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    ...options,
  }).format(numberToFormat);
};
