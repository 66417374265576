import { useEffect, useState } from "react";
import { BeneficiarySelector } from "@redux/Payments/Beneficiary/Beneficiary.slice";
import { useSelector } from "react-redux";
import { QuotationSelector } from "@redux/Payments/Quotation/Quotation.slice";
import { PaymentOrderPayload } from "@core/Payments/PaymentOrders/domain/PaymentOrderCreated";
import { PaymentOrderVerifySelector } from "@redux/Payments/PaymentOrder/PaymentOrderVerify.slice";
import { SendolaCardPaymentMethod, PaymentMethods, PlaidAccount } from "@core/Payments/Common/domain/PaymentMethods";
import { PaymentLinkedAccountSelector } from "@redux/Payments/PaymentLinkedAccount/PaymentLinkedAccount.slice";
import { PaymentAchSelector } from "@redux/Payments/PaymentACH";

export const usePaymentOrderPayload = () => {
  const {
    currentDeliveryMethodQuote,
    currentPaymentMethod,
    currentPaymentDestination,
    paymentMethodSelected,
    currentChannel,
    couponToApply,
    currentQuoteDiscounted,
  } = useSelector(QuotationSelector).data;
  const { achInformation } = useSelector(PaymentAchSelector).data;
  const { linkedAccountInformation } = useSelector(PaymentLinkedAccountSelector).data;
  const { currentVerifyResponse } = useSelector(PaymentOrderVerifySelector).data;
  const currentBeneficiarySelected = useSelector(BeneficiarySelector).data.beneficiarySelected;
  const [paymentOrderPayload, setPaymentOrderPayload] = useState<unknown>(null);

  const couponDiscountIsApplied = couponToApply || currentQuoteDiscounted;

  const generateACHPayload = () => {
    if (currentPaymentMethod && achInformation && currentVerifyResponse) {
      const request: PaymentOrderPayload = {
        quote: {
          type: "Tier 1",
          fee: currentDeliveryMethodQuote?.fee || 0,
          fx: currentDeliveryMethodQuote?.fx,
          paymentMethodFee: currentPaymentMethod.paymentMethodFee || 0,
          quoteUpdatedAt: new Date().toISOString(),
          quoteType: currentChannel,
        },
        payment: {
          paymentSource: null,
          amount:
            couponDiscountIsApplied && currentPaymentMethod.discount
              ? Number(currentPaymentMethod.totalCost) + Number(currentPaymentMethod.discount)
              : currentPaymentMethod.totalCost,
          accountNumber: achInformation.accountNumber,
          routingNumber: achInformation.labelRouting,
          fullName: achInformation.holderName,
          bankName: achInformation.bankName,
        },
        id: currentVerifyResponse?.id,
      };
      return request;
    } else {
      return null;
    }
  };

  const generateSendolaPayPayload = () => {
    if (currentPaymentMethod && currentVerifyResponse) {
      const request: PaymentOrderPayload = {
        quote: {
          type: "Tier 1",
          fee: currentDeliveryMethodQuote?.fee || 0,
          fx: currentDeliveryMethodQuote?.fx,
          paymentMethodFee: currentPaymentMethod.paymentMethodFee || 0,
          quoteUpdatedAt: new Date().toISOString(),
          quoteType: currentChannel,
        },
        payment: {
          paymentSource: "SendolaPay",
          amount:
            couponDiscountIsApplied && currentPaymentMethod.discount
              ? Number(currentPaymentMethod.totalCost) + Number(currentPaymentMethod.discount)
              : currentPaymentMethod.totalCost,
          accountNumber: (paymentMethodSelected as SendolaCardPaymentMethod)?.accountNumber,
          routingNumber: (paymentMethodSelected as SendolaCardPaymentMethod)?.routingNumber,
          bankName:
            currentBeneficiarySelected?.accountSelected?.bankName || currentPaymentDestination?.destination || "",
        },
        id: currentVerifyResponse?.id,
      };
      return request;
    } else {
      return null;
    }
  };

  const generateZellePayload = () => {
    if (currentPaymentMethod && currentVerifyResponse) {
      const request: PaymentOrderPayload = {
        quote: {
          type: "Tier 1",
          fee: currentDeliveryMethodQuote?.fee || 0,
          fx: currentDeliveryMethodQuote?.fx,
          paymentMethodFee: currentPaymentMethod.paymentMethodFee || 0,
          quoteUpdatedAt: new Date().toISOString(),
          quoteType: currentChannel,
        },
        payment: {
          paymentSource: "Zelle",
          amount:
            couponDiscountIsApplied && currentPaymentMethod.discount
              ? Number(currentPaymentMethod.totalCost) + Number(currentPaymentMethod.discount)
              : currentPaymentMethod.totalCost,
          bankName:
            currentBeneficiarySelected?.accountSelected?.bankName || currentPaymentDestination?.destination || "",
        },
        id: currentVerifyResponse?.id,
      };
      return request;
    } else {
      return null;
    }
  };

  const generateSquarePayload = () => {
    if (currentPaymentMethod && currentVerifyResponse) {
      const request: PaymentOrderPayload = {
        quote: {
          type: "Tier 1",
          fee: currentDeliveryMethodQuote?.fee || 0,
          fx: currentDeliveryMethodQuote?.fx,
          paymentMethodFee: currentPaymentMethod.paymentMethodFee || 0,
          quoteUpdatedAt: new Date().toISOString(),
          quoteType: currentChannel,
        },
        payment: {
          paymentSource: "Square",
          amount:
            couponDiscountIsApplied && currentPaymentMethod.discount
              ? Number(currentPaymentMethod.totalCost) + Number(currentPaymentMethod.discount)
              : currentPaymentMethod.totalCost,
          bankName: "",
          accountId: "",
        },
        id: currentVerifyResponse?.id,
      };
      return request;
    } else {
      return null;
    }
  };

  const generatePayPalPayload = () => {
    if (currentPaymentMethod && currentVerifyResponse) {
      const request: PaymentOrderPayload = {
        quote: {
          type: "Tier 1",
          fee: currentDeliveryMethodQuote?.fee || 0,
          fx: currentDeliveryMethodQuote?.fx,
          paymentMethodFee: currentPaymentMethod.paymentMethodFee || 0,
          quoteUpdatedAt: new Date().toISOString(),
          quoteType: currentChannel,
        },
        payment: {
          paymentSource: "PayPal",
          amount:
            couponDiscountIsApplied && currentPaymentMethod.discount
              ? Number(currentPaymentMethod.totalCost) + Number(currentPaymentMethod.discount)
              : currentPaymentMethod.totalCost,
          bankName: "",
          accountId: "",
        },
        id: currentVerifyResponse?.id,
      };
      return request;
    } else {
      return null;
    }
  };

  const generateVenmoPayload = () => {
    if (currentPaymentMethod && currentVerifyResponse) {
      const request: PaymentOrderPayload = {
        quote: {
          type: "Tier 1",
          fee: currentDeliveryMethodQuote?.fee || 0,
          fx: currentDeliveryMethodQuote?.fx,
          paymentMethodFee: currentPaymentMethod.paymentMethodFee || 0,
          quoteUpdatedAt: new Date().toISOString(),
          quoteType: currentChannel,
        },
        payment: {
          paymentSource: "Venmo",
          amount: currentPaymentMethod.totalCost,
          bankName: "",
          accountId: "",
        },
        id: currentVerifyResponse?.id,
      };
      return request;
    } else {
      return null;
    }
  };

  const generateAuthorizePayload = () => {
    if (currentPaymentMethod && currentVerifyResponse) {
      const request: PaymentOrderPayload = {
        quote: {
          type: "Tier 1",
          fee: currentDeliveryMethodQuote?.fee || 0,
          fx: currentDeliveryMethodQuote?.fx,
          paymentMethodFee: currentPaymentMethod.paymentMethodFee || 0,
          quoteUpdatedAt: new Date().toISOString(),
          quoteType: currentChannel,
        },
        payment: {
          paymentSource: "Authorize",
          amount:
            couponDiscountIsApplied && currentPaymentMethod.discount
              ? Number(currentPaymentMethod.totalCost) + Number(currentPaymentMethod.discount)
              : currentPaymentMethod.totalCost,
          bankName: "",
          accountId: "",
        },
        id: currentVerifyResponse?.id,
      };
      return request;
    } else {
      return null;
    }
  };

  const generatePlaidPayload = () => {
    if (currentPaymentMethod && (linkedAccountInformation || paymentMethodSelected) && currentVerifyResponse) {
      //? (paymentMethodSelected as PlaidAccount) for the new quoter flow and linkedAccountInformation for the old one
      const request: PaymentOrderPayload = {
        quote: {
          type: "Tier 1",
          fee: currentDeliveryMethodQuote?.fee || 0,
          fx: currentDeliveryMethodQuote?.fx,
          paymentMethodFee: currentPaymentMethod.paymentMethodFee || 0,
          quoteUpdatedAt: new Date().toISOString(),
          quoteType: currentChannel,
        },
        payment: {
          paymentSource: "Plaid",
          amount:
            couponDiscountIsApplied && currentPaymentMethod.discount
              ? Number(currentPaymentMethod.totalCost) + Number(currentPaymentMethod.discount)
              : currentPaymentMethod.totalCost,
          accountId: linkedAccountInformation?.accountId || (paymentMethodSelected as PlaidAccount).id,
          accountNumber:
            linkedAccountInformation?.accountNumber || (paymentMethodSelected as PlaidAccount).accountNumber,
          routingNumber:
            linkedAccountInformation?.routingNumber || (paymentMethodSelected as PlaidAccount).routingNumber,
          bankName: linkedAccountInformation?.bankName || (paymentMethodSelected as PlaidAccount).bankName,
        },
        id: currentVerifyResponse?.id,
      };
      return request;
    } else {
      return null;
    }
  };

  const generateCoppelPayload = () => {
    if (currentPaymentMethod && currentVerifyResponse && paymentMethodSelected) {
      const request: PaymentOrderPayload = {
        quote: {
          type: "Tier 1",
          fee: currentDeliveryMethodQuote?.fee || 0,
          fx: currentDeliveryMethodQuote?.fx,
          paymentMethodFee: currentPaymentMethod.paymentMethodFee || 0,
          quoteUpdatedAt: new Date().toISOString(),
          quoteType: currentChannel,
        },
        payment: {
          paymentSource: "Coppel",
          amount:
            couponDiscountIsApplied && currentPaymentMethod.discount
              ? Number(currentPaymentMethod.totalCost) + Number(currentPaymentMethod.discount)
              : currentPaymentMethod.totalCost,
          accountId: (paymentMethodSelected as SendolaCardPaymentMethod).id,
          accountNumber: (paymentMethodSelected as SendolaCardPaymentMethod).accountNumber,
          routingNumber: (paymentMethodSelected as SendolaCardPaymentMethod).routingNumber,
          bankName: "Coppel",
        },
        id: currentVerifyResponse.id,
      };
      return request;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (currentPaymentMethod && !paymentMethodSelected) {
      const generatePayloadFnMap: Record<PaymentMethods, () => unknown> = {
        "Sendola Card": generateSendolaPayPayload,
        "Sendola ACH": generateACHPayload,
        "Sendola Plaid": generatePlaidPayload,
        "Debit Card": () => {
          return null;
        },
        "Credit Card": () => {
          return null;
        },
        Coppel: () => {
          return null;
        },
        Square: generateSquarePayload,
        Zelle: generateZellePayload,
        PayPal: generatePayPalPayload,
        Venmo: generateVenmoPayload,
        ["authorize.net"]: generateAuthorizePayload,
      };

      const fn = generatePayloadFnMap[currentPaymentMethod.paymentOrigin as PaymentMethods];

      if (fn) {
        setPaymentOrderPayload(fn());
      }
    }
  }, [
    currentPaymentMethod,
    achInformation,
    linkedAccountInformation,
    currentVerifyResponse,
    paymentMethodSelected,
    currentChannel,
    couponToApply,
  ]);

  //? this effect works for the new quoter flow, its separated in order to maintain the old quoter flow active
  useEffect(() => {
    if (currentPaymentMethod && paymentMethodSelected) {
      const generatePayloadFnMap: Record<PaymentMethods, () => unknown> = {
        "Sendola Card":
          paymentMethodSelected?.paymentSource === "Coppel" ? generateCoppelPayload : generateSendolaPayPayload,
        "Sendola ACH": generatePlaidPayload,
        "Sendola Plaid": generatePlaidPayload,
        "Debit Card": () => {
          return null;
        },
        "Credit Card": () => {
          return null;
        },
        Coppel: generateCoppelPayload,
        Square: generateSquarePayload,
        Zelle: generateZellePayload,
        PayPal: generatePayPalPayload,
        Venmo: generateVenmoPayload,
        ["authorize.net"]: generateAuthorizePayload,
      };

      const fn = generatePayloadFnMap[currentPaymentMethod.paymentOrigin as PaymentMethods];

      if (fn) {
        setPaymentOrderPayload(fn());
      }
    }
  }, [
    currentPaymentMethod,
    linkedAccountInformation,
    currentVerifyResponse,
    paymentMethodSelected,
    currentChannel,
    couponToApply,
  ]);

  return { paymentOrderPayload };
};
