import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// Own states of redux
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, State } from "../../Redux/Store";
import { UpdateUserFeature } from "../../Redux/UpdateUserFeature/UpdateUserFeature.actions";
import { ByUserSliceSelector } from "../../Redux/ByUser/ByUser.slice";

// Own constants
import { ThemeProvider } from "styled-components";
import { UPDATE_USER_FEATURE_FULFILLED, UPDATE_USER_FEATURE_REJECTED } from "../../Constants/user";
import { WELCOME_NEW_APP } from "../../Constants/Features";

// Own models
import { JustifyContent } from "../../Models/Column";

// Own Assets
import PartyImage from "../../Assets/Img/party.png";

// Own components
import { Row, Column, FillerContainer, BottomFixedContainer } from "../../Components/Grid";
import { StepsContainer } from "../../Components/StepsRoutes/StepsRoutes.styled";
import Layout from "../../Components/Layout";
import Text from "../../Components/Text";
import Button from "../../Components/Button";
import Icon from "../../Components/Icon";

const WelcomeNewAppPage = () => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch<AppDispatch>();
  const theme = useSelector((state: State) => state.theme);
  const [isLoading, setIsLoading ] = useState<boolean>(false);
  const PersonalData = useSelector(ByUserSliceSelector);
  const userId = useMemo(() =>
    PersonalData.data?.userId || ""
  ,[PersonalData]);

  const UpdateFeature = async(userId: string) => {
    const payload = {
      userId: userId,
      featureCode: WELCOME_NEW_APP,
      isActive: false
    };

    const response = await dispatch(UpdateUserFeature(payload));

    if (response?.type === UPDATE_USER_FEATURE_FULFILLED) {
      window.location.href = "/";
    }

    if (response?.type === UPDATE_USER_FEATURE_REJECTED) {
      window.location.href = "/";
    }

    setIsLoading(false);
  };

  const handleClick = () => {
    setIsLoading(true);
    UpdateFeature(userId);
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <FillerContainer as={StepsContainer}>
          <Row>
            <Column span={12} mt={6} justifyContent={JustifyContent.center}>
              <img src={PartyImage} alt="image-logo" />
            </Column>
            <Column span={12} mt={0} justifyContent={JustifyContent.center}>
              <Text size={7} align="center" weight={800}>
                {t("welcomeNewApp.title")}
              </Text>
            </Column>

            <Column span={5} my={6} justifyContent={JustifyContent.flexEnd}>
              <Icon icon="creditCard" size="xlarge" />
            </Column>
            <Column span={2} my={6} justifyContent={JustifyContent.center}>
              <Icon icon="plus" size="xlarge" />
            </Column>
            <Column span={5} my={6} justifyContent={JustifyContent.flexStart}>
              <Icon icon="piggyBank" size="xlarge" />
            </Column>

            <Column span={12} mt={0} justifyContent={JustifyContent.center}>
              <Text size={3} align="center" weight={400} color="grey">
                {t("welcomeNewApp.subtitle")}
              </Text>
            </Column>

            <Column span={12} mt={0} justifyContent={JustifyContent.center}>
              <Text size={4} align="center" weight={400} color="black">
                {t("welcomeNewApp.subtitle2")}
              </Text>
            </Column>

            <BottomFixedContainer>
              <Button
                variant="primary"
                text={t("welcomeNewApp.btnNext")}
                loading={isLoading ? 1 : 0}
                sizeText="medium"
                onClick={handleClick}
                disabled={isLoading}
              />
            </BottomFixedContainer>
          </Row>
        </FillerContainer>
      </Layout>
    </ThemeProvider>
  )
}

export default WelcomeNewAppPage
