import USIcon from "../Assets/Flags/USIcon.png";
import MXIcon from "../Assets/Flags/MXIcon.png";
import GTMIcon from "../Assets/Flags/GTMIcon.png";
import BRAIcon from "../Assets/Flags/BRAIcon.png";
import ARGIcon from "../Assets/Flags/ARGIcon.png";
import BBIcon from "../Assets/Flags/BBIcon.png";
import CLIcon from "../Assets/Flags/CLIcon.png";
import COLIcon from "../Assets/Flags/COLIcon.png";
import CRIIcon from "../Assets/Flags/CRIIcon.png";
import ECUIcon from "../Assets/Flags/ECUIcon.png";
import SLVIcon from "../Assets/Flags/SLVIcon.png";
import HNDIcon from "../Assets/Flags/HNDIcon.png";
import HUNIcon from "../Assets/Flags/HUNIcon.png";
import JAMIcon from "../Assets/Flags/JAMIcon.png";
import MNGIcon from "../Assets/Flags/MNGIcon.png";
import PHLIcon from "../Assets/Flags/PHLIcon.png";
import THAIcon from "../Assets/Flags/THAIcon.png";
import TURIcon from "../Assets/Flags/TURIcon.png";
import ZAFIcon from "../Assets/Flags/ZAFIcon.png";
import BOBIcon from "../Assets/Flags/BOBIcon.png";
import SOLIcon from "../Assets/Flags/SOLIcon.png";
import UYUIcon from "../Assets/Flags/UYUIcon.png";
import NICIcon from "../Assets/Flags/NICIcon.png";
import CANIcon from "../Assets/Flags/CANIcon.png";
import INDIcon from "../Assets/Flags/INDIcon.png";
import PANIcon from "../Assets/Flags/PANIcon.png";
import VNMIcon from "../Assets/Flags/VNMIcon.png";

export const FlagIconPath = (countryCodeId: string) => {
  switch (countryCodeId) {
    case "USA":
      return USIcon;
    case "MEX":
      return MXIcon;
    case "CAN":
      return CANIcon;
    case "IND":
      return INDIcon;
    case "GTM":
      return GTMIcon;
    case "ARG":
      return ARGIcon;
    case "VNM":
      return VNMIcon;
    case "PHL":
      return PHLIcon;
    case "NIC":
      return NICIcon;
    case "CHL":
      return CLIcon;
    case "COL":
      return COLIcon;
    case "SLV":
      return SLVIcon;
    case "HND":
      return HNDIcon;
    case "PAN":
      return PANIcon;
    case "CRI":
      return CRIIcon;
    case "ECU":
      return ECUIcon;
    case "HUN":
      return HUNIcon;
    case "JAM":
      return JAMIcon;
    case "MNG":
      return MNGIcon;
    case "THA":
      return THAIcon;
    case "TUR":
      return TURIcon;
    case "ZAF":
      return ZAFIcon;
    case "BRA":
      return BRAIcon;
    case "BOL":
      return BOBIcon;
    case "URY":
      return UYUIcon;
    case "PER":
      return SOLIcon;
    case "BRB":
      return BBIcon;
  }
};
