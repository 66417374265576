import CardImage from "../CardImg";
import { Column, Container, Row } from "../Grid";

import IconFont from "@components/IconFont";

import {
  TransferLoaderTitle,
  TransferLoaderContainer,
  TransferLoader,
  TransferLoaderSpinner,
  TransferLoaderCardImage,
  TransferLoaderDescription,
  TransferLoaderText,
  TransferLoaderSpinnerContent,
} from "./TransactionLoader.styled";
import { ImageTypeKey } from "@models/IconInterface";

export type tTransactionLoaderProps = {
  title: string;
  text: string;
  description: string;
  image: ImageTypeKey;
};

export default function TransactionLoader(props: tTransactionLoaderProps) {
  const { title, text, description, image } = props;

  return (
    <Container>
      <Row>
        <Column span={12} as={TransferLoaderContainer}>
          <TransferLoaderTitle>{title}</TransferLoaderTitle>
        </Column>
      </Row>
      <Row>
        <Column span={12} as={TransferLoader}>
          <CardImage
            image={image}
            as={TransferLoaderCardImage}
          >
            <TransferLoaderSpinnerContent>
              <IconFont name="transaction" size="xlarge" gradient />
              <TransferLoaderSpinner />
            </TransferLoaderSpinnerContent>
            <TransferLoaderText>{text}</TransferLoaderText>
          </CardImage>
          <TransferLoaderDescription>{description}</TransferLoaderDescription>
        </Column>
      </Row>
    </Container>
  );
}
