import { useState } from "react";
import { useSubdivision } from "./useSubdivision";
import { SelectOption } from "@models/Select";
import { AdditionalField } from "@core/Payments/Common/domain/AdditionalFields";
import { Destination } from "@core/Payments/Common/domain/Destination";
import { useStatus } from "./useStatus";
import { useCity } from "./useCity";

export const useBankAccount = () => {
  const [additionalFields, setAdditionalFields] = useState<AdditionalField[]>(
    []
  );
  const [bankList, setBankList] = useState<Array<SelectOption & Destination>>(
    []
  );
  const { isLoading: bankAccountIsLoading, setStatus } = useStatus();
  const { subdivisionList, setSubdivisionList, getSubdivisionOptions } =
    useSubdivision();
  const {
    citiesList,
    isLoading: cityIsLoading,
    getSubdivisionCities,
    getSubdivisionCitiesOptions,
  } = useCity();

  const isLoading = bankAccountIsLoading || cityIsLoading;

  return {
    additionalFields,
    bankList,
    isLoading,
    subdivisionList,
    citiesList,
    setAdditionalFields,
    setBankList,
    setStatus,
    setSubdivisionList,
    getSubdivisionOptions,
    getSubdivisionCities,
    getSubdivisionCitiesOptions,
  };
};
