import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { UPDATE_USER_FEATURE } from "../../Constants/user";
import { GetRequestFeature } from "../../Models/UserFeatures";
import { defaultErrorMessage } from "../../Constants/Requests";

/**
 * Method to update user feature
 */
export const UpdateUserFeature = createAsyncThunk(
  UPDATE_USER_FEATURE,
  async (payload: GetRequestFeature, thunkAPI) => {
    try {
      const response = await BaseConfigApi.patch(
        `/userfeatures/${payload.userId}/${payload.featureCode}`,
        { isActive: payload.isActive }
      );
      return response.data.data;
    } catch (error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
