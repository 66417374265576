// Own models
import { Link } from "react-router-dom";
import { AlignItems, JustifyContent } from "../../Models/Column";
import { ZelleBannerProps } from "../../Models/PedingRequestBanner";

// Own components
import { Column, Container, Row } from "../Grid";
import Text from "../Text";
import Title from "../Title";

// Own Styles
import {
  BannerContainer,
  RowBottomFixed,
  RowTopFixed,
  ContainerText,
} from "./ZelleBanner.styled";

const ZelleBanner = ({
  title,
  message,
  textButton,
  urlLink,
}: ZelleBannerProps) => {
  return (
    <BannerContainer>
      <Container>
        <Row as={RowTopFixed}>
          <Column
            span={12}
            alignItems={AlignItems.center}
            justifyContent={JustifyContent.flexStart}
          >
            <Title
              size={1}
              tagName="h1"
              color="white"
              align="left"
              text={title}
            />
          </Column>
          <Column span={12} as={ContainerText}>
            <Text
              size={0.3}
              color="white"
              align="left"
              weight={400}
              margin="8px 0 0 0"
            >
              <span dangerouslySetInnerHTML={{ __html: message }} />
            </Text>
          </Column>
        </Row>
        <Row as={RowBottomFixed}>
          <Column span={12} justifyContent={JustifyContent.flexEnd} mt={0}>
            <button>
              <Link to={urlLink} target="_blank">
                {textButton}
              </Link>
            </button>
          </Column>
        </Row>
      </Container>
    </BannerContainer>
  );
};

export default ZelleBanner;
