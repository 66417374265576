import { createSlice } from "@reduxjs/toolkit";
import { handleAccountTransactions } from "./AccountTransactions.actions";
import { State } from "../Store";

export const accounTransactionSlice = createSlice({
  name: "accountTransaction",
  initialState: {
    loading: false,
    error: null,
    data: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .addCase(handleAccountTransactions.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        handleAccountTransactions.fulfilled,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state: any, action: any) => {
          state.loading = false;
          state.data = action.payload;
        }
      )
      .addCase(
        handleAccountTransactions.rejected,
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        (state: any, action: any) => {
          state.loading = false;
          state.data = {};
          state.error = action.error;
        }
      );
  },
});

export const accountTransactionSelector = (state: State) => state.accountTransaction;
