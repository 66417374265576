import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OnboardingSliceAction } from "./Onboarding.actions";
import { State } from "../Store";
import { SetStepsPayload, OnboardingState } from "../../Models/Onboarding";
import { GET_ONBOARDING_STEP } from "../../Constants/user";

const initialState: OnboardingState = {
  loading: false,
  error: null,
  data: null
}

export const OnboardingSlice = createSlice({
  name: GET_ONBOARDING_STEP,
  initialState,
  reducers: {
    setSteps(state, action: PayloadAction<SetStepsPayload>) {
      state.data = {
        requiredOnboarding: action.payload.requiredOnboarding || state.data?.requiredOnboarding || true,
        nextStep: action.payload.nextStep,
        lastStep: action.payload.lastStep
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(OnboardingSliceAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(OnboardingSliceAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(OnboardingSliceAction.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export default OnboardingSlice.reducer;

export const { setSteps } = OnboardingSlice.actions;

export const OnboardingSliceSelector = (state: State) => state.onboarding;
