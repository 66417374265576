import styled from "styled-components";
import { theme } from "styled-tools";

import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

export const PDFViewerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;

  .pdf-page {
    margin-bottom: 1rem;
  }

  ${mediaQuery[Screen.mobile]} {
    .react-transform-wrapper {
      padding-top: 4rem;
      max-width: 100vw;
      max-height: 100vh;
    }
  }
`;

export const PDFViewerNavigatorStyled = styled.div`
  display: flex;
  flex-direcction: row;
  align-items: center;
  justify-content: space-between;
  width: 10%;

  div {
    padding: 0.5em 0.8em;

    &:hover {
      cursor: pointer;
    }
  }

  span {
    color: ${theme("Background_01")};
    font-size: 1.2em;

    ${mediaQuery[Screen.mobile]} {
      font-size: 1em;
    }
  }

  ${mediaQuery[Screen.mobile]} {
    width: 35%;
  }
`;

export const PDFViewerConfirmStyled = styled.div`
  display: flex;
  flex-direcction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;

  div {
    padding: 0.5em 0.8em;

    &:hover {
      cursor: pointer;
    }
  }

  span {
    color: ${theme("Background_01")};
    font-size: 1.2em;

    ${mediaQuery[Screen.mobile]} {
      font-size: 1em;
    }
  }

  button {
    ${mediaQuery[Screen.mobile]} {
      width: 100%;
    }
  }
`;

export const PDFViewerScrollAnimation = styled.div`
  position: fixed;
  left:20px;
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  ${mediaQuery[Screen.mobile]} {
    width: 100%;
    left:0px;
    padding:0px !important;
  }
`;
