
import { createSlice } from "@reduxjs/toolkit";
import { getPayrollByUserAction, getRequestDirectDepositAction, pdfDirectDepositAction, PinwheelAccountAction, PinwheelLinkTokenAction, postRequestDirectDepositAction,  } from "./PayrollData.actions";
import { State } from "../Store";
import { GET_PAYROLL } from "../../Constants/Payroll";
import { PayrollAccountByUser, PayrollDataState } from "../../Models/PayrollData";

const initialState: PayrollDataState = {
  loading: false,
  error: null,
  pinwheelData: null,
  payrollData: null,
  pdfDirectDepositData: null,
  requestDirectDepositData: null,
  complete: false
}

export const PayrollDataSlice = createSlice({
  name: GET_PAYROLL,
  initialState,
  reducers: {
    resetState(state) {
      return {
        ...initialState,
        complete: state.complete
      };
    },
    setCompletedStatus(state, action) {
      state.complete = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPayrollByUserAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPayrollByUserAction.fulfilled, (state, action) => {
        state.loading = false;
        state.payrollData = action.payload as PayrollAccountByUser;
      })
      .addCase(getPayrollByUserAction.rejected, (state, action) => {
        state.loading = false;
        state.payrollData = null;
        state.error = action.payload as string;
      })
      .addCase(PinwheelLinkTokenAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PinwheelLinkTokenAction.fulfilled, (state, action) => {
        state.loading = false;
        state.pinwheelData = action.payload;
      })
      .addCase(PinwheelLinkTokenAction.rejected, (state, action) => {
        state.loading = false;
        state.pinwheelData = null;
        state.error = action.payload as string;
      })
      .addCase(PinwheelAccountAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PinwheelAccountAction.fulfilled, (state, action) => {
        state.loading = false;
        state.pinwheelData = action.payload;
      })
      .addCase(PinwheelAccountAction.rejected, (state, action) => {
        state.loading = false;
        state.pinwheelData = null;
        state.error = action.payload as string;
      })
      //GET DIRECT DEPOSIT
      .addCase(getRequestDirectDepositAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRequestDirectDepositAction.fulfilled, (state, action) => {
        state.loading = false;
        state.requestDirectDepositData = action.payload;
      })
      .addCase(getRequestDirectDepositAction.rejected, (state, action) => {
        state.loading = false;
        state.requestDirectDepositData = null;
        state.error = action.payload as string;
      })
      //POST DIRECT DEPOSIT
      .addCase(postRequestDirectDepositAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postRequestDirectDepositAction.fulfilled, (state, action) => {
        state.loading = false;
        state.pdfDirectDepositData = action.payload;
      })
      .addCase(postRequestDirectDepositAction.rejected, (state, action) => {
        state.loading = false;
        state.pdfDirectDepositData = null;
        state.error = action.payload as string;
      })
      //PDF DIRECT DEPOSIT
      .addCase(pdfDirectDepositAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(pdfDirectDepositAction.fulfilled, (state, action) => {
        state.loading = false;
        state.pdfDirectDepositData = action.payload;
      })
      .addCase(pdfDirectDepositAction.rejected, (state, action) => {
        state.loading = false;
        state.pdfDirectDepositData = null;
        state.error = action.payload as string;
      });
  },
});

export const { setCompletedStatus } = PayrollDataSlice.actions;

export default PayrollDataSlice.reducer;

export const PayrollDataSelector = (state: State) => state.Payroll;
