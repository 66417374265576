import { createSlice } from "@reduxjs/toolkit";
import { VisaPhotoVeriffAction } from "./VisaPhoto.actions";
import { State } from "../Store";
import { VisaVeriffState } from "../../Models/Visa";

const initialState: VisaVeriffState = {
  loading: false,
  error: null,
  data: null
};

export const VisaPhotoVeriffSlice = createSlice({
  name: "VisaPhotoVeriff",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(VisaPhotoVeriffAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(VisaPhotoVeriffAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(VisaPhotoVeriffAction.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action?.payload as string
      });
  },
});

export const VisaPhotoVeriffSelector = (state: State) => state.VisaPhotoVeriff;
