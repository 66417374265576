import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

import { State } from "../../../Redux/Store";

import { PaymentTransactionHistory, TransactionList } from "@core/Payments/PaymentOrders/domain/PaymentOrder";
import { CustomFormatDate } from "@utils/String";
import TransactionsCardPayments from "@components/TransactionsCardPayments";
import { getDateFnsLocale } from "@utils/Date";
import { format } from "date-fns";
import { usePaymentOrder } from "@hooks/Payments/usePaymentOrders";
import { AlignItems, JustifyContent } from "@models/Column";

import { SearchInput } from "@components/Payments/Input/SearchInput.component";
import Layout from "../../../Components/Layout";
import { Column, Container, Row } from "../../../Components/Grid";
import Text from "@components/Text";
import IconFont from "@components/IconFont";
import Icon from "@components/Icon";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import {
  ItemsContainer,
  ItemsContainerPaginator,
  PaginationSection,
  RecentTransactions,
  SearchContainer,
  TransactionContainer,
  TransferMonthTitle,
} from "./Transactions.styled";
import { useNavigate } from "react-router-dom";
import { useCountry } from "@hooks/Payments/useCountry";
import Button from "@components/Payments/Button";

const Transactions = () => {
  const theme = useSelector((state: State) => state.theme);
  const lang = useSelector((state: State) => state.language);
  const { t, i18n } = useTranslation("global");
  const [tSendMoney] = useTranslation("sendMoney");
  const [searchValue, setSearchValue] = useState("");
  const [dateList, setDateList] = useState<string[]>([]);
  const [dateListPagination, setDateListPagination] = useState<string[]>([]);
  const [transactionList, setTransactionList] = useState<PaymentTransactionHistory | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const { isLoading, getTransactionHistory, getTransactionDetail } = usePaymentOrder();
  const { getDestinationCountryList } = useCountry();

  const navigate = useNavigate();

  const getPaymentTransactionHistory = async () => {
    const transactions = (await getTransactionHistory({})) as PaymentTransactionHistory;

    if (transactions && transactions.results) {
      transactions.results.map((item) => {
        item.visible = true;
      });
    } else {
      transactions.results = [];
    }

    setTransactionList(transactions);
  };

  const currentTransaction = useMemo(() => {
    const originalList = transactionList;
    return originalList?.results.filter(
      (transaction) =>
        transaction.contactName.replace(" ", "").toUpperCase().includes(searchValue.replace(" ", "").toUpperCase()) ||
        String(transaction.amountFound).includes(searchValue) ||
        transaction.deliveryMethod
          .replace(" ", "")
          .toUpperCase()
          .includes(searchValue.replace(" ", "").toUpperCase()) ||
        transaction.status.replace(" ", "").toUpperCase().includes(searchValue.replace(" ", "").toUpperCase())
    );
  }, [searchValue, transactionList]);

  const currentPageTransaction = useMemo(() => {
    const listaCurrentTransaction = currentTransaction?.slice((currentPage - 1) * 10, currentPage * 10);
    if (listaCurrentTransaction?.length === 0) {
      setCurrentPage(1);
    }
    return listaCurrentTransaction;
  }, [currentTransaction, currentPage]);

  useEffect(() => {
    if (currentTransaction) {
      setPageCount(Math.ceil(currentTransaction.length / 10));
    }
  }, [currentTransaction]);

  function handlePageChange(page: number) {
    setCurrentPage(page);
  }

  useEffect(() => {
    if (currentPageTransaction) {
      setDateListPagination(getMonthsTransactions(currentPageTransaction));
    }

    if (currentTransaction) {
      setDateList(getMonthsTransactions(currentTransaction));
    }
  }, [currentPageTransaction, currentTransaction]);

  const getMonthsTransactions = (transactionList: TransactionList[]) => {
    const date: string[] = [];
    transactionList.map((item) => {
      if (item && item.month) {
        date.push(item.month);
      }
    });

    const result = date.filter((item: string, index: number) => {
      return date.indexOf(item) === index;
    });

    return result;
  };

  const transactionDetail = async (transaction: TransactionList) => {
    await getTransactionDetail(transaction.transactionId || "0");
    return navigate("/transactions/detail");
  };

  useEffect(() => {
    getPaymentTransactionHistory();
  }, []);

  useEffect(() => {
    getDestinationCountryList(lang.language);
  }, [lang]);

  return (
    <ThemeProvider theme={theme}>
      <Layout loading={isLoading} showNavigation>
        <Container maxWidth="545px" margin="0 auto 24px auto" padding="24px">
          <Row>
            <Column span={12} mb={3} justifyContent={JustifyContent.flexEnd} alignItems={AlignItems.center} gap={3}>
              <Button
                text={`${tSendMoney("SendMoney")}`}
                variant="outline"
                sizeText="medium"
                padding="8.2px 24px"
                weight={500}
                iconButton="arrowRight"
                colorIcon="primary"
                sizeIcon="medium"
                onClick={() => {
                  navigate("/");
                }}
              />
            </Column>
            <Column span={12}>
              {transactionList && transactionList.results.length > 0 && (
                <Row>
                  <Column span={12} xs={12} alignItems={AlignItems.flexStart} as={SearchContainer}>
                    <SearchInput searchValue={searchValue} onChange={(value) => setSearchValue(value)} />
                  </Column>
                </Row>
              )}
              <Row>
                <Column span={12} xs={12} md={12} as={ItemsContainerPaginator}>
                  {dateListPagination && dateListPagination.length > 0 ? (
                    dateListPagination.map((item, index) => {
                      return (
                        <TransactionContainer key={index}>
                          <TransferMonthTitle key={index} size={0.5} color="black" weight={600}>
                            {CustomFormatDate(item, lang.language)}
                          </TransferMonthTitle>
                          {currentPageTransaction &&
                            currentPageTransaction.length > 0 &&
                            currentPageTransaction.map((transaction: TransactionList, index: number) => {
                              return (
                                item === transaction.month && (
                                  <TransactionsCardPayments
                                    key={transaction.transactionId + index}
                                    data={transaction}
                                    date={format(new Date(transaction.processedAt), "MMM dd, yyyy", {
                                      locale: getDateFnsLocale(i18n.language),
                                    })}
                                    getDetails={() => transactionDetail(transaction)}
                                  />
                                )
                              );
                            })}
                        </TransactionContainer>
                      );
                    })
                  ) : (
                    <RecentTransactions>
                      <Text size={2} align="center" color="black" weight={600} margin="20px 0 4px 20px">
                        {transactionList && transactionList.results.length > 0
                          ? t("Dashboard.NoTransactionSearch")
                          : t("Dashboard.NotTransactions")}
                      </Text>
                      <Text size={2} align="center" color="black" weight={600} margin="20px 0 4px 20px">
                        {transactionList && transactionList.results.length > 0
                          ? t("Dashboard.NoTransactionSearchText2")
                          : ""}
                      </Text>
                    </RecentTransactions>
                  )}
                </Column>
                <Column span={12} xs={12} md={12} as={ItemsContainer}>
                  {dateList && dateList.length > 0 ? (
                    dateList.map((item, index) => {
                      return (
                        <TransactionContainer key={index}>
                          <TransferMonthTitle key={index} size={1} color="black" weight={600}>
                            {CustomFormatDate(item, lang.language)}
                          </TransferMonthTitle>
                          {currentTransaction &&
                            currentTransaction.length > 0 &&
                            currentTransaction.map((transaction: TransactionList, index: number) => {
                              return (
                                item === transaction.month && (
                                  <TransactionsCardPayments
                                    key={transaction.transactionId + index}
                                    data={transaction}
                                    date={format(new Date(transaction.processedAt), "MMM dd, yyyy", {
                                      locale: getDateFnsLocale(i18n.language),
                                    })}
                                    getDetails={() => transactionDetail(transaction)}
                                  />
                                )
                              );
                            })}
                        </TransactionContainer>
                      );
                    })
                  ) : (
                    <RecentTransactions>
                      {transactionList && transactionList.results.length > 0 ? (
                        <>
                          <IconFont name="search" size={"medium"} color="black" />
                          <Text size={"default"} align="center" color="black" weight={600} margin="20px 0 0 0">
                            {t("Dashboard.NoTransactionSearch")}
                          </Text>
                          <Text size={"default"} align="center" color="black" weight={600} margin="20px 0 0 0">
                            {t("Dashboard.NoTransactionSearchText2")}
                          </Text>
                        </>
                      ) : (
                        <>
                          <Icon icon="cashCancel" color="black" size="xlarge" fillIcon={false} />
                          <Text size={1} align="center" color="black" weight={400} margin="20px 0 4px 0">
                            {t("Dashboard.NotTransactions")}
                          </Text>
                        </>
                      )}
                    </RecentTransactions>
                  )}
                </Column>
              </Row>
              <Row>
                <Column span={12} as={PaginationSection} py={4}>
                  <ResponsivePagination
                    current={currentPage}
                    total={pageCount}
                    onPageChange={(page) => handlePageChange(page)}
                  />
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default Transactions;
