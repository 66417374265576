export const GET_ALL_ACCOUNTS = "getAllAccounts";
export const GET_ALL_ACCOUNTS_PENDING = "getAllAccounts/pending";
export const GET_ALL_ACCOUNTS_REJECTED = "getAllAccounts/rejected";
export const GET_ALL_ACCOUNTS_FULFILLED = "getAllAccounts/fulfilled";

export const ACCOUNT_WITH_CARD = "accountWithCard";
export const ACCOUNT_WITH_CARD_PENDING = "accountWithCard/pending";
export const ACCOUNT_WITH_CARD_REJECTED = "accountWithCard/rejected";
export const ACCOUNT_WITH_CARD_FULFILLED = "accountWithCard/fulfilled";

export const SAVING = "savingAccount";
export const CHECKING = "personalChecking"