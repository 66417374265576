import {
  ButtonContainer,
  IconContainer,
  Overlay,
  PopupCard,
  TextContainer,
} from "./ConstructionPopUp.styled";
import Icon from "../Icon";
import { Column, Container, Row } from "../Grid";
import Title from "../Title";
import Button from "../Button";
import { tConstructionPopUpProps } from "../../Models/PopUp";
import { useTranslation } from "react-i18next";

const ConstructionPopUp = ({
  handlePopUp,
  isPopout,
}: tConstructionPopUpProps) => {
  const [t] = useTranslation("global");

  return (
    <Overlay hidden={isPopout} onClick={handlePopUp}>
      <PopupCard>
        <Container>
          <Row>
            <Column span={12} xs={12}>
              <IconContainer>
                <Icon
                  icon="alertTriangle"
                  color="error"
                  size="large"
                  fillIcon={false}
                ></Icon>
              </IconContainer>
              <TextContainer>
                <Title
                  text={t("PopUp.Title")}
                  size={5}
                  tagName="h1"
                  color="black"
                />
                <Title
                  text={t("PopUp.SubTitle")}
                  size={3}
                  color="black"
                  tagName="h5"
                />
              </TextContainer>
              <ButtonContainer>
                <Button
                  variant="primary"
                  text={t("buttons.close")}
                  sizeText="large"
                  colorIcon="white"
                  sizeIcon="large"
                  iconButtonLeft="circleX"
                  onClick={handlePopUp}
                  sizeButton="large"
                />
              </ButtonContainer>
            </Column>
          </Row>
        </Container>
      </PopupCard>
    </Overlay>
  );
};

export default ConstructionPopUp;
