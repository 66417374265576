import { useTranslation } from "react-i18next";
import { FlexContainer, FlexItem } from "@components/Flex/Flex.styled";
import Text from "@components/Text";
import IconFont from "@components/IconFont";
import Button from "@components/Payments/Button";

interface BeneficiaryEditHeadProps {
  onClose?: () => void;
  onHandleDelete?: () => void;
  showDelete: boolean;
}

export const BeneficiaryEditHead = ({ onClose, onHandleDelete, showDelete }: BeneficiaryEditHeadProps) => {
  const { t } = useTranslation("global");

  return (
    <>
      <FlexContainer justify="space-between" alignItems="start" m={"5px 0"}>
        <FlexItem direction="column" alignItems="start">
          <Text size={1} weight={600} margin={0}>
            {t("Beneficiaries.EditBeneficiary.title")}
          </Text>
        </FlexItem>

        <FlexItem direction="row" alignItems="start">
          {showDelete && (
            <IconFont name="trash" size="default" color="error" onClick={() => onHandleDelete && onHandleDelete()} />
          )}
          <Button
            type="button"
            variant="transparent"
            sizeButton="fit"
            padding="0px"
            onClick={() => onClose && onClose()}
          >
            <IconFont name="cancel" size="medium" color="black" />
          </Button>
        </FlexItem>
      </FlexContainer>
      <FlexContainer justify="space-between" alignItems="start">
        <FlexItem direction="column" alignItems="start">
          <Text size={3} weight={600} margin={0}>
            {t("Beneficiaries.EditBeneficiary.subtitle")}
          </Text>
          <Text size={1} weight={400} color="grey" align="left" margin={0}>
            {t("Beneficiaries.EditBeneficiary.message")}
          </Text>
        </FlexItem>
      </FlexContainer>
    </>
  );
};
