import { FieldValues } from "react-hook-form";
import { Account } from "./Beneficiary";
import { AdditionalField } from "@core/Payments/Common/domain/AdditionalFields";

export type AdditionalFieldData = {
  additionalFieldInfo?: AdditionalFieldInfo[];
  branch?: string;
  ifscCode?: string;
};

// Final shape of the beneficiary form data
export type BeneficiaryForm = {
  personId: string;
  recipient: {
    firstName: string;
    middleName: string;
    lastName: string;
    alias: string;
    dateOfBirth: string;
    gender: string;
    nationality: string;
    countryOfBirth: string;
    phoneNumber: string;
    email: string;
    snn?: string;
    addressType: string;
    address: string;
    addressExtra: string;
    city: string;
    subnational: string;
    country: string;
    postalCode: string;
    additionalField?: AdditionalFieldData;
  };
  account?: Partial<Account>[];
  accountsGrouped?: Map<string, Partial<Account>[]>;
};

export const mapFieldValuesIntoBeneficiaryForm = (
  personId: string,
  data: FieldValues,
  accountAdditionalFields: AdditionalField[],
  beneficiaryAdditionalFields: AdditionalField[]
) => {
  const formData: BeneficiaryForm = {
    personId,
    recipient: {
      firstName: data.firstName?.trim(),
      middleName: data.middleName?.trim(),
      lastName: data.lastName?.trim(),
      alias: data.alias?.trim() || "",
      dateOfBirth: data.dateOfBirth,
      phoneNumber: `${data.phoneCountryCode}${data.phoneNumber?.trim()}`,
      email: data.email ? data.email.trim() : null,
      addressType: "Ho",
      address: data.address1?.trim(),
      addressExtra: data.address2,
      city: data.city,
      subnational: `${data.subnational}`,
      country: data.country,
      countryOfBirth: data.country,
      nationality: data.country,
      postalCode: data.postalCode?.trim(),
      gender: "M",
    },
    account: [
      {
        destinationId: data.destination,
        accountTypeId: data.accountTypeId,
        accountNumber: data.bankAccount,
        additionalFieldInfo: accountAdditionalFields.map((field) => ({
          fieldBelongsTo: field.fieldBelongsTo,
          fieldName: field.fieldName,
          fieldValue: data[field.fieldName],
        })),
        branch: data.branch,
        ifscCode: data.ifscCode,
      },
    ],
  };

  formData.recipient.additionalField = {
    additionalFieldInfo: beneficiaryAdditionalFields.map((field) => ({
      fieldBelongsTo: "",
      fieldName: field.fieldName,
      fieldValue: data[field.fieldName],
    })),
  };

  return formData;
};

export type AdditionalFieldInfo = {
  id?: string;
  fieldName?: string;
  fieldValue?: string;
  fieldBelongsTo?: string;
};
