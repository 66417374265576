import styled from "styled-components";
import { theme } from "styled-tools";
import mediaQuery, { Screen } from "../../../Utilities/MediaQuery";

export const ContainerText = styled.div`
  display: flex;
  align-items: center;
  max-width: 280px;

  path {
    stroke: white!important;
  }
`;

export const VisaInstructionsContainer = styled.div`
  max-width: 466px;
`;

export const TitleVisa = styled.div`
  display: flex;

  h2 {
    margin-inline: auto;
  }
`;

export const ContainerVisa = styled.div`
  display: inline-block;

  img {
    width: 96%;
  }

  div {
    max-width: 170px;
    min-height: 42px;
  }

  .visa4 {
    margin-bottom: 7px;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 42px;
  }

  path {
    fill: ${theme("Red_08")};
  }
`;

export const ButtonCancel = styled.button`
  display: block;
  border: none;
  background: none;
  cursor: pointer;
`;

export const ButtonDelete = styled.button`
  background: ${theme("Background_01")};
  border: 1px solid ${theme("Primary_01")};
  border-radius: 4px;
  width: 130px;
  height: 60px;
  cursor: pointer;

  div {
    margin-inline: auto;
  }
`;

export const ButtonSubmit = styled.button`
  background: ${theme("Primary_01")};
  border: 1px solid ${theme("Primary_01")};
  border-radius: 4px;
  width: 130px;
  height: 60px;
  cursor: pointer;

  div {
    margin-inline: auto;
  }
`;

export const ContainerButtonForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${mediaQuery[Screen.desktop]} {
    justify-content: space-around;
  }
`;

export const ThanksContainer = styled.div`
  padding: 100px 0 200px;
`;