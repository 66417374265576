import styled from "styled-components";
import { ifProp, prop, theme } from "styled-tools";

import { Flex } from "../Flex";

export const BalanceContainer = styled.div<{ $active: boolean }>`
  background-color: ${(props) => props.$active ? theme("Background_12") : `rgba(${props.theme.RGBA_01}, 0.70)`};
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow:0px 4px 24px 0px rgba(${theme("RGBA_01")}, 0.15);
  padding: 16px;
  height: 230px;
  margin: 24px 8px;
  text-align: left;

  p {
    font-family: Poppins;
  }
`;

export const BalanceLink = styled(Flex)`
  height: 48px;
`;

export const AlertBackground = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.65);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const AlertContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  overflow: hidden;
  box-sizing: border-box;
  background: ${prop("theme.Gradient_06", "gray")};
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60%;
`;

export const Subtitle = styled.p`
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  margin: 0;
  color: ${theme("Content_UX_02")};
  margin-top: -5px;
`;

export const ContainerButton = styled.div`
  height: 105px;
  padding-block: 8px;
  border-radius: 10px;
  margin-top: 4px;
`;

export const IconLink = styled.button`
  border-radius: 50%;
  background: Grey_04;
  border: none;
  height: 40px;
  width: 40px;
  cursor: pointer;
`;

export const ContainerBalance = styled(Flex)`
  margin-top: 0px;
`;

export const Details = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  p {
    text-decoration: underline;
  }
`;

export const TextInfo = styled(Flex)`
  p {
    font-size: 10px;
  }
`;

export const BannerBankCardContainer = styled(BalanceContainer)`
  position: relative;
  background-color: ${theme('Background_Banner_Bank')};

  p {
    color: ${theme('Background_01')};
  }

  svg {
    path {
      stroke: ${theme('Background_01')};
    }
  }

  button {
    border-width: 1px !important;
  }
`;

export const BannerBankCardRoundedBtn = styled(ContainerButton)`
  p {
    line-height: 14px;
  }

  button:disabled,
  button:disabled + p {
    opacity: 0.5;
  }
`;

export const BannerBankSendModeyButton = styled.div`
  button > div {
    margin: 1px 0 0 -1px;
  }

  .popover-content {
    top: calc(100% - 25px);
    left: 22px;
  }
`;

export const BannerBankPayrollButton = styled.div`
  position: relative;

  .popover-content {
    background-color: ${theme("Background_01")};
  }

  .popover-content {
    top: calc(100% - 10px);
    right: 22px;
  }

  svg {
    circle {
      fill: ${theme("BackgroundGreen")};
    }
  }
`;

export const BannerBankIconCheck = styled.div`
  position: absolute;
  right: 12px;
  top: -5px;
`;

export const BannerBankCardContainerBalance = styled(ContainerBalance)`
  margin-top: -15px;
`;

export const BannerBankCardButton = styled.div`
  background: ${theme('Gradient_10')};
  color: ${theme('Background_Banner_Bank')};
  width: 55%;
  margin-bottom: 8px;
  margin-top: 12px;

  span {
    font-weight: 600;
    font-size: 12px;
  }
`;

export const BannerBankUnlinkedCard = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
  border-radius: 10px;
  background: 
    linear-gradient(
      transparent,
      rgba(${theme('RGBA_01')}, 1) 60%
    );
`;

export const BannerBankUnlinkedCardContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;
  .linked-account-unapproved {
    p {
      font-size: 10px;
    }
  }
`;

export const UnlinkedBannerBankCardInfoLocked = styled.div<{
  $width?: string;
  $margin?: string;
}>`
  width: ${ifProp("$width", prop("$width"), "100%")};
  margin: ${ifProp("$margin", prop("$margin"), "0")};
  height: 3px;
  background-color: ${theme('Background_01')};
  border-radius: 4px;
`;