import styled from "styled-components";
import { theme } from "styled-tools";

export const ReactWebCamContainer = styled.div`
  border: 3px solid ${theme("Primary_01")};
  box-sizing: border-box;
  border-radius: 20px;
  padding: 0;
  margin: 24px 0;
  height: auto;

  video,
  img {
    margin-bottom: -3.5px;
    border-radius: 17px;
  }
`;

export const IconContainer = styled.div`
  height: auto;
  margin-inline: auto;
  margin-top: 48px;

  div {
    width: auto;
  }

  svg:first-child {
    background: ${theme("Primary_01")};
    border-radius: 50%;
  }
`;

export const DisclosureFile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin: 12px 0;
  width: 100%;
`;