import { createClient } from "@services/API/GlobalPaymentsApiClient";
import { PaymentOrderWithSquareRepository } from "../domain/PaymentOrderRepository";
import { SquarePaymentOrder } from "../domain/Square";

const client = createClient();
const sendolaPayPrefixService = "Square";

export const SquareApiPaymentOrderRepository =
  (): PaymentOrderWithSquareRepository => {
    return {
      createPaymentLink,
    };
  };

const createPaymentLink = async (id: string, paymentMethodFee: number) => {
  const response = await client.post<SquarePaymentOrder>(
    `${sendolaPayPrefixService}/payment-link`,
    {
      id: id,
      paymentMethodFee: paymentMethodFee,
    }
  );

  return response.data;
};
