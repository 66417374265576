import { FlexContainer } from "@components/Flex/Flex.styled";
import Text from "@components/Text";
import { formatNumber } from "@utils/NumberUtils";
import { useTranslation } from "react-i18next";
import QuoterSummaryDropdown from "./QuoterSummaryDropdown.component";
import { Divider } from "./QuoteSummary.styled";

interface QuoteTotalsProps {
  transferFee: number;
  previousTransferFee?: number;
  paymentMethodFee: number;
  previousPaymentMethodFee?: number;
  paymentMethodDiscount?: number;
  total: number;
  previousTotal?: number;
  totalDiscounted?: number;
  isFeeDropdownOpen?: boolean;
}

export const QuoteTotals = ({
  transferFee,
  previousTransferFee = 0,
  paymentMethodFee,
  previousPaymentMethodFee = 0,
  paymentMethodDiscount = 0,
  total,
  previousTotal,
  totalDiscounted,
  isFeeDropdownOpen,
}: QuoteTotalsProps) => {
  const [tSendMoney] = useTranslation("sendMoney");
  const totalFee = transferFee + (paymentMethodFee >= 0 ? paymentMethodFee : 0);

  return (
    <FlexContainer direction="column" gap="8px">
      <QuoterSummaryDropdown
        anchorText={tSendMoney("TotalFees")}
        anchorAmountText={`
          $${formatNumber(totalFee || 0, {})} USD  
        `}
        isOpen={isFeeDropdownOpen}
      >
        <FlexContainer direction="column" gap="8px">
          <FlexContainer alignItems="baseline" justify="space-between">
            <Text align="left" size={0.5} lineHeight="18px" weight={500} color="grey" margin={0}>
              {tSendMoney("TransferFees")}:
            </Text>

            {previousTransferFee ? (
              <FlexContainer w="max-content" alignItems="center" gap="6px">
                <Text
                  align="right"
                  size={0.5}
                  lineHeight="18px"
                  weight={500}
                  color="solid_2"
                  textDecoration="line-through"
                  margin={0}
                >
                  $
                  {formatNumber(previousTransferFee || 0, {
                    minimumFractionDigits: 2,
                  })}{" "}
                  USD
                </Text>
                <Text align="right" size={0.5} lineHeight="18px" weight={500} color="neutral600" margin={0}>
                  $
                  {formatNumber(transferFee || 0, {
                    minimumFractionDigits: 2,
                  })}{" "}
                  USD
                </Text>
              </FlexContainer>
            ) : (
              <Text align="right" size={0.5} lineHeight="18px" weight={500} color="neutral600" margin={0}>
                $
                {formatNumber(transferFee || 0, {
                  minimumFractionDigits: 2,
                })}{" "}
                USD
              </Text>
            )}
          </FlexContainer>

          {paymentMethodFee >= 0 && (
            <FlexContainer alignItems="baseline" justify="space-between">
              <Text align="left" size={0.5} lineHeight="18px" weight={500} color="grey" margin={0}>
                {tSendMoney("PaymentMethodFee")}:
              </Text>

              {previousPaymentMethodFee ? (
                <FlexContainer w="max-content" alignItems="center" color="neutral600" gap="6px">
                  <Text
                    align="right"
                    size={0.5}
                    lineHeight="18px"
                    weight={500}
                    color="solid_2"
                    textDecoration="line-through"
                    margin={0}
                  >
                    $
                    {formatNumber(previousPaymentMethodFee || 0, {
                      minimumFractionDigits: 2,
                    })}{" "}
                    USD
                  </Text>
                  <Text align="right" size={0.5} lineHeight="18px" weight={500} color="neutral600" margin={0}>
                    $
                    {formatNumber(paymentMethodFee || 0, {
                      minimumFractionDigits: 2,
                    })}{" "}
                    USD
                  </Text>
                </FlexContainer>
              ) : (
                <Text align="right" size={0.5} lineHeight="18px" weight={500} color="neutral600" margin={0}>
                  $
                  {formatNumber(paymentMethodFee || 0, {
                    minimumFractionDigits: 2,
                  })}{" "}
                  USD
                </Text>
              )}
            </FlexContainer>
          )}
        </FlexContainer>
      </QuoterSummaryDropdown>

      {paymentMethodDiscount > 0 && (
        <FlexContainer direction="column" gap="8px">
          <FlexContainer alignItems="baseline" justify="space-between" p="0 27px 0 16px">
            <Text size={0.5} lineHeight="18px" weight={500} color="grey" margin={0} gradient>
              {tSendMoney("ZelleDiscount")}:
            </Text>
            <Text size={0.5} lineHeight="18px" weight={500} margin={0} gradient>
              - $
              {formatNumber(paymentMethodDiscount || 0, {
                minimumFractionDigits: 2,
              })}{" "}
              USD
            </Text>
          </FlexContainer>
        </FlexContainer>
      )}
      <FlexContainer p="2px 0 2px 0">
        <Divider />
      </FlexContainer>
      <FlexContainer justify="space-between">
        <Text size={0.5} lineHeight="18px" weight={700} color="grey" margin={0}>
          {tSendMoney("TotalPayment")}:
        </Text>
        {previousTotal ? (
          <FlexContainer w="auto" direction="column" alignItems="end">
            <FlexContainer w="max-content" alignItems="center" gap="6px">
              <Text
                size={0.5}
                lineHeight="18px"
                weight={500}
                color="neutral600"
                textDecoration="line-through"
                margin={0}
              >
                $
                {formatNumber(previousTotal || 0, {
                  minimumFractionDigits: 2,
                })}{" "}
              </Text>
              <Text size={0.5} lineHeight="18px" weight={500} gradient margin={0}>
                $
                {formatNumber(total || 0, {
                  minimumFractionDigits: 2,
                })}{" "}
                USD
              </Text>
            </FlexContainer>
            <Text tagName="span" size={0.5} weight={600} lineHeight="18px" margin={0}>
              {tSendMoney("Coupons.save", {
                amount: formatNumber(totalDiscounted || 0, {
                  minimumFractionDigits: 2,
                }),
              })}
            </Text>
          </FlexContainer>
        ) : (
          <Text size={0.5} lineHeight="18px" weight={500} color="magenta" margin={0}>
            $
            {formatNumber(total, {
              minimumFractionDigits: 2,
            })}{" "}
            USD
          </Text>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};
