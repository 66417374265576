import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";

const initialState = {
  AddressOption: null
};

export const AddressOptionSlice = createSlice({
  name: "addressOption",
  initialState,
  reducers: {
    updateAddressOption: (state, action) => {
      state.AddressOption = action.payload;
    },
  },
});

export const { updateAddressOption } = AddressOptionSlice.actions;
export const AddressOptionSelector = (state: State) => state.addressOption;

export default AddressOptionSlice.reducer;
