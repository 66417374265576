import styled from "styled-components";
import { theme } from "styled-tools";

export const BannerCard = styled.div`
  background-color: ${theme("Primary_01_900")};
  border-radius: 8.2px;
  box-shadow: 0px 0px 12.3px 0px ${theme("Black")}40;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 4px 13px 11px 13px;
`;
