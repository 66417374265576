import styled from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { theme } from "styled-tools";

export const CardFoundContainer = styled.div`
  border-radius: 20px 0px 20px 20px;
  background: ${theme("Background_01")};
  box-shadow: 0px 4px 20px 0px rgba(${theme("RGBA_01")}, 0.15);
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 36px;
  position: relative;
  margin: 24px 0;
  max-width: 543px;
  

  ${mediaQuery[Screen.mobile]} {
    max-width: 100%;
    border-radius: 20px;
  }

  button {
    max-width: 144px;
    margin: 0;
  }

  ${mediaQuery[Screen.mobile]} {
    h2 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
    }

    button > div > span {
      font-size: 16px;
    }
  }
`;

export const IconCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.active div {

      background: linear-gradient(
        180deg,
        ${theme("Primary_03")} 0%,
        ${theme("Primary_01")} 100%
      );
  }

  &.disabled div {
    background-color: ${theme("Content_State_UX_Hover")};
  }

  div {
    border-radius: 0px 0px 0px 20px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px;

    ${mediaQuery[Screen.mobile]} {
      border-radius: 20px;
      position: relative;
      height: 24px;
      width: 24px;

      svg {
        height: 24px;
        width: 24px;
      }
    }
  }

  ${mediaQuery[Screen.mobile]} {
    flex-direction: row;
  }
`;
