import styled, { css } from "styled-components";
import { ifProp, prop, theme } from "styled-tools";

export const CarouselPaginatedContainer = styled.div<{ items: number }>`
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;

  > div {
    display: flex;
    gap: 4px;
    width: calc(${prop("items", "1")} * 100%);

    > div {
      width: calc(100% / ${prop("items", "1")});
    }
  }
`;

export const CarouselIndicator = styled.div<{ active?: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  ${ifProp(
    "active",
    css`
      background-color: ${theme("Primary_01_900")};
    `,
    css`
      background-color: ${theme("Background_15")};
    `
  )}
`;
