import styled from "styled-components";
import { theme } from "styled-tools";

export const Radio = styled.input`
  display: -webkit-inline-box;
  appearance: none;
  margin: 0;
  margin-top: 2px;
  width: 20px;
  height: 20px;
  border: 1px solid ${theme("Background_04")};
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
  position: relative;

  ::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 50%;
    margin-left: -5px;
    top: 50%;
    margin-top: -5px;
  }
  :checked {
    background-color: ${theme("Primary_10")};
    ::after {
      background-color: ${(props) => props.theme.Background_04};
    }
    :hover {
      border: 1px solid ${(props) => props.theme.Background_04};
      ::after {
        background-color: ${(props) => props.theme.Background_04};
      }
    }
  }

  :hover {
    outline: 2px solid ${theme("RADIO_SHADOW")};
    box-shadow: 0 0 0 4px ${theme("Background_01")};

    ::after {
      display: none;
    }
  }

  :checked,
  :focus {
    border-color: ${theme("Primary_500")};
    outline: 2px solid ${theme("RADIO_SHADOW")};
    box-shadow: inset 0 0 0 5px ${theme("Background_01")};

    ::after {
      display: none;
    }

    :hover {
      outline: 2px solid ${theme("RADIO_SHADOW")};
    }
  }
`;

export const Label = styled.label`
  position: relative;
  min-height: 34px;
  padding-left: 6px;
  font-weight: normal;
  cursor: pointer;
`;

export const Container = styled.div`
  margin: auto;
  position: relative;
  display: block;
`;
