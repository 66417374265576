import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

// Own states
import { State } from "../../../Redux/Store";

// Own components
import Layout from "../../../Components/Layout";
import { Column, Container, Row } from "../../../Components/Grid";
import Title from "../../../Components/Title";
import Text from "../../../Components/Text";
import DocumentLinksList from "../../../Components/LinksList/DocumentLinksList.component";

// Own models
import { JustifyContent } from "../../../Models/Column";

// Own styles
import { StepsContainer } from "./Disclosures.styled";

const Disclosures = () => {
  const theme = useSelector((state: State) => state.theme);
  const [t] = useTranslation("global");

  const LegalLinks = [
    {
      name: t("Disclosures.links.PrivacyPolicy"),
      items: [
        {
          name: "Sendola",
          url: "/SendolaPrivacyPolicy",
        },
      ],
    },
    {
      name: t("Disclosures.links.TermsAndConditions"),
      items: [
        {
          name: "Sendola",
          url: "/SendolaTermsAndConditions",
        },
      ],
    },
    {
      name: t("Disclosures.links.EletronicCommunications"),
      url: "/ElectronicCommunicationsDisclosures",
    },
    {
      name: t("Disclosures.links.USA"),
      url: "/USAPatriotAct",
    },
    {
      name: t("Disclosures.links.FeeSchedule"),
      url: "/FeeShedule",
    },
    {
      name: t("Disclosures.links.TransactionLimits"),
      url: "/TransactionLimits",
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Layout path="/" textLink={t("buttons.back")}>
        <Container as={StepsContainer}>
          <Row>
            <Column span={12}>
              <Row>
                <Column span={12} xs={12} md={12}>
                  <Title
                    size={5}
                    text={t("Disclosures.title")}
                    color="black"
                    align="left"
                    tagName="h1"
                  />
                </Column>
              </Row>
              <Row>
                <Column span={12} xs={12} md={12}>
                  <Text size="default" color="grey" align="left">
                    {t("Disclosures.subtitle")}
                  </Text>
                </Column>
              </Row>
              <Row>
                <Column span={12} xs={12} md={12}>
                  <DocumentLinksList data={LegalLinks} />
                </Column>
              </Row>
              <Row>
                <Column
                  span={12}
                  xs={12}
                  md={12}
                  justifyContent={JustifyContent.center}
                >
                  <Text size={1} color="grey" align="center">
                    {t("Disclosures.textFooter")}
                  </Text>
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default Disclosures;
