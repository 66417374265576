import { createAsyncThunk } from "@reduxjs/toolkit";
import { PersonAccountTransactionRequest } from "../../Models/PersonAccountRequest";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";

export const handleAccountTransactions = createAsyncThunk(
  "GetTransactions",
  async (payload:PersonAccountTransactionRequest, thunkAPI) => {
    const headers = {
      "personId": payload.personId,
      "accountId": payload.accountId
    };
    try {
      const response = await BaseConfigApi.get(`/transactions?startTime=${payload.startTime}&endTime=${payload.endTime}&limit=${payload.limit}&offset=${payload.offset}`, { headers: headers });
      return response.data?.data;
    } catch(error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// /accounts/transactions/usi?limit=${payload.limit}&offset=${payload.offset}
