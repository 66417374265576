import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { CarouselIndicator, CarouselPaginatedContainer } from "./CarouselPaginated.styled";
import { FlexContainer } from "@components/Flex/Flex.styled";

interface Props extends PropsWithChildren {
  items: number;
}

export const CarouselPaginated = ({ items, children }: Props) => {
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const invertWheel = (evt: WheelEvent) => {
    if (sliderRef.current && evt.deltaX === 0 && evt.deltaY !== 0) {
      evt.preventDefault();
      sliderRef.current.scrollLeft += sliderRef.current.children[0].children[0].clientWidth * Math.sign(evt.deltaY);
    }
  };

  const goToElement = (index: number) => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft = sliderRef.current.children[0].children[0].clientWidth * index;
    }
  };

  function checkVisibility() {
    const container = document.getElementById("slider");
    if (!container) return;

    const children = Array.from(container.children);

    children.forEach((child, index) => {
      const rect = child.getBoundingClientRect();

      const isCompletelyVisible =
        rect.left >= 0 && rect.right <= (window.innerWidth || document.documentElement.clientWidth);

      if (isCompletelyVisible) {
        setActiveSlide(index);
      }
    });
  }

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.addEventListener("wheel", invertWheel);
      sliderRef.current.addEventListener("scroll", checkVisibility);

      return () => {
        sliderRef.current?.removeEventListener("wheel", invertWheel);
        sliderRef.current?.removeEventListener("scroll", checkVisibility);
      };
    }
  }, [sliderRef.current]);

  return (
    <>
      <CarouselPaginatedContainer ref={sliderRef} items={items}>
        <div id="slider">{children}</div>
      </CarouselPaginatedContainer>
      <FlexContainer justify="center" m="8px 0 0 0" gap="5px">
        {Array.from({ length: items }).map((_, index) => (
          <CarouselIndicator key={index} active={activeSlide === index} onClick={() => goToElement(index)} />
        ))}
      </FlexContainer>
    </>
  );
};
