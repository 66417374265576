import { useMemo, useState } from "react";
import { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

// Own redux
import { AppDispatch, State } from "../../../Redux/Store";
import { ByUserSliceSelector } from "../../../Redux/ByUser/ByUser.slice";
import { VisaPhotoVeriffAction } from "../../../Redux/VisaPhoto/VisaPhoto.actions";
import { setSteps } from "../../../Redux/Onboarding/Onboarding.slice";
import { PersonalDataSliceSelector } from "../../../Redux/PersonalData/PersonalData.slice";

// Own models
import { EntriesVisaVeriffTypes, FormValuesFile } from "../../../Models/Visa";
import { Step } from "../../../Models/Onboarding";

// Own utils
import { dataType64toFile } from "../../../Utilities/Base64ToFileImage";

// Own constants
import { VISA_DOCUMENT_FULFILLED, VISA_DOCUMENT_REJECTED } from "../../../Constants/Visa";

// Own components
import ReactWebCam from "../../../Components/ReactWebCam"
import Layout from "../../../Components/Layout";
import { Column, Container, Row } from "../../../Components/Grid";
import Title from "../../../Components/Title";
import Icon from "../../../Components/Icon";
import Text from "../../../Components/Text";
import LoaderIcon from "../../../Components/LoaderIcon/LoaderIcon.component";

// Own styles
import {
  ButtonCancel,
  ButtonDelete,
  ButtonSubmit,
  ContainerButtonForm,
  VisaInstructionsContainer,
  TitleVisa
} from "./VisaVerification.styled";

const VisaPhoto = () => {
  const theme = useSelector((state: State) => state.theme);
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const PersonalData = useSelector(ByUserSliceSelector);
  const PersonalDataForm = useSelector(PersonalDataSliceSelector);
  const personId = useMemo(() => 
    PersonalData.data?.personId || PersonalDataForm.data?.personId
  ,[PersonalData]);

  const [activeDelete, setActiveDelete] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({ mode: "onChange" });

  const onSubmit: SubmitHandler<FormValuesFile> = async(data) => {
    setIsLoading(true);
    const fileConvert = dataType64toFile(data.file);

    const output: EntriesVisaVeriffTypes = {
      idType: "visa",
      file: fileConvert
    };

    const fd = new FormData();
    for (const key of Object.keys(output)) {
      fd.append(key, output[key as keyof EntriesVisaVeriffTypes]);
    }

    const request = {
      personId: personId,
      data: fd
    };

    const response = await dispatch(VisaPhotoVeriffAction(request));

    if (response?.type === VISA_DOCUMENT_FULFILLED) {
      dispatch(setSteps({
        lastStep: Step.visa,
        nextStep: null
      }))
      navigate("/kyc-submit-kyc");
    }

    if (response?.type === VISA_DOCUMENT_REJECTED) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout show={isLoading}>
        <LoaderIcon
          title={t("loaderText.title")}
          description={t("loaderText.description")}
        />
      </Layout>
      <Layout show={!isLoading}>
        <Container as={VisaInstructionsContainer}>
          <Row>
            <Column span={12} mt={4} as={TitleVisa}>
              <ButtonCancel onClick={() => navigate("/kyc-veriff-visa")}>
                <Icon
                  icon="cancel"
                  size="large"
                  color="black"
                />
              </ButtonCancel>
              <Title
                tagName="h2"
                color="black"
                size={3}
                text={t("VisaVerification.photo")}
                align="center"
              />
            </Column>
            <Column span={12}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Column span={12} my={2}>
                  <Controller
                    name="file"
                    control={control}
                    defaultValue={null}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange } }) => (
                      <ReactWebCam
                        textLabel=""
                        onChange={onChange}
                        showBtnDelete={false}
                        deleteImg={activeDelete}
                      />
                    )}
                  />
                </Column>
                { isError && (
                  <Column span={12} mb={2}>
                    <Text size={0.5} align="center" weight={600} color="error">
                      {t("404.subtitle")}
                    </Text>
                  </Column>
                )}
                {isValid &&
                  <>
                    <Column span={12}>
                      <Text size={1} align="left">
                        {t("VisaVerification.visaClearly")}
                      </Text>
                    </Column>
                    <Column span={12} mb={5} as={ContainerButtonForm}>
                      <ButtonDelete onClick={() => setActiveDelete(!activeDelete)}>
                        <Icon
                          icon="cancel"
                          color="solid_light"
                          size="large"
                        />
                      </ButtonDelete>
                      <ButtonSubmit>
                        <Icon
                          icon="check"
                          color="white"
                          size="large"
                        />
                      </ButtonSubmit>
                    </Column>
                  </>
                }
              </form>
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  )
}

export default VisaPhoto