import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_BALANCE } from "../../Constants/Balance";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { BalancePayload } from "../../Models/BalanceCard";
import { defaultErrorMessage } from "../../Constants/Requests";
import { AxiosError } from "axios";

export const findAccountBalance = createAsyncThunk(
  GET_BALANCE,
  async (payload:BalancePayload, thunkAPI) => {
    const headers = {
      "personId": payload.personId,
    };
    try {
      const response = await BaseConfigApi.get(`/accounts/balance/${payload.accountId}`, { headers: headers });
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
