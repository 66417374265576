import { EditInformationProps } from "../../Models/EditInformationProps";

import Text from "../Text";
import Icon from "../Icon";

import {
  EditInfoContainer,
  ContainerTitle
} from "./EditInformation.styled";

const EditInformation = (props: EditInformationProps) => {
  const { title, onClickIcon, text, isPassword } = props;

  return (
    <EditInfoContainer>
      <ContainerTitle>
        <Text size={1} weight={400}>
          {title}
        </Text>
        {onClickIcon &&
          <Icon
            icon="edit"
            color="black"
            fillIcon={false}
            size="medium"
            onClick={onClickIcon}
          />
        }
      </ContainerTitle>
      {text &&
        <Text size={isPassword ? 3: 1} color={isPassword ? "black": "neutral_2"}>
          {text}
        </Text>
      }
    </EditInfoContainer>
  )
}

export default EditInformation;