import { FlexContainer } from "@components/Flex/Flex.styled";
import Modal from "../Modal.component";
import { BottomModalWrapper } from "../BottomModal/BottomModal.styled";
import { useTranslation } from "react-i18next";
import { ModalProps } from "@models/ModalInterface";
import Text from "@components/Text";
import { PaymentMethodSelect } from "@components/Payments/PaymentMethodSelect/PaymentMethodSelect.component";
import Button from "@components/Payments/Button";
import { useState } from "react";
import { PaymentMethod } from "@core/Payments/Common/domain/PaymentMethods";

export const PaymentMethodSelectModal = (props: ModalProps) => {
  const [t] = useTranslation("global");
  const [tSendMoney] = useTranslation("sendMoney");
  const [paymentMethodSelected, setPaymentMethodSelected] = useState<PaymentMethod | null>(null);

  return (
    <Modal {...props} hiddenCloseIcon={false} as={BottomModalWrapper}>
      <FlexContainer justify="center" m="0 0 16px 0">
        <Text size={1} weight={600} lineHeight="24px" margin={0}>
          {props.title || tSendMoney("PaymentMethodSelection.selectPaymentMethod")}
        </Text>
      </FlexContainer>
      <FlexContainer justify="center">
        <PaymentMethodSelect onSelect={setPaymentMethodSelected} />
      </FlexContainer>
      <FlexContainer justify="center" m="32px 0 0 0">
        <Button
          variant="primary"
          padding="20px 90px"
          sizeButton="fit"
          withShadow
          onClick={() => {
            props.handleConfirm && props.handleConfirm(paymentMethodSelected);
            props.handleClose && props.handleClose();
          }}
        >
          <Text size={1} weight={600} lineHeight="16px" color="white" margin={0}>
            {t("global.select")}
          </Text>
        </Button>
      </FlexContainer>
    </Modal>
  );
};
