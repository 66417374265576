import { Link } from "react-router-dom";

// Own components
import Icon from "../Icon";
import DropDownItem from "./DropDownItem.component";

// Own styles
import { List, Item } from "./LinksList.styled";

// Own models
import {dataProps, EnumDataItem} from "../../Models/LinksList";

const LinksList = (props:dataProps) => {
  return(
    <List>
      {props.data.map((elem:EnumDataItem, index) => {
        if (elem.url) {
          return(
            <Link to={elem.url} key={index}>
              { elem.icon && <Icon icon={elem.icon} color="black" size="medium" /> }
              <Item>
                { elem.name }
                <Icon icon="chevronRight" color="black" size="medium" />
              </Item>
            </Link>
          )
        } else {
          return (
            <DropDownItem
              id={index}
              key={index}
              icon={elem.icon}
              name={elem.name}
              items={elem.items}
            />
          )
        }
      })}
    </List>
  )
}

export default LinksList;