
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Own Store
import { AppDispatch, State } from "../../../Redux/Store";
import { 
  resetBankTransfersState, setCurrentPlaidAccount 
} from "../../../Redux/FundsAccount/FundsAccount.slice";
import { resetAccount } from "../../../Redux/Accounts/Accounts.slice";

// Own Models
import { CurrentAccount } from "../../../Models/Account";
import { PlaidAccounts } from "../../../Models/Funds";

// Own Components
import Layout from "../../../Components/Layout";
import { Column, Container, Row } from "../../../Components/Grid";
import TransactionConfirmation from "../../../Components/TransactionConfirmation";

import CurrencyService from "../../../Services/CurrencyService";

type CurrentAccountProps = {
  amount: string;
  data: CurrentAccount;
}

const FundAccountConfirmed = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const theme = useSelector((state: State) => state.theme);
  const currentAccount = useSelector(
    (state: State) => state.Accounts
  ).accountSelected as CurrentAccountProps;
  const currentLinkAccount = useSelector(
    (state: State) => state.FundsAccount
  ).plaidAccountSelected as PlaidAccounts;

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Container>
          <Row>
            <Column span={12}>
              <TransactionConfirmation
                from={currentLinkAccount?.bankName || ''}
                accountFrom={`***********${currentLinkAccount?.accountLastFour || ''}`}
                to={currentAccount.data?.sponsorBank || ''}
                accountTo={currentAccount.data?.accountNumber || ''}
                title={t("TransactionConfirmation.title")}
                amount={`$${CurrencyService.getFloatAmount(currentAccount?.amount.toString() || '')}`}
                actionButton={() => {
                  navigate('/manage-card-account');
                  dispatch(setCurrentPlaidAccount(null));
                  dispatch(resetAccount());
                  dispatch(resetBankTransfersState());
                }}
              />
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  )
};

export default FundAccountConfirmed;