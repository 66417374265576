import { ButtonProps } from "../../Models/buttonInterface";
import Icon from "../Icon";
import { ButtonDefault, ButtonLoader, TextContainer } from "./Button.styled";

const Button = (props: ButtonProps) => {
  const {
    text,
    variant,
    sizeText,
    sizeButton,
    sizeIcon,
    iconButton,
    iconButtonLeft,
    fillIcon,
    colorIcon,
    disabled,
    loading = false,
    border,
    bgGradient,
    textColor,
    fillTransparent,
    fontWeight,
    children,
  } = props;

  return (
    <ButtonDefault
      $type={variant}
      $size={sizeText}
      $sizeButton={sizeButton}
      $border={border}
      $bgGradient={bgGradient}
      $textColor={textColor}
      $fontWeight={fontWeight}
      {...props}
      className="Button"
      disabled={disabled}
    >
      {children}
      {loading ? (
        <ButtonLoader />
      ) : (
        <TextContainer $iconButton={iconButton} $iconButtonLeft={iconButtonLeft}>
          {iconButtonLeft && (
            <Icon
              icon={iconButtonLeft}
              color={colorIcon}
              fillIcon={fillIcon}
              size={sizeIcon}
              fillTransparent={fillTransparent}
            />
          )}
          <span>{text}</span>
          {iconButton && <Icon icon={iconButton} color={colorIcon} fillIcon={fillIcon} size={sizeIcon} />}
        </TextContainer>
      )}
    </ButtonDefault>
  );
};

export default Button;
