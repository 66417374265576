import { propsTooltip } from "../../Models/TooltipProps";

import Icon from "../Icon";

import {
  TooltipContainer,
  TooltipIcon,
  TooltipBoxText,
  TooltipBox,
} from "./Tooltip.styled";

const Tooltip = (props: propsTooltip) => {
  const { colorIcon, sizeIcon, textInfo, hasShadow, boxInfo } = props;

  return (
    <TooltipContainer $hasShadow={hasShadow} >
      <TooltipIcon>
        <Icon
          icon="circleInfo"
          color={colorIcon}
          fillIcon={false}
          size={sizeIcon}
        />
      </TooltipIcon>
      {textInfo &&
        <TooltipBoxText>
          <span
            dangerouslySetInnerHTML={{
              __html: textInfo || "",
            }}
          />
        </TooltipBoxText>
      }
      {boxInfo &&
        <TooltipBox>
          {boxInfo}
        </TooltipBox>
      }
    </TooltipContainer>
  );
};

export default Tooltip;