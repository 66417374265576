import styled, { css } from "styled-components";
import { ifProp, prop, switchProp, theme } from "styled-tools";

import { iconColors, IconsFontColors, IconsShapeBorder } from "../../Models/IconInterface";

const IconShape = styled.div<{
  $height?: number;
  $width?: number;
  color?: IconsFontColors;
  border: IconsShapeBorder;
  gradient?: boolean;
}>`
  ${ifProp(
    "color",
    css`
      background: ${switchProp("color", iconColors)};
    `,
    css`
      background: ${theme("Primary_01")};
    `
  )};

  ${ifProp(
    "gradient",
    css`
      background: linear-gradient(180deg, ${theme("Primary_23")} 0%, ${theme("Primary_22")} 100%);
    `
  )}
  border-radius: ${ifProp({ border: "rounded" }, "50%", "20px")};
  height: ${ifProp("$height", prop("$height"), "56")}px;
  position: relative;
  width: ${ifProp("$width", prop("$width"), "56")}px;

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

type StyledParams = Parameters<typeof IconShape>;
type StyledProps = StyledParams[0];

export default function IconShapeStyled(props: StyledProps) {
  return <IconShape {...props} />;
}
