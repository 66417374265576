import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { ASSIGNMENT_USER_FEATURE } from "../../Constants/user";
import { requestAssignmentFeature } from "../../Models/UserFeatures";
import { defaultErrorMessage } from "../../Constants/Requests";

/**
 * Method to Assignment user feature
 */
export const AssignmentUserFeature = createAsyncThunk(
  ASSIGNMENT_USER_FEATURE,
  async (payload: requestAssignmentFeature, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post(`/userfeatures/assignment`, payload);
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);