import { ReactNode } from 'react';
import { 
  PayrollCardStyled,
  PayrollCardTitleHeader
} from './PayrollCard.styled';

import Text from '../../../../Components/Text';
import Button from '../../../../Components/Button';

type Props = {
  headerTitle: string;
  title: string;
  description: string;
  btnText: string;
  btnHandleClick?: () => void;
  image?: ReactNode;
}

export const PayrollCard = ({
  title,
  description,
  headerTitle,
  image,
  btnText,
  btnHandleClick
}: Props) => {
  return (
    <PayrollCardStyled>
      <PayrollCardTitleHeader>
        {headerTitle}
      </PayrollCardTitleHeader>
      <Text
        size={2}
        align="center"
        weight="600"
        margin="12px 15px"
      >
        {title}
      </Text>
      {image || null }
      <Text
        color="grey"
        size={0.5}
        align="center"
        weight="400"
        margin="12px 15px"
      >
        {description}
      </Text>
      <Button
        onClick={btnHandleClick}
        variant="primary"
        sizeButton="xlarge"
        sizeText="large"
        text={btnText}
      />
    </PayrollCardStyled>
  )
}