import styled from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { theme } from "styled-tools";

export const Container = styled.div`
  margin-block: 60px;
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px auto;
  background: ${theme("Primary_01")};
  border-radius: 50%;
  width: fit-content;
  padding: 10px;
`

export const ContainerButton = styled.div`
  margin: 64px 0;

  button {
    width: 100%;

    ${mediaQuery[Screen.desktop]} {
      width: 80%;
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  min-width: 100%;

  ${mediaQuery[Screen.desktop]} {
    min-width: 400px;
    width: 400px;
    margin-inline: auto;
  }
`

export const Amount = styled.div`
  margin-block: 15px;
`

export const Message = styled.div`
  margin-block: 40px;

  b {
    color: ${theme("Primary_01")};
  }
`