import { createSlice } from "@reduxjs/toolkit";
import { 
  GetDirectDeposit, 
  SetDirectDeposit, 
  UpdateDirectDepositFeature 
} from "./CheckDirectDeposit.actions";
import { State } from "../Store";
import { DirectDepositState } from "../../Models/DirectDeposit";

const initialState: DirectDepositState = {
  loading: false,
  error: null,
  data: null
}

export const GetDirectDepositSlice = createSlice({
  name: "GetDirectDeposit",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetDirectDeposit.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetDirectDeposit.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(GetDirectDeposit.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      })
      .addCase(SetDirectDeposit.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SetDirectDeposit.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(SetDirectDeposit.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      })
      .addCase(UpdateDirectDepositFeature.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdateDirectDepositFeature.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(UpdateDirectDepositFeature.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export default GetDirectDepositSlice.reducer;

export const GetDirectDepositSelector = (state: State) => state.GetDirectDeposit;
