/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useTranslation } from "react-i18next";
import { BeneficiaryModalProps } from "../../../Models/ModalInterface";
import Modal from "../Modal.component";
import { BottomModalWrapper } from "../BottomModal/BottomModal.styled";
import { FlexContainer } from "../../Flex/Flex.styled";
import Text from "../../Text";
import { RecipientExchangeRates } from "@core/Payments/Common/domain/ExchangeRates";
import { useSelector } from "react-redux";
import { BeneficiaryAccount } from "@components/Payments/Beneficiary";
import { useBankAccount } from "@hooks/Payments/useBankAccount";
import { useEffect, useState } from "react";
import { selectorLanguage } from "@redux/Translate";
import { AccountTypes } from "@core/Payments/Common/domain/Destination";
import { FormProvider, useForm } from "react-hook-form";
import { allowedCountries } from "@core/Payments/Common/domain/Countries";
import Loader from "../../Loader";
import { useBeneficiary } from "@hooks/Payments/useBeneficiary";
import { isApiRequestError } from "@core/Common/domain/ApiRequestError";

export const AddBeneficiaryAccountModal = (props: BeneficiaryModalProps) => {
  const form = useForm({ mode: "all" });
  const { watch, getValues, handleSubmit } = form;

  const { language } = useSelector(selectorLanguage);
  const {
    additionalFields,
    bankList,
    isLoading: bankAccountLoading,
    subdivisionList,
    setAdditionalFields,
    setBankList,
    setStatus: setBankAccountStatus,
    getSubdivisionOptions,
  } = useBankAccount();
  const { isLoading: beneficiaryIsLoading, addAccount } = useBeneficiary();
  const [tSendMoney] = useTranslation("sendMoney");
  const [AccountType, setAccountTypeList] = useState<AccountTypes[]>([]);

  const getAccountTypes = () => {
    const destinationSelected = bankList.find((dest) => dest.id === getValues("destination"));
    const AccountTypeList: AccountTypes[] = [];
    if (destinationSelected) {
      destinationSelected?.destinationExpressions?.map((item) => {
        AccountTypeList.push({
          label: language === "es" ? item.labelSP : item.labelEN,
          value: item.accountType,
          key: item.accountType,
        });
      });
    }
    setAccountTypeList(AccountTypeList);
  };

  const handleSaveAccount = handleSubmit(async (data) => {
    if (props.recipient?.beneficiaryId) {
      const destinationSelected = bankList.find((bank) => bank.id === data.destination);
      if (destinationSelected) {
        const accounts = {
          account: {
            accountNumber: data.bankAccount,
            accountTypeId: data.accountTypeId,
            destinationId: destinationSelected.id,
            country: data.country,
            bankName: destinationSelected.destination,
            additionalFieldInfo: additionalFields
              .filter((field) => field.fieldBelongsTo !== "Transaction")
              .map((field) => ({
                fieldBelongsTo: field.fieldBelongsTo,
                fieldName: field.fieldName,
                fieldValue: data[field.fieldName],
              })),
            branch: data.branch,
            ifscCode: data.ifscCode,
          },
          beneficiaryId: props.recipient.beneficiaryId,
        };

        if (data.beneIfscCode) {
          accounts.account.ifscCode = data.beneIfscCode;
        }

        const response = await addAccount(accounts);

        if (response && !isApiRequestError(response) && props.handleConfirm) {
          await props.handleConfirm({
            ...response.account,
            destinationId: destinationSelected?.id,
            additionalFieldInfo: additionalFields.map((field) => ({
              fieldBelongsTo: field.fieldBelongsTo,
              fieldName: field.fieldName,
              fieldValue: data[field.fieldName],
            })),
            country: data.country,
            rail: destinationSelected?.rail,
            bankName: data.destination,
          });
        }
      }
    }
  });

  useEffect(() => {
    if (props.country) {
      getSubdivisionOptions(props.country, language);
    }
  }, [props.country, props.recipient, language]);

  useEffect(() => {
    const subscription = watch(async (_value, { name, type }) => {
      if (name === "destination" && type === "change") {
        getAccountTypes();
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, bankList, allowedCountries]);

  useEffect(() => {
    getAccountTypes();
  }, [bankList, language]);

  return (
    <Modal {...props} hiddenCloseIcon={false} as={BottomModalWrapper}>
      {bankAccountLoading && <Loader />}
      <FlexContainer justify="center" m="0">
        <Text size={1} weight={500} margin={0}>
          {props.title}
        </Text>
      </FlexContainer>
      <FlexContainer justify="start" gap="6px" m="0 0 12px 0">
        <FlexContainer w="fit-content">
          <img
            width={24}
            src={RecipientExchangeRates.find((exchange) => exchange.countryCode === props.recipient?.country)?.icon}
          />
        </FlexContainer>
        <FlexContainer direction="column" flex="1 1 auto">
          <Text align="left" size={1} weight={500} lineHeight="24px" margin={0}>
            {props.recipient?.fullName}
          </Text>
          <Text align="left" size={0.5} weight={400} lineHeight="21px" color="grey" margin={0}>
            {props.recipient?.alias}
          </Text>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer m="0 0 24px 0">
        <Text size={0.5} weight={600} lineHeight="21px" margin={0}>
          {tSendMoney("AddRecipientAccount")}
        </Text>
      </FlexContainer>
      <FlexContainer direction="column" gap="8px">
        <FormProvider {...form}>
          <form onSubmit={handleSaveAccount} style={{ width: "100%" }}>
            <FlexContainer justify="center">
              <BeneficiaryAccount
                country={props.country || props.recipient!.country}
                countrySubdivisions={subdivisionList}
                AccountTypeList={AccountType}
                showOnNextButton
                showDestinationSelector
                loading={beneficiaryIsLoading}
                onAdditionalFields={(additionalFields) => {
                  setAdditionalFields(additionalFields);
                }}
                onBankList={(bankList) => bankList && setBankList(bankList)}
                onNext={() => {
                  handleSaveAccount();
                }}
                onLoading={(loading) => {
                  setBankAccountStatus(loading ? "loading" : "idle");
                }}
              />
            </FlexContainer>
          </form>
        </FormProvider>
      </FlexContainer>
    </Modal>
  );
};
