import { createSlice } from "@reduxjs/toolkit";
import { getKCYStatusAction } from "./GetKYCStatus.actions";
import { State } from "../Store";
import { KYCState } from "../../Models/KYC";

const initialState: KYCState = {
  loading: false,
  error: null,
  data: null
};

export const getKCYStatuseSlice = createSlice({
  name: "getKYCStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getKCYStatusAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getKCYStatusAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getKCYStatusAction.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export const getKycStatusSelector = (state: State) => state.getKYCStatus;
