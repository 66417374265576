import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";
import { SubmitKYCAction } from "./SubmitKYC.actions";
import { SubmitKYCState } from "../../Models/SubmitKYC";
import { SUBMIT_KYC } from "../../Constants/KYCStatus";

const initialState: SubmitKYCState = {
  loading: false,
  error: null,
  data: null
};

export const SubmitKYCSlice = createSlice({
  name: SUBMIT_KYC,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SubmitKYCAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SubmitKYCAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      .addCase(SubmitKYCAction.rejected, (state, action: any) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload.response;
      });
  },
});

export const submitKycSelector = (state: State) => state.submitKYC;
