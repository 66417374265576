import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { ifProp, theme } from "styled-tools";
import { Flex } from "../Flex";

export const BarContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: 0 -10px 10px #00000010;
  width: 100%;
  height: 64px;
  z-index: 50;
`;

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 556px;
`;

export const GradientCenterButton = styled(Flex)`
  background: ${theme("Gradient_16")};
  width: 48px;
  height: 48px;
  margin: 0 4px;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > div {
    transition: transform 250ms;
  }

  &:hover > div {
    transform: rotate(-180deg);
    cursor: pointer;
  }
`;

export const NavItem = styled(Link)<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  width: 100%;
  padding: 0 4px;
  height: 100%;
  user-select: none;
  cursor: pointer;

  & > div {
    cursor: pointer;

    ${ifProp(
      "active",
      css`
        border-radius: 4px;
        padding: 8px 4px;
        width: auto;
        background-color: ${theme("BackgroundPurple6")};
      `
    )}

    * {
      ${ifProp(
        "active",
        css`
          color: ${theme("Primary_01_700")};
        `
      )}
    }
  }

  &:hover {
    background-color: #eee;
  }
`;
