// Own models
import { ServicesCardProps } from "../../Models/BannerCard";

import { JustifyContent } from "../../Models/Column";

// Own styles
import {
  BannerServicesContainer,
  Banner,
  TitleCard,
  TextCard,
  ContainerTitle,
  Btn,
  Check,
} from "./ServicesCard.styled";

// Own components
import { Column, Row } from "../Grid";
import Icon from "../Icon";

const ServicesCard = (props: ServicesCardProps) => {
  const {
    bgColor,
    title,
    message,
    icon,
    imageTitle,
    onClickBtn,
    widthImageTitle,
    heightImageTitle,
    imgSubtitle,
    notification,
    buttonText,
    buttonIcon,
    check
  } = props;

  return (
    <Banner>
      {check &&
        <Check>
          <Icon
            icon="check"
            color="white"
            fillIcon={false}
            size="small"
          />
        </Check>
      }
      <div>
        <BannerServicesContainer $bgColor={bgColor}>
          <Row>
            <Column span={10} justifyContent={JustifyContent.flexStart} as={ContainerTitle}>
              {imageTitle ? (
                <img src={imageTitle} width={widthImageTitle ? widthImageTitle : 80} height={heightImageTitle ? heightImageTitle : 40}/>
              ) : (
                <Icon
                  icon={icon}
                  color="white"
                  fillIcon={false}
                  size="large2"
                />
              )}
              {notification &&
                <div>
                  {notification}
                </div>
              }
            </Column>
            {imgSubtitle &&
              <Column span={2}>
                <img src={imgSubtitle}/>
              </Column>
            }
            {!notification &&
              <>
                <Column span={12}>
                  <TitleCard>
                    {title}
                  </TitleCard>
                </Column>
                <Column span={12}>
                  <TextCard>
                    {message}
                  </TextCard>
                </Column>
              </>
            }
            {buttonText &&
              <Column span={12}>
                <Btn onClick={onClickBtn}>
                  {buttonIcon &&
                    <Icon icon={buttonIcon} color="white" size="small" />
                  }
                  <span>{buttonText}</span>
                </Btn>
              </Column>
            }
          </Row>
        </BannerServicesContainer>
      </div>
    </Banner>
  )
};

export default ServicesCard;