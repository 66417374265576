import styled, { keyframes} from "styled-components";
import { theme } from "styled-tools";

export const LoaderContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
`;

export const LoaderContent = styled.div`
  position: relative;
`;

export const IconLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SpinnerKeyframes = keyframes`
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  width: 110px;
  height: 110px;
  border: 7px solid ${theme("BackgroundPurple1")};
  border-bottom-color: ${theme("BackgroundPurple2")};
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${SpinnerKeyframes} 1s linear infinite;
`;