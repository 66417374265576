export const handleSizeTabs = (length: number) => {
  switch (length) {
    case 1:
      return 12;
    case 2:
      return 6;
    case 3:
      return 4;
    default:
      return 6;
  }
};
