import { createSlice } from "@reduxjs/toolkit";
import { findAccountBalance } from "./AccountBalance.actions";
import { BalanceState } from "../../Models/BalanceCard";
import { State } from "../Store";

const initialState: BalanceState = {
  loading: false,
  error: null,
  data: null
};

export const AccountBalanceSlice = createSlice({
  name: "accountBalance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findAccountBalance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(findAccountBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(findAccountBalance.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export const accountBalanceSelector = (state: State) => state.accountBalance;
