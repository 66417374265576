import styled from "styled-components";
import { theme } from "styled-tools";

import Text from "../../../Components/Text";

export const SocialSecurityNumberInfoCard = styled.div`
  display: flex;
  align-item: flex-start;
  padding: 10px 5px;
  border-radius: 8px;
  border: 1px solid ${theme("Primary_01")};
  margin-top: 12px;

  svg {
    rect {
      fill: ${theme("Primary_01")};
      stroke: ${theme("Primary_01")};
    }
  }
`;

export const SocialSecurityNumberOpenMarketInfo = styled(SocialSecurityNumberInfoCard)`
  border: none;
`;

export const SocialSecurityNumberOptional = styled.span`
  color: ${theme("Primary_01")};
  background-color: ${theme("BackgroundPurple4")};
  padding: 2px 8px;
  border-radius: 50px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
`;

export const SocialSecurityButtonsContainer = styled.div`
  gap: 15px;
`;

export const CustomText = styled(Text)`
  font-family: Poppins;
`;