import { useEffect, useRef } from "react";
import { useBlocker, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

// Components
import { FlexContainer } from "@components/Flex/Flex.styled";
import Text from "@components/Text";
import Layout from "@components/Layout";
import IconFont from "@components/IconFont";
import Link from "@components/Link";
import Button from "@components/Payments/Button";
import { QuoteSummary } from "@components/Payments/QuoteSummary/QuoteSummary.component";
import TransactionLoader from "@components/TransactionLoader";

// Redux
import { selectorLanguage } from "@redux/Translate";
import { QuotationSelector } from "@redux/Payments/Quotation/Quotation.slice";
import { PaymentOrderVerifySelector } from "@redux/Payments/PaymentOrder/PaymentOrderVerify.slice";
import { useSquarePaymentOrder } from "@hooks/Payments/useSquarePaymentOrder";
import { usePayPalPaymentOrder } from "@hooks/Payments/usePayPalPaymentOrder";

// Hooks
import { usePaymentOrder } from "@hooks/Payments/usePaymentOrders";
import { usePaymentOrderPayload } from "@hooks/Payments/usePaymentOrderPayload";
import { useModal } from "@hooks/Payments/useModal";

// utils
import { scrollTopWindow } from "@utils/ScrollTopWindow";

// Constants
import { PAYPAL_CLIENTID } from "@constants/Payments";

// Assets
import unitellerLogo from "@assets/Img/uniteller.svg";

import { PaymentOrderCreated, PaymentOrderPayload } from "@core/Payments/PaymentOrders/domain/PaymentOrderCreated";

import theme from "@contexts/ThemeProvider";

import { PaypalButtonContainer, TransactionLoaderContainer } from "./ReviewTransaction.styled";

export const ReviewTransactionPage = () => {
  const navigate = useNavigate();

  const [t] = useTranslation("global");
  const [tModals] = useTranslation("modals");
  const unitellerUrl = import.meta.env.VITE_UNITELLER_URL;
  const { isLoading: loadingPaymentOrder, error: paymentOrderError, createPayment } = usePaymentOrder();
  const { paymentOrderPayload } = usePaymentOrderPayload();
  const { modal: paymentOrderModal, showModal: showPaymentOrderModal } = useModal();
  const { modal: zelleConfirmationModal, showModal: showZelleConfirmationModal } = useModal();
  const { modal: venmoConfirmationModal, showModal: showVenmoConfirmationModal } = useModal();

  const lang = useSelector(selectorLanguage);
  const { currentVerifyResponse } = useSelector(PaymentOrderVerifySelector).data;
  const { createSquarePayment, isLoading: LoadingSquare } = useSquarePaymentOrder();
  const {
    createPayPalPayment,
    capturePayPalPayment,
    error: paypalError,
    isLoading: loadingPayPal,
  } = usePayPalPaymentOrder();

  const {
    countryDestination,
    currentQuotation,
    currentDeliveryMethodQuote,
    currentPaymentMethod,
    currentPaymentDestination,
    paymentMethodSelected,
  } = useSelector(QuotationSelector).data;

  const paypalOrderIdRef = useRef<string>("");
  const paymentOrderIdRef = useRef<string>("");

  const loading = loadingPaymentOrder || LoadingSquare || loadingPayPal;

  const onApprove = async (paypalOrderId: string) => {
    await capturePayPalPayment(paypalOrderId);
    paypalOrderIdRef.current = "";
    navigate("/transaction-completed");
  };

  const createPaypalOrder = async (): Promise<string> => {
    if (currentPaymentMethod) {
      scrollTopWindow();

      if (paypalOrderIdRef?.current) return paypalOrderIdRef.current;

      const request = paymentOrderPayload as PaymentOrderPayload;

      let response: PaymentOrderCreated | undefined;
      if (!paymentOrderIdRef?.current) {
        response = await createPayment(request);
      }

      if (response && response?.payment?.amount && response?.id) {
        paymentOrderIdRef.current = response.id;

        const order = await createPayPalPayment(request.payment.amount, response?.id || paymentOrderIdRef.current);

        if (order?.data) {
          const splittedData = order.data.split("token=");
          const orderId = splittedData?.[1] || "";

          paypalOrderIdRef.current = orderId;
          return orderId;
        }
      }
    }

    return "";
  };

  const preparePayment = async () => {
    if (currentPaymentMethod) {
      scrollTopWindow();

      if (currentPaymentMethod?.paymentOrigin === "authorize.net") {
        return navigate("/payment-authorize-add-card");
      }

      const request = paymentOrderPayload as PaymentOrderPayload;
      const response = await createPayment(request);

      if (response) {
        if (currentVerifyResponse && currentPaymentMethod?.paymentOrigin === "Square") {
          const responseSquare = await createSquarePayment(
            currentVerifyResponse.id,
            currentPaymentMethod?.paymentMethodFee || 0
          );

          if (responseSquare) {
            window.location.href = responseSquare.payment_link.url;
          }
        }
        navigate("/transaction-completed");
      }
    }
  };

  useEffect(() => {
    paypalOrderIdRef.current = "";
    paymentOrderIdRef.current = "";
  }, [paymentOrderPayload]);

  useEffect(() => {
    const hasCouponError = paymentOrderError?.errors
      ?.filter((err) => !!err)
      ?.some((err) => {
        if (typeof err === "string" || err instanceof String) {
          return (err as string).toLowerCase().includes("the coupon is not valid");
        }
        return false;
      });

    if (hasCouponError) {
      showPaymentOrderModal({
        modalType: "error",
        errorMessage: tModals("paymentOrder.errorCoupon"),
        handleClose: () => {
          navigate("/");
        },
      });
    } else if (paymentOrderError) {
      showPaymentOrderModal({
        modalType: "error",
        errorMessage: tModals("paymentOrder.errorMessage"),
      });
    }
  }, [paymentOrderError]);

  useEffect(() => {
    if (paypalError) {
      showPaymentOrderModal({
        modalType: "error",
        errorMessage: tModals("paypal.errorMessage"),
      });
    }
  }, [paypalError]);

  useEffect(() => {
    if (!currentQuotation || !currentDeliveryMethodQuote) {
      navigate("/");
    }
  }, []);

  useBlocker(
    ({ nextLocation, currentLocation }) =>
      loading &&
      currentLocation.pathname === "/review-transaction" &&
      ["/", "/beneficiary-selection"].includes(nextLocation.pathname)
  );

  return (
    <ThemeProvider theme={theme}>
      {loading && (
        <TransactionLoaderContainer>
          <TransactionLoader
            title={t("ReviewTransaction.LoaderTitle")}
            text={t("ReviewTransaction.LoaderText")}
            description={t("ReviewTransaction.LoaderDescription")}
            image="loaderTransactionImage"
          />
        </TransactionLoaderContainer>
      )}
      <Layout path={"/payment-method-selection"} textLink={t("buttons.back")} show={!loading} showNavigation={false}>
        <FlexContainer direction="column" p="0 16px" mw="556px" gap="24px">
          <FlexContainer direction="column" justify="center" p="13px 16px" gap="24px">
            <FlexContainer direction="column" alignItems="center">
              <Text size={2} align="center" weight={600}>
                {t("ReviewTransaction.SectionTitle")}
              </Text>
            </FlexContainer>
            <QuoteSummary />
            <FlexContainer>
              {currentPaymentMethod?.paymentOrigin === "PayPal" ? (
                <PayPalScriptProvider
                  options={{
                    clientId: PAYPAL_CLIENTID,
                    currency: "USD",
                    locale: "en_US",
                    intent: "capture",
                  }}
                >
                  <PaypalButtonContainer>
                    <PayPalButtons
                      style={{
                        disableMaxWidth: true,
                        shape: "pill",
                        tagline: false,
                      }}
                      forceReRender={[
                        lang,
                        currentQuotation,
                        currentDeliveryMethodQuote,
                        countryDestination,
                        currentPaymentMethod,
                        currentPaymentDestination,
                        paymentMethodSelected,
                      ]}
                      fundingSource="paypal"
                      createOrder={createPaypalOrder}
                      onApprove={(data) => onApprove(data.orderID)}
                    />
                  </PaypalButtonContainer>
                </PayPalScriptProvider>
              ) : (
                <FlexContainer justify="center">
                  <Button
                    sizeButton="fit"
                    variant="primary"
                    withShadow
                    onClick={() => {
                      if (currentPaymentMethod?.paymentOrigin === "Zelle") {
                        showZelleConfirmationModal({
                          modalType: "zelleConfirmationModal",
                          handleConfirm: () => preparePayment(),
                          handleClose: () => navigate(-1),
                        });
                      } else if (currentPaymentMethod?.paymentOrigin === "Venmo") {
                        showVenmoConfirmationModal({
                          modalType: "venmoConfirmationModal",
                          handleConfirm: () => preparePayment(),
                          handleClose: () => navigate(-1),
                        });
                      } else {
                        preparePayment();
                      }
                    }}
                  >
                    <FlexContainer justify="center" gap="20px">
                      <Text color="white" size={1} weight={600}>
                        {currentPaymentMethod?.paymentOrigin === "Zelle" ||
                        currentPaymentMethod?.paymentOrigin === "Venmo"
                          ? t("ReviewTransaction.ActionButtonCreateOrder")
                          : t("ReviewTransaction.ActionButton")}
                      </Text>
                      <IconFont name="arrow-right" color="white" size="medium" />
                    </FlexContainer>
                  </Button>
                </FlexContainer>
              )}
            </FlexContainer>
            {currentPaymentDestination?.rail === "UNT" && (
              <FlexContainer direction="column" alignItems="center" justify="center" gap="24px">
                <img src={unitellerLogo} width={83} alt="logo-header" />
                <FlexContainer justify="center" alignItems="center" gap="4px">
                  <IconFont name="circle-exclamation" size="default" color="error" />
                  <Text size="default">
                    <Link
                      href={`${unitellerUrl}`}
                      target={"_blank"}
                      rel="noopener noreferrer"
                      color="error"
                      size="default"
                      underline
                    >
                      {t("ReviewTransaction.disclaimerUniteller")}
                    </Link>
                    {t("ReviewTransaction.disclaimerUniteller2")}
                  </Text>
                </FlexContainer>
              </FlexContainer>
            )}
          </FlexContainer>
          {paymentOrderModal}
          {zelleConfirmationModal}
          {venmoConfirmationModal}
        </FlexContainer>
      </Layout>
    </ThemeProvider>
  );
};
