import Text from "../Text";
import Select, { GroupBase, SelectComponentsConfig, SelectInstance, StylesConfig } from "react-select";
import AsyncSelect from "react-select/async";
import { selectStyles, selectTheme } from "./SearchSelectTheme";
import { FlexContainer } from "../Flex/Flex.styled";
import { SearchSelectProps, SelectOption } from "../../Models/Select";
import { ErrorMessage } from "./ErrorMessage.component";
import { CustomOption } from "./CustomOptions.component";
import { CustomControl } from "./CustomControl.component";
import { CustomValueContainer } from "./CustomValueContainer.component";
import { ContainerSelect } from "./SearchSelect.styled";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import isEmpty from "lodash.isempty";
import { CustomInput } from "./CustomInput.component";
import { useTranslation } from "react-i18next";
import { Placeholder } from "@components/Payments/Input/Input.styled";

export const SearchSelect = ({
  selectRef,
  options,
  name,
  value,
  label,
  search,
  errors,
  labelProps = { color: "grey", size: 0 },
  async,
  cacheOptions = true,
  defaultOptions,
  showAvatar,
  customStyles,
  showAvatarOnValue,
  m = "0 0 10px 0",
  loadOptions,
  onBlur,
  onChange,
  defaultValueInput,
  placeholderInput,
  avatarSize,
  searchRegexp,
  isDisabled,
}: SearchSelectProps) => {
  const { t } = useTranslation("global");
  const { getValues } = useFormContext() ?? {};
  const [focusInput, setFocusInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const innerSelectRef =
    selectRef || useRef<SelectInstance<SelectOption<string>, boolean, GroupBase<SelectOption>> | null>(null);

  const components: Partial<SelectComponentsConfig<SelectOption<string>, boolean, GroupBase<SelectOption>>> = {
    Option: CustomOption,
    Control: CustomControl,
    Input: CustomInput,
    ...(showAvatarOnValue ? { ValueContainer: CustomValueContainer } : {}),
  };
  const optionsAsync = options.map((opt: SelectOption) => ({
    ...opt,
    showAvatar,
    avatarSize,
  })) as SelectOption[];

  const handleInputChange = (value: string) => {
    if ((searchRegexp && searchRegexp.test(value)) || !searchRegexp || value === "") {
      setInputValue(value);
    }
  };

  useEffect(() => {
    if (!value && getValues && name && !getValues(name)) {
      innerSelectRef.current?.clearValue();
    }
  }, [value, name, getValues]);

  return (
    <FlexContainer m={m} direction="column">
      {isEmpty(placeholderInput) && label && (
        <Text align="left" {...labelProps} weight={600} margin="0 0 10px 2px">
          {label}
        </Text>
      )}
      <ContainerSelect>
        {placeholderInput && (
          <Placeholder
            size={1}
            active={
              focusInput || Boolean(value) || Boolean(defaultValueInput) || Boolean(getValues(label || "") || false)
            }
          >
            {placeholderInput}
          </Placeholder>
        )}

        {async && (
          <AsyncSelect
            ref={innerSelectRef}
            name={name}
            styles={{ ...selectStyles, ...customStyles } as StylesConfig<SelectOption>}
            cacheOptions={cacheOptions}
            defaultOptions={defaultOptions}
            loadOptions={loadOptions}
            components={components}
            onBlur={() => {
              onBlur;
              setFocusInput(false);
            }}
            value={value}
            onFocus={() => setFocusInput(true)}
            onChange={(newValue) => onChange(newValue as SelectOption)}
            isDisabled={isDisabled}
          />
        )}
        {!async && (
          <Select
            isSearchable={search}
            ref={innerSelectRef}
            styles={{ ...selectStyles, ...customStyles } as StylesConfig<SelectOption>}
            theme={selectTheme}
            options={optionsAsync}
            name={name}
            placeholder={!placeholderInput && t("Forms.selectPlaceholder")}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            onBlur={() => {
              onBlur;
              setFocusInput(false);
            }}
            onFocus={() => setFocusInput(true)}
            onChange={(newValue) => onChange(newValue as SelectOption)}
            value={value}
            components={components}
            isDisabled={isDisabled}
          />
        )}
      </ContainerSelect>
      {name && errors?.[name] && (
        <ErrorMessage>
          <>{errors?.[name]?.message}</>
        </ErrorMessage>
      )}
    </FlexContainer>
  );
};
