import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { AlignItems, JustifyContent } from "../../Models/Column";
import { CoppelCardProps } from "../../Models/CoppelCard";

import { urlExternalServices } from "../../Constants/ConfigureApi";

import { FormatNumberToThousands } from "../../Utilities/FormatNumberToThousands";
import { maskInterbankKey } from "../../Utilities/String";
import { CopyTextToClipboard } from "../../Utilities/CopyTextToClipboard";

import { Column, Row } from "../Grid";
import Icon from "../Icon";
import Text from "../Text";
import { Flex } from "../Flex";
import Button from "../Button";

import {
  ButtonContainer,
  ButtonIcon,
  ContainerCopy,
  CoppelAccess,
  Copy,
  Details,
  NumberText,
  TitleCard,
} from "./CoppelCardAccount.styled";

import CoppelAccessImg from "../../Assets/Img/coppel_access.png";

const CoppelCardAccount = ({
  status,
  onClickBtn,
  amount,
  showBalance,
  accountNumber,
  routingNumber,
  logoUrlBank,
}: CoppelCardProps) => {
  const [t] = useTranslation("global");
  const [roundBalance, centsBalance]: string[] = useMemo(() => {
    if (amount) {
      const stringAmount = amount.toFixed(2);
      return stringAmount.split(".");
    } else {
      return ["0", "00"];
    }
  }, [amount]);

  const [copyAccount, setCopyAccount] = useState(false);
  const [copyRoute, setCopyRoute] = useState(false);

  const copyAccountNumber = (accountNumber: string | undefined) => {
    CopyTextToClipboard(accountNumber);

    setCopyAccount(true);

    setTimeout(() => {
      setCopyAccount(false);
    }, 1000);
  };

  const copyRouteNumber = (routingNumber: string | undefined) => {
    CopyTextToClipboard(routingNumber);

    setCopyRoute(true);

    setTimeout(() => {
      setCopyRoute(false);
    }, 1000);
  };

  return (
    <CoppelAccess $isYellow={status === "active" ? true : false}>
      <Flex justifyContent="space-between" alignItems="flex-start">
        <TitleCard>
          <img
            src={logoUrlBank ? logoUrlBank : CoppelAccessImg}
            width={145}
            height={19}
            alt="Coppel access"
          />
          <Text size={0.3} margin={0} color="Primary_16" align="start">
            {t("CoppelAccess.type")}
          </Text>
        </TitleCard>
        <Details onClick={onClickBtn}>
          <Text
            size={0.5}
            weight={600}
            align="left"
            margin={0}
            color="Primary_16"
          >
            {t("CoppelAccess.seeAccount")}
          </Text>
        </Details>
      </Flex>
      {!showBalance && (
        <div>
          <Row>
            <Column span={12} mt={2}>
              <NumberText>{t("CoppelAccess.account")}</NumberText>
            </Column>
            <Column span={12}>
              <Flex>
                <Text size={0.5} weight={700} color="Primary_16" margin={0}>
                  {maskInterbankKey(accountNumber as string)}
                </Text>
                <ButtonIcon
                  onClick={() =>
                    copyAccountNumber(accountNumber ? accountNumber : "")
                  }
                >
                  <Icon icon={"newCopy"} color="Primary_16" />
                </ButtonIcon>
              </Flex>
            </Column>
            {copyAccount ? (
              <Copy $show={copyAccount}>
                <Icon icon={"circleCheck"} color="white" />
                <Text size={0.3} color="white" margin={"0 0 0 4px"}>
                  {t("CoppelAccess.copy")}
                </Text>
              </Copy>
            ) : (
              <ContainerCopy />
            )}
          </Row>
          <Row>
            <Column span={12}>
              <NumberText>{t("CoppelAccess.route")}</NumberText>
            </Column>
            <Column span={12}>
              <Flex>
                <Text size={0.5} weight={700} color="Primary_16" margin={0}>
                  {routingNumber}
                </Text>
                <ButtonIcon
                  onClick={() =>
                    copyRouteNumber(routingNumber ? routingNumber : "")
                  }
                >
                  <Icon icon={"newCopy"} color="Primary_16" />
                </ButtonIcon>
              </Flex>
            </Column>
            {copyRoute ? (
              <Copy $show={copyRoute}>
                <Icon icon={"circleCheck"} color="white" />
                <Text size={0.3} color="white" margin={"0 0 0 4px"}>
                  {t("CoppelAccess.copy")}
                </Text>
              </Copy>
            ) : (
              <ContainerCopy />
            )}
          </Row>
        </div>
      )}
      {showBalance && (
        <div>
          <Row>
            <Column span={12} justifyContent={JustifyContent.flexStart} mt={1}>
              <Flex>
                <Text weight={600} size={3} margin={0} color="Primary_16">
                  ${FormatNumberToThousands(roundBalance)}
                </Text>
                <Text weight={600} size={0.3} color="Primary_16">
                  .{centsBalance}
                </Text>
              </Flex>
            </Column>
            <Column span={12} justifyContent={JustifyContent.flexStart}>
              <Text
                size={0.3}
                margin={"-10px 0 0 0"}
                color="Primary_16"
                weight={400}
              >
                {t("CoppelAccess.balance")}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column span={6} alignItems={AlignItems.flexEnd}>
              <Row>
                <Column span={12} mt={1}>
                  <NumberText>{t("CoppelAccess.account")}</NumberText>
                </Column>
                <Column span={12}>
                  <Flex>
                    <Text size={0.5} weight={700} color="Primary_16" margin={0}>
                      {maskInterbankKey(accountNumber as string)}
                    </Text>
                    <ButtonIcon
                      onClick={() =>
                        copyAccountNumber(accountNumber ? accountNumber : "")
                      }
                    >
                      <Icon icon={"newCopy"} color="Primary_16" />
                    </ButtonIcon>
                  </Flex>
                </Column>
                {copyAccount ? (
                  <Copy $show={copyAccount}>
                    <Icon icon={"circleCheck"} color="white" />
                    <Text size={0.3} color="white" margin={"0 0 0 4px"}>
                      {t("CoppelAccess.copy")}
                    </Text>
                  </Copy>
                ) : (
                  <ContainerCopy />
                )}
              </Row>
            </Column>
            <Column span={6} alignItems={AlignItems.flexEnd}>
              <Row>
                <Column span={12}>
                  <NumberText>{t("CoppelAccess.route")}</NumberText>
                </Column>
                <Column span={12}>
                  <Flex>
                    <Text size={0.5} weight={700} color="Primary_16" margin={0}>
                      {routingNumber}
                    </Text>
                    <ButtonIcon
                      onClick={() =>
                        copyRouteNumber(routingNumber ? routingNumber : "")
                      }
                    >
                      <Icon icon={"newCopy"} color="Primary_16" />
                    </ButtonIcon>
                  </Flex>
                </Column>
                {copyRoute ? (
                  <Copy $show={copyRoute}>
                    <Icon icon={"circleCheck"} color="white" />
                    <Text size={0.3} color="white" margin={"0 0 0 4px"}>
                      {t("CoppelAccess.copy")}
                    </Text>
                  </Copy>
                ) : (
                  <ContainerCopy />
                )}
              </Row>
            </Column>
          </Row>
        </div>
      )}
      <ButtonContainer>
        <Button
          text={t("CoppelAccess.btnSend")}
          variant="secondary"
          sizeText="small"
          sizeButton="medium"
          onClick={() => (window.location.href = urlExternalServices)}
        />
      </ButtonContainer>
    </CoppelAccess>
  );
};

export default CoppelCardAccount;
