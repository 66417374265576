import { createClient } from "@services/API/GlobalPaymentsApiClient";
import { PayPalOrder, PayPalOrderCapture } from "../domain/PayPal";

const client = createClient();
const sendolaPayPrefixService = "PayPal";

export const PayPalApiPaymentOrderRepository = (): any => {
  return {
    createOrder,
    captureOrder,
  };
};

const createOrder = async (amount: number, transactionId: string) => {
  const response = await client.post<PayPalOrder>(
    `${sendolaPayPrefixService}/create-order`,
    {
      amount,
      transactionId,
    }
  );

  return response.data;
};

const captureOrder = async (paypalOrderId: string) => {
  const response = await client.post<PayPalOrderCapture>(
    `${sendolaPayPrefixService}/capture-order`,
    {
      paypalId: paypalOrderId,
    }
  );

  return response.data;
};
