import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@redux/Store";
import isEmpty from "lodash.isempty";
import { useEffect, useMemo, useState } from "react";
import { getUserFeature } from "@redux/UserFeatures/UserFeatures.actions";
import { GET_USER_FEATURE_FULFILLED } from "@constants/user";
import {
  NEW_QUOTER,
  PAYPAL_PAYMENT_METHOD,
  VENMO_PAYMENT_METHOD,
  REWARDS_FEATURE,
  REWARDS_POPUP_FEATURE,
  SQUARE_PAYMENT_METHOD,
  AUTHORIZE_PAYMENT_METHOD,
  MANUAL_COUPON,
  BESTFX_LESSFEE,
} from "@constants/Features";
import { userFeatureSelector, UserFeatureSlice } from "@redux/UserFeatures/UserFeatures.slice";
import { UserFeature, UserFeaturesResponseData } from "@models/UserFeatures";
import { ByUserSliceSelector } from "@redux/ByUser/ByUser.slice";

export const useFeature = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userFeatures = useSelector(userFeatureSelector).data;
  const { loading } = useSelector(userFeatureSelector);
  const { data: user } = useSelector(ByUserSliceSelector);
  const newQuoterFeature = useMemo(
    () => userFeatures?.find((feat) => feat.featureCode === NEW_QUOTER)?.isActive,
    [userFeatures]
  );
  const squarePaymentMethodFeature = useMemo(
    () => userFeatures?.find((feat) => feat.featureCode === SQUARE_PAYMENT_METHOD)?.isActive,
    [userFeatures]
  );
  const paypalPaymentMethodFeature = useMemo(
    () => userFeatures?.find((feat) => feat.featureCode === PAYPAL_PAYMENT_METHOD)?.isActive,
    [userFeatures]
  );
  const venmoPaymentMethodFeature = useMemo(
    () => userFeatures?.find((feat) => feat.featureCode === VENMO_PAYMENT_METHOD)?.isActive,
    [userFeatures]
  );
  const rewardsFeature = useMemo(
    () => userFeatures?.find((feat) => feat.featureCode === REWARDS_FEATURE)?.isActive,
    [userFeatures]
  );
  const rewardsPopupFeature = useMemo(
    () => userFeatures?.find((feat) => feat.featureCode === REWARDS_POPUP_FEATURE)?.isActive,
    [userFeatures]
  );
  const authorizePaymentMethodFeature = useMemo(
    () => userFeatures?.find((feat) => feat.featureCode === AUTHORIZE_PAYMENT_METHOD)?.isActive,
    [userFeatures]
  );
  const manualCouponFeature = useMemo(
    () => userFeatures?.find((feat) => feat.featureCode === MANUAL_COUPON)?.isActive,
    [userFeatures]
  );
  const bestFxLessFeeFeature = useMemo(
    () => userFeatures?.find((feat) => feat.featureCode === BESTFX_LESSFEE)?.isActive,
    [userFeatures]
  );

  const isLoading = loading;

  const userId = useMemo(() => user?.userId || "", [user]);
  const [initialized, setInitialized] = useState(false);

  const getUserFeatureStatus = async (
    userId: string,
    featureCode: string,
    setUserFeature?: (active: boolean) => void
  ) => {
    const request = {
      userId: userId,
      featureCode: featureCode,
    };
    const response = await dispatch(getUserFeature(request));

    if (response?.type === GET_USER_FEATURE_FULFILLED) {
      setUserFeature && setUserFeature((response?.payload as UserFeaturesResponseData)?.isActive);
      return (response?.payload as UserFeaturesResponseData)?.isActive;
    }

    return false;
  };

  const getAllUserFeatures = async () => {
    const features = [];
    let newQuoterIsActive = newQuoterFeature;
    let squarePaymentMethodIsActive = squarePaymentMethodFeature;
    let paypalPaymentMethodIsActive = paypalPaymentMethodFeature;
    let venmoPaymentMethodIsActive = venmoPaymentMethodFeature;
    let authorizePaymentMethodIsActive = authorizePaymentMethodFeature;
    let manualCouponFeatureIsActive = manualCouponFeature;
    let rewardsIsActive = rewardsFeature;
    let rewardsPopupIsActive = rewardsPopupFeature;
    let bestFxLessFeeIsActive = bestFxLessFeeFeature;
    if (newQuoterFeature === undefined) {
      newQuoterIsActive = await getUserFeatureStatus(userId, NEW_QUOTER);
      features.push({
        featureCode: NEW_QUOTER,
        isActive: newQuoterIsActive,
      } as UserFeature);
    }
    if (squarePaymentMethodFeature === undefined) {
      squarePaymentMethodIsActive = await getUserFeatureStatus(userId, SQUARE_PAYMENT_METHOD);
      features.push({
        featureCode: SQUARE_PAYMENT_METHOD,
        isActive: squarePaymentMethodIsActive,
      } as UserFeature);
    }
    if (paypalPaymentMethodFeature === undefined) {
      paypalPaymentMethodIsActive = await getUserFeatureStatus(userId, PAYPAL_PAYMENT_METHOD);
      features.push({
        featureCode: PAYPAL_PAYMENT_METHOD,
        isActive: paypalPaymentMethodIsActive,
      } as UserFeature);
    }
    if (venmoPaymentMethodFeature === undefined) {
      venmoPaymentMethodIsActive = await getUserFeatureStatus(userId, VENMO_PAYMENT_METHOD);
      features.push({
        featureCode: VENMO_PAYMENT_METHOD,
        isActive: venmoPaymentMethodIsActive,
      } as UserFeature);
    }
    if (rewardsFeature === undefined) {
      rewardsIsActive = await getUserFeatureStatus(userId, REWARDS_FEATURE);
      features.push({
        featureCode: REWARDS_FEATURE,
        isActive: rewardsIsActive,
      } as UserFeature);
    }
    if (rewardsPopupFeature === undefined) {
      rewardsPopupIsActive = await getUserFeatureStatus(userId, REWARDS_POPUP_FEATURE);
      features.push({
        featureCode: REWARDS_POPUP_FEATURE,
        isActive: rewardsPopupIsActive,
      } as UserFeature);
    }
    if (authorizePaymentMethodFeature === undefined) {
      authorizePaymentMethodIsActive = await getUserFeatureStatus(userId, AUTHORIZE_PAYMENT_METHOD);

      features.push({
        featureCode: AUTHORIZE_PAYMENT_METHOD,
        isActive: authorizePaymentMethodIsActive,
      } as UserFeature);
    }
    if (manualCouponFeature === undefined) {
      manualCouponFeatureIsActive = await getUserFeatureStatus(userId, MANUAL_COUPON);
      features.push({
        featureCode: MANUAL_COUPON,
        isActive: manualCouponFeatureIsActive,
      } as UserFeature);
    }
    if (bestFxLessFeeFeature === undefined) {
      bestFxLessFeeIsActive = await getUserFeatureStatus(userId, BESTFX_LESSFEE);

      features.push({
        featureCode: BESTFX_LESSFEE,
        isActive: bestFxLessFeeIsActive,
      } as UserFeature);
    }

    if (!isEmpty(features)) {
      dispatch(UserFeatureSlice.actions.setFeatures(features));
    }
  };

  useEffect(() => {
    setInitialized(false);
    if (userId) {
      getAllUserFeatures().then(() => setInitialized(true));
    } else {
      setInitialized(true);
    }
  }, [userId]);

  return {
    newQuoterFeature,
    squarePaymentMethodFeature,
    paypalPaymentMethodFeature,
    venmoPaymentMethodFeature,
    authorizePaymentMethodFeature,
    rewardsFeature,
    rewardsPopupFeature,
    manualCouponFeature,
    bestFxLessFeeFeature,
    isLoading,
    initialized,
    getUserFeatureStatus,
    getAllUserFeatures
  };
};
