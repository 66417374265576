import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { CashInRequest } from "../../Models/Cashin";
import { UPLOAD_CASHIN } from "../../Constants/Cashin";

/**
 * Method to upload Cashin
 */
export const UploadCashIn = createAsyncThunk(
  UPLOAD_CASHIN,
  async (payload: CashInRequest, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post(`/logger`, {"userID": payload.userId, "personId": payload.personId, "featureCode": payload.code });
      return response.data.data;
    } catch(error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
