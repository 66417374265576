import styled, { css, CSSProperties, keyframes } from "styled-components";
import { ifProp, prop, theme } from "styled-tools";

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const LoaderContainer = styled.div<{ full?: boolean }>`
  position: ${ifProp("full", "fixed", "relative")};
  text-align: center;
  ${ifProp(
    "full",
    css`
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1099;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.5);
    `
  )}
`;

export const LoaderStyled = styled.div<{
  color?: string;
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  border?: CSSProperties["border"];
}>`
  width: ${prop("width", "60px")};
  height: ${prop("height", "60px")};
  border: ${prop("border", "0.5em")} solid ${prop("color", theme("Primary_04_highlight"))};
  border-left-color: ${(props) => props.theme.Primary_01};
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

export const Percent = styled.span<{ $msg: boolean }>`
  position: absolute;
  top: ${(props) => (props.$msg ? "30%" : "50%")};
  left: 50%;
  transform: translate(-50%, -50%);
`;
