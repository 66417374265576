import { OverLayContainer } from "./ModalTutorial.styled";
import { ModalProps } from "../../Models/ModalInterface";
import { useEffect } from "react";

const ModalTutorial = (props: ModalProps) => {
  const { show, children } = props;

  useEffect(() =>{
    if(show) {
      document.body.classList.add('modal');
    } else {
      document.body.classList.remove('modal');
    }
  },[show]);

  return(
    <OverLayContainer hidden={!show}>
      {children}
    </OverLayContainer>
  );
};

export default ModalTutorial;
