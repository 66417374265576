import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, State } from "../../../../Redux/Store";
import { Column, Row } from "../../../../Components/Grid";
import { FlexContainer, FlexItem } from "../../../../Components/Flex/Flex.styled";
import { AccountDisplayName, ActionButtons } from "./AccountCard.styled";
import Text from "../../../../Components/Text";
import Link from "../../../../Components/Link";
import { CardButton } from "../../../../Components/Card/Card.styled";
import { AccountCardProps } from "@core/Payments/Beneficiary/domain/BeneficiaryCardProps";
import { BeneficiarySlice } from "@redux/Payments/Beneficiary";
import { AlignItems } from "@models/Column";

const AccountCard = ({ withEditButtons, accountsList }: AccountCardProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const previousView = location.state?.previousView;
  const theme = useSelector((state: State) => state.theme);
  const [t] = useTranslation("global");
  const dispatch = useDispatch<AppDispatch>();

  const handleEditBeneficiary = async (bank?: string, id?: string, country?: string) => {
    dispatch(
      BeneficiarySlice.actions.setBankSelected({
        bankName: bank,
        idBankName: id,
        country,
      })
    );
    navigate("/recipients/add-accounts", {
      state: { viewAccount: "editAccount", previousView: previousView || "/recipients" },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <FlexContainer>
        <Row>
          <Column span={12} mb={2} alignItems={AlignItems.center} as={CardButton}>
            <FlexContainer w="100%" p="16px" gap="5%" alignItems="center">
              <FlexItem h="100%" w="64%" direction="column" justify="center" flexWrap>
                <AccountDisplayName>
                  <Text size={1} weight={500} margin="0" align="left">
                    {accountsList && accountsList[0].bankName}
                  </Text>
                </AccountDisplayName>
              </FlexItem>
              {withEditButtons && (
                <FlexItem w="auto" alignSelf="center" justify="end" direction="column" as={ActionButtons}>
                  <Link
                    href="#"
                    variant="primary"
                    onClick={() =>
                      handleEditBeneficiary(
                        accountsList && accountsList[0].bankName,
                        accountsList && accountsList[0].destinationId,
                        accountsList?.[0]?.country
                      )
                    }
                  >
                    {t("global.edit")}
                  </Link>
                </FlexItem>
              )}
            </FlexContainer>
          </Column>
        </Row>
      </FlexContainer>
    </ThemeProvider>
  );
};

export default AccountCard;
