import Modal from "../Modal.component";
import { FlexContainer } from "../../Flex/Flex.styled";
import { ModalProps } from "../../../Models/ModalInterface";
import Text from "../../Text";
import { useTranslation } from "react-i18next";
import ZelleLogo from "@assets/Img/zelle-wide-logo.png";
import { StepNumber, ZelleConfirmationWrap } from "./ZelleInstructionsModal.styled";
import { ZelleColorSpan } from "../../Text/Text.styled";
import Button from "@components/Payments/Button";
import IconFont from "../../IconFont";

export const ZelleConfirmationModal = (props: ModalProps) => {
  const [t] = useTranslation("global");
  const [tSendMoney] = useTranslation("sendMoney");

  return (
    <Modal {...props} hiddenCloseIcon as={ZelleConfirmationWrap}>
      <FlexContainer justify="center" alignItems="center" gap="4px" m="0 0 20px 0">
        <Text display="inline" align="left" size={1} weight={600}>
          {tSendMoney("Zelle.confirmationInstructions.title")}
          &nbsp;
          <img src={ZelleLogo} width="57px" height="32px" style={{ display: "inline", margin: "0 4px -9px 4px" }} />
        </Text>
      </FlexContainer>
      <FlexContainer direction="column" gap="12px" p="0">
        <FlexContainer alignItems="center" gap="10px">
          <StepNumber>1</StepNumber>
          <FlexContainer direction="column">
            <Text align="left" size={0.5} color="black" weight={400} lineHeight="16px">
              <ZelleColorSpan
                dangerouslySetInnerHTML={{ __html: tSendMoney("Zelle.confirmationInstructions.step1.description") }}
              />
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer alignItems="center" gap="10px">
          <StepNumber>2</StepNumber>
          <FlexContainer direction="column">
            <Text align="left" size={0.5} color="black" weight={400} lineHeight="16px">
              <ZelleColorSpan
                dangerouslySetInnerHTML={{ __html: tSendMoney("Zelle.confirmationInstructions.step2.description") }}
              />
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer alignItems="center" gap="10px">
          <StepNumber>3</StepNumber>
          <FlexContainer direction="column">
            <Text align="left" size={0.5} color="black" weight={400} lineHeight="16px">
              <ZelleColorSpan
                dangerouslySetInnerHTML={{ __html: tSendMoney("Zelle.confirmationInstructions.step3.description") }}
              />
            </Text>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer justify="center" alignItems="center" m="12px 0 0 0" gap="12px">
          <Button
            variant="outlineMuted"
            sizeButton="full"
            padding="8px"
            onClick={() => {
              if (props.handleClose) {
                props.handleClose();
              }
            }}
          >
            <FlexContainer justify="center" alignItems="center" gap="8px">
              <IconFont name="arrow-left" color="black" />
              <Text color="black" size="default" weight={600}>
                {t("global.goback")}
              </Text>
            </FlexContainer>
          </Button>
          <Button
            variant="primary"
            sizeButton="full"
            padding="8px"
            withShadow
            onClick={() => {
              if (props.handleConfirm) {
                props.handleConfirm();
              }
            }}
          >
            <FlexContainer justify="center" alignItems="center" gap="8px">
              <Text color="white" size="default" weight={600}>
                {t("global.accept")}
              </Text>
              <IconFont name="arrow-right" color="white" />
            </FlexContainer>
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
