import styled from "styled-components";
import { theme } from "styled-tools";

import Card from "../../../../Components/Card";


export const PayrollCardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover,
  &:active {
    box-shadow: 2px 4px 10px 0px rgba(${theme('RGBA_04')}), inset 0 0 0 2px ${theme('Primary_01')};
  }
`;

export const PayrollCardTitleHeader = styled.p`
  font-family: Poppins;
  font-size: 10px;
  color: ${theme('Primary_01')};
  font-weight: 700;
  text-transform: uppercase;
`;   

export const PayrollCardButtons = styled.div`
  margin-top: 15rem;
`;