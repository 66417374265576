import { createSlice } from "@reduxjs/toolkit";
import {
  PlaidAccountAction,
  PlaidAccountDetailsAction,
  PlaidCreateTokenAction,
  PlaidExchangeTokenAction,
  PlaidDeleteAccountAction,
  PlaidUpdateAccountAction,
  BankTransfersAction,
  BankTransfersDetailsAction,
  CreateBankTransferAction
} from './FundsAccount.actions';
import { State } from "../Store";
import { FUNDS_ACCOUNT } from "../../Constants/FundsAccount";
import { FundsState } from "../../Models/Funds";

const initialState: FundsState = {
  loading: false,
  error: null,
  plaidAccounts: null,
  plaidAccountDetails: null,
  plaidToken: null,
  plaidExchangeToken: null,
  plaidAccountSelected: null,
  bankTransfers: null,
  bankTransferDetails: null,
  bankTransferCreated: null
}

export const FundsAccountDataSlice = createSlice({
  name: FUNDS_ACCOUNT,
  initialState,
  reducers: {
    resetState() {
      return {
        ...initialState,
      };
    },
    resetBankTransfersState(state) {
      return {
        ...state,
        bankTransfers: null,
        bankTransferDetails: null,
        bankTransferCreated: null,
      };
    },
    setCurrentPlaidAccount(state, action) {
      state.plaidAccountSelected = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(PlaidAccountAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PlaidAccountAction.fulfilled, (state, action) => {
        state.loading = false;
        state.plaidAccounts = action.payload ;
      })
      .addCase(PlaidAccountAction.rejected, (state, action) => {
        state.loading = false;
        state.plaidAccounts = null;
        state.error = action.payload as string;
      })
      .addCase(PlaidAccountDetailsAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PlaidAccountDetailsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.plaidAccountDetails = action.payload;
      })
      .addCase(PlaidAccountDetailsAction.rejected, (state, action) => {
        state.loading = false;
        state.plaidAccountDetails = null;
        state.error = action.payload as string;
      })
      .addCase(PlaidCreateTokenAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PlaidCreateTokenAction.fulfilled, (state, action) => {
        state.loading = false;
        state.plaidToken = action.payload;
      })
      .addCase(PlaidCreateTokenAction.rejected, (state, action) => {
        state.loading = false;
        state.plaidToken = null;
        state.error = action.payload as string;
      })
      .addCase(PlaidExchangeTokenAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PlaidExchangeTokenAction.fulfilled, (state, action) => {
        state.loading = false;
        state.plaidExchangeToken = action.payload;
      })
      .addCase(PlaidExchangeTokenAction.rejected, (state, action) => {
        state.loading = false;
        state.plaidExchangeToken = null;
        state.error = action.payload as string;
      })
      .addCase(PlaidDeleteAccountAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PlaidDeleteAccountAction.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(PlaidDeleteAccountAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(PlaidUpdateAccountAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PlaidUpdateAccountAction.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(PlaidUpdateAccountAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(BankTransfersAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(BankTransfersAction.fulfilled, (state, action) => {
        state.loading = false;
        state.bankTransfers = action.payload;
      })
      .addCase(BankTransfersAction.rejected, (state, action) => {
        state.loading = false;
        state.bankTransfers = null;
        state.error = action.payload as string;
      })
      .addCase(BankTransfersDetailsAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(BankTransfersDetailsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.bankTransferDetails = action.payload;
      })
      .addCase(BankTransfersDetailsAction.rejected, (state, action) => {
        state.loading = false;
        state.bankTransferDetails = null;
        state.error = action.payload as string;
      })
      .addCase(CreateBankTransferAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(CreateBankTransferAction.fulfilled, (state, action) => {
        state.loading = false;
        state.bankTransferCreated = action.payload;
      })
      .addCase(CreateBankTransferAction.rejected, (state, action) => {
        state.loading = false;
        state.bankTransferCreated = null;
        state.error = action.payload as string;
      });
  },
});

export const { 
  setCurrentPlaidAccount, 
  resetState,
  resetBankTransfersState
} = FundsAccountDataSlice.actions;

export default FundsAccountDataSlice.reducer;

export const FundsAccountDataSelector = (state: State) => state.FundsAccount;
