import styled, { css } from "styled-components";
import { ifProp, prop, theme } from "styled-tools";
import mediaQuery, { Screen } from "../../../Utilities/MediaQuery";

import Text from "../../../Components/Text";
import Title from "../../../Components/Title";

export const InstructionsContainer = styled.div`
  ${mediaQuery[Screen.desktop]} {
    padding-inline: 450px;
  }

  .check-instructions-img {
    width: 95%;
  }
`;

export const InstructionsTitle = styled(Title)`
  font-family: Poppins;
`;

export const InstructionsList = styled.ol`
  color: ${theme('Content_UX_02')};
  padding-left: 16px;

  li {
    margin-bottom: 3px;
  }
`;

export const InstructionsText = styled(Text)<{
  fontSize?: number;
}>`
  font-family: Poppins;
  ${ifProp(
    "fontSize",
    css`
      font-size: ${prop("fontSize")}px;
    `,
    ""
  )};
`;

export const InstructionsCard = styled.div`
  background-color: ${(theme("BackgroundPurple3"))};
  border-radius: 12px;
  padding: 20px;
  margin-top: 16px;
  margin-bottom: 44px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  cursor: pointer;
`;

export const IconContainer = styled.div`
  margin-left: auto;
`;

export const Example = styled.p`
  color: ${(theme("BackgroundPurple5"))};
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin-bottom: 0;
  margin-left: 15px;
`;
