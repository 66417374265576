export type PaymentMethods =
  | "Sendola Card"
  | "Sendola ACH"
  | "Sendola Plaid"
  | "Debit Card"
  | "Credit Card"
  | "Square"
  | "Coppel"
  | "Zelle"
  | "PayPal"
  | "Venmo"
  | "authorize.net";

export type PaymentMethodType =
  | "economy"
  | "economy-minutes"
  | "economy-express"
  | "economy-express-minutes"
  | "express";

export type SendolaCardPaymentMethod = {
  id: string;
  personId: string;
  label: string;
  balance: number;
  availableBalance: number;
  routingNumber: string;
  accountNumber: string;
  type: string;
  status: string;
  createdAt: string;
  isSendolaPay: boolean;
  sponsorBank: string;
  backgroundBank: string;
  logoUrlBank: string;
  isVisible: boolean;
  paymentSource?: PaymentMethods;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSendolaCardPaymentMethod(obj: any): obj is SendolaCardPaymentMethod {
  return (
    obj &&
    "paymentSource" in obj &&
    typeof obj.paymentSource === "string" &&
    (obj.paymentSource as PaymentMethods) === "Sendola Card"
  );
}

export type PlaidAccount = {
  id?: string;
  plaidAccountId: string;
  institutionId: string;
  bankName: string;
  logoUrl: string;
  backgroundColor: string;
  accountName: string;
  accountLastFour: string;
  accountNumber: string;
  account: string;
  routingNumber: string;
  subtype: string;
  available: number;
  activeToken: boolean;
  isVisible: boolean;
  paymentSource?: PaymentMethods;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isPlaidAccount(obj?: any): obj is PlaidAccount {
  return (
    obj &&
    "paymentSource" in obj &&
    typeof obj.paymentSource === "string" &&
    (obj.paymentSource as PaymentMethods) === "Sendola Plaid"
  );
}

export type SquarePaymentMethod = {
  id?: string;
  name: string;
  description: string;
  paymentSource?: PaymentMethods;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSquareAccount(obj?: any): obj is SquarePaymentMethod {
  return (
    obj &&
    "paymentSource" in obj &&
    typeof obj.paymentSource === "string" &&
    (obj.paymentSource as PaymentMethods) === "Square"
  );
}

export type PayPalPaymentMethod = {
  id?: string;
  name: string;
  description: string;
  paymentSource?: PaymentMethods;
};

export type ZellePaymentMethod = {
  id?: string;
  name: string;
  description: string;
  paymentSource?: PaymentMethods;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isZellePaymentMethod(obj?: any): obj is ZellePaymentMethod {
  return (
    obj &&
    "paymentSource" in obj &&
    typeof obj.paymentSource === "string" &&
    (obj.paymentSource as PaymentMethods) === "Zelle"
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isPaypalPaymentMethod(obj?: any): obj is PayPalPaymentMethod {
  return (
    obj &&
    "paymentSource" in obj &&
    typeof obj.paymentSource === "string" &&
    (obj.paymentSource as PaymentMethods) === "PayPal"
  );
}

export type PaymentMethod = SendolaCardPaymentMethod | PlaidAccount | SquarePaymentMethod | PayPalPaymentMethod;

export interface PaymentMethodsResponse {
  sendolaPay?: SendolaCardPaymentMethod[];
  coppel?: SendolaCardPaymentMethod[];
  plaid?: {
    hasAccounts: boolean;
    accounts: PlaidAccount[];
  };
  square?: SquarePaymentMethod;
}
