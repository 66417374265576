import styled, { css, CSSProperties } from "styled-components";
import { PaymentMethodType } from "@core/Payments/Common/domain/PaymentMethods";
import { ifProp, prop, switchProp, theme } from "styled-tools";
import { FlexContainer } from "../../../Flex/Flex.styled";

export const PaymentMethodRadioContainer = styled(FlexContainer)`
  cursor: pointer;
`;

export const PaymentMethodTypeTag = styled.span<{ type?: PaymentMethodType; margin?: CSSProperties["margin"] }>`
  border-radius: 16px;
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 8px;
  text-align: center;
  letter-spacing: -0.48px;
  word-wrap: nowrap;
  margin: ${prop("margin")};

  color: ${switchProp("type", {
    express: theme("Green_01"),
    "economy-express": theme("Primary_Blue"),
    "economy-express-minutes": theme("Primary_Blue"),
    "economy-minutes": theme("Primary_01_600"),
    economy: theme("Primary_01_600"),
  })};
  background-color: ${switchProp("type", {
    express: theme("Green_07"),
    "economy-express": theme("Alice_Blue"),
    "economy-express-minutes": theme("Alice_Blue"),
    "economy-minutes": theme("Primary_01_25"),
    economy: theme("Primary_01_25"),
  })};
`;

export const MostPopularTag = styled.span<{ gradient?: boolean }>`
  position: absolute;
  top: -10px;
  right: 12px;
  border-radius: 16px;
  text-transform: uppercase;
  background-color: ${theme("Primary_01_700")};
  color: ${theme("White")};
  padding: 2px 6px;
  color: var(--utility-white, #fff);
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
  text-transform: uppercase;
  box-shadow: ${theme("White")} 0px 0px 0px 3px;

  ${ifProp(
    "gradient",
    css`
      background: ${theme("Gradient_Rewards")};
    `
  )};
`;

export const PaymentMethodCard = styled(FlexContainer)`
  flex: 1 1 auto;
  display: flex;
  gap: 12px;

  > img {
    width: 40px;
    height: 40px;
  }
`;

export const BankSupportedLogoContainer = styled(FlexContainer)`
  width: auto;
  height: 17px;
  padding: 4px;
`;

export const PlaidLogoElement = styled.div`
  background: linear-gradient(8.83deg, ${theme("Primary_01_800")} 0.97%, ${theme("Primary_01")} 112.47%);
  border-radius: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SendolaPayLogoElement = styled(FlexContainer)`
  width: auto;
  align-items: center;
  gap: 3px;

  span {
    font-size: 21px;
  }

  img:nth-child(1) {
    border-radius: 50%;
    background-color: ${theme("Primary_19")};
    padding: 5px;
    max-width: 40px;
    height: 40px;
    object-fit: contain;
    object-position: center;
  }

  img:nth-child(3) {
    max-width: 42px;
  }
`;

export const SendolaPayAccountLogos = styled(FlexContainer)`
  width: auto;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 3px;
  z-index: 0;

  & > div {
    width: 24px;
    height: 24px;
    margin-left: -5px;

    &:first-child {
      margin-left: 0;
      z-index: 4;
    }

    &:nth-child(n + 1) {
      z-index: 3;
    }

    &:nth-child(n + 2) {
      z-index: 2;
    }

    &:nth-child(n + 3) {
      z-index: 1;
    }
  }
`;
