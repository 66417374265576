import Modal from "../Modal.component";
import { ModalProps } from "../../../Models/ModalInterface";
import Text from "../../Text";
import Icon from "../../Icon";
import Button from "../../Button";
import { useTranslation } from "react-i18next";
import { GeoLocationModalWrap } from "../GeoLocationModal/GeoLocationModal.styled";
import { FlexContainer } from "../../Flex/Flex.styled";

export const GeoLocationRetryModal = (props: ModalProps) => {
  const { t } = useTranslation("modals");

  return (
    <Modal {...props} as={GeoLocationModalWrap}>
      <Icon icon="location" size="xlarge" />
      <Text size={3} weight={700}>
        {props.message || t("geolocation.title")}
      </Text>
      <Text size={1}>{t("geolocation.warning")}</Text>
      {/* <RetryImgInstructions
        src={imgTypes["locationPermission"]}
        alt="Enable location permission"
      /> */}
      <FlexContainer
        direction="column"
        alignItems="center"
        justify="center"
        gap="1rem"
      >
        <Button
          variant={"primary"}
          sizeButton="large"
          sizeText="large"
          text={t("geolocation.retryConfirm")}
          colorIcon="white"
          sizeIcon="large"
          onClick={props.handleConfirm}
        />
        <Button
          variant={"outline"}
          sizeButton="large"
          sizeText="large"
          text={t("geolocation.deny")}
          colorIcon="white"
          sizeIcon="large"
          onClick={() => props.handleClose && props.handleClose()}
          color={"Primary_01"}
        />
      </FlexContainer>
    </Modal>
  );
};
