import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { defaultErrorMessage } from "../../Constants/Requests";
import { POST_PROOF_ADDRESS } from "../../Constants/ProofOfAddress";
import { ProofOfAddressPayload } from "../../Models/ProofOfAddress";

export const PostProofAddress = createAsyncThunk(
  POST_PROOF_ADDRESS,
  async (payload:ProofOfAddressPayload, thunkAPI) => {
    try {
      const headers = {
        'content-type': 'multipart/form-data',
      };
      const response = await BaseConfigApi.post(`/persons/${payload.personId}/documents/proof-address`, payload.data, { headers: headers });
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
