import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "@services/API/BaseConfigApi";
import { GET_USER_FEATURE } from "@constants/user";
import { GetRequestFeature } from "@models/UserFeatures";
import { defaultErrorMessage } from "@constants/Requests";

/**
 * Method to get user feature
 */
export const getUserFeature = createAsyncThunk(
  GET_USER_FEATURE,
  async (payload: GetRequestFeature, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(
        `/userfeatures/${payload.userId}/${payload.featureCode}`
      );
      return response.data.data;
    } catch (error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
