
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import {
  CREATE_BANK_TRANSFER,
  CREATE_PLAID_TOKEN,
  DELETE_PLAID_ACCOUNT,
  EXCHANGE_PLAID_TOKEN,
  GET_BANK_TRANSFERS,
  GET_BANK_TRANSFERS_DETAILS,
  GET_PLAID_ACCOUNT,
  GET_PLAID_ACCOUNT_DETAILS,
  UPDATE_PLAID_ACCOUNT
} from "../../Constants/FundsAccount";
import { defaultErrorMessage } from "../../Constants/Requests";
import { AxiosError } from "axios";
import {
  BankTransferRequest,
  PlaidAccountsUpdateRequest,
  PlaidTokenExchangeRequest
} from "../../Models/Funds";


export const PlaidAccountAction = createAsyncThunk(
  GET_PLAID_ACCOUNT,
  async (languageCode: string, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(
        `/plaid-accounts`,
        {
          headers: { languageCode },
        }
      );
      return response.data.data;
    } catch (error) { 
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
)

export const PlaidAccountDetailsAction = createAsyncThunk(
  GET_PLAID_ACCOUNT_DETAILS,
  async (payload: {
    accountId: string,
    languageCode: string
  }, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(
        `/plaid-account-detail/${payload.accountId}`,
        {
          headers: { languageCode: payload.languageCode },
        }
      );
      return response.data.data;
    } catch (error) { 
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
)

export const PlaidCreateTokenAction = createAsyncThunk(
  CREATE_PLAID_TOKEN,
  async (payload: {
    languageCode: string,
    customizationName: string,
  }, thunkAPI
) => {
    try {
      const response = await BaseConfigApi.post(
        `/token`,
        {},
        {
          headers: {
            "languageCode": payload.languageCode,
            "linkCustomizationName": payload.customizationName
          }
        }
      );
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);

export const PlaidExchangeTokenAction = createAsyncThunk(
  EXCHANGE_PLAID_TOKEN,
  async (payload: {
    body: PlaidTokenExchangeRequest
    languageCode: string
  }, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post(
        `/exchange-token`,
        payload.body,
        {
          headers: {
            "languageCode": payload.languageCode
          }
        }
      );
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);

export const PlaidDeleteAccountAction = createAsyncThunk(
  DELETE_PLAID_ACCOUNT,
  async (payload: {
    accountId: string,
    languageCode: string
  }, thunkAPI) => {
    try {
      const response = await BaseConfigApi.delete(
        `/delete-token/${payload.accountId}`,
        {
          headers: {
            "languageCode": payload.languageCode
          }
        }
      );
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);

export const PlaidUpdateAccountAction = createAsyncThunk(
  UPDATE_PLAID_ACCOUNT,
  async (payload: {
    body: PlaidAccountsUpdateRequest,
    languageCode: string,
    customizationName: string;
  }, thunkAPI) => {
    try {
      const response = await BaseConfigApi.put(
        `/updatetoken`,
        payload.body,
        {
          headers: {
            "languageCode": payload.languageCode,
            "linkCustomizationName": payload.customizationName
          }
        }
      );
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);

export const BankTransfersAction = createAsyncThunk(
  GET_BANK_TRANSFERS,
  async (payload: { personId: string }, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(
        `/transferorders`,
        {
          headers: { 
            personId: payload.personId
          },
        }
      );
      return response.data.data.transferOrders;
    } catch (error) { 
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);

export const BankTransfersDetailsAction = createAsyncThunk(
  GET_BANK_TRANSFERS_DETAILS,
  async (payload: {
    personId: string,
    transferOrderId: string,
  }, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(
        `/transferorders/byid/${payload.transferOrderId}`,
        {
          headers: {
            transferOrderId: payload.transferOrderId,
            personId: payload.personId
          },
        }
      );
      return response.data.data;
    } catch (error) { 
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
)

export const CreateBankTransferAction = createAsyncThunk(
  CREATE_BANK_TRANSFER,
  async (payload: {
    personId: string,
    body: BankTransferRequest
  }, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post(
        `/transferorders`,
        payload.body,
        {
          headers: {
            personId: payload.personId
          }
        }
      );
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);