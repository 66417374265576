import styled, { css } from "styled-components";
import { ifProp, prop, theme } from "styled-tools";
import { FlexContainer } from "../../../Components/Flex/Flex.styled";

export const AccountSubTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-content: space-between;
  margin: 16px 0 0 0;
`;

export const AddAccountButton = styled.button`
  font-weight: 500;
  font-size: 16px;
  font-family: Poppins;
  text-align: center;
  width: 100%;
  text-decoration: underline;
  margin: 50px 0 0 0;
  color: ${theme("Neutral_500")};
  cursor: pointer;
`;

export const SkipButton = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AccountListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CloseContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  margin-top: 18px;
  margin-right: 24px;

  div {
    border-radius: 50% 50%;
    background-color: ${theme("Primary_19")};
    padding: 3px;
  }
`;

export const CountryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 4px;
  border-bottom: 2px ${theme("Primary_18")} solid;
`;

export const CountryIcon = styled.img<{ size?: string }>`
  height: ${ifProp("size", prop("size"), "35px")};
  width: ${ifProp("size", prop("size"), "35px")};
  margin-right: 3px;
  border-radius: 50%;
`;

export const ButtonSection = styled(FlexContainer)<{ withBorder: boolean }>`
  border-top: ${ifProp(
    "withBorder",
    css`
      2px ${theme("Primary_18")} solid;
    `,
    ""
  )};
  flex-direction: column;
  padding: 24px 40px;
  width: 100%;
  align-items: center;
`;
