export type ApiRequestError<E = unknown> = {
  errors: E[];
  error?: { code: string; msg: string };
  message: string;
  status: number;
  statusText: string;
  succeeded: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isApiRequestError(obj: any): obj is ApiRequestError {
  return (
    obj &&
    (("error" in obj && typeof obj.error === "object") || ("errors" in obj && Array.isArray(obj.errors))) &&
    (("message" in obj && typeof obj.message === "string") ||
      ("status" in obj && typeof obj.status === "number") ||
      ("succeeded" in obj && typeof obj.succeeded === "boolean"))
  );
}
