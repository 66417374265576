import theme from "@contexts/ThemeProvider";
import { iconTypes, imgTypes, iconFontTypes, FontColor } from "../Utilities/Icons";

export type IconSize =
  | "xsmall"
  | "small"
  | "xmedium"
  | "medium"
  | "large"
  | "xlarge"
  | "xxlarge"
  | "xxxlarge"
  | "default";

export type IconsFontType = (typeof iconFontTypes)[number];
export interface Props {
  icon: IconTypeKey;
  size?: string;
  width?: number;
  height?: number;
  color?: FontColor;
  fillIcon?: boolean;
  gradient?: boolean;
  fillTransparent?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export type IconTypes = typeof iconTypes;
export type IconTypeKey = keyof IconTypes;

export type ImageTypes = typeof imgTypes;
export type ImageTypeKey = keyof ImageTypes;

export const iconColors = {
  white: theme.Background_01,
  black: theme.Background_02,
  gray: theme.Content_UX_02,
  disabled: theme.Content_State_UX_Disabled,
  black_highlight: theme.Content_UX_01,
  primary: theme.Primary_01,
  primaryStrong: theme.Primary_01_800,
  primary700: theme.Primary_01_700,
  primary_01_600: theme.Primary_01_600,
  primaryHighlight: theme.Primary_01_500,
  primaryLight: theme.Primary_01_50,
  zelle: theme.ZelleColor,
  venmo: theme.VenmoColor,
  secondary: theme.Secondary_01,
  warning: theme.Primary_08,
  warningStrong: theme.Primary_08_700,
  error: theme.Error,
  transparent: theme.Transparent,
  blue: theme.BannerBank,
};

export type IconsFontColors = keyof typeof iconColors;

export type IconsShapeBorder = "rounded" | "square";
