
export const FUNDS_ACCOUNT = "FundsAccount";
export const FUNDS_ACCOUNT_PENDING = "FundsAccount/pending";
export const FUNDS_ACCOUNT_REJECTED = "FundsAccount/rejected";
export const FUNDS_ACCOUNT_FULFILLED = "FundsAccount/fulfilled";

export const GET_PLAID_ACCOUNT = "PlaidAccount";
export const GET_PLAID_ACCOUNT_PENDING = "PlaidAccount/pending";
export const GET_PLAID_ACCOUNT_REJECTED = "PlaidAccount/rejected";
export const GET_PLAID_ACCOUNT_FULFILLED = "PlaidAccount/fulfilled";

export const GET_PLAID_ACCOUNT_DETAILS = "PlaidAccountDetails";
export const GET_PLAID_ACCOUNT_DETAILS_PENDING = "PlaidAccountDetails/pending";
export const GET_PLAID_ACCOUNT_DETAILS_REJECTED = "PlaidAccountDetails/rejected";
export const GET_PLAID_ACCOUNT_DETAILS_FULFILLED = "PlaidAccountDetails/fulfilled";

export const CREATE_PLAID_TOKEN = "PlaidCreateToken";
export const CREATE_PLAID_TOKEN_PENDING = "PlaidCreateToken/pending";
export const CREATE_PLAID_TOKEN_REJECTED = "PlaidCreateToken/rejected";
export const CREATE_PLAID_TOKEN_FULFILLED = "PlaidCreateToken/fulfilled";

export const EXCHANGE_PLAID_TOKEN = "PlaidExchangeToken";
export const EXCHANGE_PLAID_TOKEN_PENDING = "PlaidExchangeToken/pending";
export const EXCHANGE_PLAID_TOKEN_REJECTED = "PlaidExchangeToken/rejected";
export const EXCHANGE_PLAID_TOKEN_FULFILLED = "PlaidExchangeToken/fulfilled";

export const DELETE_PLAID_ACCOUNT = "PlaidDeleteAccount";
export const DELETE_PLAID_ACCOUNT_PENDING = "PlaidDeleteAccount/pending";
export const DELETE_PLAID_ACCOUNT_REJECTED = "PlaidDeleteAccount/rejected";
export const DELETE_PLAID_ACCOUNT_FULFILLED = "PlaidDeleteAccount/fulfilled";

export const UPDATE_PLAID_ACCOUNT = "PlaidUpdateAccount";
export const UPDATE_PLAID_ACCOUNT_PENDING = "PlaidUpdateAccount/pending";
export const UPDATE_PLAID_ACCOUNT_REJECTED = "PlaidUpdateAccount/rejected";
export const UPDATE_PLAID_ACCOUNT_FULFILLED = "PlaidUpdateAccount/fulfilled";

export const GET_BANK_TRANSFERS = "BankTransfers";
export const GET_BANK_TRANSFERS_PENDING = "BankTransfers/pending";
export const GET_BANK_TRANSFERS_REJECTED = "BankTransfers/rejected";
export const GET_BANK_TRANSFERS_FULFILLED = "BankTransfers/fulfilled";

export const GET_BANK_TRANSFERS_DETAILS = "BankTransfersDetails";
export const GET_BANK_TRANSFERS_DETAILS_PENDING = "BankTransfersDetails/pending";
export const GET_BANK_TRANSFERS_DETAILS_REJECTED = "BankTransfersDetails/rejected";
export const GET_BANK_TRANSFERS_DETAILS_FULFILLED = "BankTransfersDetails/fulfilled";

export const CREATE_BANK_TRANSFER = "CreateBankTransfer";
export const CREATE_BANK_TRANSFER_PENDING = "CreateBankTransfer/pending";
export const CREATE_BANK_TRANSFER_REJECTED = "CreateBankTransfer/rejected";
export const CREATE_BANK_TRANSFER_FULFILLED = "CreateBankTransfer/fulfilled";