import styled from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { theme } from "styled-tools";
import BgWinner from "../../Assets/Img/Winner.png";

export const BannerContainer = styled.div`
  border-radius: 12px;
  background: ${theme("Primary_14")};
  padding: 12px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 122px;
  position: relative;
  background-image: url(${BgWinner});
  background-size: contain;
  background-position: right;
;

  button {
    border: none;
    border-radius: 50px;
    background: ${theme("Background_01")};
    display: flex;
    width: 165px;
    height: 36px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: ${theme("Content_UX_01")};
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;

    a {
      color: ${theme("Content_UX_01")};
      font-weight: 600;
    }
  }

  ${mediaQuery[Screen.desktop]} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 12px;
  }
`;

export const closeButton = styled.div`
  cursor: pointer;
`;

export const RowTopFixed = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  padding: 12px 24px;
`;

export const RowBottomFixed = styled.div`
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 12px 24px;
`;

export const ContainerText = styled.div`
  width: 140px;
`;

