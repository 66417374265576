import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";

const initialState = {
  transactions: true
};

export const ModalTutorialsSlice = createSlice({
  name: "modalTutorials",
  initialState,
  reducers: {
    updateTutorialTransactions: (state, action) => {
      state.transactions = action.payload;
    },
  },
});

export const { updateTutorialTransactions } = ModalTutorialsSlice.actions;
export const ModalTutorialsSelector = (state: State) => state.modalTutorials;

export default ModalTutorialsSlice.reducer;
