import styled from "styled-components";

export const OverLayContainer = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: ${(props) => (props.hidden ? "none" : "initial")};
  z-index: 9;
`;