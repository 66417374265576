import { ExchangeRate } from "./ExchangeRates";

export const allowedCountriesForPayment = [
  "US",
  ...(import.meta.env.VITE_ALLOWED_COUNTRIES_FOR_PAYMENT?.toString()?.split(",") || []),
];

export const allowedCountries = [
  "ARG",
  "BRA",
  "BOL",
  "CHL",
  "COL",
  "GTM",
  "ECU",
  "MEX",
  "PER",
  "URY",
  "SLV",
  "HND",
  "CAN",
  "NIC",
  "IND",
  "PHL",
  "VNM",
  "PAN",
];

export type Country = {
  countryCode: string;
  alpha2Code: string;
  numericCode: string;
  countryName: string;
  officialName: string;
  demonym: string;
  dialCode: string;
  displayOrder: string;
  defaultCurrency: string;
};

export type CountryWithCurrency = Country & ExchangeRate;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isCountryWithCurrency(obj: any): obj is CountryWithCurrency {
  return (
    "countryCode" in obj &&
    typeof obj.countryCode === "string" &&
    "defaultCurrency" in obj &&
    typeof obj.defaultCurrency === "string" &&
    "icon" in obj
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isCountryWithCurrencyArray(obj: any): obj is CountryWithCurrency {
  return Array.isArray(obj) && isCountryWithCurrency(obj[0]);
}

export interface CountryState {
  countries: CountryWithCurrency[];
}

export interface CountryRepository {
  getCountries: (lang: string, sponsorBank?: string) => Promise<CountryWithCurrency[]>;
  getAllCountries: (lang: string) => Promise<CountryWithCurrency[]>;
}
