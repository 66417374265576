import { createAsyncThunk } from "@reduxjs/toolkit";

// Own services Api
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { GET_PERSON_PAYROLL_INFO } from "../../Constants/Payroll";
import { PersonPayrollInfoPayload } from "../../Models/DirectDeposit";

export const getPersonPayrollInfo = createAsyncThunk(
  GET_PERSON_PAYROLL_INFO,
  async (payload: PersonPayrollInfoPayload, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(`/payroll/person/${payload.personId}/customer/${payload.customerId}/info`);
      return response.data.data;
    } catch(error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
