import { components, ValueContainerProps } from "react-select";
import { SelectOption } from "../../Models/Select";
import { TextOptionImageAvatar } from "./SearchSelect.styled";
import { LetterAvatar } from "../Avatar/LetterAvatar.component";
import { FlexItem } from "../Flex/Flex.styled";

export const CustomValueContainer = ({ children, ...props }: ValueContainerProps<SelectOption>) => {
  return (
    <components.ValueContainer {...props}>
      <FlexItem alignItems="center">
        {!props.selectProps.menuIsOpen && (
          <>
            {props.hasValue && props.getValue()[0].image ? (
              <TextOptionImageAvatar
                width={props.getValue()[0].avatarSize?.width}
                height={props.getValue()[0].avatarSize?.height}
                src={props.getValue()[0].image}
              />
            ) : (
              props.hasValue && <LetterAvatar label={props.getValue()[0].label} />
            )}
          </>
        )}
        {children}
      </FlexItem>
    </components.ValueContainer>
  );
};
