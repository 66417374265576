import CountriesWithFlag from "../Components/CountriesWithFlag";

// It is assigned to any temporarily, since this data must be returned from a service

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const countriesDummy: any = [
  {
    countryCode: "USA",
    alpha2Code: "US",
    numericCode: "840",
    countryName: "United States",
    officialName: "United States of America",
    demonym: "American",
    dialCode: "1",
    displayOrder: "1",
    defaultCurrency: "USD",
  },
  {
    countryCode: "ARG",
    alpha2Code: "AR",
    numericCode: "32",
    countryName: "Argentina",
    officialName: "Argentina Republic",
    demonym: "Argentina",
    dialCode: "54",
    displayOrder: "2",
    defaultCurrency: null,
  },
  {
    countryCode: "BB",
    alpha2Code: "BB",
    numericCode: "52",
    countryName: "Barbados",
    officialName: "Barbados",
    demonym: "Barbados",
    dialCode: "1-246",
    displayOrder: "3",
    defaultCurrency: null,
  },
  {
    countryCode: "CL",
    alpha2Code: "CL",
    numericCode: "152",
    countryName: "Chile",
    officialName: "Chile",
    demonym: "Chile",
    dialCode: "56",
    displayOrder: "4",
    defaultCurrency: null,
  },
  {
    countryCode: "COL",
    alpha2Code: "CO",
    numericCode: "170",
    countryName: "Colombia",
    officialName: "Colombia",
    demonym: "Colombia",
    dialCode: "57",
    displayOrder: "5",
    defaultCurrency: null,
  },
  {
    countryCode: "CRI",
    alpha2Code: "CR",
    numericCode: "188",
    countryName: "Costa Rica",
    officialName: "Costa Rica",
    demonym: "Costa Rica",
    dialCode: "57",
    displayOrder: "6",
    defaultCurrency: null,
  },
  {
    countryCode: "ECU",
    alpha2Code: "EC",
    numericCode: "218",
    countryName: "Ecuador",
    officialName: "Ecuador",
    demonym: "Ecuador",
    dialCode: "593",
    displayOrder: "7",
    defaultCurrency: null,
  },
  {
    countryCode: "SLV",
    alpha2Code: "SV",
    numericCode: "222",
    countryName: "El Salvador",
    officialName: "Republic of El Salvador",
    demonym: "El Salvador",
    dialCode: "503",
    displayOrder: "8",
    defaultCurrency: null,
  },
  {
    countryCode: "HND",
    alpha2Code: "HN",
    numericCode: "340",
    countryName: "Honduras",
    officialName: "Honduras",
    demonym: "Honduras",
    dialCode: "504",
    displayOrder: "9",
    defaultCurrency: null,
  },
  {
    countryCode: "HUN",
    alpha2Code: "HU",
    numericCode: "348",
    countryName: "Hungary",
    officialName: "Hungary",
    demonym: "Hungarian",
    dialCode: "36",
    displayOrder: "10",
    defaultCurrency: null,
  },
  {
    countryCode: "GTM",
    alpha2Code: "GT",
    numericCode: "320",
    countryName: "Guatemala",
    officialName: "Republic of Guatemala",
    demonym: "Guatemalans",
    dialCode: "502",
    displayOrder: "11",
    defaultCurrency: null,
  },
  {
    countryCode: "JAM",
    alpha2Code: "JM",
    numericCode: "388",
    countryName: "Jamaican",
    officialName: "Jamaican",
    demonym: "Jamaican",
    dialCode: "1-876",
    displayOrder: "12",
    defaultCurrency: null,
  },
  {
    countryCode: "MEX",
    alpha2Code: "MX",
    numericCode: "484",
    countryName: "Mexico",
    officialName: "United Mexican States",
    demonym: "Mexican",
    dialCode: "52",
    displayOrder: "13",
    defaultCurrency: "MXN",
  },
  {
    countryCode: "MNG",
    alpha2Code: "MN",
    numericCode: "976",
    countryName: "Mongolia",
    officialName: "Mongolia",
    demonym: "Mongolia",
    dialCode: "496",
    displayOrder: "14",
    defaultCurrency: "MXN",
  },
  {
    countryCode: "PHL",
    alpha2Code: "PH",
    numericCode: "608",
    countryName: "Philippines",
    officialName: "Republic of the Philippines",
    demonym: "Filipino",
    dialCode: "63",
    displayOrder: "15",
    defaultCurrency: null,
  },
  {
    countryCode: "THA",
    alpha2Code: "TH",
    numericCode: "764",
    countryName: "Thailand",
    officialName: "Kingdom of Thailand",
    demonym: "Thai",
    dialCode: "66",
    displayOrder: "16",
    defaultCurrency: null,
  },
  {
    countryCode: "TUR",
    alpha2Code: "TR",
    numericCode: "792",
    countryName: "Turkey",
    officialName: "Republic of Turkey",
    demonym: "Turkish",
    dialCode: "90",
    displayOrder: "17",
    defaultCurrency: null,
  },
  {
    countryCode: "ZAF",
    alpha2Code: "ZA",
    numericCode: "710",
    countryName: "South Africa",
    officialName: "Republic of South Africa",
    demonym: "South African",
    dialCode: "27",
    displayOrder: "18",
    defaultCurrency: null,
  },
  {
    countryCode: "PRI",
    alpha2Code: "PR",
    numericCode: "630",
    countryName: "Puerto Rico",
    officialName: "Free Associated State of Puerto Rico",
    demonym: "Puerto Rican",
    dialCode: "1",
    displayOrder: "19",
    defaultCurrency: "null",
  },
];

export const countriesIdentityEn = [
  {
    value: "MEX",
    label: CountriesWithFlag({ countryCode: "MX", label: "Mexico" }),
    filter: "Mexico",
  },
  {
    value: "GTM",
    label: CountriesWithFlag({ countryCode: "GT", label: "Guatemala" }),
    filter: "Guatemala",
  },
  {
    value: "ARG",
    label: CountriesWithFlag({ countryCode: "AR", label: "Argentine" }),
    filter: "Argentine",
  },
  {
    value: "BB",
    label: CountriesWithFlag({ countryCode: "BB", label: "Barbados" }),
    filter: "Barbados",
  },
  {
    value: "CL",
    label: CountriesWithFlag({ countryCode: "CL", label: "Chile" }),
    filter: "Chile",
  },
  {
    value: "COL",
    label: CountriesWithFlag({ countryCode: "CO", label: "Colombia" }),
    filter: "Colombia",
  },
  {
    value: "CRI",
    label: CountriesWithFlag({ countryCode: "CR", label: "Costa Rica" }),
    filter: "Costa Rica",
  },
  {
    value: "ECU",
    label: CountriesWithFlag({ countryCode: "EC", label: "Ecuador" }),
    filter: "Ecuador",
  },
  {
    value: "SLV",
    label: CountriesWithFlag({ countryCode: "SV", label: "El Salvador" }),
    filter: "El Salvador",
  },
  {
    value: "HND",
    label: CountriesWithFlag({ countryCode: "HN", label: "Honduras" }),
    filter: "Honduras",
  },
  {
    value: "HUN",
    label: CountriesWithFlag({ countryCode: "HU", label: "Hungary" }),
    filter: "Hungary",
  },
  {
    value: "JAM",
    label: CountriesWithFlag({ countryCode: "JM", label: "Jamaican" }),
    filter: "Jamaican",
  },
  {
    value: "MNG",
    label: CountriesWithFlag({ countryCode: "MN", label: "Mongolia" }),
    filter: "Mongolia",
  },
  {
    value: "PHL",
    label: CountriesWithFlag({ countryCode: "PH", label: "Philippines" }),
    filter: "Philippines",
  },
  {
    value: "THA",
    label: CountriesWithFlag({ countryCode: "TH", label: "Thailand" }),
    filter: "Thailand",
  },
  {
    value: "TUR",
    label: CountriesWithFlag({ countryCode: "TR", label: "Turkey" }),
    filter: "Turkey",
  },
  {
    value: "USA",
    label: CountriesWithFlag({ countryCode: "US", label: "United States" }),
    filter: "United States",
  },
  {
    value: "ZAF",
    label: CountriesWithFlag({ countryCode: "ZA", label: "South Africa" }),
    filter: "South Africa",
  },
  {
    value: "NIC",
    label: CountriesWithFlag({ countryCode: "NI", label: "Nicaragua" }),
    filter: "Nicaragua",
  },
  {
    value: "CAN",
    label: CountriesWithFlag({ countryCode: "CA", label: "Canada" }),
    filter: "Canada",
  },
  {
    value: "IND",
    label: CountriesWithFlag({ countryCode: "IN", label: "India" }),
    filter: "India",
  },
  {
    value: "VNM",
    label: CountriesWithFlag({ countryCode: "VN", label: "Vietnam" }),
    filter: "Vietnam",
  },
  {
    value: "PRI",
    label: CountriesWithFlag({ countryCode: "PR", label: "Puerto Rico" }),
    filter: "Puerto Rico",
  },
];

export const countriesIdentityEs = [
  {
    value: "MEX",
    label: CountriesWithFlag({ countryCode: "MX", label: "México" }),
    filter: "México",
  },
  {
    value: "GTM",
    label: CountriesWithFlag({ countryCode: "GT", label: "Guatemala" }),
    filter: "Guatemala",
  },
  {
    value: "ARG",
    label: CountriesWithFlag({ countryCode: "AR", label: "Argentina" }),
    filter: "Argentina",
  },
  {
    value: "BB",
    label: CountriesWithFlag({ countryCode: "BB", label: "Barbados" }),
    filter: "Barbados",
  },
  {
    value: "CL",
    label: CountriesWithFlag({ countryCode: "CL", label: "Chile" }),
    filter: "Chile",
  },
  {
    value: "COL",
    label: CountriesWithFlag({ countryCode: "CO", label: "Colombia" }),
    filter: "Colombia",
  },
  {
    value: "CRI",
    label: CountriesWithFlag({ countryCode: "CR", label: "Costa Rica" }),
    filter: "Costa Rica",
  },
  {
    value: "ECU",
    label: CountriesWithFlag({ countryCode: "EC", label: "Ecuador" }),
    filter: "Ecuador",
  },
  {
    value: "SLV",
    label: CountriesWithFlag({ countryCode: "SV", label: "El Salvador" }),
    filter: "El Salvador",
  },
  {
    value: "HND",
    label: CountriesWithFlag({ countryCode: "HN", label: "Honduras" }),
    filter: "Honduras",
  },
  {
    value: "HUN",
    label: CountriesWithFlag({ countryCode: "HU", label: "Hungría" }),
    filter: "Hungría",
  },
  {
    value: "JAM",
    label: CountriesWithFlag({ countryCode: "JM", label: "Jamaica" }),
    filter: "Jamaica",
  },
  {
    value: "MNG",
    label: CountriesWithFlag({ countryCode: "MN", label: "Mongolia" }),
    filter: "Mongolia",
  },
  {
    value: "PHL",
    label: CountriesWithFlag({ countryCode: "PH", label: "Filipinas" }),
    filter: "Filipinas",
  },
  {
    value: "THA",
    label: CountriesWithFlag({ countryCode: "TH", label: "Thailandia" }),
    filter: "Thailandia",
  },
  {
    value: "TUR",
    label: CountriesWithFlag({ countryCode: "TR", label: "Turquía" }),
    filter: "Turquía",
  },
  {
    value: "USA",
    label: CountriesWithFlag({ countryCode: "US", label: "Estados Unidos" }),
    filter: "Estados Unidos",
  },
  {
    value: "ZAF",
    label: CountriesWithFlag({ countryCode: "ZA", label: "Sudáfrica" }),
    filter: "Sudáfrica",
  },
  {
    value: "NIC",
    label: CountriesWithFlag({ countryCode: "NI", label: "Nicaragua" }),
    filter: "Nicaragua",
  },
  {
    value: "CAN",
    label: CountriesWithFlag({ countryCode: "CA", label: "Canadá" }),
    filter: "Canadá",
  },
  {
    value: "IND",
    label: CountriesWithFlag({ countryCode: "IN", label: "India" }),
    filter: "India",
  },
  {
    value: "VNM",
    label: CountriesWithFlag({ countryCode: "VN", label: "Vietnam" }),
    filter: "Vietnam",
  },
  {
    value: "PRI",
    label: CountriesWithFlag({ countryCode: "PR", label: "Puerto Rico" }),
    filter: "Puerto Rico",
  },
];


export const CATALOGS_ALL_COUNTRY_GP = "catalogsAllCountry";
export const CATALOGS_ALL_COUNTRY_GP_PENDING = `${CATALOGS_ALL_COUNTRY_GP}/pending`;
export const CATALOGS_ALL_COUNTRY_GP_REJECTED = `${CATALOGS_ALL_COUNTRY_GP}/rejected`;
export const CATALOGS_ALL_COUNTRY_GP_FULFILLED = `${CATALOGS_ALL_COUNTRY_GP}/fulfilled`;