// Own models
import { TabsProps, dataList } from "../../Models/Tabs";

// Own utils
import { handleSizeTabs } from "../../Utilities/TabsColumns";

// Own styles
import { Badge, TabsTitle } from "./Tabs.styled";

// Own component
import { Column, Row } from "../Grid";
import Text from "../Text";

const Tabs = (props:TabsProps) => {
  const { list, selectTabActive } = props;

  return(
    <Row>
      { list.map((item:dataList) => {
        return (
          <Column
            key={item.id}
            span={handleSizeTabs(list.length)}
            as={TabsTitle}
            mt={0}
            onClick={() => selectTabActive(item.value)}>
            <Text
              size={1}
              color={item.isActive ? "Primary01" : "grey02"}
              align="center"
              margin="12px 0"
              weight={600}
              className={item.isActive ? "active" : ""}>
              {item.label}
            </Text>
            { item.isNew && (
              <Badge>
                {item.isNew}
              </Badge>
            )}
          </Column>
        )
      })}
    </Row>
  )
};

export default Tabs;