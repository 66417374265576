// Define what main theme will look like
export const theme = {
  Transparent: "transparent",
  Platinum: "#D9D9D9",
  Platinum_2: "#e8e8e8",
  Platinum_3: "#dddddd",
  White: "#FFFFFF",
  Black: "#000000",
  Alice_Blue: "#DEEDFF",
  Background_01: "#FFF",
  Background_02: "#000",
  Background_03: "#C6C6C6",
  Background_04: "#667085",
  Background_05: "#E3E3E3",
  Background_06: "#D9D9D9",
  Background_07: "#EEEEEE",
  Background_08: "#231F20",
  Background_09: "#C6C3FD",
  Background_10: "#000000",
  Background_11: "#FDE68A",
  Background_12: "#F9F9F9",
  Background_13: "#361b4a",
  Background_14: "#221349",
  Background_15: "#cecece",
  Background_16: "#f7f7f7",
  Background_17: "#D97706",
  Background_18: "#B45309",
  Content_UX_01: "#1C1C1C",
  Content_UX_02: "#717171",
  Content_UX_03: "#FBFBFB",
  Content_State_UX_Hover: "#717171",
  Content_State_UX_Active: "#393939",
  Content_State_UX_Disabled: "#E3E3E3",
  Primary_01: "#6E66FF",
  Primary_01_900: "#4701B2",
  Primary_01_800: "#680BAD",
  Primary_01_700: "#6739DC",
  Primary_01_600: "#5C51F4",
  Primary_01_500: "#5630B5",
  Primary_01_50: "#F0EBFB",
  Primary_01_25: "#EFF0F9",
  Primary_02: "#06063F",
  Primary_03: "#FF007C",
  Primary_03_100: "#E9337C",
  Primary_04: "#210066",
  Primary_05: "#222222",
  Primary_06: "#01B442",
  Primary_07: "#24420E",
  Primary_08: "#F9CB28",
  Primary_08_700: "#B45309",
  Primary_09: "#7583A5",
  Primary_10: "#038C8C",
  Primary_11: "#C8F9E8",
  Primary_12: "#9747FF",
  Primary_13: "#491B80",
  Primary_14: "#6426CC",
  Primary_15: "#EBEBF9",
  Primary_16: "#34579D",
  Primary_17: "#00E5C5",
  Primary_18: "#B39CEE",
  Primary_19: "#EEEEEC",
  Primary_20: "#DC2626",
  Primary_21: "#F59E0B",
  Primary_22: "#5400C8",
  Primary_23: "#6E66FD",
  Primary_600: "#026E78",
  Primary_500: "#5C51F4",
  Primary_900: "#221349",
  Primary_Blue: "#338AF3",
  Primary_Blue_400: "#0C75DE",
  ZelleColor: "#6D1ED4",
  BannerBank: "#0766E1",
  BannerBank_Dark: "#264093",
  Red_08: "#94000D",
  Red_09: "#D80027",
  Red_10: "#D51A0C",
  Red_11: "#DC2626",
  Green_01: "#077D45",
  Shadow: "#00000040",
  Link: "#1348CC",
  font_family: "Poppins",
  RGBA_01: "0,0,0",
  RGBA_02: "35,44,119",
  RGBA_03: "196,21,90",
  RGBA_04: "0,0,0,0.25",
  RGBA_05: "0,0,0,0.6",
  RGB_01: "249,203,40",
  RGB_02: "7,125,69",
  RGB_03: "213,26,12",
  RGB_04: "110,102,255",
  RGB_06: "0, 0, 0, 0.35",
  RGBA_07: "0, 0, 0, 0.85",
  RGB_Background_01: "255,255,255",
  Gradient_Primary: "linear-gradient(9deg, #680BAD 0.97%, #6E66FF 112.47%)",
  Gradient_01: "linear-gradient(180deg, #FF007C 0%, #6E66FF 100%)",
  Gradient_02: " linear-gradient(8.83deg, #22B6D6 -1.99%, #0D022C 107.37%)",
  Gradient_03: " linear-gradient(8.83deg, #361B4A 9.97%, #6E66FF 100.47%)",
  Gradient_04: " linear-gradient(8.83deg, #181039 4.97%, #7C3D93 112.47%)",
  Gradient_05: " linear-gradient(8.83deg, #361B4A 4.97%, #C40081 112.47%)",
  Gradient_06: "linear-gradient(100deg, #6E66FF 9%, #7C3D93 90%)",
  Gradient_07: " linear-gradient(8.83deg, #04534E 0.97%, #01B442 112.47%)",
  Gradient_08: "linear-gradient(9deg, #680BAD 0.97%, #6E66FF 112.47%)",
  Gradient_09: " linear-gradient(8.83deg, #680BAD 0.97%, #09000C 112.47%)",
  Gradient_10: " linear-gradient(180deg, #F8F9FA 0%, #B3BBCE 100%)",
  Gradient_Coppel: "linear-gradient(180deg, #34369D 0%, #34599D 100%)",
  Linear_coppel: "linear-gradient(9deg, #0051BA 0.97%, #0868E4 89.69%)",
  Gradient_11: " linear-gradient(359.55deg, #10B981 -1.99%, #0D022C 107.37%)",
  Gradient_12: "linear-gradient(75deg, #3588F3 10.3%, #FF007C 84.17%)",
  Gradient_13: "linear-gradient(90deg, #000 61%, #6739DC 100%)",
  Gradient_14: "linear-gradient(180deg, #D9D9D9 90.61%, #6E66FF 168.89%)",
  Gradient_15: "linear-gradient(180deg, #522DAF 0%, #221349 54%)",
  Gradient_16: "linear-gradient(90deg, #6E66FF 0%, #338AF3 100%)",
  Gradient_Rewards:
    "linear-gradient(74.68deg, #6E66FF 3.44%, #7163FB 17.97%, #7C5BF1 31.7%, #8F4EE1 44.61%, #A83CC9 56.72%, #CA24AB 68.83%, #F20887 80.94%, #FF007C 84.17%)",
  Gradient_Primary_Blue: "linear-gradient(90deg, #6E66FF 0%, #338AF3 100%)",
  Gradient_Soft_Gray: "linear-gradient(180deg, #fcfdfd 35.1%, #f2f4f7 156.25%)",
  Background_Banner_Bank: "#285EA9",
  Shadow_01: "#00000040",
  Background_red: "#FCA5A5",
  Content_UX_04: "#4F4F4F",
  Green_05: "#56A034",
  Green_06: "#10B981",
  Green_07: "#DAF3E7",
  Neutral_100: "#EAECF2",
  Neutral_300: "#B3BBCE",
  Neutral_500: "#7583A5",
  Neutral_600: "#536080",
  Neutral_700: "#384055",
  Neutral_900: "#0E1015",
  Bluebonnet_Color: "#2622FF",
  Tanhide_Color: "#F98553",
  CornFlowerBlue_Color: "#569BEB",
  BackgroundPurple: "#9E44E1",
  BackgroundPurple1: "#ebdaf8",
  BackgroundPurple2: "#7C3D93",
  BackgroundPurple3: "#F0EBFB",
  BackgroundPurple4: "#EFF0F9",
  BackgroundPurple6: "#E1D7F8",
  Linear: "#b400aa",
  Background_green: "#CCEECC",
  RADIO_SHADOW: "#C8F9E8",
  Fill_Contrast: "#1E282A",
  Dark: "#4E4E4E",
  Grey: "#6B6B6B",
  Grey_01: "#504747",
  Grey_02: "#9EA8C0",
  Grey_03: "#CBCBCB",
  Grey_04: "#EEEEEC",
  Grey_05: "#646464",
  Grey_06: "#D4D4D4",
  Grey_07: "#989898",
  Dark_01: "#343434",
  RGB_05: "255, 179, 174",
  Content_UX_05: "#34369D",
  Secondary_Coppel: "#F9E12E",
  Secondary_01: "#9E44E1",
  Base_600: "#0C1112",
  BackgroundPurple5: "#815AE2",
  BackgroundBlue: "#0051BA",
  BackgroundBlue1: "#345599",
  BackgroundBlue2: "#2D356B",
  Coppel: "#FADE19",
  Grey_08: "#ADADAD",
  BackgroundBlue3: "#285EA9",
  Grey_09: "#B6B6B6",
  Grey_10: "#B3BBCE",
  Dark_02: "#11181C",
  Success_Green: "#34D399",
  Dark_03: "#1C202B",
  Foreground_Purple_01: "#680BAD",
  Black_Contrast_01: "var(--fill-contrast-600, rgba(30, 40, 42, 1))",
  Dark_04: "#484848",
  VenmoColor: " #008CFF",
  VenmoGradient: "linear-gradient(9deg, #008CFF 0.97%, #00BBFF 112.47%)",
  Silver: "#C1C1C1",
  Muted: "#EBEEEF",
  Error: "#EA5F59",
};

export type ThemeColors = keyof typeof theme;
