import {
  PaymentSendolaPayIcon,
  StepCheckStyle,
  StepContainer,
  StepCount,
  StepLabel,
  StepStyle,
  StepSubText,
  StepText,
  StepWrapper,
  StepsLabelContainer,
} from "./StepsCustom.styled";
import checkIconSrc from "@assets/Icons/IconCheck.svg";
import checkIconDisabled from "@assets/Icons/checkIconDisabled.svg";
import { StepsCustomProps } from "@models/CustomSteps";

const ProgressStepSCustom = (props: StepsCustomProps) => {
  const { enabledStatus, steps } = props;

  return (
    <StepContainer $enabledStatus={enabledStatus}>
      {steps.map(({ step, label, date, description, activeIcon }) => (
        <StepWrapper key={step + label}>
          {activeIcon ? (
            <StepCheckStyle>
              <PaymentSendolaPayIcon $active={activeIcon} src={enabledStatus ? checkIconSrc : checkIconDisabled} />
            </StepCheckStyle>
          ) : (
            <StepStyle $step={"incomplete"}>
              <StepCount></StepCount>
            </StepStyle>
          )}
          <StepsLabelContainer>
            <StepLabel key={step + label} $enabledStatus={enabledStatus} $active={activeIcon}>
              {label}
            </StepLabel>
            <StepText key={step} $enabledStatus={enabledStatus} $active={activeIcon}>
              {description}
            </StepText>
            <StepSubText $enabledStatus={enabledStatus} $active={activeIcon}>
              {date}
            </StepSubText>
          </StepsLabelContainer>
        </StepWrapper>
      ))}
    </StepContainer>
  );
};

export default ProgressStepSCustom;
