
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { NumericFormat } from 'react-number-format'
import isEmpty from "lodash.isempty";

// own states of redux
import { AppDispatch, State } from "../../../Redux/Store";
import { findAccountBalance } from "../../../Redux/AccountBalance/AccountBalance.actions";
import { resetAccount, setCurrentAccount } from "../../../Redux/Accounts/Accounts.slice";

// Own models
import { JustifyContent } from "../../../Models/Column";
import { FundsSelectAccountForm } from "../../../Models/Funds";
import { AccountBalance } from "../../../Models/BalanceCard";
import { CurrentAccount } from "../../../Models/Account";

// Own constants
import { allowenNumbers } from "../../../Constants/Regexp";

// OWn components
import Button from "../../../Components/Button";
import Layout from "../../../Components/Layout";
import { BottomFixedContainer, Column, FillerContainer, Row } from "../../../Components/Grid";
import Text from "../../../Components/Text";
import Title from "../../../Components/Title";
import BankAccountIcon from "../../../Components/Icons/BankAccount";

import { 
  AccountBankDescription, 
  AccountCard, 
  SelectAccountButton, 
  SelectedAccountError, 
  SelectedAccountInput 
} from "./SelectAccount.styles";
import { Container } from "../../404/ErrorPage.styled";
import CurrencyService from "../../../Services/CurrencyService";
import { GET_BALANCE_FULFILLED } from "../../../Constants/Balance";
import LoaderIcon from "../../../Components/LoaderIcon";
import { LinkedAccountContainer } from "../LinkedAccounts/LinkedAccounts.styles";

const PassportPage = () => {
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const [accountbalance, setAccountBalance] = useState<AccountBalance>();
  const [accountSelected, setAccountSelected] = useState<CurrentAccount>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [formattedAmount, setFormattedAmount] = useState<number>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const personId = useSelector((state: State) => state.ByUser.data)?.personId || '';
  const typeAccountSelected = useSelector((state: State) => state.SelectTypeAccount);
  const accounts = useSelector((state: State) => state.Accounts).data;
  const accountIdSelected = typeAccountSelected.selectAccount.id;

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<FundsSelectAccountForm>({
    mode: "onChange",
  });

  const amount = watch('amount');

  useEffect(() => {
    getCurrentAccountType();
  }, []);

  const getCurrentAccountType = () => {
    if (!accounts) {
      return navigate('/', { replace: true });
    }

    dispatch(resetAccount());

    const currentAccount: Array<CurrentAccount> = accounts?.filter((account: CurrentAccount) => 
      account.id === accountIdSelected
    );

    getBalance(personId, currentAccount[0].id)
    setAccountSelected(currentAccount[0])
  };

  const getBalance = async (personId: string, accountId: string) => {
    const payload = {
      personId: personId,
      accountId: accountId,
    }

    const response = await dispatch(findAccountBalance(payload));

    if (response?.type === GET_BALANCE_FULFILLED) {
      setAccountBalance(response?.payload);
    }
    setIsLoading(false);
  }

  const addAccountAmount = () => {
    if(!formattedAmount) return;

    dispatch(setCurrentAccount({
      amount: formattedAmount,
      data: accountSelected
    }));
    navigate('/funds-linked-accounts')
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout show={isLoading}>
        <LoaderIcon
          title=""
          text=""
          description={t("loaderText.description")}
        />
      </Layout>
      <Layout
        show={!isLoading}
        path="/fund-account"
        textLink={t("buttons.back")}
      >
        <FillerContainer as={LinkedAccountContainer}>
          <Row>
            <Column
              span={12}
              justifyContent={JustifyContent.center}
              mt={5}
              mb={3}
            >
              <Title
                size={4}
                text={t("Funds.SelectAccount.title")}
                color="black"
                align="left"
                tagName="h1"
              />
            </Column>
            <Column span={12} my={2}>
              <form onSubmit={handleSubmit(addAccountAmount)}>
                <Text
                  size={1}
                  color="black"
                  margin="0 4px 4px 0"
                  weight="700"
                  align="left"
                >
                  {t("Funds.SelectAccount.form.label")}
                </Text>
                <Column span={12}>
                  <Controller
                    name="amount"
                    control={control}
                    rules={{
                      pattern: {
                        value: allowenNumbers,
                        message: t("Forms.inputNumber"),
                      },
                    }}
                    render={({ field: { ref, ...rest } }) => (
                      <SelectedAccountInput>
                        <NumericFormat
                          placeholder="$0.00"
                          thousandSeparator=","
                          decimalSeparator="."
                          prefix="$ "
                          decimalScale={4}
                          onValueChange={(values) => {
                            setFormattedAmount(values.floatValue);
                          }}
                          getInputRef={ref}
                          {...rest}
                        />
                      </SelectedAccountInput>
                    )}
                  />
                  {!!errors && <SelectedAccountError>{errors.amount?.message as string}</SelectedAccountError>}
                </Column>
                <Container>
                  <Row>
                    <Column span={12} mt={5} mb={4}>
                      <Text
                        size={1}
                        color="black"
                        margin="0 4px 4px 0"
                        weight="700"
                        align="left"
                      >
                        {t("Funds.SelectAccount.accountTitle")}
                      </Text>
                      <Container as={AccountCard}>
                        <Row>
                          <Column span={7}>
                            <AccountBankDescription>
                              <BankAccountIcon />
                              <div>
                                <Text
                                  size={1}
                                  margin="2px 0 2px 10px"
                                  color="black"
                                  weight="600"
                                  align="left"
                                >
                                  {typeAccountSelected &&
                                  typeAccountSelected.selectAccount.type ===
                                    "savingAccount"
                                    ? t("balanceCard.savingsAccount")
                                    : t("balanceCard.checkAccount")}
                                </Text>
                                <Text
                                  size={1}
                                  margin="0 0 0 10px"
                                  color="grey"
                                  weight="400"
                                  align="left"
                                >
                                  {t("Card.balance")}
                                </Text>
                              </div>
                            </AccountBankDescription>
                          </Column>
                          <Column span={5}>
                            <Text
                              size={1}
                              color="black"
                              weight="600"
                              align="right"
                            >
                              {`$ ${CurrencyService.getFloatAmount(accountbalance?.currentBalance.toString() || "")}`}
                            </Text>
                          </Column>
                        </Row>
                      </Container>
                    </Column>
                  </Row>
                </Container>
                <BottomFixedContainer>
                  <Row>
                    <Column span={12}>
                      <SelectAccountButton>
                        <Button
                          type="submit"
                          variant={!isEmpty(amount) ? "primary" : "default"}
                          text={t("buttons.continue")}
                          sizeText="medium"
                          sizeButton="xlarge"
                          disabled={isEmpty(amount)}
                        />
                      </SelectAccountButton>
                    </Column>
                    <Column span={12}>
                      <Button
                        type="button"
                        variant="outline"
                        text={t("buttons.cancel")}
                        onClick={() => navigate("/")}
                        sizeText="medium"
                        sizeButton="xlarge"
                      />
                    </Column>
                  </Row>
                </BottomFixedContainer>
              </form>
            </Column>
          </Row>
        </FillerContainer>
      </Layout>
    </ThemeProvider>
  );
};

export default PassportPage;

