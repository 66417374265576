import { useState, useRef, useEffect, useCallback, ReactElement } from "react";
import { Document, pdfjs } from "react-pdf";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import Loader from "../Loader";

import PDFViewerModal from "./components/PDFViewerModal";

import {
  PDFViewerConfirmStyled,
  PDFViewerNavigatorStyled,
  PDFViewerScrollAnimation,
  PDFViewerStyled,
} from "./PDFViewer.styled";
import PDFViewerPage from "./components/PDFViewerPage";
import PDFViewerZoom from "./components/PDFViewerZoom";
import { useSelector } from "react-redux";
import { selectorLanguage } from "../../Redux/Translate";
import { LEGAL_FILES } from "../../Constants/LegalFiles";
import Button from "../Button";
import Icon from "../Icon";
import { isMobile } from "react-device-detect";
import React from "react";
import type { PDFDocumentProxy } from 'pdfjs-dist';
//import { useTranslation } from "react-i18next";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

type Props = {
  url?: string;
  children?: ReactElement;
  onClose?: () => void;
  handleConfirmButton?: () => void;
  confirmButtonText?: string;
  open?: boolean;
  canDownload?: boolean;
  directUrl?: string;
};

const PDFViewer = ({
  url,
  children,
  onClose,
  handleConfirmButton,
  confirmButtonText,
  open,
  canDownload,
  directUrl
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [pageWidth, setPageWidth] = useState(0);
  const [visiblePage, setVisiblePage] = useState(1);
  const [activeButton, setActiveButton] = useState(false);
  const [atBottom, setAtBottom] = useState(false);
  const documentRef = useRef<HTMLDivElement>(null);
  const lang = useSelector(selectorLanguage);
  const [currentUrl, setCurrentUrl] = useState("");
  const [documentScale, setDocumentScale] = useState<number>(0.6);

  const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
    setNumPages(numPages);
  };

  const onPageLoadSuccess = () => {
    setPageWidth(window.innerWidth);
  };

  const onDocumentResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;

    if (entry) {
      setPageWidth(entry.contentRect.width);
    }
  }, []);

  useEffect(() => {
    if (!documentRef?.current || !("ResizeObserver" in window)) {
      return;
    }

    const observer = new ResizeObserver(onDocumentResize);

    observer.observe(documentRef?.current);

    return () => {
      observer.disconnect();
    };
  }, [documentRef, onDocumentResize]);

  const setPageVisibility = useCallback(
    (pageNumber: number, atBottom: boolean) => {
      setAtBottom(atBottom);
      setVisiblePage(pageNumber);
    },
    []
  );

  useEffect(() => {
    if (!activeButton) setActiveButton(numPages == visiblePage && atBottom);
  }, [visiblePage, atBottom]);

  useEffect(() => {
    setActiveButton(false);
    if (isOpen || (open !== undefined && open)) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "";
    } else {
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    }
  }, [isOpen, open]);

  useEffect(() => {
    if (directUrl) {
      setCurrentUrl(directUrl);
    } else {
      selectCurrentUrl(url);
    }
  }, [url, directUrl]);

  const onZoomIn = () => {
    if (documentScale > 0.8) return;
    setDocumentScale((prevState) => prevState + 0.1);
  };

  const onZoomOut = () => {
    if (documentScale === 0.6) return;
    setDocumentScale((prevState) => prevState - 0.1);
  };

  const selectCurrentUrl = (elemUrl?: string) => {
    if (elemUrl) {
      const LegalFilesUrl = LEGAL_FILES.filter((item) => item.path === elemUrl);
      if (lang) {
        const url =
          lang.language === "es"
            ? LegalFilesUrl[0].urlEs || ""
            : lang.language === "en"
              ? LegalFilesUrl[0].urlEn || ""
              : LegalFilesUrl[0].urlEn || "";
        setCurrentUrl(url);
      }
    }
  };

  const onOpenModal = () => {
    currentUrl.includes(".pdf") ? setIsOpen(true) : window.open(currentUrl);
  };

  return (
    <>
      {open === undefined && children &&
        React.cloneElement(children, {
          onClick: (event: MouseEvent) => {
            event.preventDefault();
            onOpenModal();
          },
        })}
      <PDFViewerModal
        modalRef={documentRef}
        isOpen={open !== undefined ? open : isOpen}
        canDownload={
          canDownload === undefined ||
          (canDownload !== undefined && canDownload)
        }
        url={currentUrl}
        header={
          <PDFViewerPageNavigator
            totalPages={numPages}
            currentPage={visiblePage}
          />
        }
        footer={
          <PDFViewerConfirm
            activeConfirmButton={activeButton}
            confirmButtonText={confirmButtonText ? confirmButtonText : "Ok"}
            handleConfirmButton={handleConfirmButton}
          />
        }
        onClose={(event) => {
          event.preventDefault();
          setIsOpen(false);
          if (onClose) onClose();
        }}
      >
        <PDFViewerStyled ref={documentRef}>
          <PDFViewerZoom zoomIn={onZoomIn} zoomOut={onZoomOut}>
            <Document
              file={currentUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<Loader full />}
            >
              {Array.from(new Array(numPages), (_, index) => (
                <PDFViewerPage
                  totalPages={numPages}
                  isOpenedModal={isOpen}
                  key={`page_${index + 1}`}
                  className="pdf-page"
                  width={Math.max(pageWidth * documentScale, 350)}
                  pageNumber={index + 1}
                  onLoadSuccess={onPageLoadSuccess}
                  setPageVisibility={setPageVisibility}
                />
              ))}
            </Document>
          </PDFViewerZoom>
        </PDFViewerStyled>
      </PDFViewerModal>
    </>
  );
};

export default PDFViewer;

export const PDFViewerPageNavigator = ({
  currentPage,
  totalPages,
}: {
  totalPages: number;
  currentPage: number;
}) => {
  return (
    <PDFViewerNavigatorStyled>
      <span>
        {currentPage} / {totalPages}
      </span>
    </PDFViewerNavigatorStyled>
  );
};

export const PDFViewerConfirm = ({
  handleConfirmButton,
  activeConfirmButton,
  confirmButtonText,
}: {
  activeConfirmButton: boolean;
  confirmButtonText: string;
  handleConfirmButton?: () => void;
}) => {
  return (
    <PDFViewerConfirmStyled>
      {handleConfirmButton && (
        <>
          {!activeConfirmButton && (
            <PDFViewerScrollAnimation>
              <Icon
                icon="arrowDown"
                size="large"
                color={isMobile ? "black" : "white"}
              />
            </PDFViewerScrollAnimation>
          )}
          <Button
            variant={activeConfirmButton ? "primary" : "default"}
            disabled={!activeConfirmButton}
            sizeButton="xlarge"
            sizeText="small"
            text={confirmButtonText ? confirmButtonText : "Ok"}
            onClick={handleConfirmButton}
          />
        </>
      )}
    </PDFViewerConfirmStyled>
  );
};
