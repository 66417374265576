import { createSlice } from "@reduxjs/toolkit";
import { getPersonPayrollInfo } from "./PersonPayrollInfo.actions";
import { State } from "../Store";
import { GET_PERSON_PAYROLL_INFO } from "../../Constants/Payroll";
import { PersonPayrollDataState } from "../../Models/DirectDeposit";

const initialState: PersonPayrollDataState = {
  loading: false,
  error: null,
  data: null
}

export const PersonPayrollDataSlice = createSlice({
  name: GET_PERSON_PAYROLL_INFO,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPersonPayrollInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPersonPayrollInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload
      })
      .addCase(getPersonPayrollInfo.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export default PersonPayrollDataSlice.reducer;

export const PersonPayrollDataSliceSelector = (state: State) => state.UpdatePersonalData;