import { useState } from "react";

// Own components
import Icon from "../Icon";
import Text from "../Text";

// Own models
import { dataDropDownProps } from "../../Models/LinksList";

// Own styles
import { Item, BtnLink } from "./LinksList.styled";
import PDFViewer from "../PDFViewer";

const DropDownItem = (props: dataDropDownProps) => {
  const { icon, name, items, id } = props;
  const [showItems, setShowItems] = useState(false);

  return (
    <div key={id}>
      <BtnLink onClick={() => setShowItems(!showItems)}>
        {icon && <Icon icon={icon} color="black" size="medium" />}
        <Item>
          {name}
          <Icon
            icon={showItems ? "chevronDown" : "chevronRight"}
            color="black"
            size="medium"
          />
        </Item>
      </BtnLink>
      {showItems &&
        items?.map((itemUrl, i) => {
          return (
            <PDFViewer url={itemUrl.url} key={i}>
              <div className="pointer" key={i}>
                <Text size={0.5} className="ta-left">
                  {itemUrl.name}
                </Text>
              </div>
            </PDFViewer>
          );
        })}
    </div>
  );
};

export default DropDownItem;
