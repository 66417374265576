import { createAsyncThunk } from "@reduxjs/toolkit";
import { RECIPIENTS } from "../../Constants/Recipients";
import { AxiosError } from "axios";
import { defaultErrorMessage } from "../../Constants/Requests";
import { GlobalPaymentsAPI } from "../../Services/API/GlobalPaymentsApi";
import { PayloadProps } from "../../Models/Recipients";

export const getRecipientsAction = createAsyncThunk(
  RECIPIENTS,
  async (payload:PayloadProps, thunkAPI) => {
    try {
      const headers = {
        "languageCode": payload.languageCode,
        "destinationCountry": payload.destinationCountry,
        "destinationId": payload.destinationId
      };
      const response = await GlobalPaymentsAPI.get(`/Beneficiary/frequently/${payload.page}/${payload.perPage}`, { headers: headers });
  
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
