import styled from "styled-components";
import { theme } from "styled-tools";

export const CoppelAccess = styled.div<{$isYellow: boolean}>`
  background-color: ${(props) => props.$isYellow ? theme("Secondary_Coppel") : theme("Background_01")};
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow:0px 4px 24px 0px rgba(${theme("RGBA_01")}, 0.15);
  padding: 24px 0;
  height: 230px;
  margin: 24px 8px;
  text-align: left;
  p {
    font-family: Poppins;
  }
`;

export const ButtonContainer = styled.button`
  display: flex;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid ${theme("Content_UX_05")};
  background: ${theme("Gradient_Coppel")};
	cursor: pointer;

	path {
		fill: ${theme("Background_01")};
	}
`;

export const LinkContainer = styled.a`
  display: flex;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid ${theme("Content_UX_05")};
  background: ${theme("Gradient_Coppel")};
	cursor: pointer;
`;