import styled from "styled-components";
import mediaQuery, { Screen } from "../../../Utilities/MediaQuery";
import {
  InputStyled as BaseInputStyled,
  ContainerText as BaseContainerText,
  ContainerInput as BaseContainerInput,
} from "@components/Payments/Input/Input.styled";
import { theme } from "styled-tools";

export const ContainerInput = styled(BaseContainerInput)<{ $size?: string }>`
  width: 100%;
`;

export const ContainerText = styled(BaseContainerText)`
  color: ${theme("Content_UX_02")};
`;

export const InputStyled = styled(BaseInputStyled)`
  &:hover {
    opacity: 0.9;
  }
`;

export const Text = styled.span`
  font-size: 18px;
  font-weight: 700;
  text-align: left;

  ${mediaQuery[Screen.sm]} {
    font-size: 18px;
    font-weight: 600;
    margin-left: 0px;
  }

  ${mediaQuery[Screen.xs]} {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const Span = styled.span<{ $error?: boolean }>`
  margin-left: 4px;
  display: flex;
  flex-direction: row;
  position: relative;
  font-size: 16px;
  color: ${(props) => props.theme.Red_08};
  width: 70%;
  height: 15px;
  text-align: left;
`;
