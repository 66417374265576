import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";
import { AuthState } from "../../Models/tAuthUser";

const initialState: AuthState = {
  loading: false,
  error: null,
  data: null
}

export const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    authData(state, action) {
      state.data = action.payload;
    },
  },
});

export const { authData } = AuthSlice.actions;

export default AuthSlice.reducer;

export const AuthSelector = (state: State) => state.Auth;
