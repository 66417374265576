import { useEffect, useState } from "react";

// Own constants
import { FIRST_ELEMENT, SECOND_ELEMENT } from "../../Constants/SwitchButton";

// Own models
import { SwitchButtonProps } from "../../Models/SwitchButton";

// Own styles
import { SwitchElement, SwitchButtonContainer } from "./Switchbutton.styled";

// Own components
import Icon from "../Icon";

const SwitchButton = (props: SwitchButtonProps) => {
  const { label1, label2, GetActive, icon1, icon2, hasCPU, hasD2B } = props;
  const [firstElement, setFirstElement] = useState<boolean>(true);
  const [secondElement, setSecondElement] = useState<boolean>(false);
  const [elementActive, setElementActive] = useState(FIRST_ELEMENT);

  const ElementActive = (element: string) => {
    if (element === FIRST_ELEMENT) {
      setElementActive(FIRST_ELEMENT);
    } else {
      setElementActive(SECOND_ELEMENT);
    }
  };

  const handleChangeFirstElement = () => {
    if (hasD2B && hasD2B === true) {
      setFirstElement(true);
      setSecondElement(false);
      ElementActive(FIRST_ELEMENT);
    }
  };

  const handleChangeSecondElement = () => {
    if (hasCPU && hasCPU === true) {
      setFirstElement(false);
      setSecondElement(true);
      ElementActive(SECOND_ELEMENT);
    }
  };

  useEffect(() => {
    GetActive(elementActive);
  }, [elementActive]);

  useEffect(() => {
    if (hasD2B) {
      handleChangeFirstElement();
    } else {
      handleChangeSecondElement();
    }
  }, [hasCPU, hasD2B]);

  return (
    <SwitchButtonContainer>
      <SwitchElement $active={firstElement} onClick={handleChangeFirstElement}>
        {icon1 && <Icon icon={icon1} size="small" color={firstElement ? "white" : "grey"} />}
        {label1}
      </SwitchElement>
      <SwitchElement $active={secondElement} onClick={handleChangeSecondElement}>
        {icon2 && <Icon icon={icon2} size="small" color={secondElement ? "white" : "grey"} />}
        {label2}
      </SwitchElement>
    </SwitchButtonContainer>
  );
};

export default SwitchButton;
