import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";

const initialState = {
  documentType: null
};

export const ProofOfAddressTypeSlice = createSlice({
  name: "proofOfAddressType",
  initialState,
  reducers: {
    updateProofOfAddressType: (state, action) => {
      state.documentType = action.payload;
    },
  },
});

export const { updateProofOfAddressType } = ProofOfAddressTypeSlice.actions;
export const ProofOfAddressTypeSelector = (state: State) => state.proofOfAddressType;

export default ProofOfAddressTypeSlice.reducer;
