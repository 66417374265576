import { createConfigApiApp } from "@services/API/AppApiClient";
import { LinkedAccountRepository } from "../domain/LinkedAccountRepository";
import { LinkedAccountResponse } from "../domain/LinkedAccount";

const clientApp = createConfigApiApp();
const accountsPrefixService = "accounts";

export const createApiLinkedAccountRepository = (): LinkedAccountRepository => {
  return {
    getLinkedAccounts
  }
}

const getLinkedAccounts = async (personId: string) => {
  const response = await clientApp.get<LinkedAccountResponse>(
     `${accountsPrefixService}`,
     { headers: { personId }}
  );
  return response.data;
}