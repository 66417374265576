import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { UPLOAD_DOCUMENTS } from "../../Constants/Documents";
import { DocumentsPayload } from "../../Models/Documents";

export const UploadDocuments = createAsyncThunk(
  UPLOAD_DOCUMENTS,
  async (payload:DocumentsPayload, thunkAPI) => {
    try {
      const response = await BaseConfigApi.post(`/persons/${payload.personId}/documents`, payload.data);
      return response.data.data;
    } catch(error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
